import Select from 'react-select';
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useSearchParams } from 'react-router-dom';


const options = [
    { value: "Alchemist", label: "Alchemist" },
    { value: "Axe", label: "Axe" },
    { value: "Bristleback", label: "Bristleback" },
    { value: "Centaur Warrunner", label: "Centaur Warrunner" },
    { value: "Chaos Knight", label: "Chaos Knight" },
    { value: "Dawnbreaker", label: "Dawnbreaker" },
    { value: "Doom", label: "Doom" },
    { value: "Dragon Knight", label: "Dragon Knight" },
    { value: "Earth Spirit", label: "Earth Spirit" },
    { value: "Earthshaker", label: "Earthshaker" },
    { value: "Elder Titan", label: "Elder Titan" },
    { value: "Huskar", label: "Huskar" },
    { value: "Kunkka", label: "Kunkka" },
    { value: "Legion Commander", label: "Legion Commander" },
    { value: "Lifestealer", label: "Lifestealer" },
    { value: "Mars", label: "Mars" },
    { value: "Night Stalker", label: "Night Stalker" },
    { value: "Ogre Magi", label: "Ogre Magi" },
    { value: "Omniknight", label: "Omniknight" },
    { value: "Primal Beast", label: "Primal Beast" },
    { value: "Pudge", label: "Pudge" },
    { value: "Slardar", label: "Slardar" },
    { value: "Spirit Breaker", label: "Spirit Breaker" },
    { value: "Sven", label: "Sven" },
    { value: "Tidehunter", label: "Tidehunter" },
    { value: "Tiny", label: "Tiny" },
    { value: "Treant Protector", label: "Treant Protector" },
    { value: "Tusk", label: "Tusk" },
    { value: "Underlord", label: "Underlord" },
    { value: "Undying", label: "Undying" },
    { value: "Wraith King", label: "Wraith King" },
    { value: "Anti-Mage", label: "Anti-Mage" },
    { value: "Arc Warden", label: "Arc Warden" },
    { value: "Bloodseeker", label: "Bloodseeker" },
    { value: "Bounty Hunter", label: "Bounty Hunter" },
    { value: "Clinkz", label: "Clinkz" },
    { value: "Drow Ranger", label: "Drow Ranger" },
    { value: "Ember Spirit", label: "Ember Spirit" },
    { value: "Faceless Void", label: "Faceless Void" },
    { value: "Gyrocopter", label: "Gyrocopter" },
    { value: "Hoodwink", label: "Hoodwink" },
    { value: "Juggernaut", label: "Juggernaut" },
    { value: "Kez", label: "Kez" },
    { value: "Luna", label: "Luna" },
    { value: "Medusa", label: "Medusa" },
    { value: "Meepo", label: "Meepo" },
    { value: "Monkey King", label: "Monkey King" },
    { value: "Morphling", label: "Morphling" },
    { value: "Naga Siren", label: "Naga Siren" },
    { value: "Phantom Assassin", label: "Phantom Assassin" },
    { value: "Phantom Lancer", label: "Phantom Lancer" },
    { value: "Razor", label: "Razor" },
    { value: "Riki", label: "Riki" },
    { value: "Shadow Fiend", label: "Shadow Fiend" },
    { value: "Slark", label: "Slark" },
    { value: "Sniper", label: "Sniper" },
    { value: "Spectre", label: "Spectre" },
    { value: "Templar Assassin", label: "Templar Assassin" },
    { value: "Terrorblade", label: "Terrorblade" },
    { value: "Troll Warlord", label: "Troll Warlord" },
    { value: "Ursa", label: "Ursa" },
    { value: "Viper", label: "Viper" },
    { value: "Weaver", label: "Weaver" },
    { value: "Ancient Apparition", label: "Ancient Apparition" },
    { value: "Crystal Maiden", label: "Crystal Maiden" },
    { value: "Death Prophet", label: "Death Prophet" },
    { value: "Disruptor", label: "Disruptor" },
    { value: "Enchantress", label: "Enchantress" },
    { value: "Grimstroke", label: "Grimstroke" },
    { value: "Jakiro", label: "Jakiro" },
    { value: "Keeper of the Light", label: "Keeper of the Light" },
    { value: "Leshrac", label: "Leshrac" },
    { value: "Lich", label: "Lich" },
    { value: "Lina", label: "Lina" },
    { value: "Lion", label: "Lion" },
    { value: "Muerta", label: "Muerta" },
    { value: "Nature's Prophet", label: "Nature's Prophet" },
    { value: "Necrophos", label: "Necrophos" },
    { value: "Oracle", label: "Oracle" },
    { value: "Outworld Destroyer", label: "Outworld Destroyer" },
    { value: "Puck", label: "Puck" },
    { value: "Pugna", label: "Pugna" },
    { value: "Queen of Pain", label: "Queen of Pain" },
    { value: "Ringmaster", label: "Ringmaster"},
    { value: "Rubick", label: "Rubick" },
    { value: "Shadow Demon", label: "Shadow Demon" },
    { value: "Shadow Shaman", label: "Shadow Shaman" },
    { value: "Silencer", label: "Silencer" },
    { value: "Skywrath Mage", label: "Skywrath Mage" },
    { value: "Storm Spirit", label: "Storm Spirit" },
    { value: "Tinker", label: "Tinker" },
    { value: "Warlock", label: "Warlock" },
    { value: "Witch Doctor", label: "Witch Doctor" },
    { value: "Zeus", label: "Zeus" },
    { value: "Abaddon", label: "Abaddon" },
    { value: "Bane", label: "Bane" },
    { value: "Batrider", label: "Batrider" },
    { value: "Beastmaster", label: "Beastmaster" },
    { value: "Brewmaster", label: "Brewmaster" },
    { value: "Broodmother", label: "Broodmother" },
    { value: "Chen", label: "Chen" },
    { value: "Clockwerk", label: "Clockwerk" },
    { value: "Dark Seer", label: "Dark Seer" },
    { value: "Dark Willow", label: "Dark Willow" },
    { value: "Dazzle", label: "Dazzle" },
    { value: "Enigma", label: "Enigma" },
    { value: "Invoker", label: "Invoker" },
    { value: "Io", label: "Io" },
    { value: "Lone Druid", label: "Lone Druid" },
    { value: "Lycan", label: "Lycan" },
    { value: "Magnus", label: "Magnus" },
    { value: "Marci", label: "Marci" },
    { value: "Mirana", label: "Mirana" },
    { value: "Nyx Assassin", label: "Nyx Assassin" },
    { value: "Pangolier", label: "Pangolier" },
    { value: "Phoenix", label: "Phoenix" },
    { value: "Sand King", label: "Sand King" },
    { value: "Snapfire", label: "Snapfire" },
    { value: "Techies", label: "Techies" },
    { value: "Timbersaw", label: "Timbersaw" },
    { value: "Vengeful Spirit", label: "Vengeful Spirit" },
    { value: "Venomancer", label: "Venomancer" },
    { value: "Visage", label: "Visage" },
    { value: "Void Spirit", label: "Void Spirit" },
    { value: "Windranger", label: "Windranger" },
    { value: "Winter Wyvern", label: "Winter Wyvern" },
]

const allColumns = {
    "Alchemist": {
        name: "Alchemist",
        selector: row => row["Alchemist"],
        sortable: true,
    },
    "Axe": {
        name: "Axe",
        selector: row => row["Axe"],
        sortable: true,
    },
    "Bristleback": {
        name: "Bristleback",
        selector: row => row["Bristleback"],
        sortable: true,
    },
    "Centaur Warrunner": {
        name: "Centaur Warrunner",
        selector: row => row["Centaur Warrunner"],
        sortable: true,
    },
    "Chaos Knight": {
        name: "Chaos Knight",
        selector: row => row["Chaos Knight"],
        sortable: true,
    },
    "Dawnbreaker": {
        name: "Dawnbreaker",
        selector: row => row["Dawnbreaker"],
        sortable: true,
    },
    "Doom": {
        name: "Doom",
        selector: row => row["Doom"],
        sortable: true,
    },
    "Dragon Knight": {
        name: "Dragon Knight",
        selector: row => row["Dragon Knight"],
        sortable: true,
    },
    "Earth Spirit": {
        name: "Earth Spirit",
        selector: row => row["Earth Spirit"],
        sortable: true,
    },
    "Earthshaker": {
        name: "Earthshaker",
        selector: row => row["Earthshaker"],
        sortable: true,
    },
    "Elder Titan": {
        name: "Elder Titan",
        selector: row => row["Elder Titan"],
        sortable: true,
    },
    "Huskar": {
        name: "Huskar",
        selector: row => row["Huskar"],
        sortable: true,
    },
    "Kunkka": {
        name: "Kunkka",
        selector: row => row["Kunkka"],
        sortable: true,
    },
    "Legion Commander": {
        name: "Legion Commander",
        selector: row => row["Legion Commander"],
        sortable: true,
    },
    "Lifestealer": {
        name: "Lifestealer",
        selector: row => row["Lifestealer"],
        sortable: true,
    },
    "Mars": {
        name: "Mars",
        selector: row => row["Mars"],
        sortable: true,
    },
    "Night Stalker": {
        name: "Night Stalker",
        selector: row => row["Night Stalker"],
        sortable: true,
    },
    "Ogre Magi": {
        name: "Ogre Magi",
        selector: row => row["Ogre Magi"],
        sortable: true,
    },
    "Omniknight": {
        name: "Omniknight",
        selector: row => row["Omniknight"],
        sortable: true,
    },
    "Primal Beast": {
        name: "Primal Beast",
        selector: row => row["Primal Beast"],
        sortable: true,
    },
    "Pudge": {
        name: "Pudge",
        selector: row => row["Pudge"],
        sortable: true,
    },
    "Slardar": {
        name: "Slardar",
        selector: row => row["Slardar"],
        sortable: true,
    },
    "Spirit Breaker": {
        name: "Spirit Breaker",
        selector: row => row["Spirit Breaker"],
        sortable: true,
    },
    "Sven": {
        name: "Sven",
        selector: row => row["Sven"],
        sortable: true,
    },
    "Tidehunter": {
        name: "Tidehunter",
        selector: row => row["Tidehunter"],
        sortable: true,
    },
    "Tiny": {
        name: "Tiny",
        selector: row => row["Tiny"],
        sortable: true,
    },
    "Treant Protector": {
        name: "Treant Protector",
        selector: row => row["Treant Protector"],
        sortable: true,
    },
    "Tusk": {
        name: "Tusk",
        selector: row => row["Tusk"],
        sortable: true,
    },
    "Underlord": {
        name: "Underlord",
        selector: row => row["Underlord"],
        sortable: true,
    },
    "Undying": {
        name: "Undying",
        selector: row => row["Undying"],
        sortable: true,
    },
    "Wraith King": {
        name: "Wraith King",
        selector: row => row["Wraith King"],
        sortable: true,
    },
    "Anti-Mage": {
        name: "Anti-Mage",
        selector: row => row["Anti-Mage"],
        sortable: true,
    },
    "Arc Warden": {
        name: "Arc Warden",
        selector: row => row["Arc Warden"],
        sortable: true,
    },
    "Bloodseeker": {
        name: "Bloodseeker",
        selector: row => row["Bloodseeker"],
        sortable: true,
    },
    "Bounty Hunter": {
        name: "Bounty Hunter",
        selector: row => row["Bounty Hunter"],
        sortable: true,
    },
    "Clinkz": {
        name: "Clinkz",
        selector: row => row["Clinkz"],
        sortable: true,
    },
    "Drow Ranger": {
        name: "Drow Ranger",
        selector: row => row["Drow Ranger"],
        sortable: true,
    },
    "Ember Spirit": {
        name: "Ember Spirit",
        selector: row => row["Ember Spirit"],
        sortable: true,
    },
    "Faceless Void": {
        name: "Faceless Void",
        selector: row => row["Faceless Void"],
        sortable: true,
    },
    "Gyrocopter": {
        name: "Gyrocopter",
        selector: row => row["Gyrocopter"],
        sortable: true,
    },
    "Hoodwink": {
        name: "Hoodwink",
        selector: row => row["Hoodwink"],
        sortable: true,
    },
    "Juggernaut": {
        name: "Juggernaut",
        selector: row => row["Juggernaut"],
        sortable: true,
    },
    "Kez": {
        name: "Kez",
        selector: row => row["Kez"],
        sortable: true,
    },
    "Luna": {
        name: "Luna",
        selector: row => row["Luna"],
        sortable: true,
    },
    "Medusa": {
        name: "Medusa",
        selector: row => row["Medusa"],
        sortable: true,
    },
    "Meepo": {
        name: "Meepo",
        selector: row => row["Meepo"],
        sortable: true,
    },
    "Monkey King": {
        name: "Monkey King",
        selector: row => row["Monkey King"],
        sortable: true,
    },
    "Morphling": {
        name: "Morphling",
        selector: row => row["Morphling"],
        sortable: true,
    },
    "Naga Siren": {
        name: "Naga Siren",
        selector: row => row["Naga Siren"],
        sortable: true,
    },
    "Phantom Assassin": {
        name: "Phantom Assassin",
        selector: row => row["Phantom Assassin"],
        sortable: true,
    },
    "Phantom Lancer": {
        name: "Phantom Lancer",
        selector: row => row["Phantom Lancer"],
        sortable: true,
    },
    "Razor": {
        name: "Razor",
        selector: row => row["Razor"],
        sortable: true,
    },
    "Riki": {
        name: "Riki",
        selector: row => row["Riki"],
        sortable: true,
    },
    "Shadow Fiend": {
        name: "Shadow Fiend",
        selector: row => row["Shadow Fiend"],
        sortable: true,
    },
    "Slark": {
        name: "Slark",
        selector: row => row["Slark"],
        sortable: true,
    },
    "Sniper": {
        name: "Sniper",
        selector: row => row["Sniper"],
        sortable: true,
    },
    "Spectre": {
        name: "Spectre",
        selector: row => row["Spectre"],
        sortable: true,
    },
    "Templar Assassin": {
        name: "Templar Assassin",
        selector: row => row["Templar Assassin"],
        sortable: true,
    },
    "Terrorblade": {
        name: "Terrorblade",
        selector: row => row["Terrorblade"],
        sortable: true,
    },
    "Troll Warlord": {
        name: "Troll Warlord",
        selector: row => row["Troll Warlord"],
        sortable: true,
    },
    "Ursa": {
        name: "Ursa",
        selector: row => row["Ursa"],
        sortable: true,
    },
    "Viper": {
        name: "Viper",
        selector: row => row["Viper"],
        sortable: true,
    },
    "Weaver": {
        name: "Weaver",
        selector: row => row["Weaver"],
        sortable: true,
    },
    "Ancient Apparition": {
        name: "Ancient Apparition",
        selector: row => row["Ancient Apparition"],
        sortable: true,
    },
    "Crystal Maiden": {
        name: "Crystal Maiden",
        selector: row => row["Crystal Maiden"],
        sortable: true,
    },
    "Death Prophet": {
        name: "Death Prophet",
        selector: row => row["Death Prophet"],
        sortable: true,
    },
    "Disruptor": {
        name: "Disruptor",
        selector: row => row["Disruptor"],
        sortable: true,
    },
    "Enchantress": {
        name: "Enchantress",
        selector: row => row["Enchantress"],
        sortable: true,
    },
    "Grimstroke": {
        name: "Grimstroke",
        selector: row => row["Grimstroke"],
        sortable: true,
    },
    "Jakiro": {
        name: "Jakiro",
        selector: row => row["Jakiro"],
        sortable: true,
    },
    "Keeper of the Light": {
        name: "Keeper of the Light",
        selector: row => row["Keeper of the Light"],
        sortable: true,
    },
    "Leshrac": {
        name: "Leshrac",
        selector: row => row["Leshrac"],
        sortable: true,
    },
    "Lich": {
        name: "Lich",
        selector: row => row["Lich"],
        sortable: true,
    },
    "Lina": {
        name: "Lina",
        selector: row => row["Lina"],
        sortable: true,
    },
    "Lion": {
        name: "Lion",
        selector: row => row["Lion"],
        sortable: true,
    },
    "Muerta": {
        name: "Muerta",
        selector: row => row["Muerta"],
        sortable: true,
    },
    "Nature's Prophet": {
        name: "Nature's Prophet",
        selector: row => row["Nature's Prophet"],
        sortable: true,
    },
    "Necrophos": {
        name: "Necrophos",
        selector: row => row["Necrophos"],
        sortable: true,
    },
    "Oracle": {
        name: "Oracle",
        selector: row => row["Oracle"],
        sortable: true,
    },
    "Outworld Destroyer": {
        name: "Outworld Destroyer",
        selector: row => row["Outworld Destroyer"],
        sortable: true,
    },
    "Puck": {
        name: "Puck",
        selector: row => row["Puck"],
        sortable: true,
    },
    "Pugna": {
        name: "Pugna",
        selector: row => row["Pugna"],
        sortable: true,
    },
    "Queen of Pain": {
        name: "Queen of Pain",
        selector: row => row["Queen of Pain"],
        sortable: true,
    },
    "Ringmaster": {
        name: "Ringmaster",
        selector: row => row["Ringmaster"],
        sortable: true,
    },
    "Rubick": {
        name: "Rubick",
        selector: row => row["Rubick"],
        sortable: true,
    },
    "Shadow Demon": {
        name: "Shadow Demon",
        selector: row => row["Shadow Demon"],
        sortable: true,
    },
    "Shadow Shaman": {
        name: "Shadow Shaman",
        selector: row => row["Shadow Shaman"],
        sortable: true,
    },
    "Silencer": {
        name: "Silencer",
        selector: row => row["Silencer"],
        sortable: true,
    },
    "Skywrath Mage": {
        name: "Skywrath Mage",
        selector: row => row["Skywrath Mage"],
        sortable: true,
    },
    "Storm Spirit": {
        name: "Storm Spirit",
        selector: row => row["Storm Spirit"],
        sortable: true,
    },
    "Tinker": {
        name: "Tinker",
        selector: row => row["Tinker"],
        sortable: true,
    },
    "Warlock": {
        name: "Warlock",
        selector: row => row["Warlock"],
        sortable: true,
    },
    "Witch Doctor": {
        name: "Witch Doctor",
        selector: row => row["Witch Doctor"],
        sortable: true,
    },
    "Zeus": {
        name: "Zeus",
        selector: row => row["Zeus"],
        sortable: true,
    },
    "Abaddon": {
        name: "Abaddon",
        selector: row => row["Abaddon"],
        sortable: true,
    },
    "Bane": {
        name: "Bane",
        selector: row => row["Bane"],
        sortable: true,
    },
    "Batrider": {
        name: "Batrider",
        selector: row => row["Batrider"],
        sortable: true,
    },
    "Beastmaster": {
        name: "Beastmaster",
        selector: row => row["Beastmaster"],
        sortable: true,
    },
    "Brewmaster": {
        name: "Brewmaster",
        selector: row => row["Brewmaster"],
        sortable: true,
    },
    "Broodmother": {
        name: "Broodmother",
        selector: row => row["Broodmother"],
        sortable: true,
    },
    "Chen": {
        name: "Chen",
        selector: row => row["Chen"],
        sortable: true,
    },
    "Clockwerk": {
        name: "Clockwerk",
        selector: row => row["Clockwerk"],
        sortable: true,
    },
    "Dark Seer": {
        name: "Dark Seer",
        selector: row => row["Dark Seer"],
        sortable: true,
    },
    "Dark Willow": {
        name: "Dark Willow",
        selector: row => row["Dark Willow"],
        sortable: true,
    },
    "Dazzle": {
        name: "Dazzle",
        selector: row => row["Dazzle"],
        sortable: true,
    },
    "Enigma": {
        name: "Enigma",
        selector: row => row["Enigma"],
        sortable: true,
    },
    "Invoker": {
        name: "Invoker",
        selector: row => row["Invoker"],
        sortable: true,
    },
    "Io": {
        name: "Io",
        selector: row => row["Io"],
        sortable: true,
    },
    "Lone Druid": {
        name: "Lone Druid",
        selector: row => row["Lone Druid"],
        sortable: true,
    },
    "Lycan": {
        name: "Lycan",
        selector: row => row["Lycan"],
        sortable: true,
    },
    "Magnus": {
        name: "Magnus",
        selector: row => row["Magnus"],
        sortable: true,
    },
    "Marci": {
        name: "Marci",
        selector: row => row["Marci"],
        sortable: true,
    },
    "Mirana": {
        name: "Mirana",
        selector: row => row["Mirana"],
        sortable: true,
    },
    "Nyx Assassin": {
        name: "Nyx Assassin",
        selector: row => row["Nyx Assassin"],
        sortable: true,
    },
    "Pangolier": {
        name: "Pangolier",
        selector: row => row["Pangolier"],
        sortable: true,
    },
    "Phoenix": {
        name: "Phoenix",
        selector: row => row["Phoenix"],
        sortable: true,
    },
    "Sand King": {
        name: "Sand King",
        selector: row => row["Sand King"],
        sortable: true,
    },
    "Snapfire": {
        name: "Snapfire",
        selector: row => row["Snapfire"],
        sortable: true,
    },
    "Techies": {
        name: "Techies",
        selector: row => row["Techies"],
        sortable: true,
    },
    "Timbersaw": {
        name: "Timbersaw",
        selector: row => row["Timbersaw"],
        sortable: true,
    },
    "Vengeful Spirit": {
        name: "Vengeful Spirit",
        selector: row => row["Vengeful Spirit"],
        sortable: true,
    },
    "Venomancer": {
        name: "Venomancer",
        selector: row => row["Venomancer"],
        sortable: true,
    },
    "Visage": {
        name: "Visage",
        selector: row => row["Visage"],
        sortable: true,
    },
    "Void Spirit": {
        name: "Void Spirit",
        selector: row => row["Void Spirit"],
        sortable: true,
    },
    "Windranger": {
        name: "Windranger",
        selector: row => row["Windranger"],
        sortable: true,
    },
    "Winter Wyvern": {
        name: "Winter Wyvern",
        selector: row => row["Winter Wyvern"],
        sortable: true,
    },
}


const allData = {
    "Alchemist": [
        {
            "name": "Abaddon",
            "Alchemist": -1.145
        },
        {
            "name": "Ancient Apparition",
            "Alchemist": 2.586
        },
        {
            "name": "Anti-Mage",
            "Alchemist": 0.643
        },
        {
            "name": "Arc Warden",
            "Alchemist": 0.876
        },
        {
            "name": "Axe",
            "Alchemist": -0.126
        },
        {
            "name": "Bane",
            "Alchemist": 1.171
        },
        {
            "name": "Batrider",
            "Alchemist": 1.64
        },
        {
            "name": "Beastmaster",
            "Alchemist": -1.629
        },
        {
            "name": "Bloodseeker",
            "Alchemist": 0.201
        },
        {
            "name": "Bounty Hunter",
            "Alchemist": 0.895
        },
        {
            "name": "Brewmaster",
            "Alchemist": -1.043
        },
        {
            "name": "Bristleback",
            "Alchemist": 1.426
        },
        {
            "name": "Broodmother",
            "Alchemist": -0.498
        },
        {
            "name": "Centaur Warrunner",
            "Alchemist": -1.75
        },
        {
            "name": "Chaos Knight",
            "Alchemist": -0.966
        },
        {
            "name": "Chen",
            "Alchemist": 0.133
        },
        {
            "name": "Clinkz",
            "Alchemist": 1.523
        },
        {
            "name": "Clockwerk",
            "Alchemist": -0.321
        },
        {
            "name": "Crystal Maiden",
            "Alchemist": 0.315
        },
        {
            "name": "Dark Seer",
            "Alchemist": -0.111
        },
        {
            "name": "Dark Willow",
            "Alchemist": -0.262
        },
        {
            "name": "Dawnbreaker",
            "Alchemist": -0.359
        },
        {
            "name": "Dazzle",
            "Alchemist": 0.639
        },
        {
            "name": "Death Prophet",
            "Alchemist": 1.541
        },
        {
            "name": "Disruptor",
            "Alchemist": -0.56
        },
        {
            "name": "Doom",
            "Alchemist": 2.125
        },
        {
            "name": "Dragon Knight",
            "Alchemist": -1.145
        },
        {
            "name": "Drow Ranger",
            "Alchemist": 0.294
        },
        {
            "name": "Earth Spirit",
            "Alchemist": -0.324
        },
        {
            "name": "Earthshaker",
            "Alchemist": -0.611
        },
        {
            "name": "Elder Titan",
            "Alchemist": 0.09
        },
        {
            "name": "Ember Spirit",
            "Alchemist": -0.727
        },
        {
            "name": "Enchantress",
            "Alchemist": 0.429
        },
        {
            "name": "Enigma",
            "Alchemist": -2.309
        },
        {
            "name": "Faceless Void",
            "Alchemist": -0.119
        },
        {
            "name": "Grimstroke",
            "Alchemist": 0.442
        },
        {
            "name": "Gyrocopter",
            "Alchemist": 0.4
        },
        {
            "name": "Hoodwink",
            "Alchemist": 0.181
        },
        {
            "name": "Huskar",
            "Alchemist": 0.959
        },
        {
            "name": "Invoker",
            "Alchemist": 0.492
        },
        {
            "name": "Io",
            "Alchemist": 0.105
        },
        {
            "name": "Jakiro",
            "Alchemist": -0.097
        },
        {
            "name": "Juggernaut",
            "Alchemist": 1.644
        },
        {
            "name": "Keeper of the Light",
            "Alchemist": 0.369
        },
        {
            "name": "Kez",
            "Alchemist": 1.076
        },
        {
            "name": "Kunkka",
            "Alchemist": -0.59
        },
        {
            "name": "Legion Commander",
            "Alchemist": 0.357
        },
        {
            "name": "Leshrac",
            "Alchemist": 1.057
        },
        {
            "name": "Lich",
            "Alchemist": 0.695
        },
        {
            "name": "Lifestealer",
            "Alchemist": 0.311
        },
        {
            "name": "Lina",
            "Alchemist": 1.224
        },
        {
            "name": "Lion",
            "Alchemist": -0.042
        },
        {
            "name": "Lone Druid",
            "Alchemist": -1.894
        },
        {
            "name": "Luna",
            "Alchemist": 0.933
        },
        {
            "name": "Lycan",
            "Alchemist": -1.932
        },
        {
            "name": "Magnus",
            "Alchemist": -0.972
        },
        {
            "name": "Marci",
            "Alchemist": 1.11
        },
        {
            "name": "Mars",
            "Alchemist": -1.325
        },
        {
            "name": "Medusa",
            "Alchemist": -2.941
        },
        {
            "name": "Meepo",
            "Alchemist": -1.772
        },
        {
            "name": "Mirana",
            "Alchemist": 0.194
        },
        {
            "name": "Monkey King",
            "Alchemist": 1.363
        },
        {
            "name": "Morphling",
            "Alchemist": 0.166
        },
        {
            "name": "Muerta",
            "Alchemist": 0.272
        },
        {
            "name": "Naga Siren",
            "Alchemist": -0.419
        },
        {
            "name": "Nature's Prophet",
            "Alchemist": 0.988
        },
        {
            "name": "Necrophos",
            "Alchemist": 0.114
        },
        {
            "name": "Night Stalker",
            "Alchemist": 0.01
        },
        {
            "name": "Nyx Assassin",
            "Alchemist": 0.48
        },
        {
            "name": "Ogre Magi",
            "Alchemist": 0.066
        },
        {
            "name": "Omniknight",
            "Alchemist": 0.537
        },
        {
            "name": "Oracle",
            "Alchemist": -1.355
        },
        {
            "name": "Outworld Destroyer",
            "Alchemist": 0.387
        },
        {
            "name": "Pangolier",
            "Alchemist": 0.046
        },
        {
            "name": "Phantom Assassin",
            "Alchemist": 0.668
        },
        {
            "name": "Phantom Lancer",
            "Alchemist": -1.255
        },
        {
            "name": "Phoenix",
            "Alchemist": -0.455
        },
        {
            "name": "Primal Beast",
            "Alchemist": 0.353
        },
        {
            "name": "Puck",
            "Alchemist": -1.783
        },
        {
            "name": "Pudge",
            "Alchemist": -0.388
        },
        {
            "name": "Pugna",
            "Alchemist": -0.809
        },
        {
            "name": "Queen of Pain",
            "Alchemist": 1.379
        },
        {
            "name": "Razor",
            "Alchemist": 1.692
        },
        {
            "name": "Riki",
            "Alchemist": -1.284
        },
        {
            "name": "Ringmaster",
            "Alchemist": -0.445
        },
        {
            "name": "Rubick",
            "Alchemist": -0.401
        },
        {
            "name": "Sand King",
            "Alchemist": 0.362
        },
        {
            "name": "Shadow Demon",
            "Alchemist": -0.004
        },
        {
            "name": "Shadow Fiend",
            "Alchemist": 0.031
        },
        {
            "name": "Shadow Shaman",
            "Alchemist": -1.303
        },
        {
            "name": "Silencer",
            "Alchemist": -1.478
        },
        {
            "name": "Skywrath Mage",
            "Alchemist": 0.651
        },
        {
            "name": "Slardar",
            "Alchemist": 0.479
        },
        {
            "name": "Slark",
            "Alchemist": 0.384
        },
        {
            "name": "Snapfire",
            "Alchemist": 0.026
        },
        {
            "name": "Sniper",
            "Alchemist": 0.516
        },
        {
            "name": "Spectre",
            "Alchemist": -0.669
        },
        {
            "name": "Spirit Breaker",
            "Alchemist": 0.02
        },
        {
            "name": "Storm Spirit",
            "Alchemist": -0.23
        },
        {
            "name": "Sven",
            "Alchemist": -0.927
        },
        {
            "name": "Techies",
            "Alchemist": 0.801
        },
        {
            "name": "Templar Assassin",
            "Alchemist": -1.13
        },
        {
            "name": "Terrorblade",
            "Alchemist": 1.163
        },
        {
            "name": "Tidehunter",
            "Alchemist": -1.101
        },
        {
            "name": "Timbersaw",
            "Alchemist": 1.121
        },
        {
            "name": "Tinker",
            "Alchemist": 0.604
        },
        {
            "name": "Tiny",
            "Alchemist": -1.488
        },
        {
            "name": "Treant Protector",
            "Alchemist": -0.042
        },
        {
            "name": "Troll Warlord",
            "Alchemist": -0.29
        },
        {
            "name": "Tusk",
            "Alchemist": 0.361
        },
        {
            "name": "Underlord",
            "Alchemist": -1.103
        },
        {
            "name": "Undying",
            "Alchemist": -0.517
        },
        {
            "name": "Ursa",
            "Alchemist": -0.352
        },
        {
            "name": "Vengeful Spirit",
            "Alchemist": 0.209
        },
        {
            "name": "Venomancer",
            "Alchemist": 1.382
        },
        {
            "name": "Viper",
            "Alchemist": 0.865
        },
        {
            "name": "Visage",
            "Alchemist": -1.468
        },
        {
            "name": "Void Spirit",
            "Alchemist": -1.478
        },
        {
            "name": "Warlock",
            "Alchemist": -0.265
        },
        {
            "name": "Weaver",
            "Alchemist": -0.121
        },
        {
            "name": "Windranger",
            "Alchemist": -0.076
        },
        {
            "name": "Winter Wyvern",
            "Alchemist": 0.251
        },
        {
            "name": "Witch Doctor",
            "Alchemist": 0.205
        },
        {
            "name": "Wraith King",
            "Alchemist": -2.745
        },
        {
            "name": "Zeus",
            "Alchemist": -0.301
        }
    ],
    "Axe": [
        {
            "name": "Abaddon",
            "Axe": 2.114
        },
        {
            "name": "Alchemist",
            "Axe": 0.405
        },
        {
            "name": "Ancient Apparition",
            "Axe": 1.081
        },
        {
            "name": "Anti-Mage",
            "Axe": -4.438
        },
        {
            "name": "Arc Warden",
            "Axe": -2.18
        },
        {
            "name": "Bane",
            "Axe": 0.516
        },
        {
            "name": "Batrider",
            "Axe": 2.196
        },
        {
            "name": "Beastmaster",
            "Axe": 1.122
        },
        {
            "name": "Bloodseeker",
            "Axe": 1.024
        },
        {
            "name": "Bounty Hunter",
            "Axe": 0.135
        },
        {
            "name": "Brewmaster",
            "Axe": 0.325
        },
        {
            "name": "Bristleback",
            "Axe": -2.057
        },
        {
            "name": "Broodmother",
            "Axe": -3.82
        },
        {
            "name": "Centaur Warrunner",
            "Axe": 1.376
        },
        {
            "name": "Chaos Knight",
            "Axe": -1.899
        },
        {
            "name": "Chen",
            "Axe": -0.296
        },
        {
            "name": "Clinkz",
            "Axe": -1.405
        },
        {
            "name": "Clockwerk",
            "Axe": 0.214
        },
        {
            "name": "Crystal Maiden",
            "Axe": -0.134
        },
        {
            "name": "Dark Seer",
            "Axe": -0.871
        },
        {
            "name": "Dark Willow",
            "Axe": -1.681
        },
        {
            "name": "Dawnbreaker",
            "Axe": 0.639
        },
        {
            "name": "Dazzle",
            "Axe": -0.734
        },
        {
            "name": "Death Prophet",
            "Axe": 1.988
        },
        {
            "name": "Disruptor",
            "Axe": 0.011
        },
        {
            "name": "Doom",
            "Axe": 2.666
        },
        {
            "name": "Dragon Knight",
            "Axe": 2.302
        },
        {
            "name": "Drow Ranger",
            "Axe": -0.202
        },
        {
            "name": "Earth Spirit",
            "Axe": 1.895
        },
        {
            "name": "Earthshaker",
            "Axe": -0.099
        },
        {
            "name": "Elder Titan",
            "Axe": -0.486
        },
        {
            "name": "Ember Spirit",
            "Axe": 1.736
        },
        {
            "name": "Enchantress",
            "Axe": -0.2
        },
        {
            "name": "Enigma",
            "Axe": -0.693
        },
        {
            "name": "Faceless Void",
            "Axe": -0.203
        },
        {
            "name": "Grimstroke",
            "Axe": 0.886
        },
        {
            "name": "Gyrocopter",
            "Axe": 1.188
        },
        {
            "name": "Hoodwink",
            "Axe": -0.836
        },
        {
            "name": "Huskar",
            "Axe": -1.301
        },
        {
            "name": "Invoker",
            "Axe": -0.027
        },
        {
            "name": "Io",
            "Axe": 0.423
        },
        {
            "name": "Jakiro",
            "Axe": 0.972
        },
        {
            "name": "Juggernaut",
            "Axe": -0.425
        },
        {
            "name": "Keeper of the Light",
            "Axe": 1.461
        },
        {
            "name": "Kez",
            "Axe": -0.815
        },
        {
            "name": "Kunkka",
            "Axe": 1.086
        },
        {
            "name": "Legion Commander",
            "Axe": -1.056
        },
        {
            "name": "Leshrac",
            "Axe": 2.563
        },
        {
            "name": "Lich",
            "Axe": -0.021
        },
        {
            "name": "Lifestealer",
            "Axe": 1.903
        },
        {
            "name": "Lina",
            "Axe": -0.778
        },
        {
            "name": "Lion",
            "Axe": -0.174
        },
        {
            "name": "Lone Druid",
            "Axe": -0.215
        },
        {
            "name": "Luna",
            "Axe": 1.254
        },
        {
            "name": "Lycan",
            "Axe": -1.731
        },
        {
            "name": "Magnus",
            "Axe": 0.52
        },
        {
            "name": "Marci",
            "Axe": -1.096
        },
        {
            "name": "Mars",
            "Axe": 1.131
        },
        {
            "name": "Medusa",
            "Axe": -0.99
        },
        {
            "name": "Meepo",
            "Axe": -0.659
        },
        {
            "name": "Mirana",
            "Axe": -0.687
        },
        {
            "name": "Monkey King",
            "Axe": 1.457
        },
        {
            "name": "Morphling",
            "Axe": -1.85
        },
        {
            "name": "Muerta",
            "Axe": -0.55
        },
        {
            "name": "Naga Siren",
            "Axe": -3.7
        },
        {
            "name": "Nature's Prophet",
            "Axe": 0.497
        },
        {
            "name": "Necrophos",
            "Axe": 2.684
        },
        {
            "name": "Night Stalker",
            "Axe": 1.139
        },
        {
            "name": "Nyx Assassin",
            "Axe": -0.45
        },
        {
            "name": "Ogre Magi",
            "Axe": 0.829
        },
        {
            "name": "Omniknight",
            "Axe": 0.48
        },
        {
            "name": "Oracle",
            "Axe": -0.384
        },
        {
            "name": "Outworld Destroyer",
            "Axe": 3.001
        },
        {
            "name": "Pangolier",
            "Axe": -0.048
        },
        {
            "name": "Phantom Assassin",
            "Axe": -3.417
        },
        {
            "name": "Phantom Lancer",
            "Axe": -2.863
        },
        {
            "name": "Phoenix",
            "Axe": 2.055
        },
        {
            "name": "Primal Beast",
            "Axe": 2.502
        },
        {
            "name": "Puck",
            "Axe": -0.393
        },
        {
            "name": "Pudge",
            "Axe": 1.975
        },
        {
            "name": "Pugna",
            "Axe": 1.406
        },
        {
            "name": "Queen of Pain",
            "Axe": 1.613
        },
        {
            "name": "Razor",
            "Axe": 1.264
        },
        {
            "name": "Riki",
            "Axe": -1.199
        },
        {
            "name": "Ringmaster",
            "Axe": 1.712
        },
        {
            "name": "Rubick",
            "Axe": -0.62
        },
        {
            "name": "Sand King",
            "Axe": 1.718
        },
        {
            "name": "Shadow Demon",
            "Axe": 1.225
        },
        {
            "name": "Shadow Fiend",
            "Axe": 0.982
        },
        {
            "name": "Shadow Shaman",
            "Axe": -0.852
        },
        {
            "name": "Silencer",
            "Axe": 1.214
        },
        {
            "name": "Skywrath Mage",
            "Axe": 1.569
        },
        {
            "name": "Slardar",
            "Axe": 0.112
        },
        {
            "name": "Slark",
            "Axe": -0.121
        },
        {
            "name": "Snapfire",
            "Axe": 0.748
        },
        {
            "name": "Sniper",
            "Axe": -1.402
        },
        {
            "name": "Spectre",
            "Axe": 2.347
        },
        {
            "name": "Spirit Breaker",
            "Axe": 1.031
        },
        {
            "name": "Storm Spirit",
            "Axe": -0.944
        },
        {
            "name": "Sven",
            "Axe": 0.389
        },
        {
            "name": "Techies",
            "Axe": 0.752
        },
        {
            "name": "Templar Assassin",
            "Axe": 0.191
        },
        {
            "name": "Terrorblade",
            "Axe": -3.245
        },
        {
            "name": "Tidehunter",
            "Axe": 0.733
        },
        {
            "name": "Timbersaw",
            "Axe": 3.048
        },
        {
            "name": "Tinker",
            "Axe": 1.972
        },
        {
            "name": "Tiny",
            "Axe": 2.274
        },
        {
            "name": "Treant Protector",
            "Axe": 1.372
        },
        {
            "name": "Troll Warlord",
            "Axe": -4.647
        },
        {
            "name": "Tusk",
            "Axe": 0.377
        },
        {
            "name": "Underlord",
            "Axe": 0.908
        },
        {
            "name": "Undying",
            "Axe": 0.463
        },
        {
            "name": "Ursa",
            "Axe": 1.328
        },
        {
            "name": "Vengeful Spirit",
            "Axe": 0.383
        },
        {
            "name": "Venomancer",
            "Axe": 2.778
        },
        {
            "name": "Viper",
            "Axe": 1.284
        },
        {
            "name": "Visage",
            "Axe": -2.262
        },
        {
            "name": "Void Spirit",
            "Axe": -1.068
        },
        {
            "name": "Warlock",
            "Axe": 2.016
        },
        {
            "name": "Weaver",
            "Axe": -1.278
        },
        {
            "name": "Windranger",
            "Axe": -1.053
        },
        {
            "name": "Winter Wyvern",
            "Axe": -0.006
        },
        {
            "name": "Witch Doctor",
            "Axe": 2.413
        },
        {
            "name": "Wraith King",
            "Axe": 1.086
        },
        {
            "name": "Zeus",
            "Axe": 1.669
        }
    ],
    "Bristleback": [
        {
            "name": "Abaddon",
            "Bristleback": 0.553
        },
        {
            "name": "Alchemist",
            "Bristleback": -1.426
        },
        {
            "name": "Ancient Apparition",
            "Bristleback": 2.491
        },
        {
            "name": "Anti-Mage",
            "Bristleback": 0.881
        },
        {
            "name": "Arc Warden",
            "Bristleback": -2.254
        },
        {
            "name": "Axe",
            "Bristleback": 2.519
        },
        {
            "name": "Bane",
            "Bristleback": 0.006
        },
        {
            "name": "Batrider",
            "Bristleback": 0.497
        },
        {
            "name": "Beastmaster",
            "Bristleback": -3.725
        },
        {
            "name": "Bloodseeker",
            "Bristleback": -2.568
        },
        {
            "name": "Bounty Hunter",
            "Bristleback": 1.348
        },
        {
            "name": "Brewmaster",
            "Bristleback": -1.45
        },
        {
            "name": "Broodmother",
            "Bristleback": -2.746
        },
        {
            "name": "Centaur Warrunner",
            "Bristleback": -2.845
        },
        {
            "name": "Chaos Knight",
            "Bristleback": -1.425
        },
        {
            "name": "Chen",
            "Bristleback": -0.004
        },
        {
            "name": "Clinkz",
            "Bristleback": -3.002
        },
        {
            "name": "Clockwerk",
            "Bristleback": 0.806
        },
        {
            "name": "Crystal Maiden",
            "Bristleback": -1.603
        },
        {
            "name": "Dark Seer",
            "Bristleback": -0.745
        },
        {
            "name": "Dark Willow",
            "Bristleback": -0.932
        },
        {
            "name": "Dawnbreaker",
            "Bristleback": -2.784
        },
        {
            "name": "Dazzle",
            "Bristleback": 1.22
        },
        {
            "name": "Death Prophet",
            "Bristleback": -1.436
        },
        {
            "name": "Disruptor",
            "Bristleback": 1.138
        },
        {
            "name": "Doom",
            "Bristleback": -0.231
        },
        {
            "name": "Dragon Knight",
            "Bristleback": 2.686
        },
        {
            "name": "Drow Ranger",
            "Bristleback": 3.001
        },
        {
            "name": "Earth Spirit",
            "Bristleback": 1.757
        },
        {
            "name": "Earthshaker",
            "Bristleback": -1.414
        },
        {
            "name": "Elder Titan",
            "Bristleback": -2.372
        },
        {
            "name": "Ember Spirit",
            "Bristleback": 2.166
        },
        {
            "name": "Enchantress",
            "Bristleback": -1.591
        },
        {
            "name": "Enigma",
            "Bristleback": -4.567
        },
        {
            "name": "Faceless Void",
            "Bristleback": -0.34
        },
        {
            "name": "Grimstroke",
            "Bristleback": -0.355
        },
        {
            "name": "Gyrocopter",
            "Bristleback": -1.253
        },
        {
            "name": "Hoodwink",
            "Bristleback": 3.513
        },
        {
            "name": "Huskar",
            "Bristleback": -1.641
        },
        {
            "name": "Invoker",
            "Bristleback": -1.122
        },
        {
            "name": "Io",
            "Bristleback": -0.422
        },
        {
            "name": "Jakiro",
            "Bristleback": -0.729
        },
        {
            "name": "Juggernaut",
            "Bristleback": 2.945
        },
        {
            "name": "Keeper of the Light",
            "Bristleback": 0.403
        },
        {
            "name": "Kez",
            "Bristleback": -0.712
        },
        {
            "name": "Kunkka",
            "Bristleback": -1.055
        },
        {
            "name": "Legion Commander",
            "Bristleback": 4.614
        },
        {
            "name": "Leshrac",
            "Bristleback": -2.325
        },
        {
            "name": "Lich",
            "Bristleback": -1.635
        },
        {
            "name": "Lifestealer",
            "Bristleback": -3.5
        },
        {
            "name": "Lina",
            "Bristleback": -1.607
        },
        {
            "name": "Lion",
            "Bristleback": 0.116
        },
        {
            "name": "Lone Druid",
            "Bristleback": -2.453
        },
        {
            "name": "Luna",
            "Bristleback": -2.759
        },
        {
            "name": "Lycan",
            "Bristleback": -2.566
        },
        {
            "name": "Magnus",
            "Bristleback": 0.087
        },
        {
            "name": "Marci",
            "Bristleback": -0.371
        },
        {
            "name": "Mars",
            "Bristleback": 0.465
        },
        {
            "name": "Medusa",
            "Bristleback": -1.378
        },
        {
            "name": "Meepo",
            "Bristleback": -3.588
        },
        {
            "name": "Mirana",
            "Bristleback": 0.369
        },
        {
            "name": "Monkey King",
            "Bristleback": 0.12
        },
        {
            "name": "Morphling",
            "Bristleback": 2.129
        },
        {
            "name": "Muerta",
            "Bristleback": -0.55
        },
        {
            "name": "Naga Siren",
            "Bristleback": 0.058
        },
        {
            "name": "Nature's Prophet",
            "Bristleback": 0.78
        },
        {
            "name": "Necrophos",
            "Bristleback": 1.529
        },
        {
            "name": "Night Stalker",
            "Bristleback": -0.816
        },
        {
            "name": "Nyx Assassin",
            "Bristleback": 0.579
        },
        {
            "name": "Ogre Magi",
            "Bristleback": 1.45
        },
        {
            "name": "Omniknight",
            "Bristleback": -0.433
        },
        {
            "name": "Oracle",
            "Bristleback": -0.544
        },
        {
            "name": "Outworld Destroyer",
            "Bristleback": -1.198
        },
        {
            "name": "Pangolier",
            "Bristleback": -0.194
        },
        {
            "name": "Phantom Assassin",
            "Bristleback": -0.391
        },
        {
            "name": "Phantom Lancer",
            "Bristleback": -0.873
        },
        {
            "name": "Phoenix",
            "Bristleback": -0.494
        },
        {
            "name": "Primal Beast",
            "Bristleback": 3.124
        },
        {
            "name": "Puck",
            "Bristleback": 0.577
        },
        {
            "name": "Pudge",
            "Bristleback": -1.088
        },
        {
            "name": "Pugna",
            "Bristleback": -0.703
        },
        {
            "name": "Queen of Pain",
            "Bristleback": -0.066
        },
        {
            "name": "Razor",
            "Bristleback": -2.302
        },
        {
            "name": "Riki",
            "Bristleback": -5.043
        },
        {
            "name": "Ringmaster",
            "Bristleback": -1.076
        },
        {
            "name": "Rubick",
            "Bristleback": -0.379
        },
        {
            "name": "Sand King",
            "Bristleback": -2.37
        },
        {
            "name": "Shadow Demon",
            "Bristleback": 0.922
        },
        {
            "name": "Shadow Fiend",
            "Bristleback": 0.113
        },
        {
            "name": "Shadow Shaman",
            "Bristleback": 1.234
        },
        {
            "name": "Silencer",
            "Bristleback": 2.018
        },
        {
            "name": "Skywrath Mage",
            "Bristleback": -1.222
        },
        {
            "name": "Slardar",
            "Bristleback": 0.638
        },
        {
            "name": "Slark",
            "Bristleback": 4.569
        },
        {
            "name": "Snapfire",
            "Bristleback": -1.209
        },
        {
            "name": "Sniper",
            "Bristleback": 1.408
        },
        {
            "name": "Spectre",
            "Bristleback": -1.141
        },
        {
            "name": "Spirit Breaker",
            "Bristleback": 1.189
        },
        {
            "name": "Storm Spirit",
            "Bristleback": 2.196
        },
        {
            "name": "Sven",
            "Bristleback": -1.646
        },
        {
            "name": "Techies",
            "Bristleback": -1.298
        },
        {
            "name": "Templar Assassin",
            "Bristleback": -0.75
        },
        {
            "name": "Terrorblade",
            "Bristleback": -0.044
        },
        {
            "name": "Tidehunter",
            "Bristleback": -2.172
        },
        {
            "name": "Timbersaw",
            "Bristleback": -0.619
        },
        {
            "name": "Tinker",
            "Bristleback": 0.141
        },
        {
            "name": "Tiny",
            "Bristleback": -0.318
        },
        {
            "name": "Treant Protector",
            "Bristleback": -0.187
        },
        {
            "name": "Troll Warlord",
            "Bristleback": -1.82
        },
        {
            "name": "Tusk",
            "Bristleback": 0.431
        },
        {
            "name": "Underlord",
            "Bristleback": -1.395
        },
        {
            "name": "Undying",
            "Bristleback": -0.547
        },
        {
            "name": "Ursa",
            "Bristleback": -3.151
        },
        {
            "name": "Vengeful Spirit",
            "Bristleback": 0.343
        },
        {
            "name": "Venomancer",
            "Bristleback": 1.757
        },
        {
            "name": "Viper",
            "Bristleback": 5.107
        },
        {
            "name": "Visage",
            "Bristleback": -3.252
        },
        {
            "name": "Void Spirit",
            "Bristleback": 0.912
        },
        {
            "name": "Warlock",
            "Bristleback": -3.193
        },
        {
            "name": "Weaver",
            "Bristleback": -1.235
        },
        {
            "name": "Windranger",
            "Bristleback": -0.964
        },
        {
            "name": "Winter Wyvern",
            "Bristleback": -0.055
        },
        {
            "name": "Witch Doctor",
            "Bristleback": -2.727
        },
        {
            "name": "Wraith King",
            "Bristleback": -0.968
        },
        {
            "name": "Zeus",
            "Bristleback": -1.366
        }
    ],
    "Centaur Warrunner": [
        {
            "name": "Abaddon",
            "Centaur Warrunner": 0.588
        },
        {
            "name": "Alchemist",
            "Centaur Warrunner": 1.725
        },
        {
            "name": "Ancient Apparition",
            "Centaur Warrunner": -0.841
        },
        {
            "name": "Anti-Mage",
            "Centaur Warrunner": -0.337
        },
        {
            "name": "Arc Warden",
            "Centaur Warrunner": -2.317
        },
        {
            "name": "Axe",
            "Centaur Warrunner": -1.403
        },
        {
            "name": "Bane",
            "Centaur Warrunner": 1.387
        },
        {
            "name": "Batrider",
            "Centaur Warrunner": 0.652
        },
        {
            "name": "Beastmaster",
            "Centaur Warrunner": 0.837
        },
        {
            "name": "Bloodseeker",
            "Centaur Warrunner": 2.253
        },
        {
            "name": "Bounty Hunter",
            "Centaur Warrunner": 0.958
        },
        {
            "name": "Brewmaster",
            "Centaur Warrunner": 2.353
        },
        {
            "name": "Bristleback",
            "Centaur Warrunner": 2.844
        },
        {
            "name": "Broodmother",
            "Centaur Warrunner": 0.672
        },
        {
            "name": "Chaos Knight",
            "Centaur Warrunner": -1.482
        },
        {
            "name": "Chen",
            "Centaur Warrunner": 2.115
        },
        {
            "name": "Clinkz",
            "Centaur Warrunner": -0.263
        },
        {
            "name": "Clockwerk",
            "Centaur Warrunner": 0.535
        },
        {
            "name": "Crystal Maiden",
            "Centaur Warrunner": 0.517
        },
        {
            "name": "Dark Seer",
            "Centaur Warrunner": -0.54
        },
        {
            "name": "Dark Willow",
            "Centaur Warrunner": 0.373
        },
        {
            "name": "Dawnbreaker",
            "Centaur Warrunner": 0.248
        },
        {
            "name": "Dazzle",
            "Centaur Warrunner": 1.8
        },
        {
            "name": "Death Prophet",
            "Centaur Warrunner": 0.612
        },
        {
            "name": "Disruptor",
            "Centaur Warrunner": 0.646
        },
        {
            "name": "Doom",
            "Centaur Warrunner": 1.059
        },
        {
            "name": "Dragon Knight",
            "Centaur Warrunner": 0.666
        },
        {
            "name": "Drow Ranger",
            "Centaur Warrunner": -2.587
        },
        {
            "name": "Earth Spirit",
            "Centaur Warrunner": -0.07
        },
        {
            "name": "Earthshaker",
            "Centaur Warrunner": 0.422
        },
        {
            "name": "Elder Titan",
            "Centaur Warrunner": 1.259
        },
        {
            "name": "Ember Spirit",
            "Centaur Warrunner": 2.789
        },
        {
            "name": "Enchantress",
            "Centaur Warrunner": 0.116
        },
        {
            "name": "Enigma",
            "Centaur Warrunner": 0.271
        },
        {
            "name": "Faceless Void",
            "Centaur Warrunner": -0.177
        },
        {
            "name": "Grimstroke",
            "Centaur Warrunner": -0.242
        },
        {
            "name": "Gyrocopter",
            "Centaur Warrunner": -1.034
        },
        {
            "name": "Hoodwink",
            "Centaur Warrunner": -1.974
        },
        {
            "name": "Huskar",
            "Centaur Warrunner": 1.627
        },
        {
            "name": "Invoker",
            "Centaur Warrunner": 0.93
        },
        {
            "name": "Io",
            "Centaur Warrunner": 1.251
        },
        {
            "name": "Jakiro",
            "Centaur Warrunner": -0.223
        },
        {
            "name": "Juggernaut",
            "Centaur Warrunner": 2.292
        },
        {
            "name": "Keeper of the Light",
            "Centaur Warrunner": 0.023
        },
        {
            "name": "Kez",
            "Centaur Warrunner": 3.005
        },
        {
            "name": "Kunkka",
            "Centaur Warrunner": 0.304
        },
        {
            "name": "Legion Commander",
            "Centaur Warrunner": -0.299
        },
        {
            "name": "Leshrac",
            "Centaur Warrunner": 0.639
        },
        {
            "name": "Lich",
            "Centaur Warrunner": -0.974
        },
        {
            "name": "Lifestealer",
            "Centaur Warrunner": 4.672
        },
        {
            "name": "Lina",
            "Centaur Warrunner": -0.78
        },
        {
            "name": "Lion",
            "Centaur Warrunner": -0.792
        },
        {
            "name": "Lone Druid",
            "Centaur Warrunner": 2.882
        },
        {
            "name": "Luna",
            "Centaur Warrunner": -0.837
        },
        {
            "name": "Lycan",
            "Centaur Warrunner": -0.089
        },
        {
            "name": "Magnus",
            "Centaur Warrunner": 0.408
        },
        {
            "name": "Marci",
            "Centaur Warrunner": -0.951
        },
        {
            "name": "Mars",
            "Centaur Warrunner": 0.42
        },
        {
            "name": "Medusa",
            "Centaur Warrunner": -4.335
        },
        {
            "name": "Meepo",
            "Centaur Warrunner": 1.782
        },
        {
            "name": "Mirana",
            "Centaur Warrunner": -1.303
        },
        {
            "name": "Monkey King",
            "Centaur Warrunner": 1.448
        },
        {
            "name": "Morphling",
            "Centaur Warrunner": -0.325
        },
        {
            "name": "Muerta",
            "Centaur Warrunner": -2.352
        },
        {
            "name": "Naga Siren",
            "Centaur Warrunner": -2.897
        },
        {
            "name": "Nature's Prophet",
            "Centaur Warrunner": 0.724
        },
        {
            "name": "Necrophos",
            "Centaur Warrunner": 1.419
        },
        {
            "name": "Night Stalker",
            "Centaur Warrunner": -0.045
        },
        {
            "name": "Nyx Assassin",
            "Centaur Warrunner": -0.252
        },
        {
            "name": "Ogre Magi",
            "Centaur Warrunner": 0.392
        },
        {
            "name": "Omniknight",
            "Centaur Warrunner": -0.11
        },
        {
            "name": "Oracle",
            "Centaur Warrunner": 2.486
        },
        {
            "name": "Outworld Destroyer",
            "Centaur Warrunner": 1.113
        },
        {
            "name": "Pangolier",
            "Centaur Warrunner": 1.445
        },
        {
            "name": "Phantom Assassin",
            "Centaur Warrunner": -1.387
        },
        {
            "name": "Phantom Lancer",
            "Centaur Warrunner": -2.636
        },
        {
            "name": "Phoenix",
            "Centaur Warrunner": 0.131
        },
        {
            "name": "Primal Beast",
            "Centaur Warrunner": -0.582
        },
        {
            "name": "Puck",
            "Centaur Warrunner": -0.368
        },
        {
            "name": "Pudge",
            "Centaur Warrunner": -0.415
        },
        {
            "name": "Pugna",
            "Centaur Warrunner": -0.695
        },
        {
            "name": "Queen of Pain",
            "Centaur Warrunner": 0.327
        },
        {
            "name": "Razor",
            "Centaur Warrunner": -0.808
        },
        {
            "name": "Riki",
            "Centaur Warrunner": -0.594
        },
        {
            "name": "Ringmaster",
            "Centaur Warrunner": -0.048
        },
        {
            "name": "Rubick",
            "Centaur Warrunner": -0.238
        },
        {
            "name": "Sand King",
            "Centaur Warrunner": -1.881
        },
        {
            "name": "Shadow Demon",
            "Centaur Warrunner": -0.316
        },
        {
            "name": "Shadow Fiend",
            "Centaur Warrunner": -0.088
        },
        {
            "name": "Shadow Shaman",
            "Centaur Warrunner": 0.606
        },
        {
            "name": "Silencer",
            "Centaur Warrunner": -0.466
        },
        {
            "name": "Skywrath Mage",
            "Centaur Warrunner": -0.753
        },
        {
            "name": "Slardar",
            "Centaur Warrunner": 1.011
        },
        {
            "name": "Slark",
            "Centaur Warrunner": -0.385
        },
        {
            "name": "Snapfire",
            "Centaur Warrunner": -0.293
        },
        {
            "name": "Sniper",
            "Centaur Warrunner": -3.42
        },
        {
            "name": "Spectre",
            "Centaur Warrunner": -0.962
        },
        {
            "name": "Spirit Breaker",
            "Centaur Warrunner": -0.508
        },
        {
            "name": "Storm Spirit",
            "Centaur Warrunner": -0.145
        },
        {
            "name": "Sven",
            "Centaur Warrunner": 0.974
        },
        {
            "name": "Techies",
            "Centaur Warrunner": -0.538
        },
        {
            "name": "Templar Assassin",
            "Centaur Warrunner": 1.211
        },
        {
            "name": "Terrorblade",
            "Centaur Warrunner": 0.434
        },
        {
            "name": "Tidehunter",
            "Centaur Warrunner": 0.699
        },
        {
            "name": "Timbersaw",
            "Centaur Warrunner": 1.577
        },
        {
            "name": "Tinker",
            "Centaur Warrunner": -0.255
        },
        {
            "name": "Tiny",
            "Centaur Warrunner": 0.011
        },
        {
            "name": "Treant Protector",
            "Centaur Warrunner": 0.736
        },
        {
            "name": "Troll Warlord",
            "Centaur Warrunner": -1.245
        },
        {
            "name": "Tusk",
            "Centaur Warrunner": 0.177
        },
        {
            "name": "Underlord",
            "Centaur Warrunner": 1.877
        },
        {
            "name": "Undying",
            "Centaur Warrunner": 2.358
        },
        {
            "name": "Ursa",
            "Centaur Warrunner": 0.656
        },
        {
            "name": "Vengeful Spirit",
            "Centaur Warrunner": 0.344
        },
        {
            "name": "Venomancer",
            "Centaur Warrunner": 0.952
        },
        {
            "name": "Viper",
            "Centaur Warrunner": -0.758
        },
        {
            "name": "Visage",
            "Centaur Warrunner": 0.665
        },
        {
            "name": "Void Spirit",
            "Centaur Warrunner": -0.286
        },
        {
            "name": "Warlock",
            "Centaur Warrunner": 1.158
        },
        {
            "name": "Weaver",
            "Centaur Warrunner": 0.168
        },
        {
            "name": "Windranger",
            "Centaur Warrunner": -3.119
        },
        {
            "name": "Winter Wyvern",
            "Centaur Warrunner": -0.337
        },
        {
            "name": "Witch Doctor",
            "Centaur Warrunner": 2.117
        },
        {
            "name": "Wraith King",
            "Centaur Warrunner": 0.576
        },
        {
            "name": "Zeus",
            "Centaur Warrunner": -0.789
        }
    ],
    "Chaos Knight": [
        {
            "name": "Abaddon",
            "Chaos Knight": -0.464
        },
        {
            "name": "Alchemist",
            "Chaos Knight": 1.016
        },
        {
            "name": "Ancient Apparition",
            "Chaos Knight": -1.02
        },
        {
            "name": "Anti-Mage",
            "Chaos Knight": 1.786
        },
        {
            "name": "Arc Warden",
            "Chaos Knight": -1.664
        },
        {
            "name": "Axe",
            "Chaos Knight": 2.629
        },
        {
            "name": "Bane",
            "Chaos Knight": -3.809
        },
        {
            "name": "Batrider",
            "Chaos Knight": 0.058
        },
        {
            "name": "Beastmaster",
            "Chaos Knight": 0.292
        },
        {
            "name": "Bloodseeker",
            "Chaos Knight": -0.441
        },
        {
            "name": "Bounty Hunter",
            "Chaos Knight": -1.651
        },
        {
            "name": "Brewmaster",
            "Chaos Knight": 0.167
        },
        {
            "name": "Bristleback",
            "Chaos Knight": 1.46
        },
        {
            "name": "Broodmother",
            "Chaos Knight": -1.448
        },
        {
            "name": "Centaur Warrunner",
            "Chaos Knight": 1.496
        },
        {
            "name": "Chen",
            "Chaos Knight": -3.581
        },
        {
            "name": "Clinkz",
            "Chaos Knight": -3.024
        },
        {
            "name": "Clockwerk",
            "Chaos Knight": -0.314
        },
        {
            "name": "Crystal Maiden",
            "Chaos Knight": 2.397
        },
        {
            "name": "Dark Seer",
            "Chaos Knight": 4.485
        },
        {
            "name": "Dark Willow",
            "Chaos Knight": 0.324
        },
        {
            "name": "Dawnbreaker",
            "Chaos Knight": 2.186
        },
        {
            "name": "Dazzle",
            "Chaos Knight": 1.108
        },
        {
            "name": "Death Prophet",
            "Chaos Knight": -2.107
        },
        {
            "name": "Disruptor",
            "Chaos Knight": 0.274
        },
        {
            "name": "Doom",
            "Chaos Knight": -0.831
        },
        {
            "name": "Dragon Knight",
            "Chaos Knight": 1.374
        },
        {
            "name": "Drow Ranger",
            "Chaos Knight": -2.638
        },
        {
            "name": "Earth Spirit",
            "Chaos Knight": -0.014
        },
        {
            "name": "Earthshaker",
            "Chaos Knight": 2.076
        },
        {
            "name": "Elder Titan",
            "Chaos Knight": 0.44
        },
        {
            "name": "Ember Spirit",
            "Chaos Knight": 1.252
        },
        {
            "name": "Enchantress",
            "Chaos Knight": -2.16
        },
        {
            "name": "Enigma",
            "Chaos Knight": 0.756
        },
        {
            "name": "Faceless Void",
            "Chaos Knight": -2.11
        },
        {
            "name": "Grimstroke",
            "Chaos Knight": -0.639
        },
        {
            "name": "Gyrocopter",
            "Chaos Knight": 0.853
        },
        {
            "name": "Hoodwink",
            "Chaos Knight": 0.248
        },
        {
            "name": "Huskar",
            "Chaos Knight": -4.28
        },
        {
            "name": "Invoker",
            "Chaos Knight": 0.974
        },
        {
            "name": "Io",
            "Chaos Knight": 0.453
        },
        {
            "name": "Jakiro",
            "Chaos Knight": 2.092
        },
        {
            "name": "Juggernaut",
            "Chaos Knight": -0.041
        },
        {
            "name": "Keeper of the Light",
            "Chaos Knight": -0.99
        },
        {
            "name": "Kez",
            "Chaos Knight": 1.249
        },
        {
            "name": "Kunkka",
            "Chaos Knight": 2.017
        },
        {
            "name": "Legion Commander",
            "Chaos Knight": -2.566
        },
        {
            "name": "Leshrac",
            "Chaos Knight": 1.233
        },
        {
            "name": "Lich",
            "Chaos Knight": 2.434
        },
        {
            "name": "Lifestealer",
            "Chaos Knight": -0.91
        },
        {
            "name": "Lina",
            "Chaos Knight": 1.604
        },
        {
            "name": "Lion",
            "Chaos Knight": 0.02
        },
        {
            "name": "Lone Druid",
            "Chaos Knight": -0.089
        },
        {
            "name": "Luna",
            "Chaos Knight": 0.887
        },
        {
            "name": "Lycan",
            "Chaos Knight": -1.954
        },
        {
            "name": "Magnus",
            "Chaos Knight": 1.153
        },
        {
            "name": "Marci",
            "Chaos Knight": -0.886
        },
        {
            "name": "Mars",
            "Chaos Knight": 0.296
        },
        {
            "name": "Medusa",
            "Chaos Knight": 3.714
        },
        {
            "name": "Meepo",
            "Chaos Knight": 1.191
        },
        {
            "name": "Mirana",
            "Chaos Knight": -0.254
        },
        {
            "name": "Monkey King",
            "Chaos Knight": 1.376
        },
        {
            "name": "Morphling",
            "Chaos Knight": -0.224
        },
        {
            "name": "Muerta",
            "Chaos Knight": -0.2
        },
        {
            "name": "Naga Siren",
            "Chaos Knight": 2.333
        },
        {
            "name": "Nature's Prophet",
            "Chaos Knight": -0.889
        },
        {
            "name": "Necrophos",
            "Chaos Knight": 1.494
        },
        {
            "name": "Night Stalker",
            "Chaos Knight": -2.948
        },
        {
            "name": "Nyx Assassin",
            "Chaos Knight": -2.247
        },
        {
            "name": "Ogre Magi",
            "Chaos Knight": -3.413
        },
        {
            "name": "Omniknight",
            "Chaos Knight": 1.545
        },
        {
            "name": "Oracle",
            "Chaos Knight": -2.796
        },
        {
            "name": "Outworld Destroyer",
            "Chaos Knight": -2.07
        },
        {
            "name": "Pangolier",
            "Chaos Knight": 1.549
        },
        {
            "name": "Phantom Assassin",
            "Chaos Knight": 0.881
        },
        {
            "name": "Phantom Lancer",
            "Chaos Knight": 3.38
        },
        {
            "name": "Phoenix",
            "Chaos Knight": 4.558
        },
        {
            "name": "Primal Beast",
            "Chaos Knight": 0.723
        },
        {
            "name": "Puck",
            "Chaos Knight": -0.028
        },
        {
            "name": "Pudge",
            "Chaos Knight": -1.548
        },
        {
            "name": "Pugna",
            "Chaos Knight": -2.363
        },
        {
            "name": "Queen of Pain",
            "Chaos Knight": -1.064
        },
        {
            "name": "Razor",
            "Chaos Knight": -1.1
        },
        {
            "name": "Riki",
            "Chaos Knight": 0.156
        },
        {
            "name": "Ringmaster",
            "Chaos Knight": 1.11
        },
        {
            "name": "Rubick",
            "Chaos Knight": 0.039
        },
        {
            "name": "Sand King",
            "Chaos Knight": 4.616
        },
        {
            "name": "Shadow Demon",
            "Chaos Knight": -2.436
        },
        {
            "name": "Shadow Fiend",
            "Chaos Knight": -0.234
        },
        {
            "name": "Shadow Shaman",
            "Chaos Knight": -1.627
        },
        {
            "name": "Silencer",
            "Chaos Knight": -0.66
        },
        {
            "name": "Skywrath Mage",
            "Chaos Knight": -2.542
        },
        {
            "name": "Slardar",
            "Chaos Knight": -1.58
        },
        {
            "name": "Slark",
            "Chaos Knight": -0.276
        },
        {
            "name": "Snapfire",
            "Chaos Knight": -0.297
        },
        {
            "name": "Sniper",
            "Chaos Knight": -0.902
        },
        {
            "name": "Spectre",
            "Chaos Knight": -0.13
        },
        {
            "name": "Spirit Breaker",
            "Chaos Knight": -0.855
        },
        {
            "name": "Storm Spirit",
            "Chaos Knight": 0.169
        },
        {
            "name": "Sven",
            "Chaos Knight": 1.661
        },
        {
            "name": "Techies",
            "Chaos Knight": 0.71
        },
        {
            "name": "Templar Assassin",
            "Chaos Knight": -1.504
        },
        {
            "name": "Terrorblade",
            "Chaos Knight": 2.244
        },
        {
            "name": "Tidehunter",
            "Chaos Knight": 2.73
        },
        {
            "name": "Timbersaw",
            "Chaos Knight": 2.581
        },
        {
            "name": "Tinker",
            "Chaos Knight": -1.979
        },
        {
            "name": "Tiny",
            "Chaos Knight": 0.754
        },
        {
            "name": "Treant Protector",
            "Chaos Knight": 1.389
        },
        {
            "name": "Troll Warlord",
            "Chaos Knight": 0.879
        },
        {
            "name": "Tusk",
            "Chaos Knight": 0.335
        },
        {
            "name": "Underlord",
            "Chaos Knight": 1.474
        },
        {
            "name": "Undying",
            "Chaos Knight": 0.273
        },
        {
            "name": "Ursa",
            "Chaos Knight": -1.771
        },
        {
            "name": "Vengeful Spirit",
            "Chaos Knight": -2.029
        },
        {
            "name": "Venomancer",
            "Chaos Knight": 0.562
        },
        {
            "name": "Viper",
            "Chaos Knight": -3.852
        },
        {
            "name": "Visage",
            "Chaos Knight": -0.756
        },
        {
            "name": "Void Spirit",
            "Chaos Knight": -0.438
        },
        {
            "name": "Warlock",
            "Chaos Knight": 4.362
        },
        {
            "name": "Weaver",
            "Chaos Knight": -0.401
        },
        {
            "name": "Windranger",
            "Chaos Knight": 0.246
        },
        {
            "name": "Winter Wyvern",
            "Chaos Knight": 1.017
        },
        {
            "name": "Witch Doctor",
            "Chaos Knight": 1.004
        },
        {
            "name": "Wraith King",
            "Chaos Knight": -0.559
        },
        {
            "name": "Zeus",
            "Chaos Knight": -1.804
        }
    ],
    "Dawnbreaker": [
        {
            "name": "Abaddon",
            "Dawnbreaker": -1.247
        },
        {
            "name": "Alchemist",
            "Dawnbreaker": 0.367
        },
        {
            "name": "Ancient Apparition",
            "Dawnbreaker": 1.616
        },
        {
            "name": "Anti-Mage",
            "Dawnbreaker": 0.362
        },
        {
            "name": "Arc Warden",
            "Dawnbreaker": -0.827
        },
        {
            "name": "Axe",
            "Dawnbreaker": -0.646
        },
        {
            "name": "Bane",
            "Dawnbreaker": 0.129
        },
        {
            "name": "Batrider",
            "Dawnbreaker": 0.348
        },
        {
            "name": "Beastmaster",
            "Dawnbreaker": -1.373
        },
        {
            "name": "Bloodseeker",
            "Dawnbreaker": 0.924
        },
        {
            "name": "Bounty Hunter",
            "Dawnbreaker": 1.102
        },
        {
            "name": "Brewmaster",
            "Dawnbreaker": -1.204
        },
        {
            "name": "Bristleback",
            "Dawnbreaker": 2.741
        },
        {
            "name": "Broodmother",
            "Dawnbreaker": 1.034
        },
        {
            "name": "Centaur Warrunner",
            "Dawnbreaker": -0.217
        },
        {
            "name": "Chaos Knight",
            "Dawnbreaker": -2.082
        },
        {
            "name": "Chen",
            "Dawnbreaker": 1.385
        },
        {
            "name": "Clinkz",
            "Dawnbreaker": 1.677
        },
        {
            "name": "Clockwerk",
            "Dawnbreaker": 0.05
        },
        {
            "name": "Crystal Maiden",
            "Dawnbreaker": -1.858
        },
        {
            "name": "Dark Seer",
            "Dawnbreaker": -1.366
        },
        {
            "name": "Dark Willow",
            "Dawnbreaker": 1.107
        },
        {
            "name": "Dazzle",
            "Dawnbreaker": 0.26
        },
        {
            "name": "Death Prophet",
            "Dawnbreaker": 1.463
        },
        {
            "name": "Disruptor",
            "Dawnbreaker": 0.55
        },
        {
            "name": "Doom",
            "Dawnbreaker": 2.07
        },
        {
            "name": "Dragon Knight",
            "Dawnbreaker": 0.852
        },
        {
            "name": "Drow Ranger",
            "Dawnbreaker": 1.036
        },
        {
            "name": "Earth Spirit",
            "Dawnbreaker": -0.075
        },
        {
            "name": "Earthshaker",
            "Dawnbreaker": 0.087
        },
        {
            "name": "Elder Titan",
            "Dawnbreaker": 2.113
        },
        {
            "name": "Ember Spirit",
            "Dawnbreaker": 0.756
        },
        {
            "name": "Enchantress",
            "Dawnbreaker": -0.124
        },
        {
            "name": "Enigma",
            "Dawnbreaker": -0.367
        },
        {
            "name": "Faceless Void",
            "Dawnbreaker": 1.341
        },
        {
            "name": "Grimstroke",
            "Dawnbreaker": 0.189
        },
        {
            "name": "Gyrocopter",
            "Dawnbreaker": 0.122
        },
        {
            "name": "Hoodwink",
            "Dawnbreaker": 0.228
        },
        {
            "name": "Huskar",
            "Dawnbreaker": 0.945
        },
        {
            "name": "Invoker",
            "Dawnbreaker": 1.244
        },
        {
            "name": "Io",
            "Dawnbreaker": 0.038
        },
        {
            "name": "Jakiro",
            "Dawnbreaker": 1.163
        },
        {
            "name": "Juggernaut",
            "Dawnbreaker": 1.589
        },
        {
            "name": "Keeper of the Light",
            "Dawnbreaker": 1.37
        },
        {
            "name": "Kez",
            "Dawnbreaker": 0.932
        },
        {
            "name": "Kunkka",
            "Dawnbreaker": 1.4
        },
        {
            "name": "Legion Commander",
            "Dawnbreaker": -3.372
        },
        {
            "name": "Leshrac",
            "Dawnbreaker": -0.732
        },
        {
            "name": "Lich",
            "Dawnbreaker": 1.206
        },
        {
            "name": "Lifestealer",
            "Dawnbreaker": 0.641
        },
        {
            "name": "Lina",
            "Dawnbreaker": 2.328
        },
        {
            "name": "Lion",
            "Dawnbreaker": -0.61
        },
        {
            "name": "Lone Druid",
            "Dawnbreaker": 0.107
        },
        {
            "name": "Luna",
            "Dawnbreaker": -0.869
        },
        {
            "name": "Lycan",
            "Dawnbreaker": -0.169
        },
        {
            "name": "Magnus",
            "Dawnbreaker": -0.197
        },
        {
            "name": "Marci",
            "Dawnbreaker": -0.834
        },
        {
            "name": "Mars",
            "Dawnbreaker": -0.624
        },
        {
            "name": "Medusa",
            "Dawnbreaker": -0.575
        },
        {
            "name": "Meepo",
            "Dawnbreaker": -2.848
        },
        {
            "name": "Mirana",
            "Dawnbreaker": 0.167
        },
        {
            "name": "Monkey King",
            "Dawnbreaker": 1.546
        },
        {
            "name": "Morphling",
            "Dawnbreaker": 2.124
        },
        {
            "name": "Muerta",
            "Dawnbreaker": 1.747
        },
        {
            "name": "Naga Siren",
            "Dawnbreaker": -2.833
        },
        {
            "name": "Nature's Prophet",
            "Dawnbreaker": -1.333
        },
        {
            "name": "Necrophos",
            "Dawnbreaker": -1.246
        },
        {
            "name": "Night Stalker",
            "Dawnbreaker": 0.188
        },
        {
            "name": "Nyx Assassin",
            "Dawnbreaker": 1.985
        },
        {
            "name": "Ogre Magi",
            "Dawnbreaker": 0.59
        },
        {
            "name": "Omniknight",
            "Dawnbreaker": 0.464
        },
        {
            "name": "Oracle",
            "Dawnbreaker": -1.013
        },
        {
            "name": "Outworld Destroyer",
            "Dawnbreaker": 0.334
        },
        {
            "name": "Pangolier",
            "Dawnbreaker": 0.682
        },
        {
            "name": "Phantom Assassin",
            "Dawnbreaker": -2.202
        },
        {
            "name": "Phantom Lancer",
            "Dawnbreaker": -1.129
        },
        {
            "name": "Phoenix",
            "Dawnbreaker": 1.94
        },
        {
            "name": "Primal Beast",
            "Dawnbreaker": 0.324
        },
        {
            "name": "Puck",
            "Dawnbreaker": 0.266
        },
        {
            "name": "Pudge",
            "Dawnbreaker": -1.765
        },
        {
            "name": "Pugna",
            "Dawnbreaker": 0.514
        },
        {
            "name": "Queen of Pain",
            "Dawnbreaker": 0.397
        },
        {
            "name": "Razor",
            "Dawnbreaker": -0.014
        },
        {
            "name": "Riki",
            "Dawnbreaker": 0.147
        },
        {
            "name": "Ringmaster",
            "Dawnbreaker": 0.943
        },
        {
            "name": "Rubick",
            "Dawnbreaker": 0.653
        },
        {
            "name": "Sand King",
            "Dawnbreaker": -0.467
        },
        {
            "name": "Shadow Demon",
            "Dawnbreaker": -1.313
        },
        {
            "name": "Shadow Fiend",
            "Dawnbreaker": 0.304
        },
        {
            "name": "Shadow Shaman",
            "Dawnbreaker": -1.668
        },
        {
            "name": "Silencer",
            "Dawnbreaker": 2.614
        },
        {
            "name": "Skywrath Mage",
            "Dawnbreaker": 0.18
        },
        {
            "name": "Slardar",
            "Dawnbreaker": 0.488
        },
        {
            "name": "Slark",
            "Dawnbreaker": -2.762
        },
        {
            "name": "Snapfire",
            "Dawnbreaker": 0.862
        },
        {
            "name": "Sniper",
            "Dawnbreaker": 0.303
        },
        {
            "name": "Spectre",
            "Dawnbreaker": -1.859
        },
        {
            "name": "Spirit Breaker",
            "Dawnbreaker": -1.278
        },
        {
            "name": "Storm Spirit",
            "Dawnbreaker": -0.262
        },
        {
            "name": "Sven",
            "Dawnbreaker": 1.164
        },
        {
            "name": "Techies",
            "Dawnbreaker": -0.27
        },
        {
            "name": "Templar Assassin",
            "Dawnbreaker": 0.808
        },
        {
            "name": "Terrorblade",
            "Dawnbreaker": 0.872
        },
        {
            "name": "Tidehunter",
            "Dawnbreaker": -0.816
        },
        {
            "name": "Timbersaw",
            "Dawnbreaker": 0.81
        },
        {
            "name": "Tinker",
            "Dawnbreaker": -0.129
        },
        {
            "name": "Tiny",
            "Dawnbreaker": -0.396
        },
        {
            "name": "Treant Protector",
            "Dawnbreaker": -1.137
        },
        {
            "name": "Troll Warlord",
            "Dawnbreaker": 0.477
        },
        {
            "name": "Tusk",
            "Dawnbreaker": -1.138
        },
        {
            "name": "Underlord",
            "Dawnbreaker": 0.794
        },
        {
            "name": "Undying",
            "Dawnbreaker": -0.389
        },
        {
            "name": "Ursa",
            "Dawnbreaker": 1.527
        },
        {
            "name": "Vengeful Spirit",
            "Dawnbreaker": -0.394
        },
        {
            "name": "Venomancer",
            "Dawnbreaker": 0.205
        },
        {
            "name": "Viper",
            "Dawnbreaker": 0.464
        },
        {
            "name": "Visage",
            "Dawnbreaker": 1.245
        },
        {
            "name": "Void Spirit",
            "Dawnbreaker": -0.735
        },
        {
            "name": "Warlock",
            "Dawnbreaker": -1.058
        },
        {
            "name": "Weaver",
            "Dawnbreaker": 2.046
        },
        {
            "name": "Windranger",
            "Dawnbreaker": 0.44
        },
        {
            "name": "Winter Wyvern",
            "Dawnbreaker": 2.721
        },
        {
            "name": "Witch Doctor",
            "Dawnbreaker": 0.273
        },
        {
            "name": "Wraith King",
            "Dawnbreaker": -1.058
        },
        {
            "name": "Zeus",
            "Dawnbreaker": -0.21
        }
    ],
    "Doom": [
        {
            "name": "Abaddon",
            "Doom": -3.172
        },
        {
            "name": "Alchemist",
            "Doom": -2.061
        },
        {
            "name": "Ancient Apparition",
            "Doom": 0.019
        },
        {
            "name": "Anti-Mage",
            "Doom": 1.509
        },
        {
            "name": "Arc Warden",
            "Doom": 3.508
        },
        {
            "name": "Axe",
            "Doom": -2.104
        },
        {
            "name": "Bane",
            "Doom": 0.632
        },
        {
            "name": "Batrider",
            "Doom": 2.429
        },
        {
            "name": "Beastmaster",
            "Doom": 1.134
        },
        {
            "name": "Bloodseeker",
            "Doom": -1.326
        },
        {
            "name": "Bounty Hunter",
            "Doom": 0.234
        },
        {
            "name": "Brewmaster",
            "Doom": -0.521
        },
        {
            "name": "Bristleback",
            "Doom": 0.429
        },
        {
            "name": "Broodmother",
            "Doom": 2.368
        },
        {
            "name": "Centaur Warrunner",
            "Doom": -0.993
        },
        {
            "name": "Chaos Knight",
            "Doom": 0.869
        },
        {
            "name": "Chen",
            "Doom": -0.777
        },
        {
            "name": "Clinkz",
            "Doom": 0.991
        },
        {
            "name": "Clockwerk",
            "Doom": 0.304
        },
        {
            "name": "Crystal Maiden",
            "Doom": 1.438
        },
        {
            "name": "Dark Seer",
            "Doom": -1.504
        },
        {
            "name": "Dark Willow",
            "Doom": 0.375
        },
        {
            "name": "Dawnbreaker",
            "Doom": -1.948
        },
        {
            "name": "Dazzle",
            "Doom": -0.297
        },
        {
            "name": "Death Prophet",
            "Doom": 0.681
        },
        {
            "name": "Disruptor",
            "Doom": 0.229
        },
        {
            "name": "Dragon Knight",
            "Doom": -1.748
        },
        {
            "name": "Drow Ranger",
            "Doom": 1.073
        },
        {
            "name": "Earth Spirit",
            "Doom": -0.99
        },
        {
            "name": "Earthshaker",
            "Doom": 0.255
        },
        {
            "name": "Elder Titan",
            "Doom": -0.173
        },
        {
            "name": "Ember Spirit",
            "Doom": -0.197
        },
        {
            "name": "Enchantress",
            "Doom": -0.827
        },
        {
            "name": "Enigma",
            "Doom": 1.498
        },
        {
            "name": "Faceless Void",
            "Doom": -0.746
        },
        {
            "name": "Grimstroke",
            "Doom": -0.968
        },
        {
            "name": "Gyrocopter",
            "Doom": -0.118
        },
        {
            "name": "Hoodwink",
            "Doom": -0.387
        },
        {
            "name": "Huskar",
            "Doom": -1.419
        },
        {
            "name": "Invoker",
            "Doom": 1.102
        },
        {
            "name": "Io",
            "Doom": -0.942
        },
        {
            "name": "Jakiro",
            "Doom": -0.105
        },
        {
            "name": "Juggernaut",
            "Doom": -0.985
        },
        {
            "name": "Keeper of the Light",
            "Doom": 1.127
        },
        {
            "name": "Kez",
            "Doom": -0.374
        },
        {
            "name": "Kunkka",
            "Doom": -0.434
        },
        {
            "name": "Legion Commander",
            "Doom": 0.335
        },
        {
            "name": "Leshrac",
            "Doom": -0.862
        },
        {
            "name": "Lich",
            "Doom": 0.093
        },
        {
            "name": "Lifestealer",
            "Doom": -1.428
        },
        {
            "name": "Lina",
            "Doom": 1.627
        },
        {
            "name": "Lion",
            "Doom": -0.438
        },
        {
            "name": "Lone Druid",
            "Doom": 1.197
        },
        {
            "name": "Luna",
            "Doom": 1.771
        },
        {
            "name": "Lycan",
            "Doom": 0.569
        },
        {
            "name": "Magnus",
            "Doom": -0.248
        },
        {
            "name": "Marci",
            "Doom": 1.916
        },
        {
            "name": "Mars",
            "Doom": -0.728
        },
        {
            "name": "Medusa",
            "Doom": 2.208
        },
        {
            "name": "Meepo",
            "Doom": 2.097
        },
        {
            "name": "Mirana",
            "Doom": 0.809
        },
        {
            "name": "Monkey King",
            "Doom": 0.902
        },
        {
            "name": "Morphling",
            "Doom": -3.04
        },
        {
            "name": "Muerta",
            "Doom": 0.424
        },
        {
            "name": "Naga Siren",
            "Doom": 1.224
        },
        {
            "name": "Nature's Prophet",
            "Doom": 1.242
        },
        {
            "name": "Necrophos",
            "Doom": -3.952
        },
        {
            "name": "Night Stalker",
            "Doom": 0.41
        },
        {
            "name": "Nyx Assassin",
            "Doom": -0.394
        },
        {
            "name": "Ogre Magi",
            "Doom": 0.281
        },
        {
            "name": "Omniknight",
            "Doom": -3.353
        },
        {
            "name": "Oracle",
            "Doom": -1.037
        },
        {
            "name": "Outworld Destroyer",
            "Doom": -0.722
        },
        {
            "name": "Pangolier",
            "Doom": -1.245
        },
        {
            "name": "Phantom Assassin",
            "Doom": -0.793
        },
        {
            "name": "Phantom Lancer",
            "Doom": -1.386
        },
        {
            "name": "Phoenix",
            "Doom": -1.206
        },
        {
            "name": "Primal Beast",
            "Doom": -0.343
        },
        {
            "name": "Puck",
            "Doom": 0.376
        },
        {
            "name": "Pudge",
            "Doom": -1.052
        },
        {
            "name": "Pugna",
            "Doom": -1.69
        },
        {
            "name": "Queen of Pain",
            "Doom": 0.133
        },
        {
            "name": "Razor",
            "Doom": 0.795
        },
        {
            "name": "Riki",
            "Doom": 0.22
        },
        {
            "name": "Ringmaster",
            "Doom": -0.835
        },
        {
            "name": "Rubick",
            "Doom": 1.128
        },
        {
            "name": "Sand King",
            "Doom": -0.871
        },
        {
            "name": "Shadow Demon",
            "Doom": 0.051
        },
        {
            "name": "Shadow Fiend",
            "Doom": 0.156
        },
        {
            "name": "Shadow Shaman",
            "Doom": -0.043
        },
        {
            "name": "Silencer",
            "Doom": -0.385
        },
        {
            "name": "Skywrath Mage",
            "Doom": 0.152
        },
        {
            "name": "Slardar",
            "Doom": 0.19
        },
        {
            "name": "Slark",
            "Doom": -0.708
        },
        {
            "name": "Snapfire",
            "Doom": 0.327
        },
        {
            "name": "Sniper",
            "Doom": 0.356
        },
        {
            "name": "Spectre",
            "Doom": 0.952
        },
        {
            "name": "Spirit Breaker",
            "Doom": -0.087
        },
        {
            "name": "Storm Spirit",
            "Doom": 0.242
        },
        {
            "name": "Sven",
            "Doom": 0.009
        },
        {
            "name": "Techies",
            "Doom": -0.112
        },
        {
            "name": "Templar Assassin",
            "Doom": 0.549
        },
        {
            "name": "Terrorblade",
            "Doom": 0.634
        },
        {
            "name": "Tidehunter",
            "Doom": -1.003
        },
        {
            "name": "Timbersaw",
            "Doom": -1.688
        },
        {
            "name": "Tinker",
            "Doom": -0.01
        },
        {
            "name": "Tiny",
            "Doom": -0.973
        },
        {
            "name": "Treant Protector",
            "Doom": -1.759
        },
        {
            "name": "Troll Warlord",
            "Doom": -1.771
        },
        {
            "name": "Tusk",
            "Doom": 0.421
        },
        {
            "name": "Underlord",
            "Doom": -1.601
        },
        {
            "name": "Undying",
            "Doom": -0.268
        },
        {
            "name": "Ursa",
            "Doom": -0.211
        },
        {
            "name": "Vengeful Spirit",
            "Doom": 0.395
        },
        {
            "name": "Venomancer",
            "Doom": 1.149
        },
        {
            "name": "Viper",
            "Doom": 0.672
        },
        {
            "name": "Visage",
            "Doom": 1.352
        },
        {
            "name": "Void Spirit",
            "Doom": -0.455
        },
        {
            "name": "Warlock",
            "Doom": 0.262
        },
        {
            "name": "Weaver",
            "Doom": 0.462
        },
        {
            "name": "Windranger",
            "Doom": 1.125
        },
        {
            "name": "Winter Wyvern",
            "Doom": -1.765
        },
        {
            "name": "Witch Doctor",
            "Doom": 0.515
        },
        {
            "name": "Wraith King",
            "Doom": 1.339
        },
        {
            "name": "Zeus",
            "Doom": -0.126
        }
    ],
    "Dragon Knight": [
        {
            "name": "Abaddon",
            "Dragon Knight": -0.939
        },
        {
            "name": "Alchemist",
            "Dragon Knight": 1.077
        },
        {
            "name": "Ancient Apparition",
            "Dragon Knight": 1.819
        },
        {
            "name": "Anti-Mage",
            "Dragon Knight": -0.435
        },
        {
            "name": "Arc Warden",
            "Dragon Knight": 0.443
        },
        {
            "name": "Axe",
            "Dragon Knight": -2.181
        },
        {
            "name": "Bane",
            "Dragon Knight": -0.078
        },
        {
            "name": "Batrider",
            "Dragon Knight": 0.364
        },
        {
            "name": "Beastmaster",
            "Dragon Knight": 0.011
        },
        {
            "name": "Bloodseeker",
            "Dragon Knight": 0.591
        },
        {
            "name": "Bounty Hunter",
            "Dragon Knight": 1.288
        },
        {
            "name": "Brewmaster",
            "Dragon Knight": 0.833
        },
        {
            "name": "Bristleback",
            "Dragon Knight": -2.683
        },
        {
            "name": "Broodmother",
            "Dragon Knight": -1.99
        },
        {
            "name": "Centaur Warrunner",
            "Dragon Knight": -0.755
        },
        {
            "name": "Chaos Knight",
            "Dragon Knight": -1.364
        },
        {
            "name": "Chen",
            "Dragon Knight": 0.321
        },
        {
            "name": "Clinkz",
            "Dragon Knight": -1.131
        },
        {
            "name": "Clockwerk",
            "Dragon Knight": -0.529
        },
        {
            "name": "Crystal Maiden",
            "Dragon Knight": 0.945
        },
        {
            "name": "Dark Seer",
            "Dragon Knight": 0.051
        },
        {
            "name": "Dark Willow",
            "Dragon Knight": 0.444
        },
        {
            "name": "Dawnbreaker",
            "Dragon Knight": -0.899
        },
        {
            "name": "Dazzle",
            "Dragon Knight": -0.376
        },
        {
            "name": "Death Prophet",
            "Dragon Knight": -0.92
        },
        {
            "name": "Disruptor",
            "Dragon Knight": -1.008
        },
        {
            "name": "Doom",
            "Dragon Knight": 1.808
        },
        {
            "name": "Drow Ranger",
            "Dragon Knight": -1.431
        },
        {
            "name": "Earth Spirit",
            "Dragon Knight": 0.55
        },
        {
            "name": "Earthshaker",
            "Dragon Knight": -1.376
        },
        {
            "name": "Elder Titan",
            "Dragon Knight": -1.034
        },
        {
            "name": "Ember Spirit",
            "Dragon Knight": -0.742
        },
        {
            "name": "Enchantress",
            "Dragon Knight": 0.484
        },
        {
            "name": "Enigma",
            "Dragon Knight": 0.09
        },
        {
            "name": "Faceless Void",
            "Dragon Knight": 0.657
        },
        {
            "name": "Grimstroke",
            "Dragon Knight": -0.92
        },
        {
            "name": "Gyrocopter",
            "Dragon Knight": 0.012
        },
        {
            "name": "Hoodwink",
            "Dragon Knight": -0.187
        },
        {
            "name": "Huskar",
            "Dragon Knight": 1.85
        },
        {
            "name": "Invoker",
            "Dragon Knight": 0.573
        },
        {
            "name": "Io",
            "Dragon Knight": -1.563
        },
        {
            "name": "Jakiro",
            "Dragon Knight": 0.155
        },
        {
            "name": "Juggernaut",
            "Dragon Knight": -0.425
        },
        {
            "name": "Keeper of the Light",
            "Dragon Knight": 1.406
        },
        {
            "name": "Kez",
            "Dragon Knight": 0.034
        },
        {
            "name": "Kunkka",
            "Dragon Knight": 0.48
        },
        {
            "name": "Legion Commander",
            "Dragon Knight": -2.63
        },
        {
            "name": "Leshrac",
            "Dragon Knight": 0.04
        },
        {
            "name": "Lich",
            "Dragon Knight": 1.046
        },
        {
            "name": "Lifestealer",
            "Dragon Knight": 1.389
        },
        {
            "name": "Lina",
            "Dragon Knight": 0.157
        },
        {
            "name": "Lion",
            "Dragon Knight": -1.321
        },
        {
            "name": "Lone Druid",
            "Dragon Knight": -0.529
        },
        {
            "name": "Luna",
            "Dragon Knight": -0.049
        },
        {
            "name": "Lycan",
            "Dragon Knight": -2.401
        },
        {
            "name": "Magnus",
            "Dragon Knight": -0.297
        },
        {
            "name": "Marci",
            "Dragon Knight": -1.279
        },
        {
            "name": "Mars",
            "Dragon Knight": 1.13
        },
        {
            "name": "Medusa",
            "Dragon Knight": -0.818
        },
        {
            "name": "Meepo",
            "Dragon Knight": 0.465
        },
        {
            "name": "Mirana",
            "Dragon Knight": -0.043
        },
        {
            "name": "Monkey King",
            "Dragon Knight": 0.853
        },
        {
            "name": "Morphling",
            "Dragon Knight": -0.409
        },
        {
            "name": "Muerta",
            "Dragon Knight": 0.393
        },
        {
            "name": "Naga Siren",
            "Dragon Knight": -1.707
        },
        {
            "name": "Nature's Prophet",
            "Dragon Knight": -0.638
        },
        {
            "name": "Necrophos",
            "Dragon Knight": 2.706
        },
        {
            "name": "Night Stalker",
            "Dragon Knight": -0.837
        },
        {
            "name": "Nyx Assassin",
            "Dragon Knight": -1.217
        },
        {
            "name": "Ogre Magi",
            "Dragon Knight": 1.424
        },
        {
            "name": "Omniknight",
            "Dragon Knight": -0.319
        },
        {
            "name": "Oracle",
            "Dragon Knight": -1.303
        },
        {
            "name": "Outworld Destroyer",
            "Dragon Knight": 1.351
        },
        {
            "name": "Pangolier",
            "Dragon Knight": -1.224
        },
        {
            "name": "Phantom Assassin",
            "Dragon Knight": -0.823
        },
        {
            "name": "Phantom Lancer",
            "Dragon Knight": -1.191
        },
        {
            "name": "Phoenix",
            "Dragon Knight": 2.0
        },
        {
            "name": "Primal Beast",
            "Dragon Knight": 0.501
        },
        {
            "name": "Puck",
            "Dragon Knight": -1.944
        },
        {
            "name": "Pudge",
            "Dragon Knight": -0.153
        },
        {
            "name": "Pugna",
            "Dragon Knight": 0.105
        },
        {
            "name": "Queen of Pain",
            "Dragon Knight": -0.241
        },
        {
            "name": "Razor",
            "Dragon Knight": 0.929
        },
        {
            "name": "Riki",
            "Dragon Knight": -0.781
        },
        {
            "name": "Ringmaster",
            "Dragon Knight": 1.593
        },
        {
            "name": "Rubick",
            "Dragon Knight": 0.46
        },
        {
            "name": "Sand King",
            "Dragon Knight": 1.042
        },
        {
            "name": "Shadow Demon",
            "Dragon Knight": -0.249
        },
        {
            "name": "Shadow Fiend",
            "Dragon Knight": -1.958
        },
        {
            "name": "Shadow Shaman",
            "Dragon Knight": -0.407
        },
        {
            "name": "Silencer",
            "Dragon Knight": -0.355
        },
        {
            "name": "Skywrath Mage",
            "Dragon Knight": -1.742
        },
        {
            "name": "Slardar",
            "Dragon Knight": -0.331
        },
        {
            "name": "Slark",
            "Dragon Knight": 2.176
        },
        {
            "name": "Snapfire",
            "Dragon Knight": -0.346
        },
        {
            "name": "Sniper",
            "Dragon Knight": 0.411
        },
        {
            "name": "Spectre",
            "Dragon Knight": 0.018
        },
        {
            "name": "Spirit Breaker",
            "Dragon Knight": -0.136
        },
        {
            "name": "Storm Spirit",
            "Dragon Knight": -0.058
        },
        {
            "name": "Sven",
            "Dragon Knight": -1.431
        },
        {
            "name": "Techies",
            "Dragon Knight": 0.151
        },
        {
            "name": "Templar Assassin",
            "Dragon Knight": -0.642
        },
        {
            "name": "Terrorblade",
            "Dragon Knight": 0.955
        },
        {
            "name": "Tidehunter",
            "Dragon Knight": 0.148
        },
        {
            "name": "Timbersaw",
            "Dragon Knight": 1.15
        },
        {
            "name": "Tinker",
            "Dragon Knight": 1.19
        },
        {
            "name": "Tiny",
            "Dragon Knight": -0.047
        },
        {
            "name": "Treant Protector",
            "Dragon Knight": 1.759
        },
        {
            "name": "Troll Warlord",
            "Dragon Knight": -0.212
        },
        {
            "name": "Tusk",
            "Dragon Knight": -0.158
        },
        {
            "name": "Underlord",
            "Dragon Knight": 1.099
        },
        {
            "name": "Undying",
            "Dragon Knight": 0.431
        },
        {
            "name": "Ursa",
            "Dragon Knight": 0.072
        },
        {
            "name": "Vengeful Spirit",
            "Dragon Knight": -0.168
        },
        {
            "name": "Venomancer",
            "Dragon Knight": 2.088
        },
        {
            "name": "Viper",
            "Dragon Knight": 3.546
        },
        {
            "name": "Visage",
            "Dragon Knight": -0.605
        },
        {
            "name": "Void Spirit",
            "Dragon Knight": -2.29
        },
        {
            "name": "Warlock",
            "Dragon Knight": -0.427
        },
        {
            "name": "Weaver",
            "Dragon Knight": -0.895
        },
        {
            "name": "Windranger",
            "Dragon Knight": -0.074
        },
        {
            "name": "Winter Wyvern",
            "Dragon Knight": 1.788
        },
        {
            "name": "Witch Doctor",
            "Dragon Knight": 1.646
        },
        {
            "name": "Wraith King",
            "Dragon Knight": -1.096
        },
        {
            "name": "Zeus",
            "Dragon Knight": -0.257
        }
    ],
    "Earth Spirit": [
        {
            "name": "Abaddon",
            "Earth Spirit": 1.663
        },
        {
            "name": "Alchemist",
            "Earth Spirit": 0.386
        },
        {
            "name": "Ancient Apparition",
            "Earth Spirit": -0.544
        },
        {
            "name": "Anti-Mage",
            "Earth Spirit": 0.82
        },
        {
            "name": "Arc Warden",
            "Earth Spirit": -1.746
        },
        {
            "name": "Axe",
            "Earth Spirit": -1.619
        },
        {
            "name": "Bane",
            "Earth Spirit": 0.309
        },
        {
            "name": "Batrider",
            "Earth Spirit": 0.358
        },
        {
            "name": "Beastmaster",
            "Earth Spirit": -1.137
        },
        {
            "name": "Bloodseeker",
            "Earth Spirit": 1.267
        },
        {
            "name": "Bounty Hunter",
            "Earth Spirit": 0.936
        },
        {
            "name": "Brewmaster",
            "Earth Spirit": 0.621
        },
        {
            "name": "Bristleback",
            "Earth Spirit": -1.828
        },
        {
            "name": "Broodmother",
            "Earth Spirit": 0.429
        },
        {
            "name": "Centaur Warrunner",
            "Earth Spirit": 0.166
        },
        {
            "name": "Chaos Knight",
            "Earth Spirit": 0.012
        },
        {
            "name": "Chen",
            "Earth Spirit": 2.38
        },
        {
            "name": "Clinkz",
            "Earth Spirit": -0.578
        },
        {
            "name": "Clockwerk",
            "Earth Spirit": -0.373
        },
        {
            "name": "Crystal Maiden",
            "Earth Spirit": -0.515
        },
        {
            "name": "Dark Seer",
            "Earth Spirit": 0.863
        },
        {
            "name": "Dark Willow",
            "Earth Spirit": -0.554
        },
        {
            "name": "Dawnbreaker",
            "Earth Spirit": 0.266
        },
        {
            "name": "Dazzle",
            "Earth Spirit": -0.65
        },
        {
            "name": "Death Prophet",
            "Earth Spirit": 1.3
        },
        {
            "name": "Disruptor",
            "Earth Spirit": -0.482
        },
        {
            "name": "Doom",
            "Earth Spirit": 1.021
        },
        {
            "name": "Dragon Knight",
            "Earth Spirit": -0.617
        },
        {
            "name": "Drow Ranger",
            "Earth Spirit": -2.815
        },
        {
            "name": "Earthshaker",
            "Earth Spirit": 0.277
        },
        {
            "name": "Elder Titan",
            "Earth Spirit": 1.809
        },
        {
            "name": "Ember Spirit",
            "Earth Spirit": 1.372
        },
        {
            "name": "Enchantress",
            "Earth Spirit": -0.975
        },
        {
            "name": "Enigma",
            "Earth Spirit": 2.341
        },
        {
            "name": "Faceless Void",
            "Earth Spirit": 1.648
        },
        {
            "name": "Grimstroke",
            "Earth Spirit": 0.886
        },
        {
            "name": "Gyrocopter",
            "Earth Spirit": -0.127
        },
        {
            "name": "Hoodwink",
            "Earth Spirit": -0.838
        },
        {
            "name": "Huskar",
            "Earth Spirit": 3.635
        },
        {
            "name": "Invoker",
            "Earth Spirit": -0.209
        },
        {
            "name": "Io",
            "Earth Spirit": 0.734
        },
        {
            "name": "Jakiro",
            "Earth Spirit": 0.004
        },
        {
            "name": "Juggernaut",
            "Earth Spirit": 2.726
        },
        {
            "name": "Keeper of the Light",
            "Earth Spirit": -0.046
        },
        {
            "name": "Kez",
            "Earth Spirit": 2.44
        },
        {
            "name": "Kunkka",
            "Earth Spirit": 0.734
        },
        {
            "name": "Legion Commander",
            "Earth Spirit": 1.478
        },
        {
            "name": "Leshrac",
            "Earth Spirit": 1.243
        },
        {
            "name": "Lich",
            "Earth Spirit": -0.579
        },
        {
            "name": "Lifestealer",
            "Earth Spirit": 2.968
        },
        {
            "name": "Lina",
            "Earth Spirit": 0.423
        },
        {
            "name": "Lion",
            "Earth Spirit": 0.441
        },
        {
            "name": "Lone Druid",
            "Earth Spirit": 2.222
        },
        {
            "name": "Luna",
            "Earth Spirit": 0.957
        },
        {
            "name": "Lycan",
            "Earth Spirit": 0.282
        },
        {
            "name": "Magnus",
            "Earth Spirit": 0.175
        },
        {
            "name": "Marci",
            "Earth Spirit": 1.601
        },
        {
            "name": "Mars",
            "Earth Spirit": -2.267
        },
        {
            "name": "Medusa",
            "Earth Spirit": -1.321
        },
        {
            "name": "Meepo",
            "Earth Spirit": 3.428
        },
        {
            "name": "Mirana",
            "Earth Spirit": -0.24
        },
        {
            "name": "Monkey King",
            "Earth Spirit": 1.889
        },
        {
            "name": "Morphling",
            "Earth Spirit": 0.011
        },
        {
            "name": "Muerta",
            "Earth Spirit": 0.738
        },
        {
            "name": "Naga Siren",
            "Earth Spirit": 1.408
        },
        {
            "name": "Nature's Prophet",
            "Earth Spirit": -0.388
        },
        {
            "name": "Necrophos",
            "Earth Spirit": 0.714
        },
        {
            "name": "Night Stalker",
            "Earth Spirit": -0.662
        },
        {
            "name": "Nyx Assassin",
            "Earth Spirit": -0.902
        },
        {
            "name": "Ogre Magi",
            "Earth Spirit": 0.246
        },
        {
            "name": "Omniknight",
            "Earth Spirit": 1.422
        },
        {
            "name": "Oracle",
            "Earth Spirit": 1.325
        },
        {
            "name": "Outworld Destroyer",
            "Earth Spirit": 0.449
        },
        {
            "name": "Pangolier",
            "Earth Spirit": 0.396
        },
        {
            "name": "Phantom Assassin",
            "Earth Spirit": -1.891
        },
        {
            "name": "Phantom Lancer",
            "Earth Spirit": -0.111
        },
        {
            "name": "Phoenix",
            "Earth Spirit": 0.578
        },
        {
            "name": "Primal Beast",
            "Earth Spirit": 0.657
        },
        {
            "name": "Puck",
            "Earth Spirit": -1.106
        },
        {
            "name": "Pudge",
            "Earth Spirit": -1.216
        },
        {
            "name": "Pugna",
            "Earth Spirit": 0.458
        },
        {
            "name": "Queen of Pain",
            "Earth Spirit": -0.365
        },
        {
            "name": "Razor",
            "Earth Spirit": -1.612
        },
        {
            "name": "Riki",
            "Earth Spirit": -0.117
        },
        {
            "name": "Ringmaster",
            "Earth Spirit": -1.28
        },
        {
            "name": "Rubick",
            "Earth Spirit": -1.849
        },
        {
            "name": "Sand King",
            "Earth Spirit": -0.906
        },
        {
            "name": "Shadow Demon",
            "Earth Spirit": -1.181
        },
        {
            "name": "Shadow Fiend",
            "Earth Spirit": 0.745
        },
        {
            "name": "Shadow Shaman",
            "Earth Spirit": 1.027
        },
        {
            "name": "Silencer",
            "Earth Spirit": 0.362
        },
        {
            "name": "Skywrath Mage",
            "Earth Spirit": 0.658
        },
        {
            "name": "Slardar",
            "Earth Spirit": 1.383
        },
        {
            "name": "Slark",
            "Earth Spirit": 0.765
        },
        {
            "name": "Snapfire",
            "Earth Spirit": 0.162
        },
        {
            "name": "Sniper",
            "Earth Spirit": -3.356
        },
        {
            "name": "Spectre",
            "Earth Spirit": -1.41
        },
        {
            "name": "Spirit Breaker",
            "Earth Spirit": 0.433
        },
        {
            "name": "Storm Spirit",
            "Earth Spirit": -0.47
        },
        {
            "name": "Sven",
            "Earth Spirit": 1.361
        },
        {
            "name": "Techies",
            "Earth Spirit": -0.078
        },
        {
            "name": "Templar Assassin",
            "Earth Spirit": -0.752
        },
        {
            "name": "Terrorblade",
            "Earth Spirit": -0.418
        },
        {
            "name": "Tidehunter",
            "Earth Spirit": 2.492
        },
        {
            "name": "Timbersaw",
            "Earth Spirit": 0.41
        },
        {
            "name": "Tinker",
            "Earth Spirit": -0.579
        },
        {
            "name": "Tiny",
            "Earth Spirit": -0.804
        },
        {
            "name": "Treant Protector",
            "Earth Spirit": -0.29
        },
        {
            "name": "Troll Warlord",
            "Earth Spirit": 2.38
        },
        {
            "name": "Tusk",
            "Earth Spirit": 0.695
        },
        {
            "name": "Underlord",
            "Earth Spirit": -1.058
        },
        {
            "name": "Undying",
            "Earth Spirit": 0.369
        },
        {
            "name": "Ursa",
            "Earth Spirit": 1.905
        },
        {
            "name": "Vengeful Spirit",
            "Earth Spirit": 0.51
        },
        {
            "name": "Venomancer",
            "Earth Spirit": -0.74
        },
        {
            "name": "Viper",
            "Earth Spirit": -0.303
        },
        {
            "name": "Visage",
            "Earth Spirit": -0.727
        },
        {
            "name": "Void Spirit",
            "Earth Spirit": 0.999
        },
        {
            "name": "Warlock",
            "Earth Spirit": -1.011
        },
        {
            "name": "Weaver",
            "Earth Spirit": 0.822
        },
        {
            "name": "Windranger",
            "Earth Spirit": -0.598
        },
        {
            "name": "Winter Wyvern",
            "Earth Spirit": -0.259
        },
        {
            "name": "Witch Doctor",
            "Earth Spirit": 1.233
        },
        {
            "name": "Wraith King",
            "Earth Spirit": 1.177
        },
        {
            "name": "Zeus",
            "Earth Spirit": -0.084
        }
    ],
    "Earthshaker": [
        {
            "name": "Abaddon",
            "Earthshaker": -2.564
        },
        {
            "name": "Alchemist",
            "Earthshaker": 0.479
        },
        {
            "name": "Ancient Apparition",
            "Earthshaker": 0.323
        },
        {
            "name": "Anti-Mage",
            "Earthshaker": -2.507
        },
        {
            "name": "Arc Warden",
            "Earthshaker": -1.736
        },
        {
            "name": "Axe",
            "Earthshaker": 0.092
        },
        {
            "name": "Bane",
            "Earthshaker": -0.218
        },
        {
            "name": "Batrider",
            "Earthshaker": 1.429
        },
        {
            "name": "Beastmaster",
            "Earthshaker": -1.65
        },
        {
            "name": "Bloodseeker",
            "Earthshaker": 0.378
        },
        {
            "name": "Bounty Hunter",
            "Earthshaker": -0.869
        },
        {
            "name": "Brewmaster",
            "Earthshaker": -0.428
        },
        {
            "name": "Bristleback",
            "Earthshaker": 1.26
        },
        {
            "name": "Broodmother",
            "Earthshaker": -7.931
        },
        {
            "name": "Centaur Warrunner",
            "Earthshaker": -0.624
        },
        {
            "name": "Chaos Knight",
            "Earthshaker": -1.878
        },
        {
            "name": "Chen",
            "Earthshaker": -0.024
        },
        {
            "name": "Clinkz",
            "Earthshaker": 1.497
        },
        {
            "name": "Clockwerk",
            "Earthshaker": 1.159
        },
        {
            "name": "Crystal Maiden",
            "Earthshaker": -0.529
        },
        {
            "name": "Dark Seer",
            "Earthshaker": -2.104
        },
        {
            "name": "Dark Willow",
            "Earthshaker": 0.221
        },
        {
            "name": "Dawnbreaker",
            "Earthshaker": -0.382
        },
        {
            "name": "Dazzle",
            "Earthshaker": 0.105
        },
        {
            "name": "Death Prophet",
            "Earthshaker": 0.513
        },
        {
            "name": "Disruptor",
            "Earthshaker": 0.641
        },
        {
            "name": "Doom",
            "Earthshaker": 0.099
        },
        {
            "name": "Dragon Knight",
            "Earthshaker": 1.296
        },
        {
            "name": "Drow Ranger",
            "Earthshaker": 0.842
        },
        {
            "name": "Earth Spirit",
            "Earthshaker": -0.26
        },
        {
            "name": "Elder Titan",
            "Earthshaker": -1.083
        },
        {
            "name": "Ember Spirit",
            "Earthshaker": 0.096
        },
        {
            "name": "Enchantress",
            "Earthshaker": 0.704
        },
        {
            "name": "Enigma",
            "Earthshaker": -1.024
        },
        {
            "name": "Faceless Void",
            "Earthshaker": -0.017
        },
        {
            "name": "Grimstroke",
            "Earthshaker": 0.906
        },
        {
            "name": "Gyrocopter",
            "Earthshaker": 1.2
        },
        {
            "name": "Hoodwink",
            "Earthshaker": 1.326
        },
        {
            "name": "Huskar",
            "Earthshaker": 1.851
        },
        {
            "name": "Invoker",
            "Earthshaker": 0.063
        },
        {
            "name": "Io",
            "Earthshaker": -2.186
        },
        {
            "name": "Jakiro",
            "Earthshaker": 0.798
        },
        {
            "name": "Juggernaut",
            "Earthshaker": -0.032
        },
        {
            "name": "Keeper of the Light",
            "Earthshaker": -0.114
        },
        {
            "name": "Kez",
            "Earthshaker": 0.813
        },
        {
            "name": "Kunkka",
            "Earthshaker": 0.152
        },
        {
            "name": "Legion Commander",
            "Earthshaker": -0.89
        },
        {
            "name": "Leshrac",
            "Earthshaker": 0.451
        },
        {
            "name": "Lich",
            "Earthshaker": -0.429
        },
        {
            "name": "Lifestealer",
            "Earthshaker": 0.167
        },
        {
            "name": "Lina",
            "Earthshaker": 0.174
        },
        {
            "name": "Lion",
            "Earthshaker": 0.545
        },
        {
            "name": "Lone Druid",
            "Earthshaker": -3.145
        },
        {
            "name": "Luna",
            "Earthshaker": 0.53
        },
        {
            "name": "Lycan",
            "Earthshaker": -2.997
        },
        {
            "name": "Magnus",
            "Earthshaker": -0.893
        },
        {
            "name": "Marci",
            "Earthshaker": -1.641
        },
        {
            "name": "Mars",
            "Earthshaker": 0.191
        },
        {
            "name": "Medusa",
            "Earthshaker": -0.752
        },
        {
            "name": "Meepo",
            "Earthshaker": -6.39
        },
        {
            "name": "Mirana",
            "Earthshaker": 0.788
        },
        {
            "name": "Monkey King",
            "Earthshaker": 0.953
        },
        {
            "name": "Morphling",
            "Earthshaker": -1.703
        },
        {
            "name": "Muerta",
            "Earthshaker": 0.601
        },
        {
            "name": "Naga Siren",
            "Earthshaker": -1.657
        },
        {
            "name": "Nature's Prophet",
            "Earthshaker": 0.955
        },
        {
            "name": "Necrophos",
            "Earthshaker": 0.514
        },
        {
            "name": "Night Stalker",
            "Earthshaker": 0.745
        },
        {
            "name": "Nyx Assassin",
            "Earthshaker": -0.755
        },
        {
            "name": "Ogre Magi",
            "Earthshaker": 0.21
        },
        {
            "name": "Omniknight",
            "Earthshaker": -1.532
        },
        {
            "name": "Oracle",
            "Earthshaker": -1.479
        },
        {
            "name": "Outworld Destroyer",
            "Earthshaker": -0.084
        },
        {
            "name": "Pangolier",
            "Earthshaker": -0.494
        },
        {
            "name": "Phantom Assassin",
            "Earthshaker": 0.733
        },
        {
            "name": "Phantom Lancer",
            "Earthshaker": -2.442
        },
        {
            "name": "Phoenix",
            "Earthshaker": 0.062
        },
        {
            "name": "Primal Beast",
            "Earthshaker": -0.217
        },
        {
            "name": "Puck",
            "Earthshaker": -0.231
        },
        {
            "name": "Pudge",
            "Earthshaker": -0.695
        },
        {
            "name": "Pugna",
            "Earthshaker": -1.155
        },
        {
            "name": "Queen of Pain",
            "Earthshaker": -0.089
        },
        {
            "name": "Razor",
            "Earthshaker": 0.545
        },
        {
            "name": "Riki",
            "Earthshaker": 0.054
        },
        {
            "name": "Ringmaster",
            "Earthshaker": -0.342
        },
        {
            "name": "Rubick",
            "Earthshaker": -0.423
        },
        {
            "name": "Sand King",
            "Earthshaker": 0.783
        },
        {
            "name": "Shadow Demon",
            "Earthshaker": -1.123
        },
        {
            "name": "Shadow Fiend",
            "Earthshaker": 0.416
        },
        {
            "name": "Shadow Shaman",
            "Earthshaker": -0.138
        },
        {
            "name": "Silencer",
            "Earthshaker": 0.647
        },
        {
            "name": "Skywrath Mage",
            "Earthshaker": 0.364
        },
        {
            "name": "Slardar",
            "Earthshaker": 0.183
        },
        {
            "name": "Slark",
            "Earthshaker": -2.465
        },
        {
            "name": "Snapfire",
            "Earthshaker": 0.569
        },
        {
            "name": "Sniper",
            "Earthshaker": 1.616
        },
        {
            "name": "Spectre",
            "Earthshaker": 0.47
        },
        {
            "name": "Spirit Breaker",
            "Earthshaker": -0.127
        },
        {
            "name": "Storm Spirit",
            "Earthshaker": -0.791
        },
        {
            "name": "Sven",
            "Earthshaker": 1.374
        },
        {
            "name": "Techies",
            "Earthshaker": 0.86
        },
        {
            "name": "Templar Assassin",
            "Earthshaker": 2.197
        },
        {
            "name": "Terrorblade",
            "Earthshaker": -1.034
        },
        {
            "name": "Tidehunter",
            "Earthshaker": 0.026
        },
        {
            "name": "Timbersaw",
            "Earthshaker": -0.387
        },
        {
            "name": "Tinker",
            "Earthshaker": -0.893
        },
        {
            "name": "Tiny",
            "Earthshaker": 0.564
        },
        {
            "name": "Treant Protector",
            "Earthshaker": 0.217
        },
        {
            "name": "Troll Warlord",
            "Earthshaker": 0.854
        },
        {
            "name": "Tusk",
            "Earthshaker": -0.717
        },
        {
            "name": "Underlord",
            "Earthshaker": 0.149
        },
        {
            "name": "Undying",
            "Earthshaker": -0.845
        },
        {
            "name": "Ursa",
            "Earthshaker": -0.045
        },
        {
            "name": "Vengeful Spirit",
            "Earthshaker": -0.11
        },
        {
            "name": "Venomancer",
            "Earthshaker": 0.684
        },
        {
            "name": "Viper",
            "Earthshaker": 1.475
        },
        {
            "name": "Visage",
            "Earthshaker": -2.927
        },
        {
            "name": "Void Spirit",
            "Earthshaker": -1.312
        },
        {
            "name": "Warlock",
            "Earthshaker": -1.734
        },
        {
            "name": "Weaver",
            "Earthshaker": -0.31
        },
        {
            "name": "Windranger",
            "Earthshaker": 1.374
        },
        {
            "name": "Winter Wyvern",
            "Earthshaker": 0.786
        },
        {
            "name": "Witch Doctor",
            "Earthshaker": -0.48
        },
        {
            "name": "Wraith King",
            "Earthshaker": 0.166
        },
        {
            "name": "Zeus",
            "Earthshaker": 0.588
        }
    ],
    "Elder Titan": [
        {
            "name": "Abaddon",
            "Elder Titan": -3.729
        },
        {
            "name": "Alchemist",
            "Elder Titan": -0.18
        },
        {
            "name": "Ancient Apparition",
            "Elder Titan": -2.125
        },
        {
            "name": "Anti-Mage",
            "Elder Titan": -1.47
        },
        {
            "name": "Arc Warden",
            "Elder Titan": -0.327
        },
        {
            "name": "Axe",
            "Elder Titan": 0.698
        },
        {
            "name": "Bane",
            "Elder Titan": 2.428
        },
        {
            "name": "Batrider",
            "Elder Titan": 1.098
        },
        {
            "name": "Beastmaster",
            "Elder Titan": -3.311
        },
        {
            "name": "Bloodseeker",
            "Elder Titan": -0.151
        },
        {
            "name": "Bounty Hunter",
            "Elder Titan": 0.664
        },
        {
            "name": "Brewmaster",
            "Elder Titan": -0.583
        },
        {
            "name": "Bristleback",
            "Elder Titan": 2.374
        },
        {
            "name": "Broodmother",
            "Elder Titan": 1.725
        },
        {
            "name": "Centaur Warrunner",
            "Elder Titan": -1.343
        },
        {
            "name": "Chaos Knight",
            "Elder Titan": -0.487
        },
        {
            "name": "Chen",
            "Elder Titan": 3.379
        },
        {
            "name": "Clinkz",
            "Elder Titan": 4.259
        },
        {
            "name": "Clockwerk",
            "Elder Titan": -1.516
        },
        {
            "name": "Crystal Maiden",
            "Elder Titan": -0.039
        },
        {
            "name": "Dark Seer",
            "Elder Titan": 1.923
        },
        {
            "name": "Dark Willow",
            "Elder Titan": -1.412
        },
        {
            "name": "Dawnbreaker",
            "Elder Titan": -2.26
        },
        {
            "name": "Dazzle",
            "Elder Titan": 0.602
        },
        {
            "name": "Death Prophet",
            "Elder Titan": -0.971
        },
        {
            "name": "Disruptor",
            "Elder Titan": -0.333
        },
        {
            "name": "Doom",
            "Elder Titan": -0.002
        },
        {
            "name": "Dragon Knight",
            "Elder Titan": 0.9
        },
        {
            "name": "Drow Ranger",
            "Elder Titan": -0.663
        },
        {
            "name": "Earth Spirit",
            "Elder Titan": -1.928
        },
        {
            "name": "Earthshaker",
            "Elder Titan": 0.789
        },
        {
            "name": "Ember Spirit",
            "Elder Titan": -0.962
        },
        {
            "name": "Enchantress",
            "Elder Titan": 0.578
        },
        {
            "name": "Enigma",
            "Elder Titan": -0.142
        },
        {
            "name": "Faceless Void",
            "Elder Titan": -0.2
        },
        {
            "name": "Grimstroke",
            "Elder Titan": -0.662
        },
        {
            "name": "Gyrocopter",
            "Elder Titan": -1.82
        },
        {
            "name": "Hoodwink",
            "Elder Titan": 1.265
        },
        {
            "name": "Huskar",
            "Elder Titan": 0.263
        },
        {
            "name": "Invoker",
            "Elder Titan": 0.607
        },
        {
            "name": "Io",
            "Elder Titan": -1.776
        },
        {
            "name": "Jakiro",
            "Elder Titan": 0.007
        },
        {
            "name": "Juggernaut",
            "Elder Titan": 1.629
        },
        {
            "name": "Keeper of the Light",
            "Elder Titan": 1.051
        },
        {
            "name": "Kez",
            "Elder Titan": -0.562
        },
        {
            "name": "Kunkka",
            "Elder Titan": -0.724
        },
        {
            "name": "Legion Commander",
            "Elder Titan": 0.432
        },
        {
            "name": "Leshrac",
            "Elder Titan": -0.53
        },
        {
            "name": "Lich",
            "Elder Titan": -0.526
        },
        {
            "name": "Lifestealer",
            "Elder Titan": 0.561
        },
        {
            "name": "Lina",
            "Elder Titan": 1.534
        },
        {
            "name": "Lion",
            "Elder Titan": 0.446
        },
        {
            "name": "Lone Druid",
            "Elder Titan": -4.129
        },
        {
            "name": "Luna",
            "Elder Titan": 0.637
        },
        {
            "name": "Lycan",
            "Elder Titan": 0.444
        },
        {
            "name": "Magnus",
            "Elder Titan": -0.958
        },
        {
            "name": "Marci",
            "Elder Titan": -1.44
        },
        {
            "name": "Mars",
            "Elder Titan": -1.727
        },
        {
            "name": "Medusa",
            "Elder Titan": 0.216
        },
        {
            "name": "Meepo",
            "Elder Titan": -11.348
        },
        {
            "name": "Mirana",
            "Elder Titan": 0.558
        },
        {
            "name": "Monkey King",
            "Elder Titan": -0.409
        },
        {
            "name": "Morphling",
            "Elder Titan": -1.217
        },
        {
            "name": "Muerta",
            "Elder Titan": -1.542
        },
        {
            "name": "Naga Siren",
            "Elder Titan": -0.698
        },
        {
            "name": "Nature's Prophet",
            "Elder Titan": 2.655
        },
        {
            "name": "Necrophos",
            "Elder Titan": 0.096
        },
        {
            "name": "Night Stalker",
            "Elder Titan": -1.462
        },
        {
            "name": "Nyx Assassin",
            "Elder Titan": -0.72
        },
        {
            "name": "Ogre Magi",
            "Elder Titan": -0.396
        },
        {
            "name": "Omniknight",
            "Elder Titan": -0.279
        },
        {
            "name": "Oracle",
            "Elder Titan": -0.714
        },
        {
            "name": "Outworld Destroyer",
            "Elder Titan": -0.023
        },
        {
            "name": "Pangolier",
            "Elder Titan": -0.987
        },
        {
            "name": "Phantom Assassin",
            "Elder Titan": 3.741
        },
        {
            "name": "Phantom Lancer",
            "Elder Titan": -0.849
        },
        {
            "name": "Phoenix",
            "Elder Titan": 0.241
        },
        {
            "name": "Primal Beast",
            "Elder Titan": -0.162
        },
        {
            "name": "Puck",
            "Elder Titan": 0.759
        },
        {
            "name": "Pudge",
            "Elder Titan": -1.492
        },
        {
            "name": "Pugna",
            "Elder Titan": -1.085
        },
        {
            "name": "Queen of Pain",
            "Elder Titan": -1.251
        },
        {
            "name": "Razor",
            "Elder Titan": -2.265
        },
        {
            "name": "Riki",
            "Elder Titan": 0.525
        },
        {
            "name": "Ringmaster",
            "Elder Titan": -0.311
        },
        {
            "name": "Rubick",
            "Elder Titan": -2.178
        },
        {
            "name": "Sand King",
            "Elder Titan": 0.41
        },
        {
            "name": "Shadow Demon",
            "Elder Titan": 2.517
        },
        {
            "name": "Shadow Fiend",
            "Elder Titan": -0.211
        },
        {
            "name": "Shadow Shaman",
            "Elder Titan": 0.007
        },
        {
            "name": "Silencer",
            "Elder Titan": 0.621
        },
        {
            "name": "Skywrath Mage",
            "Elder Titan": 1.437
        },
        {
            "name": "Slardar",
            "Elder Titan": 1.869
        },
        {
            "name": "Slark",
            "Elder Titan": -3.873
        },
        {
            "name": "Snapfire",
            "Elder Titan": 0.317
        },
        {
            "name": "Sniper",
            "Elder Titan": 1.103
        },
        {
            "name": "Spectre",
            "Elder Titan": 0.202
        },
        {
            "name": "Spirit Breaker",
            "Elder Titan": 1.978
        },
        {
            "name": "Storm Spirit",
            "Elder Titan": 1.596
        },
        {
            "name": "Sven",
            "Elder Titan": 3.639
        },
        {
            "name": "Techies",
            "Elder Titan": 0.826
        },
        {
            "name": "Templar Assassin",
            "Elder Titan": 1.798
        },
        {
            "name": "Terrorblade",
            "Elder Titan": -1.092
        },
        {
            "name": "Tidehunter",
            "Elder Titan": -3.234
        },
        {
            "name": "Timbersaw",
            "Elder Titan": -1.172
        },
        {
            "name": "Tinker",
            "Elder Titan": 0.034
        },
        {
            "name": "Tiny",
            "Elder Titan": 1.306
        },
        {
            "name": "Treant Protector",
            "Elder Titan": 1.982
        },
        {
            "name": "Troll Warlord",
            "Elder Titan": 6.123
        },
        {
            "name": "Tusk",
            "Elder Titan": -0.861
        },
        {
            "name": "Underlord",
            "Elder Titan": -4.036
        },
        {
            "name": "Undying",
            "Elder Titan": -2.222
        },
        {
            "name": "Ursa",
            "Elder Titan": 2.254
        },
        {
            "name": "Vengeful Spirit",
            "Elder Titan": 0.265
        },
        {
            "name": "Venomancer",
            "Elder Titan": 1.443
        },
        {
            "name": "Viper",
            "Elder Titan": -0.387
        },
        {
            "name": "Visage",
            "Elder Titan": 2.649
        },
        {
            "name": "Void Spirit",
            "Elder Titan": -1.924
        },
        {
            "name": "Warlock",
            "Elder Titan": 0.141
        },
        {
            "name": "Weaver",
            "Elder Titan": 0.611
        },
        {
            "name": "Windranger",
            "Elder Titan": 2.39
        },
        {
            "name": "Winter Wyvern",
            "Elder Titan": 1.251
        },
        {
            "name": "Witch Doctor",
            "Elder Titan": -0.27
        },
        {
            "name": "Wraith King",
            "Elder Titan": 2.204
        },
        {
            "name": "Zeus",
            "Elder Titan": 1.589
        }
    ],
    "Huskar": [
        {
            "name": "Abaddon",
            "Huskar": 0.125
        },
        {
            "name": "Alchemist",
            "Huskar": -0.974
        },
        {
            "name": "Ancient Apparition",
            "Huskar": 6.574
        },
        {
            "name": "Anti-Mage",
            "Huskar": -6.319
        },
        {
            "name": "Arc Warden",
            "Huskar": 2.308
        },
        {
            "name": "Axe",
            "Huskar": 1.997
        },
        {
            "name": "Bane",
            "Huskar": -1.052
        },
        {
            "name": "Batrider",
            "Huskar": -1.776
        },
        {
            "name": "Beastmaster",
            "Huskar": 0.705
        },
        {
            "name": "Bloodseeker",
            "Huskar": 1.284
        },
        {
            "name": "Bounty Hunter",
            "Huskar": -1.656
        },
        {
            "name": "Brewmaster",
            "Huskar": -1.386
        },
        {
            "name": "Bristleback",
            "Huskar": 1.768
        },
        {
            "name": "Broodmother",
            "Huskar": -0.012
        },
        {
            "name": "Centaur Warrunner",
            "Huskar": -1.613
        },
        {
            "name": "Chaos Knight",
            "Huskar": 4.177
        },
        {
            "name": "Chen",
            "Huskar": 0.125
        },
        {
            "name": "Clinkz",
            "Huskar": 1.09
        },
        {
            "name": "Clockwerk",
            "Huskar": -4.137
        },
        {
            "name": "Crystal Maiden",
            "Huskar": 0.423
        },
        {
            "name": "Dark Seer",
            "Huskar": -0.182
        },
        {
            "name": "Dark Willow",
            "Huskar": 0.755
        },
        {
            "name": "Dawnbreaker",
            "Huskar": -0.858
        },
        {
            "name": "Dazzle",
            "Huskar": 0.811
        },
        {
            "name": "Death Prophet",
            "Huskar": 0.343
        },
        {
            "name": "Disruptor",
            "Huskar": -1.722
        },
        {
            "name": "Doom",
            "Huskar": 1.388
        },
        {
            "name": "Dragon Knight",
            "Huskar": -1.935
        },
        {
            "name": "Drow Ranger",
            "Huskar": 1.454
        },
        {
            "name": "Earth Spirit",
            "Huskar": -3.545
        },
        {
            "name": "Earthshaker",
            "Huskar": -1.997
        },
        {
            "name": "Elder Titan",
            "Huskar": -0.448
        },
        {
            "name": "Ember Spirit",
            "Huskar": -5.524
        },
        {
            "name": "Enchantress",
            "Huskar": 1.256
        },
        {
            "name": "Enigma",
            "Huskar": 0.916
        },
        {
            "name": "Faceless Void",
            "Huskar": -1.795
        },
        {
            "name": "Grimstroke",
            "Huskar": -0.86
        },
        {
            "name": "Gyrocopter",
            "Huskar": -0.988
        },
        {
            "name": "Hoodwink",
            "Huskar": 2.103
        },
        {
            "name": "Invoker",
            "Huskar": -0.192
        },
        {
            "name": "Io",
            "Huskar": 0.703
        },
        {
            "name": "Jakiro",
            "Huskar": -0.285
        },
        {
            "name": "Juggernaut",
            "Huskar": -1.245
        },
        {
            "name": "Keeper of the Light",
            "Huskar": -2.865
        },
        {
            "name": "Kez",
            "Huskar": 1.506
        },
        {
            "name": "Kunkka",
            "Huskar": -0.817
        },
        {
            "name": "Legion Commander",
            "Huskar": 1.658
        },
        {
            "name": "Leshrac",
            "Huskar": -0.335
        },
        {
            "name": "Lich",
            "Huskar": 0.214
        },
        {
            "name": "Lifestealer",
            "Huskar": -0.833
        },
        {
            "name": "Lina",
            "Huskar": 0.402
        },
        {
            "name": "Lion",
            "Huskar": 0.993
        },
        {
            "name": "Lone Druid",
            "Huskar": -3.539
        },
        {
            "name": "Luna",
            "Huskar": 2.178
        },
        {
            "name": "Lycan",
            "Huskar": -2.753
        },
        {
            "name": "Magnus",
            "Huskar": -1.994
        },
        {
            "name": "Marci",
            "Huskar": 0.601
        },
        {
            "name": "Mars",
            "Huskar": -2.146
        },
        {
            "name": "Medusa",
            "Huskar": 0.988
        },
        {
            "name": "Meepo",
            "Huskar": -3.917
        },
        {
            "name": "Mirana",
            "Huskar": -0.441
        },
        {
            "name": "Monkey King",
            "Huskar": -0.309
        },
        {
            "name": "Morphling",
            "Huskar": 0.835
        },
        {
            "name": "Muerta",
            "Huskar": -0.35
        },
        {
            "name": "Naga Siren",
            "Huskar": 3.842
        },
        {
            "name": "Nature's Prophet",
            "Huskar": 0.803
        },
        {
            "name": "Necrophos",
            "Huskar": 4.362
        },
        {
            "name": "Night Stalker",
            "Huskar": -4.778
        },
        {
            "name": "Nyx Assassin",
            "Huskar": -2.203
        },
        {
            "name": "Ogre Magi",
            "Huskar": -0.88
        },
        {
            "name": "Omniknight",
            "Huskar": -0.806
        },
        {
            "name": "Oracle",
            "Huskar": -1.492
        },
        {
            "name": "Outworld Destroyer",
            "Huskar": -2.309
        },
        {
            "name": "Pangolier",
            "Huskar": -4.251
        },
        {
            "name": "Phantom Assassin",
            "Huskar": 3.52
        },
        {
            "name": "Phantom Lancer",
            "Huskar": 2.985
        },
        {
            "name": "Phoenix",
            "Huskar": -1.747
        },
        {
            "name": "Primal Beast",
            "Huskar": -1.385
        },
        {
            "name": "Puck",
            "Huskar": -2.56
        },
        {
            "name": "Pudge",
            "Huskar": 0.755
        },
        {
            "name": "Pugna",
            "Huskar": -3.86
        },
        {
            "name": "Queen of Pain",
            "Huskar": -2.889
        },
        {
            "name": "Razor",
            "Huskar": -0.593
        },
        {
            "name": "Riki",
            "Huskar": 0.143
        },
        {
            "name": "Ringmaster",
            "Huskar": -0.214
        },
        {
            "name": "Rubick",
            "Huskar": -1.218
        },
        {
            "name": "Sand King",
            "Huskar": -0.752
        },
        {
            "name": "Shadow Demon",
            "Huskar": 1.934
        },
        {
            "name": "Shadow Fiend",
            "Huskar": 1.159
        },
        {
            "name": "Shadow Shaman",
            "Huskar": 0.902
        },
        {
            "name": "Silencer",
            "Huskar": -1.594
        },
        {
            "name": "Skywrath Mage",
            "Huskar": -0.405
        },
        {
            "name": "Slardar",
            "Huskar": -0.703
        },
        {
            "name": "Slark",
            "Huskar": -0.369
        },
        {
            "name": "Snapfire",
            "Huskar": -0.814
        },
        {
            "name": "Sniper",
            "Huskar": 2.297
        },
        {
            "name": "Spectre",
            "Huskar": -0.237
        },
        {
            "name": "Spirit Breaker",
            "Huskar": 0.232
        },
        {
            "name": "Storm Spirit",
            "Huskar": -1.687
        },
        {
            "name": "Sven",
            "Huskar": -1.381
        },
        {
            "name": "Techies",
            "Huskar": 0.335
        },
        {
            "name": "Templar Assassin",
            "Huskar": -0.29
        },
        {
            "name": "Terrorblade",
            "Huskar": 0.715
        },
        {
            "name": "Tidehunter",
            "Huskar": -2.681
        },
        {
            "name": "Timbersaw",
            "Huskar": 2.477
        },
        {
            "name": "Tinker",
            "Huskar": 0.418
        },
        {
            "name": "Tiny",
            "Huskar": -3.073
        },
        {
            "name": "Treant Protector",
            "Huskar": -0.633
        },
        {
            "name": "Troll Warlord",
            "Huskar": 0.808
        },
        {
            "name": "Tusk",
            "Huskar": 0.131
        },
        {
            "name": "Underlord",
            "Huskar": -1.949
        },
        {
            "name": "Undying",
            "Huskar": 1.089
        },
        {
            "name": "Ursa",
            "Huskar": 0.276
        },
        {
            "name": "Vengeful Spirit",
            "Huskar": 0.647
        },
        {
            "name": "Venomancer",
            "Huskar": -0.518
        },
        {
            "name": "Viper",
            "Huskar": 6.878
        },
        {
            "name": "Visage",
            "Huskar": 1.22
        },
        {
            "name": "Void Spirit",
            "Huskar": -4.174
        },
        {
            "name": "Warlock",
            "Huskar": 0.263
        },
        {
            "name": "Weaver",
            "Huskar": -0.594
        },
        {
            "name": "Windranger",
            "Huskar": 3.777
        },
        {
            "name": "Winter Wyvern",
            "Huskar": -1.808
        },
        {
            "name": "Witch Doctor",
            "Huskar": 1.304
        },
        {
            "name": "Wraith King",
            "Huskar": 1.15
        },
        {
            "name": "Zeus",
            "Huskar": -1.755
        }
    ],
    "Kunkka": [
        {
            "name": "Abaddon",
            "Kunkka": -1.512
        },
        {
            "name": "Alchemist",
            "Kunkka": 0.693
        },
        {
            "name": "Ancient Apparition",
            "Kunkka": 1.177
        },
        {
            "name": "Anti-Mage",
            "Kunkka": -0.499
        },
        {
            "name": "Arc Warden",
            "Kunkka": -3.188
        },
        {
            "name": "Axe",
            "Kunkka": -0.479
        },
        {
            "name": "Bane",
            "Kunkka": 1.288
        },
        {
            "name": "Batrider",
            "Kunkka": -0.559
        },
        {
            "name": "Beastmaster",
            "Kunkka": -1.109
        },
        {
            "name": "Bloodseeker",
            "Kunkka": 0.736
        },
        {
            "name": "Bounty Hunter",
            "Kunkka": 0.827
        },
        {
            "name": "Brewmaster",
            "Kunkka": 0.691
        },
        {
            "name": "Bristleback",
            "Kunkka": 1.244
        },
        {
            "name": "Broodmother",
            "Kunkka": -0.762
        },
        {
            "name": "Centaur Warrunner",
            "Kunkka": -0.208
        },
        {
            "name": "Chaos Knight",
            "Kunkka": -2.008
        },
        {
            "name": "Chen",
            "Kunkka": 0.548
        },
        {
            "name": "Clinkz",
            "Kunkka": 0.944
        },
        {
            "name": "Clockwerk",
            "Kunkka": -1.232
        },
        {
            "name": "Crystal Maiden",
            "Kunkka": -0.078
        },
        {
            "name": "Dark Seer",
            "Kunkka": 0.526
        },
        {
            "name": "Dark Willow",
            "Kunkka": 0.49
        },
        {
            "name": "Dawnbreaker",
            "Kunkka": -1.284
        },
        {
            "name": "Dazzle",
            "Kunkka": 0.489
        },
        {
            "name": "Death Prophet",
            "Kunkka": 1.212
        },
        {
            "name": "Disruptor",
            "Kunkka": -1.038
        },
        {
            "name": "Doom",
            "Kunkka": 0.4
        },
        {
            "name": "Dragon Knight",
            "Kunkka": -0.469
        },
        {
            "name": "Drow Ranger",
            "Kunkka": -0.893
        },
        {
            "name": "Earth Spirit",
            "Kunkka": -0.776
        },
        {
            "name": "Earthshaker",
            "Kunkka": -0.009
        },
        {
            "name": "Elder Titan",
            "Kunkka": 0.591
        },
        {
            "name": "Ember Spirit",
            "Kunkka": 1.9
        },
        {
            "name": "Enchantress",
            "Kunkka": 0.456
        },
        {
            "name": "Enigma",
            "Kunkka": -2.35
        },
        {
            "name": "Faceless Void",
            "Kunkka": 1.612
        },
        {
            "name": "Grimstroke",
            "Kunkka": -0.364
        },
        {
            "name": "Gyrocopter",
            "Kunkka": -0.27
        },
        {
            "name": "Hoodwink",
            "Kunkka": -0.341
        },
        {
            "name": "Huskar",
            "Kunkka": 0.886
        },
        {
            "name": "Invoker",
            "Kunkka": 0.341
        },
        {
            "name": "Io",
            "Kunkka": -2.106
        },
        {
            "name": "Jakiro",
            "Kunkka": 0.394
        },
        {
            "name": "Juggernaut",
            "Kunkka": 2.411
        },
        {
            "name": "Keeper of the Light",
            "Kunkka": 0.792
        },
        {
            "name": "Kez",
            "Kunkka": 0.982
        },
        {
            "name": "Legion Commander",
            "Kunkka": -2.114
        },
        {
            "name": "Leshrac",
            "Kunkka": -0.311
        },
        {
            "name": "Lich",
            "Kunkka": 0.461
        },
        {
            "name": "Lifestealer",
            "Kunkka": 2.317
        },
        {
            "name": "Lina",
            "Kunkka": -0.62
        },
        {
            "name": "Lion",
            "Kunkka": -0.491
        },
        {
            "name": "Lone Druid",
            "Kunkka": -1.675
        },
        {
            "name": "Luna",
            "Kunkka": -1.374
        },
        {
            "name": "Lycan",
            "Kunkka": 0.293
        },
        {
            "name": "Magnus",
            "Kunkka": -0.871
        },
        {
            "name": "Marci",
            "Kunkka": -1.879
        },
        {
            "name": "Mars",
            "Kunkka": -0.323
        },
        {
            "name": "Medusa",
            "Kunkka": -3.251
        },
        {
            "name": "Meepo",
            "Kunkka": -3.565
        },
        {
            "name": "Mirana",
            "Kunkka": -0.741
        },
        {
            "name": "Monkey King",
            "Kunkka": 0.632
        },
        {
            "name": "Morphling",
            "Kunkka": 0.254
        },
        {
            "name": "Muerta",
            "Kunkka": -1.875
        },
        {
            "name": "Naga Siren",
            "Kunkka": -0.664
        },
        {
            "name": "Nature's Prophet",
            "Kunkka": 0.205
        },
        {
            "name": "Necrophos",
            "Kunkka": 1.557
        },
        {
            "name": "Night Stalker",
            "Kunkka": -0.753
        },
        {
            "name": "Nyx Assassin",
            "Kunkka": -1.047
        },
        {
            "name": "Ogre Magi",
            "Kunkka": 0.744
        },
        {
            "name": "Omniknight",
            "Kunkka": -0.509
        },
        {
            "name": "Oracle",
            "Kunkka": -0.507
        },
        {
            "name": "Outworld Destroyer",
            "Kunkka": -0.469
        },
        {
            "name": "Pangolier",
            "Kunkka": 0.595
        },
        {
            "name": "Phantom Assassin",
            "Kunkka": 0.651
        },
        {
            "name": "Phantom Lancer",
            "Kunkka": 0.382
        },
        {
            "name": "Phoenix",
            "Kunkka": 0.547
        },
        {
            "name": "Primal Beast",
            "Kunkka": 0.104
        },
        {
            "name": "Puck",
            "Kunkka": -0.05
        },
        {
            "name": "Pudge",
            "Kunkka": -1.072
        },
        {
            "name": "Pugna",
            "Kunkka": 0.074
        },
        {
            "name": "Queen of Pain",
            "Kunkka": 0.277
        },
        {
            "name": "Razor",
            "Kunkka": 0.37
        },
        {
            "name": "Riki",
            "Kunkka": -0.079
        },
        {
            "name": "Ringmaster",
            "Kunkka": 0.02
        },
        {
            "name": "Rubick",
            "Kunkka": 1.184
        },
        {
            "name": "Sand King",
            "Kunkka": -0.853
        },
        {
            "name": "Shadow Demon",
            "Kunkka": -0.127
        },
        {
            "name": "Shadow Fiend",
            "Kunkka": -1.111
        },
        {
            "name": "Shadow Shaman",
            "Kunkka": -0.17
        },
        {
            "name": "Silencer",
            "Kunkka": 0.004
        },
        {
            "name": "Skywrath Mage",
            "Kunkka": -0.217
        },
        {
            "name": "Slardar",
            "Kunkka": 1.753
        },
        {
            "name": "Slark",
            "Kunkka": -0.578
        },
        {
            "name": "Snapfire",
            "Kunkka": -0.382
        },
        {
            "name": "Sniper",
            "Kunkka": -0.015
        },
        {
            "name": "Spectre",
            "Kunkka": -0.6
        },
        {
            "name": "Spirit Breaker",
            "Kunkka": 0.009
        },
        {
            "name": "Storm Spirit",
            "Kunkka": 0.467
        },
        {
            "name": "Sven",
            "Kunkka": -0.437
        },
        {
            "name": "Techies",
            "Kunkka": -0.372
        },
        {
            "name": "Templar Assassin",
            "Kunkka": -0.111
        },
        {
            "name": "Terrorblade",
            "Kunkka": -1.519
        },
        {
            "name": "Tidehunter",
            "Kunkka": -0.496
        },
        {
            "name": "Timbersaw",
            "Kunkka": 2.266
        },
        {
            "name": "Tinker",
            "Kunkka": 1.726
        },
        {
            "name": "Tiny",
            "Kunkka": -0.583
        },
        {
            "name": "Treant Protector",
            "Kunkka": 0.804
        },
        {
            "name": "Troll Warlord",
            "Kunkka": -0.325
        },
        {
            "name": "Tusk",
            "Kunkka": 0.286
        },
        {
            "name": "Underlord",
            "Kunkka": 1.025
        },
        {
            "name": "Undying",
            "Kunkka": 1.045
        },
        {
            "name": "Ursa",
            "Kunkka": -0.328
        },
        {
            "name": "Vengeful Spirit",
            "Kunkka": -0.233
        },
        {
            "name": "Venomancer",
            "Kunkka": 1.93
        },
        {
            "name": "Viper",
            "Kunkka": -0.254
        },
        {
            "name": "Visage",
            "Kunkka": -0.78
        },
        {
            "name": "Void Spirit",
            "Kunkka": 0.015
        },
        {
            "name": "Warlock",
            "Kunkka": 1.051
        },
        {
            "name": "Weaver",
            "Kunkka": 1.815
        },
        {
            "name": "Windranger",
            "Kunkka": -0.121
        },
        {
            "name": "Winter Wyvern",
            "Kunkka": 0.811
        },
        {
            "name": "Witch Doctor",
            "Kunkka": 0.531
        },
        {
            "name": "Wraith King",
            "Kunkka": 0.059
        },
        {
            "name": "Zeus",
            "Kunkka": 0.246
        }
    ],
    "Legion Commander": [
        {
            "name": "Abaddon",
            "Legion Commander": 1.106
        },
        {
            "name": "Alchemist",
            "Legion Commander": -0.027
        },
        {
            "name": "Ancient Apparition",
            "Legion Commander": -0.325
        },
        {
            "name": "Anti-Mage",
            "Legion Commander": -1.471
        },
        {
            "name": "Arc Warden",
            "Legion Commander": -1.898
        },
        {
            "name": "Axe",
            "Legion Commander": 1.056
        },
        {
            "name": "Bane",
            "Legion Commander": 0.788
        },
        {
            "name": "Batrider",
            "Legion Commander": 0.464
        },
        {
            "name": "Beastmaster",
            "Legion Commander": 0.81
        },
        {
            "name": "Bloodseeker",
            "Legion Commander": -2.54
        },
        {
            "name": "Bounty Hunter",
            "Legion Commander": -1.447
        },
        {
            "name": "Brewmaster",
            "Legion Commander": -0.676
        },
        {
            "name": "Bristleback",
            "Legion Commander": -3.874
        },
        {
            "name": "Broodmother",
            "Legion Commander": -0.978
        },
        {
            "name": "Centaur Warrunner",
            "Legion Commander": 0.451
        },
        {
            "name": "Chaos Knight",
            "Legion Commander": 2.654
        },
        {
            "name": "Chen",
            "Legion Commander": 1.307
        },
        {
            "name": "Clinkz",
            "Legion Commander": -1.589
        },
        {
            "name": "Clockwerk",
            "Legion Commander": 0.286
        },
        {
            "name": "Crystal Maiden",
            "Legion Commander": -0.26
        },
        {
            "name": "Dark Seer",
            "Legion Commander": -0.843
        },
        {
            "name": "Dark Willow",
            "Legion Commander": -0.643
        },
        {
            "name": "Dawnbreaker",
            "Legion Commander": 3.142
        },
        {
            "name": "Dazzle",
            "Legion Commander": 1.931
        },
        {
            "name": "Death Prophet",
            "Legion Commander": 0.058
        },
        {
            "name": "Disruptor",
            "Legion Commander": -0.516
        },
        {
            "name": "Doom",
            "Legion Commander": 0.557
        },
        {
            "name": "Dragon Knight",
            "Legion Commander": 2.748
        },
        {
            "name": "Drow Ranger",
            "Legion Commander": 0.913
        },
        {
            "name": "Earth Spirit",
            "Legion Commander": -0.779
        },
        {
            "name": "Earthshaker",
            "Legion Commander": 0.889
        },
        {
            "name": "Elder Titan",
            "Legion Commander": -0.177
        },
        {
            "name": "Ember Spirit",
            "Legion Commander": -1.404
        },
        {
            "name": "Enchantress",
            "Legion Commander": 0.461
        },
        {
            "name": "Enigma",
            "Legion Commander": -0.354
        },
        {
            "name": "Faceless Void",
            "Legion Commander": -0.67
        },
        {
            "name": "Grimstroke",
            "Legion Commander": 0.385
        },
        {
            "name": "Gyrocopter",
            "Legion Commander": -0.408
        },
        {
            "name": "Hoodwink",
            "Legion Commander": 0.456
        },
        {
            "name": "Huskar",
            "Legion Commander": -0.97
        },
        {
            "name": "Invoker",
            "Legion Commander": 0.346
        },
        {
            "name": "Io",
            "Legion Commander": 0.366
        },
        {
            "name": "Jakiro",
            "Legion Commander": -0.697
        },
        {
            "name": "Juggernaut",
            "Legion Commander": -0.707
        },
        {
            "name": "Keeper of the Light",
            "Legion Commander": -0.538
        },
        {
            "name": "Kez",
            "Legion Commander": -1.221
        },
        {
            "name": "Kunkka",
            "Legion Commander": 2.54
        },
        {
            "name": "Leshrac",
            "Legion Commander": -0.047
        },
        {
            "name": "Lich",
            "Legion Commander": 0.819
        },
        {
            "name": "Lifestealer",
            "Legion Commander": 0.349
        },
        {
            "name": "Lina",
            "Legion Commander": 0.083
        },
        {
            "name": "Lion",
            "Legion Commander": 1.419
        },
        {
            "name": "Lone Druid",
            "Legion Commander": 1.608
        },
        {
            "name": "Luna",
            "Legion Commander": 1.608
        },
        {
            "name": "Lycan",
            "Legion Commander": 0.875
        },
        {
            "name": "Magnus",
            "Legion Commander": 0.939
        },
        {
            "name": "Marci",
            "Legion Commander": 1.067
        },
        {
            "name": "Mars",
            "Legion Commander": 1.992
        },
        {
            "name": "Medusa",
            "Legion Commander": 2.382
        },
        {
            "name": "Meepo",
            "Legion Commander": -1.149
        },
        {
            "name": "Mirana",
            "Legion Commander": 0.415
        },
        {
            "name": "Monkey King",
            "Legion Commander": 3.044
        },
        {
            "name": "Morphling",
            "Legion Commander": 0.448
        },
        {
            "name": "Muerta",
            "Legion Commander": -0.359
        },
        {
            "name": "Naga Siren",
            "Legion Commander": -0.406
        },
        {
            "name": "Nature's Prophet",
            "Legion Commander": 0.506
        },
        {
            "name": "Necrophos",
            "Legion Commander": -1.288
        },
        {
            "name": "Night Stalker",
            "Legion Commander": 0.144
        },
        {
            "name": "Nyx Assassin",
            "Legion Commander": -0.023
        },
        {
            "name": "Ogre Magi",
            "Legion Commander": -1.107
        },
        {
            "name": "Omniknight",
            "Legion Commander": 3.147
        },
        {
            "name": "Oracle",
            "Legion Commander": 2.541
        },
        {
            "name": "Outworld Destroyer",
            "Legion Commander": 1.628
        },
        {
            "name": "Pangolier",
            "Legion Commander": 1.136
        },
        {
            "name": "Phantom Assassin",
            "Legion Commander": 0.256
        },
        {
            "name": "Phantom Lancer",
            "Legion Commander": -1.444
        },
        {
            "name": "Phoenix",
            "Legion Commander": -1.231
        },
        {
            "name": "Primal Beast",
            "Legion Commander": 2.147
        },
        {
            "name": "Puck",
            "Legion Commander": -1.16
        },
        {
            "name": "Pudge",
            "Legion Commander": 1.938
        },
        {
            "name": "Pugna",
            "Legion Commander": 1.116
        },
        {
            "name": "Queen of Pain",
            "Legion Commander": -1.234
        },
        {
            "name": "Razor",
            "Legion Commander": -0.004
        },
        {
            "name": "Riki",
            "Legion Commander": 1.099
        },
        {
            "name": "Ringmaster",
            "Legion Commander": 2.403
        },
        {
            "name": "Rubick",
            "Legion Commander": -0.698
        },
        {
            "name": "Sand King",
            "Legion Commander": 0.109
        },
        {
            "name": "Shadow Demon",
            "Legion Commander": 2.545
        },
        {
            "name": "Shadow Fiend",
            "Legion Commander": 1.705
        },
        {
            "name": "Shadow Shaman",
            "Legion Commander": 0.354
        },
        {
            "name": "Silencer",
            "Legion Commander": -1.707
        },
        {
            "name": "Skywrath Mage",
            "Legion Commander": 0.109
        },
        {
            "name": "Slardar",
            "Legion Commander": 0.93
        },
        {
            "name": "Slark",
            "Legion Commander": -0.673
        },
        {
            "name": "Snapfire",
            "Legion Commander": 0.542
        },
        {
            "name": "Sniper",
            "Legion Commander": 0.142
        },
        {
            "name": "Spectre",
            "Legion Commander": -0.745
        },
        {
            "name": "Spirit Breaker",
            "Legion Commander": 1.736
        },
        {
            "name": "Storm Spirit",
            "Legion Commander": -0.748
        },
        {
            "name": "Sven",
            "Legion Commander": 1.494
        },
        {
            "name": "Techies",
            "Legion Commander": 1.814
        },
        {
            "name": "Templar Assassin",
            "Legion Commander": 2.494
        },
        {
            "name": "Terrorblade",
            "Legion Commander": -0.831
        },
        {
            "name": "Tidehunter",
            "Legion Commander": 0.24
        },
        {
            "name": "Timbersaw",
            "Legion Commander": 0.82
        },
        {
            "name": "Tinker",
            "Legion Commander": 0.584
        },
        {
            "name": "Tiny",
            "Legion Commander": 2.077
        },
        {
            "name": "Treant Protector",
            "Legion Commander": 1.397
        },
        {
            "name": "Troll Warlord",
            "Legion Commander": 1.628
        },
        {
            "name": "Tusk",
            "Legion Commander": 2.788
        },
        {
            "name": "Underlord",
            "Legion Commander": -0.371
        },
        {
            "name": "Undying",
            "Legion Commander": -0.539
        },
        {
            "name": "Ursa",
            "Legion Commander": 1.677
        },
        {
            "name": "Vengeful Spirit",
            "Legion Commander": 1.525
        },
        {
            "name": "Venomancer",
            "Legion Commander": -1.465
        },
        {
            "name": "Viper",
            "Legion Commander": -1.395
        },
        {
            "name": "Visage",
            "Legion Commander": 0.132
        },
        {
            "name": "Void Spirit",
            "Legion Commander": -0.216
        },
        {
            "name": "Warlock",
            "Legion Commander": -0.288
        },
        {
            "name": "Weaver",
            "Legion Commander": -0.984
        },
        {
            "name": "Windranger",
            "Legion Commander": 1.0
        },
        {
            "name": "Winter Wyvern",
            "Legion Commander": 3.417
        },
        {
            "name": "Witch Doctor",
            "Legion Commander": 0.027
        },
        {
            "name": "Wraith King",
            "Legion Commander": 1.527
        },
        {
            "name": "Zeus",
            "Legion Commander": 0.947
        }
    ],
    "Lifestealer": [
        {
            "name": "Abaddon",
            "Lifestealer": 0.386
        },
        {
            "name": "Alchemist",
            "Lifestealer": -0.275
        },
        {
            "name": "Ancient Apparition",
            "Lifestealer": 0.216
        },
        {
            "name": "Anti-Mage",
            "Lifestealer": 1.146
        },
        {
            "name": "Arc Warden",
            "Lifestealer": 1.577
        },
        {
            "name": "Axe",
            "Lifestealer": -2.179
        },
        {
            "name": "Bane",
            "Lifestealer": 0.943
        },
        {
            "name": "Batrider",
            "Lifestealer": 0.394
        },
        {
            "name": "Beastmaster",
            "Lifestealer": 1.403
        },
        {
            "name": "Bloodseeker",
            "Lifestealer": -2.024
        },
        {
            "name": "Bounty Hunter",
            "Lifestealer": 0.339
        },
        {
            "name": "Brewmaster",
            "Lifestealer": 0.117
        },
        {
            "name": "Bristleback",
            "Lifestealer": 3.499
        },
        {
            "name": "Broodmother",
            "Lifestealer": 3.99
        },
        {
            "name": "Centaur Warrunner",
            "Lifestealer": -4.508
        },
        {
            "name": "Chaos Knight",
            "Lifestealer": 0.983
        },
        {
            "name": "Chen",
            "Lifestealer": 3.199
        },
        {
            "name": "Clinkz",
            "Lifestealer": 3.579
        },
        {
            "name": "Clockwerk",
            "Lifestealer": -1.337
        },
        {
            "name": "Crystal Maiden",
            "Lifestealer": 0.133
        },
        {
            "name": "Dark Seer",
            "Lifestealer": 0.544
        },
        {
            "name": "Dark Willow",
            "Lifestealer": -0.047
        },
        {
            "name": "Dawnbreaker",
            "Lifestealer": -0.653
        },
        {
            "name": "Dazzle",
            "Lifestealer": 1.839
        },
        {
            "name": "Death Prophet",
            "Lifestealer": 2.426
        },
        {
            "name": "Disruptor",
            "Lifestealer": -0.067
        },
        {
            "name": "Doom",
            "Lifestealer": 1.614
        },
        {
            "name": "Dragon Knight",
            "Lifestealer": -1.536
        },
        {
            "name": "Drow Ranger",
            "Lifestealer": 1.258
        },
        {
            "name": "Earth Spirit",
            "Lifestealer": -2.642
        },
        {
            "name": "Earthshaker",
            "Lifestealer": -0.597
        },
        {
            "name": "Elder Titan",
            "Lifestealer": -0.458
        },
        {
            "name": "Ember Spirit",
            "Lifestealer": -0.066
        },
        {
            "name": "Enchantress",
            "Lifestealer": 0.683
        },
        {
            "name": "Enigma",
            "Lifestealer": 3.713
        },
        {
            "name": "Faceless Void",
            "Lifestealer": 1.588
        },
        {
            "name": "Grimstroke",
            "Lifestealer": 0.979
        },
        {
            "name": "Gyrocopter",
            "Lifestealer": -2.077
        },
        {
            "name": "Hoodwink",
            "Lifestealer": 0.119
        },
        {
            "name": "Huskar",
            "Lifestealer": 0.895
        },
        {
            "name": "Invoker",
            "Lifestealer": -0.76
        },
        {
            "name": "Io",
            "Lifestealer": 2.223
        },
        {
            "name": "Jakiro",
            "Lifestealer": -0.999
        },
        {
            "name": "Juggernaut",
            "Lifestealer": 1.536
        },
        {
            "name": "Keeper of the Light",
            "Lifestealer": -0.365
        },
        {
            "name": "Kez",
            "Lifestealer": 1.653
        },
        {
            "name": "Kunkka",
            "Lifestealer": -2.023
        },
        {
            "name": "Legion Commander",
            "Lifestealer": -0.378
        },
        {
            "name": "Leshrac",
            "Lifestealer": -0.705
        },
        {
            "name": "Lich",
            "Lifestealer": 1.065
        },
        {
            "name": "Lina",
            "Lifestealer": 0.107
        },
        {
            "name": "Lion",
            "Lifestealer": -0.217
        },
        {
            "name": "Lone Druid",
            "Lifestealer": 1.037
        },
        {
            "name": "Luna",
            "Lifestealer": 0.292
        },
        {
            "name": "Lycan",
            "Lifestealer": 0.198
        },
        {
            "name": "Magnus",
            "Lifestealer": -0.313
        },
        {
            "name": "Marci",
            "Lifestealer": 1.551
        },
        {
            "name": "Mars",
            "Lifestealer": -2.93
        },
        {
            "name": "Medusa",
            "Lifestealer": 1.02
        },
        {
            "name": "Meepo",
            "Lifestealer": 0.966
        },
        {
            "name": "Mirana",
            "Lifestealer": 0.797
        },
        {
            "name": "Monkey King",
            "Lifestealer": 1.348
        },
        {
            "name": "Morphling",
            "Lifestealer": 1.291
        },
        {
            "name": "Muerta",
            "Lifestealer": 0.86
        },
        {
            "name": "Naga Siren",
            "Lifestealer": 1.662
        },
        {
            "name": "Nature's Prophet",
            "Lifestealer": 3.366
        },
        {
            "name": "Necrophos",
            "Lifestealer": 0.309
        },
        {
            "name": "Night Stalker",
            "Lifestealer": 0.923
        },
        {
            "name": "Nyx Assassin",
            "Lifestealer": -2.401
        },
        {
            "name": "Ogre Magi",
            "Lifestealer": -0.382
        },
        {
            "name": "Omniknight",
            "Lifestealer": 1.22
        },
        {
            "name": "Oracle",
            "Lifestealer": 0.541
        },
        {
            "name": "Outworld Destroyer",
            "Lifestealer": -1.047
        },
        {
            "name": "Pangolier",
            "Lifestealer": 1.577
        },
        {
            "name": "Phantom Assassin",
            "Lifestealer": 2.1
        },
        {
            "name": "Phantom Lancer",
            "Lifestealer": -0.234
        },
        {
            "name": "Phoenix",
            "Lifestealer": -2.735
        },
        {
            "name": "Primal Beast",
            "Lifestealer": -1.148
        },
        {
            "name": "Puck",
            "Lifestealer": 0.379
        },
        {
            "name": "Pudge",
            "Lifestealer": -1.019
        },
        {
            "name": "Pugna",
            "Lifestealer": 0.113
        },
        {
            "name": "Queen of Pain",
            "Lifestealer": 1.324
        },
        {
            "name": "Razor",
            "Lifestealer": 3.086
        },
        {
            "name": "Riki",
            "Lifestealer": 2.092
        },
        {
            "name": "Ringmaster",
            "Lifestealer": 0.221
        },
        {
            "name": "Rubick",
            "Lifestealer": -1.087
        },
        {
            "name": "Sand King",
            "Lifestealer": -1.915
        },
        {
            "name": "Shadow Demon",
            "Lifestealer": -0.605
        },
        {
            "name": "Shadow Fiend",
            "Lifestealer": -0.174
        },
        {
            "name": "Shadow Shaman",
            "Lifestealer": 0.507
        },
        {
            "name": "Silencer",
            "Lifestealer": 0.227
        },
        {
            "name": "Skywrath Mage",
            "Lifestealer": 1.498
        },
        {
            "name": "Slardar",
            "Lifestealer": 2.827
        },
        {
            "name": "Slark",
            "Lifestealer": 1.004
        },
        {
            "name": "Snapfire",
            "Lifestealer": -0.593
        },
        {
            "name": "Sniper",
            "Lifestealer": 0.695
        },
        {
            "name": "Spectre",
            "Lifestealer": -2.196
        },
        {
            "name": "Spirit Breaker",
            "Lifestealer": 1.141
        },
        {
            "name": "Storm Spirit",
            "Lifestealer": 1.717
        },
        {
            "name": "Sven",
            "Lifestealer": -0.464
        },
        {
            "name": "Techies",
            "Lifestealer": -2.117
        },
        {
            "name": "Templar Assassin",
            "Lifestealer": 0.746
        },
        {
            "name": "Terrorblade",
            "Lifestealer": -0.075
        },
        {
            "name": "Tidehunter",
            "Lifestealer": -0.679
        },
        {
            "name": "Timbersaw",
            "Lifestealer": -0.8
        },
        {
            "name": "Tinker",
            "Lifestealer": 0.094
        },
        {
            "name": "Tiny",
            "Lifestealer": -2.683
        },
        {
            "name": "Treant Protector",
            "Lifestealer": 0.26
        },
        {
            "name": "Troll Warlord",
            "Lifestealer": 1.446
        },
        {
            "name": "Tusk",
            "Lifestealer": 2.026
        },
        {
            "name": "Underlord",
            "Lifestealer": -2.595
        },
        {
            "name": "Undying",
            "Lifestealer": -0.412
        },
        {
            "name": "Ursa",
            "Lifestealer": 0.626
        },
        {
            "name": "Vengeful Spirit",
            "Lifestealer": -0.171
        },
        {
            "name": "Venomancer",
            "Lifestealer": 0.378
        },
        {
            "name": "Viper",
            "Lifestealer": 1.278
        },
        {
            "name": "Visage",
            "Lifestealer": 2.506
        },
        {
            "name": "Void Spirit",
            "Lifestealer": 0.45
        },
        {
            "name": "Warlock",
            "Lifestealer": -0.57
        },
        {
            "name": "Weaver",
            "Lifestealer": 1.58
        },
        {
            "name": "Windranger",
            "Lifestealer": 1.833
        },
        {
            "name": "Winter Wyvern",
            "Lifestealer": 1.91
        },
        {
            "name": "Witch Doctor",
            "Lifestealer": 1.117
        },
        {
            "name": "Wraith King",
            "Lifestealer": -2.085
        },
        {
            "name": "Zeus",
            "Lifestealer": -1.55
        }
    ],
    "Mars": [
        {
            "name": "Abaddon",
            "Mars": -0.645
        },
        {
            "name": "Alchemist",
            "Mars": 1.421
        },
        {
            "name": "Ancient Apparition",
            "Mars": -0.435
        },
        {
            "name": "Anti-Mage",
            "Mars": 2.753
        },
        {
            "name": "Arc Warden",
            "Mars": -0.887
        },
        {
            "name": "Axe",
            "Mars": -0.562
        },
        {
            "name": "Bane",
            "Mars": 1.027
        },
        {
            "name": "Batrider",
            "Mars": 0.909
        },
        {
            "name": "Beastmaster",
            "Mars": 1.352
        },
        {
            "name": "Bloodseeker",
            "Mars": 2.101
        },
        {
            "name": "Bounty Hunter",
            "Mars": 0.777
        },
        {
            "name": "Brewmaster",
            "Mars": -0.512
        },
        {
            "name": "Bristleback",
            "Mars": -0.44
        },
        {
            "name": "Broodmother",
            "Mars": -0.554
        },
        {
            "name": "Centaur Warrunner",
            "Mars": -0.432
        },
        {
            "name": "Chaos Knight",
            "Mars": -0.327
        },
        {
            "name": "Chen",
            "Mars": 1.479
        },
        {
            "name": "Clinkz",
            "Mars": -0.824
        },
        {
            "name": "Clockwerk",
            "Mars": 0.071
        },
        {
            "name": "Crystal Maiden",
            "Mars": -0.395
        },
        {
            "name": "Dark Seer",
            "Mars": -0.277
        },
        {
            "name": "Dark Willow",
            "Mars": 0.498
        },
        {
            "name": "Dawnbreaker",
            "Mars": 0.745
        },
        {
            "name": "Dazzle",
            "Mars": -0.371
        },
        {
            "name": "Death Prophet",
            "Mars": 0.634
        },
        {
            "name": "Disruptor",
            "Mars": 0.394
        },
        {
            "name": "Doom",
            "Mars": 0.745
        },
        {
            "name": "Dragon Knight",
            "Mars": -1.112
        },
        {
            "name": "Drow Ranger",
            "Mars": -3.838
        },
        {
            "name": "Earth Spirit",
            "Mars": 2.14
        },
        {
            "name": "Earthshaker",
            "Mars": -0.007
        },
        {
            "name": "Elder Titan",
            "Mars": 1.511
        },
        {
            "name": "Ember Spirit",
            "Mars": 1.901
        },
        {
            "name": "Enchantress",
            "Mars": -1.78
        },
        {
            "name": "Enigma",
            "Mars": 1.352
        },
        {
            "name": "Faceless Void",
            "Mars": 1.829
        },
        {
            "name": "Grimstroke",
            "Mars": -0.069
        },
        {
            "name": "Gyrocopter",
            "Mars": -1.164
        },
        {
            "name": "Hoodwink",
            "Mars": -1.106
        },
        {
            "name": "Huskar",
            "Mars": 2.134
        },
        {
            "name": "Invoker",
            "Mars": -0.095
        },
        {
            "name": "Io",
            "Mars": -1.386
        },
        {
            "name": "Jakiro",
            "Mars": 0.062
        },
        {
            "name": "Juggernaut",
            "Mars": 0.751
        },
        {
            "name": "Keeper of the Light",
            "Mars": 0.347
        },
        {
            "name": "Kez",
            "Mars": 2.227
        },
        {
            "name": "Kunkka",
            "Mars": 0.276
        },
        {
            "name": "Legion Commander",
            "Mars": -1.475
        },
        {
            "name": "Leshrac",
            "Mars": 0.132
        },
        {
            "name": "Lich",
            "Mars": 0.894
        },
        {
            "name": "Lifestealer",
            "Mars": 3.123
        },
        {
            "name": "Lina",
            "Mars": -0.51
        },
        {
            "name": "Lion",
            "Mars": -0.79
        },
        {
            "name": "Lone Druid",
            "Mars": 2.179
        },
        {
            "name": "Luna",
            "Mars": -1.533
        },
        {
            "name": "Lycan",
            "Mars": 0.976
        },
        {
            "name": "Magnus",
            "Mars": -0.456
        },
        {
            "name": "Marci",
            "Mars": -1.157
        },
        {
            "name": "Medusa",
            "Mars": -1.224
        },
        {
            "name": "Meepo",
            "Mars": 1.582
        },
        {
            "name": "Mirana",
            "Mars": -1.319
        },
        {
            "name": "Monkey King",
            "Mars": 0.542
        },
        {
            "name": "Morphling",
            "Mars": 0.739
        },
        {
            "name": "Muerta",
            "Mars": 0.473
        },
        {
            "name": "Naga Siren",
            "Mars": 1.039
        },
        {
            "name": "Nature's Prophet",
            "Mars": -0.539
        },
        {
            "name": "Necrophos",
            "Mars": -0.137
        },
        {
            "name": "Night Stalker",
            "Mars": -0.158
        },
        {
            "name": "Nyx Assassin",
            "Mars": 0.577
        },
        {
            "name": "Ogre Magi",
            "Mars": 0.03
        },
        {
            "name": "Omniknight",
            "Mars": -0.365
        },
        {
            "name": "Oracle",
            "Mars": -0.264
        },
        {
            "name": "Outworld Destroyer",
            "Mars": -0.241
        },
        {
            "name": "Pangolier",
            "Mars": 1.019
        },
        {
            "name": "Phantom Assassin",
            "Mars": 1.04
        },
        {
            "name": "Phantom Lancer",
            "Mars": 2.551
        },
        {
            "name": "Phoenix",
            "Mars": 0.496
        },
        {
            "name": "Primal Beast",
            "Mars": -0.135
        },
        {
            "name": "Puck",
            "Mars": 2.147
        },
        {
            "name": "Pudge",
            "Mars": -0.607
        },
        {
            "name": "Pugna",
            "Mars": 1.025
        },
        {
            "name": "Queen of Pain",
            "Mars": 2.029
        },
        {
            "name": "Razor",
            "Mars": 0.394
        },
        {
            "name": "Riki",
            "Mars": 0.889
        },
        {
            "name": "Ringmaster",
            "Mars": 0.94
        },
        {
            "name": "Rubick",
            "Mars": -0.858
        },
        {
            "name": "Sand King",
            "Mars": 0.624
        },
        {
            "name": "Shadow Demon",
            "Mars": 0.236
        },
        {
            "name": "Shadow Fiend",
            "Mars": -1.63
        },
        {
            "name": "Shadow Shaman",
            "Mars": -0.637
        },
        {
            "name": "Silencer",
            "Mars": 0.458
        },
        {
            "name": "Skywrath Mage",
            "Mars": -0.542
        },
        {
            "name": "Slardar",
            "Mars": 0.44
        },
        {
            "name": "Slark",
            "Mars": -3.926
        },
        {
            "name": "Snapfire",
            "Mars": -0.326
        },
        {
            "name": "Sniper",
            "Mars": -2.196
        },
        {
            "name": "Spectre",
            "Mars": 1.372
        },
        {
            "name": "Spirit Breaker",
            "Mars": -2.195
        },
        {
            "name": "Storm Spirit",
            "Mars": 2.311
        },
        {
            "name": "Sven",
            "Mars": 0.171
        },
        {
            "name": "Techies",
            "Mars": -1.596
        },
        {
            "name": "Templar Assassin",
            "Mars": -0.497
        },
        {
            "name": "Terrorblade",
            "Mars": -0.53
        },
        {
            "name": "Tidehunter",
            "Mars": -0.211
        },
        {
            "name": "Timbersaw",
            "Mars": -0.697
        },
        {
            "name": "Tinker",
            "Mars": 1.385
        },
        {
            "name": "Tiny",
            "Mars": -0.2
        },
        {
            "name": "Treant Protector",
            "Mars": -0.74
        },
        {
            "name": "Troll Warlord",
            "Mars": -1.038
        },
        {
            "name": "Tusk",
            "Mars": 0.011
        },
        {
            "name": "Underlord",
            "Mars": 1.13
        },
        {
            "name": "Undying",
            "Mars": 0.759
        },
        {
            "name": "Ursa",
            "Mars": -0.143
        },
        {
            "name": "Vengeful Spirit",
            "Mars": 0.041
        },
        {
            "name": "Venomancer",
            "Mars": 1.299
        },
        {
            "name": "Viper",
            "Mars": -1.198
        },
        {
            "name": "Visage",
            "Mars": -0.098
        },
        {
            "name": "Void Spirit",
            "Mars": 2.404
        },
        {
            "name": "Warlock",
            "Mars": -0.005
        },
        {
            "name": "Weaver",
            "Mars": -0.601
        },
        {
            "name": "Windranger",
            "Mars": -1.905
        },
        {
            "name": "Winter Wyvern",
            "Mars": -0.689
        },
        {
            "name": "Witch Doctor",
            "Mars": -0.458
        },
        {
            "name": "Wraith King",
            "Mars": 0.157
        },
        {
            "name": "Zeus",
            "Mars": -0.722
        }
    ],
    "Night Stalker": [
        {
            "name": "Abaddon",
            "Night Stalker": 0.175
        },
        {
            "name": "Alchemist",
            "Night Stalker": -0.012
        },
        {
            "name": "Ancient Apparition",
            "Night Stalker": -2.187
        },
        {
            "name": "Anti-Mage",
            "Night Stalker": -0.715
        },
        {
            "name": "Arc Warden",
            "Night Stalker": 3.628
        },
        {
            "name": "Axe",
            "Night Stalker": -1.347
        },
        {
            "name": "Bane",
            "Night Stalker": 0.047
        },
        {
            "name": "Batrider",
            "Night Stalker": 1.57
        },
        {
            "name": "Beastmaster",
            "Night Stalker": 4.009
        },
        {
            "name": "Bloodseeker",
            "Night Stalker": 2.358
        },
        {
            "name": "Bounty Hunter",
            "Night Stalker": 1.785
        },
        {
            "name": "Brewmaster",
            "Night Stalker": 1.834
        },
        {
            "name": "Bristleback",
            "Night Stalker": 0.712
        },
        {
            "name": "Broodmother",
            "Night Stalker": 5.442
        },
        {
            "name": "Centaur Warrunner",
            "Night Stalker": 0.065
        },
        {
            "name": "Chaos Knight",
            "Night Stalker": 2.956
        },
        {
            "name": "Chen",
            "Night Stalker": 2.28
        },
        {
            "name": "Clinkz",
            "Night Stalker": 1.449
        },
        {
            "name": "Clockwerk",
            "Night Stalker": 0.22
        },
        {
            "name": "Crystal Maiden",
            "Night Stalker": 0.614
        },
        {
            "name": "Dark Seer",
            "Night Stalker": 1.648
        },
        {
            "name": "Dark Willow",
            "Night Stalker": 1.608
        },
        {
            "name": "Dawnbreaker",
            "Night Stalker": -0.22
        },
        {
            "name": "Dazzle",
            "Night Stalker": 0.334
        },
        {
            "name": "Death Prophet",
            "Night Stalker": -0.154
        },
        {
            "name": "Disruptor",
            "Night Stalker": 0.649
        },
        {
            "name": "Doom",
            "Night Stalker": -0.191
        },
        {
            "name": "Dragon Knight",
            "Night Stalker": 0.686
        },
        {
            "name": "Drow Ranger",
            "Night Stalker": -1.102
        },
        {
            "name": "Earth Spirit",
            "Night Stalker": 0.801
        },
        {
            "name": "Earthshaker",
            "Night Stalker": -1.315
        },
        {
            "name": "Elder Titan",
            "Night Stalker": 1.477
        },
        {
            "name": "Ember Spirit",
            "Night Stalker": -0.01
        },
        {
            "name": "Enchantress",
            "Night Stalker": 1.283
        },
        {
            "name": "Enigma",
            "Night Stalker": 1.722
        },
        {
            "name": "Faceless Void",
            "Night Stalker": -1.494
        },
        {
            "name": "Grimstroke",
            "Night Stalker": -0.562
        },
        {
            "name": "Gyrocopter",
            "Night Stalker": 1.046
        },
        {
            "name": "Hoodwink",
            "Night Stalker": -0.064
        },
        {
            "name": "Huskar",
            "Night Stalker": 4.76
        },
        {
            "name": "Invoker",
            "Night Stalker": 0.717
        },
        {
            "name": "Io",
            "Night Stalker": 2.638
        },
        {
            "name": "Jakiro",
            "Night Stalker": -0.241
        },
        {
            "name": "Juggernaut",
            "Night Stalker": -2.953
        },
        {
            "name": "Keeper of the Light",
            "Night Stalker": 1.393
        },
        {
            "name": "Kez",
            "Night Stalker": 1.843
        },
        {
            "name": "Kunkka",
            "Night Stalker": 0.876
        },
        {
            "name": "Legion Commander",
            "Night Stalker": -0.179
        },
        {
            "name": "Leshrac",
            "Night Stalker": 0.513
        },
        {
            "name": "Lich",
            "Night Stalker": -0.004
        },
        {
            "name": "Lifestealer",
            "Night Stalker": -0.925
        },
        {
            "name": "Lina",
            "Night Stalker": -1.588
        },
        {
            "name": "Lion",
            "Night Stalker": -0.731
        },
        {
            "name": "Lone Druid",
            "Night Stalker": 4.046
        },
        {
            "name": "Luna",
            "Night Stalker": 2.466
        },
        {
            "name": "Lycan",
            "Night Stalker": 3.644
        },
        {
            "name": "Magnus",
            "Night Stalker": 0.061
        },
        {
            "name": "Marci",
            "Night Stalker": 3.149
        },
        {
            "name": "Mars",
            "Night Stalker": 0.226
        },
        {
            "name": "Medusa",
            "Night Stalker": 0.041
        },
        {
            "name": "Meepo",
            "Night Stalker": 3.533
        },
        {
            "name": "Mirana",
            "Night Stalker": 0.108
        },
        {
            "name": "Monkey King",
            "Night Stalker": 1.235
        },
        {
            "name": "Morphling",
            "Night Stalker": -0.074
        },
        {
            "name": "Muerta",
            "Night Stalker": 0.47
        },
        {
            "name": "Naga Siren",
            "Night Stalker": 6.297
        },
        {
            "name": "Nature's Prophet",
            "Night Stalker": -0.592
        },
        {
            "name": "Necrophos",
            "Night Stalker": 0.06
        },
        {
            "name": "Nyx Assassin",
            "Night Stalker": -1.54
        },
        {
            "name": "Ogre Magi",
            "Night Stalker": 1.769
        },
        {
            "name": "Omniknight",
            "Night Stalker": 0.775
        },
        {
            "name": "Oracle",
            "Night Stalker": -0.888
        },
        {
            "name": "Outworld Destroyer",
            "Night Stalker": -1.302
        },
        {
            "name": "Pangolier",
            "Night Stalker": -0.841
        },
        {
            "name": "Phantom Assassin",
            "Night Stalker": 1.451
        },
        {
            "name": "Phantom Lancer",
            "Night Stalker": 1.633
        },
        {
            "name": "Phoenix",
            "Night Stalker": 1.915
        },
        {
            "name": "Primal Beast",
            "Night Stalker": -0.139
        },
        {
            "name": "Puck",
            "Night Stalker": -4.717
        },
        {
            "name": "Pudge",
            "Night Stalker": -1.096
        },
        {
            "name": "Pugna",
            "Night Stalker": -1.351
        },
        {
            "name": "Queen of Pain",
            "Night Stalker": -1.377
        },
        {
            "name": "Razor",
            "Night Stalker": 2.051
        },
        {
            "name": "Riki",
            "Night Stalker": -0.964
        },
        {
            "name": "Ringmaster",
            "Night Stalker": -0.611
        },
        {
            "name": "Rubick",
            "Night Stalker": -0.832
        },
        {
            "name": "Sand King",
            "Night Stalker": -1.099
        },
        {
            "name": "Shadow Demon",
            "Night Stalker": 0.444
        },
        {
            "name": "Shadow Fiend",
            "Night Stalker": 0.853
        },
        {
            "name": "Shadow Shaman",
            "Night Stalker": 0.457
        },
        {
            "name": "Silencer",
            "Night Stalker": -2.31
        },
        {
            "name": "Skywrath Mage",
            "Night Stalker": -1.646
        },
        {
            "name": "Slardar",
            "Night Stalker": 0.806
        },
        {
            "name": "Slark",
            "Night Stalker": -2.118
        },
        {
            "name": "Snapfire",
            "Night Stalker": 0.198
        },
        {
            "name": "Sniper",
            "Night Stalker": 0.997
        },
        {
            "name": "Spectre",
            "Night Stalker": -0.034
        },
        {
            "name": "Spirit Breaker",
            "Night Stalker": 1.393
        },
        {
            "name": "Storm Spirit",
            "Night Stalker": -1.604
        },
        {
            "name": "Sven",
            "Night Stalker": 1.469
        },
        {
            "name": "Techies",
            "Night Stalker": 1.219
        },
        {
            "name": "Templar Assassin",
            "Night Stalker": 2.488
        },
        {
            "name": "Terrorblade",
            "Night Stalker": 4.179
        },
        {
            "name": "Tidehunter",
            "Night Stalker": -0.396
        },
        {
            "name": "Timbersaw",
            "Night Stalker": -2.153
        },
        {
            "name": "Tinker",
            "Night Stalker": 0.593
        },
        {
            "name": "Tiny",
            "Night Stalker": 0.572
        },
        {
            "name": "Treant Protector",
            "Night Stalker": 1.527
        },
        {
            "name": "Troll Warlord",
            "Night Stalker": 3.121
        },
        {
            "name": "Tusk",
            "Night Stalker": 0.629
        },
        {
            "name": "Underlord",
            "Night Stalker": 0.449
        },
        {
            "name": "Undying",
            "Night Stalker": 1.087
        },
        {
            "name": "Ursa",
            "Night Stalker": -0.833
        },
        {
            "name": "Vengeful Spirit",
            "Night Stalker": 1.033
        },
        {
            "name": "Venomancer",
            "Night Stalker": 2.135
        },
        {
            "name": "Viper",
            "Night Stalker": 2.059
        },
        {
            "name": "Visage",
            "Night Stalker": 1.211
        },
        {
            "name": "Void Spirit",
            "Night Stalker": -2.864
        },
        {
            "name": "Warlock",
            "Night Stalker": -0.112
        },
        {
            "name": "Weaver",
            "Night Stalker": 0.106
        },
        {
            "name": "Windranger",
            "Night Stalker": 1.404
        },
        {
            "name": "Winter Wyvern",
            "Night Stalker": 1.072
        },
        {
            "name": "Witch Doctor",
            "Night Stalker": -1.317
        },
        {
            "name": "Wraith King",
            "Night Stalker": 1.207
        },
        {
            "name": "Zeus",
            "Night Stalker": -0.345
        }
    ],
    "Ogre Magi": [
        {
            "name": "Abaddon",
            "Ogre Magi": 0.169
        },
        {
            "name": "Alchemist",
            "Ogre Magi": 0.058
        },
        {
            "name": "Ancient Apparition",
            "Ogre Magi": -0.167
        },
        {
            "name": "Anti-Mage",
            "Ogre Magi": 2.342
        },
        {
            "name": "Arc Warden",
            "Ogre Magi": 1.265
        },
        {
            "name": "Axe",
            "Ogre Magi": -0.859
        },
        {
            "name": "Bane",
            "Ogre Magi": 0.216
        },
        {
            "name": "Batrider",
            "Ogre Magi": 0.597
        },
        {
            "name": "Beastmaster",
            "Ogre Magi": 2.154
        },
        {
            "name": "Bloodseeker",
            "Ogre Magi": -0.399
        },
        {
            "name": "Bounty Hunter",
            "Ogre Magi": -0.2
        },
        {
            "name": "Brewmaster",
            "Ogre Magi": 1.091
        },
        {
            "name": "Bristleback",
            "Ogre Magi": -1.179
        },
        {
            "name": "Broodmother",
            "Ogre Magi": 2.209
        },
        {
            "name": "Centaur Warrunner",
            "Ogre Magi": -0.366
        },
        {
            "name": "Chaos Knight",
            "Ogre Magi": 3.148
        },
        {
            "name": "Chen",
            "Ogre Magi": 0.828
        },
        {
            "name": "Clinkz",
            "Ogre Magi": -0.444
        },
        {
            "name": "Clockwerk",
            "Ogre Magi": -0.54
        },
        {
            "name": "Crystal Maiden",
            "Ogre Magi": 0.328
        },
        {
            "name": "Dark Seer",
            "Ogre Magi": 1.092
        },
        {
            "name": "Dark Willow",
            "Ogre Magi": 0.956
        },
        {
            "name": "Dawnbreaker",
            "Ogre Magi": -0.606
        },
        {
            "name": "Dazzle",
            "Ogre Magi": 0.266
        },
        {
            "name": "Death Prophet",
            "Ogre Magi": -0.058
        },
        {
            "name": "Disruptor",
            "Ogre Magi": -0.103
        },
        {
            "name": "Doom",
            "Ogre Magi": 0.445
        },
        {
            "name": "Dragon Knight",
            "Ogre Magi": -1.138
        },
        {
            "name": "Drow Ranger",
            "Ogre Magi": -1.213
        },
        {
            "name": "Earth Spirit",
            "Ogre Magi": 0.086
        },
        {
            "name": "Earthshaker",
            "Ogre Magi": -0.181
        },
        {
            "name": "Elder Titan",
            "Ogre Magi": 0.248
        },
        {
            "name": "Ember Spirit",
            "Ogre Magi": 0.425
        },
        {
            "name": "Enchantress",
            "Ogre Magi": -1.069
        },
        {
            "name": "Enigma",
            "Ogre Magi": 1.771
        },
        {
            "name": "Faceless Void",
            "Ogre Magi": 0.592
        },
        {
            "name": "Grimstroke",
            "Ogre Magi": 0.332
        },
        {
            "name": "Gyrocopter",
            "Ogre Magi": -0.244
        },
        {
            "name": "Hoodwink",
            "Ogre Magi": -0.489
        },
        {
            "name": "Huskar",
            "Ogre Magi": 1.12
        },
        {
            "name": "Invoker",
            "Ogre Magi": 1.133
        },
        {
            "name": "Io",
            "Ogre Magi": 0.283
        },
        {
            "name": "Jakiro",
            "Ogre Magi": -0.015
        },
        {
            "name": "Juggernaut",
            "Ogre Magi": 1.035
        },
        {
            "name": "Keeper of the Light",
            "Ogre Magi": -0.124
        },
        {
            "name": "Kez",
            "Ogre Magi": -0.075
        },
        {
            "name": "Kunkka",
            "Ogre Magi": -0.193
        },
        {
            "name": "Legion Commander",
            "Ogre Magi": 1.071
        },
        {
            "name": "Leshrac",
            "Ogre Magi": -0.593
        },
        {
            "name": "Lich",
            "Ogre Magi": -0.089
        },
        {
            "name": "Lifestealer",
            "Ogre Magi": 0.154
        },
        {
            "name": "Lina",
            "Ogre Magi": -0.421
        },
        {
            "name": "Lion",
            "Ogre Magi": 0.116
        },
        {
            "name": "Lone Druid",
            "Ogre Magi": 1.045
        },
        {
            "name": "Luna",
            "Ogre Magi": 0.734
        },
        {
            "name": "Lycan",
            "Ogre Magi": 0.305
        },
        {
            "name": "Magnus",
            "Ogre Magi": 1.154
        },
        {
            "name": "Marci",
            "Ogre Magi": 0.601
        },
        {
            "name": "Mars",
            "Ogre Magi": 0.425
        },
        {
            "name": "Medusa",
            "Ogre Magi": -0.619
        },
        {
            "name": "Meepo",
            "Ogre Magi": 2.574
        },
        {
            "name": "Mirana",
            "Ogre Magi": 0.784
        },
        {
            "name": "Monkey King",
            "Ogre Magi": 0.129
        },
        {
            "name": "Morphling",
            "Ogre Magi": 0.061
        },
        {
            "name": "Muerta",
            "Ogre Magi": 0.246
        },
        {
            "name": "Naga Siren",
            "Ogre Magi": 3.88
        },
        {
            "name": "Nature's Prophet",
            "Ogre Magi": 1.122
        },
        {
            "name": "Necrophos",
            "Ogre Magi": 0.222
        },
        {
            "name": "Night Stalker",
            "Ogre Magi": -1.802
        },
        {
            "name": "Nyx Assassin",
            "Ogre Magi": -0.493
        },
        {
            "name": "Omniknight",
            "Ogre Magi": -0.609
        },
        {
            "name": "Oracle",
            "Ogre Magi": 0.76
        },
        {
            "name": "Outworld Destroyer",
            "Ogre Magi": -0.908
        },
        {
            "name": "Pangolier",
            "Ogre Magi": -0.539
        },
        {
            "name": "Phantom Assassin",
            "Ogre Magi": -1.66
        },
        {
            "name": "Phantom Lancer",
            "Ogre Magi": 4.508
        },
        {
            "name": "Phoenix",
            "Ogre Magi": -0.385
        },
        {
            "name": "Primal Beast",
            "Ogre Magi": 0.282
        },
        {
            "name": "Puck",
            "Ogre Magi": 0.702
        },
        {
            "name": "Pudge",
            "Ogre Magi": 0.503
        },
        {
            "name": "Pugna",
            "Ogre Magi": -0.347
        },
        {
            "name": "Queen of Pain",
            "Ogre Magi": 0.332
        },
        {
            "name": "Razor",
            "Ogre Magi": -0.145
        },
        {
            "name": "Riki",
            "Ogre Magi": -0.688
        },
        {
            "name": "Ringmaster",
            "Ogre Magi": 0.654
        },
        {
            "name": "Rubick",
            "Ogre Magi": 0.201
        },
        {
            "name": "Sand King",
            "Ogre Magi": 0.77
        },
        {
            "name": "Shadow Demon",
            "Ogre Magi": 1.479
        },
        {
            "name": "Shadow Fiend",
            "Ogre Magi": -0.591
        },
        {
            "name": "Shadow Shaman",
            "Ogre Magi": 0.281
        },
        {
            "name": "Silencer",
            "Ogre Magi": -1.527
        },
        {
            "name": "Skywrath Mage",
            "Ogre Magi": 0.237
        },
        {
            "name": "Slardar",
            "Ogre Magi": -0.874
        },
        {
            "name": "Slark",
            "Ogre Magi": 2.213
        },
        {
            "name": "Snapfire",
            "Ogre Magi": 0.596
        },
        {
            "name": "Sniper",
            "Ogre Magi": -0.018
        },
        {
            "name": "Spectre",
            "Ogre Magi": -0.14
        },
        {
            "name": "Spirit Breaker",
            "Ogre Magi": -0.586
        },
        {
            "name": "Storm Spirit",
            "Ogre Magi": 0.798
        },
        {
            "name": "Sven",
            "Ogre Magi": 0.029
        },
        {
            "name": "Techies",
            "Ogre Magi": 1.01
        },
        {
            "name": "Templar Assassin",
            "Ogre Magi": -0.071
        },
        {
            "name": "Terrorblade",
            "Ogre Magi": 0.937
        },
        {
            "name": "Tidehunter",
            "Ogre Magi": 1.241
        },
        {
            "name": "Timbersaw",
            "Ogre Magi": -0.37
        },
        {
            "name": "Tinker",
            "Ogre Magi": 0.488
        },
        {
            "name": "Tiny",
            "Ogre Magi": -0.718
        },
        {
            "name": "Treant Protector",
            "Ogre Magi": 0.745
        },
        {
            "name": "Troll Warlord",
            "Ogre Magi": -1.686
        },
        {
            "name": "Tusk",
            "Ogre Magi": 1.003
        },
        {
            "name": "Underlord",
            "Ogre Magi": 0.849
        },
        {
            "name": "Undying",
            "Ogre Magi": -0.707
        },
        {
            "name": "Ursa",
            "Ogre Magi": -1.692
        },
        {
            "name": "Vengeful Spirit",
            "Ogre Magi": 0.204
        },
        {
            "name": "Venomancer",
            "Ogre Magi": 0.765
        },
        {
            "name": "Viper",
            "Ogre Magi": 0.82
        },
        {
            "name": "Visage",
            "Ogre Magi": 0.255
        },
        {
            "name": "Void Spirit",
            "Ogre Magi": -0.493
        },
        {
            "name": "Warlock",
            "Ogre Magi": 1.44
        },
        {
            "name": "Weaver",
            "Ogre Magi": -0.716
        },
        {
            "name": "Windranger",
            "Ogre Magi": 0.287
        },
        {
            "name": "Winter Wyvern",
            "Ogre Magi": 0.912
        },
        {
            "name": "Witch Doctor",
            "Ogre Magi": 0.41
        },
        {
            "name": "Wraith King",
            "Ogre Magi": -0.529
        },
        {
            "name": "Zeus",
            "Ogre Magi": -0.48
        }
    ],
    "Omniknight": [
        {
            "name": "Abaddon",
            "Omniknight": -1.038
        },
        {
            "name": "Alchemist",
            "Omniknight": -0.543
        },
        {
            "name": "Ancient Apparition",
            "Omniknight": 3.444
        },
        {
            "name": "Anti-Mage",
            "Omniknight": -0.785
        },
        {
            "name": "Arc Warden",
            "Omniknight": -2.844
        },
        {
            "name": "Axe",
            "Omniknight": 0.154
        },
        {
            "name": "Bane",
            "Omniknight": -0.042
        },
        {
            "name": "Batrider",
            "Omniknight": -3.033
        },
        {
            "name": "Beastmaster",
            "Omniknight": -0.501
        },
        {
            "name": "Bloodseeker",
            "Omniknight": 0.386
        },
        {
            "name": "Bounty Hunter",
            "Omniknight": -0.111
        },
        {
            "name": "Brewmaster",
            "Omniknight": -1.611
        },
        {
            "name": "Bristleback",
            "Omniknight": 0.505
        },
        {
            "name": "Broodmother",
            "Omniknight": -2.812
        },
        {
            "name": "Centaur Warrunner",
            "Omniknight": 0.249
        },
        {
            "name": "Chaos Knight",
            "Omniknight": -1.563
        },
        {
            "name": "Chen",
            "Omniknight": -1.967
        },
        {
            "name": "Clinkz",
            "Omniknight": 1.768
        },
        {
            "name": "Clockwerk",
            "Omniknight": -1.818
        },
        {
            "name": "Crystal Maiden",
            "Omniknight": 1.839
        },
        {
            "name": "Dark Seer",
            "Omniknight": 0.954
        },
        {
            "name": "Dark Willow",
            "Omniknight": 0.172
        },
        {
            "name": "Dawnbreaker",
            "Omniknight": -0.313
        },
        {
            "name": "Dazzle",
            "Omniknight": -0.567
        },
        {
            "name": "Death Prophet",
            "Omniknight": -0.302
        },
        {
            "name": "Disruptor",
            "Omniknight": -0.096
        },
        {
            "name": "Doom",
            "Omniknight": 3.458
        },
        {
            "name": "Dragon Knight",
            "Omniknight": 0.377
        },
        {
            "name": "Drow Ranger",
            "Omniknight": 0.062
        },
        {
            "name": "Earth Spirit",
            "Omniknight": -1.469
        },
        {
            "name": "Earthshaker",
            "Omniknight": 1.817
        },
        {
            "name": "Elder Titan",
            "Omniknight": 0.254
        },
        {
            "name": "Ember Spirit",
            "Omniknight": -1.478
        },
        {
            "name": "Enchantress",
            "Omniknight": 2.05
        },
        {
            "name": "Enigma",
            "Omniknight": 0.276
        },
        {
            "name": "Faceless Void",
            "Omniknight": 2.453
        },
        {
            "name": "Grimstroke",
            "Omniknight": 1.086
        },
        {
            "name": "Gyrocopter",
            "Omniknight": 0.948
        },
        {
            "name": "Hoodwink",
            "Omniknight": -0.067
        },
        {
            "name": "Huskar",
            "Omniknight": 0.75
        },
        {
            "name": "Invoker",
            "Omniknight": -0.862
        },
        {
            "name": "Io",
            "Omniknight": -0.856
        },
        {
            "name": "Jakiro",
            "Omniknight": 0.023
        },
        {
            "name": "Juggernaut",
            "Omniknight": -0.109
        },
        {
            "name": "Keeper of the Light",
            "Omniknight": 0.301
        },
        {
            "name": "Kez",
            "Omniknight": -0.492
        },
        {
            "name": "Kunkka",
            "Omniknight": 0.418
        },
        {
            "name": "Legion Commander",
            "Omniknight": -2.99
        },
        {
            "name": "Leshrac",
            "Omniknight": 1.7
        },
        {
            "name": "Lich",
            "Omniknight": 0.317
        },
        {
            "name": "Lifestealer",
            "Omniknight": -1.015
        },
        {
            "name": "Lina",
            "Omniknight": 0.962
        },
        {
            "name": "Lion",
            "Omniknight": -0.707
        },
        {
            "name": "Lone Druid",
            "Omniknight": -1.961
        },
        {
            "name": "Luna",
            "Omniknight": 2.107
        },
        {
            "name": "Lycan",
            "Omniknight": -4.094
        },
        {
            "name": "Magnus",
            "Omniknight": 0.246
        },
        {
            "name": "Marci",
            "Omniknight": -2.283
        },
        {
            "name": "Mars",
            "Omniknight": 0.338
        },
        {
            "name": "Medusa",
            "Omniknight": 0.734
        },
        {
            "name": "Meepo",
            "Omniknight": -2.851
        },
        {
            "name": "Mirana",
            "Omniknight": -1.283
        },
        {
            "name": "Monkey King",
            "Omniknight": 1.728
        },
        {
            "name": "Morphling",
            "Omniknight": -0.02
        },
        {
            "name": "Muerta",
            "Omniknight": 1.427
        },
        {
            "name": "Naga Siren",
            "Omniknight": -6.538
        },
        {
            "name": "Nature's Prophet",
            "Omniknight": 1.153
        },
        {
            "name": "Necrophos",
            "Omniknight": 0.84
        },
        {
            "name": "Night Stalker",
            "Omniknight": -0.629
        },
        {
            "name": "Nyx Assassin",
            "Omniknight": -0.129
        },
        {
            "name": "Ogre Magi",
            "Omniknight": 1.122
        },
        {
            "name": "Oracle",
            "Omniknight": -1.923
        },
        {
            "name": "Outworld Destroyer",
            "Omniknight": 2.302
        },
        {
            "name": "Pangolier",
            "Omniknight": -0.884
        },
        {
            "name": "Phantom Assassin",
            "Omniknight": -0.721
        },
        {
            "name": "Phantom Lancer",
            "Omniknight": -1.699
        },
        {
            "name": "Phoenix",
            "Omniknight": 0.073
        },
        {
            "name": "Primal Beast",
            "Omniknight": -0.045
        },
        {
            "name": "Puck",
            "Omniknight": -1.579
        },
        {
            "name": "Pudge",
            "Omniknight": 1.082
        },
        {
            "name": "Pugna",
            "Omniknight": 1.82
        },
        {
            "name": "Queen of Pain",
            "Omniknight": 1.02
        },
        {
            "name": "Razor",
            "Omniknight": 0.415
        },
        {
            "name": "Riki",
            "Omniknight": 0.655
        },
        {
            "name": "Ringmaster",
            "Omniknight": -0.031
        },
        {
            "name": "Rubick",
            "Omniknight": 0.671
        },
        {
            "name": "Sand King",
            "Omniknight": -0.495
        },
        {
            "name": "Shadow Demon",
            "Omniknight": 0.194
        },
        {
            "name": "Shadow Fiend",
            "Omniknight": 0.652
        },
        {
            "name": "Shadow Shaman",
            "Omniknight": -1.082
        },
        {
            "name": "Silencer",
            "Omniknight": 1.985
        },
        {
            "name": "Skywrath Mage",
            "Omniknight": -1.517
        },
        {
            "name": "Slardar",
            "Omniknight": 0.358
        },
        {
            "name": "Slark",
            "Omniknight": -1.875
        },
        {
            "name": "Snapfire",
            "Omniknight": -2.216
        },
        {
            "name": "Sniper",
            "Omniknight": 0.213
        },
        {
            "name": "Spectre",
            "Omniknight": -0.802
        },
        {
            "name": "Spirit Breaker",
            "Omniknight": -0.454
        },
        {
            "name": "Storm Spirit",
            "Omniknight": -1.642
        },
        {
            "name": "Sven",
            "Omniknight": 1.822
        },
        {
            "name": "Techies",
            "Omniknight": 0.203
        },
        {
            "name": "Templar Assassin",
            "Omniknight": 0.019
        },
        {
            "name": "Terrorblade",
            "Omniknight": 1.343
        },
        {
            "name": "Tidehunter",
            "Omniknight": -3.484
        },
        {
            "name": "Timbersaw",
            "Omniknight": 1.824
        },
        {
            "name": "Tinker",
            "Omniknight": -4.098
        },
        {
            "name": "Tiny",
            "Omniknight": 0.474
        },
        {
            "name": "Treant Protector",
            "Omniknight": 1.123
        },
        {
            "name": "Troll Warlord",
            "Omniknight": -2.059
        },
        {
            "name": "Tusk",
            "Omniknight": -0.168
        },
        {
            "name": "Underlord",
            "Omniknight": -0.953
        },
        {
            "name": "Undying",
            "Omniknight": 2.225
        },
        {
            "name": "Ursa",
            "Omniknight": -0.954
        },
        {
            "name": "Vengeful Spirit",
            "Omniknight": -1.542
        },
        {
            "name": "Venomancer",
            "Omniknight": 0.691
        },
        {
            "name": "Viper",
            "Omniknight": -0.008
        },
        {
            "name": "Visage",
            "Omniknight": 0.629
        },
        {
            "name": "Void Spirit",
            "Omniknight": -3.161
        },
        {
            "name": "Warlock",
            "Omniknight": 1.265
        },
        {
            "name": "Weaver",
            "Omniknight": 1.33
        },
        {
            "name": "Windranger",
            "Omniknight": -0.384
        },
        {
            "name": "Winter Wyvern",
            "Omniknight": 0.556
        },
        {
            "name": "Witch Doctor",
            "Omniknight": 1.679
        },
        {
            "name": "Wraith King",
            "Omniknight": 0.194
        },
        {
            "name": "Zeus",
            "Omniknight": -0.043
        }
    ],
    "Primal Beast": [
        {
            "name": "Abaddon",
            "Primal Beast": -1.427
        },
        {
            "name": "Alchemist",
            "Primal Beast": -0.196
        },
        {
            "name": "Ancient Apparition",
            "Primal Beast": -0.072
        },
        {
            "name": "Anti-Mage",
            "Primal Beast": 2.36
        },
        {
            "name": "Arc Warden",
            "Primal Beast": -0.802
        },
        {
            "name": "Axe",
            "Primal Beast": -1.837
        },
        {
            "name": "Bane",
            "Primal Beast": 0.092
        },
        {
            "name": "Batrider",
            "Primal Beast": 0.916
        },
        {
            "name": "Beastmaster",
            "Primal Beast": -0.23
        },
        {
            "name": "Bloodseeker",
            "Primal Beast": 1.122
        },
        {
            "name": "Bounty Hunter",
            "Primal Beast": -0.486
        },
        {
            "name": "Brewmaster",
            "Primal Beast": -0.658
        },
        {
            "name": "Bristleback",
            "Primal Beast": -3.146
        },
        {
            "name": "Broodmother",
            "Primal Beast": -1.535
        },
        {
            "name": "Centaur Warrunner",
            "Primal Beast": 0.775
        },
        {
            "name": "Chaos Knight",
            "Primal Beast": -0.75
        },
        {
            "name": "Chen",
            "Primal Beast": -0.521
        },
        {
            "name": "Clinkz",
            "Primal Beast": -0.198
        },
        {
            "name": "Clockwerk",
            "Primal Beast": 1.746
        },
        {
            "name": "Crystal Maiden",
            "Primal Beast": -0.369
        },
        {
            "name": "Dark Seer",
            "Primal Beast": 1.052
        },
        {
            "name": "Dark Willow",
            "Primal Beast": 1.051
        },
        {
            "name": "Dawnbreaker",
            "Primal Beast": 0.036
        },
        {
            "name": "Dazzle",
            "Primal Beast": -0.414
        },
        {
            "name": "Death Prophet",
            "Primal Beast": 1.3
        },
        {
            "name": "Disruptor",
            "Primal Beast": 2.043
        },
        {
            "name": "Doom",
            "Primal Beast": 0.311
        },
        {
            "name": "Dragon Knight",
            "Primal Beast": -0.311
        },
        {
            "name": "Drow Ranger",
            "Primal Beast": -1.832
        },
        {
            "name": "Earth Spirit",
            "Primal Beast": -0.645
        },
        {
            "name": "Earthshaker",
            "Primal Beast": 0.729
        },
        {
            "name": "Elder Titan",
            "Primal Beast": 0.068
        },
        {
            "name": "Ember Spirit",
            "Primal Beast": 1.251
        },
        {
            "name": "Enchantress",
            "Primal Beast": 0.204
        },
        {
            "name": "Enigma",
            "Primal Beast": 2.806
        },
        {
            "name": "Faceless Void",
            "Primal Beast": 2.376
        },
        {
            "name": "Grimstroke",
            "Primal Beast": 2.132
        },
        {
            "name": "Gyrocopter",
            "Primal Beast": 0.058
        },
        {
            "name": "Hoodwink",
            "Primal Beast": -1.826
        },
        {
            "name": "Huskar",
            "Primal Beast": 1.495
        },
        {
            "name": "Invoker",
            "Primal Beast": 0.208
        },
        {
            "name": "Io",
            "Primal Beast": -1.315
        },
        {
            "name": "Jakiro",
            "Primal Beast": 0.969
        },
        {
            "name": "Juggernaut",
            "Primal Beast": 2.206
        },
        {
            "name": "Keeper of the Light",
            "Primal Beast": 0.161
        },
        {
            "name": "Kez",
            "Primal Beast": -0.646
        },
        {
            "name": "Kunkka",
            "Primal Beast": -0.122
        },
        {
            "name": "Legion Commander",
            "Primal Beast": -1.349
        },
        {
            "name": "Leshrac",
            "Primal Beast": 0.04
        },
        {
            "name": "Lich",
            "Primal Beast": 1.135
        },
        {
            "name": "Lifestealer",
            "Primal Beast": 1.583
        },
        {
            "name": "Lina",
            "Primal Beast": 0.922
        },
        {
            "name": "Lion",
            "Primal Beast": 1.589
        },
        {
            "name": "Lone Druid",
            "Primal Beast": 0.585
        },
        {
            "name": "Luna",
            "Primal Beast": -1.159
        },
        {
            "name": "Lycan",
            "Primal Beast": 0.726
        },
        {
            "name": "Magnus",
            "Primal Beast": -0.039
        },
        {
            "name": "Marci",
            "Primal Beast": -1.217
        },
        {
            "name": "Mars",
            "Primal Beast": 0.142
        },
        {
            "name": "Medusa",
            "Primal Beast": 0.361
        },
        {
            "name": "Meepo",
            "Primal Beast": -0.624
        },
        {
            "name": "Mirana",
            "Primal Beast": 0.723
        },
        {
            "name": "Monkey King",
            "Primal Beast": -0.226
        },
        {
            "name": "Morphling",
            "Primal Beast": 1.254
        },
        {
            "name": "Muerta",
            "Primal Beast": -2.008
        },
        {
            "name": "Naga Siren",
            "Primal Beast": -1.936
        },
        {
            "name": "Nature's Prophet",
            "Primal Beast": -2.768
        },
        {
            "name": "Necrophos",
            "Primal Beast": 0.34
        },
        {
            "name": "Night Stalker",
            "Primal Beast": 0.44
        },
        {
            "name": "Nyx Assassin",
            "Primal Beast": 0.569
        },
        {
            "name": "Ogre Magi",
            "Primal Beast": 0.561
        },
        {
            "name": "Omniknight",
            "Primal Beast": -0.188
        },
        {
            "name": "Oracle",
            "Primal Beast": 0.459
        },
        {
            "name": "Outworld Destroyer",
            "Primal Beast": 1.991
        },
        {
            "name": "Pangolier",
            "Primal Beast": -0.805
        },
        {
            "name": "Phantom Assassin",
            "Primal Beast": -2.958
        },
        {
            "name": "Phantom Lancer",
            "Primal Beast": -0.018
        },
        {
            "name": "Phoenix",
            "Primal Beast": -0.388
        },
        {
            "name": "Puck",
            "Primal Beast": 1.178
        },
        {
            "name": "Pudge",
            "Primal Beast": -0.645
        },
        {
            "name": "Pugna",
            "Primal Beast": -0.928
        },
        {
            "name": "Queen of Pain",
            "Primal Beast": 1.458
        },
        {
            "name": "Razor",
            "Primal Beast": -2.126
        },
        {
            "name": "Riki",
            "Primal Beast": -1.027
        },
        {
            "name": "Ringmaster",
            "Primal Beast": 0.094
        },
        {
            "name": "Rubick",
            "Primal Beast": -0.888
        },
        {
            "name": "Sand King",
            "Primal Beast": 1.01
        },
        {
            "name": "Shadow Demon",
            "Primal Beast": 0.036
        },
        {
            "name": "Shadow Fiend",
            "Primal Beast": -0.874
        },
        {
            "name": "Shadow Shaman",
            "Primal Beast": 0.955
        },
        {
            "name": "Silencer",
            "Primal Beast": 1.247
        },
        {
            "name": "Skywrath Mage",
            "Primal Beast": 0.837
        },
        {
            "name": "Slardar",
            "Primal Beast": 0.719
        },
        {
            "name": "Slark",
            "Primal Beast": -1.451
        },
        {
            "name": "Snapfire",
            "Primal Beast": 0.39
        },
        {
            "name": "Sniper",
            "Primal Beast": -3.113
        },
        {
            "name": "Spectre",
            "Primal Beast": -0.46
        },
        {
            "name": "Spirit Breaker",
            "Primal Beast": -0.676
        },
        {
            "name": "Storm Spirit",
            "Primal Beast": 0.351
        },
        {
            "name": "Sven",
            "Primal Beast": 0.491
        },
        {
            "name": "Techies",
            "Primal Beast": -0.315
        },
        {
            "name": "Templar Assassin",
            "Primal Beast": -1.438
        },
        {
            "name": "Terrorblade",
            "Primal Beast": -1.784
        },
        {
            "name": "Tidehunter",
            "Primal Beast": -0.143
        },
        {
            "name": "Timbersaw",
            "Primal Beast": -0.448
        },
        {
            "name": "Tinker",
            "Primal Beast": -0.47
        },
        {
            "name": "Tiny",
            "Primal Beast": 0.583
        },
        {
            "name": "Treant Protector",
            "Primal Beast": -0.978
        },
        {
            "name": "Troll Warlord",
            "Primal Beast": 0.186
        },
        {
            "name": "Tusk",
            "Primal Beast": -1.051
        },
        {
            "name": "Underlord",
            "Primal Beast": 2.39
        },
        {
            "name": "Undying",
            "Primal Beast": 1.067
        },
        {
            "name": "Ursa",
            "Primal Beast": -0.075
        },
        {
            "name": "Vengeful Spirit",
            "Primal Beast": -0.006
        },
        {
            "name": "Venomancer",
            "Primal Beast": 0.354
        },
        {
            "name": "Viper",
            "Primal Beast": 1.004
        },
        {
            "name": "Visage",
            "Primal Beast": 0.661
        },
        {
            "name": "Void Spirit",
            "Primal Beast": 0.696
        },
        {
            "name": "Warlock",
            "Primal Beast": -1.376
        },
        {
            "name": "Weaver",
            "Primal Beast": 1.367
        },
        {
            "name": "Windranger",
            "Primal Beast": -0.596
        },
        {
            "name": "Winter Wyvern",
            "Primal Beast": -1.996
        },
        {
            "name": "Witch Doctor",
            "Primal Beast": 1.88
        },
        {
            "name": "Wraith King",
            "Primal Beast": 0.115
        },
        {
            "name": "Zeus",
            "Primal Beast": 0.037
        }
    ],
    "Pudge": [
        {
            "name": "Abaddon",
            "Pudge": -1.235
        },
        {
            "name": "Alchemist",
            "Pudge": 0.478
        },
        {
            "name": "Ancient Apparition",
            "Pudge": -0.488
        },
        {
            "name": "Anti-Mage",
            "Pudge": 1.261
        },
        {
            "name": "Arc Warden",
            "Pudge": -0.623
        },
        {
            "name": "Axe",
            "Pudge": -2.001
        },
        {
            "name": "Bane",
            "Pudge": -0.9
        },
        {
            "name": "Batrider",
            "Pudge": 2.839
        },
        {
            "name": "Beastmaster",
            "Pudge": 2.226
        },
        {
            "name": "Bloodseeker",
            "Pudge": -0.874
        },
        {
            "name": "Bounty Hunter",
            "Pudge": -0.014
        },
        {
            "name": "Brewmaster",
            "Pudge": 0.11
        },
        {
            "name": "Bristleback",
            "Pudge": 1.126
        },
        {
            "name": "Broodmother",
            "Pudge": 1.91
        },
        {
            "name": "Centaur Warrunner",
            "Pudge": 0.246
        },
        {
            "name": "Chaos Knight",
            "Pudge": 1.425
        },
        {
            "name": "Chen",
            "Pudge": 2.192
        },
        {
            "name": "Clinkz",
            "Pudge": -0.676
        },
        {
            "name": "Clockwerk",
            "Pudge": 0.937
        },
        {
            "name": "Crystal Maiden",
            "Pudge": -1.673
        },
        {
            "name": "Dark Seer",
            "Pudge": 0.18
        },
        {
            "name": "Dark Willow",
            "Pudge": 0.446
        },
        {
            "name": "Dawnbreaker",
            "Pudge": 1.315
        },
        {
            "name": "Dazzle",
            "Pudge": 0.569
        },
        {
            "name": "Death Prophet",
            "Pudge": 0.883
        },
        {
            "name": "Disruptor",
            "Pudge": 0.72
        },
        {
            "name": "Doom",
            "Pudge": 1.731
        },
        {
            "name": "Dragon Knight",
            "Pudge": 0.414
        },
        {
            "name": "Drow Ranger",
            "Pudge": -2.365
        },
        {
            "name": "Earth Spirit",
            "Pudge": 1.328
        },
        {
            "name": "Earthshaker",
            "Pudge": 0.751
        },
        {
            "name": "Elder Titan",
            "Pudge": 0.931
        },
        {
            "name": "Ember Spirit",
            "Pudge": 1.078
        },
        {
            "name": "Enchantress",
            "Pudge": -1.088
        },
        {
            "name": "Enigma",
            "Pudge": 0.797
        },
        {
            "name": "Faceless Void",
            "Pudge": 0.245
        },
        {
            "name": "Grimstroke",
            "Pudge": 1.121
        },
        {
            "name": "Gyrocopter",
            "Pudge": 0.165
        },
        {
            "name": "Hoodwink",
            "Pudge": 1.124
        },
        {
            "name": "Huskar",
            "Pudge": -0.167
        },
        {
            "name": "Invoker",
            "Pudge": 0.984
        },
        {
            "name": "Io",
            "Pudge": 0.914
        },
        {
            "name": "Jakiro",
            "Pudge": -0.541
        },
        {
            "name": "Juggernaut",
            "Pudge": -0.052
        },
        {
            "name": "Keeper of the Light",
            "Pudge": 0.521
        },
        {
            "name": "Kez",
            "Pudge": 0.57
        },
        {
            "name": "Kunkka",
            "Pudge": 1.415
        },
        {
            "name": "Legion Commander",
            "Pudge": -1.979
        },
        {
            "name": "Leshrac",
            "Pudge": 0.185
        },
        {
            "name": "Lich",
            "Pudge": -0.913
        },
        {
            "name": "Lifestealer",
            "Pudge": 0.472
        },
        {
            "name": "Lina",
            "Pudge": -0.239
        },
        {
            "name": "Lion",
            "Pudge": -0.109
        },
        {
            "name": "Lone Druid",
            "Pudge": 1.133
        },
        {
            "name": "Luna",
            "Pudge": 1.005
        },
        {
            "name": "Lycan",
            "Pudge": 1.763
        },
        {
            "name": "Magnus",
            "Pudge": 2.308
        },
        {
            "name": "Marci",
            "Pudge": 2.879
        },
        {
            "name": "Mars",
            "Pudge": 1.044
        },
        {
            "name": "Medusa",
            "Pudge": -0.061
        },
        {
            "name": "Meepo",
            "Pudge": 0.535
        },
        {
            "name": "Mirana",
            "Pudge": 1.098
        },
        {
            "name": "Monkey King",
            "Pudge": 2.321
        },
        {
            "name": "Morphling",
            "Pudge": 0.616
        },
        {
            "name": "Muerta",
            "Pudge": -0.28
        },
        {
            "name": "Naga Siren",
            "Pudge": 1.0
        },
        {
            "name": "Nature's Prophet",
            "Pudge": 0.179
        },
        {
            "name": "Necrophos",
            "Pudge": -2.1
        },
        {
            "name": "Night Stalker",
            "Pudge": 0.553
        },
        {
            "name": "Nyx Assassin",
            "Pudge": -0.006
        },
        {
            "name": "Ogre Magi",
            "Pudge": -0.509
        },
        {
            "name": "Omniknight",
            "Pudge": -0.402
        },
        {
            "name": "Oracle",
            "Pudge": -0.865
        },
        {
            "name": "Outworld Destroyer",
            "Pudge": -0.111
        },
        {
            "name": "Pangolier",
            "Pudge": 2.548
        },
        {
            "name": "Phantom Assassin",
            "Pudge": -1.785
        },
        {
            "name": "Phantom Lancer",
            "Pudge": -0.276
        },
        {
            "name": "Phoenix",
            "Pudge": 0.538
        },
        {
            "name": "Primal Beast",
            "Pudge": 1.438
        },
        {
            "name": "Puck",
            "Pudge": 1.55
        },
        {
            "name": "Pugna",
            "Pudge": -2.006
        },
        {
            "name": "Queen of Pain",
            "Pudge": 0.958
        },
        {
            "name": "Razor",
            "Pudge": -0.466
        },
        {
            "name": "Riki",
            "Pudge": -0.578
        },
        {
            "name": "Ringmaster",
            "Pudge": 1.212
        },
        {
            "name": "Rubick",
            "Pudge": -0.391
        },
        {
            "name": "Sand King",
            "Pudge": -0.893
        },
        {
            "name": "Shadow Demon",
            "Pudge": 0.077
        },
        {
            "name": "Shadow Fiend",
            "Pudge": 0.58
        },
        {
            "name": "Shadow Shaman",
            "Pudge": -1.254
        },
        {
            "name": "Silencer",
            "Pudge": 0.308
        },
        {
            "name": "Skywrath Mage",
            "Pudge": 0.633
        },
        {
            "name": "Slardar",
            "Pudge": 1.544
        },
        {
            "name": "Slark",
            "Pudge": 0.387
        },
        {
            "name": "Snapfire",
            "Pudge": 0.896
        },
        {
            "name": "Sniper",
            "Pudge": -2.64
        },
        {
            "name": "Spectre",
            "Pudge": -1.077
        },
        {
            "name": "Spirit Breaker",
            "Pudge": -0.626
        },
        {
            "name": "Storm Spirit",
            "Pudge": 1.067
        },
        {
            "name": "Sven",
            "Pudge": 1.687
        },
        {
            "name": "Techies",
            "Pudge": -0.019
        },
        {
            "name": "Templar Assassin",
            "Pudge": 0.521
        },
        {
            "name": "Terrorblade",
            "Pudge": 1.957
        },
        {
            "name": "Tidehunter",
            "Pudge": 0.237
        },
        {
            "name": "Timbersaw",
            "Pudge": 2.153
        },
        {
            "name": "Tinker",
            "Pudge": -1.076
        },
        {
            "name": "Tiny",
            "Pudge": 0.846
        },
        {
            "name": "Treant Protector",
            "Pudge": -0.117
        },
        {
            "name": "Troll Warlord",
            "Pudge": 0.21
        },
        {
            "name": "Tusk",
            "Pudge": 1.857
        },
        {
            "name": "Underlord",
            "Pudge": -1.075
        },
        {
            "name": "Undying",
            "Pudge": 0.762
        },
        {
            "name": "Ursa",
            "Pudge": 2.687
        },
        {
            "name": "Vengeful Spirit",
            "Pudge": 0.589
        },
        {
            "name": "Venomancer",
            "Pudge": -0.128
        },
        {
            "name": "Viper",
            "Pudge": -1.668
        },
        {
            "name": "Visage",
            "Pudge": 0.43
        },
        {
            "name": "Void Spirit",
            "Pudge": 1.312
        },
        {
            "name": "Warlock",
            "Pudge": -0.32
        },
        {
            "name": "Weaver",
            "Pudge": 1.738
        },
        {
            "name": "Windranger",
            "Pudge": 1.121
        },
        {
            "name": "Winter Wyvern",
            "Pudge": -0.586
        },
        {
            "name": "Witch Doctor",
            "Pudge": 0.209
        },
        {
            "name": "Wraith King",
            "Pudge": 0.721
        },
        {
            "name": "Zeus",
            "Pudge": -0.565
        }
    ],
    "Slardar": [
        {
            "name": "Abaddon",
            "Slardar": 0.169
        },
        {
            "name": "Alchemist",
            "Slardar": -0.462
        },
        {
            "name": "Ancient Apparition",
            "Slardar": -0.954
        },
        {
            "name": "Anti-Mage",
            "Slardar": -4.011
        },
        {
            "name": "Arc Warden",
            "Slardar": 1.146
        },
        {
            "name": "Axe",
            "Slardar": 0.359
        },
        {
            "name": "Bane",
            "Slardar": -1.354
        },
        {
            "name": "Batrider",
            "Slardar": -0.973
        },
        {
            "name": "Beastmaster",
            "Slardar": 0.973
        },
        {
            "name": "Bloodseeker",
            "Slardar": -0.656
        },
        {
            "name": "Bounty Hunter",
            "Slardar": -0.81
        },
        {
            "name": "Brewmaster",
            "Slardar": -0.755
        },
        {
            "name": "Bristleback",
            "Slardar": -0.66
        },
        {
            "name": "Broodmother",
            "Slardar": -2.834
        },
        {
            "name": "Centaur Warrunner",
            "Slardar": -1.058
        },
        {
            "name": "Chaos Knight",
            "Slardar": 1.473
        },
        {
            "name": "Chen",
            "Slardar": -2.977
        },
        {
            "name": "Clinkz",
            "Slardar": -2.163
        },
        {
            "name": "Clockwerk",
            "Slardar": 0.126
        },
        {
            "name": "Crystal Maiden",
            "Slardar": 0.859
        },
        {
            "name": "Dark Seer",
            "Slardar": 0.145
        },
        {
            "name": "Dark Willow",
            "Slardar": 1.49
        },
        {
            "name": "Dawnbreaker",
            "Slardar": -0.458
        },
        {
            "name": "Dazzle",
            "Slardar": 0.097
        },
        {
            "name": "Death Prophet",
            "Slardar": -1.553
        },
        {
            "name": "Disruptor",
            "Slardar": 0.207
        },
        {
            "name": "Doom",
            "Slardar": -0.176
        },
        {
            "name": "Dragon Knight",
            "Slardar": 0.317
        },
        {
            "name": "Drow Ranger",
            "Slardar": 2.458
        },
        {
            "name": "Earth Spirit",
            "Slardar": -1.338
        },
        {
            "name": "Earthshaker",
            "Slardar": -0.231
        },
        {
            "name": "Elder Titan",
            "Slardar": -1.879
        },
        {
            "name": "Ember Spirit",
            "Slardar": -0.115
        },
        {
            "name": "Enchantress",
            "Slardar": 0.152
        },
        {
            "name": "Enigma",
            "Slardar": 0.867
        },
        {
            "name": "Faceless Void",
            "Slardar": -1.159
        },
        {
            "name": "Grimstroke",
            "Slardar": -0.242
        },
        {
            "name": "Gyrocopter",
            "Slardar": 1.275
        },
        {
            "name": "Hoodwink",
            "Slardar": 0.012
        },
        {
            "name": "Huskar",
            "Slardar": 0.647
        },
        {
            "name": "Invoker",
            "Slardar": -0.656
        },
        {
            "name": "Io",
            "Slardar": -1.427
        },
        {
            "name": "Jakiro",
            "Slardar": 0.814
        },
        {
            "name": "Juggernaut",
            "Slardar": 2.059
        },
        {
            "name": "Keeper of the Light",
            "Slardar": -1.29
        },
        {
            "name": "Kez",
            "Slardar": 0.551
        },
        {
            "name": "Kunkka",
            "Slardar": -1.677
        },
        {
            "name": "Legion Commander",
            "Slardar": -0.538
        },
        {
            "name": "Leshrac",
            "Slardar": -0.41
        },
        {
            "name": "Lich",
            "Slardar": 0.922
        },
        {
            "name": "Lifestealer",
            "Slardar": -2.849
        },
        {
            "name": "Lina",
            "Slardar": -1.318
        },
        {
            "name": "Lion",
            "Slardar": 0.191
        },
        {
            "name": "Lone Druid",
            "Slardar": -0.329
        },
        {
            "name": "Luna",
            "Slardar": 2.777
        },
        {
            "name": "Lycan",
            "Slardar": -2.925
        },
        {
            "name": "Magnus",
            "Slardar": 0.041
        },
        {
            "name": "Marci",
            "Slardar": -1.206
        },
        {
            "name": "Mars",
            "Slardar": -0.444
        },
        {
            "name": "Medusa",
            "Slardar": 2.567
        },
        {
            "name": "Meepo",
            "Slardar": -0.203
        },
        {
            "name": "Mirana",
            "Slardar": 0.574
        },
        {
            "name": "Monkey King",
            "Slardar": 0.626
        },
        {
            "name": "Morphling",
            "Slardar": 0.861
        },
        {
            "name": "Muerta",
            "Slardar": 2.511
        },
        {
            "name": "Naga Siren",
            "Slardar": 1.193
        },
        {
            "name": "Nature's Prophet",
            "Slardar": -0.658
        },
        {
            "name": "Necrophos",
            "Slardar": 0.295
        },
        {
            "name": "Night Stalker",
            "Slardar": -0.77
        },
        {
            "name": "Nyx Assassin",
            "Slardar": -2.04
        },
        {
            "name": "Ogre Magi",
            "Slardar": 1.284
        },
        {
            "name": "Omniknight",
            "Slardar": -0.316
        },
        {
            "name": "Oracle",
            "Slardar": -1.317
        },
        {
            "name": "Outworld Destroyer",
            "Slardar": 0.171
        },
        {
            "name": "Pangolier",
            "Slardar": 0.071
        },
        {
            "name": "Phantom Assassin",
            "Slardar": 0.541
        },
        {
            "name": "Phantom Lancer",
            "Slardar": 2.647
        },
        {
            "name": "Phoenix",
            "Slardar": 1.616
        },
        {
            "name": "Primal Beast",
            "Slardar": -0.672
        },
        {
            "name": "Puck",
            "Slardar": -0.039
        },
        {
            "name": "Pudge",
            "Slardar": -1.506
        },
        {
            "name": "Pugna",
            "Slardar": -1.595
        },
        {
            "name": "Queen of Pain",
            "Slardar": 0.019
        },
        {
            "name": "Razor",
            "Slardar": 1.148
        },
        {
            "name": "Riki",
            "Slardar": -0.242
        },
        {
            "name": "Ringmaster",
            "Slardar": 0.608
        },
        {
            "name": "Rubick",
            "Slardar": -0.67
        },
        {
            "name": "Sand King",
            "Slardar": -1.067
        },
        {
            "name": "Shadow Demon",
            "Slardar": 0.272
        },
        {
            "name": "Shadow Fiend",
            "Slardar": 0.308
        },
        {
            "name": "Shadow Shaman",
            "Slardar": 2.024
        },
        {
            "name": "Silencer",
            "Slardar": 1.018
        },
        {
            "name": "Skywrath Mage",
            "Slardar": -0.517
        },
        {
            "name": "Slark",
            "Slardar": 0.871
        },
        {
            "name": "Snapfire",
            "Slardar": -0.344
        },
        {
            "name": "Sniper",
            "Slardar": 0.629
        },
        {
            "name": "Spectre",
            "Slardar": -1.894
        },
        {
            "name": "Spirit Breaker",
            "Slardar": -0.37
        },
        {
            "name": "Storm Spirit",
            "Slardar": 0.513
        },
        {
            "name": "Sven",
            "Slardar": -1.463
        },
        {
            "name": "Techies",
            "Slardar": 0.739
        },
        {
            "name": "Templar Assassin",
            "Slardar": -0.521
        },
        {
            "name": "Terrorblade",
            "Slardar": 2.77
        },
        {
            "name": "Tidehunter",
            "Slardar": 2.134
        },
        {
            "name": "Timbersaw",
            "Slardar": -0.933
        },
        {
            "name": "Tinker",
            "Slardar": -0.951
        },
        {
            "name": "Tiny",
            "Slardar": 0.089
        },
        {
            "name": "Treant Protector",
            "Slardar": 0.232
        },
        {
            "name": "Troll Warlord",
            "Slardar": 2.366
        },
        {
            "name": "Tusk",
            "Slardar": -0.142
        },
        {
            "name": "Underlord",
            "Slardar": 0.218
        },
        {
            "name": "Undying",
            "Slardar": -0.417
        },
        {
            "name": "Ursa",
            "Slardar": -1.692
        },
        {
            "name": "Vengeful Spirit",
            "Slardar": 0.39
        },
        {
            "name": "Venomancer",
            "Slardar": 1.396
        },
        {
            "name": "Viper",
            "Slardar": 0.042
        },
        {
            "name": "Visage",
            "Slardar": 0.449
        },
        {
            "name": "Void Spirit",
            "Slardar": -1.232
        },
        {
            "name": "Warlock",
            "Slardar": -1.966
        },
        {
            "name": "Weaver",
            "Slardar": -0.65
        },
        {
            "name": "Windranger",
            "Slardar": 0.974
        },
        {
            "name": "Winter Wyvern",
            "Slardar": 0.172
        },
        {
            "name": "Witch Doctor",
            "Slardar": 0.103
        },
        {
            "name": "Wraith King",
            "Slardar": -0.026
        },
        {
            "name": "Zeus",
            "Slardar": 0.063
        }
    ],
    "Spirit Breaker": [
        {
            "name": "Abaddon",
            "Spirit Breaker": -0.393
        },
        {
            "name": "Alchemist",
            "Spirit Breaker": 0.039
        },
        {
            "name": "Ancient Apparition",
            "Spirit Breaker": -1.402
        },
        {
            "name": "Anti-Mage",
            "Spirit Breaker": -0.752
        },
        {
            "name": "Arc Warden",
            "Spirit Breaker": 0.14
        },
        {
            "name": "Axe",
            "Spirit Breaker": -1.013
        },
        {
            "name": "Bane",
            "Spirit Breaker": -0.58
        },
        {
            "name": "Batrider",
            "Spirit Breaker": -0.274
        },
        {
            "name": "Beastmaster",
            "Spirit Breaker": 1.526
        },
        {
            "name": "Bloodseeker",
            "Spirit Breaker": -0.679
        },
        {
            "name": "Bounty Hunter",
            "Spirit Breaker": -0.525
        },
        {
            "name": "Brewmaster",
            "Spirit Breaker": -1.126
        },
        {
            "name": "Bristleback",
            "Spirit Breaker": -1.124
        },
        {
            "name": "Broodmother",
            "Spirit Breaker": -3.303
        },
        {
            "name": "Centaur Warrunner",
            "Spirit Breaker": 0.485
        },
        {
            "name": "Chaos Knight",
            "Spirit Breaker": 0.894
        },
        {
            "name": "Chen",
            "Spirit Breaker": 0.041
        },
        {
            "name": "Clinkz",
            "Spirit Breaker": -1.023
        },
        {
            "name": "Clockwerk",
            "Spirit Breaker": 2.61
        },
        {
            "name": "Crystal Maiden",
            "Spirit Breaker": 0.859
        },
        {
            "name": "Dark Seer",
            "Spirit Breaker": 0.596
        },
        {
            "name": "Dark Willow",
            "Spirit Breaker": 1.264
        },
        {
            "name": "Dawnbreaker",
            "Spirit Breaker": 1.193
        },
        {
            "name": "Dazzle",
            "Spirit Breaker": 0.19
        },
        {
            "name": "Death Prophet",
            "Spirit Breaker": -0.377
        },
        {
            "name": "Disruptor",
            "Spirit Breaker": 0.897
        },
        {
            "name": "Doom",
            "Spirit Breaker": 0.448
        },
        {
            "name": "Dragon Knight",
            "Spirit Breaker": 0.183
        },
        {
            "name": "Drow Ranger",
            "Spirit Breaker": -0.526
        },
        {
            "name": "Earth Spirit",
            "Spirit Breaker": -0.218
        },
        {
            "name": "Earthshaker",
            "Spirit Breaker": -0.017
        },
        {
            "name": "Elder Titan",
            "Spirit Breaker": -1.837
        },
        {
            "name": "Ember Spirit",
            "Spirit Breaker": 0.957
        },
        {
            "name": "Enchantress",
            "Spirit Breaker": 1.111
        },
        {
            "name": "Enigma",
            "Spirit Breaker": 0.864
        },
        {
            "name": "Faceless Void",
            "Spirit Breaker": 0.879
        },
        {
            "name": "Grimstroke",
            "Spirit Breaker": -0.337
        },
        {
            "name": "Gyrocopter",
            "Spirit Breaker": 0.086
        },
        {
            "name": "Hoodwink",
            "Spirit Breaker": -1.094
        },
        {
            "name": "Huskar",
            "Spirit Breaker": -0.035
        },
        {
            "name": "Invoker",
            "Spirit Breaker": 0.986
        },
        {
            "name": "Io",
            "Spirit Breaker": 0.074
        },
        {
            "name": "Jakiro",
            "Spirit Breaker": 1.436
        },
        {
            "name": "Juggernaut",
            "Spirit Breaker": -1.609
        },
        {
            "name": "Keeper of the Light",
            "Spirit Breaker": -0.529
        },
        {
            "name": "Kez",
            "Spirit Breaker": -1.155
        },
        {
            "name": "Kunkka",
            "Spirit Breaker": 0.269
        },
        {
            "name": "Legion Commander",
            "Spirit Breaker": -1.757
        },
        {
            "name": "Leshrac",
            "Spirit Breaker": 0.508
        },
        {
            "name": "Lich",
            "Spirit Breaker": 1.422
        },
        {
            "name": "Lifestealer",
            "Spirit Breaker": -1.172
        },
        {
            "name": "Lina",
            "Spirit Breaker": -1.046
        },
        {
            "name": "Lion",
            "Spirit Breaker": 0.191
        },
        {
            "name": "Lone Druid",
            "Spirit Breaker": 0.085
        },
        {
            "name": "Luna",
            "Spirit Breaker": -0.087
        },
        {
            "name": "Lycan",
            "Spirit Breaker": -2.118
        },
        {
            "name": "Magnus",
            "Spirit Breaker": 0.015
        },
        {
            "name": "Marci",
            "Spirit Breaker": -0.309
        },
        {
            "name": "Mars",
            "Spirit Breaker": 2.32
        },
        {
            "name": "Medusa",
            "Spirit Breaker": -0.017
        },
        {
            "name": "Meepo",
            "Spirit Breaker": 2.828
        },
        {
            "name": "Mirana",
            "Spirit Breaker": -0.109
        },
        {
            "name": "Monkey King",
            "Spirit Breaker": 0.496
        },
        {
            "name": "Morphling",
            "Spirit Breaker": 0.898
        },
        {
            "name": "Muerta",
            "Spirit Breaker": 0.112
        },
        {
            "name": "Naga Siren",
            "Spirit Breaker": 2.114
        },
        {
            "name": "Nature's Prophet",
            "Spirit Breaker": -0.08
        },
        {
            "name": "Necrophos",
            "Spirit Breaker": 2.8
        },
        {
            "name": "Night Stalker",
            "Spirit Breaker": -1.419
        },
        {
            "name": "Nyx Assassin",
            "Spirit Breaker": 0.048
        },
        {
            "name": "Ogre Magi",
            "Spirit Breaker": 0.607
        },
        {
            "name": "Omniknight",
            "Spirit Breaker": 0.623
        },
        {
            "name": "Oracle",
            "Spirit Breaker": 1.155
        },
        {
            "name": "Outworld Destroyer",
            "Spirit Breaker": -0.025
        },
        {
            "name": "Pangolier",
            "Spirit Breaker": -0.228
        },
        {
            "name": "Phantom Assassin",
            "Spirit Breaker": -0.589
        },
        {
            "name": "Phantom Lancer",
            "Spirit Breaker": 0.5
        },
        {
            "name": "Phoenix",
            "Spirit Breaker": 0.386
        },
        {
            "name": "Primal Beast",
            "Spirit Breaker": 1.082
        },
        {
            "name": "Puck",
            "Spirit Breaker": 0.321
        },
        {
            "name": "Pudge",
            "Spirit Breaker": 0.623
        },
        {
            "name": "Pugna",
            "Spirit Breaker": -1.215
        },
        {
            "name": "Queen of Pain",
            "Spirit Breaker": 0.395
        },
        {
            "name": "Razor",
            "Spirit Breaker": 0.37
        },
        {
            "name": "Riki",
            "Spirit Breaker": -0.615
        },
        {
            "name": "Ringmaster",
            "Spirit Breaker": 0.591
        },
        {
            "name": "Rubick",
            "Spirit Breaker": 0.257
        },
        {
            "name": "Sand King",
            "Spirit Breaker": 0.642
        },
        {
            "name": "Shadow Demon",
            "Spirit Breaker": -0.114
        },
        {
            "name": "Shadow Fiend",
            "Spirit Breaker": -1.061
        },
        {
            "name": "Shadow Shaman",
            "Spirit Breaker": -0.23
        },
        {
            "name": "Silencer",
            "Spirit Breaker": -1.562
        },
        {
            "name": "Skywrath Mage",
            "Spirit Breaker": -0.764
        },
        {
            "name": "Slardar",
            "Spirit Breaker": 0.504
        },
        {
            "name": "Slark",
            "Spirit Breaker": -0.359
        },
        {
            "name": "Snapfire",
            "Spirit Breaker": 0.223
        },
        {
            "name": "Sniper",
            "Spirit Breaker": -1.436
        },
        {
            "name": "Spectre",
            "Spirit Breaker": 1.332
        },
        {
            "name": "Storm Spirit",
            "Spirit Breaker": -0.28
        },
        {
            "name": "Sven",
            "Spirit Breaker": 0.909
        },
        {
            "name": "Techies",
            "Spirit Breaker": 0.63
        },
        {
            "name": "Templar Assassin",
            "Spirit Breaker": 0.811
        },
        {
            "name": "Terrorblade",
            "Spirit Breaker": 0.475
        },
        {
            "name": "Tidehunter",
            "Spirit Breaker": 1.547
        },
        {
            "name": "Timbersaw",
            "Spirit Breaker": 1.166
        },
        {
            "name": "Tinker",
            "Spirit Breaker": 0.088
        },
        {
            "name": "Tiny",
            "Spirit Breaker": 2.247
        },
        {
            "name": "Treant Protector",
            "Spirit Breaker": 0.986
        },
        {
            "name": "Troll Warlord",
            "Spirit Breaker": 0.015
        },
        {
            "name": "Tusk",
            "Spirit Breaker": 1.552
        },
        {
            "name": "Underlord",
            "Spirit Breaker": 3.472
        },
        {
            "name": "Undying",
            "Spirit Breaker": 0.366
        },
        {
            "name": "Ursa",
            "Spirit Breaker": -0.066
        },
        {
            "name": "Vengeful Spirit",
            "Spirit Breaker": 0.097
        },
        {
            "name": "Venomancer",
            "Spirit Breaker": 1.426
        },
        {
            "name": "Viper",
            "Spirit Breaker": 0.216
        },
        {
            "name": "Visage",
            "Spirit Breaker": 0.058
        },
        {
            "name": "Void Spirit",
            "Spirit Breaker": -0.151
        },
        {
            "name": "Warlock",
            "Spirit Breaker": 0.413
        },
        {
            "name": "Weaver",
            "Spirit Breaker": -0.045
        },
        {
            "name": "Windranger",
            "Spirit Breaker": 0.1
        },
        {
            "name": "Winter Wyvern",
            "Spirit Breaker": 1.038
        },
        {
            "name": "Witch Doctor",
            "Spirit Breaker": 0.658
        },
        {
            "name": "Wraith King",
            "Spirit Breaker": 1.003
        },
        {
            "name": "Zeus",
            "Spirit Breaker": -0.14
        }
    ],
    "Sven": [
        {
            "name": "Abaddon",
            "Sven": -2.014
        },
        {
            "name": "Alchemist",
            "Sven": 1.203
        },
        {
            "name": "Ancient Apparition",
            "Sven": 0.266
        },
        {
            "name": "Anti-Mage",
            "Sven": -1.479
        },
        {
            "name": "Arc Warden",
            "Sven": -3.123
        },
        {
            "name": "Axe",
            "Sven": 0.691
        },
        {
            "name": "Bane",
            "Sven": -0.305
        },
        {
            "name": "Batrider",
            "Sven": -1.778
        },
        {
            "name": "Beastmaster",
            "Sven": -1.346
        },
        {
            "name": "Bloodseeker",
            "Sven": -0.569
        },
        {
            "name": "Bounty Hunter",
            "Sven": 0.856
        },
        {
            "name": "Brewmaster",
            "Sven": -0.77
        },
        {
            "name": "Bristleback",
            "Sven": 2.104
        },
        {
            "name": "Broodmother",
            "Sven": -3.531
        },
        {
            "name": "Centaur Warrunner",
            "Sven": -0.879
        },
        {
            "name": "Chaos Knight",
            "Sven": -1.63
        },
        {
            "name": "Chen",
            "Sven": -3.184
        },
        {
            "name": "Clinkz",
            "Sven": 0.902
        },
        {
            "name": "Clockwerk",
            "Sven": 0.775
        },
        {
            "name": "Crystal Maiden",
            "Sven": 1.346
        },
        {
            "name": "Dark Seer",
            "Sven": -0.581
        },
        {
            "name": "Dark Willow",
            "Sven": 1.145
        },
        {
            "name": "Dawnbreaker",
            "Sven": -0.937
        },
        {
            "name": "Dazzle",
            "Sven": -0.841
        },
        {
            "name": "Death Prophet",
            "Sven": -1.513
        },
        {
            "name": "Disruptor",
            "Sven": 0.814
        },
        {
            "name": "Doom",
            "Sven": 0.052
        },
        {
            "name": "Dragon Knight",
            "Sven": 1.799
        },
        {
            "name": "Drow Ranger",
            "Sven": -1.265
        },
        {
            "name": "Earth Spirit",
            "Sven": -1.369
        },
        {
            "name": "Earthshaker",
            "Sven": -0.944
        },
        {
            "name": "Elder Titan",
            "Sven": -3.555
        },
        {
            "name": "Ember Spirit",
            "Sven": -0.178
        },
        {
            "name": "Enchantress",
            "Sven": 0.505
        },
        {
            "name": "Enigma",
            "Sven": -0.338
        },
        {
            "name": "Faceless Void",
            "Sven": 0.349
        },
        {
            "name": "Grimstroke",
            "Sven": -0.307
        },
        {
            "name": "Gyrocopter",
            "Sven": 0.18
        },
        {
            "name": "Hoodwink",
            "Sven": -0.388
        },
        {
            "name": "Huskar",
            "Sven": 1.495
        },
        {
            "name": "Invoker",
            "Sven": 0.272
        },
        {
            "name": "Io",
            "Sven": -1.96
        },
        {
            "name": "Jakiro",
            "Sven": 0.104
        },
        {
            "name": "Juggernaut",
            "Sven": 0.058
        },
        {
            "name": "Keeper of the Light",
            "Sven": -1.626
        },
        {
            "name": "Kez",
            "Sven": -0.058
        },
        {
            "name": "Kunkka",
            "Sven": 0.449
        },
        {
            "name": "Legion Commander",
            "Sven": -0.485
        },
        {
            "name": "Leshrac",
            "Sven": -1.385
        },
        {
            "name": "Lich",
            "Sven": 1.076
        },
        {
            "name": "Lifestealer",
            "Sven": 0.915
        },
        {
            "name": "Lina",
            "Sven": -1.129
        },
        {
            "name": "Lion",
            "Sven": 0.421
        },
        {
            "name": "Lone Druid",
            "Sven": -1.731
        },
        {
            "name": "Luna",
            "Sven": 1.223
        },
        {
            "name": "Lycan",
            "Sven": -3.948
        },
        {
            "name": "Magnus",
            "Sven": -0.706
        },
        {
            "name": "Marci",
            "Sven": -2.665
        },
        {
            "name": "Mars",
            "Sven": -0.118
        },
        {
            "name": "Medusa",
            "Sven": -0.462
        },
        {
            "name": "Meepo",
            "Sven": -4.369
        },
        {
            "name": "Mirana",
            "Sven": 0.956
        },
        {
            "name": "Monkey King",
            "Sven": 0.54
        },
        {
            "name": "Morphling",
            "Sven": 0.208
        },
        {
            "name": "Muerta",
            "Sven": 0.505
        },
        {
            "name": "Naga Siren",
            "Sven": -4.976
        },
        {
            "name": "Nature's Prophet",
            "Sven": 0.731
        },
        {
            "name": "Necrophos",
            "Sven": 0.712
        },
        {
            "name": "Night Stalker",
            "Sven": -1.297
        },
        {
            "name": "Nyx Assassin",
            "Sven": -0.882
        },
        {
            "name": "Ogre Magi",
            "Sven": 1.017
        },
        {
            "name": "Omniknight",
            "Sven": -2.013
        },
        {
            "name": "Oracle",
            "Sven": -0.75
        },
        {
            "name": "Outworld Destroyer",
            "Sven": 0.184
        },
        {
            "name": "Pangolier",
            "Sven": -1.445
        },
        {
            "name": "Phantom Assassin",
            "Sven": 1.309
        },
        {
            "name": "Phantom Lancer",
            "Sven": -3.79
        },
        {
            "name": "Phoenix",
            "Sven": 1.317
        },
        {
            "name": "Primal Beast",
            "Sven": -0.514
        },
        {
            "name": "Puck",
            "Sven": -1.673
        },
        {
            "name": "Pudge",
            "Sven": -0.578
        },
        {
            "name": "Pugna",
            "Sven": -2.156
        },
        {
            "name": "Queen of Pain",
            "Sven": 0.695
        },
        {
            "name": "Razor",
            "Sven": 0.742
        },
        {
            "name": "Riki",
            "Sven": -0.385
        },
        {
            "name": "Ringmaster",
            "Sven": 0.178
        },
        {
            "name": "Rubick",
            "Sven": 1.011
        },
        {
            "name": "Sand King",
            "Sven": -1.069
        },
        {
            "name": "Shadow Demon",
            "Sven": -2.043
        },
        {
            "name": "Shadow Fiend",
            "Sven": -0.075
        },
        {
            "name": "Shadow Shaman",
            "Sven": 1.035
        },
        {
            "name": "Silencer",
            "Sven": 0.577
        },
        {
            "name": "Skywrath Mage",
            "Sven": -1.03
        },
        {
            "name": "Slardar",
            "Sven": 1.614
        },
        {
            "name": "Slark",
            "Sven": 0.922
        },
        {
            "name": "Snapfire",
            "Sven": -1.647
        },
        {
            "name": "Sniper",
            "Sven": 0.843
        },
        {
            "name": "Spectre",
            "Sven": -0.917
        },
        {
            "name": "Spirit Breaker",
            "Sven": -0.351
        },
        {
            "name": "Storm Spirit",
            "Sven": 0.343
        },
        {
            "name": "Techies",
            "Sven": 1.064
        },
        {
            "name": "Templar Assassin",
            "Sven": 1.354
        },
        {
            "name": "Terrorblade",
            "Sven": 0.395
        },
        {
            "name": "Tidehunter",
            "Sven": -1.917
        },
        {
            "name": "Timbersaw",
            "Sven": 0.252
        },
        {
            "name": "Tinker",
            "Sven": -1.647
        },
        {
            "name": "Tiny",
            "Sven": -0.693
        },
        {
            "name": "Treant Protector",
            "Sven": 0.765
        },
        {
            "name": "Troll Warlord",
            "Sven": 2.2
        },
        {
            "name": "Tusk",
            "Sven": -0.791
        },
        {
            "name": "Underlord",
            "Sven": -1.075
        },
        {
            "name": "Undying",
            "Sven": 1.182
        },
        {
            "name": "Ursa",
            "Sven": 0.542
        },
        {
            "name": "Vengeful Spirit",
            "Sven": 0.211
        },
        {
            "name": "Venomancer",
            "Sven": 0.532
        },
        {
            "name": "Viper",
            "Sven": 0.466
        },
        {
            "name": "Visage",
            "Sven": -2.402
        },
        {
            "name": "Void Spirit",
            "Sven": -2.562
        },
        {
            "name": "Warlock",
            "Sven": -1.725
        },
        {
            "name": "Weaver",
            "Sven": 1.187
        },
        {
            "name": "Windranger",
            "Sven": 1.024
        },
        {
            "name": "Winter Wyvern",
            "Sven": 0.954
        },
        {
            "name": "Witch Doctor",
            "Sven": 0.587
        },
        {
            "name": "Wraith King",
            "Sven": 1.702
        },
        {
            "name": "Zeus",
            "Sven": -0.445
        }
    ],
    "Tidehunter": [
        {
            "name": "Abaddon",
            "Tidehunter": 0.343
        },
        {
            "name": "Alchemist",
            "Tidehunter": 1.072
        },
        {
            "name": "Ancient Apparition",
            "Tidehunter": 0.462
        },
        {
            "name": "Anti-Mage",
            "Tidehunter": 2.018
        },
        {
            "name": "Arc Warden",
            "Tidehunter": -1.155
        },
        {
            "name": "Axe",
            "Tidehunter": -0.664
        },
        {
            "name": "Bane",
            "Tidehunter": -1.709
        },
        {
            "name": "Batrider",
            "Tidehunter": -0.663
        },
        {
            "name": "Beastmaster",
            "Tidehunter": -2.106
        },
        {
            "name": "Bloodseeker",
            "Tidehunter": 2.17
        },
        {
            "name": "Bounty Hunter",
            "Tidehunter": -1.879
        },
        {
            "name": "Brewmaster",
            "Tidehunter": 0.583
        },
        {
            "name": "Bristleback",
            "Tidehunter": 2.154
        },
        {
            "name": "Broodmother",
            "Tidehunter": -0.615
        },
        {
            "name": "Centaur Warrunner",
            "Tidehunter": -0.691
        },
        {
            "name": "Chaos Knight",
            "Tidehunter": -2.631
        },
        {
            "name": "Chen",
            "Tidehunter": 6.683
        },
        {
            "name": "Clinkz",
            "Tidehunter": 1.483
        },
        {
            "name": "Clockwerk",
            "Tidehunter": 0.872
        },
        {
            "name": "Crystal Maiden",
            "Tidehunter": -0.817
        },
        {
            "name": "Dark Seer",
            "Tidehunter": 1.758
        },
        {
            "name": "Dark Willow",
            "Tidehunter": 0.317
        },
        {
            "name": "Dawnbreaker",
            "Tidehunter": 0.825
        },
        {
            "name": "Dazzle",
            "Tidehunter": -2.185
        },
        {
            "name": "Death Prophet",
            "Tidehunter": 3.693
        },
        {
            "name": "Disruptor",
            "Tidehunter": 0.612
        },
        {
            "name": "Doom",
            "Tidehunter": 1.133
        },
        {
            "name": "Dragon Knight",
            "Tidehunter": -0.245
        },
        {
            "name": "Drow Ranger",
            "Tidehunter": -0.226
        },
        {
            "name": "Earth Spirit",
            "Tidehunter": -2.323
        },
        {
            "name": "Earthshaker",
            "Tidehunter": -0.275
        },
        {
            "name": "Elder Titan",
            "Tidehunter": 2.97
        },
        {
            "name": "Ember Spirit",
            "Tidehunter": -1.291
        },
        {
            "name": "Enchantress",
            "Tidehunter": 1.449
        },
        {
            "name": "Enigma",
            "Tidehunter": 0.51
        },
        {
            "name": "Faceless Void",
            "Tidehunter": -1.722
        },
        {
            "name": "Grimstroke",
            "Tidehunter": 1.318
        },
        {
            "name": "Gyrocopter",
            "Tidehunter": 0.073
        },
        {
            "name": "Hoodwink",
            "Tidehunter": 0.475
        },
        {
            "name": "Huskar",
            "Tidehunter": 2.682
        },
        {
            "name": "Invoker",
            "Tidehunter": -0.176
        },
        {
            "name": "Io",
            "Tidehunter": 0.555
        },
        {
            "name": "Jakiro",
            "Tidehunter": -0.988
        },
        {
            "name": "Juggernaut",
            "Tidehunter": 3.328
        },
        {
            "name": "Keeper of the Light",
            "Tidehunter": 0.361
        },
        {
            "name": "Kez",
            "Tidehunter": -0.584
        },
        {
            "name": "Kunkka",
            "Tidehunter": 0.593
        },
        {
            "name": "Legion Commander",
            "Tidehunter": -0.071
        },
        {
            "name": "Leshrac",
            "Tidehunter": 2.875
        },
        {
            "name": "Lich",
            "Tidehunter": 0.912
        },
        {
            "name": "Lifestealer",
            "Tidehunter": 0.67
        },
        {
            "name": "Lina",
            "Tidehunter": -1.222
        },
        {
            "name": "Lion",
            "Tidehunter": -1.34
        },
        {
            "name": "Lone Druid",
            "Tidehunter": -0.173
        },
        {
            "name": "Luna",
            "Tidehunter": 0.544
        },
        {
            "name": "Lycan",
            "Tidehunter": -0.285
        },
        {
            "name": "Magnus",
            "Tidehunter": -0.874
        },
        {
            "name": "Marci",
            "Tidehunter": -0.681
        },
        {
            "name": "Mars",
            "Tidehunter": 0.267
        },
        {
            "name": "Medusa",
            "Tidehunter": -2.503
        },
        {
            "name": "Meepo",
            "Tidehunter": 1.073
        },
        {
            "name": "Mirana",
            "Tidehunter": 0.589
        },
        {
            "name": "Monkey King",
            "Tidehunter": 1.919
        },
        {
            "name": "Morphling",
            "Tidehunter": -1.093
        },
        {
            "name": "Muerta",
            "Tidehunter": 1.877
        },
        {
            "name": "Naga Siren",
            "Tidehunter": -1.175
        },
        {
            "name": "Nature's Prophet",
            "Tidehunter": 1.518
        },
        {
            "name": "Necrophos",
            "Tidehunter": 1.804
        },
        {
            "name": "Night Stalker",
            "Tidehunter": 0.361
        },
        {
            "name": "Nyx Assassin",
            "Tidehunter": -0.046
        },
        {
            "name": "Ogre Magi",
            "Tidehunter": -1.411
        },
        {
            "name": "Omniknight",
            "Tidehunter": 3.37
        },
        {
            "name": "Oracle",
            "Tidehunter": -0.306
        },
        {
            "name": "Outworld Destroyer",
            "Tidehunter": 2.529
        },
        {
            "name": "Pangolier",
            "Tidehunter": 2.622
        },
        {
            "name": "Phantom Assassin",
            "Tidehunter": -1.546
        },
        {
            "name": "Phantom Lancer",
            "Tidehunter": -3.079
        },
        {
            "name": "Phoenix",
            "Tidehunter": -1.674
        },
        {
            "name": "Primal Beast",
            "Tidehunter": 0.322
        },
        {
            "name": "Puck",
            "Tidehunter": -0.792
        },
        {
            "name": "Pudge",
            "Tidehunter": -0.369
        },
        {
            "name": "Pugna",
            "Tidehunter": 2.942
        },
        {
            "name": "Queen of Pain",
            "Tidehunter": 0.023
        },
        {
            "name": "Razor",
            "Tidehunter": 3.619
        },
        {
            "name": "Riki",
            "Tidehunter": -0.611
        },
        {
            "name": "Ringmaster",
            "Tidehunter": 0.364
        },
        {
            "name": "Rubick",
            "Tidehunter": 0.051
        },
        {
            "name": "Sand King",
            "Tidehunter": -0.013
        },
        {
            "name": "Shadow Demon",
            "Tidehunter": -0.832
        },
        {
            "name": "Shadow Fiend",
            "Tidehunter": 0.968
        },
        {
            "name": "Shadow Shaman",
            "Tidehunter": -0.712
        },
        {
            "name": "Silencer",
            "Tidehunter": -2.336
        },
        {
            "name": "Skywrath Mage",
            "Tidehunter": -1.453
        },
        {
            "name": "Slardar",
            "Tidehunter": -2.124
        },
        {
            "name": "Slark",
            "Tidehunter": 2.255
        },
        {
            "name": "Snapfire",
            "Tidehunter": 1.953
        },
        {
            "name": "Sniper",
            "Tidehunter": 0.955
        },
        {
            "name": "Spectre",
            "Tidehunter": -2.91
        },
        {
            "name": "Spirit Breaker",
            "Tidehunter": -1.642
        },
        {
            "name": "Storm Spirit",
            "Tidehunter": -2.02
        },
        {
            "name": "Sven",
            "Tidehunter": 2.089
        },
        {
            "name": "Techies",
            "Tidehunter": -0.626
        },
        {
            "name": "Templar Assassin",
            "Tidehunter": -1.019
        },
        {
            "name": "Terrorblade",
            "Tidehunter": 0.085
        },
        {
            "name": "Timbersaw",
            "Tidehunter": 1.912
        },
        {
            "name": "Tinker",
            "Tidehunter": 2.302
        },
        {
            "name": "Tiny",
            "Tidehunter": 1.2
        },
        {
            "name": "Treant Protector",
            "Tidehunter": 0.133
        },
        {
            "name": "Troll Warlord",
            "Tidehunter": -0.445
        },
        {
            "name": "Tusk",
            "Tidehunter": 1.297
        },
        {
            "name": "Underlord",
            "Tidehunter": 0.912
        },
        {
            "name": "Undying",
            "Tidehunter": 1.172
        },
        {
            "name": "Ursa",
            "Tidehunter": 2.364
        },
        {
            "name": "Vengeful Spirit",
            "Tidehunter": -1.294
        },
        {
            "name": "Venomancer",
            "Tidehunter": 1.131
        },
        {
            "name": "Viper",
            "Tidehunter": 0.801
        },
        {
            "name": "Visage",
            "Tidehunter": -0.53
        },
        {
            "name": "Void Spirit",
            "Tidehunter": -1.24
        },
        {
            "name": "Warlock",
            "Tidehunter": -2.447
        },
        {
            "name": "Weaver",
            "Tidehunter": 1.583
        },
        {
            "name": "Windranger",
            "Tidehunter": 0.303
        },
        {
            "name": "Winter Wyvern",
            "Tidehunter": -1.14
        },
        {
            "name": "Witch Doctor",
            "Tidehunter": 2.037
        },
        {
            "name": "Wraith King",
            "Tidehunter": -3.09
        },
        {
            "name": "Zeus",
            "Tidehunter": 0.353
        }
    ],
    "Tiny": [
        {
            "name": "Abaddon",
            "Tiny": -0.825
        },
        {
            "name": "Alchemist",
            "Tiny": 1.493
        },
        {
            "name": "Ancient Apparition",
            "Tiny": 0.239
        },
        {
            "name": "Anti-Mage",
            "Tiny": -0.796
        },
        {
            "name": "Arc Warden",
            "Tiny": -0.794
        },
        {
            "name": "Axe",
            "Tiny": -2.021
        },
        {
            "name": "Bane",
            "Tiny": 0.227
        },
        {
            "name": "Batrider",
            "Tiny": -0.287
        },
        {
            "name": "Beastmaster",
            "Tiny": -0.033
        },
        {
            "name": "Bloodseeker",
            "Tiny": 1.806
        },
        {
            "name": "Bounty Hunter",
            "Tiny": 0.198
        },
        {
            "name": "Brewmaster",
            "Tiny": 2.167
        },
        {
            "name": "Bristleback",
            "Tiny": 0.335
        },
        {
            "name": "Broodmother",
            "Tiny": -2.454
        },
        {
            "name": "Centaur Warrunner",
            "Tiny": -0.06
        },
        {
            "name": "Chaos Knight",
            "Tiny": -0.81
        },
        {
            "name": "Chen",
            "Tiny": -0.011
        },
        {
            "name": "Clinkz",
            "Tiny": -0.735
        },
        {
            "name": "Clockwerk",
            "Tiny": 0.501
        },
        {
            "name": "Crystal Maiden",
            "Tiny": 0.427
        },
        {
            "name": "Dark Seer",
            "Tiny": -0.222
        },
        {
            "name": "Dark Willow",
            "Tiny": -0.048
        },
        {
            "name": "Dawnbreaker",
            "Tiny": 0.415
        },
        {
            "name": "Dazzle",
            "Tiny": 0.106
        },
        {
            "name": "Death Prophet",
            "Tiny": 0.995
        },
        {
            "name": "Disruptor",
            "Tiny": -0.1
        },
        {
            "name": "Doom",
            "Tiny": 1.019
        },
        {
            "name": "Dragon Knight",
            "Tiny": 0.12
        },
        {
            "name": "Drow Ranger",
            "Tiny": -2.092
        },
        {
            "name": "Earth Spirit",
            "Tiny": 0.754
        },
        {
            "name": "Earthshaker",
            "Tiny": -0.515
        },
        {
            "name": "Elder Titan",
            "Tiny": -1.41
        },
        {
            "name": "Ember Spirit",
            "Tiny": 0.583
        },
        {
            "name": "Enchantress",
            "Tiny": 1.189
        },
        {
            "name": "Enigma",
            "Tiny": 0.481
        },
        {
            "name": "Faceless Void",
            "Tiny": 0.258
        },
        {
            "name": "Grimstroke",
            "Tiny": 0.429
        },
        {
            "name": "Gyrocopter",
            "Tiny": 0.999
        },
        {
            "name": "Hoodwink",
            "Tiny": -1.232
        },
        {
            "name": "Huskar",
            "Tiny": 3.006
        },
        {
            "name": "Invoker",
            "Tiny": -0.043
        },
        {
            "name": "Io",
            "Tiny": -1.182
        },
        {
            "name": "Jakiro",
            "Tiny": 0.252
        },
        {
            "name": "Juggernaut",
            "Tiny": 0.866
        },
        {
            "name": "Keeper of the Light",
            "Tiny": 1.027
        },
        {
            "name": "Kez",
            "Tiny": 1.006
        },
        {
            "name": "Kunkka",
            "Tiny": 0.539
        },
        {
            "name": "Legion Commander",
            "Tiny": -1.731
        },
        {
            "name": "Leshrac",
            "Tiny": 1.279
        },
        {
            "name": "Lich",
            "Tiny": 0.509
        },
        {
            "name": "Lifestealer",
            "Tiny": 2.727
        },
        {
            "name": "Lina",
            "Tiny": -0.51
        },
        {
            "name": "Lion",
            "Tiny": -0.929
        },
        {
            "name": "Lone Druid",
            "Tiny": -0.848
        },
        {
            "name": "Luna",
            "Tiny": 0.302
        },
        {
            "name": "Lycan",
            "Tiny": -1.667
        },
        {
            "name": "Magnus",
            "Tiny": 0.041
        },
        {
            "name": "Marci",
            "Tiny": -0.835
        },
        {
            "name": "Mars",
            "Tiny": 0.203
        },
        {
            "name": "Medusa",
            "Tiny": -1.206
        },
        {
            "name": "Meepo",
            "Tiny": -0.857
        },
        {
            "name": "Mirana",
            "Tiny": -0.634
        },
        {
            "name": "Monkey King",
            "Tiny": 1.303
        },
        {
            "name": "Morphling",
            "Tiny": -1.513
        },
        {
            "name": "Muerta",
            "Tiny": -0.416
        },
        {
            "name": "Naga Siren",
            "Tiny": -2.725
        },
        {
            "name": "Nature's Prophet",
            "Tiny": -0.077
        },
        {
            "name": "Necrophos",
            "Tiny": 3.987
        },
        {
            "name": "Night Stalker",
            "Tiny": -0.591
        },
        {
            "name": "Nyx Assassin",
            "Tiny": -1.006
        },
        {
            "name": "Ogre Magi",
            "Tiny": 1.19
        },
        {
            "name": "Omniknight",
            "Tiny": -0.533
        },
        {
            "name": "Oracle",
            "Tiny": -0.275
        },
        {
            "name": "Outworld Destroyer",
            "Tiny": -0.147
        },
        {
            "name": "Pangolier",
            "Tiny": 0.411
        },
        {
            "name": "Phantom Assassin",
            "Tiny": -1.688
        },
        {
            "name": "Phantom Lancer",
            "Tiny": -0.785
        },
        {
            "name": "Phoenix",
            "Tiny": 1.679
        },
        {
            "name": "Primal Beast",
            "Tiny": -0.454
        },
        {
            "name": "Puck",
            "Tiny": -2.16
        },
        {
            "name": "Pudge",
            "Tiny": -0.509
        },
        {
            "name": "Pugna",
            "Tiny": 0.042
        },
        {
            "name": "Queen of Pain",
            "Tiny": 0.604
        },
        {
            "name": "Razor",
            "Tiny": 0.694
        },
        {
            "name": "Riki",
            "Tiny": -1.854
        },
        {
            "name": "Ringmaster",
            "Tiny": 0.827
        },
        {
            "name": "Rubick",
            "Tiny": 0.07
        },
        {
            "name": "Sand King",
            "Tiny": 1.362
        },
        {
            "name": "Shadow Demon",
            "Tiny": -0.632
        },
        {
            "name": "Shadow Fiend",
            "Tiny": -0.972
        },
        {
            "name": "Shadow Shaman",
            "Tiny": -0.772
        },
        {
            "name": "Silencer",
            "Tiny": 0.328
        },
        {
            "name": "Skywrath Mage",
            "Tiny": -0.159
        },
        {
            "name": "Slardar",
            "Tiny": -0.135
        },
        {
            "name": "Slark",
            "Tiny": -1.27
        },
        {
            "name": "Snapfire",
            "Tiny": 0.179
        },
        {
            "name": "Sniper",
            "Tiny": -0.596
        },
        {
            "name": "Spectre",
            "Tiny": 0.23
        },
        {
            "name": "Spirit Breaker",
            "Tiny": -2.207
        },
        {
            "name": "Storm Spirit",
            "Tiny": -0.619
        },
        {
            "name": "Sven",
            "Tiny": 0.679
        },
        {
            "name": "Techies",
            "Tiny": 0.138
        },
        {
            "name": "Templar Assassin",
            "Tiny": -0.254
        },
        {
            "name": "Terrorblade",
            "Tiny": 0.794
        },
        {
            "name": "Tidehunter",
            "Tiny": -1.171
        },
        {
            "name": "Timbersaw",
            "Tiny": 2.318
        },
        {
            "name": "Tinker",
            "Tiny": -0.79
        },
        {
            "name": "Treant Protector",
            "Tiny": 0.955
        },
        {
            "name": "Troll Warlord",
            "Tiny": -0.363
        },
        {
            "name": "Tusk",
            "Tiny": -0.119
        },
        {
            "name": "Underlord",
            "Tiny": 1.874
        },
        {
            "name": "Undying",
            "Tiny": 2.121
        },
        {
            "name": "Ursa",
            "Tiny": -0.321
        },
        {
            "name": "Vengeful Spirit",
            "Tiny": -0.576
        },
        {
            "name": "Venomancer",
            "Tiny": 1.764
        },
        {
            "name": "Viper",
            "Tiny": 2.145
        },
        {
            "name": "Visage",
            "Tiny": -0.63
        },
        {
            "name": "Void Spirit",
            "Tiny": -1.233
        },
        {
            "name": "Warlock",
            "Tiny": 0.454
        },
        {
            "name": "Weaver",
            "Tiny": -0.075
        },
        {
            "name": "Windranger",
            "Tiny": -1.18
        },
        {
            "name": "Winter Wyvern",
            "Tiny": 0.36
        },
        {
            "name": "Witch Doctor",
            "Tiny": 0.77
        },
        {
            "name": "Wraith King",
            "Tiny": 0.973
        },
        {
            "name": "Zeus",
            "Tiny": 0.169
        }
    ],
    "Treant Protector": [
        {
            "name": "Abaddon",
            "Treant Protector": -0.113
        },
        {
            "name": "Alchemist",
            "Treant Protector": 0.11
        },
        {
            "name": "Ancient Apparition",
            "Treant Protector": 1.255
        },
        {
            "name": "Anti-Mage",
            "Treant Protector": -0.326
        },
        {
            "name": "Arc Warden",
            "Treant Protector": -0.13
        },
        {
            "name": "Axe",
            "Treant Protector": -0.927
        },
        {
            "name": "Bane",
            "Treant Protector": -1.686
        },
        {
            "name": "Batrider",
            "Treant Protector": 0.573
        },
        {
            "name": "Beastmaster",
            "Treant Protector": -1.872
        },
        {
            "name": "Bloodseeker",
            "Treant Protector": -0.346
        },
        {
            "name": "Bounty Hunter",
            "Treant Protector": -1.299
        },
        {
            "name": "Brewmaster",
            "Treant Protector": 1.189
        },
        {
            "name": "Bristleback",
            "Treant Protector": 0.248
        },
        {
            "name": "Broodmother",
            "Treant Protector": 0.165
        },
        {
            "name": "Centaur Warrunner",
            "Treant Protector": -0.705
        },
        {
            "name": "Chaos Knight",
            "Treant Protector": -1.376
        },
        {
            "name": "Chen",
            "Treant Protector": -0.027
        },
        {
            "name": "Clinkz",
            "Treant Protector": -0.027
        },
        {
            "name": "Clockwerk",
            "Treant Protector": 0.331
        },
        {
            "name": "Crystal Maiden",
            "Treant Protector": 0.497
        },
        {
            "name": "Dark Seer",
            "Treant Protector": 1.508
        },
        {
            "name": "Dark Willow",
            "Treant Protector": 0.402
        },
        {
            "name": "Dawnbreaker",
            "Treant Protector": 1.366
        },
        {
            "name": "Dazzle",
            "Treant Protector": -0.859
        },
        {
            "name": "Death Prophet",
            "Treant Protector": 0.598
        },
        {
            "name": "Disruptor",
            "Treant Protector": 0.052
        },
        {
            "name": "Doom",
            "Treant Protector": 1.744
        },
        {
            "name": "Dragon Knight",
            "Treant Protector": -1.83
        },
        {
            "name": "Drow Ranger",
            "Treant Protector": -0.224
        },
        {
            "name": "Earth Spirit",
            "Treant Protector": 0.346
        },
        {
            "name": "Earthshaker",
            "Treant Protector": -0.12
        },
        {
            "name": "Elder Titan",
            "Treant Protector": -1.93
        },
        {
            "name": "Ember Spirit",
            "Treant Protector": -1.203
        },
        {
            "name": "Enchantress",
            "Treant Protector": 0.339
        },
        {
            "name": "Enigma",
            "Treant Protector": -0.764
        },
        {
            "name": "Faceless Void",
            "Treant Protector": 0.141
        },
        {
            "name": "Grimstroke",
            "Treant Protector": 0.446
        },
        {
            "name": "Gyrocopter",
            "Treant Protector": -0.733
        },
        {
            "name": "Hoodwink",
            "Treant Protector": -0.393
        },
        {
            "name": "Huskar",
            "Treant Protector": 0.634
        },
        {
            "name": "Invoker",
            "Treant Protector": 0.073
        },
        {
            "name": "Io",
            "Treant Protector": -1.052
        },
        {
            "name": "Jakiro",
            "Treant Protector": 1.24
        },
        {
            "name": "Juggernaut",
            "Treant Protector": 2.355
        },
        {
            "name": "Keeper of the Light",
            "Treant Protector": 0.337
        },
        {
            "name": "Kez",
            "Treant Protector": 0.036
        },
        {
            "name": "Kunkka",
            "Treant Protector": -0.842
        },
        {
            "name": "Legion Commander",
            "Treant Protector": -0.878
        },
        {
            "name": "Leshrac",
            "Treant Protector": 2.517
        },
        {
            "name": "Lich",
            "Treant Protector": 0.612
        },
        {
            "name": "Lifestealer",
            "Treant Protector": -0.066
        },
        {
            "name": "Lina",
            "Treant Protector": 0.139
        },
        {
            "name": "Lion",
            "Treant Protector": -0.532
        },
        {
            "name": "Lone Druid",
            "Treant Protector": -1.589
        },
        {
            "name": "Luna",
            "Treant Protector": 0.175
        },
        {
            "name": "Lycan",
            "Treant Protector": -0.389
        },
        {
            "name": "Magnus",
            "Treant Protector": 0.311
        },
        {
            "name": "Marci",
            "Treant Protector": -0.678
        },
        {
            "name": "Mars",
            "Treant Protector": 0.698
        },
        {
            "name": "Medusa",
            "Treant Protector": -1.896
        },
        {
            "name": "Meepo",
            "Treant Protector": -2.593
        },
        {
            "name": "Mirana",
            "Treant Protector": -0.521
        },
        {
            "name": "Monkey King",
            "Treant Protector": -0.085
        },
        {
            "name": "Morphling",
            "Treant Protector": 1.047
        },
        {
            "name": "Muerta",
            "Treant Protector": 1.767
        },
        {
            "name": "Naga Siren",
            "Treant Protector": -0.502
        },
        {
            "name": "Nature's Prophet",
            "Treant Protector": -2.183
        },
        {
            "name": "Necrophos",
            "Treant Protector": 1.589
        },
        {
            "name": "Night Stalker",
            "Treant Protector": -1.459
        },
        {
            "name": "Nyx Assassin",
            "Treant Protector": -0.472
        },
        {
            "name": "Ogre Magi",
            "Treant Protector": -0.33
        },
        {
            "name": "Omniknight",
            "Treant Protector": -1.158
        },
        {
            "name": "Oracle",
            "Treant Protector": -0.348
        },
        {
            "name": "Outworld Destroyer",
            "Treant Protector": 0.502
        },
        {
            "name": "Pangolier",
            "Treant Protector": -1.095
        },
        {
            "name": "Phantom Assassin",
            "Treant Protector": -0.618
        },
        {
            "name": "Phantom Lancer",
            "Treant Protector": -1.082
        },
        {
            "name": "Phoenix",
            "Treant Protector": 2.391
        },
        {
            "name": "Primal Beast",
            "Treant Protector": 0.938
        },
        {
            "name": "Puck",
            "Treant Protector": -0.152
        },
        {
            "name": "Pudge",
            "Treant Protector": 0.905
        },
        {
            "name": "Pugna",
            "Treant Protector": 0.505
        },
        {
            "name": "Queen of Pain",
            "Treant Protector": 0.448
        },
        {
            "name": "Razor",
            "Treant Protector": -0.132
        },
        {
            "name": "Riki",
            "Treant Protector": -1.852
        },
        {
            "name": "Ringmaster",
            "Treant Protector": 1.023
        },
        {
            "name": "Rubick",
            "Treant Protector": 0.248
        },
        {
            "name": "Sand King",
            "Treant Protector": 0.636
        },
        {
            "name": "Shadow Demon",
            "Treant Protector": -0.412
        },
        {
            "name": "Shadow Fiend",
            "Treant Protector": -0.515
        },
        {
            "name": "Shadow Shaman",
            "Treant Protector": -0.904
        },
        {
            "name": "Silencer",
            "Treant Protector": -0.138
        },
        {
            "name": "Skywrath Mage",
            "Treant Protector": 0.53
        },
        {
            "name": "Slardar",
            "Treant Protector": -0.26
        },
        {
            "name": "Slark",
            "Treant Protector": 1.996
        },
        {
            "name": "Snapfire",
            "Treant Protector": 0.163
        },
        {
            "name": "Sniper",
            "Treant Protector": -0.079
        },
        {
            "name": "Spectre",
            "Treant Protector": -0.544
        },
        {
            "name": "Spirit Breaker",
            "Treant Protector": -0.777
        },
        {
            "name": "Storm Spirit",
            "Treant Protector": -0.627
        },
        {
            "name": "Sven",
            "Treant Protector": -0.79
        },
        {
            "name": "Techies",
            "Treant Protector": 0.476
        },
        {
            "name": "Templar Assassin",
            "Treant Protector": -1.963
        },
        {
            "name": "Terrorblade",
            "Treant Protector": 0.307
        },
        {
            "name": "Tidehunter",
            "Treant Protector": -0.028
        },
        {
            "name": "Timbersaw",
            "Treant Protector": 2.167
        },
        {
            "name": "Tinker",
            "Treant Protector": 0.543
        },
        {
            "name": "Tiny",
            "Treant Protector": -1.018
        },
        {
            "name": "Troll Warlord",
            "Treant Protector": 0.415
        },
        {
            "name": "Tusk",
            "Treant Protector": -1.406
        },
        {
            "name": "Underlord",
            "Treant Protector": 0.579
        },
        {
            "name": "Undying",
            "Treant Protector": 0.874
        },
        {
            "name": "Ursa",
            "Treant Protector": -0.081
        },
        {
            "name": "Vengeful Spirit",
            "Treant Protector": 0.174
        },
        {
            "name": "Venomancer",
            "Treant Protector": 0.064
        },
        {
            "name": "Viper",
            "Treant Protector": 1.922
        },
        {
            "name": "Visage",
            "Treant Protector": -1.663
        },
        {
            "name": "Void Spirit",
            "Treant Protector": -0.204
        },
        {
            "name": "Warlock",
            "Treant Protector": 0.531
        },
        {
            "name": "Weaver",
            "Treant Protector": 0.437
        },
        {
            "name": "Windranger",
            "Treant Protector": -0.228
        },
        {
            "name": "Winter Wyvern",
            "Treant Protector": -0.761
        },
        {
            "name": "Witch Doctor",
            "Treant Protector": 0.266
        },
        {
            "name": "Wraith King",
            "Treant Protector": -1.205
        },
        {
            "name": "Zeus",
            "Treant Protector": 0.29
        }
    ],
    "Tusk": [
        {
            "name": "Abaddon",
            "Tusk": -2.515
        },
        {
            "name": "Alchemist",
            "Tusk": -0.221
        },
        {
            "name": "Ancient Apparition",
            "Tusk": -0.164
        },
        {
            "name": "Anti-Mage",
            "Tusk": -0.407
        },
        {
            "name": "Arc Warden",
            "Tusk": -1.275
        },
        {
            "name": "Axe",
            "Tusk": 0.48
        },
        {
            "name": "Bane",
            "Tusk": 0.374
        },
        {
            "name": "Batrider",
            "Tusk": 0.174
        },
        {
            "name": "Beastmaster",
            "Tusk": -0.255
        },
        {
            "name": "Bloodseeker",
            "Tusk": -0.98
        },
        {
            "name": "Bounty Hunter",
            "Tusk": -0.592
        },
        {
            "name": "Brewmaster",
            "Tusk": -1.613
        },
        {
            "name": "Bristleback",
            "Tusk": -0.29
        },
        {
            "name": "Broodmother",
            "Tusk": -0.967
        },
        {
            "name": "Centaur Warrunner",
            "Tusk": -0.101
        },
        {
            "name": "Chaos Knight",
            "Tusk": -0.361
        },
        {
            "name": "Chen",
            "Tusk": 0.362
        },
        {
            "name": "Clinkz",
            "Tusk": -0.382
        },
        {
            "name": "Clockwerk",
            "Tusk": -0.675
        },
        {
            "name": "Crystal Maiden",
            "Tusk": 0.601
        },
        {
            "name": "Dark Seer",
            "Tusk": 0.023
        },
        {
            "name": "Dark Willow",
            "Tusk": 1.595
        },
        {
            "name": "Dawnbreaker",
            "Tusk": 1.365
        },
        {
            "name": "Dazzle",
            "Tusk": -0.503
        },
        {
            "name": "Death Prophet",
            "Tusk": 0.297
        },
        {
            "name": "Disruptor",
            "Tusk": 0.094
        },
        {
            "name": "Doom",
            "Tusk": -0.421
        },
        {
            "name": "Dragon Knight",
            "Tusk": 0.315
        },
        {
            "name": "Drow Ranger",
            "Tusk": 0.107
        },
        {
            "name": "Earth Spirit",
            "Tusk": -0.698
        },
        {
            "name": "Earthshaker",
            "Tusk": 1.251
        },
        {
            "name": "Elder Titan",
            "Tusk": 0.662
        },
        {
            "name": "Ember Spirit",
            "Tusk": 1.965
        },
        {
            "name": "Enchantress",
            "Tusk": -0.232
        },
        {
            "name": "Enigma",
            "Tusk": 1.144
        },
        {
            "name": "Faceless Void",
            "Tusk": 1.472
        },
        {
            "name": "Grimstroke",
            "Tusk": 0.415
        },
        {
            "name": "Gyrocopter",
            "Tusk": -0.295
        },
        {
            "name": "Hoodwink",
            "Tusk": 0.259
        },
        {
            "name": "Huskar",
            "Tusk": -0.121
        },
        {
            "name": "Invoker",
            "Tusk": -1.264
        },
        {
            "name": "Io",
            "Tusk": -0.349
        },
        {
            "name": "Jakiro",
            "Tusk": 0.825
        },
        {
            "name": "Juggernaut",
            "Tusk": -0.451
        },
        {
            "name": "Keeper of the Light",
            "Tusk": -0.184
        },
        {
            "name": "Kez",
            "Tusk": -0.139
        },
        {
            "name": "Kunkka",
            "Tusk": -0.297
        },
        {
            "name": "Legion Commander",
            "Tusk": -2.177
        },
        {
            "name": "Leshrac",
            "Tusk": 0.857
        },
        {
            "name": "Lich",
            "Tusk": 1.004
        },
        {
            "name": "Lifestealer",
            "Tusk": -1.902
        },
        {
            "name": "Lina",
            "Tusk": 0.012
        },
        {
            "name": "Lion",
            "Tusk": 0.571
        },
        {
            "name": "Lone Druid",
            "Tusk": -1.911
        },
        {
            "name": "Luna",
            "Tusk": 1.391
        },
        {
            "name": "Lycan",
            "Tusk": -3.512
        },
        {
            "name": "Magnus",
            "Tusk": 0.431
        },
        {
            "name": "Marci",
            "Tusk": -0.17
        },
        {
            "name": "Mars",
            "Tusk": 0.017
        },
        {
            "name": "Medusa",
            "Tusk": -0.157
        },
        {
            "name": "Meepo",
            "Tusk": -0.079
        },
        {
            "name": "Mirana",
            "Tusk": 0.16
        },
        {
            "name": "Monkey King",
            "Tusk": 1.932
        },
        {
            "name": "Morphling",
            "Tusk": 1.403
        },
        {
            "name": "Muerta",
            "Tusk": 0.892
        },
        {
            "name": "Naga Siren",
            "Tusk": 0.083
        },
        {
            "name": "Nature's Prophet",
            "Tusk": 0.387
        },
        {
            "name": "Necrophos",
            "Tusk": 0.855
        },
        {
            "name": "Night Stalker",
            "Tusk": -0.491
        },
        {
            "name": "Nyx Assassin",
            "Tusk": -0.928
        },
        {
            "name": "Ogre Magi",
            "Tusk": -0.379
        },
        {
            "name": "Omniknight",
            "Tusk": 0.071
        },
        {
            "name": "Oracle",
            "Tusk": -2.478
        },
        {
            "name": "Outworld Destroyer",
            "Tusk": -0.911
        },
        {
            "name": "Pangolier",
            "Tusk": -0.312
        },
        {
            "name": "Phantom Assassin",
            "Tusk": -1.237
        },
        {
            "name": "Phantom Lancer",
            "Tusk": -0.723
        },
        {
            "name": "Phoenix",
            "Tusk": 1.395
        },
        {
            "name": "Primal Beast",
            "Tusk": 1.027
        },
        {
            "name": "Puck",
            "Tusk": 1.041
        },
        {
            "name": "Pudge",
            "Tusk": -1.315
        },
        {
            "name": "Pugna",
            "Tusk": 1.022
        },
        {
            "name": "Queen of Pain",
            "Tusk": 0.234
        },
        {
            "name": "Razor",
            "Tusk": -0.834
        },
        {
            "name": "Riki",
            "Tusk": 0.719
        },
        {
            "name": "Ringmaster",
            "Tusk": 0.122
        },
        {
            "name": "Rubick",
            "Tusk": -1.508
        },
        {
            "name": "Sand King",
            "Tusk": 1.772
        },
        {
            "name": "Shadow Demon",
            "Tusk": -0.837
        },
        {
            "name": "Shadow Fiend",
            "Tusk": -0.322
        },
        {
            "name": "Shadow Shaman",
            "Tusk": 0.366
        },
        {
            "name": "Silencer",
            "Tusk": -0.775
        },
        {
            "name": "Skywrath Mage",
            "Tusk": -1.507
        },
        {
            "name": "Slardar",
            "Tusk": 0.166
        },
        {
            "name": "Slark",
            "Tusk": -0.612
        },
        {
            "name": "Snapfire",
            "Tusk": -0.077
        },
        {
            "name": "Sniper",
            "Tusk": -0.026
        },
        {
            "name": "Spectre",
            "Tusk": 0.593
        },
        {
            "name": "Spirit Breaker",
            "Tusk": -1.247
        },
        {
            "name": "Storm Spirit",
            "Tusk": 0.262
        },
        {
            "name": "Sven",
            "Tusk": 0.79
        },
        {
            "name": "Techies",
            "Tusk": 0.951
        },
        {
            "name": "Templar Assassin",
            "Tusk": 1.212
        },
        {
            "name": "Terrorblade",
            "Tusk": 1.162
        },
        {
            "name": "Tidehunter",
            "Tusk": -1.257
        },
        {
            "name": "Timbersaw",
            "Tusk": 1.705
        },
        {
            "name": "Tinker",
            "Tusk": -0.324
        },
        {
            "name": "Tiny",
            "Tusk": 0.174
        },
        {
            "name": "Treant Protector",
            "Tusk": 1.428
        },
        {
            "name": "Troll Warlord",
            "Tusk": -0.517
        },
        {
            "name": "Underlord",
            "Tusk": 0.589
        },
        {
            "name": "Undying",
            "Tusk": 1.347
        },
        {
            "name": "Ursa",
            "Tusk": -0.515
        },
        {
            "name": "Vengeful Spirit",
            "Tusk": 0.338
        },
        {
            "name": "Venomancer",
            "Tusk": 0.35
        },
        {
            "name": "Viper",
            "Tusk": -0.293
        },
        {
            "name": "Visage",
            "Tusk": -0.478
        },
        {
            "name": "Void Spirit",
            "Tusk": -0.62
        },
        {
            "name": "Warlock",
            "Tusk": -1.277
        },
        {
            "name": "Weaver",
            "Tusk": 1.442
        },
        {
            "name": "Windranger",
            "Tusk": -0.236
        },
        {
            "name": "Winter Wyvern",
            "Tusk": 2.843
        },
        {
            "name": "Witch Doctor",
            "Tusk": -0.023
        },
        {
            "name": "Wraith King",
            "Tusk": -0.265
        },
        {
            "name": "Zeus",
            "Tusk": 0.192
        }
    ],
    "Underlord": [
        {
            "name": "Abaddon",
            "Underlord": -0.49
        },
        {
            "name": "Alchemist",
            "Underlord": 1.087
        },
        {
            "name": "Ancient Apparition",
            "Underlord": 2.885
        },
        {
            "name": "Anti-Mage",
            "Underlord": 1.852
        },
        {
            "name": "Arc Warden",
            "Underlord": -1.477
        },
        {
            "name": "Axe",
            "Underlord": -0.758
        },
        {
            "name": "Bane",
            "Underlord": -1.188
        },
        {
            "name": "Batrider",
            "Underlord": 0.345
        },
        {
            "name": "Beastmaster",
            "Underlord": -2.524
        },
        {
            "name": "Bloodseeker",
            "Underlord": 0.3
        },
        {
            "name": "Bounty Hunter",
            "Underlord": -0.663
        },
        {
            "name": "Brewmaster",
            "Underlord": 1.062
        },
        {
            "name": "Bristleback",
            "Underlord": 1.382
        },
        {
            "name": "Broodmother",
            "Underlord": 0.868
        },
        {
            "name": "Centaur Warrunner",
            "Underlord": -1.922
        },
        {
            "name": "Chaos Knight",
            "Underlord": -1.525
        },
        {
            "name": "Chen",
            "Underlord": 1.53
        },
        {
            "name": "Clinkz",
            "Underlord": 0.255
        },
        {
            "name": "Clockwerk",
            "Underlord": -1.289
        },
        {
            "name": "Crystal Maiden",
            "Underlord": 1.003
        },
        {
            "name": "Dark Seer",
            "Underlord": -0.872
        },
        {
            "name": "Dark Willow",
            "Underlord": -1.069
        },
        {
            "name": "Dawnbreaker",
            "Underlord": -0.78
        },
        {
            "name": "Dazzle",
            "Underlord": -0.445
        },
        {
            "name": "Death Prophet",
            "Underlord": 0.283
        },
        {
            "name": "Disruptor",
            "Underlord": -1.021
        },
        {
            "name": "Doom",
            "Underlord": 1.546
        },
        {
            "name": "Dragon Knight",
            "Underlord": -1.34
        },
        {
            "name": "Drow Ranger",
            "Underlord": -0.701
        },
        {
            "name": "Earth Spirit",
            "Underlord": 1.024
        },
        {
            "name": "Earthshaker",
            "Underlord": -0.409
        },
        {
            "name": "Elder Titan",
            "Underlord": 3.911
        },
        {
            "name": "Ember Spirit",
            "Underlord": -1.071
        },
        {
            "name": "Enchantress",
            "Underlord": 2.964
        },
        {
            "name": "Enigma",
            "Underlord": 1.77
        },
        {
            "name": "Faceless Void",
            "Underlord": 1.205
        },
        {
            "name": "Grimstroke",
            "Underlord": -0.486
        },
        {
            "name": "Gyrocopter",
            "Underlord": -0.642
        },
        {
            "name": "Hoodwink",
            "Underlord": 0.398
        },
        {
            "name": "Huskar",
            "Underlord": 1.984
        },
        {
            "name": "Invoker",
            "Underlord": 0.718
        },
        {
            "name": "Io",
            "Underlord": -2.526
        },
        {
            "name": "Jakiro",
            "Underlord": -0.191
        },
        {
            "name": "Juggernaut",
            "Underlord": 3.494
        },
        {
            "name": "Keeper of the Light",
            "Underlord": 0.742
        },
        {
            "name": "Kez",
            "Underlord": 1.122
        },
        {
            "name": "Kunkka",
            "Underlord": -0.997
        },
        {
            "name": "Legion Commander",
            "Underlord": 0.808
        },
        {
            "name": "Leshrac",
            "Underlord": 0.319
        },
        {
            "name": "Lich",
            "Underlord": 0.105
        },
        {
            "name": "Lifestealer",
            "Underlord": 2.84
        },
        {
            "name": "Lina",
            "Underlord": 0.924
        },
        {
            "name": "Lion",
            "Underlord": -0.551
        },
        {
            "name": "Lone Druid",
            "Underlord": -3.899
        },
        {
            "name": "Luna",
            "Underlord": -2.056
        },
        {
            "name": "Lycan",
            "Underlord": -0.368
        },
        {
            "name": "Magnus",
            "Underlord": 0.655
        },
        {
            "name": "Marci",
            "Underlord": -1.25
        },
        {
            "name": "Mars",
            "Underlord": -1.213
        },
        {
            "name": "Medusa",
            "Underlord": -3.031
        },
        {
            "name": "Meepo",
            "Underlord": -3.19
        },
        {
            "name": "Mirana",
            "Underlord": 0.069
        },
        {
            "name": "Monkey King",
            "Underlord": 1.007
        },
        {
            "name": "Morphling",
            "Underlord": 0.545
        },
        {
            "name": "Muerta",
            "Underlord": -0.932
        },
        {
            "name": "Naga Siren",
            "Underlord": -2.103
        },
        {
            "name": "Nature's Prophet",
            "Underlord": -1.356
        },
        {
            "name": "Necrophos",
            "Underlord": -1.653
        },
        {
            "name": "Night Stalker",
            "Underlord": -0.367
        },
        {
            "name": "Nyx Assassin",
            "Underlord": 0.208
        },
        {
            "name": "Ogre Magi",
            "Underlord": -0.935
        },
        {
            "name": "Omniknight",
            "Underlord": 0.984
        },
        {
            "name": "Oracle",
            "Underlord": 1.234
        },
        {
            "name": "Outworld Destroyer",
            "Underlord": 0.977
        },
        {
            "name": "Pangolier",
            "Underlord": 1.476
        },
        {
            "name": "Phantom Assassin",
            "Underlord": 1.058
        },
        {
            "name": "Phantom Lancer",
            "Underlord": -3.892
        },
        {
            "name": "Phoenix",
            "Underlord": -1.373
        },
        {
            "name": "Primal Beast",
            "Underlord": -2.363
        },
        {
            "name": "Puck",
            "Underlord": -0.159
        },
        {
            "name": "Pudge",
            "Underlord": 1.391
        },
        {
            "name": "Pugna",
            "Underlord": 1.63
        },
        {
            "name": "Queen of Pain",
            "Underlord": -0.215
        },
        {
            "name": "Razor",
            "Underlord": -1.044
        },
        {
            "name": "Riki",
            "Underlord": -3.07
        },
        {
            "name": "Ringmaster",
            "Underlord": 0.834
        },
        {
            "name": "Rubick",
            "Underlord": 6.504
        },
        {
            "name": "Sand King",
            "Underlord": -2.098
        },
        {
            "name": "Shadow Demon",
            "Underlord": -1.132
        },
        {
            "name": "Shadow Fiend",
            "Underlord": -0.724
        },
        {
            "name": "Shadow Shaman",
            "Underlord": -0.834
        },
        {
            "name": "Silencer",
            "Underlord": -0.663
        },
        {
            "name": "Skywrath Mage",
            "Underlord": 0.312
        },
        {
            "name": "Slardar",
            "Underlord": -0.268
        },
        {
            "name": "Slark",
            "Underlord": 0.378
        },
        {
            "name": "Snapfire",
            "Underlord": 2.354
        },
        {
            "name": "Sniper",
            "Underlord": 1.369
        },
        {
            "name": "Spectre",
            "Underlord": -2.65
        },
        {
            "name": "Spirit Breaker",
            "Underlord": -3.783
        },
        {
            "name": "Storm Spirit",
            "Underlord": -0.876
        },
        {
            "name": "Sven",
            "Underlord": 1.161
        },
        {
            "name": "Techies",
            "Underlord": -0.724
        },
        {
            "name": "Templar Assassin",
            "Underlord": -0.459
        },
        {
            "name": "Terrorblade",
            "Underlord": -0.73
        },
        {
            "name": "Tidehunter",
            "Underlord": -0.902
        },
        {
            "name": "Timbersaw",
            "Underlord": 1.922
        },
        {
            "name": "Tinker",
            "Underlord": 2.081
        },
        {
            "name": "Tiny",
            "Underlord": -2.053
        },
        {
            "name": "Treant Protector",
            "Underlord": -0.613
        },
        {
            "name": "Troll Warlord",
            "Underlord": -1.254
        },
        {
            "name": "Tusk",
            "Underlord": -0.682
        },
        {
            "name": "Undying",
            "Underlord": 0.525
        },
        {
            "name": "Ursa",
            "Underlord": 1.734
        },
        {
            "name": "Vengeful Spirit",
            "Underlord": -0.891
        },
        {
            "name": "Venomancer",
            "Underlord": 0.037
        },
        {
            "name": "Viper",
            "Underlord": 0.279
        },
        {
            "name": "Visage",
            "Underlord": -2.381
        },
        {
            "name": "Void Spirit",
            "Underlord": -1.6
        },
        {
            "name": "Warlock",
            "Underlord": -0.697
        },
        {
            "name": "Weaver",
            "Underlord": -0.1
        },
        {
            "name": "Windranger",
            "Underlord": 0.078
        },
        {
            "name": "Winter Wyvern",
            "Underlord": -1.07
        },
        {
            "name": "Witch Doctor",
            "Underlord": 1.524
        },
        {
            "name": "Wraith King",
            "Underlord": -3.513
        },
        {
            "name": "Zeus",
            "Underlord": -1.489
        }
    ],
    "Undying": [
        {
            "name": "Abaddon",
            "Undying": -3.168
        },
        {
            "name": "Alchemist",
            "Undying": 0.515
        },
        {
            "name": "Ancient Apparition",
            "Undying": 2.247
        },
        {
            "name": "Anti-Mage",
            "Undying": 1.641
        },
        {
            "name": "Arc Warden",
            "Undying": -0.278
        },
        {
            "name": "Axe",
            "Undying": -0.442
        },
        {
            "name": "Bane",
            "Undying": 0.114
        },
        {
            "name": "Batrider",
            "Undying": 0.159
        },
        {
            "name": "Beastmaster",
            "Undying": -0.867
        },
        {
            "name": "Bloodseeker",
            "Undying": 0.392
        },
        {
            "name": "Bounty Hunter",
            "Undying": 1.082
        },
        {
            "name": "Brewmaster",
            "Undying": -0.051
        },
        {
            "name": "Bristleback",
            "Undying": 0.471
        },
        {
            "name": "Broodmother",
            "Undying": 1.287
        },
        {
            "name": "Centaur Warrunner",
            "Undying": -2.347
        },
        {
            "name": "Chaos Knight",
            "Undying": -0.175
        },
        {
            "name": "Chen",
            "Undying": 1.241
        },
        {
            "name": "Clinkz",
            "Undying": 1.62
        },
        {
            "name": "Clockwerk",
            "Undying": -1.944
        },
        {
            "name": "Crystal Maiden",
            "Undying": -0.676
        },
        {
            "name": "Dark Seer",
            "Undying": 0.608
        },
        {
            "name": "Dark Willow",
            "Undying": -0.054
        },
        {
            "name": "Dawnbreaker",
            "Undying": 0.388
        },
        {
            "name": "Dazzle",
            "Undying": -0.558
        },
        {
            "name": "Death Prophet",
            "Undying": -1.631
        },
        {
            "name": "Disruptor",
            "Undying": -0.889
        },
        {
            "name": "Doom",
            "Undying": 0.443
        },
        {
            "name": "Dragon Knight",
            "Undying": -0.503
        },
        {
            "name": "Drow Ranger",
            "Undying": -0.501
        },
        {
            "name": "Earth Spirit",
            "Undying": -0.268
        },
        {
            "name": "Earthshaker",
            "Undying": 0.635
        },
        {
            "name": "Elder Titan",
            "Undying": 2.102
        },
        {
            "name": "Ember Spirit",
            "Undying": 0.52
        },
        {
            "name": "Enchantress",
            "Undying": 1.431
        },
        {
            "name": "Enigma",
            "Undying": -0.788
        },
        {
            "name": "Faceless Void",
            "Undying": 1.083
        },
        {
            "name": "Grimstroke",
            "Undying": 0.511
        },
        {
            "name": "Gyrocopter",
            "Undying": 2.223
        },
        {
            "name": "Hoodwink",
            "Undying": 0.439
        },
        {
            "name": "Huskar",
            "Undying": -1.024
        },
        {
            "name": "Invoker",
            "Undying": 0.593
        },
        {
            "name": "Io",
            "Undying": -1.004
        },
        {
            "name": "Jakiro",
            "Undying": 0.024
        },
        {
            "name": "Juggernaut",
            "Undying": 0.45
        },
        {
            "name": "Keeper of the Light",
            "Undying": 0.894
        },
        {
            "name": "Kez",
            "Undying": 0.42
        },
        {
            "name": "Kunkka",
            "Undying": -0.849
        },
        {
            "name": "Legion Commander",
            "Undying": 0.7
        },
        {
            "name": "Leshrac",
            "Undying": -3.032
        },
        {
            "name": "Lich",
            "Undying": -1.04
        },
        {
            "name": "Lifestealer",
            "Undying": 0.408
        },
        {
            "name": "Lina",
            "Undying": 0.703
        },
        {
            "name": "Lion",
            "Undying": 0.353
        },
        {
            "name": "Lone Druid",
            "Undying": -1.466
        },
        {
            "name": "Luna",
            "Undying": 2.842
        },
        {
            "name": "Lycan",
            "Undying": 1.462
        },
        {
            "name": "Magnus",
            "Undying": 1.14
        },
        {
            "name": "Marci",
            "Undying": 0.72
        },
        {
            "name": "Mars",
            "Undying": -0.694
        },
        {
            "name": "Medusa",
            "Undying": 4.481
        },
        {
            "name": "Meepo",
            "Undying": 2.057
        },
        {
            "name": "Mirana",
            "Undying": 0.142
        },
        {
            "name": "Monkey King",
            "Undying": 1.521
        },
        {
            "name": "Morphling",
            "Undying": 1.042
        },
        {
            "name": "Muerta",
            "Undying": 1.015
        },
        {
            "name": "Naga Siren",
            "Undying": 2.687
        },
        {
            "name": "Nature's Prophet",
            "Undying": 0.604
        },
        {
            "name": "Necrophos",
            "Undying": 0.266
        },
        {
            "name": "Night Stalker",
            "Undying": -1.105
        },
        {
            "name": "Nyx Assassin",
            "Undying": -0.021
        },
        {
            "name": "Ogre Magi",
            "Undying": 0.714
        },
        {
            "name": "Omniknight",
            "Undying": -1.905
        },
        {
            "name": "Oracle",
            "Undying": -2.129
        },
        {
            "name": "Outworld Destroyer",
            "Undying": -0.184
        },
        {
            "name": "Pangolier",
            "Undying": 0.371
        },
        {
            "name": "Phantom Assassin",
            "Undying": 0.251
        },
        {
            "name": "Phantom Lancer",
            "Undying": 1.886
        },
        {
            "name": "Phoenix",
            "Undying": 1.135
        },
        {
            "name": "Primal Beast",
            "Undying": -0.747
        },
        {
            "name": "Puck",
            "Undying": -1.319
        },
        {
            "name": "Pudge",
            "Undying": -1.169
        },
        {
            "name": "Pugna",
            "Undying": 0.652
        },
        {
            "name": "Queen of Pain",
            "Undying": -0.171
        },
        {
            "name": "Razor",
            "Undying": 0.31
        },
        {
            "name": "Riki",
            "Undying": 1.41
        },
        {
            "name": "Ringmaster",
            "Undying": -0.458
        },
        {
            "name": "Rubick",
            "Undying": 1.26
        },
        {
            "name": "Sand King",
            "Undying": 0.395
        },
        {
            "name": "Shadow Demon",
            "Undying": -1.657
        },
        {
            "name": "Shadow Fiend",
            "Undying": 0.364
        },
        {
            "name": "Shadow Shaman",
            "Undying": -0.406
        },
        {
            "name": "Silencer",
            "Undying": 1.002
        },
        {
            "name": "Skywrath Mage",
            "Undying": 0.339
        },
        {
            "name": "Slardar",
            "Undying": 0.432
        },
        {
            "name": "Slark",
            "Undying": 1.318
        },
        {
            "name": "Snapfire",
            "Undying": 0.157
        },
        {
            "name": "Sniper",
            "Undying": 0.748
        },
        {
            "name": "Spectre",
            "Undying": -3.493
        },
        {
            "name": "Spirit Breaker",
            "Undying": -0.451
        },
        {
            "name": "Storm Spirit",
            "Undying": 0.588
        },
        {
            "name": "Sven",
            "Undying": -0.88
        },
        {
            "name": "Techies",
            "Undying": -0.175
        },
        {
            "name": "Templar Assassin",
            "Undying": 0.163
        },
        {
            "name": "Terrorblade",
            "Undying": -0.007
        },
        {
            "name": "Tidehunter",
            "Undying": -1.169
        },
        {
            "name": "Timbersaw",
            "Undying": 1.633
        },
        {
            "name": "Tinker",
            "Undying": 0.488
        },
        {
            "name": "Tiny",
            "Undying": -2.095
        },
        {
            "name": "Treant Protector",
            "Undying": -0.706
        },
        {
            "name": "Troll Warlord",
            "Undying": 1.311
        },
        {
            "name": "Tusk",
            "Undying": -1.161
        },
        {
            "name": "Underlord",
            "Undying": -0.428
        },
        {
            "name": "Ursa",
            "Undying": -1.592
        },
        {
            "name": "Vengeful Spirit",
            "Undying": -0.361
        },
        {
            "name": "Venomancer",
            "Undying": 1.539
        },
        {
            "name": "Viper",
            "Undying": -0.733
        },
        {
            "name": "Visage",
            "Undying": -1.646
        },
        {
            "name": "Void Spirit",
            "Undying": 0.216
        },
        {
            "name": "Warlock",
            "Undying": -0.008
        },
        {
            "name": "Weaver",
            "Undying": 0.838
        },
        {
            "name": "Windranger",
            "Undying": 0.361
        },
        {
            "name": "Winter Wyvern",
            "Undying": -2.031
        },
        {
            "name": "Witch Doctor",
            "Undying": 1.706
        },
        {
            "name": "Wraith King",
            "Undying": -3.02
        },
        {
            "name": "Zeus",
            "Undying": -0.981
        }
    ],
    "Wraith King": [
        {
            "name": "Abaddon",
            "Wraith King": 1.916
        },
        {
            "name": "Alchemist",
            "Wraith King": 2.791
        },
        {
            "name": "Ancient Apparition",
            "Wraith King": -0.5
        },
        {
            "name": "Anti-Mage",
            "Wraith King": 2.499
        },
        {
            "name": "Arc Warden",
            "Wraith King": 0.002
        },
        {
            "name": "Axe",
            "Wraith King": -1.313
        },
        {
            "name": "Bane",
            "Wraith King": 0.071
        },
        {
            "name": "Batrider",
            "Wraith King": -0.374
        },
        {
            "name": "Beastmaster",
            "Wraith King": 2.943
        },
        {
            "name": "Bloodseeker",
            "Wraith King": -0.185
        },
        {
            "name": "Bounty Hunter",
            "Wraith King": 1.041
        },
        {
            "name": "Brewmaster",
            "Wraith King": 2.294
        },
        {
            "name": "Bristleback",
            "Wraith King": 0.987
        },
        {
            "name": "Broodmother",
            "Wraith King": 2.521
        },
        {
            "name": "Centaur Warrunner",
            "Wraith King": -0.447
        },
        {
            "name": "Chaos Knight",
            "Wraith King": 0.784
        },
        {
            "name": "Chen",
            "Wraith King": 2.801
        },
        {
            "name": "Clinkz",
            "Wraith King": -1.454
        },
        {
            "name": "Clockwerk",
            "Wraith King": 0.426
        },
        {
            "name": "Crystal Maiden",
            "Wraith King": 1.509
        },
        {
            "name": "Dark Seer",
            "Wraith King": 2.051
        },
        {
            "name": "Dark Willow",
            "Wraith King": 0.177
        },
        {
            "name": "Dawnbreaker",
            "Wraith King": 1.071
        },
        {
            "name": "Dazzle",
            "Wraith King": 2.256
        },
        {
            "name": "Death Prophet",
            "Wraith King": 0.085
        },
        {
            "name": "Disruptor",
            "Wraith King": 0.004
        },
        {
            "name": "Doom",
            "Wraith King": -0.695
        },
        {
            "name": "Dragon Knight",
            "Wraith King": 1.13
        },
        {
            "name": "Drow Ranger",
            "Wraith King": -1.547
        },
        {
            "name": "Earth Spirit",
            "Wraith King": -0.831
        },
        {
            "name": "Earthshaker",
            "Wraith King": -0.538
        },
        {
            "name": "Elder Titan",
            "Wraith King": -1.777
        },
        {
            "name": "Ember Spirit",
            "Wraith King": 1.346
        },
        {
            "name": "Enchantress",
            "Wraith King": 0.685
        },
        {
            "name": "Enigma",
            "Wraith King": -2.176
        },
        {
            "name": "Faceless Void",
            "Wraith King": -0.739
        },
        {
            "name": "Grimstroke",
            "Wraith King": -0.479
        },
        {
            "name": "Gyrocopter",
            "Wraith King": 0.257
        },
        {
            "name": "Hoodwink",
            "Wraith King": -0.058
        },
        {
            "name": "Huskar",
            "Wraith King": -0.793
        },
        {
            "name": "Invoker",
            "Wraith King": 1.906
        },
        {
            "name": "Io",
            "Wraith King": 1.583
        },
        {
            "name": "Jakiro",
            "Wraith King": 1.958
        },
        {
            "name": "Juggernaut",
            "Wraith King": -0.501
        },
        {
            "name": "Keeper of the Light",
            "Wraith King": 1.589
        },
        {
            "name": "Kez",
            "Wraith King": 0.581
        },
        {
            "name": "Kunkka",
            "Wraith King": 0.397
        },
        {
            "name": "Legion Commander",
            "Wraith King": -1.745
        },
        {
            "name": "Leshrac",
            "Wraith King": -0.088
        },
        {
            "name": "Lich",
            "Wraith King": 1.206
        },
        {
            "name": "Lifestealer",
            "Wraith King": 2.03
        },
        {
            "name": "Lina",
            "Wraith King": -0.658
        },
        {
            "name": "Lion",
            "Wraith King": -0.678
        },
        {
            "name": "Lone Druid",
            "Wraith King": 4.024
        },
        {
            "name": "Luna",
            "Wraith King": -0.656
        },
        {
            "name": "Lycan",
            "Wraith King": 1.542
        },
        {
            "name": "Magnus",
            "Wraith King": -0.729
        },
        {
            "name": "Marci",
            "Wraith King": 0.766
        },
        {
            "name": "Mars",
            "Wraith King": 0.156
        },
        {
            "name": "Medusa",
            "Wraith King": 0.853
        },
        {
            "name": "Meepo",
            "Wraith King": 3.439
        },
        {
            "name": "Mirana",
            "Wraith King": -0.801
        },
        {
            "name": "Monkey King",
            "Wraith King": 2.053
        },
        {
            "name": "Morphling",
            "Wraith King": 1.884
        },
        {
            "name": "Muerta",
            "Wraith King": -1.018
        },
        {
            "name": "Naga Siren",
            "Wraith King": 3.406
        },
        {
            "name": "Nature's Prophet",
            "Wraith King": -0.012
        },
        {
            "name": "Necrophos",
            "Wraith King": 3.641
        },
        {
            "name": "Night Stalker",
            "Wraith King": -1.126
        },
        {
            "name": "Nyx Assassin",
            "Wraith King": -0.817
        },
        {
            "name": "Ogre Magi",
            "Wraith King": 0.305
        },
        {
            "name": "Omniknight",
            "Wraith King": 0.286
        },
        {
            "name": "Oracle",
            "Wraith King": 0.282
        },
        {
            "name": "Outworld Destroyer",
            "Wraith King": -1.77
        },
        {
            "name": "Pangolier",
            "Wraith King": 2.511
        },
        {
            "name": "Phantom Assassin",
            "Wraith King": -1.059
        },
        {
            "name": "Phantom Lancer",
            "Wraith King": 4.882
        },
        {
            "name": "Phoenix",
            "Wraith King": 2.692
        },
        {
            "name": "Primal Beast",
            "Wraith King": 0.477
        },
        {
            "name": "Puck",
            "Wraith King": -1.125
        },
        {
            "name": "Pudge",
            "Wraith King": -1.343
        },
        {
            "name": "Pugna",
            "Wraith King": -0.202
        },
        {
            "name": "Queen of Pain",
            "Wraith King": -0.091
        },
        {
            "name": "Razor",
            "Wraith King": 0.832
        },
        {
            "name": "Riki",
            "Wraith King": -1.389
        },
        {
            "name": "Ringmaster",
            "Wraith King": -0.426
        },
        {
            "name": "Rubick",
            "Wraith King": 1.464
        },
        {
            "name": "Sand King",
            "Wraith King": 1.625
        },
        {
            "name": "Shadow Demon",
            "Wraith King": 0.427
        },
        {
            "name": "Shadow Fiend",
            "Wraith King": -0.983
        },
        {
            "name": "Shadow Shaman",
            "Wraith King": -1.048
        },
        {
            "name": "Silencer",
            "Wraith King": -2.32
        },
        {
            "name": "Skywrath Mage",
            "Wraith King": -1.602
        },
        {
            "name": "Slardar",
            "Wraith King": 0.267
        },
        {
            "name": "Slark",
            "Wraith King": 2.782
        },
        {
            "name": "Snapfire",
            "Wraith King": 0.272
        },
        {
            "name": "Sniper",
            "Wraith King": -0.14
        },
        {
            "name": "Spectre",
            "Wraith King": -0.741
        },
        {
            "name": "Spirit Breaker",
            "Wraith King": -1.092
        },
        {
            "name": "Storm Spirit",
            "Wraith King": 0.127
        },
        {
            "name": "Sven",
            "Wraith King": -0.846
        },
        {
            "name": "Techies",
            "Wraith King": -0.724
        },
        {
            "name": "Templar Assassin",
            "Wraith King": -0.127
        },
        {
            "name": "Terrorblade",
            "Wraith King": 2.458
        },
        {
            "name": "Tidehunter",
            "Wraith King": 3.085
        },
        {
            "name": "Timbersaw",
            "Wraith King": 2.09
        },
        {
            "name": "Tinker",
            "Wraith King": 1.944
        },
        {
            "name": "Tiny",
            "Wraith King": -0.737
        },
        {
            "name": "Treant Protector",
            "Wraith King": 1.522
        },
        {
            "name": "Troll Warlord",
            "Wraith King": 0.098
        },
        {
            "name": "Tusk",
            "Wraith King": 0.657
        },
        {
            "name": "Underlord",
            "Wraith King": 3.457
        },
        {
            "name": "Undying",
            "Wraith King": 2.975
        },
        {
            "name": "Ursa",
            "Wraith King": -2.11
        },
        {
            "name": "Vengeful Spirit",
            "Wraith King": 0.273
        },
        {
            "name": "Venomancer",
            "Wraith King": 1.882
        },
        {
            "name": "Viper",
            "Wraith King": -0.948
        },
        {
            "name": "Visage",
            "Wraith King": 1.191
        },
        {
            "name": "Void Spirit",
            "Wraith King": -0.555
        },
        {
            "name": "Warlock",
            "Wraith King": 2.872
        },
        {
            "name": "Weaver",
            "Wraith King": -0.308
        },
        {
            "name": "Windranger",
            "Wraith King": 0.042
        },
        {
            "name": "Winter Wyvern",
            "Wraith King": 1.279
        },
        {
            "name": "Witch Doctor",
            "Wraith King": -1.018
        },
        {
            "name": "Zeus",
            "Wraith King": -0.38
        }
    ],
    "Anti-Mage": [
        {
            "name": "Abaddon",
            "Anti-Mage": -3.243
        },
        {
            "name": "Alchemist",
            "Anti-Mage": -0.592
        },
        {
            "name": "Ancient Apparition",
            "Anti-Mage": -2.892
        },
        {
            "name": "Arc Warden",
            "Anti-Mage": -0.346
        },
        {
            "name": "Axe",
            "Anti-Mage": 4.708
        },
        {
            "name": "Bane",
            "Anti-Mage": 0.46
        },
        {
            "name": "Batrider",
            "Anti-Mage": 1.224
        },
        {
            "name": "Beastmaster",
            "Anti-Mage": 2.356
        },
        {
            "name": "Bloodseeker",
            "Anti-Mage": 1.431
        },
        {
            "name": "Bounty Hunter",
            "Anti-Mage": -1.055
        },
        {
            "name": "Brewmaster",
            "Anti-Mage": -1.305
        },
        {
            "name": "Bristleback",
            "Anti-Mage": -0.86
        },
        {
            "name": "Broodmother",
            "Anti-Mage": 1.943
        },
        {
            "name": "Centaur Warrunner",
            "Anti-Mage": 0.207
        },
        {
            "name": "Chaos Knight",
            "Anti-Mage": -1.634
        },
        {
            "name": "Chen",
            "Anti-Mage": -0.835
        },
        {
            "name": "Clinkz",
            "Anti-Mage": -0.369
        },
        {
            "name": "Clockwerk",
            "Anti-Mage": -2.057
        },
        {
            "name": "Crystal Maiden",
            "Anti-Mage": 1.12
        },
        {
            "name": "Dark Seer",
            "Anti-Mage": -0.382
        },
        {
            "name": "Dark Willow",
            "Anti-Mage": 0.098
        },
        {
            "name": "Dawnbreaker",
            "Anti-Mage": -0.451
        },
        {
            "name": "Dazzle",
            "Anti-Mage": 0.514
        },
        {
            "name": "Death Prophet",
            "Anti-Mage": -2.034
        },
        {
            "name": "Disruptor",
            "Anti-Mage": 1.741
        },
        {
            "name": "Doom",
            "Anti-Mage": -1.083
        },
        {
            "name": "Dragon Knight",
            "Anti-Mage": 0.453
        },
        {
            "name": "Drow Ranger",
            "Anti-Mage": 1.063
        },
        {
            "name": "Earth Spirit",
            "Anti-Mage": -0.682
        },
        {
            "name": "Earthshaker",
            "Anti-Mage": 2.548
        },
        {
            "name": "Elder Titan",
            "Anti-Mage": 1.118
        },
        {
            "name": "Ember Spirit",
            "Anti-Mage": 0.095
        },
        {
            "name": "Enchantress",
            "Anti-Mage": 2.057
        },
        {
            "name": "Enigma",
            "Anti-Mage": 1.874
        },
        {
            "name": "Faceless Void",
            "Anti-Mage": -0.544
        },
        {
            "name": "Grimstroke",
            "Anti-Mage": -0.351
        },
        {
            "name": "Gyrocopter",
            "Anti-Mage": 0.056
        },
        {
            "name": "Hoodwink",
            "Anti-Mage": 2.671
        },
        {
            "name": "Huskar",
            "Anti-Mage": 5.912
        },
        {
            "name": "Invoker",
            "Anti-Mage": -0.679
        },
        {
            "name": "Io",
            "Anti-Mage": 1.787
        },
        {
            "name": "Jakiro",
            "Anti-Mage": 1.36
        },
        {
            "name": "Juggernaut",
            "Anti-Mage": -2.112
        },
        {
            "name": "Keeper of the Light",
            "Anti-Mage": -0.916
        },
        {
            "name": "Kez",
            "Anti-Mage": 3.177
        },
        {
            "name": "Kunkka",
            "Anti-Mage": 0.587
        },
        {
            "name": "Legion Commander",
            "Anti-Mage": 1.688
        },
        {
            "name": "Leshrac",
            "Anti-Mage": -1.019
        },
        {
            "name": "Lich",
            "Anti-Mage": -1.471
        },
        {
            "name": "Lifestealer",
            "Anti-Mage": -1.215
        },
        {
            "name": "Lina",
            "Anti-Mage": -1.388
        },
        {
            "name": "Lion",
            "Anti-Mage": 1.083
        },
        {
            "name": "Lone Druid",
            "Anti-Mage": 3.974
        },
        {
            "name": "Luna",
            "Anti-Mage": 1.787
        },
        {
            "name": "Lycan",
            "Anti-Mage": 0.316
        },
        {
            "name": "Magnus",
            "Anti-Mage": 1.996
        },
        {
            "name": "Marci",
            "Anti-Mage": 2.854
        },
        {
            "name": "Mars",
            "Anti-Mage": -2.344
        },
        {
            "name": "Medusa",
            "Anti-Mage": -10.053
        },
        {
            "name": "Meepo",
            "Anti-Mage": 7.093
        },
        {
            "name": "Mirana",
            "Anti-Mage": 1.101
        },
        {
            "name": "Monkey King",
            "Anti-Mage": 2.371
        },
        {
            "name": "Morphling",
            "Anti-Mage": -2.11
        },
        {
            "name": "Muerta",
            "Anti-Mage": -0.591
        },
        {
            "name": "Naga Siren",
            "Anti-Mage": 0.304
        },
        {
            "name": "Nature's Prophet",
            "Anti-Mage": -0.635
        },
        {
            "name": "Necrophos",
            "Anti-Mage": -1.388
        },
        {
            "name": "Night Stalker",
            "Anti-Mage": 0.46
        },
        {
            "name": "Nyx Assassin",
            "Anti-Mage": 0.813
        },
        {
            "name": "Ogre Magi",
            "Anti-Mage": -2.395
        },
        {
            "name": "Omniknight",
            "Anti-Mage": 0.781
        },
        {
            "name": "Oracle",
            "Anti-Mage": -2.944
        },
        {
            "name": "Outworld Destroyer",
            "Anti-Mage": -1.287
        },
        {
            "name": "Pangolier",
            "Anti-Mage": 1.316
        },
        {
            "name": "Phantom Assassin",
            "Anti-Mage": 5.3
        },
        {
            "name": "Phantom Lancer",
            "Anti-Mage": -3.476
        },
        {
            "name": "Phoenix",
            "Anti-Mage": 1.304
        },
        {
            "name": "Primal Beast",
            "Anti-Mage": -1.788
        },
        {
            "name": "Puck",
            "Anti-Mage": 1.694
        },
        {
            "name": "Pudge",
            "Anti-Mage": -1.286
        },
        {
            "name": "Pugna",
            "Anti-Mage": -1.984
        },
        {
            "name": "Queen of Pain",
            "Anti-Mage": -1.527
        },
        {
            "name": "Razor",
            "Anti-Mage": -2.9
        },
        {
            "name": "Riki",
            "Anti-Mage": 1.669
        },
        {
            "name": "Ringmaster",
            "Anti-Mage": -0.402
        },
        {
            "name": "Rubick",
            "Anti-Mage": -0.225
        },
        {
            "name": "Sand King",
            "Anti-Mage": -1.187
        },
        {
            "name": "Shadow Demon",
            "Anti-Mage": -1.382
        },
        {
            "name": "Shadow Fiend",
            "Anti-Mage": 2.666
        },
        {
            "name": "Shadow Shaman",
            "Anti-Mage": 0.717
        },
        {
            "name": "Silencer",
            "Anti-Mage": -0.995
        },
        {
            "name": "Skywrath Mage",
            "Anti-Mage": -0.927
        },
        {
            "name": "Slardar",
            "Anti-Mage": 3.755
        },
        {
            "name": "Slark",
            "Anti-Mage": 0.483
        },
        {
            "name": "Snapfire",
            "Anti-Mage": 0.721
        },
        {
            "name": "Sniper",
            "Anti-Mage": 0.312
        },
        {
            "name": "Spectre",
            "Anti-Mage": -1.05
        },
        {
            "name": "Spirit Breaker",
            "Anti-Mage": 0.734
        },
        {
            "name": "Storm Spirit",
            "Anti-Mage": -4.225
        },
        {
            "name": "Sven",
            "Anti-Mage": 1.779
        },
        {
            "name": "Techies",
            "Anti-Mage": 0.449
        },
        {
            "name": "Templar Assassin",
            "Anti-Mage": 2.495
        },
        {
            "name": "Terrorblade",
            "Anti-Mage": 2.81
        },
        {
            "name": "Tidehunter",
            "Anti-Mage": -1.985
        },
        {
            "name": "Timbersaw",
            "Anti-Mage": 1.063
        },
        {
            "name": "Tinker",
            "Anti-Mage": -3.021
        },
        {
            "name": "Tiny",
            "Anti-Mage": 0.874
        },
        {
            "name": "Treant Protector",
            "Anti-Mage": 0.456
        },
        {
            "name": "Troll Warlord",
            "Anti-Mage": 3.274
        },
        {
            "name": "Tusk",
            "Anti-Mage": 0.641
        },
        {
            "name": "Underlord",
            "Anti-Mage": -1.745
        },
        {
            "name": "Undying",
            "Anti-Mage": -1.661
        },
        {
            "name": "Ursa",
            "Anti-Mage": 1.986
        },
        {
            "name": "Vengeful Spirit",
            "Anti-Mage": -0.921
        },
        {
            "name": "Venomancer",
            "Anti-Mage": -0.707
        },
        {
            "name": "Viper",
            "Anti-Mage": 0.006
        },
        {
            "name": "Visage",
            "Anti-Mage": -0.749
        },
        {
            "name": "Void Spirit",
            "Anti-Mage": -1.539
        },
        {
            "name": "Warlock",
            "Anti-Mage": -0.97
        },
        {
            "name": "Weaver",
            "Anti-Mage": -0.236
        },
        {
            "name": "Windranger",
            "Anti-Mage": 1.183
        },
        {
            "name": "Winter Wyvern",
            "Anti-Mage": -2.406
        },
        {
            "name": "Witch Doctor",
            "Anti-Mage": 0.757
        },
        {
            "name": "Wraith King",
            "Anti-Mage": -2.517
        },
        {
            "name": "Zeus",
            "Anti-Mage": -2.062
        }
    ],
    "Arc Warden": [
        {
            "name": "Abaddon",
            "Arc Warden": 1.936
        },
        {
            "name": "Alchemist",
            "Arc Warden": -1.056
        },
        {
            "name": "Ancient Apparition",
            "Arc Warden": -1.52
        },
        {
            "name": "Anti-Mage",
            "Arc Warden": 0.21
        },
        {
            "name": "Axe",
            "Arc Warden": 2.69
        },
        {
            "name": "Bane",
            "Arc Warden": -4.163
        },
        {
            "name": "Batrider",
            "Arc Warden": -0.365
        },
        {
            "name": "Beastmaster",
            "Arc Warden": 0.014
        },
        {
            "name": "Bloodseeker",
            "Arc Warden": -0.671
        },
        {
            "name": "Bounty Hunter",
            "Arc Warden": -1.86
        },
        {
            "name": "Brewmaster",
            "Arc Warden": 0.659
        },
        {
            "name": "Bristleback",
            "Arc Warden": 2.276
        },
        {
            "name": "Broodmother",
            "Arc Warden": 5.083
        },
        {
            "name": "Centaur Warrunner",
            "Arc Warden": 2.387
        },
        {
            "name": "Chaos Knight",
            "Arc Warden": 1.609
        },
        {
            "name": "Chen",
            "Arc Warden": -1.077
        },
        {
            "name": "Clinkz",
            "Arc Warden": -2.512
        },
        {
            "name": "Clockwerk",
            "Arc Warden": -0.623
        },
        {
            "name": "Crystal Maiden",
            "Arc Warden": 0.734
        },
        {
            "name": "Dark Seer",
            "Arc Warden": 0.272
        },
        {
            "name": "Dark Willow",
            "Arc Warden": -1.168
        },
        {
            "name": "Dawnbreaker",
            "Arc Warden": 0.838
        },
        {
            "name": "Dazzle",
            "Arc Warden": 0.987
        },
        {
            "name": "Death Prophet",
            "Arc Warden": -0.605
        },
        {
            "name": "Disruptor",
            "Arc Warden": -1.732
        },
        {
            "name": "Doom",
            "Arc Warden": -3.63
        },
        {
            "name": "Dragon Knight",
            "Arc Warden": -0.668
        },
        {
            "name": "Drow Ranger",
            "Arc Warden": -1.837
        },
        {
            "name": "Earth Spirit",
            "Arc Warden": 1.759
        },
        {
            "name": "Earthshaker",
            "Arc Warden": 1.603
        },
        {
            "name": "Elder Titan",
            "Arc Warden": 0.287
        },
        {
            "name": "Ember Spirit",
            "Arc Warden": -0.948
        },
        {
            "name": "Enchantress",
            "Arc Warden": 0.911
        },
        {
            "name": "Enigma",
            "Arc Warden": 1.721
        },
        {
            "name": "Faceless Void",
            "Arc Warden": -0.396
        },
        {
            "name": "Grimstroke",
            "Arc Warden": -0.138
        },
        {
            "name": "Gyrocopter",
            "Arc Warden": 0.84
        },
        {
            "name": "Hoodwink",
            "Arc Warden": 0.28
        },
        {
            "name": "Huskar",
            "Arc Warden": -2.49
        },
        {
            "name": "Invoker",
            "Arc Warden": -1.113
        },
        {
            "name": "Io",
            "Arc Warden": 2.197
        },
        {
            "name": "Jakiro",
            "Arc Warden": 1.647
        },
        {
            "name": "Juggernaut",
            "Arc Warden": 0.124
        },
        {
            "name": "Keeper of the Light",
            "Arc Warden": -1.695
        },
        {
            "name": "Kez",
            "Arc Warden": 1.313
        },
        {
            "name": "Kunkka",
            "Arc Warden": 3.197
        },
        {
            "name": "Legion Commander",
            "Arc Warden": 2.449
        },
        {
            "name": "Leshrac",
            "Arc Warden": 0.199
        },
        {
            "name": "Lich",
            "Arc Warden": 1.911
        },
        {
            "name": "Lifestealer",
            "Arc Warden": -1.661
        },
        {
            "name": "Lina",
            "Arc Warden": 0.431
        },
        {
            "name": "Lion",
            "Arc Warden": -1.683
        },
        {
            "name": "Lone Druid",
            "Arc Warden": 2.092
        },
        {
            "name": "Luna",
            "Arc Warden": 1.303
        },
        {
            "name": "Lycan",
            "Arc Warden": 3.924
        },
        {
            "name": "Magnus",
            "Arc Warden": 1.069
        },
        {
            "name": "Marci",
            "Arc Warden": 3.093
        },
        {
            "name": "Mars",
            "Arc Warden": 0.844
        },
        {
            "name": "Medusa",
            "Arc Warden": 1.237
        },
        {
            "name": "Meepo",
            "Arc Warden": 5.299
        },
        {
            "name": "Mirana",
            "Arc Warden": 1.408
        },
        {
            "name": "Monkey King",
            "Arc Warden": -0.6
        },
        {
            "name": "Morphling",
            "Arc Warden": 1.065
        },
        {
            "name": "Muerta",
            "Arc Warden": -1.256
        },
        {
            "name": "Naga Siren",
            "Arc Warden": 1.186
        },
        {
            "name": "Nature's Prophet",
            "Arc Warden": -1.815
        },
        {
            "name": "Necrophos",
            "Arc Warden": -3.07
        },
        {
            "name": "Night Stalker",
            "Arc Warden": -3.729
        },
        {
            "name": "Nyx Assassin",
            "Arc Warden": -1.249
        },
        {
            "name": "Ogre Magi",
            "Arc Warden": -1.504
        },
        {
            "name": "Omniknight",
            "Arc Warden": 2.831
        },
        {
            "name": "Oracle",
            "Arc Warden": -0.214
        },
        {
            "name": "Outworld Destroyer",
            "Arc Warden": -3.141
        },
        {
            "name": "Pangolier",
            "Arc Warden": 3.62
        },
        {
            "name": "Phantom Assassin",
            "Arc Warden": 1.233
        },
        {
            "name": "Phantom Lancer",
            "Arc Warden": 4.234
        },
        {
            "name": "Phoenix",
            "Arc Warden": -0.763
        },
        {
            "name": "Primal Beast",
            "Arc Warden": 0.791
        },
        {
            "name": "Puck",
            "Arc Warden": -1.388
        },
        {
            "name": "Pudge",
            "Arc Warden": 0.603
        },
        {
            "name": "Pugna",
            "Arc Warden": 1.125
        },
        {
            "name": "Queen of Pain",
            "Arc Warden": -1.762
        },
        {
            "name": "Razor",
            "Arc Warden": -0.406
        },
        {
            "name": "Riki",
            "Arc Warden": -1.38
        },
        {
            "name": "Ringmaster",
            "Arc Warden": 0.9
        },
        {
            "name": "Rubick",
            "Arc Warden": 0.642
        },
        {
            "name": "Sand King",
            "Arc Warden": 2.265
        },
        {
            "name": "Shadow Demon",
            "Arc Warden": -0.65
        },
        {
            "name": "Shadow Fiend",
            "Arc Warden": 0.537
        },
        {
            "name": "Shadow Shaman",
            "Arc Warden": -1.415
        },
        {
            "name": "Silencer",
            "Arc Warden": -2.024
        },
        {
            "name": "Skywrath Mage",
            "Arc Warden": -0.917
        },
        {
            "name": "Slardar",
            "Arc Warden": -1.301
        },
        {
            "name": "Slark",
            "Arc Warden": 0.438
        },
        {
            "name": "Snapfire",
            "Arc Warden": 1.381
        },
        {
            "name": "Sniper",
            "Arc Warden": -0.835
        },
        {
            "name": "Spectre",
            "Arc Warden": 0.105
        },
        {
            "name": "Spirit Breaker",
            "Arc Warden": -0.189
        },
        {
            "name": "Storm Spirit",
            "Arc Warden": -1.752
        },
        {
            "name": "Sven",
            "Arc Warden": 3.109
        },
        {
            "name": "Techies",
            "Arc Warden": 0.752
        },
        {
            "name": "Templar Assassin",
            "Arc Warden": -3.086
        },
        {
            "name": "Terrorblade",
            "Arc Warden": -0.49
        },
        {
            "name": "Tidehunter",
            "Arc Warden": 1.218
        },
        {
            "name": "Timbersaw",
            "Arc Warden": -0.438
        },
        {
            "name": "Tinker",
            "Arc Warden": -0.051
        },
        {
            "name": "Tiny",
            "Arc Warden": 0.657
        },
        {
            "name": "Treant Protector",
            "Arc Warden": 0.06
        },
        {
            "name": "Troll Warlord",
            "Arc Warden": 1.349
        },
        {
            "name": "Tusk",
            "Arc Warden": 1.304
        },
        {
            "name": "Underlord",
            "Arc Warden": 1.442
        },
        {
            "name": "Undying",
            "Arc Warden": 0.238
        },
        {
            "name": "Ursa",
            "Arc Warden": -1.035
        },
        {
            "name": "Vengeful Spirit",
            "Arc Warden": -0.516
        },
        {
            "name": "Venomancer",
            "Arc Warden": -1.008
        },
        {
            "name": "Viper",
            "Arc Warden": -2.349
        },
        {
            "name": "Visage",
            "Arc Warden": -0.599
        },
        {
            "name": "Void Spirit",
            "Arc Warden": 0.231
        },
        {
            "name": "Warlock",
            "Arc Warden": 2.873
        },
        {
            "name": "Weaver",
            "Arc Warden": -1.537
        },
        {
            "name": "Windranger",
            "Arc Warden": 0.486
        },
        {
            "name": "Winter Wyvern",
            "Arc Warden": 0.076
        },
        {
            "name": "Witch Doctor",
            "Arc Warden": 0.742
        },
        {
            "name": "Wraith King",
            "Arc Warden": 0.2
        },
        {
            "name": "Zeus",
            "Arc Warden": -0.868
        }
    ],
    "Bloodseeker": [
        {
            "name": "Abaddon",
            "Bloodseeker": 0.2
        },
        {
            "name": "Alchemist",
            "Bloodseeker": -0.259
        },
        {
            "name": "Ancient Apparition",
            "Bloodseeker": 2.312
        },
        {
            "name": "Anti-Mage",
            "Bloodseeker": -1.901
        },
        {
            "name": "Arc Warden",
            "Bloodseeker": 0.689
        },
        {
            "name": "Axe",
            "Bloodseeker": -1.305
        },
        {
            "name": "Bane",
            "Bloodseeker": -0.126
        },
        {
            "name": "Batrider",
            "Bloodseeker": -0.615
        },
        {
            "name": "Beastmaster",
            "Bloodseeker": 0.66
        },
        {
            "name": "Bounty Hunter",
            "Bloodseeker": 0.421
        },
        {
            "name": "Brewmaster",
            "Bloodseeker": 0.75
        },
        {
            "name": "Bristleback",
            "Bloodseeker": 2.488
        },
        {
            "name": "Broodmother",
            "Bloodseeker": 1.123
        },
        {
            "name": "Centaur Warrunner",
            "Bloodseeker": -2.3
        },
        {
            "name": "Chaos Knight",
            "Bloodseeker": 0.418
        },
        {
            "name": "Chen",
            "Bloodseeker": 3.199
        },
        {
            "name": "Clinkz",
            "Bloodseeker": 0.777
        },
        {
            "name": "Clockwerk",
            "Bloodseeker": -0.001
        },
        {
            "name": "Crystal Maiden",
            "Bloodseeker": 0.984
        },
        {
            "name": "Dark Seer",
            "Bloodseeker": -3.059
        },
        {
            "name": "Dark Willow",
            "Bloodseeker": 0.676
        },
        {
            "name": "Dawnbreaker",
            "Bloodseeker": -0.98
        },
        {
            "name": "Dazzle",
            "Bloodseeker": 0.309
        },
        {
            "name": "Death Prophet",
            "Bloodseeker": -0.52
        },
        {
            "name": "Disruptor",
            "Bloodseeker": -1.243
        },
        {
            "name": "Doom",
            "Bloodseeker": 1.411
        },
        {
            "name": "Dragon Knight",
            "Bloodseeker": -0.832
        },
        {
            "name": "Drow Ranger",
            "Bloodseeker": -1.242
        },
        {
            "name": "Earth Spirit",
            "Bloodseeker": -1.184
        },
        {
            "name": "Earthshaker",
            "Bloodseeker": -0.976
        },
        {
            "name": "Elder Titan",
            "Bloodseeker": 0.267
        },
        {
            "name": "Ember Spirit",
            "Bloodseeker": 2.362
        },
        {
            "name": "Enchantress",
            "Bloodseeker": 2.619
        },
        {
            "name": "Enigma",
            "Bloodseeker": 0.253
        },
        {
            "name": "Faceless Void",
            "Bloodseeker": 1.152
        },
        {
            "name": "Grimstroke",
            "Bloodseeker": 0.007
        },
        {
            "name": "Gyrocopter",
            "Bloodseeker": -1.191
        },
        {
            "name": "Hoodwink",
            "Bloodseeker": -0.287
        },
        {
            "name": "Huskar",
            "Bloodseeker": -1.318
        },
        {
            "name": "Invoker",
            "Bloodseeker": -0.19
        },
        {
            "name": "Io",
            "Bloodseeker": 2.328
        },
        {
            "name": "Jakiro",
            "Bloodseeker": -0.287
        },
        {
            "name": "Juggernaut",
            "Bloodseeker": 3.713
        },
        {
            "name": "Keeper of the Light",
            "Bloodseeker": 2.268
        },
        {
            "name": "Kez",
            "Bloodseeker": -0.017
        },
        {
            "name": "Kunkka",
            "Bloodseeker": -0.63
        },
        {
            "name": "Legion Commander",
            "Bloodseeker": 2.745
        },
        {
            "name": "Leshrac",
            "Bloodseeker": -0.715
        },
        {
            "name": "Lich",
            "Bloodseeker": -0.816
        },
        {
            "name": "Lifestealer",
            "Bloodseeker": 2.096
        },
        {
            "name": "Lina",
            "Bloodseeker": -0.384
        },
        {
            "name": "Lion",
            "Bloodseeker": -0.088
        },
        {
            "name": "Lone Druid",
            "Bloodseeker": -1.328
        },
        {
            "name": "Luna",
            "Bloodseeker": 1.114
        },
        {
            "name": "Lycan",
            "Bloodseeker": -1.671
        },
        {
            "name": "Magnus",
            "Bloodseeker": -0.991
        },
        {
            "name": "Marci",
            "Bloodseeker": -0.589
        },
        {
            "name": "Mars",
            "Bloodseeker": -2.131
        },
        {
            "name": "Medusa",
            "Bloodseeker": 6.685
        },
        {
            "name": "Meepo",
            "Bloodseeker": -0.48
        },
        {
            "name": "Mirana",
            "Bloodseeker": 0.172
        },
        {
            "name": "Monkey King",
            "Bloodseeker": 1.513
        },
        {
            "name": "Morphling",
            "Bloodseeker": 1.258
        },
        {
            "name": "Muerta",
            "Bloodseeker": -0.691
        },
        {
            "name": "Naga Siren",
            "Bloodseeker": -0.184
        },
        {
            "name": "Nature's Prophet",
            "Bloodseeker": 3.368
        },
        {
            "name": "Necrophos",
            "Bloodseeker": -0.557
        },
        {
            "name": "Night Stalker",
            "Bloodseeker": -2.357
        },
        {
            "name": "Nyx Assassin",
            "Bloodseeker": -0.228
        },
        {
            "name": "Ogre Magi",
            "Bloodseeker": 0.114
        },
        {
            "name": "Omniknight",
            "Bloodseeker": -0.21
        },
        {
            "name": "Oracle",
            "Bloodseeker": 2.797
        },
        {
            "name": "Outworld Destroyer",
            "Bloodseeker": -0.442
        },
        {
            "name": "Pangolier",
            "Bloodseeker": -4.01
        },
        {
            "name": "Phantom Assassin",
            "Bloodseeker": -0.439
        },
        {
            "name": "Phantom Lancer",
            "Bloodseeker": -3.409
        },
        {
            "name": "Phoenix",
            "Bloodseeker": -2.549
        },
        {
            "name": "Primal Beast",
            "Bloodseeker": -0.867
        },
        {
            "name": "Puck",
            "Bloodseeker": 0.431
        },
        {
            "name": "Pudge",
            "Bloodseeker": 0.415
        },
        {
            "name": "Pugna",
            "Bloodseeker": -0.68
        },
        {
            "name": "Queen of Pain",
            "Bloodseeker": 0.306
        },
        {
            "name": "Razor",
            "Bloodseeker": -2.178
        },
        {
            "name": "Riki",
            "Bloodseeker": -2.197
        },
        {
            "name": "Ringmaster",
            "Bloodseeker": -1.894
        },
        {
            "name": "Rubick",
            "Bloodseeker": 0.054
        },
        {
            "name": "Sand King",
            "Bloodseeker": -1.864
        },
        {
            "name": "Shadow Demon",
            "Bloodseeker": -0.005
        },
        {
            "name": "Shadow Fiend",
            "Bloodseeker": 1.478
        },
        {
            "name": "Shadow Shaman",
            "Bloodseeker": -0.177
        },
        {
            "name": "Silencer",
            "Bloodseeker": -0.365
        },
        {
            "name": "Skywrath Mage",
            "Bloodseeker": 1.588
        },
        {
            "name": "Slardar",
            "Bloodseeker": 0.641
        },
        {
            "name": "Slark",
            "Bloodseeker": -3.035
        },
        {
            "name": "Snapfire",
            "Bloodseeker": -0.215
        },
        {
            "name": "Sniper",
            "Bloodseeker": 1.059
        },
        {
            "name": "Spectre",
            "Bloodseeker": -0.272
        },
        {
            "name": "Spirit Breaker",
            "Bloodseeker": 0.532
        },
        {
            "name": "Storm Spirit",
            "Bloodseeker": 3.068
        },
        {
            "name": "Sven",
            "Bloodseeker": 0.785
        },
        {
            "name": "Techies",
            "Bloodseeker": 1.424
        },
        {
            "name": "Templar Assassin",
            "Bloodseeker": 0.415
        },
        {
            "name": "Terrorblade",
            "Bloodseeker": 0.177
        },
        {
            "name": "Tidehunter",
            "Bloodseeker": -2.267
        },
        {
            "name": "Timbersaw",
            "Bloodseeker": -2.117
        },
        {
            "name": "Tinker",
            "Bloodseeker": 1.049
        },
        {
            "name": "Tiny",
            "Bloodseeker": -1.903
        },
        {
            "name": "Treant Protector",
            "Bloodseeker": 0.441
        },
        {
            "name": "Troll Warlord",
            "Bloodseeker": 1.074
        },
        {
            "name": "Tusk",
            "Bloodseeker": 1.001
        },
        {
            "name": "Underlord",
            "Bloodseeker": -0.221
        },
        {
            "name": "Undying",
            "Bloodseeker": -0.434
        },
        {
            "name": "Ursa",
            "Bloodseeker": 1.024
        },
        {
            "name": "Vengeful Spirit",
            "Bloodseeker": -0.123
        },
        {
            "name": "Venomancer",
            "Bloodseeker": 0.497
        },
        {
            "name": "Viper",
            "Bloodseeker": 2.116
        },
        {
            "name": "Visage",
            "Bloodseeker": 0.002
        },
        {
            "name": "Void Spirit",
            "Bloodseeker": -1.008
        },
        {
            "name": "Warlock",
            "Bloodseeker": 0.516
        },
        {
            "name": "Weaver",
            "Bloodseeker": 0.671
        },
        {
            "name": "Windranger",
            "Bloodseeker": -0.433
        },
        {
            "name": "Winter Wyvern",
            "Bloodseeker": -0.282
        },
        {
            "name": "Witch Doctor",
            "Bloodseeker": 2.487
        },
        {
            "name": "Wraith King",
            "Bloodseeker": 0.205
        },
        {
            "name": "Zeus",
            "Bloodseeker": 0.155
        }
    ],
    "Bounty Hunter": [
        {
            "name": "Abaddon",
            "Bounty Hunter": 2.218
        },
        {
            "name": "Alchemist",
            "Bounty Hunter": -0.916
        },
        {
            "name": "Ancient Apparition",
            "Bounty Hunter": -0.885
        },
        {
            "name": "Anti-Mage",
            "Bounty Hunter": 1.052
        },
        {
            "name": "Arc Warden",
            "Bounty Hunter": 1.76
        },
        {
            "name": "Axe",
            "Bounty Hunter": 0.014
        },
        {
            "name": "Bane",
            "Bounty Hunter": -0.498
        },
        {
            "name": "Batrider",
            "Bounty Hunter": 1.372
        },
        {
            "name": "Beastmaster",
            "Bounty Hunter": -0.208
        },
        {
            "name": "Bloodseeker",
            "Bounty Hunter": -0.485
        },
        {
            "name": "Brewmaster",
            "Bounty Hunter": -0.685
        },
        {
            "name": "Bristleback",
            "Bounty Hunter": -1.452
        },
        {
            "name": "Broodmother",
            "Bounty Hunter": -0.976
        },
        {
            "name": "Centaur Warrunner",
            "Bounty Hunter": -0.983
        },
        {
            "name": "Chaos Knight",
            "Bounty Hunter": 1.586
        },
        {
            "name": "Chen",
            "Bounty Hunter": 1.556
        },
        {
            "name": "Clinkz",
            "Bounty Hunter": -1.762
        },
        {
            "name": "Clockwerk",
            "Bounty Hunter": 0.206
        },
        {
            "name": "Crystal Maiden",
            "Bounty Hunter": 1.248
        },
        {
            "name": "Dark Seer",
            "Bounty Hunter": 0.136
        },
        {
            "name": "Dark Willow",
            "Bounty Hunter": 0.433
        },
        {
            "name": "Dawnbreaker",
            "Bounty Hunter": -1.116
        },
        {
            "name": "Dazzle",
            "Bounty Hunter": 0.469
        },
        {
            "name": "Death Prophet",
            "Bounty Hunter": -0.377
        },
        {
            "name": "Disruptor",
            "Bounty Hunter": -0.588
        },
        {
            "name": "Doom",
            "Bounty Hunter": -0.034
        },
        {
            "name": "Dragon Knight",
            "Bounty Hunter": -1.388
        },
        {
            "name": "Drow Ranger",
            "Bounty Hunter": -0.259
        },
        {
            "name": "Earth Spirit",
            "Bounty Hunter": -0.842
        },
        {
            "name": "Earthshaker",
            "Bounty Hunter": 0.757
        },
        {
            "name": "Elder Titan",
            "Bounty Hunter": -0.66
        },
        {
            "name": "Ember Spirit",
            "Bounty Hunter": -0.019
        },
        {
            "name": "Enchantress",
            "Bounty Hunter": -0.8
        },
        {
            "name": "Enigma",
            "Bounty Hunter": 0.787
        },
        {
            "name": "Faceless Void",
            "Bounty Hunter": 0.353
        },
        {
            "name": "Grimstroke",
            "Bounty Hunter": -0.079
        },
        {
            "name": "Gyrocopter",
            "Bounty Hunter": -0.971
        },
        {
            "name": "Hoodwink",
            "Bounty Hunter": -0.192
        },
        {
            "name": "Huskar",
            "Bounty Hunter": 1.611
        },
        {
            "name": "Invoker",
            "Bounty Hunter": -0.973
        },
        {
            "name": "Io",
            "Bounty Hunter": 0.447
        },
        {
            "name": "Jakiro",
            "Bounty Hunter": -0.352
        },
        {
            "name": "Juggernaut",
            "Bounty Hunter": 1.096
        },
        {
            "name": "Keeper of the Light",
            "Bounty Hunter": -0.098
        },
        {
            "name": "Kez",
            "Bounty Hunter": 0.68
        },
        {
            "name": "Kunkka",
            "Bounty Hunter": -0.683
        },
        {
            "name": "Legion Commander",
            "Bounty Hunter": 1.791
        },
        {
            "name": "Leshrac",
            "Bounty Hunter": -0.462
        },
        {
            "name": "Lich",
            "Bounty Hunter": -0.981
        },
        {
            "name": "Lifestealer",
            "Bounty Hunter": -0.325
        },
        {
            "name": "Lina",
            "Bounty Hunter": -1.145
        },
        {
            "name": "Lion",
            "Bounty Hunter": 0.868
        },
        {
            "name": "Lone Druid",
            "Bounty Hunter": -0.577
        },
        {
            "name": "Luna",
            "Bounty Hunter": 1.104
        },
        {
            "name": "Lycan",
            "Bounty Hunter": -1.898
        },
        {
            "name": "Magnus",
            "Bounty Hunter": 0.946
        },
        {
            "name": "Marci",
            "Bounty Hunter": 0.843
        },
        {
            "name": "Mars",
            "Bounty Hunter": -0.709
        },
        {
            "name": "Medusa",
            "Bounty Hunter": 0.136
        },
        {
            "name": "Meepo",
            "Bounty Hunter": 2.091
        },
        {
            "name": "Mirana",
            "Bounty Hunter": -1.104
        },
        {
            "name": "Monkey King",
            "Bounty Hunter": -0.304
        },
        {
            "name": "Morphling",
            "Bounty Hunter": -0.534
        },
        {
            "name": "Muerta",
            "Bounty Hunter": -0.543
        },
        {
            "name": "Naga Siren",
            "Bounty Hunter": 3.57
        },
        {
            "name": "Nature's Prophet",
            "Bounty Hunter": 0.677
        },
        {
            "name": "Necrophos",
            "Bounty Hunter": 0.523
        },
        {
            "name": "Night Stalker",
            "Bounty Hunter": -1.773
        },
        {
            "name": "Nyx Assassin",
            "Bounty Hunter": 0.036
        },
        {
            "name": "Ogre Magi",
            "Bounty Hunter": 0.245
        },
        {
            "name": "Omniknight",
            "Bounty Hunter": 0.209
        },
        {
            "name": "Oracle",
            "Bounty Hunter": 0.812
        },
        {
            "name": "Outworld Destroyer",
            "Bounty Hunter": -0.3
        },
        {
            "name": "Pangolier",
            "Bounty Hunter": -1.059
        },
        {
            "name": "Phantom Assassin",
            "Bounty Hunter": -1.264
        },
        {
            "name": "Phantom Lancer",
            "Bounty Hunter": 1.886
        },
        {
            "name": "Phoenix",
            "Bounty Hunter": 0.308
        },
        {
            "name": "Primal Beast",
            "Bounty Hunter": 0.672
        },
        {
            "name": "Puck",
            "Bounty Hunter": -0.337
        },
        {
            "name": "Pudge",
            "Bounty Hunter": -0.024
        },
        {
            "name": "Pugna",
            "Bounty Hunter": -0.918
        },
        {
            "name": "Queen of Pain",
            "Bounty Hunter": 0.048
        },
        {
            "name": "Razor",
            "Bounty Hunter": 0.67
        },
        {
            "name": "Riki",
            "Bounty Hunter": -0.989
        },
        {
            "name": "Ringmaster",
            "Bounty Hunter": -0.483
        },
        {
            "name": "Rubick",
            "Bounty Hunter": 0.107
        },
        {
            "name": "Sand King",
            "Bounty Hunter": -1.425
        },
        {
            "name": "Shadow Demon",
            "Bounty Hunter": -1.591
        },
        {
            "name": "Shadow Fiend",
            "Bounty Hunter": -0.503
        },
        {
            "name": "Shadow Shaman",
            "Bounty Hunter": 2.259
        },
        {
            "name": "Silencer",
            "Bounty Hunter": -0.656
        },
        {
            "name": "Skywrath Mage",
            "Bounty Hunter": 0.451
        },
        {
            "name": "Slardar",
            "Bounty Hunter": 0.832
        },
        {
            "name": "Slark",
            "Bounty Hunter": 2.65
        },
        {
            "name": "Snapfire",
            "Bounty Hunter": 0.506
        },
        {
            "name": "Sniper",
            "Bounty Hunter": -0.372
        },
        {
            "name": "Spectre",
            "Bounty Hunter": -0.519
        },
        {
            "name": "Spirit Breaker",
            "Bounty Hunter": 0.577
        },
        {
            "name": "Storm Spirit",
            "Bounty Hunter": 1.512
        },
        {
            "name": "Sven",
            "Bounty Hunter": -0.478
        },
        {
            "name": "Techies",
            "Bounty Hunter": 0.986
        },
        {
            "name": "Templar Assassin",
            "Bounty Hunter": -0.317
        },
        {
            "name": "Terrorblade",
            "Bounty Hunter": 0.055
        },
        {
            "name": "Tidehunter",
            "Bounty Hunter": 1.862
        },
        {
            "name": "Timbersaw",
            "Bounty Hunter": -0.861
        },
        {
            "name": "Tinker",
            "Bounty Hunter": 1.24
        },
        {
            "name": "Tiny",
            "Bounty Hunter": -0.168
        },
        {
            "name": "Treant Protector",
            "Bounty Hunter": 1.375
        },
        {
            "name": "Troll Warlord",
            "Bounty Hunter": 1.383
        },
        {
            "name": "Tusk",
            "Bounty Hunter": 0.72
        },
        {
            "name": "Underlord",
            "Bounty Hunter": 0.624
        },
        {
            "name": "Undying",
            "Bounty Hunter": -1.111
        },
        {
            "name": "Ursa",
            "Bounty Hunter": 0.106
        },
        {
            "name": "Vengeful Spirit",
            "Bounty Hunter": -0.406
        },
        {
            "name": "Venomancer",
            "Bounty Hunter": 0.763
        },
        {
            "name": "Viper",
            "Bounty Hunter": 0.943
        },
        {
            "name": "Visage",
            "Bounty Hunter": -0.113
        },
        {
            "name": "Void Spirit",
            "Bounty Hunter": 0.359
        },
        {
            "name": "Warlock",
            "Bounty Hunter": -1.654
        },
        {
            "name": "Weaver",
            "Bounty Hunter": -1.194
        },
        {
            "name": "Windranger",
            "Bounty Hunter": 0.39
        },
        {
            "name": "Winter Wyvern",
            "Bounty Hunter": -1.221
        },
        {
            "name": "Witch Doctor",
            "Bounty Hunter": -0.599
        },
        {
            "name": "Wraith King",
            "Bounty Hunter": -0.969
        },
        {
            "name": "Zeus",
            "Bounty Hunter": 0.311
        }
    ],
    "Clinkz": [
        {
            "name": "Abaddon",
            "Clinkz": 1.909
        },
        {
            "name": "Alchemist",
            "Clinkz": -1.652
        },
        {
            "name": "Ancient Apparition",
            "Clinkz": -0.645
        },
        {
            "name": "Anti-Mage",
            "Clinkz": 0.304
        },
        {
            "name": "Arc Warden",
            "Clinkz": 2.394
        },
        {
            "name": "Axe",
            "Clinkz": 1.915
        },
        {
            "name": "Bane",
            "Clinkz": -1.353
        },
        {
            "name": "Batrider",
            "Clinkz": -0.496
        },
        {
            "name": "Beastmaster",
            "Clinkz": -2.831
        },
        {
            "name": "Bloodseeker",
            "Clinkz": -0.754
        },
        {
            "name": "Bounty Hunter",
            "Clinkz": 1.857
        },
        {
            "name": "Brewmaster",
            "Clinkz": -1.786
        },
        {
            "name": "Bristleback",
            "Clinkz": 3.08
        },
        {
            "name": "Broodmother",
            "Clinkz": 2.914
        },
        {
            "name": "Centaur Warrunner",
            "Clinkz": 0.253
        },
        {
            "name": "Chaos Knight",
            "Clinkz": 2.939
        },
        {
            "name": "Chen",
            "Clinkz": 0.621
        },
        {
            "name": "Clockwerk",
            "Clinkz": -0.404
        },
        {
            "name": "Crystal Maiden",
            "Clinkz": 0.812
        },
        {
            "name": "Dark Seer",
            "Clinkz": -0.715
        },
        {
            "name": "Dark Willow",
            "Clinkz": 0.27
        },
        {
            "name": "Dawnbreaker",
            "Clinkz": -1.639
        },
        {
            "name": "Dazzle",
            "Clinkz": 1.079
        },
        {
            "name": "Death Prophet",
            "Clinkz": -2.59
        },
        {
            "name": "Disruptor",
            "Clinkz": -1.124
        },
        {
            "name": "Doom",
            "Clinkz": -1.005
        },
        {
            "name": "Dragon Knight",
            "Clinkz": 1.085
        },
        {
            "name": "Drow Ranger",
            "Clinkz": 1.309
        },
        {
            "name": "Earth Spirit",
            "Clinkz": 0.545
        },
        {
            "name": "Earthshaker",
            "Clinkz": -1.96
        },
        {
            "name": "Elder Titan",
            "Clinkz": -4.28
        },
        {
            "name": "Ember Spirit",
            "Clinkz": -1.523
        },
        {
            "name": "Enchantress",
            "Clinkz": 2.13
        },
        {
            "name": "Enigma",
            "Clinkz": -2.105
        },
        {
            "name": "Faceless Void",
            "Clinkz": 0.223
        },
        {
            "name": "Grimstroke",
            "Clinkz": -1.377
        },
        {
            "name": "Gyrocopter",
            "Clinkz": -1.135
        },
        {
            "name": "Hoodwink",
            "Clinkz": -0.289
        },
        {
            "name": "Huskar",
            "Clinkz": -1.223
        },
        {
            "name": "Invoker",
            "Clinkz": -0.265
        },
        {
            "name": "Io",
            "Clinkz": 0.586
        },
        {
            "name": "Jakiro",
            "Clinkz": -0.348
        },
        {
            "name": "Juggernaut",
            "Clinkz": -2.954
        },
        {
            "name": "Keeper of the Light",
            "Clinkz": -1.727
        },
        {
            "name": "Kez",
            "Clinkz": -0.678
        },
        {
            "name": "Kunkka",
            "Clinkz": -0.959
        },
        {
            "name": "Legion Commander",
            "Clinkz": 2.206
        },
        {
            "name": "Leshrac",
            "Clinkz": -2.126
        },
        {
            "name": "Lich",
            "Clinkz": -0.763
        },
        {
            "name": "Lifestealer",
            "Clinkz": -3.647
        },
        {
            "name": "Lina",
            "Clinkz": -2.592
        },
        {
            "name": "Lion",
            "Clinkz": 0.158
        },
        {
            "name": "Lone Druid",
            "Clinkz": -1.759
        },
        {
            "name": "Luna",
            "Clinkz": 1.7
        },
        {
            "name": "Lycan",
            "Clinkz": -0.5
        },
        {
            "name": "Magnus",
            "Clinkz": -0.503
        },
        {
            "name": "Marci",
            "Clinkz": 0.23
        },
        {
            "name": "Mars",
            "Clinkz": 0.815
        },
        {
            "name": "Medusa",
            "Clinkz": 0.091
        },
        {
            "name": "Meepo",
            "Clinkz": 1.539
        },
        {
            "name": "Mirana",
            "Clinkz": 1.139
        },
        {
            "name": "Monkey King",
            "Clinkz": -0.874
        },
        {
            "name": "Morphling",
            "Clinkz": 1.872
        },
        {
            "name": "Muerta",
            "Clinkz": -0.305
        },
        {
            "name": "Naga Siren",
            "Clinkz": 5.409
        },
        {
            "name": "Nature's Prophet",
            "Clinkz": 0.854
        },
        {
            "name": "Necrophos",
            "Clinkz": -1.166
        },
        {
            "name": "Night Stalker",
            "Clinkz": -1.426
        },
        {
            "name": "Nyx Assassin",
            "Clinkz": 0.063
        },
        {
            "name": "Ogre Magi",
            "Clinkz": 0.627
        },
        {
            "name": "Omniknight",
            "Clinkz": -1.81
        },
        {
            "name": "Oracle",
            "Clinkz": -1.05
        },
        {
            "name": "Outworld Destroyer",
            "Clinkz": -1.023
        },
        {
            "name": "Pangolier",
            "Clinkz": -1.346
        },
        {
            "name": "Phantom Assassin",
            "Clinkz": 2.053
        },
        {
            "name": "Phantom Lancer",
            "Clinkz": 3.772
        },
        {
            "name": "Phoenix",
            "Clinkz": -2.801
        },
        {
            "name": "Primal Beast",
            "Clinkz": 0.232
        },
        {
            "name": "Puck",
            "Clinkz": -0.381
        },
        {
            "name": "Pudge",
            "Clinkz": 0.942
        },
        {
            "name": "Pugna",
            "Clinkz": -0.69
        },
        {
            "name": "Queen of Pain",
            "Clinkz": -0.729
        },
        {
            "name": "Razor",
            "Clinkz": 1.821
        },
        {
            "name": "Riki",
            "Clinkz": 2.195
        },
        {
            "name": "Ringmaster",
            "Clinkz": 0.134
        },
        {
            "name": "Rubick",
            "Clinkz": -1.55
        },
        {
            "name": "Sand King",
            "Clinkz": -0.956
        },
        {
            "name": "Shadow Demon",
            "Clinkz": -0.213
        },
        {
            "name": "Shadow Fiend",
            "Clinkz": 0.107
        },
        {
            "name": "Shadow Shaman",
            "Clinkz": 0.521
        },
        {
            "name": "Silencer",
            "Clinkz": 0.76
        },
        {
            "name": "Skywrath Mage",
            "Clinkz": -0.036
        },
        {
            "name": "Slardar",
            "Clinkz": 2.132
        },
        {
            "name": "Slark",
            "Clinkz": 1.249
        },
        {
            "name": "Snapfire",
            "Clinkz": -0.847
        },
        {
            "name": "Sniper",
            "Clinkz": 0.328
        },
        {
            "name": "Spectre",
            "Clinkz": 3.14
        },
        {
            "name": "Spirit Breaker",
            "Clinkz": 1.169
        },
        {
            "name": "Storm Spirit",
            "Clinkz": -0.259
        },
        {
            "name": "Sven",
            "Clinkz": -0.905
        },
        {
            "name": "Techies",
            "Clinkz": 0.766
        },
        {
            "name": "Templar Assassin",
            "Clinkz": -1.582
        },
        {
            "name": "Terrorblade",
            "Clinkz": 1.19
        },
        {
            "name": "Tidehunter",
            "Clinkz": -1.528
        },
        {
            "name": "Timbersaw",
            "Clinkz": -1.197
        },
        {
            "name": "Tinker",
            "Clinkz": -0.817
        },
        {
            "name": "Tiny",
            "Clinkz": 0.635
        },
        {
            "name": "Treant Protector",
            "Clinkz": 0.052
        },
        {
            "name": "Troll Warlord",
            "Clinkz": 0.351
        },
        {
            "name": "Tusk",
            "Clinkz": 0.29
        },
        {
            "name": "Underlord",
            "Clinkz": -0.267
        },
        {
            "name": "Undying",
            "Clinkz": -1.615
        },
        {
            "name": "Ursa",
            "Clinkz": -2.839
        },
        {
            "name": "Vengeful Spirit",
            "Clinkz": 0.66
        },
        {
            "name": "Venomancer",
            "Clinkz": -0.581
        },
        {
            "name": "Viper",
            "Clinkz": -1.281
        },
        {
            "name": "Visage",
            "Clinkz": -1.126
        },
        {
            "name": "Void Spirit",
            "Clinkz": 1.809
        },
        {
            "name": "Warlock",
            "Clinkz": -0.518
        },
        {
            "name": "Weaver",
            "Clinkz": -1.034
        },
        {
            "name": "Windranger",
            "Clinkz": 0.943
        },
        {
            "name": "Winter Wyvern",
            "Clinkz": 1.458
        },
        {
            "name": "Witch Doctor",
            "Clinkz": -0.737
        },
        {
            "name": "Wraith King",
            "Clinkz": 1.824
        },
        {
            "name": "Zeus",
            "Clinkz": -0.542
        }
    ],
    "Drow Ranger": [
        {
            "name": "Abaddon",
            "Drow Ranger": -1.29
        },
        {
            "name": "Alchemist",
            "Drow Ranger": -0.189
        },
        {
            "name": "Ancient Apparition",
            "Drow Ranger": -0.796
        },
        {
            "name": "Anti-Mage",
            "Drow Ranger": -1.044
        },
        {
            "name": "Arc Warden",
            "Drow Ranger": 1.572
        },
        {
            "name": "Axe",
            "Drow Ranger": 0.242
        },
        {
            "name": "Bane",
            "Drow Ranger": -0.604
        },
        {
            "name": "Batrider",
            "Drow Ranger": 2.156
        },
        {
            "name": "Beastmaster",
            "Drow Ranger": -0.475
        },
        {
            "name": "Bloodseeker",
            "Drow Ranger": 0.868
        },
        {
            "name": "Bounty Hunter",
            "Drow Ranger": 0.223
        },
        {
            "name": "Brewmaster",
            "Drow Ranger": 0.64
        },
        {
            "name": "Bristleback",
            "Drow Ranger": -2.749
        },
        {
            "name": "Broodmother",
            "Drow Ranger": 0.663
        },
        {
            "name": "Centaur Warrunner",
            "Drow Ranger": 2.344
        },
        {
            "name": "Chaos Knight",
            "Drow Ranger": 2.473
        },
        {
            "name": "Chen",
            "Drow Ranger": 1.086
        },
        {
            "name": "Clinkz",
            "Drow Ranger": -1.146
        },
        {
            "name": "Clockwerk",
            "Drow Ranger": 1.526
        },
        {
            "name": "Crystal Maiden",
            "Drow Ranger": -1.509
        },
        {
            "name": "Dark Seer",
            "Drow Ranger": 2.094
        },
        {
            "name": "Dark Willow",
            "Drow Ranger": 0.574
        },
        {
            "name": "Dawnbreaker",
            "Drow Ranger": -1.051
        },
        {
            "name": "Dazzle",
            "Drow Ranger": -0.481
        },
        {
            "name": "Death Prophet",
            "Drow Ranger": -1.362
        },
        {
            "name": "Disruptor",
            "Drow Ranger": -1.2
        },
        {
            "name": "Doom",
            "Drow Ranger": -0.528
        },
        {
            "name": "Dragon Knight",
            "Drow Ranger": 1.462
        },
        {
            "name": "Earth Spirit",
            "Drow Ranger": 2.693
        },
        {
            "name": "Earthshaker",
            "Drow Ranger": -0.944
        },
        {
            "name": "Elder Titan",
            "Drow Ranger": 0.476
        },
        {
            "name": "Ember Spirit",
            "Drow Ranger": 0.067
        },
        {
            "name": "Enchantress",
            "Drow Ranger": 0.757
        },
        {
            "name": "Enigma",
            "Drow Ranger": 0.161
        },
        {
            "name": "Faceless Void",
            "Drow Ranger": 2.155
        },
        {
            "name": "Grimstroke",
            "Drow Ranger": -0.42
        },
        {
            "name": "Gyrocopter",
            "Drow Ranger": 0.144
        },
        {
            "name": "Hoodwink",
            "Drow Ranger": 0.325
        },
        {
            "name": "Huskar",
            "Drow Ranger": -1.105
        },
        {
            "name": "Invoker",
            "Drow Ranger": 0.595
        },
        {
            "name": "Io",
            "Drow Ranger": -0.818
        },
        {
            "name": "Jakiro",
            "Drow Ranger": -0.653
        },
        {
            "name": "Juggernaut",
            "Drow Ranger": -0.791
        },
        {
            "name": "Keeper of the Light",
            "Drow Ranger": 0.786
        },
        {
            "name": "Kez",
            "Drow Ranger": -1.766
        },
        {
            "name": "Kunkka",
            "Drow Ranger": 1.098
        },
        {
            "name": "Legion Commander",
            "Drow Ranger": -0.904
        },
        {
            "name": "Leshrac",
            "Drow Ranger": -1.984
        },
        {
            "name": "Lich",
            "Drow Ranger": -0.984
        },
        {
            "name": "Lifestealer",
            "Drow Ranger": -1.316
        },
        {
            "name": "Lina",
            "Drow Ranger": -0.165
        },
        {
            "name": "Lion",
            "Drow Ranger": -1.041
        },
        {
            "name": "Lone Druid",
            "Drow Ranger": 0.606
        },
        {
            "name": "Luna",
            "Drow Ranger": 0.791
        },
        {
            "name": "Lycan",
            "Drow Ranger": 2.339
        },
        {
            "name": "Magnus",
            "Drow Ranger": 1.162
        },
        {
            "name": "Marci",
            "Drow Ranger": 0.326
        },
        {
            "name": "Mars",
            "Drow Ranger": 3.814
        },
        {
            "name": "Medusa",
            "Drow Ranger": -0.392
        },
        {
            "name": "Meepo",
            "Drow Ranger": -1.758
        },
        {
            "name": "Mirana",
            "Drow Ranger": 0.677
        },
        {
            "name": "Monkey King",
            "Drow Ranger": 0.862
        },
        {
            "name": "Morphling",
            "Drow Ranger": 0.618
        },
        {
            "name": "Muerta",
            "Drow Ranger": 0.891
        },
        {
            "name": "Naga Siren",
            "Drow Ranger": 2.199
        },
        {
            "name": "Nature's Prophet",
            "Drow Ranger": 1.789
        },
        {
            "name": "Necrophos",
            "Drow Ranger": -2.27
        },
        {
            "name": "Night Stalker",
            "Drow Ranger": 0.866
        },
        {
            "name": "Nyx Assassin",
            "Drow Ranger": 0.498
        },
        {
            "name": "Ogre Magi",
            "Drow Ranger": 1.261
        },
        {
            "name": "Omniknight",
            "Drow Ranger": 0.149
        },
        {
            "name": "Oracle",
            "Drow Ranger": -1.558
        },
        {
            "name": "Outworld Destroyer",
            "Drow Ranger": 0.656
        },
        {
            "name": "Pangolier",
            "Drow Ranger": -1.354
        },
        {
            "name": "Phantom Assassin",
            "Drow Ranger": -0.954
        },
        {
            "name": "Phantom Lancer",
            "Drow Ranger": 3.902
        },
        {
            "name": "Phoenix",
            "Drow Ranger": -0.681
        },
        {
            "name": "Primal Beast",
            "Drow Ranger": 2.103
        },
        {
            "name": "Puck",
            "Drow Ranger": 0.664
        },
        {
            "name": "Pudge",
            "Drow Ranger": 2.526
        },
        {
            "name": "Pugna",
            "Drow Ranger": -1.121
        },
        {
            "name": "Queen of Pain",
            "Drow Ranger": 0.272
        },
        {
            "name": "Razor",
            "Drow Ranger": -0.979
        },
        {
            "name": "Riki",
            "Drow Ranger": -2.175
        },
        {
            "name": "Ringmaster",
            "Drow Ranger": 0.961
        },
        {
            "name": "Rubick",
            "Drow Ranger": -0.493
        },
        {
            "name": "Sand King",
            "Drow Ranger": 1.028
        },
        {
            "name": "Shadow Demon",
            "Drow Ranger": 0.891
        },
        {
            "name": "Shadow Fiend",
            "Drow Ranger": -0.985
        },
        {
            "name": "Shadow Shaman",
            "Drow Ranger": -0.873
        },
        {
            "name": "Silencer",
            "Drow Ranger": 0.672
        },
        {
            "name": "Skywrath Mage",
            "Drow Ranger": 0.431
        },
        {
            "name": "Slardar",
            "Drow Ranger": -2.116
        },
        {
            "name": "Slark",
            "Drow Ranger": -0.716
        },
        {
            "name": "Snapfire",
            "Drow Ranger": 0.656
        },
        {
            "name": "Sniper",
            "Drow Ranger": 1.525
        },
        {
            "name": "Spectre",
            "Drow Ranger": 2.182
        },
        {
            "name": "Spirit Breaker",
            "Drow Ranger": 0.47
        },
        {
            "name": "Storm Spirit",
            "Drow Ranger": 2.161
        },
        {
            "name": "Sven",
            "Drow Ranger": 1.781
        },
        {
            "name": "Techies",
            "Drow Ranger": 0.246
        },
        {
            "name": "Templar Assassin",
            "Drow Ranger": 0.236
        },
        {
            "name": "Terrorblade",
            "Drow Ranger": -0.618
        },
        {
            "name": "Tidehunter",
            "Drow Ranger": 0.173
        },
        {
            "name": "Timbersaw",
            "Drow Ranger": 0.543
        },
        {
            "name": "Tinker",
            "Drow Ranger": 0.165
        },
        {
            "name": "Tiny",
            "Drow Ranger": 2.147
        },
        {
            "name": "Treant Protector",
            "Drow Ranger": 0.465
        },
        {
            "name": "Troll Warlord",
            "Drow Ranger": 0.224
        },
        {
            "name": "Tusk",
            "Drow Ranger": 0.308
        },
        {
            "name": "Underlord",
            "Drow Ranger": 0.627
        },
        {
            "name": "Undying",
            "Drow Ranger": 0.386
        },
        {
            "name": "Ursa",
            "Drow Ranger": -0.327
        },
        {
            "name": "Vengeful Spirit",
            "Drow Ranger": 0.077
        },
        {
            "name": "Venomancer",
            "Drow Ranger": 0.892
        },
        {
            "name": "Viper",
            "Drow Ranger": -1.291
        },
        {
            "name": "Visage",
            "Drow Ranger": -0.1
        },
        {
            "name": "Void Spirit",
            "Drow Ranger": 0.672
        },
        {
            "name": "Warlock",
            "Drow Ranger": -0.208
        },
        {
            "name": "Weaver",
            "Drow Ranger": -2.001
        },
        {
            "name": "Windranger",
            "Drow Ranger": 0.354
        },
        {
            "name": "Winter Wyvern",
            "Drow Ranger": 1.119
        },
        {
            "name": "Witch Doctor",
            "Drow Ranger": -1.161
        },
        {
            "name": "Wraith King",
            "Drow Ranger": 1.334
        },
        {
            "name": "Zeus",
            "Drow Ranger": 1.75
        }
    ],
    "Ember Spirit": [
        {
            "name": "Abaddon",
            "Ember Spirit": 0.332
        },
        {
            "name": "Alchemist",
            "Ember Spirit": 0.851
        },
        {
            "name": "Ancient Apparition",
            "Ember Spirit": -0.454
        },
        {
            "name": "Anti-Mage",
            "Ember Spirit": 0.126
        },
        {
            "name": "Arc Warden",
            "Ember Spirit": 0.891
        },
        {
            "name": "Axe",
            "Ember Spirit": -1.23
        },
        {
            "name": "Bane",
            "Ember Spirit": 2.517
        },
        {
            "name": "Batrider",
            "Ember Spirit": 0.141
        },
        {
            "name": "Beastmaster",
            "Ember Spirit": -1.772
        },
        {
            "name": "Bloodseeker",
            "Ember Spirit": -2.285
        },
        {
            "name": "Bounty Hunter",
            "Ember Spirit": 0.18
        },
        {
            "name": "Brewmaster",
            "Ember Spirit": 0.174
        },
        {
            "name": "Bristleback",
            "Ember Spirit": -2.192
        },
        {
            "name": "Broodmother",
            "Ember Spirit": 4.73
        },
        {
            "name": "Centaur Warrunner",
            "Ember Spirit": -2.743
        },
        {
            "name": "Chaos Knight",
            "Ember Spirit": -1.249
        },
        {
            "name": "Chen",
            "Ember Spirit": 2.516
        },
        {
            "name": "Clinkz",
            "Ember Spirit": 1.497
        },
        {
            "name": "Clockwerk",
            "Ember Spirit": -2.5
        },
        {
            "name": "Crystal Maiden",
            "Ember Spirit": 0.884
        },
        {
            "name": "Dark Seer",
            "Ember Spirit": -1.435
        },
        {
            "name": "Dark Willow",
            "Ember Spirit": 1.502
        },
        {
            "name": "Dawnbreaker",
            "Ember Spirit": -0.562
        },
        {
            "name": "Dazzle",
            "Ember Spirit": 1.594
        },
        {
            "name": "Death Prophet",
            "Ember Spirit": 0.983
        },
        {
            "name": "Disruptor",
            "Ember Spirit": -0.25
        },
        {
            "name": "Doom",
            "Ember Spirit": 0.188
        },
        {
            "name": "Dragon Knight",
            "Ember Spirit": 0.926
        },
        {
            "name": "Drow Ranger",
            "Ember Spirit": 0.333
        },
        {
            "name": "Earth Spirit",
            "Ember Spirit": -1.381
        },
        {
            "name": "Earthshaker",
            "Ember Spirit": 0.146
        },
        {
            "name": "Elder Titan",
            "Ember Spirit": 0.839
        },
        {
            "name": "Enchantress",
            "Ember Spirit": 0.526
        },
        {
            "name": "Enigma",
            "Ember Spirit": 0.725
        },
        {
            "name": "Faceless Void",
            "Ember Spirit": -0.379
        },
        {
            "name": "Grimstroke",
            "Ember Spirit": 0.968
        },
        {
            "name": "Gyrocopter",
            "Ember Spirit": -0.753
        },
        {
            "name": "Hoodwink",
            "Ember Spirit": 0.207
        },
        {
            "name": "Huskar",
            "Ember Spirit": 5.706
        },
        {
            "name": "Invoker",
            "Ember Spirit": 0.35
        },
        {
            "name": "Io",
            "Ember Spirit": -0.452
        },
        {
            "name": "Jakiro",
            "Ember Spirit": -0.18
        },
        {
            "name": "Juggernaut",
            "Ember Spirit": 1.726
        },
        {
            "name": "Keeper of the Light",
            "Ember Spirit": 0.313
        },
        {
            "name": "Kez",
            "Ember Spirit": 1.512
        },
        {
            "name": "Kunkka",
            "Ember Spirit": -1.929
        },
        {
            "name": "Legion Commander",
            "Ember Spirit": 2.374
        },
        {
            "name": "Leshrac",
            "Ember Spirit": -1.584
        },
        {
            "name": "Lich",
            "Ember Spirit": -0.72
        },
        {
            "name": "Lifestealer",
            "Ember Spirit": 0.288
        },
        {
            "name": "Lina",
            "Ember Spirit": -1.585
        },
        {
            "name": "Lion",
            "Ember Spirit": -0.279
        },
        {
            "name": "Lone Druid",
            "Ember Spirit": 4.413
        },
        {
            "name": "Luna",
            "Ember Spirit": -0.42
        },
        {
            "name": "Lycan",
            "Ember Spirit": 0.246
        },
        {
            "name": "Magnus",
            "Ember Spirit": -1.583
        },
        {
            "name": "Marci",
            "Ember Spirit": -0.172
        },
        {
            "name": "Mars",
            "Ember Spirit": -1.933
        },
        {
            "name": "Medusa",
            "Ember Spirit": -1.817
        },
        {
            "name": "Meepo",
            "Ember Spirit": 3.741
        },
        {
            "name": "Mirana",
            "Ember Spirit": -0.35
        },
        {
            "name": "Monkey King",
            "Ember Spirit": 3.876
        },
        {
            "name": "Morphling",
            "Ember Spirit": 2.526
        },
        {
            "name": "Muerta",
            "Ember Spirit": 2.197
        },
        {
            "name": "Naga Siren",
            "Ember Spirit": 2.573
        },
        {
            "name": "Nature's Prophet",
            "Ember Spirit": 1.45
        },
        {
            "name": "Necrophos",
            "Ember Spirit": 0.544
        },
        {
            "name": "Night Stalker",
            "Ember Spirit": 0.181
        },
        {
            "name": "Nyx Assassin",
            "Ember Spirit": -1.897
        },
        {
            "name": "Ogre Magi",
            "Ember Spirit": 0.159
        },
        {
            "name": "Omniknight",
            "Ember Spirit": 1.546
        },
        {
            "name": "Oracle",
            "Ember Spirit": 2.668
        },
        {
            "name": "Outworld Destroyer",
            "Ember Spirit": -0.501
        },
        {
            "name": "Pangolier",
            "Ember Spirit": -1.598
        },
        {
            "name": "Phantom Assassin",
            "Ember Spirit": 0.514
        },
        {
            "name": "Phantom Lancer",
            "Ember Spirit": -3.258
        },
        {
            "name": "Phoenix",
            "Ember Spirit": 0.12
        },
        {
            "name": "Primal Beast",
            "Ember Spirit": -1.207
        },
        {
            "name": "Puck",
            "Ember Spirit": 0.23
        },
        {
            "name": "Pudge",
            "Ember Spirit": -0.486
        },
        {
            "name": "Pugna",
            "Ember Spirit": 1.348
        },
        {
            "name": "Queen of Pain",
            "Ember Spirit": -3.996
        },
        {
            "name": "Razor",
            "Ember Spirit": -2.147
        },
        {
            "name": "Riki",
            "Ember Spirit": 1.829
        },
        {
            "name": "Ringmaster",
            "Ember Spirit": -0.954
        },
        {
            "name": "Rubick",
            "Ember Spirit": -1.703
        },
        {
            "name": "Sand King",
            "Ember Spirit": -2.089
        },
        {
            "name": "Shadow Demon",
            "Ember Spirit": -1.902
        },
        {
            "name": "Shadow Fiend",
            "Ember Spirit": 2.326
        },
        {
            "name": "Shadow Shaman",
            "Ember Spirit": 0.215
        },
        {
            "name": "Silencer",
            "Ember Spirit": 0.049
        },
        {
            "name": "Skywrath Mage",
            "Ember Spirit": 0.799
        },
        {
            "name": "Slardar",
            "Ember Spirit": 0.123
        },
        {
            "name": "Slark",
            "Ember Spirit": 4.431
        },
        {
            "name": "Snapfire",
            "Ember Spirit": 0.652
        },
        {
            "name": "Sniper",
            "Ember Spirit": 1.248
        },
        {
            "name": "Spectre",
            "Ember Spirit": -2.974
        },
        {
            "name": "Spirit Breaker",
            "Ember Spirit": -0.688
        },
        {
            "name": "Storm Spirit",
            "Ember Spirit": -2.366
        },
        {
            "name": "Sven",
            "Ember Spirit": 0.188
        },
        {
            "name": "Techies",
            "Ember Spirit": 1.36
        },
        {
            "name": "Templar Assassin",
            "Ember Spirit": 1.131
        },
        {
            "name": "Terrorblade",
            "Ember Spirit": -0.057
        },
        {
            "name": "Tidehunter",
            "Ember Spirit": 1.481
        },
        {
            "name": "Timbersaw",
            "Ember Spirit": -0.354
        },
        {
            "name": "Tinker",
            "Ember Spirit": 3.703
        },
        {
            "name": "Tiny",
            "Ember Spirit": -0.631
        },
        {
            "name": "Treant Protector",
            "Ember Spirit": 1.243
        },
        {
            "name": "Troll Warlord",
            "Ember Spirit": 4.233
        },
        {
            "name": "Tusk",
            "Ember Spirit": -1.937
        },
        {
            "name": "Underlord",
            "Ember Spirit": 1.065
        },
        {
            "name": "Undying",
            "Ember Spirit": -0.405
        },
        {
            "name": "Ursa",
            "Ember Spirit": 0.819
        },
        {
            "name": "Vengeful Spirit",
            "Ember Spirit": -0.547
        },
        {
            "name": "Venomancer",
            "Ember Spirit": -0.051
        },
        {
            "name": "Viper",
            "Ember Spirit": 0.751
        },
        {
            "name": "Visage",
            "Ember Spirit": 0.294
        },
        {
            "name": "Void Spirit",
            "Ember Spirit": -1.913
        },
        {
            "name": "Warlock",
            "Ember Spirit": -0.038
        },
        {
            "name": "Weaver",
            "Ember Spirit": 2.374
        },
        {
            "name": "Windranger",
            "Ember Spirit": 0.59
        },
        {
            "name": "Winter Wyvern",
            "Ember Spirit": 0.537
        },
        {
            "name": "Witch Doctor",
            "Ember Spirit": 0.171
        },
        {
            "name": "Wraith King",
            "Ember Spirit": -0.982
        },
        {
            "name": "Zeus",
            "Ember Spirit": -1.97
        }
    ],
    "Faceless Void": [
        {
            "name": "Abaddon",
            "Faceless Void": 0.572
        },
        {
            "name": "Alchemist",
            "Faceless Void": 0.155
        },
        {
            "name": "Ancient Apparition",
            "Faceless Void": -0.006
        },
        {
            "name": "Anti-Mage",
            "Faceless Void": 1.007
        },
        {
            "name": "Arc Warden",
            "Faceless Void": 0.234
        },
        {
            "name": "Axe",
            "Faceless Void": 1.086
        },
        {
            "name": "Bane",
            "Faceless Void": 0.177
        },
        {
            "name": "Batrider",
            "Faceless Void": -1.661
        },
        {
            "name": "Beastmaster",
            "Faceless Void": 1.147
        },
        {
            "name": "Bloodseeker",
            "Faceless Void": -1.196
        },
        {
            "name": "Bounty Hunter",
            "Faceless Void": -0.285
        },
        {
            "name": "Brewmaster",
            "Faceless Void": -2.095
        },
        {
            "name": "Bristleback",
            "Faceless Void": 0.464
        },
        {
            "name": "Broodmother",
            "Faceless Void": 0.438
        },
        {
            "name": "Centaur Warrunner",
            "Faceless Void": 0.134
        },
        {
            "name": "Chaos Knight",
            "Faceless Void": 1.924
        },
        {
            "name": "Chen",
            "Faceless Void": 0.949
        },
        {
            "name": "Clinkz",
            "Faceless Void": -0.336
        },
        {
            "name": "Clockwerk",
            "Faceless Void": -1.114
        },
        {
            "name": "Crystal Maiden",
            "Faceless Void": 0.133
        },
        {
            "name": "Dark Seer",
            "Faceless Void": -1.82
        },
        {
            "name": "Dark Willow",
            "Faceless Void": -0.755
        },
        {
            "name": "Dawnbreaker",
            "Faceless Void": -1.255
        },
        {
            "name": "Dazzle",
            "Faceless Void": -0.471
        },
        {
            "name": "Death Prophet",
            "Faceless Void": 0.002
        },
        {
            "name": "Disruptor",
            "Faceless Void": 0.609
        },
        {
            "name": "Doom",
            "Faceless Void": 0.695
        },
        {
            "name": "Dragon Knight",
            "Faceless Void": -0.538
        },
        {
            "name": "Drow Ranger",
            "Faceless Void": -1.83
        },
        {
            "name": "Earth Spirit",
            "Faceless Void": -1.72
        },
        {
            "name": "Earthshaker",
            "Faceless Void": 0.468
        },
        {
            "name": "Elder Titan",
            "Faceless Void": -0.075
        },
        {
            "name": "Ember Spirit",
            "Faceless Void": 0.344
        },
        {
            "name": "Enchantress",
            "Faceless Void": 2.433
        },
        {
            "name": "Enigma",
            "Faceless Void": -0.643
        },
        {
            "name": "Grimstroke",
            "Faceless Void": 0.107
        },
        {
            "name": "Gyrocopter",
            "Faceless Void": -1.005
        },
        {
            "name": "Hoodwink",
            "Faceless Void": 0.659
        },
        {
            "name": "Huskar",
            "Faceless Void": 1.732
        },
        {
            "name": "Invoker",
            "Faceless Void": -1.162
        },
        {
            "name": "Io",
            "Faceless Void": -1.609
        },
        {
            "name": "Jakiro",
            "Faceless Void": -0.429
        },
        {
            "name": "Juggernaut",
            "Faceless Void": -1.163
        },
        {
            "name": "Keeper of the Light",
            "Faceless Void": -2.412
        },
        {
            "name": "Kez",
            "Faceless Void": -2.679
        },
        {
            "name": "Kunkka",
            "Faceless Void": -1.639
        },
        {
            "name": "Legion Commander",
            "Faceless Void": 1.802
        },
        {
            "name": "Leshrac",
            "Faceless Void": 0.01
        },
        {
            "name": "Lich",
            "Faceless Void": -0.441
        },
        {
            "name": "Lifestealer",
            "Faceless Void": -1.514
        },
        {
            "name": "Lina",
            "Faceless Void": -1.16
        },
        {
            "name": "Lion",
            "Faceless Void": 1.307
        },
        {
            "name": "Lone Druid",
            "Faceless Void": 1.506
        },
        {
            "name": "Luna",
            "Faceless Void": 1.048
        },
        {
            "name": "Lycan",
            "Faceless Void": 0.245
        },
        {
            "name": "Magnus",
            "Faceless Void": -1.88
        },
        {
            "name": "Marci",
            "Faceless Void": -0.869
        },
        {
            "name": "Mars",
            "Faceless Void": -1.823
        },
        {
            "name": "Medusa",
            "Faceless Void": -0.221
        },
        {
            "name": "Meepo",
            "Faceless Void": 2.659
        },
        {
            "name": "Mirana",
            "Faceless Void": -0.165
        },
        {
            "name": "Monkey King",
            "Faceless Void": 0.546
        },
        {
            "name": "Morphling",
            "Faceless Void": 0.267
        },
        {
            "name": "Muerta",
            "Faceless Void": 0.691
        },
        {
            "name": "Naga Siren",
            "Faceless Void": 1.734
        },
        {
            "name": "Nature's Prophet",
            "Faceless Void": 0.94
        },
        {
            "name": "Necrophos",
            "Faceless Void": -0.297
        },
        {
            "name": "Night Stalker",
            "Faceless Void": 1.533
        },
        {
            "name": "Nyx Assassin",
            "Faceless Void": -0.281
        },
        {
            "name": "Ogre Magi",
            "Faceless Void": 0.148
        },
        {
            "name": "Omniknight",
            "Faceless Void": -2.526
        },
        {
            "name": "Oracle",
            "Faceless Void": 1.234
        },
        {
            "name": "Outworld Destroyer",
            "Faceless Void": 1.175
        },
        {
            "name": "Pangolier",
            "Faceless Void": -0.335
        },
        {
            "name": "Phantom Assassin",
            "Faceless Void": -0.087
        },
        {
            "name": "Phantom Lancer",
            "Faceless Void": -1.566
        },
        {
            "name": "Phoenix",
            "Faceless Void": -0.247
        },
        {
            "name": "Primal Beast",
            "Faceless Void": -2.355
        },
        {
            "name": "Puck",
            "Faceless Void": -0.426
        },
        {
            "name": "Pudge",
            "Faceless Void": 0.812
        },
        {
            "name": "Pugna",
            "Faceless Void": -1.4
        },
        {
            "name": "Queen of Pain",
            "Faceless Void": -1.501
        },
        {
            "name": "Razor",
            "Faceless Void": -1.768
        },
        {
            "name": "Riki",
            "Faceless Void": 1.111
        },
        {
            "name": "Ringmaster",
            "Faceless Void": -0.633
        },
        {
            "name": "Rubick",
            "Faceless Void": 0.69
        },
        {
            "name": "Sand King",
            "Faceless Void": -2.067
        },
        {
            "name": "Shadow Demon",
            "Faceless Void": -1.367
        },
        {
            "name": "Shadow Fiend",
            "Faceless Void": 0.309
        },
        {
            "name": "Shadow Shaman",
            "Faceless Void": 1.072
        },
        {
            "name": "Silencer",
            "Faceless Void": 2.039
        },
        {
            "name": "Skywrath Mage",
            "Faceless Void": 0.993
        },
        {
            "name": "Slardar",
            "Faceless Void": 1.113
        },
        {
            "name": "Slark",
            "Faceless Void": 1.056
        },
        {
            "name": "Snapfire",
            "Faceless Void": -1.649
        },
        {
            "name": "Sniper",
            "Faceless Void": 0.078
        },
        {
            "name": "Spectre",
            "Faceless Void": 2.133
        },
        {
            "name": "Spirit Breaker",
            "Faceless Void": -0.524
        },
        {
            "name": "Storm Spirit",
            "Faceless Void": 0.342
        },
        {
            "name": "Sven",
            "Faceless Void": -0.381
        },
        {
            "name": "Techies",
            "Faceless Void": -0.667
        },
        {
            "name": "Templar Assassin",
            "Faceless Void": -1.196
        },
        {
            "name": "Terrorblade",
            "Faceless Void": -1.752
        },
        {
            "name": "Tidehunter",
            "Faceless Void": 1.752
        },
        {
            "name": "Timbersaw",
            "Faceless Void": -2.533
        },
        {
            "name": "Tinker",
            "Faceless Void": -1.393
        },
        {
            "name": "Tiny",
            "Faceless Void": -0.229
        },
        {
            "name": "Treant Protector",
            "Faceless Void": -0.255
        },
        {
            "name": "Troll Warlord",
            "Faceless Void": -0.094
        },
        {
            "name": "Tusk",
            "Faceless Void": -1.467
        },
        {
            "name": "Underlord",
            "Faceless Void": -1.315
        },
        {
            "name": "Undying",
            "Faceless Void": -0.989
        },
        {
            "name": "Ursa",
            "Faceless Void": -0.157
        },
        {
            "name": "Vengeful Spirit",
            "Faceless Void": 0.39
        },
        {
            "name": "Venomancer",
            "Faceless Void": -0.672
        },
        {
            "name": "Viper",
            "Faceless Void": 0.13
        },
        {
            "name": "Visage",
            "Faceless Void": -0.023
        },
        {
            "name": "Void Spirit",
            "Faceless Void": -0.477
        },
        {
            "name": "Warlock",
            "Faceless Void": -0.484
        },
        {
            "name": "Weaver",
            "Faceless Void": -3.531
        },
        {
            "name": "Windranger",
            "Faceless Void": -1.97
        },
        {
            "name": "Winter Wyvern",
            "Faceless Void": -0.533
        },
        {
            "name": "Witch Doctor",
            "Faceless Void": -1.432
        },
        {
            "name": "Wraith King",
            "Faceless Void": 1.28
        },
        {
            "name": "Zeus",
            "Faceless Void": -1.032
        }
    ],
    "Gyrocopter": [
        {
            "name": "Abaddon",
            "Gyrocopter": 0.118
        },
        {
            "name": "Alchemist",
            "Gyrocopter": -0.364
        },
        {
            "name": "Ancient Apparition",
            "Gyrocopter": 1.676
        },
        {
            "name": "Anti-Mage",
            "Gyrocopter": 0.115
        },
        {
            "name": "Arc Warden",
            "Gyrocopter": -0.834
        },
        {
            "name": "Axe",
            "Gyrocopter": -0.715
        },
        {
            "name": "Bane",
            "Gyrocopter": 0.536
        },
        {
            "name": "Batrider",
            "Gyrocopter": -0.043
        },
        {
            "name": "Beastmaster",
            "Gyrocopter": -3.408
        },
        {
            "name": "Bloodseeker",
            "Gyrocopter": 1.206
        },
        {
            "name": "Bounty Hunter",
            "Gyrocopter": 1.075
        },
        {
            "name": "Brewmaster",
            "Gyrocopter": -1.4
        },
        {
            "name": "Bristleback",
            "Gyrocopter": 1.372
        },
        {
            "name": "Broodmother",
            "Gyrocopter": 1.39
        },
        {
            "name": "Centaur Warrunner",
            "Gyrocopter": 1.06
        },
        {
            "name": "Chaos Knight",
            "Gyrocopter": -0.855
        },
        {
            "name": "Chen",
            "Gyrocopter": 0.282
        },
        {
            "name": "Clinkz",
            "Gyrocopter": 1.031
        },
        {
            "name": "Clockwerk",
            "Gyrocopter": -0.217
        },
        {
            "name": "Crystal Maiden",
            "Gyrocopter": -0.678
        },
        {
            "name": "Dark Seer",
            "Gyrocopter": -2.056
        },
        {
            "name": "Dark Willow",
            "Gyrocopter": 0.959
        },
        {
            "name": "Dawnbreaker",
            "Gyrocopter": -0.008
        },
        {
            "name": "Dazzle",
            "Gyrocopter": -1.436
        },
        {
            "name": "Death Prophet",
            "Gyrocopter": -1.169
        },
        {
            "name": "Disruptor",
            "Gyrocopter": -0.42
        },
        {
            "name": "Doom",
            "Gyrocopter": 0.119
        },
        {
            "name": "Dragon Knight",
            "Gyrocopter": 0.057
        },
        {
            "name": "Drow Ranger",
            "Gyrocopter": 0.134
        },
        {
            "name": "Earth Spirit",
            "Gyrocopter": 0.133
        },
        {
            "name": "Earthshaker",
            "Gyrocopter": -1.193
        },
        {
            "name": "Elder Titan",
            "Gyrocopter": 1.613
        },
        {
            "name": "Ember Spirit",
            "Gyrocopter": 0.749
        },
        {
            "name": "Enchantress",
            "Gyrocopter": 1.059
        },
        {
            "name": "Enigma",
            "Gyrocopter": -2.854
        },
        {
            "name": "Faceless Void",
            "Gyrocopter": 0.979
        },
        {
            "name": "Grimstroke",
            "Gyrocopter": -2.332
        },
        {
            "name": "Hoodwink",
            "Gyrocopter": -0.081
        },
        {
            "name": "Huskar",
            "Gyrocopter": 0.978
        },
        {
            "name": "Invoker",
            "Gyrocopter": -0.259
        },
        {
            "name": "Io",
            "Gyrocopter": -0.811
        },
        {
            "name": "Jakiro",
            "Gyrocopter": -0.182
        },
        {
            "name": "Juggernaut",
            "Gyrocopter": 1.426
        },
        {
            "name": "Keeper of the Light",
            "Gyrocopter": 2.09
        },
        {
            "name": "Kez",
            "Gyrocopter": -0.004
        },
        {
            "name": "Kunkka",
            "Gyrocopter": 0.275
        },
        {
            "name": "Legion Commander",
            "Gyrocopter": 1.124
        },
        {
            "name": "Leshrac",
            "Gyrocopter": -0.873
        },
        {
            "name": "Lich",
            "Gyrocopter": -0.279
        },
        {
            "name": "Lifestealer",
            "Gyrocopter": 2.248
        },
        {
            "name": "Lina",
            "Gyrocopter": -0.312
        },
        {
            "name": "Lion",
            "Gyrocopter": -1.202
        },
        {
            "name": "Lone Druid",
            "Gyrocopter": -1.0
        },
        {
            "name": "Luna",
            "Gyrocopter": 0.329
        },
        {
            "name": "Lycan",
            "Gyrocopter": -2.027
        },
        {
            "name": "Magnus",
            "Gyrocopter": -0.729
        },
        {
            "name": "Marci",
            "Gyrocopter": -0.627
        },
        {
            "name": "Mars",
            "Gyrocopter": 1.169
        },
        {
            "name": "Medusa",
            "Gyrocopter": 0.224
        },
        {
            "name": "Meepo",
            "Gyrocopter": 0.391
        },
        {
            "name": "Mirana",
            "Gyrocopter": -0.338
        },
        {
            "name": "Monkey King",
            "Gyrocopter": -0.418
        },
        {
            "name": "Morphling",
            "Gyrocopter": 0.187
        },
        {
            "name": "Muerta",
            "Gyrocopter": -0.171
        },
        {
            "name": "Naga Siren",
            "Gyrocopter": -2.684
        },
        {
            "name": "Nature's Prophet",
            "Gyrocopter": 0.463
        },
        {
            "name": "Necrophos",
            "Gyrocopter": -1.218
        },
        {
            "name": "Night Stalker",
            "Gyrocopter": -0.953
        },
        {
            "name": "Nyx Assassin",
            "Gyrocopter": 0.53
        },
        {
            "name": "Ogre Magi",
            "Gyrocopter": 0.771
        },
        {
            "name": "Omniknight",
            "Gyrocopter": -1.002
        },
        {
            "name": "Oracle",
            "Gyrocopter": 0.413
        },
        {
            "name": "Outworld Destroyer",
            "Gyrocopter": 0.057
        },
        {
            "name": "Pangolier",
            "Gyrocopter": -1.654
        },
        {
            "name": "Phantom Assassin",
            "Gyrocopter": -0.174
        },
        {
            "name": "Phantom Lancer",
            "Gyrocopter": -1.459
        },
        {
            "name": "Phoenix",
            "Gyrocopter": 0.804
        },
        {
            "name": "Primal Beast",
            "Gyrocopter": -0.039
        },
        {
            "name": "Puck",
            "Gyrocopter": -0.447
        },
        {
            "name": "Pudge",
            "Gyrocopter": 0.434
        },
        {
            "name": "Pugna",
            "Gyrocopter": 0.16
        },
        {
            "name": "Queen of Pain",
            "Gyrocopter": 0.405
        },
        {
            "name": "Razor",
            "Gyrocopter": 0.596
        },
        {
            "name": "Riki",
            "Gyrocopter": -0.378
        },
        {
            "name": "Ringmaster",
            "Gyrocopter": 0.707
        },
        {
            "name": "Rubick",
            "Gyrocopter": -0.419
        },
        {
            "name": "Sand King",
            "Gyrocopter": -0.12
        },
        {
            "name": "Shadow Demon",
            "Gyrocopter": 0.92
        },
        {
            "name": "Shadow Fiend",
            "Gyrocopter": -1.404
        },
        {
            "name": "Shadow Shaman",
            "Gyrocopter": -1.595
        },
        {
            "name": "Silencer",
            "Gyrocopter": 0.059
        },
        {
            "name": "Skywrath Mage",
            "Gyrocopter": -0.134
        },
        {
            "name": "Slardar",
            "Gyrocopter": -1.3
        },
        {
            "name": "Slark",
            "Gyrocopter": -0.88
        },
        {
            "name": "Snapfire",
            "Gyrocopter": 0.135
        },
        {
            "name": "Sniper",
            "Gyrocopter": 1.337
        },
        {
            "name": "Spectre",
            "Gyrocopter": 0.324
        },
        {
            "name": "Spirit Breaker",
            "Gyrocopter": 0.159
        },
        {
            "name": "Storm Spirit",
            "Gyrocopter": 0.517
        },
        {
            "name": "Sven",
            "Gyrocopter": -0.112
        },
        {
            "name": "Techies",
            "Gyrocopter": 0.459
        },
        {
            "name": "Templar Assassin",
            "Gyrocopter": 0.329
        },
        {
            "name": "Terrorblade",
            "Gyrocopter": 0.307
        },
        {
            "name": "Tidehunter",
            "Gyrocopter": -0.029
        },
        {
            "name": "Timbersaw",
            "Gyrocopter": -0.579
        },
        {
            "name": "Tinker",
            "Gyrocopter": -0.088
        },
        {
            "name": "Tiny",
            "Gyrocopter": -1.018
        },
        {
            "name": "Treant Protector",
            "Gyrocopter": 0.715
        },
        {
            "name": "Troll Warlord",
            "Gyrocopter": -1.275
        },
        {
            "name": "Tusk",
            "Gyrocopter": 0.317
        },
        {
            "name": "Underlord",
            "Gyrocopter": 0.595
        },
        {
            "name": "Undying",
            "Gyrocopter": -2.164
        },
        {
            "name": "Ursa",
            "Gyrocopter": -0.009
        },
        {
            "name": "Vengeful Spirit",
            "Gyrocopter": -0.592
        },
        {
            "name": "Venomancer",
            "Gyrocopter": 0.579
        },
        {
            "name": "Viper",
            "Gyrocopter": 2.158
        },
        {
            "name": "Visage",
            "Gyrocopter": -1.417
        },
        {
            "name": "Void Spirit",
            "Gyrocopter": -1.733
        },
        {
            "name": "Warlock",
            "Gyrocopter": -0.651
        },
        {
            "name": "Weaver",
            "Gyrocopter": -0.521
        },
        {
            "name": "Windranger",
            "Gyrocopter": -0.61
        },
        {
            "name": "Winter Wyvern",
            "Gyrocopter": -1.753
        },
        {
            "name": "Witch Doctor",
            "Gyrocopter": 0.728
        },
        {
            "name": "Wraith King",
            "Gyrocopter": 0.052
        },
        {
            "name": "Zeus",
            "Gyrocopter": 0.9
        }
    ],
    "Hoodwink": [
        {
            "name": "Abaddon",
            "Hoodwink": -0.541
        },
        {
            "name": "Alchemist",
            "Hoodwink": -0.183
        },
        {
            "name": "Ancient Apparition",
            "Hoodwink": -0.845
        },
        {
            "name": "Anti-Mage",
            "Hoodwink": -2.611
        },
        {
            "name": "Arc Warden",
            "Hoodwink": -0.672
        },
        {
            "name": "Axe",
            "Hoodwink": 1.595
        },
        {
            "name": "Bane",
            "Hoodwink": 0.557
        },
        {
            "name": "Batrider",
            "Hoodwink": 0.101
        },
        {
            "name": "Beastmaster",
            "Hoodwink": -0.409
        },
        {
            "name": "Bloodseeker",
            "Hoodwink": 0.11
        },
        {
            "name": "Bounty Hunter",
            "Hoodwink": 0.136
        },
        {
            "name": "Brewmaster",
            "Hoodwink": 0.136
        },
        {
            "name": "Bristleback",
            "Hoodwink": -3.551
        },
        {
            "name": "Broodmother",
            "Hoodwink": -0.884
        },
        {
            "name": "Centaur Warrunner",
            "Hoodwink": 1.805
        },
        {
            "name": "Chaos Knight",
            "Hoodwink": -0.394
        },
        {
            "name": "Chen",
            "Hoodwink": 0.544
        },
        {
            "name": "Clinkz",
            "Hoodwink": 0.051
        },
        {
            "name": "Clockwerk",
            "Hoodwink": 0.32
        },
        {
            "name": "Crystal Maiden",
            "Hoodwink": -0.427
        },
        {
            "name": "Dark Seer",
            "Hoodwink": -2.654
        },
        {
            "name": "Dark Willow",
            "Hoodwink": -0.344
        },
        {
            "name": "Dawnbreaker",
            "Hoodwink": -0.23
        },
        {
            "name": "Dazzle",
            "Hoodwink": -0.478
        },
        {
            "name": "Death Prophet",
            "Hoodwink": -1.155
        },
        {
            "name": "Disruptor",
            "Hoodwink": -0.526
        },
        {
            "name": "Doom",
            "Hoodwink": 0.345
        },
        {
            "name": "Dragon Knight",
            "Hoodwink": 0.227
        },
        {
            "name": "Drow Ranger",
            "Hoodwink": -0.014
        },
        {
            "name": "Earth Spirit",
            "Hoodwink": 0.636
        },
        {
            "name": "Earthshaker",
            "Hoodwink": -1.129
        },
        {
            "name": "Elder Titan",
            "Hoodwink": -1.556
        },
        {
            "name": "Ember Spirit",
            "Hoodwink": -0.256
        },
        {
            "name": "Enchantress",
            "Hoodwink": -0.272
        },
        {
            "name": "Enigma",
            "Hoodwink": -0.39
        },
        {
            "name": "Faceless Void",
            "Hoodwink": -0.657
        },
        {
            "name": "Grimstroke",
            "Hoodwink": -0.118
        },
        {
            "name": "Gyrocopter",
            "Hoodwink": 0.032
        },
        {
            "name": "Huskar",
            "Hoodwink": -2.112
        },
        {
            "name": "Invoker",
            "Hoodwink": -1.013
        },
        {
            "name": "Io",
            "Hoodwink": -1.301
        },
        {
            "name": "Jakiro",
            "Hoodwink": -0.024
        },
        {
            "name": "Juggernaut",
            "Hoodwink": -0.199
        },
        {
            "name": "Keeper of the Light",
            "Hoodwink": -0.149
        },
        {
            "name": "Kez",
            "Hoodwink": 0.569
        },
        {
            "name": "Kunkka",
            "Hoodwink": 0.196
        },
        {
            "name": "Legion Commander",
            "Hoodwink": 0.218
        },
        {
            "name": "Leshrac",
            "Hoodwink": -1.461
        },
        {
            "name": "Lich",
            "Hoodwink": -0.425
        },
        {
            "name": "Lifestealer",
            "Hoodwink": -0.163
        },
        {
            "name": "Lina",
            "Hoodwink": -0.254
        },
        {
            "name": "Lion",
            "Hoodwink": -0.286
        },
        {
            "name": "Lone Druid",
            "Hoodwink": -1.088
        },
        {
            "name": "Luna",
            "Hoodwink": -0.106
        },
        {
            "name": "Lycan",
            "Hoodwink": -0.913
        },
        {
            "name": "Magnus",
            "Hoodwink": -0.351
        },
        {
            "name": "Marci",
            "Hoodwink": -0.347
        },
        {
            "name": "Mars",
            "Hoodwink": 1.032
        },
        {
            "name": "Medusa",
            "Hoodwink": -0.877
        },
        {
            "name": "Meepo",
            "Hoodwink": -1.109
        },
        {
            "name": "Mirana",
            "Hoodwink": 0.405
        },
        {
            "name": "Monkey King",
            "Hoodwink": -1.149
        },
        {
            "name": "Morphling",
            "Hoodwink": -0.432
        },
        {
            "name": "Muerta",
            "Hoodwink": -0.568
        },
        {
            "name": "Naga Siren",
            "Hoodwink": 0.723
        },
        {
            "name": "Nature's Prophet",
            "Hoodwink": -0.243
        },
        {
            "name": "Necrophos",
            "Hoodwink": -0.662
        },
        {
            "name": "Night Stalker",
            "Hoodwink": -0.073
        },
        {
            "name": "Nyx Assassin",
            "Hoodwink": 0.202
        },
        {
            "name": "Ogre Magi",
            "Hoodwink": 1.124
        },
        {
            "name": "Omniknight",
            "Hoodwink": -0.196
        },
        {
            "name": "Oracle",
            "Hoodwink": -0.975
        },
        {
            "name": "Outworld Destroyer",
            "Hoodwink": -0.122
        },
        {
            "name": "Pangolier",
            "Hoodwink": 0.097
        },
        {
            "name": "Phantom Assassin",
            "Hoodwink": 1.274
        },
        {
            "name": "Phantom Lancer",
            "Hoodwink": 1.659
        },
        {
            "name": "Phoenix",
            "Hoodwink": -0.51
        },
        {
            "name": "Primal Beast",
            "Hoodwink": 1.686
        },
        {
            "name": "Puck",
            "Hoodwink": 0.923
        },
        {
            "name": "Pudge",
            "Hoodwink": -0.439
        },
        {
            "name": "Pugna",
            "Hoodwink": 0.016
        },
        {
            "name": "Queen of Pain",
            "Hoodwink": 0.056
        },
        {
            "name": "Razor",
            "Hoodwink": -0.267
        },
        {
            "name": "Riki",
            "Hoodwink": 0.479
        },
        {
            "name": "Ringmaster",
            "Hoodwink": -0.237
        },
        {
            "name": "Rubick",
            "Hoodwink": -1.275
        },
        {
            "name": "Sand King",
            "Hoodwink": 0.138
        },
        {
            "name": "Shadow Demon",
            "Hoodwink": 0.259
        },
        {
            "name": "Shadow Fiend",
            "Hoodwink": -1.24
        },
        {
            "name": "Shadow Shaman",
            "Hoodwink": -0.675
        },
        {
            "name": "Silencer",
            "Hoodwink": -0.572
        },
        {
            "name": "Skywrath Mage",
            "Hoodwink": 0.342
        },
        {
            "name": "Slardar",
            "Hoodwink": -0.103
        },
        {
            "name": "Slark",
            "Hoodwink": -0.274
        },
        {
            "name": "Snapfire",
            "Hoodwink": 0.524
        },
        {
            "name": "Sniper",
            "Hoodwink": 0.12
        },
        {
            "name": "Spectre",
            "Hoodwink": -0.036
        },
        {
            "name": "Spirit Breaker",
            "Hoodwink": 1.376
        },
        {
            "name": "Storm Spirit",
            "Hoodwink": 0.011
        },
        {
            "name": "Sven",
            "Hoodwink": 0.408
        },
        {
            "name": "Techies",
            "Hoodwink": 0.208
        },
        {
            "name": "Templar Assassin",
            "Hoodwink": -0.262
        },
        {
            "name": "Terrorblade",
            "Hoodwink": -0.471
        },
        {
            "name": "Tidehunter",
            "Hoodwink": -0.524
        },
        {
            "name": "Timbersaw",
            "Hoodwink": 1.809
        },
        {
            "name": "Tinker",
            "Hoodwink": 0.58
        },
        {
            "name": "Tiny",
            "Hoodwink": 1.196
        },
        {
            "name": "Treant Protector",
            "Hoodwink": 0.275
        },
        {
            "name": "Troll Warlord",
            "Hoodwink": -0.697
        },
        {
            "name": "Tusk",
            "Hoodwink": -0.267
        },
        {
            "name": "Underlord",
            "Hoodwink": -0.607
        },
        {
            "name": "Undying",
            "Hoodwink": -0.502
        },
        {
            "name": "Ursa",
            "Hoodwink": -0.769
        },
        {
            "name": "Vengeful Spirit",
            "Hoodwink": 0.279
        },
        {
            "name": "Venomancer",
            "Hoodwink": -0.068
        },
        {
            "name": "Viper",
            "Hoodwink": -0.576
        },
        {
            "name": "Visage",
            "Hoodwink": -2.314
        },
        {
            "name": "Void Spirit",
            "Hoodwink": 0.439
        },
        {
            "name": "Warlock",
            "Hoodwink": -0.108
        },
        {
            "name": "Weaver",
            "Hoodwink": 0.009
        },
        {
            "name": "Windranger",
            "Hoodwink": 0.64
        },
        {
            "name": "Winter Wyvern",
            "Hoodwink": -0.325
        },
        {
            "name": "Witch Doctor",
            "Hoodwink": -0.828
        },
        {
            "name": "Wraith King",
            "Hoodwink": 0.26
        },
        {
            "name": "Zeus",
            "Hoodwink": 0.624
        }
    ],
    "Juggernaut": [
        {
            "name": "Abaddon",
            "Juggernaut": 0.168
        },
        {
            "name": "Alchemist",
            "Juggernaut": -1.244
        },
        {
            "name": "Ancient Apparition",
            "Juggernaut": -0.105
        },
        {
            "name": "Anti-Mage",
            "Juggernaut": 2.121
        },
        {
            "name": "Arc Warden",
            "Juggernaut": -0.043
        },
        {
            "name": "Axe",
            "Juggernaut": 0.419
        },
        {
            "name": "Bane",
            "Juggernaut": 0.585
        },
        {
            "name": "Batrider",
            "Juggernaut": 0.634
        },
        {
            "name": "Beastmaster",
            "Juggernaut": 0.851
        },
        {
            "name": "Bloodseeker",
            "Juggernaut": -3.465
        },
        {
            "name": "Bounty Hunter",
            "Juggernaut": -0.905
        },
        {
            "name": "Brewmaster",
            "Juggernaut": 0.051
        },
        {
            "name": "Bristleback",
            "Juggernaut": -2.534
        },
        {
            "name": "Broodmother",
            "Juggernaut": 2.545
        },
        {
            "name": "Centaur Warrunner",
            "Juggernaut": -1.97
        },
        {
            "name": "Chaos Knight",
            "Juggernaut": 0.359
        },
        {
            "name": "Chen",
            "Juggernaut": 2.631
        },
        {
            "name": "Clinkz",
            "Juggernaut": 2.791
        },
        {
            "name": "Clockwerk",
            "Juggernaut": -1.941
        },
        {
            "name": "Crystal Maiden",
            "Juggernaut": -1.384
        },
        {
            "name": "Dark Seer",
            "Juggernaut": -0.944
        },
        {
            "name": "Dark Willow",
            "Juggernaut": -1.383
        },
        {
            "name": "Dawnbreaker",
            "Juggernaut": -1.43
        },
        {
            "name": "Dazzle",
            "Juggernaut": 0.864
        },
        {
            "name": "Death Prophet",
            "Juggernaut": 2.375
        },
        {
            "name": "Disruptor",
            "Juggernaut": 1.005
        },
        {
            "name": "Doom",
            "Juggernaut": 1.593
        },
        {
            "name": "Dragon Knight",
            "Juggernaut": 0.616
        },
        {
            "name": "Drow Ranger",
            "Juggernaut": 0.792
        },
        {
            "name": "Earth Spirit",
            "Juggernaut": -2.027
        },
        {
            "name": "Earthshaker",
            "Juggernaut": 0.018
        },
        {
            "name": "Elder Titan",
            "Juggernaut": -1.383
        },
        {
            "name": "Ember Spirit",
            "Juggernaut": -0.872
        },
        {
            "name": "Enchantress",
            "Juggernaut": 0.471
        },
        {
            "name": "Enigma",
            "Juggernaut": 0.863
        },
        {
            "name": "Faceless Void",
            "Juggernaut": 1.882
        },
        {
            "name": "Grimstroke",
            "Juggernaut": 0.601
        },
        {
            "name": "Gyrocopter",
            "Juggernaut": -0.724
        },
        {
            "name": "Hoodwink",
            "Juggernaut": 0.713
        },
        {
            "name": "Huskar",
            "Juggernaut": 1.539
        },
        {
            "name": "Invoker",
            "Juggernaut": -0.116
        },
        {
            "name": "Io",
            "Juggernaut": 1.546
        },
        {
            "name": "Jakiro",
            "Juggernaut": -1.187
        },
        {
            "name": "Keeper of the Light",
            "Juggernaut": -0.06
        },
        {
            "name": "Kez",
            "Juggernaut": 0.537
        },
        {
            "name": "Kunkka",
            "Juggernaut": -1.533
        },
        {
            "name": "Legion Commander",
            "Juggernaut": 0.69
        },
        {
            "name": "Leshrac",
            "Juggernaut": 0.459
        },
        {
            "name": "Lich",
            "Juggernaut": -0.511
        },
        {
            "name": "Lifestealer",
            "Juggernaut": -1.472
        },
        {
            "name": "Lina",
            "Juggernaut": 0.634
        },
        {
            "name": "Lion",
            "Juggernaut": 1.732
        },
        {
            "name": "Lone Druid",
            "Juggernaut": 2.0
        },
        {
            "name": "Luna",
            "Juggernaut": 0.698
        },
        {
            "name": "Lycan",
            "Juggernaut": 1.208
        },
        {
            "name": "Magnus",
            "Juggernaut": -0.657
        },
        {
            "name": "Marci",
            "Juggernaut": -0.088
        },
        {
            "name": "Mars",
            "Juggernaut": -0.156
        },
        {
            "name": "Medusa",
            "Juggernaut": -0.486
        },
        {
            "name": "Meepo",
            "Juggernaut": 0.866
        },
        {
            "name": "Mirana",
            "Juggernaut": 1.131
        },
        {
            "name": "Monkey King",
            "Juggernaut": 0.382
        },
        {
            "name": "Morphling",
            "Juggernaut": 2.893
        },
        {
            "name": "Muerta",
            "Juggernaut": 2.181
        },
        {
            "name": "Naga Siren",
            "Juggernaut": -0.599
        },
        {
            "name": "Nature's Prophet",
            "Juggernaut": 3.018
        },
        {
            "name": "Necrophos",
            "Juggernaut": 0.006
        },
        {
            "name": "Night Stalker",
            "Juggernaut": 2.547
        },
        {
            "name": "Nyx Assassin",
            "Juggernaut": -1.04
        },
        {
            "name": "Ogre Magi",
            "Juggernaut": -1.059
        },
        {
            "name": "Omniknight",
            "Juggernaut": 0.566
        },
        {
            "name": "Oracle",
            "Juggernaut": 0.541
        },
        {
            "name": "Outworld Destroyer",
            "Juggernaut": 0.747
        },
        {
            "name": "Pangolier",
            "Juggernaut": 0.782
        },
        {
            "name": "Phantom Assassin",
            "Juggernaut": 0.032
        },
        {
            "name": "Phantom Lancer",
            "Juggernaut": 0.734
        },
        {
            "name": "Phoenix",
            "Juggernaut": -2.832
        },
        {
            "name": "Primal Beast",
            "Juggernaut": -1.1
        },
        {
            "name": "Puck",
            "Juggernaut": 1.197
        },
        {
            "name": "Pudge",
            "Juggernaut": -0.058
        },
        {
            "name": "Pugna",
            "Juggernaut": 0.591
        },
        {
            "name": "Queen of Pain",
            "Juggernaut": 0.98
        },
        {
            "name": "Razor",
            "Juggernaut": 0.747
        },
        {
            "name": "Riki",
            "Juggernaut": 2.96
        },
        {
            "name": "Ringmaster",
            "Juggernaut": -0.04
        },
        {
            "name": "Rubick",
            "Juggernaut": 0.042
        },
        {
            "name": "Sand King",
            "Juggernaut": -1.494
        },
        {
            "name": "Shadow Demon",
            "Juggernaut": 0.517
        },
        {
            "name": "Shadow Fiend",
            "Juggernaut": 0.724
        },
        {
            "name": "Shadow Shaman",
            "Juggernaut": 0.772
        },
        {
            "name": "Silencer",
            "Juggernaut": 0.732
        },
        {
            "name": "Skywrath Mage",
            "Juggernaut": 1.52
        },
        {
            "name": "Slardar",
            "Juggernaut": -1.482
        },
        {
            "name": "Slark",
            "Juggernaut": 1.385
        },
        {
            "name": "Snapfire",
            "Juggernaut": 0.556
        },
        {
            "name": "Sniper",
            "Juggernaut": 1.007
        },
        {
            "name": "Spectre",
            "Juggernaut": -0.891
        },
        {
            "name": "Spirit Breaker",
            "Juggernaut": 1.542
        },
        {
            "name": "Storm Spirit",
            "Juggernaut": 1.265
        },
        {
            "name": "Sven",
            "Juggernaut": 0.983
        },
        {
            "name": "Techies",
            "Juggernaut": -0.82
        },
        {
            "name": "Templar Assassin",
            "Juggernaut": 2.32
        },
        {
            "name": "Terrorblade",
            "Juggernaut": -0.25
        },
        {
            "name": "Tidehunter",
            "Juggernaut": -2.935
        },
        {
            "name": "Timbersaw",
            "Juggernaut": -0.009
        },
        {
            "name": "Tinker",
            "Juggernaut": -1.008
        },
        {
            "name": "Tiny",
            "Juggernaut": -0.403
        },
        {
            "name": "Treant Protector",
            "Juggernaut": -1.494
        },
        {
            "name": "Troll Warlord",
            "Juggernaut": 1.078
        },
        {
            "name": "Tusk",
            "Juggernaut": 1.088
        },
        {
            "name": "Underlord",
            "Juggernaut": -2.805
        },
        {
            "name": "Undying",
            "Juggernaut": -0.394
        },
        {
            "name": "Ursa",
            "Juggernaut": 2.033
        },
        {
            "name": "Vengeful Spirit",
            "Juggernaut": -0.034
        },
        {
            "name": "Venomancer",
            "Juggernaut": -0.992
        },
        {
            "name": "Viper",
            "Juggernaut": -1.655
        },
        {
            "name": "Visage",
            "Juggernaut": 2.588
        },
        {
            "name": "Void Spirit",
            "Juggernaut": 2.185
        },
        {
            "name": "Warlock",
            "Juggernaut": 0.031
        },
        {
            "name": "Weaver",
            "Juggernaut": 2.245
        },
        {
            "name": "Windranger",
            "Juggernaut": 2.388
        },
        {
            "name": "Winter Wyvern",
            "Juggernaut": -0.162
        },
        {
            "name": "Witch Doctor",
            "Juggernaut": 0.916
        },
        {
            "name": "Wraith King",
            "Juggernaut": 0.325
        },
        {
            "name": "Zeus",
            "Juggernaut": -0.492
        }
    ],
    "Kez": [
        {
            "name": "Abaddon",
            "Kez": 1.26
        },
        {
            "name": "Alchemist",
            "Kez": -1.066
        },
        {
            "name": "Ancient Apparition",
            "Kez": 0.077
        },
        {
            "name": "Anti-Mage",
            "Kez": -3.317
        },
        {
            "name": "Arc Warden",
            "Kez": -1.403
        },
        {
            "name": "Axe",
            "Kez": 1.38
        },
        {
            "name": "Bane",
            "Kez": 2.183
        },
        {
            "name": "Batrider",
            "Kez": 0.654
        },
        {
            "name": "Beastmaster",
            "Kez": -0.15
        },
        {
            "name": "Bloodseeker",
            "Kez": -0.01
        },
        {
            "name": "Bounty Hunter",
            "Kez": -0.657
        },
        {
            "name": "Brewmaster",
            "Kez": -1.609
        },
        {
            "name": "Bristleback",
            "Kez": 0.752
        },
        {
            "name": "Broodmother",
            "Kez": -0.62
        },
        {
            "name": "Centaur Warrunner",
            "Kez": -2.979
        },
        {
            "name": "Chaos Knight",
            "Kez": -1.224
        },
        {
            "name": "Chen",
            "Kez": 0.123
        },
        {
            "name": "Clinkz",
            "Kez": 0.618
        },
        {
            "name": "Clockwerk",
            "Kez": -0.155
        },
        {
            "name": "Crystal Maiden",
            "Kez": -0.245
        },
        {
            "name": "Dark Seer",
            "Kez": -0.744
        },
        {
            "name": "Dark Willow",
            "Kez": 0.877
        },
        {
            "name": "Dawnbreaker",
            "Kez": -0.888
        },
        {
            "name": "Dazzle",
            "Kez": -0.219
        },
        {
            "name": "Death Prophet",
            "Kez": 1.489
        },
        {
            "name": "Disruptor",
            "Kez": -0.912
        },
        {
            "name": "Doom",
            "Kez": 0.45
        },
        {
            "name": "Dragon Knight",
            "Kez": -0.046
        },
        {
            "name": "Drow Ranger",
            "Kez": 2.131
        },
        {
            "name": "Earth Spirit",
            "Kez": -2.358
        },
        {
            "name": "Earthshaker",
            "Kez": -0.882
        },
        {
            "name": "Elder Titan",
            "Kez": 0.398
        },
        {
            "name": "Ember Spirit",
            "Kez": -1.45
        },
        {
            "name": "Enchantress",
            "Kez": -0.157
        },
        {
            "name": "Enigma",
            "Kez": 0.71
        },
        {
            "name": "Faceless Void",
            "Kez": 2.675
        },
        {
            "name": "Grimstroke",
            "Kez": -0.327
        },
        {
            "name": "Gyrocopter",
            "Kez": 0.014
        },
        {
            "name": "Hoodwink",
            "Kez": -0.633
        },
        {
            "name": "Huskar",
            "Kez": -1.496
        },
        {
            "name": "Invoker",
            "Kez": -0.687
        },
        {
            "name": "Io",
            "Kez": -0.559
        },
        {
            "name": "Jakiro",
            "Kez": -0.121
        },
        {
            "name": "Juggernaut",
            "Kez": -0.185
        },
        {
            "name": "Keeper of the Light",
            "Kez": -0.685
        },
        {
            "name": "Kunkka",
            "Kez": -0.914
        },
        {
            "name": "Legion Commander",
            "Kez": 1.891
        },
        {
            "name": "Leshrac",
            "Kez": -0.057
        },
        {
            "name": "Lich",
            "Kez": -0.517
        },
        {
            "name": "Lifestealer",
            "Kez": -1.616
        },
        {
            "name": "Lina",
            "Kez": -0.281
        },
        {
            "name": "Lion",
            "Kez": 1.033
        },
        {
            "name": "Lone Druid",
            "Kez": -1.57
        },
        {
            "name": "Luna",
            "Kez": 1.072
        },
        {
            "name": "Lycan",
            "Kez": -1.366
        },
        {
            "name": "Magnus",
            "Kez": -0.526
        },
        {
            "name": "Marci",
            "Kez": -0.296
        },
        {
            "name": "Mars",
            "Kez": -2.207
        },
        {
            "name": "Medusa",
            "Kez": 0.899
        },
        {
            "name": "Meepo",
            "Kez": -0.978
        },
        {
            "name": "Mirana",
            "Kez": 0.77
        },
        {
            "name": "Monkey King",
            "Kez": 1.945
        },
        {
            "name": "Morphling",
            "Kez": -1.112
        },
        {
            "name": "Muerta",
            "Kez": 1.259
        },
        {
            "name": "Naga Siren",
            "Kez": -0.688
        },
        {
            "name": "Nature's Prophet",
            "Kez": -0.488
        },
        {
            "name": "Necrophos",
            "Kez": 0.925
        },
        {
            "name": "Night Stalker",
            "Kez": -1.838
        },
        {
            "name": "Nyx Assassin",
            "Kez": -0.955
        },
        {
            "name": "Ogre Magi",
            "Kez": 0.425
        },
        {
            "name": "Omniknight",
            "Kez": 0.463
        },
        {
            "name": "Oracle",
            "Kez": 0.292
        },
        {
            "name": "Outworld Destroyer",
            "Kez": 0.482
        },
        {
            "name": "Pangolier",
            "Kez": -0.614
        },
        {
            "name": "Phantom Assassin",
            "Kez": 1.243
        },
        {
            "name": "Phantom Lancer",
            "Kez": 0.166
        },
        {
            "name": "Phoenix",
            "Kez": -0.757
        },
        {
            "name": "Primal Beast",
            "Kez": 0.741
        },
        {
            "name": "Puck",
            "Kez": 0.03
        },
        {
            "name": "Pudge",
            "Kez": -0.249
        },
        {
            "name": "Pugna",
            "Kez": 1.149
        },
        {
            "name": "Queen of Pain",
            "Kez": -0.429
        },
        {
            "name": "Razor",
            "Kez": 0.576
        },
        {
            "name": "Riki",
            "Kez": 0.983
        },
        {
            "name": "Ringmaster",
            "Kez": -1.263
        },
        {
            "name": "Rubick",
            "Kez": -1.099
        },
        {
            "name": "Sand King",
            "Kez": -1.588
        },
        {
            "name": "Shadow Demon",
            "Kez": 0.078
        },
        {
            "name": "Shadow Fiend",
            "Kez": -0.005
        },
        {
            "name": "Shadow Shaman",
            "Kez": 1.379
        },
        {
            "name": "Silencer",
            "Kez": 0.177
        },
        {
            "name": "Skywrath Mage",
            "Kez": -0.096
        },
        {
            "name": "Slardar",
            "Kez": -0.57
        },
        {
            "name": "Slark",
            "Kez": 0.801
        },
        {
            "name": "Snapfire",
            "Kez": -0.703
        },
        {
            "name": "Sniper",
            "Kez": 0.493
        },
        {
            "name": "Spectre",
            "Kez": -2.466
        },
        {
            "name": "Spirit Breaker",
            "Kez": 1.359
        },
        {
            "name": "Storm Spirit",
            "Kez": -1.181
        },
        {
            "name": "Sven",
            "Kez": 0.227
        },
        {
            "name": "Techies",
            "Kez": 0.818
        },
        {
            "name": "Templar Assassin",
            "Kez": 2.006
        },
        {
            "name": "Terrorblade",
            "Kez": 0.928
        },
        {
            "name": "Tidehunter",
            "Kez": 0.606
        },
        {
            "name": "Timbersaw",
            "Kez": -1.764
        },
        {
            "name": "Tinker",
            "Kez": 1.82
        },
        {
            "name": "Tiny",
            "Kez": -1.012
        },
        {
            "name": "Treant Protector",
            "Kez": -0.03
        },
        {
            "name": "Troll Warlord",
            "Kez": 2.351
        },
        {
            "name": "Tusk",
            "Kez": 0.127
        },
        {
            "name": "Underlord",
            "Kez": -1.172
        },
        {
            "name": "Undying",
            "Kez": -0.38
        },
        {
            "name": "Ursa",
            "Kez": 1.796
        },
        {
            "name": "Vengeful Spirit",
            "Kez": 0.305
        },
        {
            "name": "Venomancer",
            "Kez": -0.542
        },
        {
            "name": "Viper",
            "Kez": -0.57
        },
        {
            "name": "Visage",
            "Kez": -1.127
        },
        {
            "name": "Void Spirit",
            "Kez": -1.104
        },
        {
            "name": "Warlock",
            "Kez": -0.735
        },
        {
            "name": "Weaver",
            "Kez": 0.26
        },
        {
            "name": "Windranger",
            "Kez": 1.598
        },
        {
            "name": "Winter Wyvern",
            "Kez": 1.192
        },
        {
            "name": "Witch Doctor",
            "Kez": 1.07
        },
        {
            "name": "Wraith King",
            "Kez": -0.371
        },
        {
            "name": "Zeus",
            "Kez": -0.475
        }
    ],
    "Luna": [
        {
            "name": "Abaddon",
            "Luna": 0.127
        },
        {
            "name": "Alchemist",
            "Luna": -0.852
        },
        {
            "name": "Ancient Apparition",
            "Luna": 0.457
        },
        {
            "name": "Anti-Mage",
            "Luna": -1.538
        },
        {
            "name": "Arc Warden",
            "Luna": -1.398
        },
        {
            "name": "Axe",
            "Luna": -0.419
        },
        {
            "name": "Bane",
            "Luna": -0.492
        },
        {
            "name": "Batrider",
            "Luna": -0.51
        },
        {
            "name": "Beastmaster",
            "Luna": 0.354
        },
        {
            "name": "Bloodseeker",
            "Luna": -1.074
        },
        {
            "name": "Bounty Hunter",
            "Luna": -0.928
        },
        {
            "name": "Brewmaster",
            "Luna": -0.914
        },
        {
            "name": "Bristleback",
            "Luna": 3.138
        },
        {
            "name": "Broodmother",
            "Luna": -0.096
        },
        {
            "name": "Centaur Warrunner",
            "Luna": 0.895
        },
        {
            "name": "Chaos Knight",
            "Luna": -0.9
        },
        {
            "name": "Chen",
            "Luna": -1.55
        },
        {
            "name": "Clinkz",
            "Luna": -1.723
        },
        {
            "name": "Clockwerk",
            "Luna": 1.713
        },
        {
            "name": "Crystal Maiden",
            "Luna": -0.311
        },
        {
            "name": "Dark Seer",
            "Luna": 1.449
        },
        {
            "name": "Dark Willow",
            "Luna": -0.726
        },
        {
            "name": "Dawnbreaker",
            "Luna": 1.054
        },
        {
            "name": "Dazzle",
            "Luna": -0.589
        },
        {
            "name": "Death Prophet",
            "Luna": -1.364
        },
        {
            "name": "Disruptor",
            "Luna": -0.637
        },
        {
            "name": "Doom",
            "Luna": -1.738
        },
        {
            "name": "Dragon Knight",
            "Luna": 0.267
        },
        {
            "name": "Drow Ranger",
            "Luna": -0.253
        },
        {
            "name": "Earth Spirit",
            "Luna": -0.951
        },
        {
            "name": "Earthshaker",
            "Luna": -0.144
        },
        {
            "name": "Elder Titan",
            "Luna": -0.813
        },
        {
            "name": "Ember Spirit",
            "Luna": 0.429
        },
        {
            "name": "Enchantress",
            "Luna": -0.175
        },
        {
            "name": "Enigma",
            "Luna": 0.018
        },
        {
            "name": "Faceless Void",
            "Luna": -1.08
        },
        {
            "name": "Grimstroke",
            "Luna": -0.337
        },
        {
            "name": "Gyrocopter",
            "Luna": -0.308
        },
        {
            "name": "Hoodwink",
            "Luna": 0.108
        },
        {
            "name": "Huskar",
            "Luna": -2.203
        },
        {
            "name": "Invoker",
            "Luna": 0.708
        },
        {
            "name": "Io",
            "Luna": -2.184
        },
        {
            "name": "Jakiro",
            "Luna": 0.5
        },
        {
            "name": "Juggernaut",
            "Luna": 0.234
        },
        {
            "name": "Keeper of the Light",
            "Luna": -0.678
        },
        {
            "name": "Kez",
            "Luna": -1.025
        },
        {
            "name": "Kunkka",
            "Luna": 1.297
        },
        {
            "name": "Legion Commander",
            "Luna": -0.793
        },
        {
            "name": "Leshrac",
            "Luna": -1.54
        },
        {
            "name": "Lich",
            "Luna": 2.018
        },
        {
            "name": "Lifestealer",
            "Luna": -0.097
        },
        {
            "name": "Lina",
            "Luna": 1.052
        },
        {
            "name": "Lion",
            "Luna": -0.333
        },
        {
            "name": "Lone Druid",
            "Luna": -3.586
        },
        {
            "name": "Lycan",
            "Luna": -1.452
        },
        {
            "name": "Magnus",
            "Luna": 0.157
        },
        {
            "name": "Marci",
            "Luna": -1.146
        },
        {
            "name": "Mars",
            "Luna": 1.598
        },
        {
            "name": "Medusa",
            "Luna": 2.493
        },
        {
            "name": "Meepo",
            "Luna": -3.171
        },
        {
            "name": "Mirana",
            "Luna": -1.113
        },
        {
            "name": "Monkey King",
            "Luna": -1.294
        },
        {
            "name": "Morphling",
            "Luna": -1.277
        },
        {
            "name": "Muerta",
            "Luna": 0.05
        },
        {
            "name": "Naga Siren",
            "Luna": -1.797
        },
        {
            "name": "Nature's Prophet",
            "Luna": -1.525
        },
        {
            "name": "Necrophos",
            "Luna": 0.405
        },
        {
            "name": "Night Stalker",
            "Luna": -2.407
        },
        {
            "name": "Nyx Assassin",
            "Luna": 1.649
        },
        {
            "name": "Ogre Magi",
            "Luna": 0.027
        },
        {
            "name": "Omniknight",
            "Luna": -2.166
        },
        {
            "name": "Oracle",
            "Luna": 0.282
        },
        {
            "name": "Outworld Destroyer",
            "Luna": 0.062
        },
        {
            "name": "Pangolier",
            "Luna": -1.377
        },
        {
            "name": "Phantom Assassin",
            "Luna": -1.503
        },
        {
            "name": "Phantom Lancer",
            "Luna": 0.282
        },
        {
            "name": "Phoenix",
            "Luna": 0.316
        },
        {
            "name": "Primal Beast",
            "Luna": 1.084
        },
        {
            "name": "Puck",
            "Luna": -0.869
        },
        {
            "name": "Pudge",
            "Luna": 0.077
        },
        {
            "name": "Pugna",
            "Luna": -0.615
        },
        {
            "name": "Queen of Pain",
            "Luna": 0.785
        },
        {
            "name": "Razor",
            "Luna": -0.243
        },
        {
            "name": "Riki",
            "Luna": -1.769
        },
        {
            "name": "Ringmaster",
            "Luna": 0.677
        },
        {
            "name": "Rubick",
            "Luna": 2.007
        },
        {
            "name": "Sand King",
            "Luna": 1.511
        },
        {
            "name": "Shadow Demon",
            "Luna": 1.152
        },
        {
            "name": "Shadow Fiend",
            "Luna": -1.447
        },
        {
            "name": "Shadow Shaman",
            "Luna": -1.143
        },
        {
            "name": "Silencer",
            "Luna": -1.388
        },
        {
            "name": "Skywrath Mage",
            "Luna": 0.014
        },
        {
            "name": "Slardar",
            "Luna": -2.769
        },
        {
            "name": "Slark",
            "Luna": 0.919
        },
        {
            "name": "Snapfire",
            "Luna": 0.312
        },
        {
            "name": "Sniper",
            "Luna": 0.582
        },
        {
            "name": "Spectre",
            "Luna": 1.945
        },
        {
            "name": "Spirit Breaker",
            "Luna": 0.595
        },
        {
            "name": "Storm Spirit",
            "Luna": -1.065
        },
        {
            "name": "Sven",
            "Luna": -1.173
        },
        {
            "name": "Techies",
            "Luna": 0.914
        },
        {
            "name": "Templar Assassin",
            "Luna": -1.023
        },
        {
            "name": "Terrorblade",
            "Luna": 0.1
        },
        {
            "name": "Tidehunter",
            "Luna": -0.441
        },
        {
            "name": "Timbersaw",
            "Luna": 0.036
        },
        {
            "name": "Tinker",
            "Luna": -0.976
        },
        {
            "name": "Tiny",
            "Luna": -0.228
        },
        {
            "name": "Treant Protector",
            "Luna": -0.232
        },
        {
            "name": "Troll Warlord",
            "Luna": -1.088
        },
        {
            "name": "Tusk",
            "Luna": -1.418
        },
        {
            "name": "Underlord",
            "Luna": 1.938
        },
        {
            "name": "Undying",
            "Luna": -2.728
        },
        {
            "name": "Ursa",
            "Luna": -0.423
        },
        {
            "name": "Vengeful Spirit",
            "Luna": -0.312
        },
        {
            "name": "Venomancer",
            "Luna": 0.651
        },
        {
            "name": "Viper",
            "Luna": 0.373
        },
        {
            "name": "Visage",
            "Luna": -1.968
        },
        {
            "name": "Void Spirit",
            "Luna": -0.962
        },
        {
            "name": "Warlock",
            "Luna": 1.798
        },
        {
            "name": "Weaver",
            "Luna": -1.797
        },
        {
            "name": "Windranger",
            "Luna": -0.661
        },
        {
            "name": "Winter Wyvern",
            "Luna": -0.727
        },
        {
            "name": "Witch Doctor",
            "Luna": 1.569
        },
        {
            "name": "Wraith King",
            "Luna": 1.294
        },
        {
            "name": "Zeus",
            "Luna": 0.827
        }
    ],
    "Medusa": [
        {
            "name": "Abaddon",
            "Medusa": -1.077
        },
        {
            "name": "Alchemist",
            "Medusa": 2.926
        },
        {
            "name": "Ancient Apparition",
            "Medusa": -1.671
        },
        {
            "name": "Anti-Mage",
            "Medusa": 10.739
        },
        {
            "name": "Arc Warden",
            "Medusa": -1.174
        },
        {
            "name": "Axe",
            "Medusa": 1.093
        },
        {
            "name": "Bane",
            "Medusa": -0.161
        },
        {
            "name": "Batrider",
            "Medusa": -0.342
        },
        {
            "name": "Beastmaster",
            "Medusa": -4.559
        },
        {
            "name": "Bloodseeker",
            "Medusa": -6.589
        },
        {
            "name": "Bounty Hunter",
            "Medusa": -0.152
        },
        {
            "name": "Brewmaster",
            "Medusa": 0.323
        },
        {
            "name": "Bristleback",
            "Medusa": 1.333
        },
        {
            "name": "Broodmother",
            "Medusa": 1.234
        },
        {
            "name": "Centaur Warrunner",
            "Medusa": 4.284
        },
        {
            "name": "Chaos Knight",
            "Medusa": -3.496
        },
        {
            "name": "Chen",
            "Medusa": 3.044
        },
        {
            "name": "Clinkz",
            "Medusa": -0.037
        },
        {
            "name": "Clockwerk",
            "Medusa": 1.472
        },
        {
            "name": "Crystal Maiden",
            "Medusa": -0.542
        },
        {
            "name": "Dark Seer",
            "Medusa": 5.759
        },
        {
            "name": "Dark Willow",
            "Medusa": 0.876
        },
        {
            "name": "Dawnbreaker",
            "Medusa": 0.573
        },
        {
            "name": "Dazzle",
            "Medusa": 1.227
        },
        {
            "name": "Death Prophet",
            "Medusa": -1.602
        },
        {
            "name": "Disruptor",
            "Medusa": -0.753
        },
        {
            "name": "Doom",
            "Medusa": -1.867
        },
        {
            "name": "Dragon Knight",
            "Medusa": 0.74
        },
        {
            "name": "Drow Ranger",
            "Medusa": 0.284
        },
        {
            "name": "Earth Spirit",
            "Medusa": 1.424
        },
        {
            "name": "Earthshaker",
            "Medusa": 0.472
        },
        {
            "name": "Elder Titan",
            "Medusa": -0.169
        },
        {
            "name": "Ember Spirit",
            "Medusa": 1.902
        },
        {
            "name": "Enchantress",
            "Medusa": -1.666
        },
        {
            "name": "Enigma",
            "Medusa": -3.838
        },
        {
            "name": "Faceless Void",
            "Medusa": 0.312
        },
        {
            "name": "Grimstroke",
            "Medusa": -0.705
        },
        {
            "name": "Gyrocopter",
            "Medusa": -0.118
        },
        {
            "name": "Hoodwink",
            "Medusa": 0.864
        },
        {
            "name": "Huskar",
            "Medusa": -0.912
        },
        {
            "name": "Invoker",
            "Medusa": 4.669
        },
        {
            "name": "Io",
            "Medusa": -0.903
        },
        {
            "name": "Jakiro",
            "Medusa": 0.913
        },
        {
            "name": "Juggernaut",
            "Medusa": 0.49
        },
        {
            "name": "Keeper of the Light",
            "Medusa": 0.307
        },
        {
            "name": "Kez",
            "Medusa": -0.838
        },
        {
            "name": "Kunkka",
            "Medusa": 3.271
        },
        {
            "name": "Legion Commander",
            "Medusa": -2.594
        },
        {
            "name": "Leshrac",
            "Medusa": -2.881
        },
        {
            "name": "Lich",
            "Medusa": 0.959
        },
        {
            "name": "Lifestealer",
            "Medusa": -1.038
        },
        {
            "name": "Lina",
            "Medusa": -1.436
        },
        {
            "name": "Lion",
            "Medusa": 0.948
        },
        {
            "name": "Lone Druid",
            "Medusa": 2.398
        },
        {
            "name": "Luna",
            "Medusa": -2.216
        },
        {
            "name": "Lycan",
            "Medusa": 0.23
        },
        {
            "name": "Magnus",
            "Medusa": -0.207
        },
        {
            "name": "Marci",
            "Medusa": -1.008
        },
        {
            "name": "Mars",
            "Medusa": 1.291
        },
        {
            "name": "Meepo",
            "Medusa": -3.252
        },
        {
            "name": "Mirana",
            "Medusa": 0.225
        },
        {
            "name": "Monkey King",
            "Medusa": 3.952
        },
        {
            "name": "Morphling",
            "Medusa": -1.725
        },
        {
            "name": "Muerta",
            "Medusa": -1.494
        },
        {
            "name": "Naga Siren",
            "Medusa": -1.688
        },
        {
            "name": "Nature's Prophet",
            "Medusa": 0.04
        },
        {
            "name": "Necrophos",
            "Medusa": -1.586
        },
        {
            "name": "Night Stalker",
            "Medusa": -0.053
        },
        {
            "name": "Nyx Assassin",
            "Medusa": 1.072
        },
        {
            "name": "Ogre Magi",
            "Medusa": 0.554
        },
        {
            "name": "Omniknight",
            "Medusa": -0.533
        },
        {
            "name": "Oracle",
            "Medusa": -0.026
        },
        {
            "name": "Outworld Destroyer",
            "Medusa": -2.834
        },
        {
            "name": "Pangolier",
            "Medusa": 3.284
        },
        {
            "name": "Phantom Assassin",
            "Medusa": -0.49
        },
        {
            "name": "Phantom Lancer",
            "Medusa": 0.677
        },
        {
            "name": "Phoenix",
            "Medusa": -0.655
        },
        {
            "name": "Primal Beast",
            "Medusa": -0.085
        },
        {
            "name": "Puck",
            "Medusa": -0.799
        },
        {
            "name": "Pudge",
            "Medusa": -0.242
        },
        {
            "name": "Pugna",
            "Medusa": -1.694
        },
        {
            "name": "Queen of Pain",
            "Medusa": -4.192
        },
        {
            "name": "Razor",
            "Medusa": 1.025
        },
        {
            "name": "Riki",
            "Medusa": 2.171
        },
        {
            "name": "Ringmaster",
            "Medusa": 0.498
        },
        {
            "name": "Rubick",
            "Medusa": 1.838
        },
        {
            "name": "Sand King",
            "Medusa": 2.749
        },
        {
            "name": "Shadow Demon",
            "Medusa": 4.251
        },
        {
            "name": "Shadow Fiend",
            "Medusa": -2.289
        },
        {
            "name": "Shadow Shaman",
            "Medusa": -2.319
        },
        {
            "name": "Silencer",
            "Medusa": -1.132
        },
        {
            "name": "Skywrath Mage",
            "Medusa": -2.668
        },
        {
            "name": "Slardar",
            "Medusa": -2.488
        },
        {
            "name": "Slark",
            "Medusa": 3.893
        },
        {
            "name": "Snapfire",
            "Medusa": 2.115
        },
        {
            "name": "Sniper",
            "Medusa": 3.751
        },
        {
            "name": "Spectre",
            "Medusa": -1.736
        },
        {
            "name": "Spirit Breaker",
            "Medusa": -0.051
        },
        {
            "name": "Storm Spirit",
            "Medusa": -1.003
        },
        {
            "name": "Sven",
            "Medusa": 0.71
        },
        {
            "name": "Techies",
            "Medusa": -2.414
        },
        {
            "name": "Templar Assassin",
            "Medusa": -1.87
        },
        {
            "name": "Terrorblade",
            "Medusa": 3.574
        },
        {
            "name": "Tidehunter",
            "Medusa": 2.469
        },
        {
            "name": "Timbersaw",
            "Medusa": -3.937
        },
        {
            "name": "Tinker",
            "Medusa": 0.035
        },
        {
            "name": "Tiny",
            "Medusa": 1.214
        },
        {
            "name": "Treant Protector",
            "Medusa": 1.97
        },
        {
            "name": "Troll Warlord",
            "Medusa": -1.259
        },
        {
            "name": "Tusk",
            "Medusa": 0.272
        },
        {
            "name": "Underlord",
            "Medusa": 2.96
        },
        {
            "name": "Undying",
            "Medusa": -4.509
        },
        {
            "name": "Ursa",
            "Medusa": 0.595
        },
        {
            "name": "Vengeful Spirit",
            "Medusa": -0.695
        },
        {
            "name": "Venomancer",
            "Medusa": 0.153
        },
        {
            "name": "Viper",
            "Medusa": -1.902
        },
        {
            "name": "Visage",
            "Medusa": -3.006
        },
        {
            "name": "Void Spirit",
            "Medusa": -0.537
        },
        {
            "name": "Warlock",
            "Medusa": 0.409
        },
        {
            "name": "Weaver",
            "Medusa": -1.235
        },
        {
            "name": "Windranger",
            "Medusa": 1.913
        },
        {
            "name": "Winter Wyvern",
            "Medusa": 2.765
        },
        {
            "name": "Witch Doctor",
            "Medusa": -3.603
        },
        {
            "name": "Wraith King",
            "Medusa": -0.832
        },
        {
            "name": "Zeus",
            "Medusa": -1.221
        }
    ],
    "Meepo": [
        {
            "name": "Abaddon",
            "Meepo": -3.262
        },
        {
            "name": "Alchemist",
            "Meepo": 1.887
        },
        {
            "name": "Ancient Apparition",
            "Meepo": 1.231
        },
        {
            "name": "Anti-Mage",
            "Meepo": -8.057
        },
        {
            "name": "Arc Warden",
            "Meepo": -5.405
        },
        {
            "name": "Axe",
            "Meepo": 1.228
        },
        {
            "name": "Bane",
            "Meepo": -3.071
        },
        {
            "name": "Batrider",
            "Meepo": 2.344
        },
        {
            "name": "Beastmaster",
            "Meepo": -2.669
        },
        {
            "name": "Bloodseeker",
            "Meepo": 0.635
        },
        {
            "name": "Bounty Hunter",
            "Meepo": -2.135
        },
        {
            "name": "Brewmaster",
            "Meepo": -2.244
        },
        {
            "name": "Bristleback",
            "Meepo": 3.862
        },
        {
            "name": "Broodmother",
            "Meepo": -2.325
        },
        {
            "name": "Centaur Warrunner",
            "Meepo": -1.83
        },
        {
            "name": "Chaos Knight",
            "Meepo": -1.145
        },
        {
            "name": "Chen",
            "Meepo": -2.535
        },
        {
            "name": "Clinkz",
            "Meepo": -1.613
        },
        {
            "name": "Clockwerk",
            "Meepo": -3.693
        },
        {
            "name": "Crystal Maiden",
            "Meepo": 3.432
        },
        {
            "name": "Dark Seer",
            "Meepo": 0.113
        },
        {
            "name": "Dark Willow",
            "Meepo": 1.224
        },
        {
            "name": "Dawnbreaker",
            "Meepo": 3.192
        },
        {
            "name": "Dazzle",
            "Meepo": 2.029
        },
        {
            "name": "Death Prophet",
            "Meepo": 2.311
        },
        {
            "name": "Disruptor",
            "Meepo": 1.184
        },
        {
            "name": "Doom",
            "Meepo": -2.21
        },
        {
            "name": "Dragon Knight",
            "Meepo": -0.534
        },
        {
            "name": "Drow Ranger",
            "Meepo": 2.209
        },
        {
            "name": "Earth Spirit",
            "Meepo": -3.544
        },
        {
            "name": "Earthshaker",
            "Meepo": 7.559
        },
        {
            "name": "Elder Titan",
            "Meepo": 11.268
        },
        {
            "name": "Ember Spirit",
            "Meepo": -3.884
        },
        {
            "name": "Enchantress",
            "Meepo": -2.121
        },
        {
            "name": "Enigma",
            "Meepo": 2.023
        },
        {
            "name": "Faceless Void",
            "Meepo": -2.952
        },
        {
            "name": "Grimstroke",
            "Meepo": -1.39
        },
        {
            "name": "Gyrocopter",
            "Meepo": -0.466
        },
        {
            "name": "Hoodwink",
            "Meepo": 0.958
        },
        {
            "name": "Huskar",
            "Meepo": 3.935
        },
        {
            "name": "Invoker",
            "Meepo": -1.678
        },
        {
            "name": "Io",
            "Meepo": 0.63
        },
        {
            "name": "Jakiro",
            "Meepo": 4.734
        },
        {
            "name": "Juggernaut",
            "Meepo": -0.544
        },
        {
            "name": "Keeper of the Light",
            "Meepo": -0.539
        },
        {
            "name": "Kez",
            "Meepo": 1.052
        },
        {
            "name": "Kunkka",
            "Meepo": 3.572
        },
        {
            "name": "Legion Commander",
            "Meepo": 1.983
        },
        {
            "name": "Leshrac",
            "Meepo": 6.795
        },
        {
            "name": "Lich",
            "Meepo": 0.392
        },
        {
            "name": "Lifestealer",
            "Meepo": -0.855
        },
        {
            "name": "Lina",
            "Meepo": 4.948
        },
        {
            "name": "Lion",
            "Meepo": 1.051
        },
        {
            "name": "Lone Druid",
            "Meepo": 0.998
        },
        {
            "name": "Luna",
            "Meepo": 3.154
        },
        {
            "name": "Lycan",
            "Meepo": -2.286
        },
        {
            "name": "Magnus",
            "Meepo": 0.566
        },
        {
            "name": "Marci",
            "Meepo": 2.694
        },
        {
            "name": "Mars",
            "Meepo": -1.759
        },
        {
            "name": "Medusa",
            "Meepo": 3.603
        },
        {
            "name": "Mirana",
            "Meepo": -1.246
        },
        {
            "name": "Monkey King",
            "Meepo": 3.977
        },
        {
            "name": "Morphling",
            "Meepo": -2.769
        },
        {
            "name": "Muerta",
            "Meepo": 2.059
        },
        {
            "name": "Naga Siren",
            "Meepo": 3.1
        },
        {
            "name": "Nature's Prophet",
            "Meepo": -1.052
        },
        {
            "name": "Necrophos",
            "Meepo": 0.922
        },
        {
            "name": "Night Stalker",
            "Meepo": -3.555
        },
        {
            "name": "Nyx Assassin",
            "Meepo": -1.828
        },
        {
            "name": "Ogre Magi",
            "Meepo": -2.685
        },
        {
            "name": "Omniknight",
            "Meepo": 2.918
        },
        {
            "name": "Oracle",
            "Meepo": -2.576
        },
        {
            "name": "Outworld Destroyer",
            "Meepo": -0.289
        },
        {
            "name": "Pangolier",
            "Meepo": 0.652
        },
        {
            "name": "Phantom Assassin",
            "Meepo": -0.378
        },
        {
            "name": "Phantom Lancer",
            "Meepo": -2.446
        },
        {
            "name": "Phoenix",
            "Meepo": -0.502
        },
        {
            "name": "Primal Beast",
            "Meepo": 0.561
        },
        {
            "name": "Puck",
            "Meepo": -1.283
        },
        {
            "name": "Pudge",
            "Meepo": -0.23
        },
        {
            "name": "Pugna",
            "Meepo": -0.196
        },
        {
            "name": "Queen of Pain",
            "Meepo": -3.772
        },
        {
            "name": "Razor",
            "Meepo": 0.478
        },
        {
            "name": "Riki",
            "Meepo": -0.278
        },
        {
            "name": "Ringmaster",
            "Meepo": 1.85
        },
        {
            "name": "Rubick",
            "Meepo": -0.453
        },
        {
            "name": "Sand King",
            "Meepo": 1.761
        },
        {
            "name": "Shadow Demon",
            "Meepo": -1.756
        },
        {
            "name": "Shadow Fiend",
            "Meepo": 4.979
        },
        {
            "name": "Shadow Shaman",
            "Meepo": -2.009
        },
        {
            "name": "Silencer",
            "Meepo": -0.015
        },
        {
            "name": "Skywrath Mage",
            "Meepo": -1.308
        },
        {
            "name": "Slardar",
            "Meepo": 0.237
        },
        {
            "name": "Slark",
            "Meepo": 1.678
        },
        {
            "name": "Snapfire",
            "Meepo": 1.913
        },
        {
            "name": "Sniper",
            "Meepo": -2.156
        },
        {
            "name": "Spectre",
            "Meepo": -4.435
        },
        {
            "name": "Spirit Breaker",
            "Meepo": -2.974
        },
        {
            "name": "Storm Spirit",
            "Meepo": -4.695
        },
        {
            "name": "Sven",
            "Meepo": 4.372
        },
        {
            "name": "Techies",
            "Meepo": 3.712
        },
        {
            "name": "Templar Assassin",
            "Meepo": -0.884
        },
        {
            "name": "Terrorblade",
            "Meepo": 1.316
        },
        {
            "name": "Tidehunter",
            "Meepo": -1.054
        },
        {
            "name": "Timbersaw",
            "Meepo": 3.374
        },
        {
            "name": "Tinker",
            "Meepo": 1.311
        },
        {
            "name": "Tiny",
            "Meepo": 0.728
        },
        {
            "name": "Treant Protector",
            "Meepo": 2.608
        },
        {
            "name": "Troll Warlord",
            "Meepo": 6.673
        },
        {
            "name": "Tusk",
            "Meepo": -0.048
        },
        {
            "name": "Underlord",
            "Meepo": 3.276
        },
        {
            "name": "Undying",
            "Meepo": -2.011
        },
        {
            "name": "Ursa",
            "Meepo": 0.856
        },
        {
            "name": "Vengeful Spirit",
            "Meepo": -1.746
        },
        {
            "name": "Venomancer",
            "Meepo": 1.461
        },
        {
            "name": "Viper",
            "Meepo": -1.474
        },
        {
            "name": "Visage",
            "Meepo": -0.752
        },
        {
            "name": "Void Spirit",
            "Meepo": -5.255
        },
        {
            "name": "Warlock",
            "Meepo": 4.16
        },
        {
            "name": "Weaver",
            "Meepo": -1.434
        },
        {
            "name": "Windranger",
            "Meepo": 1.189
        },
        {
            "name": "Winter Wyvern",
            "Meepo": 6.388
        },
        {
            "name": "Witch Doctor",
            "Meepo": 2.162
        },
        {
            "name": "Wraith King",
            "Meepo": -3.386
        },
        {
            "name": "Zeus",
            "Meepo": -4.1
        }
    ],
    "Monkey King": [
        {
            "name": "Abaddon",
            "Monkey King": -2.821
        },
        {
            "name": "Alchemist",
            "Monkey King": -1.318
        },
        {
            "name": "Ancient Apparition",
            "Monkey King": 1.38
        },
        {
            "name": "Anti-Mage",
            "Monkey King": -2.186
        },
        {
            "name": "Arc Warden",
            "Monkey King": 0.335
        },
        {
            "name": "Axe",
            "Monkey King": -0.516
        },
        {
            "name": "Bane",
            "Monkey King": 0.381
        },
        {
            "name": "Batrider",
            "Monkey King": 2.245
        },
        {
            "name": "Beastmaster",
            "Monkey King": -0.103
        },
        {
            "name": "Bloodseeker",
            "Monkey King": -1.622
        },
        {
            "name": "Bounty Hunter",
            "Monkey King": 0.403
        },
        {
            "name": "Brewmaster",
            "Monkey King": -0.785
        },
        {
            "name": "Bristleback",
            "Monkey King": -0.018
        },
        {
            "name": "Broodmother",
            "Monkey King": -2.407
        },
        {
            "name": "Centaur Warrunner",
            "Monkey King": -1.488
        },
        {
            "name": "Chaos Knight",
            "Monkey King": -1.464
        },
        {
            "name": "Chen",
            "Monkey King": 0.284
        },
        {
            "name": "Clinkz",
            "Monkey King": 0.682
        },
        {
            "name": "Clockwerk",
            "Monkey King": -0.792
        },
        {
            "name": "Crystal Maiden",
            "Monkey King": -0.523
        },
        {
            "name": "Dark Seer",
            "Monkey King": -0.79
        },
        {
            "name": "Dark Willow",
            "Monkey King": 0.639
        },
        {
            "name": "Dawnbreaker",
            "Monkey King": -1.488
        },
        {
            "name": "Dazzle",
            "Monkey King": -0.685
        },
        {
            "name": "Death Prophet",
            "Monkey King": -0.094
        },
        {
            "name": "Disruptor",
            "Monkey King": -0.13
        },
        {
            "name": "Doom",
            "Monkey King": -0.891
        },
        {
            "name": "Dragon Knight",
            "Monkey King": -0.727
        },
        {
            "name": "Drow Ranger",
            "Monkey King": -0.382
        },
        {
            "name": "Earth Spirit",
            "Monkey King": -1.956
        },
        {
            "name": "Earthshaker",
            "Monkey King": -0.553
        },
        {
            "name": "Elder Titan",
            "Monkey King": -0.007
        },
        {
            "name": "Ember Spirit",
            "Monkey King": -3.817
        },
        {
            "name": "Enchantress",
            "Monkey King": 0.729
        },
        {
            "name": "Enigma",
            "Monkey King": 1.089
        },
        {
            "name": "Faceless Void",
            "Monkey King": -0.583
        },
        {
            "name": "Grimstroke",
            "Monkey King": -1.001
        },
        {
            "name": "Gyrocopter",
            "Monkey King": 0.371
        },
        {
            "name": "Hoodwink",
            "Monkey King": 1.181
        },
        {
            "name": "Huskar",
            "Monkey King": 0.235
        },
        {
            "name": "Invoker",
            "Monkey King": 1.234
        },
        {
            "name": "Io",
            "Monkey King": -2.033
        },
        {
            "name": "Jakiro",
            "Monkey King": 1.099
        },
        {
            "name": "Juggernaut",
            "Monkey King": 0.473
        },
        {
            "name": "Keeper of the Light",
            "Monkey King": 2.067
        },
        {
            "name": "Kez",
            "Monkey King": -1.932
        },
        {
            "name": "Kunkka",
            "Monkey King": -0.756
        },
        {
            "name": "Legion Commander",
            "Monkey King": -2.316
        },
        {
            "name": "Leshrac",
            "Monkey King": 3.065
        },
        {
            "name": "Lich",
            "Monkey King": 1.229
        },
        {
            "name": "Lifestealer",
            "Monkey King": -1.27
        },
        {
            "name": "Lina",
            "Monkey King": 0.614
        },
        {
            "name": "Lion",
            "Monkey King": 0.005
        },
        {
            "name": "Lone Druid",
            "Monkey King": -5.192
        },
        {
            "name": "Luna",
            "Monkey King": 1.215
        },
        {
            "name": "Lycan",
            "Monkey King": -2.902
        },
        {
            "name": "Magnus",
            "Monkey King": -0.135
        },
        {
            "name": "Marci",
            "Monkey King": -1.683
        },
        {
            "name": "Mars",
            "Monkey King": -0.546
        },
        {
            "name": "Medusa",
            "Monkey King": -3.922
        },
        {
            "name": "Meepo",
            "Monkey King": -3.993
        },
        {
            "name": "Mirana",
            "Monkey King": 0.889
        },
        {
            "name": "Morphling",
            "Monkey King": -0.431
        },
        {
            "name": "Muerta",
            "Monkey King": 3.368
        },
        {
            "name": "Naga Siren",
            "Monkey King": -0.91
        },
        {
            "name": "Nature's Prophet",
            "Monkey King": -0.112
        },
        {
            "name": "Necrophos",
            "Monkey King": 0.023
        },
        {
            "name": "Night Stalker",
            "Monkey King": -1.225
        },
        {
            "name": "Nyx Assassin",
            "Monkey King": -0.352
        },
        {
            "name": "Ogre Magi",
            "Monkey King": 0.711
        },
        {
            "name": "Omniknight",
            "Monkey King": -1.818
        },
        {
            "name": "Oracle",
            "Monkey King": -1.13
        },
        {
            "name": "Outworld Destroyer",
            "Monkey King": 0.966
        },
        {
            "name": "Pangolier",
            "Monkey King": -4.113
        },
        {
            "name": "Phantom Assassin",
            "Monkey King": 0.611
        },
        {
            "name": "Phantom Lancer",
            "Monkey King": -1.519
        },
        {
            "name": "Phoenix",
            "Monkey King": 1.917
        },
        {
            "name": "Primal Beast",
            "Monkey King": 0.193
        },
        {
            "name": "Puck",
            "Monkey King": -1.102
        },
        {
            "name": "Pudge",
            "Monkey King": -1.447
        },
        {
            "name": "Pugna",
            "Monkey King": 0.817
        },
        {
            "name": "Queen of Pain",
            "Monkey King": 1.424
        },
        {
            "name": "Razor",
            "Monkey King": 0.386
        },
        {
            "name": "Riki",
            "Monkey King": 0.019
        },
        {
            "name": "Ringmaster",
            "Monkey King": 0.885
        },
        {
            "name": "Rubick",
            "Monkey King": -0.465
        },
        {
            "name": "Sand King",
            "Monkey King": 0.417
        },
        {
            "name": "Shadow Demon",
            "Monkey King": -0.337
        },
        {
            "name": "Shadow Fiend",
            "Monkey King": 0.026
        },
        {
            "name": "Shadow Shaman",
            "Monkey King": -0.227
        },
        {
            "name": "Silencer",
            "Monkey King": -1.135
        },
        {
            "name": "Skywrath Mage",
            "Monkey King": 0.406
        },
        {
            "name": "Slardar",
            "Monkey King": -0.639
        },
        {
            "name": "Slark",
            "Monkey King": 0.74
        },
        {
            "name": "Snapfire",
            "Monkey King": 1.434
        },
        {
            "name": "Sniper",
            "Monkey King": 1.217
        },
        {
            "name": "Spectre",
            "Monkey King": -0.543
        },
        {
            "name": "Spirit Breaker",
            "Monkey King": -0.123
        },
        {
            "name": "Storm Spirit",
            "Monkey King": 0.922
        },
        {
            "name": "Sven",
            "Monkey King": -0.517
        },
        {
            "name": "Techies",
            "Monkey King": 2.88
        },
        {
            "name": "Templar Assassin",
            "Monkey King": -0.325
        },
        {
            "name": "Terrorblade",
            "Monkey King": 0.43
        },
        {
            "name": "Tidehunter",
            "Monkey King": -1.901
        },
        {
            "name": "Timbersaw",
            "Monkey King": 0.942
        },
        {
            "name": "Tinker",
            "Monkey King": 1.255
        },
        {
            "name": "Tiny",
            "Monkey King": -1.299
        },
        {
            "name": "Treant Protector",
            "Monkey King": -0.068
        },
        {
            "name": "Troll Warlord",
            "Monkey King": 1.171
        },
        {
            "name": "Tusk",
            "Monkey King": -1.891
        },
        {
            "name": "Underlord",
            "Monkey King": -1.165
        },
        {
            "name": "Undying",
            "Monkey King": -1.469
        },
        {
            "name": "Ursa",
            "Monkey King": 0.425
        },
        {
            "name": "Vengeful Spirit",
            "Monkey King": 0.294
        },
        {
            "name": "Venomancer",
            "Monkey King": 0.918
        },
        {
            "name": "Viper",
            "Monkey King": 1.25
        },
        {
            "name": "Visage",
            "Monkey King": 0.149
        },
        {
            "name": "Void Spirit",
            "Monkey King": -2.675
        },
        {
            "name": "Warlock",
            "Monkey King": -0.937
        },
        {
            "name": "Weaver",
            "Monkey King": 1.441
        },
        {
            "name": "Windranger",
            "Monkey King": 1.979
        },
        {
            "name": "Winter Wyvern",
            "Monkey King": 0.602
        },
        {
            "name": "Witch Doctor",
            "Monkey King": 0.687
        },
        {
            "name": "Wraith King",
            "Monkey King": -1.743
        },
        {
            "name": "Zeus",
            "Monkey King": 1.771
        }
    ],
    "Morphling": [
        {
            "name": "Abaddon",
            "Morphling": -0.305
        },
        {
            "name": "Alchemist",
            "Morphling": -0.205
        },
        {
            "name": "Ancient Apparition",
            "Morphling": 1.512
        },
        {
            "name": "Anti-Mage",
            "Morphling": 2.311
        },
        {
            "name": "Arc Warden",
            "Morphling": -1.231
        },
        {
            "name": "Axe",
            "Morphling": 2.345
        },
        {
            "name": "Bane",
            "Morphling": 2.349
        },
        {
            "name": "Batrider",
            "Morphling": -0.035
        },
        {
            "name": "Beastmaster",
            "Morphling": 2.159
        },
        {
            "name": "Bloodseeker",
            "Morphling": -1.336
        },
        {
            "name": "Bounty Hunter",
            "Morphling": 0.493
        },
        {
            "name": "Brewmaster",
            "Morphling": -1.259
        },
        {
            "name": "Bristleback",
            "Morphling": -2.122
        },
        {
            "name": "Broodmother",
            "Morphling": 0.693
        },
        {
            "name": "Centaur Warrunner",
            "Morphling": 0.243
        },
        {
            "name": "Chaos Knight",
            "Morphling": 0.134
        },
        {
            "name": "Chen",
            "Morphling": 0.721
        },
        {
            "name": "Clinkz",
            "Morphling": -1.888
        },
        {
            "name": "Clockwerk",
            "Morphling": -1.819
        },
        {
            "name": "Crystal Maiden",
            "Morphling": -0.37
        },
        {
            "name": "Dark Seer",
            "Morphling": 0.083
        },
        {
            "name": "Dark Willow",
            "Morphling": -0.09
        },
        {
            "name": "Dawnbreaker",
            "Morphling": -2.163
        },
        {
            "name": "Dazzle",
            "Morphling": -0.181
        },
        {
            "name": "Death Prophet",
            "Morphling": -0.037
        },
        {
            "name": "Disruptor",
            "Morphling": -0.609
        },
        {
            "name": "Doom",
            "Morphling": 2.986
        },
        {
            "name": "Dragon Knight",
            "Morphling": 0.406
        },
        {
            "name": "Drow Ranger",
            "Morphling": -0.478
        },
        {
            "name": "Earth Spirit",
            "Morphling": -0.038
        },
        {
            "name": "Earthshaker",
            "Morphling": 1.943
        },
        {
            "name": "Elder Titan",
            "Morphling": 0.872
        },
        {
            "name": "Ember Spirit",
            "Morphling": -2.354
        },
        {
            "name": "Enchantress",
            "Morphling": -1.238
        },
        {
            "name": "Enigma",
            "Morphling": -0.243
        },
        {
            "name": "Faceless Void",
            "Morphling": -0.212
        },
        {
            "name": "Grimstroke",
            "Morphling": 0.349
        },
        {
            "name": "Gyrocopter",
            "Morphling": -0.159
        },
        {
            "name": "Hoodwink",
            "Morphling": 0.436
        },
        {
            "name": "Huskar",
            "Morphling": -0.846
        },
        {
            "name": "Invoker",
            "Morphling": -0.726
        },
        {
            "name": "Io",
            "Morphling": 0.515
        },
        {
            "name": "Jakiro",
            "Morphling": -1.174
        },
        {
            "name": "Juggernaut",
            "Morphling": -2.807
        },
        {
            "name": "Keeper of the Light",
            "Morphling": 1.08
        },
        {
            "name": "Kez",
            "Morphling": 1.064
        },
        {
            "name": "Kunkka",
            "Morphling": -0.233
        },
        {
            "name": "Legion Commander",
            "Morphling": -0.081
        },
        {
            "name": "Leshrac",
            "Morphling": 0.094
        },
        {
            "name": "Lich",
            "Morphling": -0.343
        },
        {
            "name": "Lifestealer",
            "Morphling": -1.315
        },
        {
            "name": "Lina",
            "Morphling": 2.94
        },
        {
            "name": "Lion",
            "Morphling": 1.902
        },
        {
            "name": "Lone Druid",
            "Morphling": 0.521
        },
        {
            "name": "Luna",
            "Morphling": 1.318
        },
        {
            "name": "Lycan",
            "Morphling": -0.366
        },
        {
            "name": "Magnus",
            "Morphling": -0.201
        },
        {
            "name": "Marci",
            "Morphling": 1.186
        },
        {
            "name": "Mars",
            "Morphling": -0.683
        },
        {
            "name": "Medusa",
            "Morphling": 1.667
        },
        {
            "name": "Meepo",
            "Morphling": 2.486
        },
        {
            "name": "Mirana",
            "Morphling": 0.117
        },
        {
            "name": "Monkey King",
            "Morphling": 0.493
        },
        {
            "name": "Muerta",
            "Morphling": 0.906
        },
        {
            "name": "Naga Siren",
            "Morphling": 0.292
        },
        {
            "name": "Nature's Prophet",
            "Morphling": -1.441
        },
        {
            "name": "Necrophos",
            "Morphling": 0.085
        },
        {
            "name": "Night Stalker",
            "Morphling": -0.02
        },
        {
            "name": "Nyx Assassin",
            "Morphling": 3.015
        },
        {
            "name": "Ogre Magi",
            "Morphling": 0.235
        },
        {
            "name": "Omniknight",
            "Morphling": -0.026
        },
        {
            "name": "Oracle",
            "Morphling": -0.186
        },
        {
            "name": "Outworld Destroyer",
            "Morphling": 0.707
        },
        {
            "name": "Pangolier",
            "Morphling": -2.681
        },
        {
            "name": "Phantom Assassin",
            "Morphling": -1.942
        },
        {
            "name": "Phantom Lancer",
            "Morphling": 1.874
        },
        {
            "name": "Phoenix",
            "Morphling": -1.47
        },
        {
            "name": "Primal Beast",
            "Morphling": -0.987
        },
        {
            "name": "Puck",
            "Morphling": 0.435
        },
        {
            "name": "Pudge",
            "Morphling": -0.291
        },
        {
            "name": "Pugna",
            "Morphling": -0.053
        },
        {
            "name": "Queen of Pain",
            "Morphling": -1.427
        },
        {
            "name": "Razor",
            "Morphling": -3.032
        },
        {
            "name": "Riki",
            "Morphling": 0.959
        },
        {
            "name": "Ringmaster",
            "Morphling": -0.126
        },
        {
            "name": "Rubick",
            "Morphling": 2.29
        },
        {
            "name": "Sand King",
            "Morphling": -0.841
        },
        {
            "name": "Shadow Demon",
            "Morphling": -0.791
        },
        {
            "name": "Shadow Fiend",
            "Morphling": 0.332
        },
        {
            "name": "Shadow Shaman",
            "Morphling": 0.801
        },
        {
            "name": "Silencer",
            "Morphling": -2.218
        },
        {
            "name": "Skywrath Mage",
            "Morphling": 0.337
        },
        {
            "name": "Slardar",
            "Morphling": -0.899
        },
        {
            "name": "Slark",
            "Morphling": 1.369
        },
        {
            "name": "Snapfire",
            "Morphling": -0.116
        },
        {
            "name": "Sniper",
            "Morphling": -2.727
        },
        {
            "name": "Spectre",
            "Morphling": -0.519
        },
        {
            "name": "Spirit Breaker",
            "Morphling": -0.824
        },
        {
            "name": "Storm Spirit",
            "Morphling": -0.454
        },
        {
            "name": "Sven",
            "Morphling": -0.079
        },
        {
            "name": "Techies",
            "Morphling": 1.458
        },
        {
            "name": "Templar Assassin",
            "Morphling": -0.135
        },
        {
            "name": "Terrorblade",
            "Morphling": 2.281
        },
        {
            "name": "Tidehunter",
            "Morphling": 1.019
        },
        {
            "name": "Timbersaw",
            "Morphling": 1.232
        },
        {
            "name": "Tinker",
            "Morphling": 1.333
        },
        {
            "name": "Tiny",
            "Morphling": 1.498
        },
        {
            "name": "Treant Protector",
            "Morphling": -0.934
        },
        {
            "name": "Troll Warlord",
            "Morphling": 1.181
        },
        {
            "name": "Tusk",
            "Morphling": -1.251
        },
        {
            "name": "Underlord",
            "Morphling": -0.632
        },
        {
            "name": "Undying",
            "Morphling": -1.071
        },
        {
            "name": "Ursa",
            "Morphling": 0.935
        },
        {
            "name": "Vengeful Spirit",
            "Morphling": -0.238
        },
        {
            "name": "Venomancer",
            "Morphling": -1.86
        },
        {
            "name": "Viper",
            "Morphling": -3.13
        },
        {
            "name": "Visage",
            "Morphling": 0.211
        },
        {
            "name": "Void Spirit",
            "Morphling": -0.327
        },
        {
            "name": "Warlock",
            "Morphling": -1.573
        },
        {
            "name": "Weaver",
            "Morphling": -1.45
        },
        {
            "name": "Windranger",
            "Morphling": 0.678
        },
        {
            "name": "Winter Wyvern",
            "Morphling": -0.614
        },
        {
            "name": "Witch Doctor",
            "Morphling": -0.954
        },
        {
            "name": "Wraith King",
            "Morphling": -1.811
        },
        {
            "name": "Zeus",
            "Morphling": 1.125
        }
    ],
    "Naga Siren": [
        {
            "name": "Abaddon",
            "Naga Siren": 1.104
        },
        {
            "name": "Alchemist",
            "Naga Siren": 0.655
        },
        {
            "name": "Ancient Apparition",
            "Naga Siren": 1.856
        },
        {
            "name": "Anti-Mage",
            "Naga Siren": 0.304
        },
        {
            "name": "Arc Warden",
            "Naga Siren": -1.235
        },
        {
            "name": "Axe",
            "Naga Siren": 5.471
        },
        {
            "name": "Bane",
            "Naga Siren": -3.298
        },
        {
            "name": "Batrider",
            "Naga Siren": -1.839
        },
        {
            "name": "Beastmaster",
            "Naga Siren": -1.468
        },
        {
            "name": "Bloodseeker",
            "Naga Siren": 0.555
        },
        {
            "name": "Bounty Hunter",
            "Naga Siren": -3.486
        },
        {
            "name": "Brewmaster",
            "Naga Siren": 1.949
        },
        {
            "name": "Bristleback",
            "Naga Siren": 0.426
        },
        {
            "name": "Broodmother",
            "Naga Siren": -6.605
        },
        {
            "name": "Centaur Warrunner",
            "Naga Siren": 3.235
        },
        {
            "name": "Chaos Knight",
            "Naga Siren": -2.173
        },
        {
            "name": "Chen",
            "Naga Siren": -4.413
        },
        {
            "name": "Clinkz",
            "Naga Siren": -5.485
        },
        {
            "name": "Clockwerk",
            "Naga Siren": -1.658
        },
        {
            "name": "Crystal Maiden",
            "Naga Siren": 3.386
        },
        {
            "name": "Dark Seer",
            "Naga Siren": 0.338
        },
        {
            "name": "Dark Willow",
            "Naga Siren": -3.24
        },
        {
            "name": "Dawnbreaker",
            "Naga Siren": 3.421
        },
        {
            "name": "Dazzle",
            "Naga Siren": 3.504
        },
        {
            "name": "Death Prophet",
            "Naga Siren": 1.522
        },
        {
            "name": "Disruptor",
            "Naga Siren": 0.097
        },
        {
            "name": "Doom",
            "Naga Siren": -1.195
        },
        {
            "name": "Dragon Knight",
            "Naga Siren": 2.204
        },
        {
            "name": "Drow Ranger",
            "Naga Siren": -1.736
        },
        {
            "name": "Earth Spirit",
            "Naga Siren": -1.357
        },
        {
            "name": "Earthshaker",
            "Naga Siren": 2.736
        },
        {
            "name": "Elder Titan",
            "Naga Siren": 0.75
        },
        {
            "name": "Ember Spirit",
            "Naga Siren": -2.587
        },
        {
            "name": "Enchantress",
            "Naga Siren": 2.139
        },
        {
            "name": "Enigma",
            "Naga Siren": 1.699
        },
        {
            "name": "Faceless Void",
            "Naga Siren": -1.895
        },
        {
            "name": "Grimstroke",
            "Naga Siren": 1.34
        },
        {
            "name": "Gyrocopter",
            "Naga Siren": 2.939
        },
        {
            "name": "Hoodwink",
            "Naga Siren": -0.765
        },
        {
            "name": "Huskar",
            "Naga Siren": -3.925
        },
        {
            "name": "Invoker",
            "Naga Siren": -0.607
        },
        {
            "name": "Io",
            "Naga Siren": -2.172
        },
        {
            "name": "Jakiro",
            "Naga Siren": 4.455
        },
        {
            "name": "Juggernaut",
            "Naga Siren": 1.969
        },
        {
            "name": "Keeper of the Light",
            "Naga Siren": -0.97
        },
        {
            "name": "Kez",
            "Naga Siren": 1.0
        },
        {
            "name": "Kunkka",
            "Naga Siren": 0.635
        },
        {
            "name": "Legion Commander",
            "Naga Siren": 1.758
        },
        {
            "name": "Leshrac",
            "Naga Siren": 3.486
        },
        {
            "name": "Lich",
            "Naga Siren": 1.451
        },
        {
            "name": "Lifestealer",
            "Naga Siren": -1.281
        },
        {
            "name": "Lina",
            "Naga Siren": 3.577
        },
        {
            "name": "Lion",
            "Naga Siren": 1.773
        },
        {
            "name": "Lone Druid",
            "Naga Siren": -1.571
        },
        {
            "name": "Luna",
            "Naga Siren": 1.909
        },
        {
            "name": "Lycan",
            "Naga Siren": -4.8
        },
        {
            "name": "Magnus",
            "Naga Siren": 2.014
        },
        {
            "name": "Marci",
            "Naga Siren": -1.622
        },
        {
            "name": "Mars",
            "Naga Siren": -1.136
        },
        {
            "name": "Medusa",
            "Naga Siren": 2.307
        },
        {
            "name": "Meepo",
            "Naga Siren": -2.927
        },
        {
            "name": "Mirana",
            "Naga Siren": 1.558
        },
        {
            "name": "Monkey King",
            "Naga Siren": 0.951
        },
        {
            "name": "Morphling",
            "Naga Siren": -0.131
        },
        {
            "name": "Muerta",
            "Naga Siren": -1.314
        },
        {
            "name": "Nature's Prophet",
            "Naga Siren": -0.576
        },
        {
            "name": "Necrophos",
            "Naga Siren": 4.407
        },
        {
            "name": "Night Stalker",
            "Naga Siren": -6.292
        },
        {
            "name": "Nyx Assassin",
            "Naga Siren": -0.56
        },
        {
            "name": "Ogre Magi",
            "Naga Siren": -3.484
        },
        {
            "name": "Omniknight",
            "Naga Siren": 6.626
        },
        {
            "name": "Oracle",
            "Naga Siren": -1.917
        },
        {
            "name": "Outworld Destroyer",
            "Naga Siren": -1.708
        },
        {
            "name": "Pangolier",
            "Naga Siren": 5.921
        },
        {
            "name": "Phantom Assassin",
            "Naga Siren": -0.506
        },
        {
            "name": "Phantom Lancer",
            "Naga Siren": -2.565
        },
        {
            "name": "Phoenix",
            "Naga Siren": 1.998
        },
        {
            "name": "Primal Beast",
            "Naga Siren": 2.024
        },
        {
            "name": "Puck",
            "Naga Siren": 2.031
        },
        {
            "name": "Pudge",
            "Naga Siren": 0.42
        },
        {
            "name": "Pugna",
            "Naga Siren": 2.737
        },
        {
            "name": "Queen of Pain",
            "Naga Siren": 0.991
        },
        {
            "name": "Razor",
            "Naga Siren": -0.939
        },
        {
            "name": "Riki",
            "Naga Siren": -3.69
        },
        {
            "name": "Ringmaster",
            "Naga Siren": -1.848
        },
        {
            "name": "Rubick",
            "Naga Siren": 0.541
        },
        {
            "name": "Sand King",
            "Naga Siren": 4.902
        },
        {
            "name": "Shadow Demon",
            "Naga Siren": -1.849
        },
        {
            "name": "Shadow Fiend",
            "Naga Siren": -0.301
        },
        {
            "name": "Shadow Shaman",
            "Naga Siren": -1.385
        },
        {
            "name": "Silencer",
            "Naga Siren": -2.133
        },
        {
            "name": "Skywrath Mage",
            "Naga Siren": -0.663
        },
        {
            "name": "Slardar",
            "Naga Siren": -1.11
        },
        {
            "name": "Slark",
            "Naga Siren": -1.743
        },
        {
            "name": "Snapfire",
            "Naga Siren": 2.375
        },
        {
            "name": "Sniper",
            "Naga Siren": -0.702
        },
        {
            "name": "Spectre",
            "Naga Siren": -1.591
        },
        {
            "name": "Spirit Breaker",
            "Naga Siren": -1.673
        },
        {
            "name": "Storm Spirit",
            "Naga Siren": -2.389
        },
        {
            "name": "Sven",
            "Naga Siren": 5.083
        },
        {
            "name": "Techies",
            "Naga Siren": 1.917
        },
        {
            "name": "Templar Assassin",
            "Naga Siren": -3.911
        },
        {
            "name": "Terrorblade",
            "Naga Siren": -4.303
        },
        {
            "name": "Tidehunter",
            "Naga Siren": 1.498
        },
        {
            "name": "Timbersaw",
            "Naga Siren": 6.523
        },
        {
            "name": "Tinker",
            "Naga Siren": -2.513
        },
        {
            "name": "Tiny",
            "Naga Siren": 3.071
        },
        {
            "name": "Treant Protector",
            "Naga Siren": 0.539
        },
        {
            "name": "Troll Warlord",
            "Naga Siren": -2.483
        },
        {
            "name": "Tusk",
            "Naga Siren": 0.023
        },
        {
            "name": "Underlord",
            "Naga Siren": 2.303
        },
        {
            "name": "Undying",
            "Naga Siren": -2.456
        },
        {
            "name": "Ursa",
            "Naga Siren": -2.962
        },
        {
            "name": "Vengeful Spirit",
            "Naga Siren": -1.574
        },
        {
            "name": "Venomancer",
            "Naga Siren": -0.804
        },
        {
            "name": "Viper",
            "Naga Siren": -4.281
        },
        {
            "name": "Visage",
            "Naga Siren": -6.022
        },
        {
            "name": "Void Spirit",
            "Naga Siren": -0.609
        },
        {
            "name": "Warlock",
            "Naga Siren": 4.716
        },
        {
            "name": "Weaver",
            "Naga Siren": -3.02
        },
        {
            "name": "Windranger",
            "Naga Siren": -0.704
        },
        {
            "name": "Winter Wyvern",
            "Naga Siren": -1.682
        },
        {
            "name": "Witch Doctor",
            "Naga Siren": -1.675
        },
        {
            "name": "Wraith King",
            "Naga Siren": -2.852
        },
        {
            "name": "Zeus",
            "Naga Siren": -1.282
        }
    ],
    "Phantom Assassin": [
        {
            "name": "Abaddon",
            "Phantom Assassin": -0.776
        },
        {
            "name": "Alchemist",
            "Phantom Assassin": -0.494
        },
        {
            "name": "Ancient Apparition",
            "Phantom Assassin": -1.954
        },
        {
            "name": "Anti-Mage",
            "Phantom Assassin": -4.822
        },
        {
            "name": "Arc Warden",
            "Phantom Assassin": -1.195
        },
        {
            "name": "Axe",
            "Phantom Assassin": 3.235
        },
        {
            "name": "Bane",
            "Phantom Assassin": 1.743
        },
        {
            "name": "Batrider",
            "Phantom Assassin": 1.41
        },
        {
            "name": "Beastmaster",
            "Phantom Assassin": 0.295
        },
        {
            "name": "Bloodseeker",
            "Phantom Assassin": -0.073
        },
        {
            "name": "Bounty Hunter",
            "Phantom Assassin": 1.074
        },
        {
            "name": "Brewmaster",
            "Phantom Assassin": 0.485
        },
        {
            "name": "Bristleback",
            "Phantom Assassin": 0.468
        },
        {
            "name": "Broodmother",
            "Phantom Assassin": -2.656
        },
        {
            "name": "Centaur Warrunner",
            "Phantom Assassin": 1.091
        },
        {
            "name": "Chaos Knight",
            "Phantom Assassin": -0.61
        },
        {
            "name": "Chen",
            "Phantom Assassin": -0.424
        },
        {
            "name": "Clinkz",
            "Phantom Assassin": -1.687
        },
        {
            "name": "Clockwerk",
            "Phantom Assassin": 0.47
        },
        {
            "name": "Crystal Maiden",
            "Phantom Assassin": -0.132
        },
        {
            "name": "Dark Seer",
            "Phantom Assassin": -0.669
        },
        {
            "name": "Dark Willow",
            "Phantom Assassin": -0.177
        },
        {
            "name": "Dawnbreaker",
            "Phantom Assassin": 1.804
        },
        {
            "name": "Dazzle",
            "Phantom Assassin": 0.106
        },
        {
            "name": "Death Prophet",
            "Phantom Assassin": 0.902
        },
        {
            "name": "Disruptor",
            "Phantom Assassin": -0.543
        },
        {
            "name": "Doom",
            "Phantom Assassin": 1.273
        },
        {
            "name": "Dragon Knight",
            "Phantom Assassin": 0.911
        },
        {
            "name": "Drow Ranger",
            "Phantom Assassin": 0.851
        },
        {
            "name": "Earth Spirit",
            "Phantom Assassin": 1.834
        },
        {
            "name": "Earthshaker",
            "Phantom Assassin": -0.677
        },
        {
            "name": "Elder Titan",
            "Phantom Assassin": -3.275
        },
        {
            "name": "Ember Spirit",
            "Phantom Assassin": 0.002
        },
        {
            "name": "Enchantress",
            "Phantom Assassin": -3.64
        },
        {
            "name": "Enigma",
            "Phantom Assassin": -0.73
        },
        {
            "name": "Faceless Void",
            "Phantom Assassin": 0.953
        },
        {
            "name": "Grimstroke",
            "Phantom Assassin": 0.207
        },
        {
            "name": "Gyrocopter",
            "Phantom Assassin": 0.525
        },
        {
            "name": "Hoodwink",
            "Phantom Assassin": -0.552
        },
        {
            "name": "Huskar",
            "Phantom Assassin": -2.717
        },
        {
            "name": "Invoker",
            "Phantom Assassin": -1.282
        },
        {
            "name": "Io",
            "Phantom Assassin": -0.27
        },
        {
            "name": "Jakiro",
            "Phantom Assassin": 0.45
        },
        {
            "name": "Juggernaut",
            "Phantom Assassin": -0.103
        },
        {
            "name": "Keeper of the Light",
            "Phantom Assassin": -1.119
        },
        {
            "name": "Kez",
            "Phantom Assassin": -0.85
        },
        {
            "name": "Kunkka",
            "Phantom Assassin": -0.123
        },
        {
            "name": "Legion Commander",
            "Phantom Assassin": -0.335
        },
        {
            "name": "Leshrac",
            "Phantom Assassin": 1.089
        },
        {
            "name": "Lich",
            "Phantom Assassin": 0.535
        },
        {
            "name": "Lifestealer",
            "Phantom Assassin": -2.146
        },
        {
            "name": "Lina",
            "Phantom Assassin": -0.805
        },
        {
            "name": "Lion",
            "Phantom Assassin": -0.143
        },
        {
            "name": "Lone Druid",
            "Phantom Assassin": -3.416
        },
        {
            "name": "Luna",
            "Phantom Assassin": 2.098
        },
        {
            "name": "Lycan",
            "Phantom Assassin": -2.916
        },
        {
            "name": "Magnus",
            "Phantom Assassin": 1.024
        },
        {
            "name": "Marci",
            "Phantom Assassin": -0.281
        },
        {
            "name": "Mars",
            "Phantom Assassin": -0.487
        },
        {
            "name": "Medusa",
            "Phantom Assassin": 0.164
        },
        {
            "name": "Meepo",
            "Phantom Assassin": 0.526
        },
        {
            "name": "Mirana",
            "Phantom Assassin": 1.006
        },
        {
            "name": "Monkey King",
            "Phantom Assassin": 0.253
        },
        {
            "name": "Morphling",
            "Phantom Assassin": 1.968
        },
        {
            "name": "Muerta",
            "Phantom Assassin": 0.884
        },
        {
            "name": "Naga Siren",
            "Phantom Assassin": 1.387
        },
        {
            "name": "Nature's Prophet",
            "Phantom Assassin": -0.806
        },
        {
            "name": "Necrophos",
            "Phantom Assassin": -0.167
        },
        {
            "name": "Night Stalker",
            "Phantom Assassin": -1.607
        },
        {
            "name": "Nyx Assassin",
            "Phantom Assassin": 1.919
        },
        {
            "name": "Ogre Magi",
            "Phantom Assassin": 1.612
        },
        {
            "name": "Omniknight",
            "Phantom Assassin": 0.867
        },
        {
            "name": "Oracle",
            "Phantom Assassin": -0.933
        },
        {
            "name": "Outworld Destroyer",
            "Phantom Assassin": -1.221
        },
        {
            "name": "Pangolier",
            "Phantom Assassin": 1.73
        },
        {
            "name": "Phantom Lancer",
            "Phantom Assassin": -0.327
        },
        {
            "name": "Phoenix",
            "Phantom Assassin": 2.298
        },
        {
            "name": "Primal Beast",
            "Phantom Assassin": 3.216
        },
        {
            "name": "Puck",
            "Phantom Assassin": 1.218
        },
        {
            "name": "Pudge",
            "Phantom Assassin": 1.841
        },
        {
            "name": "Pugna",
            "Phantom Assassin": 0.232
        },
        {
            "name": "Queen of Pain",
            "Phantom Assassin": 1.44
        },
        {
            "name": "Razor",
            "Phantom Assassin": 1.039
        },
        {
            "name": "Riki",
            "Phantom Assassin": -0.976
        },
        {
            "name": "Ringmaster",
            "Phantom Assassin": -0.837
        },
        {
            "name": "Rubick",
            "Phantom Assassin": -0.437
        },
        {
            "name": "Sand King",
            "Phantom Assassin": 0.841
        },
        {
            "name": "Shadow Demon",
            "Phantom Assassin": -0.59
        },
        {
            "name": "Shadow Fiend",
            "Phantom Assassin": 1.459
        },
        {
            "name": "Shadow Shaman",
            "Phantom Assassin": -0.03
        },
        {
            "name": "Silencer",
            "Phantom Assassin": -1.306
        },
        {
            "name": "Skywrath Mage",
            "Phantom Assassin": -0.363
        },
        {
            "name": "Slardar",
            "Phantom Assassin": -0.294
        },
        {
            "name": "Slark",
            "Phantom Assassin": 0.745
        },
        {
            "name": "Snapfire",
            "Phantom Assassin": 0.229
        },
        {
            "name": "Sniper",
            "Phantom Assassin": -0.868
        },
        {
            "name": "Spectre",
            "Phantom Assassin": -0.39
        },
        {
            "name": "Spirit Breaker",
            "Phantom Assassin": 0.466
        },
        {
            "name": "Storm Spirit",
            "Phantom Assassin": 0.797
        },
        {
            "name": "Sven",
            "Phantom Assassin": -0.111
        },
        {
            "name": "Techies",
            "Phantom Assassin": 1.285
        },
        {
            "name": "Templar Assassin",
            "Phantom Assassin": 2.521
        },
        {
            "name": "Terrorblade",
            "Phantom Assassin": -0.305
        },
        {
            "name": "Tidehunter",
            "Phantom Assassin": 1.267
        },
        {
            "name": "Timbersaw",
            "Phantom Assassin": 1.926
        },
        {
            "name": "Tinker",
            "Phantom Assassin": 0.851
        },
        {
            "name": "Tiny",
            "Phantom Assassin": 1.783
        },
        {
            "name": "Treant Protector",
            "Phantom Assassin": 0.894
        },
        {
            "name": "Troll Warlord",
            "Phantom Assassin": 1.458
        },
        {
            "name": "Tusk",
            "Phantom Assassin": 1.695
        },
        {
            "name": "Underlord",
            "Phantom Assassin": -0.937
        },
        {
            "name": "Undying",
            "Phantom Assassin": -0.384
        },
        {
            "name": "Ursa",
            "Phantom Assassin": 0.32
        },
        {
            "name": "Vengeful Spirit",
            "Phantom Assassin": 0.347
        },
        {
            "name": "Venomancer",
            "Phantom Assassin": -1.262
        },
        {
            "name": "Viper",
            "Phantom Assassin": 0.3
        },
        {
            "name": "Visage",
            "Phantom Assassin": -2.017
        },
        {
            "name": "Void Spirit",
            "Phantom Assassin": 0.253
        },
        {
            "name": "Warlock",
            "Phantom Assassin": -2.093
        },
        {
            "name": "Weaver",
            "Phantom Assassin": 1.0
        },
        {
            "name": "Windranger",
            "Phantom Assassin": 1.725
        },
        {
            "name": "Winter Wyvern",
            "Phantom Assassin": -0.767
        },
        {
            "name": "Witch Doctor",
            "Phantom Assassin": -1.301
        },
        {
            "name": "Wraith King",
            "Phantom Assassin": 0.581
        },
        {
            "name": "Zeus",
            "Phantom Assassin": 0.45
        }
    ],
    "Phantom Lancer": [
        {
            "name": "Abaddon",
            "Phantom Lancer": -1.592
        },
        {
            "name": "Alchemist",
            "Phantom Lancer": 1.395
        },
        {
            "name": "Ancient Apparition",
            "Phantom Lancer": -1.666
        },
        {
            "name": "Anti-Mage",
            "Phantom Lancer": 4.09
        },
        {
            "name": "Arc Warden",
            "Phantom Lancer": -4.22
        },
        {
            "name": "Axe",
            "Phantom Lancer": 3.774
        },
        {
            "name": "Bane",
            "Phantom Lancer": -1.88
        },
        {
            "name": "Batrider",
            "Phantom Lancer": -0.654
        },
        {
            "name": "Beastmaster",
            "Phantom Lancer": 1.157
        },
        {
            "name": "Bloodseeker",
            "Phantom Lancer": 3.604
        },
        {
            "name": "Bounty Hunter",
            "Phantom Lancer": -1.793
        },
        {
            "name": "Brewmaster",
            "Phantom Lancer": -0.15
        },
        {
            "name": "Bristleback",
            "Phantom Lancer": 1.002
        },
        {
            "name": "Broodmother",
            "Phantom Lancer": -2.344
        },
        {
            "name": "Centaur Warrunner",
            "Phantom Lancer": 2.763
        },
        {
            "name": "Chaos Knight",
            "Phantom Lancer": -3.424
        },
        {
            "name": "Chen",
            "Phantom Lancer": -4.202
        },
        {
            "name": "Clinkz",
            "Phantom Lancer": -3.816
        },
        {
            "name": "Clockwerk",
            "Phantom Lancer": -2.65
        },
        {
            "name": "Crystal Maiden",
            "Phantom Lancer": 4.65
        },
        {
            "name": "Dark Seer",
            "Phantom Lancer": 3.967
        },
        {
            "name": "Dark Willow",
            "Phantom Lancer": -1.885
        },
        {
            "name": "Dawnbreaker",
            "Phantom Lancer": 1.336
        },
        {
            "name": "Dazzle",
            "Phantom Lancer": -0.858
        },
        {
            "name": "Death Prophet",
            "Phantom Lancer": -1.602
        },
        {
            "name": "Disruptor",
            "Phantom Lancer": 0.029
        },
        {
            "name": "Doom",
            "Phantom Lancer": 1.373
        },
        {
            "name": "Dragon Knight",
            "Phantom Lancer": 1.31
        },
        {
            "name": "Drow Ranger",
            "Phantom Lancer": -4.036
        },
        {
            "name": "Earth Spirit",
            "Phantom Lancer": 0.085
        },
        {
            "name": "Earthshaker",
            "Phantom Lancer": 3.074
        },
        {
            "name": "Elder Titan",
            "Phantom Lancer": 0.568
        },
        {
            "name": "Ember Spirit",
            "Phantom Lancer": 3.215
        },
        {
            "name": "Enchantress",
            "Phantom Lancer": 0.903
        },
        {
            "name": "Enigma",
            "Phantom Lancer": 0.824
        },
        {
            "name": "Faceless Void",
            "Phantom Lancer": 1.598
        },
        {
            "name": "Grimstroke",
            "Phantom Lancer": -0.199
        },
        {
            "name": "Gyrocopter",
            "Phantom Lancer": 1.532
        },
        {
            "name": "Hoodwink",
            "Phantom Lancer": -1.868
        },
        {
            "name": "Huskar",
            "Phantom Lancer": -3.023
        },
        {
            "name": "Invoker",
            "Phantom Lancer": -3.111
        },
        {
            "name": "Io",
            "Phantom Lancer": -1.646
        },
        {
            "name": "Jakiro",
            "Phantom Lancer": 1.949
        },
        {
            "name": "Juggernaut",
            "Phantom Lancer": -0.174
        },
        {
            "name": "Keeper of the Light",
            "Phantom Lancer": 0.615
        },
        {
            "name": "Kez",
            "Phantom Lancer": -0.163
        },
        {
            "name": "Kunkka",
            "Phantom Lancer": -0.417
        },
        {
            "name": "Legion Commander",
            "Phantom Lancer": 2.427
        },
        {
            "name": "Leshrac",
            "Phantom Lancer": 3.524
        },
        {
            "name": "Lich",
            "Phantom Lancer": -1.113
        },
        {
            "name": "Lifestealer",
            "Phantom Lancer": 0.427
        },
        {
            "name": "Lina",
            "Phantom Lancer": 4.156
        },
        {
            "name": "Lion",
            "Phantom Lancer": -0.509
        },
        {
            "name": "Lone Druid",
            "Phantom Lancer": -2.205
        },
        {
            "name": "Luna",
            "Phantom Lancer": -0.334
        },
        {
            "name": "Lycan",
            "Phantom Lancer": -1.792
        },
        {
            "name": "Magnus",
            "Phantom Lancer": 2.172
        },
        {
            "name": "Marci",
            "Phantom Lancer": -0.661
        },
        {
            "name": "Mars",
            "Phantom Lancer": -2.583
        },
        {
            "name": "Medusa",
            "Phantom Lancer": -0.476
        },
        {
            "name": "Meepo",
            "Phantom Lancer": 2.312
        },
        {
            "name": "Mirana",
            "Phantom Lancer": 1.141
        },
        {
            "name": "Monkey King",
            "Phantom Lancer": 1.455
        },
        {
            "name": "Morphling",
            "Phantom Lancer": -1.945
        },
        {
            "name": "Muerta",
            "Phantom Lancer": 0.0
        },
        {
            "name": "Naga Siren",
            "Phantom Lancer": 2.521
        },
        {
            "name": "Nature's Prophet",
            "Phantom Lancer": 0.91
        },
        {
            "name": "Necrophos",
            "Phantom Lancer": 4.695
        },
        {
            "name": "Night Stalker",
            "Phantom Lancer": -1.58
        },
        {
            "name": "Nyx Assassin",
            "Phantom Lancer": 1.481
        },
        {
            "name": "Ogre Magi",
            "Phantom Lancer": -4.692
        },
        {
            "name": "Omniknight",
            "Phantom Lancer": 1.571
        },
        {
            "name": "Oracle",
            "Phantom Lancer": -2.45
        },
        {
            "name": "Outworld Destroyer",
            "Phantom Lancer": -3.417
        },
        {
            "name": "Pangolier",
            "Phantom Lancer": 1.227
        },
        {
            "name": "Phantom Assassin",
            "Phantom Lancer": 0.886
        },
        {
            "name": "Phoenix",
            "Phantom Lancer": 1.315
        },
        {
            "name": "Primal Beast",
            "Phantom Lancer": 0.047
        },
        {
            "name": "Puck",
            "Phantom Lancer": 3.905
        },
        {
            "name": "Pudge",
            "Phantom Lancer": 1.036
        },
        {
            "name": "Pugna",
            "Phantom Lancer": 1.424
        },
        {
            "name": "Queen of Pain",
            "Phantom Lancer": 0.128
        },
        {
            "name": "Razor",
            "Phantom Lancer": 1.689
        },
        {
            "name": "Riki",
            "Phantom Lancer": -0.282
        },
        {
            "name": "Ringmaster",
            "Phantom Lancer": -1.121
        },
        {
            "name": "Rubick",
            "Phantom Lancer": 1.533
        },
        {
            "name": "Sand King",
            "Phantom Lancer": 7.392
        },
        {
            "name": "Shadow Demon",
            "Phantom Lancer": -2.491
        },
        {
            "name": "Shadow Fiend",
            "Phantom Lancer": -1.686
        },
        {
            "name": "Shadow Shaman",
            "Phantom Lancer": -3.835
        },
        {
            "name": "Silencer",
            "Phantom Lancer": -1.832
        },
        {
            "name": "Skywrath Mage",
            "Phantom Lancer": -0.559
        },
        {
            "name": "Slardar",
            "Phantom Lancer": -2.691
        },
        {
            "name": "Slark",
            "Phantom Lancer": -0.111
        },
        {
            "name": "Snapfire",
            "Phantom Lancer": 0.306
        },
        {
            "name": "Sniper",
            "Phantom Lancer": -0.913
        },
        {
            "name": "Spectre",
            "Phantom Lancer": -0.841
        },
        {
            "name": "Spirit Breaker",
            "Phantom Lancer": -0.23
        },
        {
            "name": "Storm Spirit",
            "Phantom Lancer": 1.335
        },
        {
            "name": "Sven",
            "Phantom Lancer": 3.719
        },
        {
            "name": "Techies",
            "Phantom Lancer": 1.794
        },
        {
            "name": "Templar Assassin",
            "Phantom Lancer": -5.278
        },
        {
            "name": "Terrorblade",
            "Phantom Lancer": -4.457
        },
        {
            "name": "Tidehunter",
            "Phantom Lancer": 3.236
        },
        {
            "name": "Timbersaw",
            "Phantom Lancer": 4.709
        },
        {
            "name": "Tinker",
            "Phantom Lancer": 0.139
        },
        {
            "name": "Tiny",
            "Phantom Lancer": 0.788
        },
        {
            "name": "Treant Protector",
            "Phantom Lancer": 0.981
        },
        {
            "name": "Troll Warlord",
            "Phantom Lancer": -1.558
        },
        {
            "name": "Tusk",
            "Phantom Lancer": 0.734
        },
        {
            "name": "Underlord",
            "Phantom Lancer": 3.839
        },
        {
            "name": "Undying",
            "Phantom Lancer": -1.857
        },
        {
            "name": "Ursa",
            "Phantom Lancer": -4.454
        },
        {
            "name": "Vengeful Spirit",
            "Phantom Lancer": -4.262
        },
        {
            "name": "Venomancer",
            "Phantom Lancer": -2.973
        },
        {
            "name": "Viper",
            "Phantom Lancer": -6.499
        },
        {
            "name": "Visage",
            "Phantom Lancer": -4.489
        },
        {
            "name": "Void Spirit",
            "Phantom Lancer": -0.777
        },
        {
            "name": "Warlock",
            "Phantom Lancer": 5.701
        },
        {
            "name": "Weaver",
            "Phantom Lancer": -1.121
        },
        {
            "name": "Windranger",
            "Phantom Lancer": 0.419
        },
        {
            "name": "Winter Wyvern",
            "Phantom Lancer": -0.794
        },
        {
            "name": "Witch Doctor",
            "Phantom Lancer": 0.677
        },
        {
            "name": "Wraith King",
            "Phantom Lancer": -4.782
        },
        {
            "name": "Zeus",
            "Phantom Lancer": 0.171
        }
    ],
    "Razor": [
        {
            "name": "Abaddon",
            "Razor": -1.066
        },
        {
            "name": "Alchemist",
            "Razor": -1.737
        },
        {
            "name": "Ancient Apparition",
            "Razor": 0.415
        },
        {
            "name": "Anti-Mage",
            "Razor": 3.046
        },
        {
            "name": "Arc Warden",
            "Razor": 0.375
        },
        {
            "name": "Axe",
            "Razor": -1.204
        },
        {
            "name": "Bane",
            "Razor": -0.676
        },
        {
            "name": "Batrider",
            "Razor": 0.473
        },
        {
            "name": "Beastmaster",
            "Razor": -0.505
        },
        {
            "name": "Bloodseeker",
            "Razor": 2.146
        },
        {
            "name": "Bounty Hunter",
            "Razor": -0.675
        },
        {
            "name": "Brewmaster",
            "Razor": 1.437
        },
        {
            "name": "Bristleback",
            "Razor": 2.295
        },
        {
            "name": "Broodmother",
            "Razor": 0.25
        },
        {
            "name": "Centaur Warrunner",
            "Razor": 0.8
        },
        {
            "name": "Chaos Knight",
            "Razor": 1.071
        },
        {
            "name": "Chen",
            "Razor": 0.544
        },
        {
            "name": "Clinkz",
            "Razor": -1.805
        },
        {
            "name": "Clockwerk",
            "Razor": 1.566
        },
        {
            "name": "Crystal Maiden",
            "Razor": 1.09
        },
        {
            "name": "Dark Seer",
            "Razor": 0.093
        },
        {
            "name": "Dark Willow",
            "Razor": 2.025
        },
        {
            "name": "Dawnbreaker",
            "Razor": 0.013
        },
        {
            "name": "Dazzle",
            "Razor": -1.384
        },
        {
            "name": "Death Prophet",
            "Razor": -1.732
        },
        {
            "name": "Disruptor",
            "Razor": -0.615
        },
        {
            "name": "Doom",
            "Razor": -0.701
        },
        {
            "name": "Dragon Knight",
            "Razor": -1.077
        },
        {
            "name": "Drow Ranger",
            "Razor": 1.079
        },
        {
            "name": "Earth Spirit",
            "Razor": 1.635
        },
        {
            "name": "Earthshaker",
            "Razor": -0.797
        },
        {
            "name": "Elder Titan",
            "Razor": 2.184
        },
        {
            "name": "Ember Spirit",
            "Razor": 2.169
        },
        {
            "name": "Enchantress",
            "Razor": 0.968
        },
        {
            "name": "Enigma",
            "Razor": 1.061
        },
        {
            "name": "Faceless Void",
            "Razor": 1.782
        },
        {
            "name": "Grimstroke",
            "Razor": -1.712
        },
        {
            "name": "Gyrocopter",
            "Razor": -0.549
        },
        {
            "name": "Hoodwink",
            "Razor": 0.217
        },
        {
            "name": "Huskar",
            "Razor": 0.628
        },
        {
            "name": "Invoker",
            "Razor": -0.246
        },
        {
            "name": "Io",
            "Razor": -0.569
        },
        {
            "name": "Jakiro",
            "Razor": -0.195
        },
        {
            "name": "Juggernaut",
            "Razor": -0.682
        },
        {
            "name": "Keeper of the Light",
            "Razor": 0.367
        },
        {
            "name": "Kez",
            "Razor": -0.585
        },
        {
            "name": "Kunkka",
            "Razor": -0.268
        },
        {
            "name": "Legion Commander",
            "Razor": 0.307
        },
        {
            "name": "Leshrac",
            "Razor": -0.018
        },
        {
            "name": "Lich",
            "Razor": 0.066
        },
        {
            "name": "Lifestealer",
            "Razor": -3.102
        },
        {
            "name": "Lina",
            "Razor": -1.339
        },
        {
            "name": "Lion",
            "Razor": -2.093
        },
        {
            "name": "Lone Druid",
            "Razor": -2.481
        },
        {
            "name": "Luna",
            "Razor": 0.366
        },
        {
            "name": "Lycan",
            "Razor": 1.902
        },
        {
            "name": "Magnus",
            "Razor": 0.989
        },
        {
            "name": "Marci",
            "Razor": 0.284
        },
        {
            "name": "Mars",
            "Razor": -0.363
        },
        {
            "name": "Medusa",
            "Razor": -0.999
        },
        {
            "name": "Meepo",
            "Razor": -0.49
        },
        {
            "name": "Mirana",
            "Razor": 1.73
        },
        {
            "name": "Monkey King",
            "Razor": -0.424
        },
        {
            "name": "Morphling",
            "Razor": 3.032
        },
        {
            "name": "Muerta",
            "Razor": 1.15
        },
        {
            "name": "Naga Siren",
            "Razor": 1.144
        },
        {
            "name": "Nature's Prophet",
            "Razor": 1.53
        },
        {
            "name": "Necrophos",
            "Razor": 1.006
        },
        {
            "name": "Night Stalker",
            "Razor": -2.049
        },
        {
            "name": "Nyx Assassin",
            "Razor": 0.495
        },
        {
            "name": "Ogre Magi",
            "Razor": 0.226
        },
        {
            "name": "Omniknight",
            "Razor": -0.264
        },
        {
            "name": "Oracle",
            "Razor": -3.102
        },
        {
            "name": "Outworld Destroyer",
            "Razor": -0.617
        },
        {
            "name": "Pangolier",
            "Razor": -0.35
        },
        {
            "name": "Phantom Assassin",
            "Razor": -1.216
        },
        {
            "name": "Phantom Lancer",
            "Razor": -1.593
        },
        {
            "name": "Phoenix",
            "Razor": 1.072
        },
        {
            "name": "Primal Beast",
            "Razor": 2.202
        },
        {
            "name": "Puck",
            "Razor": 0.917
        },
        {
            "name": "Pudge",
            "Razor": 0.557
        },
        {
            "name": "Pugna",
            "Razor": 0.756
        },
        {
            "name": "Queen of Pain",
            "Razor": 3.657
        },
        {
            "name": "Riki",
            "Razor": -0.704
        },
        {
            "name": "Ringmaster",
            "Razor": 0.386
        },
        {
            "name": "Rubick",
            "Razor": -1.936
        },
        {
            "name": "Sand King",
            "Razor": 0.755
        },
        {
            "name": "Shadow Demon",
            "Razor": -0.302
        },
        {
            "name": "Shadow Fiend",
            "Razor": 0.821
        },
        {
            "name": "Shadow Shaman",
            "Razor": -1.66
        },
        {
            "name": "Silencer",
            "Razor": -1.019
        },
        {
            "name": "Skywrath Mage",
            "Razor": -1.016
        },
        {
            "name": "Slardar",
            "Razor": -1.149
        },
        {
            "name": "Slark",
            "Razor": 0.704
        },
        {
            "name": "Snapfire",
            "Razor": 0.62
        },
        {
            "name": "Sniper",
            "Razor": 0.429
        },
        {
            "name": "Spectre",
            "Razor": 0.364
        },
        {
            "name": "Spirit Breaker",
            "Razor": -0.371
        },
        {
            "name": "Storm Spirit",
            "Razor": 2.405
        },
        {
            "name": "Sven",
            "Razor": -0.506
        },
        {
            "name": "Techies",
            "Razor": 1.93
        },
        {
            "name": "Templar Assassin",
            "Razor": 0.032
        },
        {
            "name": "Terrorblade",
            "Razor": 1.888
        },
        {
            "name": "Tidehunter",
            "Razor": -3.635
        },
        {
            "name": "Timbersaw",
            "Razor": 1.407
        },
        {
            "name": "Tinker",
            "Razor": 0.183
        },
        {
            "name": "Tiny",
            "Razor": -0.72
        },
        {
            "name": "Treant Protector",
            "Razor": 0.242
        },
        {
            "name": "Troll Warlord",
            "Razor": -3.398
        },
        {
            "name": "Tusk",
            "Razor": 0.908
        },
        {
            "name": "Underlord",
            "Razor": 0.963
        },
        {
            "name": "Undying",
            "Razor": -0.289
        },
        {
            "name": "Ursa",
            "Razor": -1.97
        },
        {
            "name": "Vengeful Spirit",
            "Razor": 0.1
        },
        {
            "name": "Venomancer",
            "Razor": -0.137
        },
        {
            "name": "Viper",
            "Razor": 1.508
        },
        {
            "name": "Visage",
            "Razor": -1.806
        },
        {
            "name": "Void Spirit",
            "Razor": 1.131
        },
        {
            "name": "Warlock",
            "Razor": -0.597
        },
        {
            "name": "Weaver",
            "Razor": 2.454
        },
        {
            "name": "Windranger",
            "Razor": -0.855
        },
        {
            "name": "Winter Wyvern",
            "Razor": -0.229
        },
        {
            "name": "Witch Doctor",
            "Razor": 1.915
        },
        {
            "name": "Wraith King",
            "Razor": -0.727
        },
        {
            "name": "Zeus",
            "Razor": -0.368
        }
    ],
    "Riki": [
        {
            "name": "Abaddon",
            "Riki": -0.417
        },
        {
            "name": "Alchemist",
            "Riki": 1.253
        },
        {
            "name": "Ancient Apparition",
            "Riki": -0.846
        },
        {
            "name": "Anti-Mage",
            "Riki": -1.924
        },
        {
            "name": "Arc Warden",
            "Riki": 1.298
        },
        {
            "name": "Axe",
            "Riki": 1.57
        },
        {
            "name": "Bane",
            "Riki": -1.165
        },
        {
            "name": "Batrider",
            "Riki": -0.941
        },
        {
            "name": "Beastmaster",
            "Riki": 0.852
        },
        {
            "name": "Bloodseeker",
            "Riki": 2.223
        },
        {
            "name": "Bounty Hunter",
            "Riki": 1.066
        },
        {
            "name": "Brewmaster",
            "Riki": -0.495
        },
        {
            "name": "Bristleback",
            "Riki": 5.188
        },
        {
            "name": "Broodmother",
            "Riki": 1.36
        },
        {
            "name": "Centaur Warrunner",
            "Riki": 0.554
        },
        {
            "name": "Chaos Knight",
            "Riki": -0.132
        },
        {
            "name": "Chen",
            "Riki": -1.4
        },
        {
            "name": "Clinkz",
            "Riki": -2.188
        },
        {
            "name": "Clockwerk",
            "Riki": 0.69
        },
        {
            "name": "Crystal Maiden",
            "Riki": 0.168
        },
        {
            "name": "Dark Seer",
            "Riki": 1.009
        },
        {
            "name": "Dark Willow",
            "Riki": 1.273
        },
        {
            "name": "Dawnbreaker",
            "Riki": -0.14
        },
        {
            "name": "Dazzle",
            "Riki": -1.591
        },
        {
            "name": "Death Prophet",
            "Riki": 0.405
        },
        {
            "name": "Disruptor",
            "Riki": 1.449
        },
        {
            "name": "Doom",
            "Riki": -0.196
        },
        {
            "name": "Dragon Knight",
            "Riki": 0.693
        },
        {
            "name": "Drow Ranger",
            "Riki": 2.375
        },
        {
            "name": "Earth Spirit",
            "Riki": 0.121
        },
        {
            "name": "Earthshaker",
            "Riki": -0.338
        },
        {
            "name": "Elder Titan",
            "Riki": -0.596
        },
        {
            "name": "Ember Spirit",
            "Riki": -1.777
        },
        {
            "name": "Enchantress",
            "Riki": -0.394
        },
        {
            "name": "Enigma",
            "Riki": -2.047
        },
        {
            "name": "Faceless Void",
            "Riki": -1.251
        },
        {
            "name": "Grimstroke",
            "Riki": 0.502
        },
        {
            "name": "Gyrocopter",
            "Riki": 0.346
        },
        {
            "name": "Hoodwink",
            "Riki": -0.674
        },
        {
            "name": "Huskar",
            "Riki": -0.203
        },
        {
            "name": "Invoker",
            "Riki": -1.559
        },
        {
            "name": "Io",
            "Riki": 0.168
        },
        {
            "name": "Jakiro",
            "Riki": 2.007
        },
        {
            "name": "Juggernaut",
            "Riki": -3.272
        },
        {
            "name": "Keeper of the Light",
            "Riki": -0.172
        },
        {
            "name": "Kez",
            "Riki": -1.026
        },
        {
            "name": "Kunkka",
            "Riki": 0.085
        },
        {
            "name": "Legion Commander",
            "Riki": -0.957
        },
        {
            "name": "Leshrac",
            "Riki": 1.297
        },
        {
            "name": "Lich",
            "Riki": 0.4
        },
        {
            "name": "Lifestealer",
            "Riki": -2.136
        },
        {
            "name": "Lina",
            "Riki": -0.767
        },
        {
            "name": "Lion",
            "Riki": 0.506
        },
        {
            "name": "Lone Druid",
            "Riki": -0.446
        },
        {
            "name": "Luna",
            "Riki": 1.794
        },
        {
            "name": "Lycan",
            "Riki": 1.189
        },
        {
            "name": "Magnus",
            "Riki": -0.575
        },
        {
            "name": "Marci",
            "Riki": -0.212
        },
        {
            "name": "Mars",
            "Riki": -0.911
        },
        {
            "name": "Medusa",
            "Riki": -2.19
        },
        {
            "name": "Meepo",
            "Riki": 0.25
        },
        {
            "name": "Mirana",
            "Riki": 0.034
        },
        {
            "name": "Monkey King",
            "Riki": -0.158
        },
        {
            "name": "Morphling",
            "Riki": -1.109
        },
        {
            "name": "Muerta",
            "Riki": 0.98
        },
        {
            "name": "Naga Siren",
            "Riki": 3.672
        },
        {
            "name": "Nature's Prophet",
            "Riki": 0.742
        },
        {
            "name": "Necrophos",
            "Riki": 2.077
        },
        {
            "name": "Night Stalker",
            "Riki": 1.008
        },
        {
            "name": "Nyx Assassin",
            "Riki": 0.677
        },
        {
            "name": "Ogre Magi",
            "Riki": 0.851
        },
        {
            "name": "Omniknight",
            "Riki": -0.631
        },
        {
            "name": "Oracle",
            "Riki": -2.592
        },
        {
            "name": "Outworld Destroyer",
            "Riki": -0.231
        },
        {
            "name": "Pangolier",
            "Riki": -1.358
        },
        {
            "name": "Phantom Assassin",
            "Riki": 1.076
        },
        {
            "name": "Phantom Lancer",
            "Riki": 0.298
        },
        {
            "name": "Phoenix",
            "Riki": 0.389
        },
        {
            "name": "Primal Beast",
            "Riki": 1.022
        },
        {
            "name": "Puck",
            "Riki": -4.994
        },
        {
            "name": "Pudge",
            "Riki": 0.684
        },
        {
            "name": "Pugna",
            "Riki": -0.901
        },
        {
            "name": "Queen of Pain",
            "Riki": -1.689
        },
        {
            "name": "Razor",
            "Riki": 0.656
        },
        {
            "name": "Ringmaster",
            "Riki": -0.651
        },
        {
            "name": "Rubick",
            "Riki": -0.049
        },
        {
            "name": "Sand King",
            "Riki": 1.258
        },
        {
            "name": "Shadow Demon",
            "Riki": -1.986
        },
        {
            "name": "Shadow Fiend",
            "Riki": 0.819
        },
        {
            "name": "Shadow Shaman",
            "Riki": -0.012
        },
        {
            "name": "Silencer",
            "Riki": 0.882
        },
        {
            "name": "Skywrath Mage",
            "Riki": -0.599
        },
        {
            "name": "Slardar",
            "Riki": 0.227
        },
        {
            "name": "Slark",
            "Riki": -2.61
        },
        {
            "name": "Snapfire",
            "Riki": 1.096
        },
        {
            "name": "Sniper",
            "Riki": 0.762
        },
        {
            "name": "Spectre",
            "Riki": 3.389
        },
        {
            "name": "Spirit Breaker",
            "Riki": 0.662
        },
        {
            "name": "Storm Spirit",
            "Riki": -3.381
        },
        {
            "name": "Sven",
            "Riki": 0.438
        },
        {
            "name": "Techies",
            "Riki": 1.965
        },
        {
            "name": "Templar Assassin",
            "Riki": -0.423
        },
        {
            "name": "Terrorblade",
            "Riki": 0.015
        },
        {
            "name": "Tidehunter",
            "Riki": 0.616
        },
        {
            "name": "Timbersaw",
            "Riki": 0.25
        },
        {
            "name": "Tinker",
            "Riki": 0.958
        },
        {
            "name": "Tiny",
            "Riki": 1.849
        },
        {
            "name": "Treant Protector",
            "Riki": 1.854
        },
        {
            "name": "Troll Warlord",
            "Riki": 0.258
        },
        {
            "name": "Tusk",
            "Riki": -0.742
        },
        {
            "name": "Underlord",
            "Riki": 2.984
        },
        {
            "name": "Undying",
            "Riki": -1.48
        },
        {
            "name": "Ursa",
            "Riki": -3.34
        },
        {
            "name": "Vengeful Spirit",
            "Riki": -0.009
        },
        {
            "name": "Venomancer",
            "Riki": 0.295
        },
        {
            "name": "Viper",
            "Riki": -1.33
        },
        {
            "name": "Visage",
            "Riki": -2.428
        },
        {
            "name": "Void Spirit",
            "Riki": -2.475
        },
        {
            "name": "Warlock",
            "Riki": 0.882
        },
        {
            "name": "Weaver",
            "Riki": -2.092
        },
        {
            "name": "Windranger",
            "Riki": 0.633
        },
        {
            "name": "Winter Wyvern",
            "Riki": 0.542
        },
        {
            "name": "Witch Doctor",
            "Riki": -2.358
        },
        {
            "name": "Wraith King",
            "Riki": 1.633
        },
        {
            "name": "Zeus",
            "Riki": 1.901
        }
    ],
    "Shadow Fiend": [
        {
            "name": "Abaddon",
            "Shadow Fiend": -2.01
        },
        {
            "name": "Alchemist",
            "Shadow Fiend": -0.205
        },
        {
            "name": "Ancient Apparition",
            "Shadow Fiend": 0.551
        },
        {
            "name": "Anti-Mage",
            "Shadow Fiend": -2.655
        },
        {
            "name": "Arc Warden",
            "Shadow Fiend": -1.109
        },
        {
            "name": "Axe",
            "Shadow Fiend": -0.782
        },
        {
            "name": "Bane",
            "Shadow Fiend": 0.47
        },
        {
            "name": "Batrider",
            "Shadow Fiend": 1.16
        },
        {
            "name": "Beastmaster",
            "Shadow Fiend": -0.917
        },
        {
            "name": "Bloodseeker",
            "Shadow Fiend": -1.833
        },
        {
            "name": "Bounty Hunter",
            "Shadow Fiend": 0.251
        },
        {
            "name": "Brewmaster",
            "Shadow Fiend": -0.024
        },
        {
            "name": "Bristleback",
            "Shadow Fiend": -0.24
        },
        {
            "name": "Broodmother",
            "Shadow Fiend": -2.946
        },
        {
            "name": "Centaur Warrunner",
            "Shadow Fiend": -0.202
        },
        {
            "name": "Chaos Knight",
            "Shadow Fiend": -0.069
        },
        {
            "name": "Chen",
            "Shadow Fiend": -1.36
        },
        {
            "name": "Clinkz",
            "Shadow Fiend": -0.453
        },
        {
            "name": "Clockwerk",
            "Shadow Fiend": 0.178
        },
        {
            "name": "Crystal Maiden",
            "Shadow Fiend": -0.936
        },
        {
            "name": "Dark Seer",
            "Shadow Fiend": 0.805
        },
        {
            "name": "Dark Willow",
            "Shadow Fiend": -0.537
        },
        {
            "name": "Dawnbreaker",
            "Shadow Fiend": -0.564
        },
        {
            "name": "Dazzle",
            "Shadow Fiend": -0.469
        },
        {
            "name": "Death Prophet",
            "Shadow Fiend": -0.979
        },
        {
            "name": "Disruptor",
            "Shadow Fiend": -0.999
        },
        {
            "name": "Doom",
            "Shadow Fiend": -0.081
        },
        {
            "name": "Dragon Knight",
            "Shadow Fiend": 1.841
        },
        {
            "name": "Drow Ranger",
            "Shadow Fiend": 1.035
        },
        {
            "name": "Earth Spirit",
            "Shadow Fiend": -0.906
        },
        {
            "name": "Earthshaker",
            "Shadow Fiend": -0.342
        },
        {
            "name": "Elder Titan",
            "Shadow Fiend": -0.298
        },
        {
            "name": "Ember Spirit",
            "Shadow Fiend": -2.184
        },
        {
            "name": "Enchantress",
            "Shadow Fiend": -0.297
        },
        {
            "name": "Enigma",
            "Shadow Fiend": -0.652
        },
        {
            "name": "Faceless Void",
            "Shadow Fiend": -0.159
        },
        {
            "name": "Grimstroke",
            "Shadow Fiend": -0.52
        },
        {
            "name": "Gyrocopter",
            "Shadow Fiend": 1.243
        },
        {
            "name": "Hoodwink",
            "Shadow Fiend": 1.221
        },
        {
            "name": "Huskar",
            "Shadow Fiend": -1.185
        },
        {
            "name": "Invoker",
            "Shadow Fiend": 0.458
        },
        {
            "name": "Io",
            "Shadow Fiend": -1.894
        },
        {
            "name": "Jakiro",
            "Shadow Fiend": 0.204
        },
        {
            "name": "Juggernaut",
            "Shadow Fiend": -0.56
        },
        {
            "name": "Keeper of the Light",
            "Shadow Fiend": -0.074
        },
        {
            "name": "Kez",
            "Shadow Fiend": -0.117
        },
        {
            "name": "Kunkka",
            "Shadow Fiend": 0.862
        },
        {
            "name": "Legion Commander",
            "Shadow Fiend": -1.533
        },
        {
            "name": "Leshrac",
            "Shadow Fiend": -0.955
        },
        {
            "name": "Lich",
            "Shadow Fiend": 0.011
        },
        {
            "name": "Lifestealer",
            "Shadow Fiend": -0.157
        },
        {
            "name": "Lina",
            "Shadow Fiend": 0.761
        },
        {
            "name": "Lion",
            "Shadow Fiend": -0.001
        },
        {
            "name": "Lone Druid",
            "Shadow Fiend": -2.663
        },
        {
            "name": "Luna",
            "Shadow Fiend": 1.448
        },
        {
            "name": "Lycan",
            "Shadow Fiend": -0.559
        },
        {
            "name": "Magnus",
            "Shadow Fiend": -0.46
        },
        {
            "name": "Marci",
            "Shadow Fiend": -1.086
        },
        {
            "name": "Mars",
            "Shadow Fiend": 1.501
        },
        {
            "name": "Medusa",
            "Shadow Fiend": 1.871
        },
        {
            "name": "Meepo",
            "Shadow Fiend": -4.618
        },
        {
            "name": "Mirana",
            "Shadow Fiend": 0.845
        },
        {
            "name": "Monkey King",
            "Shadow Fiend": 0.147
        },
        {
            "name": "Morphling",
            "Shadow Fiend": -0.369
        },
        {
            "name": "Muerta",
            "Shadow Fiend": 0.546
        },
        {
            "name": "Naga Siren",
            "Shadow Fiend": 0.502
        },
        {
            "name": "Nature's Prophet",
            "Shadow Fiend": -0.299
        },
        {
            "name": "Necrophos",
            "Shadow Fiend": -1.829
        },
        {
            "name": "Night Stalker",
            "Shadow Fiend": -1.196
        },
        {
            "name": "Nyx Assassin",
            "Shadow Fiend": 1.089
        },
        {
            "name": "Ogre Magi",
            "Shadow Fiend": 0.822
        },
        {
            "name": "Omniknight",
            "Shadow Fiend": -0.785
        },
        {
            "name": "Oracle",
            "Shadow Fiend": -2.698
        },
        {
            "name": "Outworld Destroyer",
            "Shadow Fiend": 0.21
        },
        {
            "name": "Pangolier",
            "Shadow Fiend": -1.269
        },
        {
            "name": "Phantom Assassin",
            "Shadow Fiend": -1.307
        },
        {
            "name": "Phantom Lancer",
            "Shadow Fiend": 1.424
        },
        {
            "name": "Phoenix",
            "Shadow Fiend": 0.177
        },
        {
            "name": "Primal Beast",
            "Shadow Fiend": 0.855
        },
        {
            "name": "Puck",
            "Shadow Fiend": -1.298
        },
        {
            "name": "Pudge",
            "Shadow Fiend": -0.238
        },
        {
            "name": "Pugna",
            "Shadow Fiend": -1.178
        },
        {
            "name": "Queen of Pain",
            "Shadow Fiend": -0.421
        },
        {
            "name": "Razor",
            "Shadow Fiend": -1.013
        },
        {
            "name": "Riki",
            "Shadow Fiend": -1.105
        },
        {
            "name": "Ringmaster",
            "Shadow Fiend": 0.581
        },
        {
            "name": "Rubick",
            "Shadow Fiend": 0.028
        },
        {
            "name": "Sand King",
            "Shadow Fiend": -0.337
        },
        {
            "name": "Shadow Demon",
            "Shadow Fiend": 1.243
        },
        {
            "name": "Shadow Shaman",
            "Shadow Fiend": 0.072
        },
        {
            "name": "Silencer",
            "Shadow Fiend": -1.386
        },
        {
            "name": "Skywrath Mage",
            "Shadow Fiend": -0.37
        },
        {
            "name": "Slardar",
            "Shadow Fiend": -0.447
        },
        {
            "name": "Slark",
            "Shadow Fiend": -1.409
        },
        {
            "name": "Snapfire",
            "Shadow Fiend": 0.724
        },
        {
            "name": "Sniper",
            "Shadow Fiend": 1.288
        },
        {
            "name": "Spectre",
            "Shadow Fiend": -0.809
        },
        {
            "name": "Spirit Breaker",
            "Shadow Fiend": 0.995
        },
        {
            "name": "Storm Spirit",
            "Shadow Fiend": -1.915
        },
        {
            "name": "Sven",
            "Shadow Fiend": 0.214
        },
        {
            "name": "Techies",
            "Shadow Fiend": 0.838
        },
        {
            "name": "Templar Assassin",
            "Shadow Fiend": 1.257
        },
        {
            "name": "Terrorblade",
            "Shadow Fiend": 2.108
        },
        {
            "name": "Tidehunter",
            "Shadow Fiend": -1.149
        },
        {
            "name": "Timbersaw",
            "Shadow Fiend": -0.809
        },
        {
            "name": "Tinker",
            "Shadow Fiend": -0.47
        },
        {
            "name": "Tiny",
            "Shadow Fiend": 0.821
        },
        {
            "name": "Treant Protector",
            "Shadow Fiend": 0.367
        },
        {
            "name": "Troll Warlord",
            "Shadow Fiend": 0.711
        },
        {
            "name": "Tusk",
            "Shadow Fiend": 0.408
        },
        {
            "name": "Underlord",
            "Shadow Fiend": 0.394
        },
        {
            "name": "Undying",
            "Shadow Fiend": -0.654
        },
        {
            "name": "Ursa",
            "Shadow Fiend": 0.898
        },
        {
            "name": "Vengeful Spirit",
            "Shadow Fiend": 0.554
        },
        {
            "name": "Venomancer",
            "Shadow Fiend": 0.338
        },
        {
            "name": "Viper",
            "Shadow Fiend": -0.096
        },
        {
            "name": "Visage",
            "Shadow Fiend": -0.175
        },
        {
            "name": "Void Spirit",
            "Shadow Fiend": -1.501
        },
        {
            "name": "Warlock",
            "Shadow Fiend": -1.972
        },
        {
            "name": "Weaver",
            "Shadow Fiend": -0.38
        },
        {
            "name": "Windranger",
            "Shadow Fiend": 1.453
        },
        {
            "name": "Winter Wyvern",
            "Shadow Fiend": -0.033
        },
        {
            "name": "Witch Doctor",
            "Shadow Fiend": -0.525
        },
        {
            "name": "Wraith King",
            "Shadow Fiend": 0.751
        },
        {
            "name": "Zeus",
            "Shadow Fiend": 1.509
        }
    ],
    "Slark": [
        {
            "name": "Abaddon",
            "Slark": -2.112
        },
        {
            "name": "Alchemist",
            "Slark": -0.389
        },
        {
            "name": "Ancient Apparition",
            "Slark": 1.583
        },
        {
            "name": "Anti-Mage",
            "Slark": -0.5
        },
        {
            "name": "Arc Warden",
            "Slark": -0.569
        },
        {
            "name": "Axe",
            "Slark": 0.348
        },
        {
            "name": "Bane",
            "Slark": -2.899
        },
        {
            "name": "Batrider",
            "Slark": -0.221
        },
        {
            "name": "Beastmaster",
            "Slark": 0.392
        },
        {
            "name": "Bloodseeker",
            "Slark": 2.511
        },
        {
            "name": "Bounty Hunter",
            "Slark": -2.594
        },
        {
            "name": "Brewmaster",
            "Slark": -2.013
        },
        {
            "name": "Bristleback",
            "Slark": -4.533
        },
        {
            "name": "Broodmother",
            "Slark": 0.107
        },
        {
            "name": "Centaur Warrunner",
            "Slark": 0.271
        },
        {
            "name": "Chaos Knight",
            "Slark": 0.23
        },
        {
            "name": "Chen",
            "Slark": -1.665
        },
        {
            "name": "Clinkz",
            "Slark": -1.235
        },
        {
            "name": "Clockwerk",
            "Slark": 2.346
        },
        {
            "name": "Crystal Maiden",
            "Slark": 1.538
        },
        {
            "name": "Dark Seer",
            "Slark": 0.077
        },
        {
            "name": "Dark Willow",
            "Slark": 0.808
        },
        {
            "name": "Dawnbreaker",
            "Slark": 2.611
        },
        {
            "name": "Dazzle",
            "Slark": -1.2
        },
        {
            "name": "Death Prophet",
            "Slark": -0.391
        },
        {
            "name": "Disruptor",
            "Slark": 3.551
        },
        {
            "name": "Doom",
            "Slark": 0.843
        },
        {
            "name": "Dragon Knight",
            "Slark": -2.183
        },
        {
            "name": "Drow Ranger",
            "Slark": 0.82
        },
        {
            "name": "Earth Spirit",
            "Slark": -0.713
        },
        {
            "name": "Earthshaker",
            "Slark": 2.62
        },
        {
            "name": "Elder Titan",
            "Slark": 3.341
        },
        {
            "name": "Ember Spirit",
            "Slark": -3.978
        },
        {
            "name": "Enchantress",
            "Slark": -1.111
        },
        {
            "name": "Enigma",
            "Slark": 2.518
        },
        {
            "name": "Faceless Void",
            "Slark": -0.772
        },
        {
            "name": "Grimstroke",
            "Slark": 3.608
        },
        {
            "name": "Gyrocopter",
            "Slark": 0.908
        },
        {
            "name": "Hoodwink",
            "Slark": 0.324
        },
        {
            "name": "Huskar",
            "Slark": 0.376
        },
        {
            "name": "Invoker",
            "Slark": 1.343
        },
        {
            "name": "Io",
            "Slark": -0.936
        },
        {
            "name": "Jakiro",
            "Slark": -0.534
        },
        {
            "name": "Juggernaut",
            "Slark": -1.261
        },
        {
            "name": "Keeper of the Light",
            "Slark": -1.029
        },
        {
            "name": "Kez",
            "Slark": -0.725
        },
        {
            "name": "Kunkka",
            "Slark": 0.638
        },
        {
            "name": "Legion Commander",
            "Slark": 1.014
        },
        {
            "name": "Leshrac",
            "Slark": 3.06
        },
        {
            "name": "Lich",
            "Slark": -0.242
        },
        {
            "name": "Lifestealer",
            "Slark": -1.068
        },
        {
            "name": "Lina",
            "Slark": 0.404
        },
        {
            "name": "Lion",
            "Slark": 1.911
        },
        {
            "name": "Lone Druid",
            "Slark": -1.077
        },
        {
            "name": "Luna",
            "Slark": -0.669
        },
        {
            "name": "Lycan",
            "Slark": -1.196
        },
        {
            "name": "Magnus",
            "Slark": 1.265
        },
        {
            "name": "Marci",
            "Slark": -0.239
        },
        {
            "name": "Mars",
            "Slark": 3.822
        },
        {
            "name": "Medusa",
            "Slark": -3.864
        },
        {
            "name": "Meepo",
            "Slark": -1.555
        },
        {
            "name": "Mirana",
            "Slark": 0.38
        },
        {
            "name": "Monkey King",
            "Slark": -0.525
        },
        {
            "name": "Morphling",
            "Slark": -1.335
        },
        {
            "name": "Muerta",
            "Slark": 1.857
        },
        {
            "name": "Naga Siren",
            "Slark": 1.951
        },
        {
            "name": "Nature's Prophet",
            "Slark": 0.116
        },
        {
            "name": "Necrophos",
            "Slark": 1.815
        },
        {
            "name": "Night Stalker",
            "Slark": 1.907
        },
        {
            "name": "Nyx Assassin",
            "Slark": -0.178
        },
        {
            "name": "Ogre Magi",
            "Slark": -2.238
        },
        {
            "name": "Omniknight",
            "Slark": 1.655
        },
        {
            "name": "Oracle",
            "Slark": -0.144
        },
        {
            "name": "Outworld Destroyer",
            "Slark": 1.586
        },
        {
            "name": "Pangolier",
            "Slark": -0.173
        },
        {
            "name": "Phantom Assassin",
            "Slark": -0.697
        },
        {
            "name": "Phantom Lancer",
            "Slark": 0.152
        },
        {
            "name": "Phoenix",
            "Slark": -1.08
        },
        {
            "name": "Primal Beast",
            "Slark": 1.622
        },
        {
            "name": "Puck",
            "Slark": 2.015
        },
        {
            "name": "Pudge",
            "Slark": -0.184
        },
        {
            "name": "Pugna",
            "Slark": 2.304
        },
        {
            "name": "Queen of Pain",
            "Slark": 0.147
        },
        {
            "name": "Razor",
            "Slark": -0.76
        },
        {
            "name": "Riki",
            "Slark": 2.315
        },
        {
            "name": "Ringmaster",
            "Slark": 2.185
        },
        {
            "name": "Rubick",
            "Slark": -0.672
        },
        {
            "name": "Sand King",
            "Slark": 1.115
        },
        {
            "name": "Shadow Demon",
            "Slark": 0.609
        },
        {
            "name": "Shadow Fiend",
            "Slark": 1.372
        },
        {
            "name": "Shadow Shaman",
            "Slark": 0.708
        },
        {
            "name": "Silencer",
            "Slark": -1.489
        },
        {
            "name": "Skywrath Mage",
            "Slark": 0.397
        },
        {
            "name": "Slardar",
            "Slark": -0.853
        },
        {
            "name": "Snapfire",
            "Slark": 0.964
        },
        {
            "name": "Sniper",
            "Slark": -1.613
        },
        {
            "name": "Spectre",
            "Slark": 0.129
        },
        {
            "name": "Spirit Breaker",
            "Slark": 0.409
        },
        {
            "name": "Storm Spirit",
            "Slark": -1.826
        },
        {
            "name": "Sven",
            "Slark": -0.515
        },
        {
            "name": "Techies",
            "Slark": 2.877
        },
        {
            "name": "Templar Assassin",
            "Slark": -1.72
        },
        {
            "name": "Terrorblade",
            "Slark": -1.889
        },
        {
            "name": "Tidehunter",
            "Slark": -2.252
        },
        {
            "name": "Timbersaw",
            "Slark": 2.029
        },
        {
            "name": "Tinker",
            "Slark": 0.253
        },
        {
            "name": "Tiny",
            "Slark": 1.279
        },
        {
            "name": "Treant Protector",
            "Slark": -1.772
        },
        {
            "name": "Troll Warlord",
            "Slark": -3.483
        },
        {
            "name": "Tusk",
            "Slark": 0.782
        },
        {
            "name": "Underlord",
            "Slark": -0.435
        },
        {
            "name": "Undying",
            "Slark": -1.358
        },
        {
            "name": "Ursa",
            "Slark": -0.539
        },
        {
            "name": "Vengeful Spirit",
            "Slark": 0.035
        },
        {
            "name": "Venomancer",
            "Slark": -1.667
        },
        {
            "name": "Viper",
            "Slark": -1.573
        },
        {
            "name": "Visage",
            "Slark": -0.067
        },
        {
            "name": "Void Spirit",
            "Slark": -0.494
        },
        {
            "name": "Warlock",
            "Slark": -1.466
        },
        {
            "name": "Weaver",
            "Slark": -0.765
        },
        {
            "name": "Windranger",
            "Slark": 0.79
        },
        {
            "name": "Winter Wyvern",
            "Slark": -1.658
        },
        {
            "name": "Witch Doctor",
            "Slark": -1.091
        },
        {
            "name": "Wraith King",
            "Slark": -2.775
        },
        {
            "name": "Zeus",
            "Slark": 0.757
        }
    ],
    "Sniper": [
        {
            "name": "Abaddon",
            "Sniper": -1.101
        },
        {
            "name": "Alchemist",
            "Sniper": -0.631
        },
        {
            "name": "Ancient Apparition",
            "Sniper": -0.878
        },
        {
            "name": "Anti-Mage",
            "Sniper": -0.42
        },
        {
            "name": "Arc Warden",
            "Sniper": 0.145
        },
        {
            "name": "Axe",
            "Sniper": 1.19
        },
        {
            "name": "Bane",
            "Sniper": -0.123
        },
        {
            "name": "Batrider",
            "Sniper": 0.364
        },
        {
            "name": "Beastmaster",
            "Sniper": -0.794
        },
        {
            "name": "Bloodseeker",
            "Sniper": -1.69
        },
        {
            "name": "Bounty Hunter",
            "Sniper": -0.02
        },
        {
            "name": "Brewmaster",
            "Sniper": -0.06
        },
        {
            "name": "Bristleback",
            "Sniper": -1.39
        },
        {
            "name": "Broodmother",
            "Sniper": -0.663
        },
        {
            "name": "Centaur Warrunner",
            "Sniper": 2.49
        },
        {
            "name": "Chaos Knight",
            "Sniper": 0.514
        },
        {
            "name": "Chen",
            "Sniper": 1.589
        },
        {
            "name": "Clinkz",
            "Sniper": -0.644
        },
        {
            "name": "Clockwerk",
            "Sniper": 1.255
        },
        {
            "name": "Crystal Maiden",
            "Sniper": -0.938
        },
        {
            "name": "Dark Seer",
            "Sniper": -0.398
        },
        {
            "name": "Dark Willow",
            "Sniper": 0.089
        },
        {
            "name": "Dawnbreaker",
            "Sniper": -0.664
        },
        {
            "name": "Dazzle",
            "Sniper": 0.001
        },
        {
            "name": "Death Prophet",
            "Sniper": -1.812
        },
        {
            "name": "Disruptor",
            "Sniper": -1.894
        },
        {
            "name": "Doom",
            "Sniper": -0.001
        },
        {
            "name": "Dragon Knight",
            "Sniper": -0.434
        },
        {
            "name": "Drow Ranger",
            "Sniper": -1.634
        },
        {
            "name": "Earth Spirit",
            "Sniper": 2.758
        },
        {
            "name": "Earthshaker",
            "Sniper": -1.609
        },
        {
            "name": "Elder Titan",
            "Sniper": -1.476
        },
        {
            "name": "Ember Spirit",
            "Sniper": -0.884
        },
        {
            "name": "Enchantress",
            "Sniper": 1.31
        },
        {
            "name": "Enigma",
            "Sniper": -2.67
        },
        {
            "name": "Faceless Void",
            "Sniper": 0.451
        },
        {
            "name": "Grimstroke",
            "Sniper": -0.819
        },
        {
            "name": "Gyrocopter",
            "Sniper": -1.063
        },
        {
            "name": "Hoodwink",
            "Sniper": 0.198
        },
        {
            "name": "Huskar",
            "Sniper": -1.956
        },
        {
            "name": "Invoker",
            "Sniper": -0.039
        },
        {
            "name": "Io",
            "Sniper": -0.401
        },
        {
            "name": "Jakiro",
            "Sniper": -1.007
        },
        {
            "name": "Juggernaut",
            "Sniper": -1.124
        },
        {
            "name": "Keeper of the Light",
            "Sniper": -0.393
        },
        {
            "name": "Kez",
            "Sniper": -0.497
        },
        {
            "name": "Kunkka",
            "Sniper": 0.078
        },
        {
            "name": "Legion Commander",
            "Sniper": -0.303
        },
        {
            "name": "Leshrac",
            "Sniper": -1.421
        },
        {
            "name": "Lich",
            "Sniper": -0.941
        },
        {
            "name": "Lifestealer",
            "Sniper": -1.133
        },
        {
            "name": "Lina",
            "Sniper": -0.758
        },
        {
            "name": "Lion",
            "Sniper": -0.537
        },
        {
            "name": "Lone Druid",
            "Sniper": -1.612
        },
        {
            "name": "Luna",
            "Sniper": -0.004
        },
        {
            "name": "Lycan",
            "Sniper": 1.616
        },
        {
            "name": "Magnus",
            "Sniper": 0.241
        },
        {
            "name": "Marci",
            "Sniper": -0.785
        },
        {
            "name": "Mars",
            "Sniper": 2.052
        },
        {
            "name": "Medusa",
            "Sniper": -3.791
        },
        {
            "name": "Meepo",
            "Sniper": 1.562
        },
        {
            "name": "Mirana",
            "Sniper": 1.341
        },
        {
            "name": "Monkey King",
            "Sniper": -0.581
        },
        {
            "name": "Morphling",
            "Sniper": 2.446
        },
        {
            "name": "Muerta",
            "Sniper": -0.196
        },
        {
            "name": "Naga Siren",
            "Sniper": 1.159
        },
        {
            "name": "Nature's Prophet",
            "Sniper": 1.094
        },
        {
            "name": "Necrophos",
            "Sniper": -2.437
        },
        {
            "name": "Night Stalker",
            "Sniper": -1.492
        },
        {
            "name": "Nyx Assassin",
            "Sniper": 2.049
        },
        {
            "name": "Ogre Magi",
            "Sniper": -0.087
        },
        {
            "name": "Omniknight",
            "Sniper": -0.264
        },
        {
            "name": "Oracle",
            "Sniper": -0.625
        },
        {
            "name": "Outworld Destroyer",
            "Sniper": -0.899
        },
        {
            "name": "Pangolier",
            "Sniper": 0.11
        },
        {
            "name": "Phantom Assassin",
            "Sniper": 0.848
        },
        {
            "name": "Phantom Lancer",
            "Sniper": 0.822
        },
        {
            "name": "Phoenix",
            "Sniper": -1.149
        },
        {
            "name": "Primal Beast",
            "Sniper": 2.938
        },
        {
            "name": "Puck",
            "Sniper": -1.144
        },
        {
            "name": "Pudge",
            "Sniper": 2.796
        },
        {
            "name": "Pugna",
            "Sniper": -1.611
        },
        {
            "name": "Queen of Pain",
            "Sniper": 0.473
        },
        {
            "name": "Razor",
            "Sniper": -0.718
        },
        {
            "name": "Riki",
            "Sniper": -1.105
        },
        {
            "name": "Ringmaster",
            "Sniper": 0.118
        },
        {
            "name": "Rubick",
            "Sniper": 0.153
        },
        {
            "name": "Sand King",
            "Sniper": -1.021
        },
        {
            "name": "Shadow Demon",
            "Sniper": 0.077
        },
        {
            "name": "Shadow Fiend",
            "Sniper": -1.09
        },
        {
            "name": "Shadow Shaman",
            "Sniper": -1.428
        },
        {
            "name": "Silencer",
            "Sniper": -2.428
        },
        {
            "name": "Skywrath Mage",
            "Sniper": 0.015
        },
        {
            "name": "Slardar",
            "Sniper": -0.676
        },
        {
            "name": "Slark",
            "Sniper": 1.336
        },
        {
            "name": "Snapfire",
            "Sniper": 0.877
        },
        {
            "name": "Spectre",
            "Sniper": 2.616
        },
        {
            "name": "Spirit Breaker",
            "Sniper": 1.049
        },
        {
            "name": "Storm Spirit",
            "Sniper": 2.684
        },
        {
            "name": "Sven",
            "Sniper": -0.062
        },
        {
            "name": "Techies",
            "Sniper": 0.4
        },
        {
            "name": "Templar Assassin",
            "Sniper": -0.996
        },
        {
            "name": "Terrorblade",
            "Sniper": -0.377
        },
        {
            "name": "Tidehunter",
            "Sniper": -1.224
        },
        {
            "name": "Timbersaw",
            "Sniper": 1.398
        },
        {
            "name": "Tinker",
            "Sniper": -0.033
        },
        {
            "name": "Tiny",
            "Sniper": 0.574
        },
        {
            "name": "Treant Protector",
            "Sniper": 0.126
        },
        {
            "name": "Troll Warlord",
            "Sniper": -0.419
        },
        {
            "name": "Tusk",
            "Sniper": 0.361
        },
        {
            "name": "Underlord",
            "Sniper": -1.541
        },
        {
            "name": "Undying",
            "Sniper": -1.101
        },
        {
            "name": "Ursa",
            "Sniper": 0.773
        },
        {
            "name": "Vengeful Spirit",
            "Sniper": -0.417
        },
        {
            "name": "Venomancer",
            "Sniper": -1.642
        },
        {
            "name": "Viper",
            "Sniper": -0.877
        },
        {
            "name": "Visage",
            "Sniper": -1.67
        },
        {
            "name": "Void Spirit",
            "Sniper": 0.835
        },
        {
            "name": "Warlock",
            "Sniper": -1.474
        },
        {
            "name": "Weaver",
            "Sniper": 0.904
        },
        {
            "name": "Windranger",
            "Sniper": 1.636
        },
        {
            "name": "Winter Wyvern",
            "Sniper": -1.594
        },
        {
            "name": "Witch Doctor",
            "Sniper": -1.26
        },
        {
            "name": "Wraith King",
            "Sniper": -0.445
        },
        {
            "name": "Zeus",
            "Sniper": 0.837
        }
    ],
    "Spectre": [
        {
            "name": "Abaddon",
            "Spectre": 4.02
        },
        {
            "name": "Alchemist",
            "Spectre": 0.882
        },
        {
            "name": "Ancient Apparition",
            "Spectre": -2.053
        },
        {
            "name": "Anti-Mage",
            "Spectre": 1.123
        },
        {
            "name": "Arc Warden",
            "Spectre": 0.094
        },
        {
            "name": "Axe",
            "Spectre": -2.392
        },
        {
            "name": "Bane",
            "Spectre": -1.095
        },
        {
            "name": "Batrider",
            "Spectre": 1.449
        },
        {
            "name": "Beastmaster",
            "Spectre": 4.169
        },
        {
            "name": "Bloodseeker",
            "Spectre": 0.149
        },
        {
            "name": "Bounty Hunter",
            "Spectre": 0.648
        },
        {
            "name": "Brewmaster",
            "Spectre": 1.797
        },
        {
            "name": "Bristleback",
            "Spectre": 1.288
        },
        {
            "name": "Broodmother",
            "Spectre": 1.316
        },
        {
            "name": "Centaur Warrunner",
            "Spectre": 1.014
        },
        {
            "name": "Chaos Knight",
            "Spectre": 0.444
        },
        {
            "name": "Chen",
            "Spectre": 3.288
        },
        {
            "name": "Clinkz",
            "Spectre": -2.501
        },
        {
            "name": "Clockwerk",
            "Spectre": 0.644
        },
        {
            "name": "Crystal Maiden",
            "Spectre": 0.647
        },
        {
            "name": "Dark Seer",
            "Spectre": 1.185
        },
        {
            "name": "Dark Willow",
            "Spectre": -0.21
        },
        {
            "name": "Dawnbreaker",
            "Spectre": 1.776
        },
        {
            "name": "Dazzle",
            "Spectre": 1.904
        },
        {
            "name": "Death Prophet",
            "Spectre": 0.586
        },
        {
            "name": "Disruptor",
            "Spectre": 0.63
        },
        {
            "name": "Doom",
            "Spectre": -0.053
        },
        {
            "name": "Dragon Knight",
            "Spectre": 0.25
        },
        {
            "name": "Drow Ranger",
            "Spectre": -2.105
        },
        {
            "name": "Earth Spirit",
            "Spectre": 1.687
        },
        {
            "name": "Earthshaker",
            "Spectre": -0.511
        },
        {
            "name": "Elder Titan",
            "Spectre": -0.052
        },
        {
            "name": "Ember Spirit",
            "Spectre": 3.315
        },
        {
            "name": "Enchantress",
            "Spectre": 2.246
        },
        {
            "name": "Enigma",
            "Spectre": -1.637
        },
        {
            "name": "Faceless Void",
            "Spectre": -1.22
        },
        {
            "name": "Grimstroke",
            "Spectre": -0.923
        },
        {
            "name": "Gyrocopter",
            "Spectre": 0.228
        },
        {
            "name": "Hoodwink",
            "Spectre": 0.597
        },
        {
            "name": "Huskar",
            "Spectre": 0.668
        },
        {
            "name": "Invoker",
            "Spectre": 1.108
        },
        {
            "name": "Io",
            "Spectre": 2.74
        },
        {
            "name": "Jakiro",
            "Spectre": 1.455
        },
        {
            "name": "Juggernaut",
            "Spectre": 0.877
        },
        {
            "name": "Keeper of the Light",
            "Spectre": 1.529
        },
        {
            "name": "Kez",
            "Spectre": 2.638
        },
        {
            "name": "Kunkka",
            "Spectre": 1.131
        },
        {
            "name": "Legion Commander",
            "Spectre": 0.743
        },
        {
            "name": "Leshrac",
            "Spectre": 0.376
        },
        {
            "name": "Lich",
            "Spectre": -0.668
        },
        {
            "name": "Lifestealer",
            "Spectre": 1.968
        },
        {
            "name": "Lina",
            "Spectre": 0.407
        },
        {
            "name": "Lion",
            "Spectre": -0.693
        },
        {
            "name": "Lone Druid",
            "Spectre": 3.389
        },
        {
            "name": "Luna",
            "Spectre": -0.912
        },
        {
            "name": "Lycan",
            "Spectre": 3.448
        },
        {
            "name": "Magnus",
            "Spectre": 1.233
        },
        {
            "name": "Marci",
            "Spectre": 2.121
        },
        {
            "name": "Mars",
            "Spectre": -0.705
        },
        {
            "name": "Medusa",
            "Spectre": 1.607
        },
        {
            "name": "Meepo",
            "Spectre": 4.345
        },
        {
            "name": "Mirana",
            "Spectre": -0.429
        },
        {
            "name": "Monkey King",
            "Spectre": 1.297
        },
        {
            "name": "Morphling",
            "Spectre": 0.868
        },
        {
            "name": "Muerta",
            "Spectre": -1.674
        },
        {
            "name": "Naga Siren",
            "Spectre": 2.4
        },
        {
            "name": "Nature's Prophet",
            "Spectre": -2.259
        },
        {
            "name": "Necrophos",
            "Spectre": 1.872
        },
        {
            "name": "Night Stalker",
            "Spectre": 0.001
        },
        {
            "name": "Nyx Assassin",
            "Spectre": -0.657
        },
        {
            "name": "Ogre Magi",
            "Spectre": 0.078
        },
        {
            "name": "Omniknight",
            "Spectre": 1.164
        },
        {
            "name": "Oracle",
            "Spectre": 1.915
        },
        {
            "name": "Outworld Destroyer",
            "Spectre": -0.016
        },
        {
            "name": "Pangolier",
            "Spectre": 1.831
        },
        {
            "name": "Phantom Assassin",
            "Spectre": 0.285
        },
        {
            "name": "Phantom Lancer",
            "Spectre": 1.323
        },
        {
            "name": "Phoenix",
            "Spectre": 0.953
        },
        {
            "name": "Primal Beast",
            "Spectre": 1.247
        },
        {
            "name": "Puck",
            "Spectre": -2.01
        },
        {
            "name": "Pudge",
            "Spectre": 0.987
        },
        {
            "name": "Pugna",
            "Spectre": 0.791
        },
        {
            "name": "Queen of Pain",
            "Spectre": 0.661
        },
        {
            "name": "Razor",
            "Spectre": -0.155
        },
        {
            "name": "Riki",
            "Spectre": -2.836
        },
        {
            "name": "Ringmaster",
            "Spectre": 0.563
        },
        {
            "name": "Rubick",
            "Spectre": 0.271
        },
        {
            "name": "Sand King",
            "Spectre": -0.594
        },
        {
            "name": "Shadow Demon",
            "Spectre": 1.207
        },
        {
            "name": "Shadow Fiend",
            "Spectre": 0.966
        },
        {
            "name": "Shadow Shaman",
            "Spectre": -1.606
        },
        {
            "name": "Silencer",
            "Spectre": -1.881
        },
        {
            "name": "Skywrath Mage",
            "Spectre": 0.11
        },
        {
            "name": "Slardar",
            "Spectre": 2.059
        },
        {
            "name": "Slark",
            "Spectre": 0.118
        },
        {
            "name": "Snapfire",
            "Spectre": 1.116
        },
        {
            "name": "Sniper",
            "Spectre": -3.019
        },
        {
            "name": "Spirit Breaker",
            "Spectre": -1.222
        },
        {
            "name": "Storm Spirit",
            "Spectre": -0.271
        },
        {
            "name": "Sven",
            "Spectre": 1.699
        },
        {
            "name": "Techies",
            "Spectre": -0.529
        },
        {
            "name": "Templar Assassin",
            "Spectre": -0.81
        },
        {
            "name": "Terrorblade",
            "Spectre": 1.096
        },
        {
            "name": "Tidehunter",
            "Spectre": 2.793
        },
        {
            "name": "Timbersaw",
            "Spectre": -0.925
        },
        {
            "name": "Tinker",
            "Spectre": 1.165
        },
        {
            "name": "Tiny",
            "Spectre": 0.241
        },
        {
            "name": "Treant Protector",
            "Spectre": 1.063
        },
        {
            "name": "Troll Warlord",
            "Spectre": 0.258
        },
        {
            "name": "Tusk",
            "Spectre": 0.157
        },
        {
            "name": "Underlord",
            "Spectre": 2.588
        },
        {
            "name": "Undying",
            "Spectre": 3.289
        },
        {
            "name": "Ursa",
            "Spectre": -1.047
        },
        {
            "name": "Vengeful Spirit",
            "Spectre": -0.211
        },
        {
            "name": "Venomancer",
            "Spectre": 1.55
        },
        {
            "name": "Viper",
            "Spectre": 1.928
        },
        {
            "name": "Visage",
            "Spectre": 1.215
        },
        {
            "name": "Void Spirit",
            "Spectre": 0.592
        },
        {
            "name": "Warlock",
            "Spectre": 1.579
        },
        {
            "name": "Weaver",
            "Spectre": 0.266
        },
        {
            "name": "Windranger",
            "Spectre": -0.677
        },
        {
            "name": "Winter Wyvern",
            "Spectre": -0.482
        },
        {
            "name": "Witch Doctor",
            "Spectre": 0.716
        },
        {
            "name": "Wraith King",
            "Spectre": 0.623
        },
        {
            "name": "Zeus",
            "Spectre": -1.817
        }
    ],
    "Templar Assassin": [
        {
            "name": "Abaddon",
            "Templar Assassin": -0.776
        },
        {
            "name": "Alchemist",
            "Templar Assassin": 1.362
        },
        {
            "name": "Ancient Apparition",
            "Templar Assassin": -0.541
        },
        {
            "name": "Anti-Mage",
            "Templar Assassin": -2.17
        },
        {
            "name": "Arc Warden",
            "Templar Assassin": 2.821
        },
        {
            "name": "Axe",
            "Templar Assassin": 0.993
        },
        {
            "name": "Bane",
            "Templar Assassin": 0.035
        },
        {
            "name": "Batrider",
            "Templar Assassin": 3.243
        },
        {
            "name": "Beastmaster",
            "Templar Assassin": -0.036
        },
        {
            "name": "Bloodseeker",
            "Templar Assassin": -0.355
        },
        {
            "name": "Bounty Hunter",
            "Templar Assassin": 0.507
        },
        {
            "name": "Brewmaster",
            "Templar Assassin": 1.153
        },
        {
            "name": "Bristleback",
            "Templar Assassin": 1.0
        },
        {
            "name": "Broodmother",
            "Templar Assassin": -0.569
        },
        {
            "name": "Centaur Warrunner",
            "Templar Assassin": -1.159
        },
        {
            "name": "Chaos Knight",
            "Templar Assassin": 1.428
        },
        {
            "name": "Chen",
            "Templar Assassin": 1.64
        },
        {
            "name": "Clinkz",
            "Templar Assassin": 1.379
        },
        {
            "name": "Clockwerk",
            "Templar Assassin": -0.676
        },
        {
            "name": "Crystal Maiden",
            "Templar Assassin": 1.331
        },
        {
            "name": "Dark Seer",
            "Templar Assassin": 2.258
        },
        {
            "name": "Dark Willow",
            "Templar Assassin": 1.2
        },
        {
            "name": "Dawnbreaker",
            "Templar Assassin": -0.551
        },
        {
            "name": "Dazzle",
            "Templar Assassin": 0.132
        },
        {
            "name": "Death Prophet",
            "Templar Assassin": -0.015
        },
        {
            "name": "Disruptor",
            "Templar Assassin": -1.664
        },
        {
            "name": "Doom",
            "Templar Assassin": -0.564
        },
        {
            "name": "Dragon Knight",
            "Templar Assassin": 0.961
        },
        {
            "name": "Drow Ranger",
            "Templar Assassin": 0.443
        },
        {
            "name": "Earth Spirit",
            "Templar Assassin": 0.676
        },
        {
            "name": "Earthshaker",
            "Templar Assassin": -1.755
        },
        {
            "name": "Elder Titan",
            "Templar Assassin": -2.008
        },
        {
            "name": "Ember Spirit",
            "Templar Assassin": -1.163
        },
        {
            "name": "Enchantress",
            "Templar Assassin": 2.273
        },
        {
            "name": "Enigma",
            "Templar Assassin": 0.003
        },
        {
            "name": "Faceless Void",
            "Templar Assassin": 1.177
        },
        {
            "name": "Grimstroke",
            "Templar Assassin": -0.862
        },
        {
            "name": "Gyrocopter",
            "Templar Assassin": -0.308
        },
        {
            "name": "Hoodwink",
            "Templar Assassin": 0.295
        },
        {
            "name": "Huskar",
            "Templar Assassin": 0.318
        },
        {
            "name": "Invoker",
            "Templar Assassin": -1.291
        },
        {
            "name": "Io",
            "Templar Assassin": -0.302
        },
        {
            "name": "Jakiro",
            "Templar Assassin": 0.703
        },
        {
            "name": "Juggernaut",
            "Templar Assassin": -1.533
        },
        {
            "name": "Keeper of the Light",
            "Templar Assassin": -0.253
        },
        {
            "name": "Kez",
            "Templar Assassin": -1.956
        },
        {
            "name": "Kunkka",
            "Templar Assassin": 0.008
        },
        {
            "name": "Legion Commander",
            "Templar Assassin": -1.532
        },
        {
            "name": "Leshrac",
            "Templar Assassin": 3.676
        },
        {
            "name": "Lich",
            "Templar Assassin": -0.226
        },
        {
            "name": "Lifestealer",
            "Templar Assassin": -0.512
        },
        {
            "name": "Lina",
            "Templar Assassin": -2.771
        },
        {
            "name": "Lion",
            "Templar Assassin": -0.807
        },
        {
            "name": "Lone Druid",
            "Templar Assassin": -0.247
        },
        {
            "name": "Luna",
            "Templar Assassin": 1.032
        },
        {
            "name": "Lycan",
            "Templar Assassin": -0.636
        },
        {
            "name": "Magnus",
            "Templar Assassin": -0.574
        },
        {
            "name": "Marci",
            "Templar Assassin": -0.91
        },
        {
            "name": "Mars",
            "Templar Assassin": 0.541
        },
        {
            "name": "Medusa",
            "Templar Assassin": 2.139
        },
        {
            "name": "Meepo",
            "Templar Assassin": 0.631
        },
        {
            "name": "Mirana",
            "Templar Assassin": 0.859
        },
        {
            "name": "Monkey King",
            "Templar Assassin": 0.356
        },
        {
            "name": "Morphling",
            "Templar Assassin": 0.376
        },
        {
            "name": "Muerta",
            "Templar Assassin": -1.256
        },
        {
            "name": "Naga Siren",
            "Templar Assassin": 3.585
        },
        {
            "name": "Nature's Prophet",
            "Templar Assassin": -0.746
        },
        {
            "name": "Necrophos",
            "Templar Assassin": 1.32
        },
        {
            "name": "Night Stalker",
            "Templar Assassin": -2.371
        },
        {
            "name": "Nyx Assassin",
            "Templar Assassin": -1.973
        },
        {
            "name": "Ogre Magi",
            "Templar Assassin": 1.029
        },
        {
            "name": "Omniknight",
            "Templar Assassin": -0.184
        },
        {
            "name": "Oracle",
            "Templar Assassin": -2.882
        },
        {
            "name": "Outworld Destroyer",
            "Templar Assassin": -3.394
        },
        {
            "name": "Pangolier",
            "Templar Assassin": 0.71
        },
        {
            "name": "Phantom Assassin",
            "Templar Assassin": -1.785
        },
        {
            "name": "Phantom Lancer",
            "Templar Assassin": 5.153
        },
        {
            "name": "Phoenix",
            "Templar Assassin": 3.128
        },
        {
            "name": "Primal Beast",
            "Templar Assassin": 1.388
        },
        {
            "name": "Puck",
            "Templar Assassin": -1.463
        },
        {
            "name": "Pudge",
            "Templar Assassin": 0.818
        },
        {
            "name": "Pugna",
            "Templar Assassin": 1.515
        },
        {
            "name": "Queen of Pain",
            "Templar Assassin": -1.107
        },
        {
            "name": "Razor",
            "Templar Assassin": 0.077
        },
        {
            "name": "Riki",
            "Templar Assassin": 0.427
        },
        {
            "name": "Ringmaster",
            "Templar Assassin": 0.016
        },
        {
            "name": "Rubick",
            "Templar Assassin": -2.032
        },
        {
            "name": "Sand King",
            "Templar Assassin": 1.389
        },
        {
            "name": "Shadow Demon",
            "Templar Assassin": 0.912
        },
        {
            "name": "Shadow Fiend",
            "Templar Assassin": -1.047
        },
        {
            "name": "Shadow Shaman",
            "Templar Assassin": 1.437
        },
        {
            "name": "Silencer",
            "Templar Assassin": -0.023
        },
        {
            "name": "Skywrath Mage",
            "Templar Assassin": -2.017
        },
        {
            "name": "Slardar",
            "Templar Assassin": 0.59
        },
        {
            "name": "Slark",
            "Templar Assassin": 2.116
        },
        {
            "name": "Snapfire",
            "Templar Assassin": -0.185
        },
        {
            "name": "Sniper",
            "Templar Assassin": 1.91
        },
        {
            "name": "Spectre",
            "Templar Assassin": 1.99
        },
        {
            "name": "Spirit Breaker",
            "Templar Assassin": -0.323
        },
        {
            "name": "Storm Spirit",
            "Templar Assassin": 0.954
        },
        {
            "name": "Sven",
            "Templar Assassin": -1.357
        },
        {
            "name": "Techies",
            "Templar Assassin": -1.762
        },
        {
            "name": "Terrorblade",
            "Templar Assassin": 2.929
        },
        {
            "name": "Tidehunter",
            "Templar Assassin": 1.241
        },
        {
            "name": "Timbersaw",
            "Templar Assassin": -2.322
        },
        {
            "name": "Tinker",
            "Templar Assassin": 2.144
        },
        {
            "name": "Tiny",
            "Templar Assassin": 0.365
        },
        {
            "name": "Treant Protector",
            "Templar Assassin": 1.852
        },
        {
            "name": "Troll Warlord",
            "Templar Assassin": 2.914
        },
        {
            "name": "Tusk",
            "Templar Assassin": -1.24
        },
        {
            "name": "Underlord",
            "Templar Assassin": 0.352
        },
        {
            "name": "Undying",
            "Templar Assassin": 0.047
        },
        {
            "name": "Ursa",
            "Templar Assassin": -1.506
        },
        {
            "name": "Vengeful Spirit",
            "Templar Assassin": 0.421
        },
        {
            "name": "Venomancer",
            "Templar Assassin": 1.565
        },
        {
            "name": "Viper",
            "Templar Assassin": 1.282
        },
        {
            "name": "Visage",
            "Templar Assassin": -0.563
        },
        {
            "name": "Void Spirit",
            "Templar Assassin": -0.464
        },
        {
            "name": "Warlock",
            "Templar Assassin": 0.037
        },
        {
            "name": "Weaver",
            "Templar Assassin": -0.389
        },
        {
            "name": "Windranger",
            "Templar Assassin": 0.629
        },
        {
            "name": "Winter Wyvern",
            "Templar Assassin": 1.465
        },
        {
            "name": "Witch Doctor",
            "Templar Assassin": -0.856
        },
        {
            "name": "Wraith King",
            "Templar Assassin": 0.695
        },
        {
            "name": "Zeus",
            "Templar Assassin": -0.428
        }
    ],
    "Terrorblade": [
        {
            "name": "Abaddon",
            "Terrorblade": -1.544
        },
        {
            "name": "Alchemist",
            "Terrorblade": -1.047
        },
        {
            "name": "Ancient Apparition",
            "Terrorblade": -0.1
        },
        {
            "name": "Anti-Mage",
            "Terrorblade": -2.749
        },
        {
            "name": "Arc Warden",
            "Terrorblade": 0.421
        },
        {
            "name": "Axe",
            "Terrorblade": 4.666
        },
        {
            "name": "Bane",
            "Terrorblade": -2.043
        },
        {
            "name": "Batrider",
            "Terrorblade": 0.254
        },
        {
            "name": "Beastmaster",
            "Terrorblade": -1.049
        },
        {
            "name": "Bloodseeker",
            "Terrorblade": -0.036
        },
        {
            "name": "Bounty Hunter",
            "Terrorblade": 0.115
        },
        {
            "name": "Brewmaster",
            "Terrorblade": 2.067
        },
        {
            "name": "Bristleback",
            "Terrorblade": 0.226
        },
        {
            "name": "Broodmother",
            "Terrorblade": -3.811
        },
        {
            "name": "Centaur Warrunner",
            "Terrorblade": -0.297
        },
        {
            "name": "Chaos Knight",
            "Terrorblade": -2.246
        },
        {
            "name": "Chen",
            "Terrorblade": -2.018
        },
        {
            "name": "Clinkz",
            "Terrorblade": -1.181
        },
        {
            "name": "Clockwerk",
            "Terrorblade": -1.918
        },
        {
            "name": "Crystal Maiden",
            "Terrorblade": 0.96
        },
        {
            "name": "Dark Seer",
            "Terrorblade": 2.336
        },
        {
            "name": "Dark Willow",
            "Terrorblade": -0.368
        },
        {
            "name": "Dawnbreaker",
            "Terrorblade": -0.641
        },
        {
            "name": "Dazzle",
            "Terrorblade": 0.971
        },
        {
            "name": "Death Prophet",
            "Terrorblade": -1.376
        },
        {
            "name": "Disruptor",
            "Terrorblade": -1.037
        },
        {
            "name": "Doom",
            "Terrorblade": -0.634
        },
        {
            "name": "Dragon Knight",
            "Terrorblade": -0.864
        },
        {
            "name": "Drow Ranger",
            "Terrorblade": 1.31
        },
        {
            "name": "Earth Spirit",
            "Terrorblade": 0.416
        },
        {
            "name": "Earthshaker",
            "Terrorblade": 1.592
        },
        {
            "name": "Elder Titan",
            "Terrorblade": 1.012
        },
        {
            "name": "Ember Spirit",
            "Terrorblade": 0.082
        },
        {
            "name": "Enchantress",
            "Terrorblade": -1.221
        },
        {
            "name": "Enigma",
            "Terrorblade": -1.56
        },
        {
            "name": "Faceless Void",
            "Terrorblade": 1.754
        },
        {
            "name": "Grimstroke",
            "Terrorblade": 0.02
        },
        {
            "name": "Gyrocopter",
            "Terrorblade": -0.278
        },
        {
            "name": "Hoodwink",
            "Terrorblade": 0.47
        },
        {
            "name": "Huskar",
            "Terrorblade": -0.715
        },
        {
            "name": "Invoker",
            "Terrorblade": -0.128
        },
        {
            "name": "Io",
            "Terrorblade": -1.426
        },
        {
            "name": "Jakiro",
            "Terrorblade": 0.924
        },
        {
            "name": "Juggernaut",
            "Terrorblade": 1.069
        },
        {
            "name": "Keeper of the Light",
            "Terrorblade": 0.925
        },
        {
            "name": "Kez",
            "Terrorblade": -0.888
        },
        {
            "name": "Kunkka",
            "Terrorblade": 1.452
        },
        {
            "name": "Legion Commander",
            "Terrorblade": 1.921
        },
        {
            "name": "Leshrac",
            "Terrorblade": 0.732
        },
        {
            "name": "Lich",
            "Terrorblade": 1.587
        },
        {
            "name": "Lifestealer",
            "Terrorblade": 0.32
        },
        {
            "name": "Lina",
            "Terrorblade": 2.67
        },
        {
            "name": "Lion",
            "Terrorblade": 0.614
        },
        {
            "name": "Lone Druid",
            "Terrorblade": -2.69
        },
        {
            "name": "Luna",
            "Terrorblade": -0.133
        },
        {
            "name": "Lycan",
            "Terrorblade": -3.208
        },
        {
            "name": "Magnus",
            "Terrorblade": 0.121
        },
        {
            "name": "Marci",
            "Terrorblade": -1.255
        },
        {
            "name": "Mars",
            "Terrorblade": 0.555
        },
        {
            "name": "Medusa",
            "Terrorblade": -3.498
        },
        {
            "name": "Meepo",
            "Terrorblade": -1.302
        },
        {
            "name": "Mirana",
            "Terrorblade": -1.042
        },
        {
            "name": "Monkey King",
            "Terrorblade": -0.554
        },
        {
            "name": "Morphling",
            "Terrorblade": -2.342
        },
        {
            "name": "Muerta",
            "Terrorblade": 0.078
        },
        {
            "name": "Naga Siren",
            "Terrorblade": 4.221
        },
        {
            "name": "Nature's Prophet",
            "Terrorblade": 0.033
        },
        {
            "name": "Necrophos",
            "Terrorblade": 0.001
        },
        {
            "name": "Night Stalker",
            "Terrorblade": -4.098
        },
        {
            "name": "Nyx Assassin",
            "Terrorblade": -1.279
        },
        {
            "name": "Ogre Magi",
            "Terrorblade": -0.357
        },
        {
            "name": "Omniknight",
            "Terrorblade": -1.435
        },
        {
            "name": "Oracle",
            "Terrorblade": -1.532
        },
        {
            "name": "Outworld Destroyer",
            "Terrorblade": 0.103
        },
        {
            "name": "Pangolier",
            "Terrorblade": -1.756
        },
        {
            "name": "Phantom Assassin",
            "Terrorblade": 1.188
        },
        {
            "name": "Phantom Lancer",
            "Terrorblade": 4.405
        },
        {
            "name": "Phoenix",
            "Terrorblade": 1.235
        },
        {
            "name": "Primal Beast",
            "Terrorblade": 1.792
        },
        {
            "name": "Puck",
            "Terrorblade": 0.87
        },
        {
            "name": "Pudge",
            "Terrorblade": -1.333
        },
        {
            "name": "Pugna",
            "Terrorblade": -0.884
        },
        {
            "name": "Queen of Pain",
            "Terrorblade": 0.087
        },
        {
            "name": "Razor",
            "Terrorblade": -1.859
        },
        {
            "name": "Riki",
            "Terrorblade": 0.061
        },
        {
            "name": "Ringmaster",
            "Terrorblade": 0.541
        },
        {
            "name": "Rubick",
            "Terrorblade": 0.352
        },
        {
            "name": "Sand King",
            "Terrorblade": 2.23
        },
        {
            "name": "Shadow Demon",
            "Terrorblade": -0.25
        },
        {
            "name": "Shadow Fiend",
            "Terrorblade": -2.305
        },
        {
            "name": "Shadow Shaman",
            "Terrorblade": -1.341
        },
        {
            "name": "Silencer",
            "Terrorblade": -1.66
        },
        {
            "name": "Skywrath Mage",
            "Terrorblade": 0.954
        },
        {
            "name": "Slardar",
            "Terrorblade": -2.772
        },
        {
            "name": "Slark",
            "Terrorblade": 2.198
        },
        {
            "name": "Snapfire",
            "Terrorblade": 0.7
        },
        {
            "name": "Sniper",
            "Terrorblade": 0.79
        },
        {
            "name": "Spectre",
            "Terrorblade": -0.326
        },
        {
            "name": "Spirit Breaker",
            "Terrorblade": -0.042
        },
        {
            "name": "Storm Spirit",
            "Terrorblade": 0.085
        },
        {
            "name": "Sven",
            "Terrorblade": -0.398
        },
        {
            "name": "Techies",
            "Terrorblade": 1.7
        },
        {
            "name": "Templar Assassin",
            "Terrorblade": -2.946
        },
        {
            "name": "Tidehunter",
            "Terrorblade": 0.063
        },
        {
            "name": "Timbersaw",
            "Terrorblade": 1.782
        },
        {
            "name": "Tinker",
            "Terrorblade": 1.726
        },
        {
            "name": "Tiny",
            "Terrorblade": -0.758
        },
        {
            "name": "Treant Protector",
            "Terrorblade": -0.353
        },
        {
            "name": "Troll Warlord",
            "Terrorblade": 0.545
        },
        {
            "name": "Tusk",
            "Terrorblade": -1.124
        },
        {
            "name": "Underlord",
            "Terrorblade": 0.655
        },
        {
            "name": "Undying",
            "Terrorblade": 0.264
        },
        {
            "name": "Ursa",
            "Terrorblade": -0.467
        },
        {
            "name": "Vengeful Spirit",
            "Terrorblade": -2.587
        },
        {
            "name": "Venomancer",
            "Terrorblade": -1.161
        },
        {
            "name": "Viper",
            "Terrorblade": -2.659
        },
        {
            "name": "Visage",
            "Terrorblade": -1.673
        },
        {
            "name": "Void Spirit",
            "Terrorblade": -1.372
        },
        {
            "name": "Warlock",
            "Terrorblade": 4.567
        },
        {
            "name": "Weaver",
            "Terrorblade": 0.242
        },
        {
            "name": "Windranger",
            "Terrorblade": 0.794
        },
        {
            "name": "Winter Wyvern",
            "Terrorblade": 3.047
        },
        {
            "name": "Witch Doctor",
            "Terrorblade": 0.926
        },
        {
            "name": "Wraith King",
            "Terrorblade": -2.094
        },
        {
            "name": "Zeus",
            "Terrorblade": 0.034
        }
    ],
    "Troll Warlord": [
        {
            "name": "Abaddon",
            "Troll Warlord": 0.451
        },
        {
            "name": "Alchemist",
            "Troll Warlord": 0.227
        },
        {
            "name": "Ancient Apparition",
            "Troll Warlord": 2.215
        },
        {
            "name": "Anti-Mage",
            "Troll Warlord": -3.761
        },
        {
            "name": "Arc Warden",
            "Troll Warlord": -1.361
        },
        {
            "name": "Axe",
            "Troll Warlord": 5.694
        },
        {
            "name": "Bane",
            "Troll Warlord": 0.5
        },
        {
            "name": "Batrider",
            "Troll Warlord": 0.204
        },
        {
            "name": "Beastmaster",
            "Troll Warlord": -2.58
        },
        {
            "name": "Bloodseeker",
            "Troll Warlord": -1.025
        },
        {
            "name": "Bounty Hunter",
            "Troll Warlord": -1.464
        },
        {
            "name": "Brewmaster",
            "Troll Warlord": 2.054
        },
        {
            "name": "Bristleback",
            "Troll Warlord": 1.877
        },
        {
            "name": "Broodmother",
            "Troll Warlord": -2.029
        },
        {
            "name": "Centaur Warrunner",
            "Troll Warlord": 1.278
        },
        {
            "name": "Chaos Knight",
            "Troll Warlord": -0.958
        },
        {
            "name": "Chen",
            "Troll Warlord": -2.759
        },
        {
            "name": "Clinkz",
            "Troll Warlord": -0.432
        },
        {
            "name": "Clockwerk",
            "Troll Warlord": -0.266
        },
        {
            "name": "Crystal Maiden",
            "Troll Warlord": 1.882
        },
        {
            "name": "Dark Seer",
            "Troll Warlord": -1.116
        },
        {
            "name": "Dark Willow",
            "Troll Warlord": 0.12
        },
        {
            "name": "Dawnbreaker",
            "Troll Warlord": -0.45
        },
        {
            "name": "Dazzle",
            "Troll Warlord": -1.232
        },
        {
            "name": "Death Prophet",
            "Troll Warlord": -0.604
        },
        {
            "name": "Disruptor",
            "Troll Warlord": 0.658
        },
        {
            "name": "Doom",
            "Troll Warlord": 1.846
        },
        {
            "name": "Dragon Knight",
            "Troll Warlord": 0.118
        },
        {
            "name": "Drow Ranger",
            "Troll Warlord": -0.183
        },
        {
            "name": "Earth Spirit",
            "Troll Warlord": -2.411
        },
        {
            "name": "Earthshaker",
            "Troll Warlord": -1.181
        },
        {
            "name": "Elder Titan",
            "Troll Warlord": -6.119
        },
        {
            "name": "Ember Spirit",
            "Troll Warlord": -4.299
        },
        {
            "name": "Enchantress",
            "Troll Warlord": 1.158
        },
        {
            "name": "Enigma",
            "Troll Warlord": -0.266
        },
        {
            "name": "Faceless Void",
            "Troll Warlord": -0.115
        },
        {
            "name": "Grimstroke",
            "Troll Warlord": 0.017
        },
        {
            "name": "Gyrocopter",
            "Troll Warlord": 1.249
        },
        {
            "name": "Hoodwink",
            "Troll Warlord": 0.486
        },
        {
            "name": "Huskar",
            "Troll Warlord": -0.957
        },
        {
            "name": "Invoker",
            "Troll Warlord": 1.407
        },
        {
            "name": "Io",
            "Troll Warlord": -3.307
        },
        {
            "name": "Jakiro",
            "Troll Warlord": 1.657
        },
        {
            "name": "Juggernaut",
            "Troll Warlord": -0.928
        },
        {
            "name": "Keeper of the Light",
            "Troll Warlord": 0.355
        },
        {
            "name": "Kez",
            "Troll Warlord": -2.467
        },
        {
            "name": "Kunkka",
            "Troll Warlord": 0.307
        },
        {
            "name": "Legion Commander",
            "Troll Warlord": -1.398
        },
        {
            "name": "Leshrac",
            "Troll Warlord": 0.848
        },
        {
            "name": "Lich",
            "Troll Warlord": 0.827
        },
        {
            "name": "Lifestealer",
            "Troll Warlord": -1.39
        },
        {
            "name": "Lina",
            "Troll Warlord": 1.379
        },
        {
            "name": "Lion",
            "Troll Warlord": 1.661
        },
        {
            "name": "Lone Druid",
            "Troll Warlord": -1.244
        },
        {
            "name": "Luna",
            "Troll Warlord": 1.028
        },
        {
            "name": "Lycan",
            "Troll Warlord": -4.751
        },
        {
            "name": "Magnus",
            "Troll Warlord": -2.115
        },
        {
            "name": "Marci",
            "Troll Warlord": -4.127
        },
        {
            "name": "Mars",
            "Troll Warlord": 1.037
        },
        {
            "name": "Medusa",
            "Troll Warlord": 1.398
        },
        {
            "name": "Meepo",
            "Troll Warlord": -6.55
        },
        {
            "name": "Mirana",
            "Troll Warlord": 1.34
        },
        {
            "name": "Monkey King",
            "Troll Warlord": -1.442
        },
        {
            "name": "Morphling",
            "Troll Warlord": -1.337
        },
        {
            "name": "Muerta",
            "Troll Warlord": 2.52
        },
        {
            "name": "Naga Siren",
            "Troll Warlord": 2.595
        },
        {
            "name": "Nature's Prophet",
            "Troll Warlord": -0.644
        },
        {
            "name": "Necrophos",
            "Troll Warlord": 1.922
        },
        {
            "name": "Night Stalker",
            "Troll Warlord": -3.132
        },
        {
            "name": "Nyx Assassin",
            "Troll Warlord": -0.875
        },
        {
            "name": "Ogre Magi",
            "Troll Warlord": 2.1
        },
        {
            "name": "Omniknight",
            "Troll Warlord": 1.837
        },
        {
            "name": "Oracle",
            "Troll Warlord": 1.481
        },
        {
            "name": "Outworld Destroyer",
            "Troll Warlord": 3.112
        },
        {
            "name": "Pangolier",
            "Troll Warlord": 2.002
        },
        {
            "name": "Phantom Assassin",
            "Troll Warlord": -1.711
        },
        {
            "name": "Phantom Lancer",
            "Troll Warlord": 1.495
        },
        {
            "name": "Phoenix",
            "Troll Warlord": 1.276
        },
        {
            "name": "Primal Beast",
            "Troll Warlord": -0.205
        },
        {
            "name": "Puck",
            "Troll Warlord": -0.161
        },
        {
            "name": "Pudge",
            "Troll Warlord": -0.089
        },
        {
            "name": "Pugna",
            "Troll Warlord": 2.011
        },
        {
            "name": "Queen of Pain",
            "Troll Warlord": 0.156
        },
        {
            "name": "Razor",
            "Troll Warlord": 3.524
        },
        {
            "name": "Riki",
            "Troll Warlord": -0.283
        },
        {
            "name": "Ringmaster",
            "Troll Warlord": 0.795
        },
        {
            "name": "Rubick",
            "Troll Warlord": 0.383
        },
        {
            "name": "Sand King",
            "Troll Warlord": -0.863
        },
        {
            "name": "Shadow Demon",
            "Troll Warlord": -0.525
        },
        {
            "name": "Shadow Fiend",
            "Troll Warlord": -1.17
        },
        {
            "name": "Shadow Shaman",
            "Troll Warlord": 1.57
        },
        {
            "name": "Silencer",
            "Troll Warlord": 0.589
        },
        {
            "name": "Skywrath Mage",
            "Troll Warlord": 0.979
        },
        {
            "name": "Slardar",
            "Troll Warlord": -2.472
        },
        {
            "name": "Slark",
            "Troll Warlord": 3.666
        },
        {
            "name": "Snapfire",
            "Troll Warlord": -0.129
        },
        {
            "name": "Sniper",
            "Troll Warlord": 0.094
        },
        {
            "name": "Spectre",
            "Troll Warlord": 0.06
        },
        {
            "name": "Spirit Breaker",
            "Troll Warlord": 0.086
        },
        {
            "name": "Storm Spirit",
            "Troll Warlord": -2.906
        },
        {
            "name": "Sven",
            "Troll Warlord": -2.125
        },
        {
            "name": "Techies",
            "Troll Warlord": 1.788
        },
        {
            "name": "Templar Assassin",
            "Troll Warlord": -3.102
        },
        {
            "name": "Terrorblade",
            "Troll Warlord": -0.589
        },
        {
            "name": "Tidehunter",
            "Troll Warlord": 0.489
        },
        {
            "name": "Timbersaw",
            "Troll Warlord": -0.534
        },
        {
            "name": "Tinker",
            "Troll Warlord": 1.305
        },
        {
            "name": "Tiny",
            "Troll Warlord": 0.252
        },
        {
            "name": "Treant Protector",
            "Troll Warlord": -0.485
        },
        {
            "name": "Tusk",
            "Troll Warlord": 0.503
        },
        {
            "name": "Underlord",
            "Troll Warlord": 1.25
        },
        {
            "name": "Undying",
            "Troll Warlord": -1.307
        },
        {
            "name": "Ursa",
            "Troll Warlord": -2.501
        },
        {
            "name": "Vengeful Spirit",
            "Troll Warlord": -0.458
        },
        {
            "name": "Venomancer",
            "Troll Warlord": -0.018
        },
        {
            "name": "Viper",
            "Troll Warlord": -0.65
        },
        {
            "name": "Visage",
            "Troll Warlord": -1.341
        },
        {
            "name": "Void Spirit",
            "Troll Warlord": -2.214
        },
        {
            "name": "Warlock",
            "Troll Warlord": -0.754
        },
        {
            "name": "Weaver",
            "Troll Warlord": -0.264
        },
        {
            "name": "Windranger",
            "Troll Warlord": 1.556
        },
        {
            "name": "Winter Wyvern",
            "Troll Warlord": 2.336
        },
        {
            "name": "Witch Doctor",
            "Troll Warlord": 0.561
        },
        {
            "name": "Wraith King",
            "Troll Warlord": 0.228
        },
        {
            "name": "Zeus",
            "Troll Warlord": 0.36
        }
    ],
    "Ursa": [
        {
            "name": "Abaddon",
            "Ursa": -0.914
        },
        {
            "name": "Alchemist",
            "Ursa": 0.362
        },
        {
            "name": "Ancient Apparition",
            "Ursa": -0.873
        },
        {
            "name": "Anti-Mage",
            "Ursa": -2.037
        },
        {
            "name": "Arc Warden",
            "Ursa": 0.795
        },
        {
            "name": "Axe",
            "Ursa": -1.03
        },
        {
            "name": "Bane",
            "Ursa": -1.071
        },
        {
            "name": "Batrider",
            "Ursa": 1.842
        },
        {
            "name": "Beastmaster",
            "Ursa": 0.754
        },
        {
            "name": "Bloodseeker",
            "Ursa": -1.142
        },
        {
            "name": "Bounty Hunter",
            "Ursa": -0.121
        },
        {
            "name": "Brewmaster",
            "Ursa": 1.683
        },
        {
            "name": "Bristleback",
            "Ursa": 3.19
        },
        {
            "name": "Broodmother",
            "Ursa": 1.884
        },
        {
            "name": "Centaur Warrunner",
            "Ursa": -0.712
        },
        {
            "name": "Chaos Knight",
            "Ursa": 1.65
        },
        {
            "name": "Chen",
            "Ursa": -1.311
        },
        {
            "name": "Clinkz",
            "Ursa": 2.618
        },
        {
            "name": "Clockwerk",
            "Ursa": -0.646
        },
        {
            "name": "Crystal Maiden",
            "Ursa": -0.055
        },
        {
            "name": "Dark Seer",
            "Ursa": -1.343
        },
        {
            "name": "Dark Willow",
            "Ursa": 0.818
        },
        {
            "name": "Dawnbreaker",
            "Ursa": -1.512
        },
        {
            "name": "Dazzle",
            "Ursa": 0.817
        },
        {
            "name": "Death Prophet",
            "Ursa": 2.026
        },
        {
            "name": "Disruptor",
            "Ursa": 1.122
        },
        {
            "name": "Doom",
            "Ursa": 0.305
        },
        {
            "name": "Dragon Knight",
            "Ursa": -0.074
        },
        {
            "name": "Drow Ranger",
            "Ursa": 0.465
        },
        {
            "name": "Earth Spirit",
            "Ursa": -1.816
        },
        {
            "name": "Earthshaker",
            "Ursa": 0.057
        },
        {
            "name": "Elder Titan",
            "Ursa": -2.283
        },
        {
            "name": "Ember Spirit",
            "Ursa": -0.681
        },
        {
            "name": "Enchantress",
            "Ursa": 1.218
        },
        {
            "name": "Enigma",
            "Ursa": 1.622
        },
        {
            "name": "Faceless Void",
            "Ursa": 0.24
        },
        {
            "name": "Grimstroke",
            "Ursa": 0.239
        },
        {
            "name": "Gyrocopter",
            "Ursa": 0.032
        },
        {
            "name": "Hoodwink",
            "Ursa": 0.765
        },
        {
            "name": "Huskar",
            "Ursa": -0.248
        },
        {
            "name": "Invoker",
            "Ursa": -0.007
        },
        {
            "name": "Io",
            "Ursa": -0.528
        },
        {
            "name": "Jakiro",
            "Ursa": -0.292
        },
        {
            "name": "Juggernaut",
            "Ursa": -1.913
        },
        {
            "name": "Keeper of the Light",
            "Ursa": -0.351
        },
        {
            "name": "Kez",
            "Ursa": -1.743
        },
        {
            "name": "Kunkka",
            "Ursa": 0.333
        },
        {
            "name": "Legion Commander",
            "Ursa": -1.394
        },
        {
            "name": "Leshrac",
            "Ursa": -1.785
        },
        {
            "name": "Lich",
            "Ursa": 0.271
        },
        {
            "name": "Lifestealer",
            "Ursa": -0.69
        },
        {
            "name": "Lina",
            "Ursa": -1.149
        },
        {
            "name": "Lion",
            "Ursa": 0.476
        },
        {
            "name": "Lone Druid",
            "Ursa": -0.819
        },
        {
            "name": "Luna",
            "Ursa": 0.538
        },
        {
            "name": "Lycan",
            "Ursa": 0.093
        },
        {
            "name": "Magnus",
            "Ursa": -1.227
        },
        {
            "name": "Marci",
            "Ursa": -0.648
        },
        {
            "name": "Mars",
            "Ursa": 0.195
        },
        {
            "name": "Medusa",
            "Ursa": -0.61
        },
        {
            "name": "Meepo",
            "Ursa": -0.843
        },
        {
            "name": "Mirana",
            "Ursa": 1.973
        },
        {
            "name": "Monkey King",
            "Ursa": -0.358
        },
        {
            "name": "Morphling",
            "Ursa": -0.971
        },
        {
            "name": "Muerta",
            "Ursa": 0.82
        },
        {
            "name": "Naga Siren",
            "Ursa": 2.94
        },
        {
            "name": "Nature's Prophet",
            "Ursa": 0.56
        },
        {
            "name": "Necrophos",
            "Ursa": 1.23
        },
        {
            "name": "Night Stalker",
            "Ursa": 0.757
        },
        {
            "name": "Nyx Assassin",
            "Ursa": -1.889
        },
        {
            "name": "Ogre Magi",
            "Ursa": 2.048
        },
        {
            "name": "Omniknight",
            "Ursa": 0.917
        },
        {
            "name": "Oracle",
            "Ursa": -0.614
        },
        {
            "name": "Outworld Destroyer",
            "Ursa": -0.009
        },
        {
            "name": "Pangolier",
            "Ursa": -0.099
        },
        {
            "name": "Phantom Assassin",
            "Ursa": -0.158
        },
        {
            "name": "Phantom Lancer",
            "Ursa": 4.281
        },
        {
            "name": "Phoenix",
            "Ursa": -0.138
        },
        {
            "name": "Primal Beast",
            "Ursa": 0.202
        },
        {
            "name": "Puck",
            "Ursa": -0.522
        },
        {
            "name": "Pudge",
            "Ursa": -2.835
        },
        {
            "name": "Pugna",
            "Ursa": -0.475
        },
        {
            "name": "Queen of Pain",
            "Ursa": -0.284
        },
        {
            "name": "Razor",
            "Ursa": 1.901
        },
        {
            "name": "Riki",
            "Ursa": 3.166
        },
        {
            "name": "Ringmaster",
            "Ursa": -0.662
        },
        {
            "name": "Rubick",
            "Ursa": -2.022
        },
        {
            "name": "Sand King",
            "Ursa": -0.486
        },
        {
            "name": "Shadow Demon",
            "Ursa": 0.449
        },
        {
            "name": "Shadow Fiend",
            "Ursa": -1.051
        },
        {
            "name": "Shadow Shaman",
            "Ursa": 2.641
        },
        {
            "name": "Silencer",
            "Ursa": 0.728
        },
        {
            "name": "Skywrath Mage",
            "Ursa": 0.337
        },
        {
            "name": "Slardar",
            "Ursa": 1.653
        },
        {
            "name": "Slark",
            "Ursa": 0.53
        },
        {
            "name": "Snapfire",
            "Ursa": -1.136
        },
        {
            "name": "Sniper",
            "Ursa": -0.926
        },
        {
            "name": "Spectre",
            "Ursa": 1.486
        },
        {
            "name": "Spirit Breaker",
            "Ursa": 0.153
        },
        {
            "name": "Storm Spirit",
            "Ursa": 0.27
        },
        {
            "name": "Sven",
            "Ursa": -0.351
        },
        {
            "name": "Techies",
            "Ursa": 0.526
        },
        {
            "name": "Templar Assassin",
            "Ursa": 1.656
        },
        {
            "name": "Terrorblade",
            "Ursa": 0.571
        },
        {
            "name": "Tidehunter",
            "Ursa": -2.347
        },
        {
            "name": "Timbersaw",
            "Ursa": -2.161
        },
        {
            "name": "Tinker",
            "Ursa": 1.334
        },
        {
            "name": "Tiny",
            "Ursa": 0.355
        },
        {
            "name": "Treant Protector",
            "Ursa": 0.126
        },
        {
            "name": "Troll Warlord",
            "Ursa": 2.26
        },
        {
            "name": "Tusk",
            "Ursa": 0.635
        },
        {
            "name": "Underlord",
            "Ursa": -1.71
        },
        {
            "name": "Undying",
            "Ursa": 1.545
        },
        {
            "name": "Vengeful Spirit",
            "Ursa": 0.158
        },
        {
            "name": "Venomancer",
            "Ursa": 1.969
        },
        {
            "name": "Viper",
            "Ursa": 0.687
        },
        {
            "name": "Visage",
            "Ursa": 0.654
        },
        {
            "name": "Void Spirit",
            "Ursa": -1.053
        },
        {
            "name": "Warlock",
            "Ursa": -1.104
        },
        {
            "name": "Weaver",
            "Ursa": 2.035
        },
        {
            "name": "Windranger",
            "Ursa": 2.505
        },
        {
            "name": "Winter Wyvern",
            "Ursa": 1.269
        },
        {
            "name": "Witch Doctor",
            "Ursa": -0.981
        },
        {
            "name": "Wraith King",
            "Ursa": 2.247
        },
        {
            "name": "Zeus",
            "Ursa": 0.544
        }
    ],
    "Viper": [
        {
            "name": "Abaddon",
            "Viper": -0.528
        },
        {
            "name": "Alchemist",
            "Viper": -0.864
        },
        {
            "name": "Ancient Apparition",
            "Viper": -0.034
        },
        {
            "name": "Anti-Mage",
            "Viper": 0.005
        },
        {
            "name": "Arc Warden",
            "Viper": 2.038
        },
        {
            "name": "Axe",
            "Viper": -1.069
        },
        {
            "name": "Bane",
            "Viper": 0.125
        },
        {
            "name": "Batrider",
            "Viper": -2.315
        },
        {
            "name": "Beastmaster",
            "Viper": -1.415
        },
        {
            "name": "Bloodseeker",
            "Viper": -2.178
        },
        {
            "name": "Bounty Hunter",
            "Viper": -0.99
        },
        {
            "name": "Brewmaster",
            "Viper": 2.08
        },
        {
            "name": "Bristleback",
            "Viper": -5.337
        },
        {
            "name": "Broodmother",
            "Viper": 0.854
        },
        {
            "name": "Centaur Warrunner",
            "Viper": 0.708
        },
        {
            "name": "Chaos Knight",
            "Viper": 3.651
        },
        {
            "name": "Chen",
            "Viper": 1.485
        },
        {
            "name": "Clinkz",
            "Viper": 1.12
        },
        {
            "name": "Clockwerk",
            "Viper": -1.688
        },
        {
            "name": "Crystal Maiden",
            "Viper": 0.929
        },
        {
            "name": "Dark Seer",
            "Viper": 3.227
        },
        {
            "name": "Dark Willow",
            "Viper": -0.005
        },
        {
            "name": "Dawnbreaker",
            "Viper": -0.459
        },
        {
            "name": "Dazzle",
            "Viper": -0.695
        },
        {
            "name": "Death Prophet",
            "Viper": 0.163
        },
        {
            "name": "Disruptor",
            "Viper": -1.352
        },
        {
            "name": "Doom",
            "Viper": -0.567
        },
        {
            "name": "Dragon Knight",
            "Viper": -3.705
        },
        {
            "name": "Drow Ranger",
            "Viper": 1.515
        },
        {
            "name": "Earth Spirit",
            "Viper": 0.348
        },
        {
            "name": "Earthshaker",
            "Viper": -1.691
        },
        {
            "name": "Elder Titan",
            "Viper": 0.267
        },
        {
            "name": "Ember Spirit",
            "Viper": -0.624
        },
        {
            "name": "Enchantress",
            "Viper": 0.744
        },
        {
            "name": "Enigma",
            "Viper": -0.085
        },
        {
            "name": "Faceless Void",
            "Viper": -0.094
        },
        {
            "name": "Grimstroke",
            "Viper": -0.295
        },
        {
            "name": "Gyrocopter",
            "Viper": -2.074
        },
        {
            "name": "Hoodwink",
            "Viper": 0.536
        },
        {
            "name": "Huskar",
            "Viper": -6.653
        },
        {
            "name": "Invoker",
            "Viper": 1.596
        },
        {
            "name": "Io",
            "Viper": -1.021
        },
        {
            "name": "Jakiro",
            "Viper": 0.181
        },
        {
            "name": "Juggernaut",
            "Viper": 2.095
        },
        {
            "name": "Keeper of the Light",
            "Viper": 0.194
        },
        {
            "name": "Kez",
            "Viper": 0.56
        },
        {
            "name": "Kunkka",
            "Viper": 0.284
        },
        {
            "name": "Legion Commander",
            "Viper": 1.889
        },
        {
            "name": "Leshrac",
            "Viper": -0.21
        },
        {
            "name": "Lich",
            "Viper": -0.514
        },
        {
            "name": "Lifestealer",
            "Viper": -1.307
        },
        {
            "name": "Lina",
            "Viper": -0.169
        },
        {
            "name": "Lion",
            "Viper": -0.612
        },
        {
            "name": "Lone Druid",
            "Viper": -2.34
        },
        {
            "name": "Luna",
            "Viper": -0.249
        },
        {
            "name": "Lycan",
            "Viper": 2.818
        },
        {
            "name": "Magnus",
            "Viper": 0.01
        },
        {
            "name": "Marci",
            "Viper": 0.89
        },
        {
            "name": "Mars",
            "Viper": 1.199
        },
        {
            "name": "Medusa",
            "Viper": 1.86
        },
        {
            "name": "Meepo",
            "Viper": 1.353
        },
        {
            "name": "Mirana",
            "Viper": 1.227
        },
        {
            "name": "Monkey King",
            "Viper": -1.231
        },
        {
            "name": "Morphling",
            "Viper": 3.116
        },
        {
            "name": "Muerta",
            "Viper": 0.467
        },
        {
            "name": "Naga Siren",
            "Viper": 4.162
        },
        {
            "name": "Nature's Prophet",
            "Viper": 0.332
        },
        {
            "name": "Necrophos",
            "Viper": -2.301
        },
        {
            "name": "Night Stalker",
            "Viper": -2.073
        },
        {
            "name": "Nyx Assassin",
            "Viper": -0.652
        },
        {
            "name": "Ogre Magi",
            "Viper": -0.712
        },
        {
            "name": "Omniknight",
            "Viper": 0.007
        },
        {
            "name": "Oracle",
            "Viper": -0.64
        },
        {
            "name": "Outworld Destroyer",
            "Viper": 0.676
        },
        {
            "name": "Pangolier",
            "Viper": 1.524
        },
        {
            "name": "Phantom Assassin",
            "Viper": -0.191
        },
        {
            "name": "Phantom Lancer",
            "Viper": 6.232
        },
        {
            "name": "Phoenix",
            "Viper": -0.652
        },
        {
            "name": "Primal Beast",
            "Viper": -0.823
        },
        {
            "name": "Puck",
            "Viper": 0.819
        },
        {
            "name": "Pudge",
            "Viper": 2.249
        },
        {
            "name": "Pugna",
            "Viper": -0.391
        },
        {
            "name": "Queen of Pain",
            "Viper": -0.27
        },
        {
            "name": "Razor",
            "Viper": -1.526
        },
        {
            "name": "Riki",
            "Viper": 1.205
        },
        {
            "name": "Ringmaster",
            "Viper": -0.028
        },
        {
            "name": "Rubick",
            "Viper": 0.464
        },
        {
            "name": "Sand King",
            "Viper": 0.617
        },
        {
            "name": "Shadow Demon",
            "Viper": 1.967
        },
        {
            "name": "Shadow Fiend",
            "Viper": -0.089
        },
        {
            "name": "Shadow Shaman",
            "Viper": 1.241
        },
        {
            "name": "Silencer",
            "Viper": -0.656
        },
        {
            "name": "Skywrath Mage",
            "Viper": -0.166
        },
        {
            "name": "Slardar",
            "Viper": -0.037
        },
        {
            "name": "Slark",
            "Viper": 1.595
        },
        {
            "name": "Snapfire",
            "Viper": 0.575
        },
        {
            "name": "Sniper",
            "Viper": 0.813
        },
        {
            "name": "Spectre",
            "Viper": -1.804
        },
        {
            "name": "Spirit Breaker",
            "Viper": -0.18
        },
        {
            "name": "Storm Spirit",
            "Viper": 0.722
        },
        {
            "name": "Sven",
            "Viper": -0.211
        },
        {
            "name": "Techies",
            "Viper": 1.157
        },
        {
            "name": "Templar Assassin",
            "Viper": -1.13
        },
        {
            "name": "Terrorblade",
            "Viper": 2.619
        },
        {
            "name": "Tidehunter",
            "Viper": -0.807
        },
        {
            "name": "Timbersaw",
            "Viper": 0.024
        },
        {
            "name": "Tinker",
            "Viper": 2.07
        },
        {
            "name": "Tiny",
            "Viper": -2.128
        },
        {
            "name": "Treant Protector",
            "Viper": -1.768
        },
        {
            "name": "Troll Warlord",
            "Viper": 0.49
        },
        {
            "name": "Tusk",
            "Viper": 0.406
        },
        {
            "name": "Underlord",
            "Viper": -0.347
        },
        {
            "name": "Undying",
            "Viper": 0.717
        },
        {
            "name": "Ursa",
            "Viper": -0.713
        },
        {
            "name": "Vengeful Spirit",
            "Viper": -0.261
        },
        {
            "name": "Venomancer",
            "Viper": -0.862
        },
        {
            "name": "Visage",
            "Viper": -0.492
        },
        {
            "name": "Void Spirit",
            "Viper": 1.053
        },
        {
            "name": "Warlock",
            "Viper": 0.863
        },
        {
            "name": "Weaver",
            "Viper": 2.382
        },
        {
            "name": "Windranger",
            "Viper": 2.94
        },
        {
            "name": "Winter Wyvern",
            "Viper": -1.395
        },
        {
            "name": "Witch Doctor",
            "Viper": 1.985
        },
        {
            "name": "Wraith King",
            "Viper": 1.112
        },
        {
            "name": "Zeus",
            "Viper": -1.237
        }
    ],
    "Weaver": [
        {
            "name": "Abaddon",
            "Weaver": -1.232
        },
        {
            "name": "Alchemist",
            "Weaver": 0.156
        },
        {
            "name": "Ancient Apparition",
            "Weaver": -2.184
        },
        {
            "name": "Anti-Mage",
            "Weaver": 0.366
        },
        {
            "name": "Arc Warden",
            "Weaver": 1.326
        },
        {
            "name": "Axe",
            "Weaver": 1.964
        },
        {
            "name": "Bane",
            "Weaver": 0.675
        },
        {
            "name": "Batrider",
            "Weaver": -2.325
        },
        {
            "name": "Beastmaster",
            "Weaver": -1.36
        },
        {
            "name": "Bloodseeker",
            "Weaver": -0.765
        },
        {
            "name": "Bounty Hunter",
            "Weaver": 1.256
        },
        {
            "name": "Brewmaster",
            "Weaver": -2.453
        },
        {
            "name": "Bristleback",
            "Weaver": 1.287
        },
        {
            "name": "Broodmother",
            "Weaver": 1.32
        },
        {
            "name": "Centaur Warrunner",
            "Weaver": -0.167
        },
        {
            "name": "Chaos Knight",
            "Weaver": 0.336
        },
        {
            "name": "Chen",
            "Weaver": -1.102
        },
        {
            "name": "Clinkz",
            "Weaver": 0.879
        },
        {
            "name": "Clockwerk",
            "Weaver": 0.825
        },
        {
            "name": "Crystal Maiden",
            "Weaver": 0.798
        },
        {
            "name": "Dark Seer",
            "Weaver": -2.165
        },
        {
            "name": "Dark Willow",
            "Weaver": 2.062
        },
        {
            "name": "Dawnbreaker",
            "Weaver": -2.051
        },
        {
            "name": "Dazzle",
            "Weaver": -1.768
        },
        {
            "name": "Death Prophet",
            "Weaver": -1.297
        },
        {
            "name": "Disruptor",
            "Weaver": -0.115
        },
        {
            "name": "Doom",
            "Weaver": -0.407
        },
        {
            "name": "Dragon Knight",
            "Weaver": 0.953
        },
        {
            "name": "Drow Ranger",
            "Weaver": 2.421
        },
        {
            "name": "Earth Spirit",
            "Weaver": -0.841
        },
        {
            "name": "Earthshaker",
            "Weaver": 0.402
        },
        {
            "name": "Elder Titan",
            "Weaver": -0.78
        },
        {
            "name": "Ember Spirit",
            "Weaver": -2.294
        },
        {
            "name": "Enchantress",
            "Weaver": -0.783
        },
        {
            "name": "Enigma",
            "Weaver": -0.507
        },
        {
            "name": "Faceless Void",
            "Weaver": 3.507
        },
        {
            "name": "Grimstroke",
            "Weaver": -0.269
        },
        {
            "name": "Gyrocopter",
            "Weaver": 0.51
        },
        {
            "name": "Hoodwink",
            "Weaver": -0.152
        },
        {
            "name": "Huskar",
            "Weaver": 0.546
        },
        {
            "name": "Invoker",
            "Weaver": -1.192
        },
        {
            "name": "Io",
            "Weaver": -1.186
        },
        {
            "name": "Jakiro",
            "Weaver": -1.193
        },
        {
            "name": "Juggernaut",
            "Weaver": -2.086
        },
        {
            "name": "Keeper of the Light",
            "Weaver": -2.305
        },
        {
            "name": "Kez",
            "Weaver": -0.249
        },
        {
            "name": "Kunkka",
            "Weaver": -1.752
        },
        {
            "name": "Legion Commander",
            "Weaver": 1.722
        },
        {
            "name": "Leshrac",
            "Weaver": -0.35
        },
        {
            "name": "Lich",
            "Weaver": -1.623
        },
        {
            "name": "Lifestealer",
            "Weaver": -1.508
        },
        {
            "name": "Lina",
            "Weaver": -0.929
        },
        {
            "name": "Lion",
            "Weaver": 1.292
        },
        {
            "name": "Lone Druid",
            "Weaver": -1.482
        },
        {
            "name": "Luna",
            "Weaver": 1.859
        },
        {
            "name": "Lycan",
            "Weaver": -0.906
        },
        {
            "name": "Magnus",
            "Weaver": -1.56
        },
        {
            "name": "Marci",
            "Weaver": -1.794
        },
        {
            "name": "Mars",
            "Weaver": 0.615
        },
        {
            "name": "Medusa",
            "Weaver": 1.299
        },
        {
            "name": "Meepo",
            "Weaver": 1.337
        },
        {
            "name": "Mirana",
            "Weaver": 1.031
        },
        {
            "name": "Monkey King",
            "Weaver": -1.458
        },
        {
            "name": "Morphling",
            "Weaver": 1.49
        },
        {
            "name": "Muerta",
            "Weaver": 1.345
        },
        {
            "name": "Naga Siren",
            "Weaver": 3.043
        },
        {
            "name": "Nature's Prophet",
            "Weaver": 1.045
        },
        {
            "name": "Necrophos",
            "Weaver": 0.717
        },
        {
            "name": "Night Stalker",
            "Weaver": -0.096
        },
        {
            "name": "Nyx Assassin",
            "Weaver": 2.145
        },
        {
            "name": "Ogre Magi",
            "Weaver": 1.178
        },
        {
            "name": "Omniknight",
            "Weaver": -1.319
        },
        {
            "name": "Oracle",
            "Weaver": -1.032
        },
        {
            "name": "Outworld Destroyer",
            "Weaver": -0.271
        },
        {
            "name": "Pangolier",
            "Weaver": -1.933
        },
        {
            "name": "Phantom Assassin",
            "Weaver": -0.794
        },
        {
            "name": "Phantom Lancer",
            "Weaver": 1.105
        },
        {
            "name": "Phoenix",
            "Weaver": -1.113
        },
        {
            "name": "Primal Beast",
            "Weaver": -1.263
        },
        {
            "name": "Puck",
            "Weaver": 1.527
        },
        {
            "name": "Pudge",
            "Weaver": -1.582
        },
        {
            "name": "Pugna",
            "Weaver": -0.536
        },
        {
            "name": "Queen of Pain",
            "Weaver": 0.192
        },
        {
            "name": "Razor",
            "Weaver": -2.443
        },
        {
            "name": "Riki",
            "Weaver": 1.974
        },
        {
            "name": "Ringmaster",
            "Weaver": -0.262
        },
        {
            "name": "Rubick",
            "Weaver": 2.376
        },
        {
            "name": "Sand King",
            "Weaver": -0.359
        },
        {
            "name": "Shadow Demon",
            "Weaver": -1.884
        },
        {
            "name": "Shadow Fiend",
            "Weaver": 0.306
        },
        {
            "name": "Shadow Shaman",
            "Weaver": 1.375
        },
        {
            "name": "Silencer",
            "Weaver": 1.033
        },
        {
            "name": "Skywrath Mage",
            "Weaver": 0.757
        },
        {
            "name": "Slardar",
            "Weaver": 0.617
        },
        {
            "name": "Slark",
            "Weaver": 0.82
        },
        {
            "name": "Snapfire",
            "Weaver": -1.135
        },
        {
            "name": "Sniper",
            "Weaver": -1.062
        },
        {
            "name": "Spectre",
            "Weaver": 0.17
        },
        {
            "name": "Spirit Breaker",
            "Weaver": 0.237
        },
        {
            "name": "Storm Spirit",
            "Weaver": 0.336
        },
        {
            "name": "Sven",
            "Weaver": -1.075
        },
        {
            "name": "Techies",
            "Weaver": 1.383
        },
        {
            "name": "Templar Assassin",
            "Weaver": 0.445
        },
        {
            "name": "Terrorblade",
            "Weaver": -0.198
        },
        {
            "name": "Tidehunter",
            "Weaver": -1.585
        },
        {
            "name": "Timbersaw",
            "Weaver": -2.472
        },
        {
            "name": "Tinker",
            "Weaver": 0.409
        },
        {
            "name": "Tiny",
            "Weaver": 0.042
        },
        {
            "name": "Treant Protector",
            "Weaver": -0.439
        },
        {
            "name": "Troll Warlord",
            "Weaver": 0.162
        },
        {
            "name": "Tusk",
            "Weaver": -1.408
        },
        {
            "name": "Underlord",
            "Weaver": 0.029
        },
        {
            "name": "Undying",
            "Weaver": -0.824
        },
        {
            "name": "Ursa",
            "Weaver": -2.082
        },
        {
            "name": "Vengeful Spirit",
            "Weaver": 0.617
        },
        {
            "name": "Venomancer",
            "Weaver": -2.279
        },
        {
            "name": "Viper",
            "Weaver": -2.427
        },
        {
            "name": "Visage",
            "Weaver": -1.545
        },
        {
            "name": "Void Spirit",
            "Weaver": 1.143
        },
        {
            "name": "Warlock",
            "Weaver": -2.308
        },
        {
            "name": "Windranger",
            "Weaver": -0.399
        },
        {
            "name": "Winter Wyvern",
            "Weaver": -1.392
        },
        {
            "name": "Witch Doctor",
            "Weaver": -0.186
        },
        {
            "name": "Wraith King",
            "Weaver": 0.598
        },
        {
            "name": "Zeus",
            "Weaver": 0.667
        }
    ],
    "Ancient Apparition": [
        {
            "name": "Abaddon",
            "Ancient Apparition": -1.792
        },
        {
            "name": "Alchemist",
            "Ancient Apparition": -2.632
        },
        {
            "name": "Anti-Mage",
            "Ancient Apparition": 2.977
        },
        {
            "name": "Arc Warden",
            "Ancient Apparition": 1.402
        },
        {
            "name": "Axe",
            "Ancient Apparition": -1.095
        },
        {
            "name": "Bane",
            "Ancient Apparition": 1.276
        },
        {
            "name": "Batrider",
            "Ancient Apparition": -0.317
        },
        {
            "name": "Beastmaster",
            "Ancient Apparition": 0.522
        },
        {
            "name": "Bloodseeker",
            "Ancient Apparition": -2.32
        },
        {
            "name": "Bounty Hunter",
            "Ancient Apparition": 0.853
        },
        {
            "name": "Brewmaster",
            "Ancient Apparition": 1.654
        },
        {
            "name": "Bristleback",
            "Ancient Apparition": -2.654
        },
        {
            "name": "Broodmother",
            "Ancient Apparition": 0.096
        },
        {
            "name": "Centaur Warrunner",
            "Ancient Apparition": 0.864
        },
        {
            "name": "Chaos Knight",
            "Ancient Apparition": 1.016
        },
        {
            "name": "Chen",
            "Ancient Apparition": 1.251
        },
        {
            "name": "Clinkz",
            "Ancient Apparition": 0.635
        },
        {
            "name": "Clockwerk",
            "Ancient Apparition": -0.482
        },
        {
            "name": "Crystal Maiden",
            "Ancient Apparition": -0.082
        },
        {
            "name": "Dark Seer",
            "Ancient Apparition": 1.248
        },
        {
            "name": "Dark Willow",
            "Ancient Apparition": -0.254
        },
        {
            "name": "Dawnbreaker",
            "Ancient Apparition": -1.664
        },
        {
            "name": "Dazzle",
            "Ancient Apparition": -2.322
        },
        {
            "name": "Death Prophet",
            "Ancient Apparition": -3.744
        },
        {
            "name": "Disruptor",
            "Ancient Apparition": -0.868
        },
        {
            "name": "Doom",
            "Ancient Apparition": 0.087
        },
        {
            "name": "Dragon Knight",
            "Ancient Apparition": -2.021
        },
        {
            "name": "Drow Ranger",
            "Ancient Apparition": 0.76
        },
        {
            "name": "Earth Spirit",
            "Ancient Apparition": 0.628
        },
        {
            "name": "Earthshaker",
            "Ancient Apparition": -0.682
        },
        {
            "name": "Elder Titan",
            "Ancient Apparition": 2.074
        },
        {
            "name": "Ember Spirit",
            "Ancient Apparition": 0.587
        },
        {
            "name": "Enchantress",
            "Ancient Apparition": 0.676
        },
        {
            "name": "Enigma",
            "Ancient Apparition": -0.183
        },
        {
            "name": "Faceless Void",
            "Ancient Apparition": 0.05
        },
        {
            "name": "Grimstroke",
            "Ancient Apparition": 0.836
        },
        {
            "name": "Gyrocopter",
            "Ancient Apparition": -1.582
        },
        {
            "name": "Hoodwink",
            "Ancient Apparition": 0.753
        },
        {
            "name": "Huskar",
            "Ancient Apparition": -6.466
        },
        {
            "name": "Invoker",
            "Ancient Apparition": 0.261
        },
        {
            "name": "Io",
            "Ancient Apparition": -3.189
        },
        {
            "name": "Jakiro",
            "Ancient Apparition": -0.356
        },
        {
            "name": "Juggernaut",
            "Ancient Apparition": 0.138
        },
        {
            "name": "Keeper of the Light",
            "Ancient Apparition": -0.453
        },
        {
            "name": "Kez",
            "Ancient Apparition": -0.057
        },
        {
            "name": "Kunkka",
            "Ancient Apparition": -1.032
        },
        {
            "name": "Legion Commander",
            "Ancient Apparition": 0.499
        },
        {
            "name": "Leshrac",
            "Ancient Apparition": -2.375
        },
        {
            "name": "Lich",
            "Ancient Apparition": -0.14
        },
        {
            "name": "Lifestealer",
            "Ancient Apparition": -0.234
        },
        {
            "name": "Lina",
            "Ancient Apparition": 1.26
        },
        {
            "name": "Lion",
            "Ancient Apparition": 0.667
        },
        {
            "name": "Lone Druid",
            "Ancient Apparition": 0.207
        },
        {
            "name": "Luna",
            "Ancient Apparition": -0.329
        },
        {
            "name": "Lycan",
            "Ancient Apparition": 2.029
        },
        {
            "name": "Magnus",
            "Ancient Apparition": 0.368
        },
        {
            "name": "Marci",
            "Ancient Apparition": -2.046
        },
        {
            "name": "Mars",
            "Ancient Apparition": 0.442
        },
        {
            "name": "Medusa",
            "Ancient Apparition": 1.675
        },
        {
            "name": "Meepo",
            "Ancient Apparition": -1.134
        },
        {
            "name": "Mirana",
            "Ancient Apparition": 1.212
        },
        {
            "name": "Monkey King",
            "Ancient Apparition": -1.391
        },
        {
            "name": "Morphling",
            "Ancient Apparition": -1.611
        },
        {
            "name": "Muerta",
            "Ancient Apparition": -0.142
        },
        {
            "name": "Naga Siren",
            "Ancient Apparition": -1.355
        },
        {
            "name": "Nature's Prophet",
            "Ancient Apparition": 1.56
        },
        {
            "name": "Necrophos",
            "Ancient Apparition": -4.244
        },
        {
            "name": "Night Stalker",
            "Ancient Apparition": 2.183
        },
        {
            "name": "Nyx Assassin",
            "Ancient Apparition": 0.734
        },
        {
            "name": "Ogre Magi",
            "Ancient Apparition": 0.104
        },
        {
            "name": "Omniknight",
            "Ancient Apparition": -3.041
        },
        {
            "name": "Oracle",
            "Ancient Apparition": 0.682
        },
        {
            "name": "Outworld Destroyer",
            "Ancient Apparition": 2.0
        },
        {
            "name": "Pangolier",
            "Ancient Apparition": 1.124
        },
        {
            "name": "Phantom Assassin",
            "Ancient Apparition": 2.035
        },
        {
            "name": "Phantom Lancer",
            "Ancient Apparition": 1.735
        },
        {
            "name": "Phoenix",
            "Ancient Apparition": -0.148
        },
        {
            "name": "Primal Beast",
            "Ancient Apparition": 0.231
        },
        {
            "name": "Puck",
            "Ancient Apparition": 2.338
        },
        {
            "name": "Pudge",
            "Ancient Apparition": 0.367
        },
        {
            "name": "Pugna",
            "Ancient Apparition": -1.012
        },
        {
            "name": "Queen of Pain",
            "Ancient Apparition": 0.745
        },
        {
            "name": "Razor",
            "Ancient Apparition": -0.437
        },
        {
            "name": "Riki",
            "Ancient Apparition": 0.863
        },
        {
            "name": "Ringmaster",
            "Ancient Apparition": -0.221
        },
        {
            "name": "Rubick",
            "Ancient Apparition": -0.331
        },
        {
            "name": "Sand King",
            "Ancient Apparition": -1.243
        },
        {
            "name": "Shadow Demon",
            "Ancient Apparition": 0.013
        },
        {
            "name": "Shadow Fiend",
            "Ancient Apparition": -0.965
        },
        {
            "name": "Shadow Shaman",
            "Ancient Apparition": -0.157
        },
        {
            "name": "Silencer",
            "Ancient Apparition": 1.79
        },
        {
            "name": "Skywrath Mage",
            "Ancient Apparition": 0.661
        },
        {
            "name": "Slardar",
            "Ancient Apparition": 0.991
        },
        {
            "name": "Slark",
            "Ancient Apparition": -1.74
        },
        {
            "name": "Snapfire",
            "Ancient Apparition": 0.135
        },
        {
            "name": "Sniper",
            "Ancient Apparition": 0.427
        },
        {
            "name": "Spectre",
            "Ancient Apparition": 2.389
        },
        {
            "name": "Spirit Breaker",
            "Ancient Apparition": 1.418
        },
        {
            "name": "Storm Spirit",
            "Ancient Apparition": 3.329
        },
        {
            "name": "Sven",
            "Ancient Apparition": -0.013
        },
        {
            "name": "Techies",
            "Ancient Apparition": 0.35
        },
        {
            "name": "Templar Assassin",
            "Ancient Apparition": 0.661
        },
        {
            "name": "Terrorblade",
            "Ancient Apparition": 0.23
        },
        {
            "name": "Tidehunter",
            "Ancient Apparition": -0.474
        },
        {
            "name": "Timbersaw",
            "Ancient Apparition": -0.574
        },
        {
            "name": "Tinker",
            "Ancient Apparition": -0.313
        },
        {
            "name": "Tiny",
            "Ancient Apparition": -0.261
        },
        {
            "name": "Treant Protector",
            "Ancient Apparition": -1.104
        },
        {
            "name": "Troll Warlord",
            "Ancient Apparition": -2.163
        },
        {
            "name": "Tusk",
            "Ancient Apparition": 0.248
        },
        {
            "name": "Underlord",
            "Ancient Apparition": -2.772
        },
        {
            "name": "Undying",
            "Ancient Apparition": -2.282
        },
        {
            "name": "Ursa",
            "Ancient Apparition": 0.867
        },
        {
            "name": "Vengeful Spirit",
            "Ancient Apparition": 0.712
        },
        {
            "name": "Venomancer",
            "Ancient Apparition": -1.093
        },
        {
            "name": "Viper",
            "Ancient Apparition": 0.006
        },
        {
            "name": "Visage",
            "Ancient Apparition": 0.984
        },
        {
            "name": "Void Spirit",
            "Ancient Apparition": 1.587
        },
        {
            "name": "Warlock",
            "Ancient Apparition": -0.454
        },
        {
            "name": "Weaver",
            "Ancient Apparition": 2.182
        },
        {
            "name": "Windranger",
            "Ancient Apparition": 1.4
        },
        {
            "name": "Winter Wyvern",
            "Ancient Apparition": -2.415
        },
        {
            "name": "Witch Doctor",
            "Ancient Apparition": -0.6
        },
        {
            "name": "Wraith King",
            "Ancient Apparition": 0.61
        },
        {
            "name": "Zeus",
            "Ancient Apparition": 1.009
        }
    ],
    "Crystal Maiden": [
        {
            "name": "Abaddon",
            "Crystal Maiden": -0.389
        },
        {
            "name": "Alchemist",
            "Crystal Maiden": -0.262
        },
        {
            "name": "Ancient Apparition",
            "Crystal Maiden": 0.018
        },
        {
            "name": "Anti-Mage",
            "Crystal Maiden": -1.139
        },
        {
            "name": "Arc Warden",
            "Crystal Maiden": -0.768
        },
        {
            "name": "Axe",
            "Crystal Maiden": 0.22
        },
        {
            "name": "Bane",
            "Crystal Maiden": 0.066
        },
        {
            "name": "Batrider",
            "Crystal Maiden": -0.616
        },
        {
            "name": "Beastmaster",
            "Crystal Maiden": -0.461
        },
        {
            "name": "Bloodseeker",
            "Crystal Maiden": -1.109
        },
        {
            "name": "Bounty Hunter",
            "Crystal Maiden": -1.238
        },
        {
            "name": "Brewmaster",
            "Crystal Maiden": -0.248
        },
        {
            "name": "Bristleback",
            "Crystal Maiden": 1.561
        },
        {
            "name": "Broodmother",
            "Crystal Maiden": -3.543
        },
        {
            "name": "Centaur Warrunner",
            "Crystal Maiden": -0.559
        },
        {
            "name": "Chaos Knight",
            "Crystal Maiden": -2.118
        },
        {
            "name": "Chen",
            "Crystal Maiden": 0.784
        },
        {
            "name": "Clinkz",
            "Crystal Maiden": -0.72
        },
        {
            "name": "Clockwerk",
            "Crystal Maiden": 0.666
        },
        {
            "name": "Dark Seer",
            "Crystal Maiden": -1.247
        },
        {
            "name": "Dark Willow",
            "Crystal Maiden": -0.747
        },
        {
            "name": "Dawnbreaker",
            "Crystal Maiden": 1.712
        },
        {
            "name": "Dazzle",
            "Crystal Maiden": -0.046
        },
        {
            "name": "Death Prophet",
            "Crystal Maiden": 0.59
        },
        {
            "name": "Disruptor",
            "Crystal Maiden": 0.376
        },
        {
            "name": "Doom",
            "Crystal Maiden": -1.06
        },
        {
            "name": "Dragon Knight",
            "Crystal Maiden": -0.929
        },
        {
            "name": "Drow Ranger",
            "Crystal Maiden": 1.538
        },
        {
            "name": "Earth Spirit",
            "Crystal Maiden": 0.609
        },
        {
            "name": "Earthshaker",
            "Crystal Maiden": 0.453
        },
        {
            "name": "Elder Titan",
            "Crystal Maiden": 0.012
        },
        {
            "name": "Ember Spirit",
            "Crystal Maiden": -0.568
        },
        {
            "name": "Enchantress",
            "Crystal Maiden": -1.819
        },
        {
            "name": "Enigma",
            "Crystal Maiden": 1.77
        },
        {
            "name": "Faceless Void",
            "Crystal Maiden": 0.193
        },
        {
            "name": "Grimstroke",
            "Crystal Maiden": 0.456
        },
        {
            "name": "Gyrocopter",
            "Crystal Maiden": 0.82
        },
        {
            "name": "Hoodwink",
            "Crystal Maiden": 0.58
        },
        {
            "name": "Huskar",
            "Crystal Maiden": -0.277
        },
        {
            "name": "Invoker",
            "Crystal Maiden": 0.217
        },
        {
            "name": "Io",
            "Crystal Maiden": -0.923
        },
        {
            "name": "Jakiro",
            "Crystal Maiden": 0.227
        },
        {
            "name": "Juggernaut",
            "Crystal Maiden": 1.536
        },
        {
            "name": "Keeper of the Light",
            "Crystal Maiden": -0.504
        },
        {
            "name": "Kez",
            "Crystal Maiden": 0.337
        },
        {
            "name": "Kunkka",
            "Crystal Maiden": 0.276
        },
        {
            "name": "Legion Commander",
            "Crystal Maiden": 0.391
        },
        {
            "name": "Leshrac",
            "Crystal Maiden": 0.424
        },
        {
            "name": "Lich",
            "Crystal Maiden": 0.034
        },
        {
            "name": "Lifestealer",
            "Crystal Maiden": -0.24
        },
        {
            "name": "Lina",
            "Crystal Maiden": -0.062
        },
        {
            "name": "Lion",
            "Crystal Maiden": -0.057
        },
        {
            "name": "Lone Druid",
            "Crystal Maiden": -2.048
        },
        {
            "name": "Luna",
            "Crystal Maiden": 0.713
        },
        {
            "name": "Lycan",
            "Crystal Maiden": -2.736
        },
        {
            "name": "Magnus",
            "Crystal Maiden": 0.819
        },
        {
            "name": "Marci",
            "Crystal Maiden": -0.586
        },
        {
            "name": "Mars",
            "Crystal Maiden": 0.556
        },
        {
            "name": "Medusa",
            "Crystal Maiden": 0.432
        },
        {
            "name": "Meepo",
            "Crystal Maiden": -2.851
        },
        {
            "name": "Mirana",
            "Crystal Maiden": -0.066
        },
        {
            "name": "Monkey King",
            "Crystal Maiden": 0.79
        },
        {
            "name": "Morphling",
            "Crystal Maiden": 0.434
        },
        {
            "name": "Muerta",
            "Crystal Maiden": 0.134
        },
        {
            "name": "Naga Siren",
            "Crystal Maiden": -2.249
        },
        {
            "name": "Nature's Prophet",
            "Crystal Maiden": -0.72
        },
        {
            "name": "Necrophos",
            "Crystal Maiden": -1.357
        },
        {
            "name": "Night Stalker",
            "Crystal Maiden": -0.719
        },
        {
            "name": "Nyx Assassin",
            "Crystal Maiden": 0.367
        },
        {
            "name": "Ogre Magi",
            "Crystal Maiden": -0.328
        },
        {
            "name": "Omniknight",
            "Crystal Maiden": -1.503
        },
        {
            "name": "Oracle",
            "Crystal Maiden": -0.793
        },
        {
            "name": "Outworld Destroyer",
            "Crystal Maiden": -0.225
        },
        {
            "name": "Pangolier",
            "Crystal Maiden": 0.907
        },
        {
            "name": "Phantom Assassin",
            "Crystal Maiden": 0.102
        },
        {
            "name": "Phantom Lancer",
            "Crystal Maiden": -4.063
        },
        {
            "name": "Phoenix",
            "Crystal Maiden": 0.913
        },
        {
            "name": "Primal Beast",
            "Crystal Maiden": 0.719
        },
        {
            "name": "Puck",
            "Crystal Maiden": 1.304
        },
        {
            "name": "Pudge",
            "Crystal Maiden": 1.894
        },
        {
            "name": "Pugna",
            "Crystal Maiden": 1.002
        },
        {
            "name": "Queen of Pain",
            "Crystal Maiden": 0.27
        },
        {
            "name": "Razor",
            "Crystal Maiden": -1.042
        },
        {
            "name": "Riki",
            "Crystal Maiden": -0.187
        },
        {
            "name": "Ringmaster",
            "Crystal Maiden": 0.218
        },
        {
            "name": "Rubick",
            "Crystal Maiden": 2.293
        },
        {
            "name": "Sand King",
            "Crystal Maiden": -0.136
        },
        {
            "name": "Shadow Demon",
            "Crystal Maiden": 0.357
        },
        {
            "name": "Shadow Fiend",
            "Crystal Maiden": 0.887
        },
        {
            "name": "Shadow Shaman",
            "Crystal Maiden": 0.598
        },
        {
            "name": "Silencer",
            "Crystal Maiden": 0.048
        },
        {
            "name": "Skywrath Mage",
            "Crystal Maiden": -0.245
        },
        {
            "name": "Slardar",
            "Crystal Maiden": -0.723
        },
        {
            "name": "Slark",
            "Crystal Maiden": -1.487
        },
        {
            "name": "Snapfire",
            "Crystal Maiden": 0.231
        },
        {
            "name": "Sniper",
            "Crystal Maiden": 0.772
        },
        {
            "name": "Spectre",
            "Crystal Maiden": -0.584
        },
        {
            "name": "Spirit Breaker",
            "Crystal Maiden": -0.885
        },
        {
            "name": "Storm Spirit",
            "Crystal Maiden": -1.249
        },
        {
            "name": "Sven",
            "Crystal Maiden": -0.721
        },
        {
            "name": "Techies",
            "Crystal Maiden": 0.483
        },
        {
            "name": "Templar Assassin",
            "Crystal Maiden": -0.754
        },
        {
            "name": "Terrorblade",
            "Crystal Maiden": -0.532
        },
        {
            "name": "Tidehunter",
            "Crystal Maiden": 0.739
        },
        {
            "name": "Timbersaw",
            "Crystal Maiden": -0.029
        },
        {
            "name": "Tinker",
            "Crystal Maiden": 0.012
        },
        {
            "name": "Tiny",
            "Crystal Maiden": -0.265
        },
        {
            "name": "Treant Protector",
            "Crystal Maiden": -0.212
        },
        {
            "name": "Troll Warlord",
            "Crystal Maiden": -1.696
        },
        {
            "name": "Tusk",
            "Crystal Maiden": -0.227
        },
        {
            "name": "Underlord",
            "Crystal Maiden": -0.911
        },
        {
            "name": "Undying",
            "Crystal Maiden": 0.57
        },
        {
            "name": "Ursa",
            "Crystal Maiden": 0.102
        },
        {
            "name": "Vengeful Spirit",
            "Crystal Maiden": -0.765
        },
        {
            "name": "Venomancer",
            "Crystal Maiden": -0.411
        },
        {
            "name": "Viper",
            "Crystal Maiden": -0.866
        },
        {
            "name": "Visage",
            "Crystal Maiden": -0.262
        },
        {
            "name": "Void Spirit",
            "Crystal Maiden": -0.157
        },
        {
            "name": "Warlock",
            "Crystal Maiden": -0.573
        },
        {
            "name": "Weaver",
            "Crystal Maiden": -0.613
        },
        {
            "name": "Windranger",
            "Crystal Maiden": -0.042
        },
        {
            "name": "Winter Wyvern",
            "Crystal Maiden": -0.851
        },
        {
            "name": "Witch Doctor",
            "Crystal Maiden": 1.031
        },
        {
            "name": "Wraith King",
            "Crystal Maiden": -1.532
        },
        {
            "name": "Zeus",
            "Crystal Maiden": 0.878
        }
    ],
    "Death Prophet": [
        {
            "name": "Abaddon",
            "Death Prophet": -0.937
        },
        {
            "name": "Alchemist",
            "Death Prophet": -1.583
        },
        {
            "name": "Ancient Apparition",
            "Death Prophet": 3.993
        },
        {
            "name": "Anti-Mage",
            "Death Prophet": 2.331
        },
        {
            "name": "Arc Warden",
            "Death Prophet": 0.537
        },
        {
            "name": "Axe",
            "Death Prophet": -1.665
        },
        {
            "name": "Bane",
            "Death Prophet": -1.0
        },
        {
            "name": "Batrider",
            "Death Prophet": -0.25
        },
        {
            "name": "Beastmaster",
            "Death Prophet": -0.072
        },
        {
            "name": "Bloodseeker",
            "Death Prophet": 0.702
        },
        {
            "name": "Bounty Hunter",
            "Death Prophet": 0.476
        },
        {
            "name": "Brewmaster",
            "Death Prophet": -1.157
        },
        {
            "name": "Bristleback",
            "Death Prophet": 1.594
        },
        {
            "name": "Broodmother",
            "Death Prophet": 0.796
        },
        {
            "name": "Centaur Warrunner",
            "Death Prophet": -0.58
        },
        {
            "name": "Chaos Knight",
            "Death Prophet": 2.112
        },
        {
            "name": "Chen",
            "Death Prophet": -1.863
        },
        {
            "name": "Clinkz",
            "Death Prophet": 2.584
        },
        {
            "name": "Clockwerk",
            "Death Prophet": 0.012
        },
        {
            "name": "Crystal Maiden",
            "Death Prophet": -0.52
        },
        {
            "name": "Dark Seer",
            "Death Prophet": 0.28
        },
        {
            "name": "Dark Willow",
            "Death Prophet": 0.182
        },
        {
            "name": "Dawnbreaker",
            "Death Prophet": -1.418
        },
        {
            "name": "Dazzle",
            "Death Prophet": -0.206
        },
        {
            "name": "Disruptor",
            "Death Prophet": 0.946
        },
        {
            "name": "Doom",
            "Death Prophet": -0.682
        },
        {
            "name": "Dragon Knight",
            "Death Prophet": 0.975
        },
        {
            "name": "Drow Ranger",
            "Death Prophet": 1.826
        },
        {
            "name": "Earth Spirit",
            "Death Prophet": -1.297
        },
        {
            "name": "Earthshaker",
            "Death Prophet": -0.58
        },
        {
            "name": "Elder Titan",
            "Death Prophet": 0.877
        },
        {
            "name": "Ember Spirit",
            "Death Prophet": -0.962
        },
        {
            "name": "Enchantress",
            "Death Prophet": 2.214
        },
        {
            "name": "Enigma",
            "Death Prophet": 0.261
        },
        {
            "name": "Faceless Void",
            "Death Prophet": -0.178
        },
        {
            "name": "Grimstroke",
            "Death Prophet": -0.398
        },
        {
            "name": "Gyrocopter",
            "Death Prophet": 1.19
        },
        {
            "name": "Hoodwink",
            "Death Prophet": 1.025
        },
        {
            "name": "Huskar",
            "Death Prophet": -0.392
        },
        {
            "name": "Invoker",
            "Death Prophet": -0.611
        },
        {
            "name": "Io",
            "Death Prophet": -0.559
        },
        {
            "name": "Jakiro",
            "Death Prophet": -0.011
        },
        {
            "name": "Juggernaut",
            "Death Prophet": -2.25
        },
        {
            "name": "Keeper of the Light",
            "Death Prophet": 1.288
        },
        {
            "name": "Kez",
            "Death Prophet": -1.53
        },
        {
            "name": "Kunkka",
            "Death Prophet": -1.189
        },
        {
            "name": "Legion Commander",
            "Death Prophet": 0.547
        },
        {
            "name": "Leshrac",
            "Death Prophet": -1.879
        },
        {
            "name": "Lich",
            "Death Prophet": -0.509
        },
        {
            "name": "Lifestealer",
            "Death Prophet": -2.363
        },
        {
            "name": "Lina",
            "Death Prophet": 0.585
        },
        {
            "name": "Lion",
            "Death Prophet": -0.2
        },
        {
            "name": "Lone Druid",
            "Death Prophet": -0.642
        },
        {
            "name": "Luna",
            "Death Prophet": 1.399
        },
        {
            "name": "Lycan",
            "Death Prophet": 3.202
        },
        {
            "name": "Magnus",
            "Death Prophet": 0.454
        },
        {
            "name": "Marci",
            "Death Prophet": -0.538
        },
        {
            "name": "Mars",
            "Death Prophet": -0.648
        },
        {
            "name": "Medusa",
            "Death Prophet": 1.887
        },
        {
            "name": "Meepo",
            "Death Prophet": -2.307
        },
        {
            "name": "Mirana",
            "Death Prophet": 2.472
        },
        {
            "name": "Monkey King",
            "Death Prophet": -0.074
        },
        {
            "name": "Morphling",
            "Death Prophet": 0.018
        },
        {
            "name": "Muerta",
            "Death Prophet": 0.078
        },
        {
            "name": "Naga Siren",
            "Death Prophet": -1.349
        },
        {
            "name": "Nature's Prophet",
            "Death Prophet": 1.382
        },
        {
            "name": "Necrophos",
            "Death Prophet": -3.103
        },
        {
            "name": "Night Stalker",
            "Death Prophet": 0.348
        },
        {
            "name": "Nyx Assassin",
            "Death Prophet": -1.144
        },
        {
            "name": "Ogre Magi",
            "Death Prophet": 0.491
        },
        {
            "name": "Omniknight",
            "Death Prophet": 0.275
        },
        {
            "name": "Oracle",
            "Death Prophet": -3.357
        },
        {
            "name": "Outworld Destroyer",
            "Death Prophet": -1.832
        },
        {
            "name": "Pangolier",
            "Death Prophet": 0.666
        },
        {
            "name": "Phantom Assassin",
            "Death Prophet": -0.771
        },
        {
            "name": "Phantom Lancer",
            "Death Prophet": 1.606
        },
        {
            "name": "Phoenix",
            "Death Prophet": -1.417
        },
        {
            "name": "Primal Beast",
            "Death Prophet": -1.358
        },
        {
            "name": "Puck",
            "Death Prophet": -0.828
        },
        {
            "name": "Pudge",
            "Death Prophet": -0.534
        },
        {
            "name": "Pugna",
            "Death Prophet": -0.66
        },
        {
            "name": "Queen of Pain",
            "Death Prophet": 0.84
        },
        {
            "name": "Razor",
            "Death Prophet": 1.89
        },
        {
            "name": "Riki",
            "Death Prophet": -0.437
        },
        {
            "name": "Ringmaster",
            "Death Prophet": -0.719
        },
        {
            "name": "Rubick",
            "Death Prophet": -0.104
        },
        {
            "name": "Sand King",
            "Death Prophet": -0.242
        },
        {
            "name": "Shadow Demon",
            "Death Prophet": -0.035
        },
        {
            "name": "Shadow Fiend",
            "Death Prophet": 0.875
        },
        {
            "name": "Shadow Shaman",
            "Death Prophet": -0.204
        },
        {
            "name": "Silencer",
            "Death Prophet": 1.428
        },
        {
            "name": "Skywrath Mage",
            "Death Prophet": -0.514
        },
        {
            "name": "Slardar",
            "Death Prophet": 1.593
        },
        {
            "name": "Slark",
            "Death Prophet": 0.383
        },
        {
            "name": "Snapfire",
            "Death Prophet": -0.341
        },
        {
            "name": "Sniper",
            "Death Prophet": 2.025
        },
        {
            "name": "Spectre",
            "Death Prophet": -0.106
        },
        {
            "name": "Spirit Breaker",
            "Death Prophet": 0.66
        },
        {
            "name": "Storm Spirit",
            "Death Prophet": -0.755
        },
        {
            "name": "Sven",
            "Death Prophet": 1.563
        },
        {
            "name": "Techies",
            "Death Prophet": 0.432
        },
        {
            "name": "Templar Assassin",
            "Death Prophet": -0.091
        },
        {
            "name": "Terrorblade",
            "Death Prophet": 1.451
        },
        {
            "name": "Tidehunter",
            "Death Prophet": -3.8
        },
        {
            "name": "Timbersaw",
            "Death Prophet": -2.432
        },
        {
            "name": "Tinker",
            "Death Prophet": -2.919
        },
        {
            "name": "Tiny",
            "Death Prophet": -1.067
        },
        {
            "name": "Treant Protector",
            "Death Prophet": -0.616
        },
        {
            "name": "Troll Warlord",
            "Death Prophet": 0.591
        },
        {
            "name": "Tusk",
            "Death Prophet": -0.325
        },
        {
            "name": "Underlord",
            "Death Prophet": -0.309
        },
        {
            "name": "Undying",
            "Death Prophet": 1.826
        },
        {
            "name": "Ursa",
            "Death Prophet": -2.163
        },
        {
            "name": "Vengeful Spirit",
            "Death Prophet": 0.576
        },
        {
            "name": "Venomancer",
            "Death Prophet": 0.944
        },
        {
            "name": "Viper",
            "Death Prophet": -0.162
        },
        {
            "name": "Visage",
            "Death Prophet": 0.09
        },
        {
            "name": "Void Spirit",
            "Death Prophet": 0.068
        },
        {
            "name": "Warlock",
            "Death Prophet": 0.961
        },
        {
            "name": "Weaver",
            "Death Prophet": 1.282
        },
        {
            "name": "Windranger",
            "Death Prophet": 1.128
        },
        {
            "name": "Winter Wyvern",
            "Death Prophet": -1.644
        },
        {
            "name": "Witch Doctor",
            "Death Prophet": -0.54
        },
        {
            "name": "Wraith King",
            "Death Prophet": 0.289
        },
        {
            "name": "Zeus",
            "Death Prophet": -0.765
        }
    ],
    "Disruptor": [
        {
            "name": "Abaddon",
            "Disruptor": 0.183
        },
        {
            "name": "Alchemist",
            "Disruptor": 0.574
        },
        {
            "name": "Ancient Apparition",
            "Disruptor": 0.85
        },
        {
            "name": "Anti-Mage",
            "Disruptor": -1.855
        },
        {
            "name": "Arc Warden",
            "Disruptor": 1.634
        },
        {
            "name": "Axe",
            "Disruptor": 0.252
        },
        {
            "name": "Bane",
            "Disruptor": 0.572
        },
        {
            "name": "Batrider",
            "Disruptor": 0.684
        },
        {
            "name": "Beastmaster",
            "Disruptor": 1.427
        },
        {
            "name": "Bloodseeker",
            "Disruptor": 1.207
        },
        {
            "name": "Bounty Hunter",
            "Disruptor": 0.613
        },
        {
            "name": "Brewmaster",
            "Disruptor": -0.362
        },
        {
            "name": "Bristleback",
            "Disruptor": -1.255
        },
        {
            "name": "Broodmother",
            "Disruptor": -0.217
        },
        {
            "name": "Centaur Warrunner",
            "Disruptor": -0.661
        },
        {
            "name": "Chaos Knight",
            "Disruptor": -0.29
        },
        {
            "name": "Chen",
            "Disruptor": 2.292
        },
        {
            "name": "Clinkz",
            "Disruptor": 1.109
        },
        {
            "name": "Clockwerk",
            "Disruptor": -1.253
        },
        {
            "name": "Crystal Maiden",
            "Disruptor": -0.356
        },
        {
            "name": "Dark Seer",
            "Disruptor": -0.424
        },
        {
            "name": "Dark Willow",
            "Disruptor": 0.366
        },
        {
            "name": "Dawnbreaker",
            "Disruptor": -0.557
        },
        {
            "name": "Dazzle",
            "Disruptor": 1.031
        },
        {
            "name": "Death Prophet",
            "Disruptor": -0.864
        },
        {
            "name": "Doom",
            "Disruptor": -0.162
        },
        {
            "name": "Dragon Knight",
            "Disruptor": 0.967
        },
        {
            "name": "Drow Ranger",
            "Disruptor": 1.364
        },
        {
            "name": "Earth Spirit",
            "Disruptor": 0.528
        },
        {
            "name": "Earthshaker",
            "Disruptor": -0.841
        },
        {
            "name": "Elder Titan",
            "Disruptor": 0.218
        },
        {
            "name": "Ember Spirit",
            "Disruptor": 0.288
        },
        {
            "name": "Enchantress",
            "Disruptor": 0.138
        },
        {
            "name": "Enigma",
            "Disruptor": -0.626
        },
        {
            "name": "Faceless Void",
            "Disruptor": -0.631
        },
        {
            "name": "Grimstroke",
            "Disruptor": -0.535
        },
        {
            "name": "Gyrocopter",
            "Disruptor": 0.449
        },
        {
            "name": "Hoodwink",
            "Disruptor": 0.431
        },
        {
            "name": "Huskar",
            "Disruptor": 1.723
        },
        {
            "name": "Invoker",
            "Disruptor": 1.004
        },
        {
            "name": "Io",
            "Disruptor": -1.491
        },
        {
            "name": "Jakiro",
            "Disruptor": -0.07
        },
        {
            "name": "Juggernaut",
            "Disruptor": -0.863
        },
        {
            "name": "Keeper of the Light",
            "Disruptor": 1.026
        },
        {
            "name": "Kez",
            "Disruptor": 0.924
        },
        {
            "name": "Kunkka",
            "Disruptor": 1.018
        },
        {
            "name": "Legion Commander",
            "Disruptor": 0.951
        },
        {
            "name": "Leshrac",
            "Disruptor": -0.69
        },
        {
            "name": "Lich",
            "Disruptor": -0.057
        },
        {
            "name": "Lifestealer",
            "Disruptor": 0.108
        },
        {
            "name": "Lina",
            "Disruptor": -0.047
        },
        {
            "name": "Lion",
            "Disruptor": -0.435
        },
        {
            "name": "Lone Druid",
            "Disruptor": 0.073
        },
        {
            "name": "Luna",
            "Disruptor": 0.678
        },
        {
            "name": "Lycan",
            "Disruptor": 0.683
        },
        {
            "name": "Magnus",
            "Disruptor": -0.987
        },
        {
            "name": "Marci",
            "Disruptor": -0.667
        },
        {
            "name": "Mars",
            "Disruptor": -0.356
        },
        {
            "name": "Medusa",
            "Disruptor": 0.783
        },
        {
            "name": "Meepo",
            "Disruptor": -1.067
        },
        {
            "name": "Mirana",
            "Disruptor": 0.16
        },
        {
            "name": "Monkey King",
            "Disruptor": 0.079
        },
        {
            "name": "Morphling",
            "Disruptor": 0.566
        },
        {
            "name": "Muerta",
            "Disruptor": 0.562
        },
        {
            "name": "Naga Siren",
            "Disruptor": 0.106
        },
        {
            "name": "Nature's Prophet",
            "Disruptor": 1.517
        },
        {
            "name": "Necrophos",
            "Disruptor": -1.215
        },
        {
            "name": "Night Stalker",
            "Disruptor": -0.65
        },
        {
            "name": "Nyx Assassin",
            "Disruptor": -1.12
        },
        {
            "name": "Ogre Magi",
            "Disruptor": 0.271
        },
        {
            "name": "Omniknight",
            "Disruptor": 0.111
        },
        {
            "name": "Oracle",
            "Disruptor": 1.388
        },
        {
            "name": "Outworld Destroyer",
            "Disruptor": -0.183
        },
        {
            "name": "Pangolier",
            "Disruptor": 1.397
        },
        {
            "name": "Phantom Assassin",
            "Disruptor": 0.681
        },
        {
            "name": "Phantom Lancer",
            "Disruptor": 0.047
        },
        {
            "name": "Phoenix",
            "Disruptor": -0.821
        },
        {
            "name": "Primal Beast",
            "Disruptor": -1.836
        },
        {
            "name": "Puck",
            "Disruptor": -0.828
        },
        {
            "name": "Pudge",
            "Disruptor": -0.746
        },
        {
            "name": "Pugna",
            "Disruptor": 1.288
        },
        {
            "name": "Queen of Pain",
            "Disruptor": -0.374
        },
        {
            "name": "Razor",
            "Disruptor": 0.619
        },
        {
            "name": "Riki",
            "Disruptor": -1.492
        },
        {
            "name": "Ringmaster",
            "Disruptor": 0.85
        },
        {
            "name": "Rubick",
            "Disruptor": -0.83
        },
        {
            "name": "Sand King",
            "Disruptor": -1.351
        },
        {
            "name": "Shadow Demon",
            "Disruptor": 1.739
        },
        {
            "name": "Shadow Fiend",
            "Disruptor": 0.839
        },
        {
            "name": "Shadow Shaman",
            "Disruptor": 0.574
        },
        {
            "name": "Silencer",
            "Disruptor": -0.489
        },
        {
            "name": "Skywrath Mage",
            "Disruptor": -0.47
        },
        {
            "name": "Slardar",
            "Disruptor": -0.188
        },
        {
            "name": "Slark",
            "Disruptor": -3.741
        },
        {
            "name": "Snapfire",
            "Disruptor": 0.21
        },
        {
            "name": "Sniper",
            "Disruptor": 1.88
        },
        {
            "name": "Spectre",
            "Disruptor": -0.408
        },
        {
            "name": "Spirit Breaker",
            "Disruptor": -0.908
        },
        {
            "name": "Storm Spirit",
            "Disruptor": -1.308
        },
        {
            "name": "Sven",
            "Disruptor": -0.59
        },
        {
            "name": "Techies",
            "Disruptor": -0.192
        },
        {
            "name": "Templar Assassin",
            "Disruptor": 1.718
        },
        {
            "name": "Terrorblade",
            "Disruptor": 1.095
        },
        {
            "name": "Tidehunter",
            "Disruptor": -0.634
        },
        {
            "name": "Timbersaw",
            "Disruptor": -0.951
        },
        {
            "name": "Tinker",
            "Disruptor": 1.044
        },
        {
            "name": "Tiny",
            "Disruptor": 0.043
        },
        {
            "name": "Treant Protector",
            "Disruptor": -0.007
        },
        {
            "name": "Troll Warlord",
            "Disruptor": -0.665
        },
        {
            "name": "Tusk",
            "Disruptor": -0.065
        },
        {
            "name": "Underlord",
            "Disruptor": 0.992
        },
        {
            "name": "Undying",
            "Disruptor": 0.931
        },
        {
            "name": "Ursa",
            "Disruptor": -1.202
        },
        {
            "name": "Vengeful Spirit",
            "Disruptor": 0.467
        },
        {
            "name": "Venomancer",
            "Disruptor": 0.764
        },
        {
            "name": "Viper",
            "Disruptor": 1.324
        },
        {
            "name": "Visage",
            "Disruptor": -1.305
        },
        {
            "name": "Void Spirit",
            "Disruptor": -1.097
        },
        {
            "name": "Warlock",
            "Disruptor": -0.047
        },
        {
            "name": "Weaver",
            "Disruptor": 0.09
        },
        {
            "name": "Windranger",
            "Disruptor": 0.149
        },
        {
            "name": "Winter Wyvern",
            "Disruptor": 0.794
        },
        {
            "name": "Witch Doctor",
            "Disruptor": -0.588
        },
        {
            "name": "Wraith King",
            "Disruptor": 0.165
        },
        {
            "name": "Zeus",
            "Disruptor": 0.372
        }
    ],
    "Enchantress": [
        {
            "name": "Abaddon",
            "Enchantress": -0.557
        },
        {
            "name": "Alchemist",
            "Enchantress": -0.348
        },
        {
            "name": "Ancient Apparition",
            "Enchantress": -0.602
        },
        {
            "name": "Anti-Mage",
            "Enchantress": -2.039
        },
        {
            "name": "Arc Warden",
            "Enchantress": -0.972
        },
        {
            "name": "Axe",
            "Enchantress": 1.03
        },
        {
            "name": "Bane",
            "Enchantress": 1.918
        },
        {
            "name": "Batrider",
            "Enchantress": -2.376
        },
        {
            "name": "Beastmaster",
            "Enchantress": -2.305
        },
        {
            "name": "Bloodseeker",
            "Enchantress": -2.486
        },
        {
            "name": "Bounty Hunter",
            "Enchantress": 1.018
        },
        {
            "name": "Brewmaster",
            "Enchantress": -0.891
        },
        {
            "name": "Bristleback",
            "Enchantress": 1.889
        },
        {
            "name": "Broodmother",
            "Enchantress": -2.101
        },
        {
            "name": "Centaur Warrunner",
            "Enchantress": 0.024
        },
        {
            "name": "Chaos Knight",
            "Enchantress": 2.205
        },
        {
            "name": "Chen",
            "Enchantress": -0.552
        },
        {
            "name": "Clinkz",
            "Enchantress": -2.216
        },
        {
            "name": "Clockwerk",
            "Enchantress": 0.5
        },
        {
            "name": "Crystal Maiden",
            "Enchantress": 2.388
        },
        {
            "name": "Dark Seer",
            "Enchantress": -3.005
        },
        {
            "name": "Dark Willow",
            "Enchantress": 0.448
        },
        {
            "name": "Dawnbreaker",
            "Enchantress": 0.345
        },
        {
            "name": "Dazzle",
            "Enchantress": 0.606
        },
        {
            "name": "Death Prophet",
            "Enchantress": -2.176
        },
        {
            "name": "Disruptor",
            "Enchantress": -0.058
        },
        {
            "name": "Doom",
            "Enchantress": 0.827
        },
        {
            "name": "Dragon Knight",
            "Enchantress": -0.404
        },
        {
            "name": "Drow Ranger",
            "Enchantress": -0.387
        },
        {
            "name": "Earth Spirit",
            "Enchantress": 0.994
        },
        {
            "name": "Earthshaker",
            "Enchantress": -0.553
        },
        {
            "name": "Elder Titan",
            "Enchantress": -0.617
        },
        {
            "name": "Ember Spirit",
            "Enchantress": -0.488
        },
        {
            "name": "Enigma",
            "Enchantress": -0.858
        },
        {
            "name": "Faceless Void",
            "Enchantress": -2.648
        },
        {
            "name": "Grimstroke",
            "Enchantress": 0.083
        },
        {
            "name": "Gyrocopter",
            "Enchantress": -1.055
        },
        {
            "name": "Hoodwink",
            "Enchantress": 0.186
        },
        {
            "name": "Huskar",
            "Enchantress": -1.291
        },
        {
            "name": "Invoker",
            "Enchantress": -0.458
        },
        {
            "name": "Io",
            "Enchantress": 2.444
        },
        {
            "name": "Jakiro",
            "Enchantress": -0.739
        },
        {
            "name": "Juggernaut",
            "Enchantress": 0.229
        },
        {
            "name": "Keeper of the Light",
            "Enchantress": 1.273
        },
        {
            "name": "Kez",
            "Enchantress": 0.192
        },
        {
            "name": "Kunkka",
            "Enchantress": -0.475
        },
        {
            "name": "Legion Commander",
            "Enchantress": 0.276
        },
        {
            "name": "Leshrac",
            "Enchantress": 0.452
        },
        {
            "name": "Lich",
            "Enchantress": 0.745
        },
        {
            "name": "Lifestealer",
            "Enchantress": -0.503
        },
        {
            "name": "Lina",
            "Enchantress": -0.097
        },
        {
            "name": "Lion",
            "Enchantress": 0.787
        },
        {
            "name": "Lone Druid",
            "Enchantress": -0.464
        },
        {
            "name": "Luna",
            "Enchantress": 0.204
        },
        {
            "name": "Lycan",
            "Enchantress": -3.523
        },
        {
            "name": "Magnus",
            "Enchantress": 0.012
        },
        {
            "name": "Marci",
            "Enchantress": 1.255
        },
        {
            "name": "Mars",
            "Enchantress": 1.765
        },
        {
            "name": "Medusa",
            "Enchantress": 1.951
        },
        {
            "name": "Meepo",
            "Enchantress": 1.999
        },
        {
            "name": "Mirana",
            "Enchantress": -0.557
        },
        {
            "name": "Monkey King",
            "Enchantress": -0.866
        },
        {
            "name": "Morphling",
            "Enchantress": 1.319
        },
        {
            "name": "Muerta",
            "Enchantress": -2.37
        },
        {
            "name": "Naga Siren",
            "Enchantress": -2.135
        },
        {
            "name": "Nature's Prophet",
            "Enchantress": 0.028
        },
        {
            "name": "Necrophos",
            "Enchantress": 0.848
        },
        {
            "name": "Night Stalker",
            "Enchantress": -1.169
        },
        {
            "name": "Nyx Assassin",
            "Enchantress": 0.974
        },
        {
            "name": "Ogre Magi",
            "Enchantress": 1.955
        },
        {
            "name": "Omniknight",
            "Enchantress": -2.099
        },
        {
            "name": "Oracle",
            "Enchantress": -2.255
        },
        {
            "name": "Outworld Destroyer",
            "Enchantress": -0.888
        },
        {
            "name": "Pangolier",
            "Enchantress": 0.007
        },
        {
            "name": "Phantom Assassin",
            "Enchantress": 5.163
        },
        {
            "name": "Phantom Lancer",
            "Enchantress": -0.896
        },
        {
            "name": "Phoenix",
            "Enchantress": -0.653
        },
        {
            "name": "Primal Beast",
            "Enchantress": -0.209
        },
        {
            "name": "Puck",
            "Enchantress": -0.16
        },
        {
            "name": "Pudge",
            "Enchantress": 2.371
        },
        {
            "name": "Pugna",
            "Enchantress": -1.192
        },
        {
            "name": "Queen of Pain",
            "Enchantress": 0.071
        },
        {
            "name": "Razor",
            "Enchantress": -0.849
        },
        {
            "name": "Riki",
            "Enchantress": 0.492
        },
        {
            "name": "Ringmaster",
            "Enchantress": -0.879
        },
        {
            "name": "Rubick",
            "Enchantress": -0.362
        },
        {
            "name": "Sand King",
            "Enchantress": -0.41
        },
        {
            "name": "Shadow Demon",
            "Enchantress": -1.68
        },
        {
            "name": "Shadow Fiend",
            "Enchantress": 0.258
        },
        {
            "name": "Shadow Shaman",
            "Enchantress": -0.297
        },
        {
            "name": "Silencer",
            "Enchantress": -1.43
        },
        {
            "name": "Skywrath Mage",
            "Enchantress": -0.162
        },
        {
            "name": "Slardar",
            "Enchantress": -0.165
        },
        {
            "name": "Slark",
            "Enchantress": 1.325
        },
        {
            "name": "Snapfire",
            "Enchantress": -1.242
        },
        {
            "name": "Sniper",
            "Enchantress": -1.338
        },
        {
            "name": "Spectre",
            "Enchantress": -1.87
        },
        {
            "name": "Spirit Breaker",
            "Enchantress": -0.853
        },
        {
            "name": "Storm Spirit",
            "Enchantress": 0.594
        },
        {
            "name": "Sven",
            "Enchantress": -0.513
        },
        {
            "name": "Techies",
            "Enchantress": 0.933
        },
        {
            "name": "Templar Assassin",
            "Enchantress": -2.373
        },
        {
            "name": "Terrorblade",
            "Enchantress": 1.237
        },
        {
            "name": "Tidehunter",
            "Enchantress": -1.366
        },
        {
            "name": "Timbersaw",
            "Enchantress": -0.46
        },
        {
            "name": "Tinker",
            "Enchantress": -1.64
        },
        {
            "name": "Tiny",
            "Enchantress": -1.195
        },
        {
            "name": "Treant Protector",
            "Enchantress": -0.401
        },
        {
            "name": "Troll Warlord",
            "Enchantress": -1.162
        },
        {
            "name": "Tusk",
            "Enchantress": 0.21
        },
        {
            "name": "Underlord",
            "Enchantress": -2.993
        },
        {
            "name": "Undying",
            "Enchantress": -1.363
        },
        {
            "name": "Ursa",
            "Enchantress": -1.266
        },
        {
            "name": "Vengeful Spirit",
            "Enchantress": -1.239
        },
        {
            "name": "Venomancer",
            "Enchantress": -1.29
        },
        {
            "name": "Viper",
            "Enchantress": -0.717
        },
        {
            "name": "Visage",
            "Enchantress": -0.991
        },
        {
            "name": "Void Spirit",
            "Enchantress": -2.505
        },
        {
            "name": "Warlock",
            "Enchantress": -0.479
        },
        {
            "name": "Weaver",
            "Enchantress": 0.845
        },
        {
            "name": "Windranger",
            "Enchantress": 0.313
        },
        {
            "name": "Winter Wyvern",
            "Enchantress": -1.227
        },
        {
            "name": "Witch Doctor",
            "Enchantress": 0.753
        },
        {
            "name": "Wraith King",
            "Enchantress": -0.168
        },
        {
            "name": "Zeus",
            "Enchantress": -0.57
        }
    ],
    "Grimstroke": [
        {
            "name": "Abaddon",
            "Grimstroke": -0.868
        },
        {
            "name": "Alchemist",
            "Grimstroke": -0.403
        },
        {
            "name": "Ancient Apparition",
            "Grimstroke": -0.822
        },
        {
            "name": "Anti-Mage",
            "Grimstroke": 0.484
        },
        {
            "name": "Arc Warden",
            "Grimstroke": 0.107
        },
        {
            "name": "Axe",
            "Grimstroke": -0.46
        },
        {
            "name": "Bane",
            "Grimstroke": -0.284
        },
        {
            "name": "Batrider",
            "Grimstroke": -1.122
        },
        {
            "name": "Beastmaster",
            "Grimstroke": 1.143
        },
        {
            "name": "Bloodseeker",
            "Grimstroke": 0.018
        },
        {
            "name": "Bounty Hunter",
            "Grimstroke": 0.144
        },
        {
            "name": "Brewmaster",
            "Grimstroke": -0.074
        },
        {
            "name": "Bristleback",
            "Grimstroke": 0.335
        },
        {
            "name": "Broodmother",
            "Grimstroke": -1.577
        },
        {
            "name": "Centaur Warrunner",
            "Grimstroke": 0.27
        },
        {
            "name": "Chaos Knight",
            "Grimstroke": 0.574
        },
        {
            "name": "Chen",
            "Grimstroke": 0.904
        },
        {
            "name": "Clinkz",
            "Grimstroke": 1.338
        },
        {
            "name": "Clockwerk",
            "Grimstroke": -0.711
        },
        {
            "name": "Crystal Maiden",
            "Grimstroke": -0.343
        },
        {
            "name": "Dark Seer",
            "Grimstroke": -0.802
        },
        {
            "name": "Dark Willow",
            "Grimstroke": 1.296
        },
        {
            "name": "Dawnbreaker",
            "Grimstroke": -0.077
        },
        {
            "name": "Dazzle",
            "Grimstroke": -0.429
        },
        {
            "name": "Death Prophet",
            "Grimstroke": 0.392
        },
        {
            "name": "Disruptor",
            "Grimstroke": 0.554
        },
        {
            "name": "Doom",
            "Grimstroke": 0.965
        },
        {
            "name": "Dragon Knight",
            "Grimstroke": 1.004
        },
        {
            "name": "Drow Ranger",
            "Grimstroke": 0.667
        },
        {
            "name": "Earth Spirit",
            "Grimstroke": -0.933
        },
        {
            "name": "Earthshaker",
            "Grimstroke": -1.014
        },
        {
            "name": "Elder Titan",
            "Grimstroke": 0.669
        },
        {
            "name": "Ember Spirit",
            "Grimstroke": -0.957
        },
        {
            "name": "Enchantress",
            "Grimstroke": -0.047
        },
        {
            "name": "Enigma",
            "Grimstroke": -0.972
        },
        {
            "name": "Faceless Void",
            "Grimstroke": -0.183
        },
        {
            "name": "Gyrocopter",
            "Grimstroke": 2.317
        },
        {
            "name": "Hoodwink",
            "Grimstroke": -0.009
        },
        {
            "name": "Huskar",
            "Grimstroke": 0.861
        },
        {
            "name": "Invoker",
            "Grimstroke": 0.276
        },
        {
            "name": "Io",
            "Grimstroke": 0.048
        },
        {
            "name": "Jakiro",
            "Grimstroke": 1.024
        },
        {
            "name": "Juggernaut",
            "Grimstroke": -0.286
        },
        {
            "name": "Keeper of the Light",
            "Grimstroke": 0.29
        },
        {
            "name": "Kez",
            "Grimstroke": 0.33
        },
        {
            "name": "Kunkka",
            "Grimstroke": 0.353
        },
        {
            "name": "Legion Commander",
            "Grimstroke": 0.1
        },
        {
            "name": "Leshrac",
            "Grimstroke": -1.309
        },
        {
            "name": "Lich",
            "Grimstroke": 0.475
        },
        {
            "name": "Lifestealer",
            "Grimstroke": -0.861
        },
        {
            "name": "Lina",
            "Grimstroke": 1.05
        },
        {
            "name": "Lion",
            "Grimstroke": -0.345
        },
        {
            "name": "Lone Druid",
            "Grimstroke": 0.995
        },
        {
            "name": "Luna",
            "Grimstroke": 0.276
        },
        {
            "name": "Lycan",
            "Grimstroke": 1.433
        },
        {
            "name": "Magnus",
            "Grimstroke": -0.934
        },
        {
            "name": "Marci",
            "Grimstroke": -0.436
        },
        {
            "name": "Mars",
            "Grimstroke": 0.0
        },
        {
            "name": "Medusa",
            "Grimstroke": 0.805
        },
        {
            "name": "Meepo",
            "Grimstroke": 1.346
        },
        {
            "name": "Mirana",
            "Grimstroke": 0.315
        },
        {
            "name": "Monkey King",
            "Grimstroke": 0.93
        },
        {
            "name": "Morphling",
            "Grimstroke": -0.393
        },
        {
            "name": "Muerta",
            "Grimstroke": -0.276
        },
        {
            "name": "Naga Siren",
            "Grimstroke": -1.09
        },
        {
            "name": "Nature's Prophet",
            "Grimstroke": 1.499
        },
        {
            "name": "Necrophos",
            "Grimstroke": -2.368
        },
        {
            "name": "Night Stalker",
            "Grimstroke": 0.695
        },
        {
            "name": "Nyx Assassin",
            "Grimstroke": -1.34
        },
        {
            "name": "Ogre Magi",
            "Grimstroke": -0.052
        },
        {
            "name": "Omniknight",
            "Grimstroke": -1.13
        },
        {
            "name": "Oracle",
            "Grimstroke": -0.154
        },
        {
            "name": "Outworld Destroyer",
            "Grimstroke": -0.193
        },
        {
            "name": "Pangolier",
            "Grimstroke": -1.73
        },
        {
            "name": "Phantom Assassin",
            "Grimstroke": -0.016
        },
        {
            "name": "Phantom Lancer",
            "Grimstroke": 0.128
        },
        {
            "name": "Phoenix",
            "Grimstroke": -0.075
        },
        {
            "name": "Primal Beast",
            "Grimstroke": -2.1
        },
        {
            "name": "Puck",
            "Grimstroke": -0.861
        },
        {
            "name": "Pudge",
            "Grimstroke": -0.955
        },
        {
            "name": "Pugna",
            "Grimstroke": 0.124
        },
        {
            "name": "Queen of Pain",
            "Grimstroke": -0.855
        },
        {
            "name": "Razor",
            "Grimstroke": 1.78
        },
        {
            "name": "Riki",
            "Grimstroke": -0.494
        },
        {
            "name": "Ringmaster",
            "Grimstroke": 0.664
        },
        {
            "name": "Rubick",
            "Grimstroke": -0.084
        },
        {
            "name": "Sand King",
            "Grimstroke": 0.071
        },
        {
            "name": "Shadow Demon",
            "Grimstroke": 1.501
        },
        {
            "name": "Shadow Fiend",
            "Grimstroke": 0.375
        },
        {
            "name": "Shadow Shaman",
            "Grimstroke": 0.294
        },
        {
            "name": "Silencer",
            "Grimstroke": 1.054
        },
        {
            "name": "Skywrath Mage",
            "Grimstroke": 0.175
        },
        {
            "name": "Slardar",
            "Grimstroke": 0.193
        },
        {
            "name": "Slark",
            "Grimstroke": -3.777
        },
        {
            "name": "Snapfire",
            "Grimstroke": 0.802
        },
        {
            "name": "Sniper",
            "Grimstroke": 0.853
        },
        {
            "name": "Spectre",
            "Grimstroke": 1.514
        },
        {
            "name": "Spirit Breaker",
            "Grimstroke": 0.51
        },
        {
            "name": "Storm Spirit",
            "Grimstroke": -1.573
        },
        {
            "name": "Sven",
            "Grimstroke": 0.345
        },
        {
            "name": "Techies",
            "Grimstroke": -0.769
        },
        {
            "name": "Templar Assassin",
            "Grimstroke": 0.791
        },
        {
            "name": "Terrorblade",
            "Grimstroke": 0.024
        },
        {
            "name": "Tidehunter",
            "Grimstroke": -1.311
        },
        {
            "name": "Timbersaw",
            "Grimstroke": -3.204
        },
        {
            "name": "Tinker",
            "Grimstroke": -0.421
        },
        {
            "name": "Tiny",
            "Grimstroke": -0.462
        },
        {
            "name": "Treant Protector",
            "Grimstroke": -0.491
        },
        {
            "name": "Troll Warlord",
            "Grimstroke": -0.005
        },
        {
            "name": "Tusk",
            "Grimstroke": -0.439
        },
        {
            "name": "Underlord",
            "Grimstroke": 0.443
        },
        {
            "name": "Undying",
            "Grimstroke": -0.46
        },
        {
            "name": "Ursa",
            "Grimstroke": -0.264
        },
        {
            "name": "Vengeful Spirit",
            "Grimstroke": 0.263
        },
        {
            "name": "Venomancer",
            "Grimstroke": 0.256
        },
        {
            "name": "Viper",
            "Grimstroke": 0.277
        },
        {
            "name": "Visage",
            "Grimstroke": 1.276
        },
        {
            "name": "Void Spirit",
            "Grimstroke": 0.068
        },
        {
            "name": "Warlock",
            "Grimstroke": 0.681
        },
        {
            "name": "Weaver",
            "Grimstroke": 0.273
        },
        {
            "name": "Windranger",
            "Grimstroke": 0.789
        },
        {
            "name": "Winter Wyvern",
            "Grimstroke": -0.082
        },
        {
            "name": "Witch Doctor",
            "Grimstroke": -0.152
        },
        {
            "name": "Wraith King",
            "Grimstroke": 0.829
        },
        {
            "name": "Zeus",
            "Grimstroke": 0.32
        }
    ],
    "Jakiro": [
        {
            "name": "Abaddon",
            "Jakiro": 1.013
        },
        {
            "name": "Alchemist",
            "Jakiro": 0.106
        },
        {
            "name": "Ancient Apparition",
            "Jakiro": 0.235
        },
        {
            "name": "Anti-Mage",
            "Jakiro": -1.377
        },
        {
            "name": "Arc Warden",
            "Jakiro": -1.614
        },
        {
            "name": "Axe",
            "Jakiro": -0.9
        },
        {
            "name": "Bane",
            "Jakiro": 0.014
        },
        {
            "name": "Batrider",
            "Jakiro": -0.468
        },
        {
            "name": "Beastmaster",
            "Jakiro": -1.646
        },
        {
            "name": "Bloodseeker",
            "Jakiro": 0.094
        },
        {
            "name": "Bounty Hunter",
            "Jakiro": 0.327
        },
        {
            "name": "Brewmaster",
            "Jakiro": -1.142
        },
        {
            "name": "Bristleback",
            "Jakiro": 0.719
        },
        {
            "name": "Broodmother",
            "Jakiro": -1.79
        },
        {
            "name": "Centaur Warrunner",
            "Jakiro": 0.136
        },
        {
            "name": "Chaos Knight",
            "Jakiro": -1.952
        },
        {
            "name": "Chen",
            "Jakiro": -2.269
        },
        {
            "name": "Clinkz",
            "Jakiro": 0.319
        },
        {
            "name": "Clockwerk",
            "Jakiro": -0.905
        },
        {
            "name": "Crystal Maiden",
            "Jakiro": -0.224
        },
        {
            "name": "Dark Seer",
            "Jakiro": -0.639
        },
        {
            "name": "Dark Willow",
            "Jakiro": -0.377
        },
        {
            "name": "Dawnbreaker",
            "Jakiro": -1.203
        },
        {
            "name": "Dazzle",
            "Jakiro": -0.344
        },
        {
            "name": "Death Prophet",
            "Jakiro": 0.105
        },
        {
            "name": "Disruptor",
            "Jakiro": 0.053
        },
        {
            "name": "Doom",
            "Jakiro": 0.339
        },
        {
            "name": "Dragon Knight",
            "Jakiro": -0.155
        },
        {
            "name": "Drow Ranger",
            "Jakiro": 0.702
        },
        {
            "name": "Earth Spirit",
            "Jakiro": 0.094
        },
        {
            "name": "Earthshaker",
            "Jakiro": -0.916
        },
        {
            "name": "Elder Titan",
            "Jakiro": -0.129
        },
        {
            "name": "Ember Spirit",
            "Jakiro": 0.347
        },
        {
            "name": "Enchantress",
            "Jakiro": 0.862
        },
        {
            "name": "Enigma",
            "Jakiro": -1.076
        },
        {
            "name": "Faceless Void",
            "Jakiro": 0.636
        },
        {
            "name": "Grimstroke",
            "Jakiro": -0.891
        },
        {
            "name": "Gyrocopter",
            "Jakiro": 0.34
        },
        {
            "name": "Hoodwink",
            "Jakiro": 0.142
        },
        {
            "name": "Huskar",
            "Jakiro": 0.366
        },
        {
            "name": "Invoker",
            "Jakiro": 0.367
        },
        {
            "name": "Io",
            "Jakiro": -0.792
        },
        {
            "name": "Juggernaut",
            "Jakiro": 1.402
        },
        {
            "name": "Keeper of the Light",
            "Jakiro": 1.291
        },
        {
            "name": "Kez",
            "Jakiro": 0.177
        },
        {
            "name": "Kunkka",
            "Jakiro": -0.205
        },
        {
            "name": "Legion Commander",
            "Jakiro": 0.965
        },
        {
            "name": "Leshrac",
            "Jakiro": 0.21
        },
        {
            "name": "Lich",
            "Jakiro": -0.56
        },
        {
            "name": "Lifestealer",
            "Jakiro": 0.855
        },
        {
            "name": "Lina",
            "Jakiro": 0.598
        },
        {
            "name": "Lion",
            "Jakiro": 0.062
        },
        {
            "name": "Lone Druid",
            "Jakiro": -2.585
        },
        {
            "name": "Luna",
            "Jakiro": -0.19
        },
        {
            "name": "Lycan",
            "Jakiro": -0.405
        },
        {
            "name": "Magnus",
            "Jakiro": 0.028
        },
        {
            "name": "Marci",
            "Jakiro": -1.029
        },
        {
            "name": "Mars",
            "Jakiro": 0.103
        },
        {
            "name": "Medusa",
            "Jakiro": -0.949
        },
        {
            "name": "Meepo",
            "Jakiro": -4.25
        },
        {
            "name": "Mirana",
            "Jakiro": 0.353
        },
        {
            "name": "Monkey King",
            "Jakiro": -0.825
        },
        {
            "name": "Morphling",
            "Jakiro": 1.187
        },
        {
            "name": "Muerta",
            "Jakiro": -0.588
        },
        {
            "name": "Naga Siren",
            "Jakiro": -3.561
        },
        {
            "name": "Nature's Prophet",
            "Jakiro": 0.564
        },
        {
            "name": "Necrophos",
            "Jakiro": 0.05
        },
        {
            "name": "Night Stalker",
            "Jakiro": 0.099
        },
        {
            "name": "Nyx Assassin",
            "Jakiro": -0.103
        },
        {
            "name": "Ogre Magi",
            "Jakiro": 0.093
        },
        {
            "name": "Omniknight",
            "Jakiro": 0.04
        },
        {
            "name": "Oracle",
            "Jakiro": 0.922
        },
        {
            "name": "Outworld Destroyer",
            "Jakiro": 0.863
        },
        {
            "name": "Pangolier",
            "Jakiro": 1.383
        },
        {
            "name": "Phantom Assassin",
            "Jakiro": -0.466
        },
        {
            "name": "Phantom Lancer",
            "Jakiro": -1.659
        },
        {
            "name": "Phoenix",
            "Jakiro": -1.006
        },
        {
            "name": "Primal Beast",
            "Jakiro": -0.613
        },
        {
            "name": "Puck",
            "Jakiro": 0.091
        },
        {
            "name": "Pudge",
            "Jakiro": 0.718
        },
        {
            "name": "Pugna",
            "Jakiro": 0.763
        },
        {
            "name": "Queen of Pain",
            "Jakiro": 0.43
        },
        {
            "name": "Razor",
            "Jakiro": 0.152
        },
        {
            "name": "Riki",
            "Jakiro": -1.958
        },
        {
            "name": "Ringmaster",
            "Jakiro": 1.056
        },
        {
            "name": "Rubick",
            "Jakiro": 3.796
        },
        {
            "name": "Sand King",
            "Jakiro": -0.631
        },
        {
            "name": "Shadow Demon",
            "Jakiro": -0.539
        },
        {
            "name": "Shadow Fiend",
            "Jakiro": -0.282
        },
        {
            "name": "Shadow Shaman",
            "Jakiro": -0.556
        },
        {
            "name": "Silencer",
            "Jakiro": 0.082
        },
        {
            "name": "Skywrath Mage",
            "Jakiro": 0.156
        },
        {
            "name": "Slardar",
            "Jakiro": -0.74
        },
        {
            "name": "Slark",
            "Jakiro": 0.525
        },
        {
            "name": "Snapfire",
            "Jakiro": 0.824
        },
        {
            "name": "Sniper",
            "Jakiro": 0.919
        },
        {
            "name": "Spectre",
            "Jakiro": -1.397
        },
        {
            "name": "Spirit Breaker",
            "Jakiro": -1.453
        },
        {
            "name": "Storm Spirit",
            "Jakiro": 0.44
        },
        {
            "name": "Sven",
            "Jakiro": 0.262
        },
        {
            "name": "Techies",
            "Jakiro": -0.695
        },
        {
            "name": "Templar Assassin",
            "Jakiro": -0.319
        },
        {
            "name": "Terrorblade",
            "Jakiro": -0.576
        },
        {
            "name": "Tidehunter",
            "Jakiro": 0.901
        },
        {
            "name": "Timbersaw",
            "Jakiro": 0.047
        },
        {
            "name": "Tinker",
            "Jakiro": 0.645
        },
        {
            "name": "Tiny",
            "Jakiro": -0.163
        },
        {
            "name": "Treant Protector",
            "Jakiro": -1.023
        },
        {
            "name": "Troll Warlord",
            "Jakiro": -1.543
        },
        {
            "name": "Tusk",
            "Jakiro": -0.481
        },
        {
            "name": "Underlord",
            "Jakiro": 0.093
        },
        {
            "name": "Undying",
            "Jakiro": -0.101
        },
        {
            "name": "Ursa",
            "Jakiro": 0.317
        },
        {
            "name": "Vengeful Spirit",
            "Jakiro": -0.922
        },
        {
            "name": "Venomancer",
            "Jakiro": 0.626
        },
        {
            "name": "Viper",
            "Jakiro": -0.135
        },
        {
            "name": "Visage",
            "Jakiro": -0.795
        },
        {
            "name": "Void Spirit",
            "Jakiro": -0.288
        },
        {
            "name": "Warlock",
            "Jakiro": -1.282
        },
        {
            "name": "Weaver",
            "Jakiro": 1.223
        },
        {
            "name": "Windranger",
            "Jakiro": 0.868
        },
        {
            "name": "Winter Wyvern",
            "Jakiro": 0.028
        },
        {
            "name": "Witch Doctor",
            "Jakiro": -0.391
        },
        {
            "name": "Wraith King",
            "Jakiro": -1.968
        },
        {
            "name": "Zeus",
            "Jakiro": 0.821
        }
    ],
    "Keeper of the Light": [
        {
            "name": "Abaddon",
            "Keeper of the Light": -1.581
        },
        {
            "name": "Alchemist",
            "Keeper of the Light": -0.311
        },
        {
            "name": "Ancient Apparition",
            "Keeper of the Light": 0.605
        },
        {
            "name": "Anti-Mage",
            "Keeper of the Light": 1.323
        },
        {
            "name": "Arc Warden",
            "Keeper of the Light": 1.666
        },
        {
            "name": "Axe",
            "Keeper of the Light": -0.862
        },
        {
            "name": "Bane",
            "Keeper of the Light": 1.015
        },
        {
            "name": "Batrider",
            "Keeper of the Light": 1.61
        },
        {
            "name": "Beastmaster",
            "Keeper of the Light": -3.464
        },
        {
            "name": "Bloodseeker",
            "Keeper of the Light": -2.22
        },
        {
            "name": "Bounty Hunter",
            "Keeper of the Light": 0.316
        },
        {
            "name": "Brewmaster",
            "Keeper of the Light": -1.25
        },
        {
            "name": "Bristleback",
            "Keeper of the Light": -0.356
        },
        {
            "name": "Broodmother",
            "Keeper of the Light": 0.012
        },
        {
            "name": "Centaur Warrunner",
            "Keeper of the Light": 0.053
        },
        {
            "name": "Chaos Knight",
            "Keeper of the Light": 1.088
        },
        {
            "name": "Chen",
            "Keeper of the Light": 0.347
        },
        {
            "name": "Clinkz",
            "Keeper of the Light": 1.706
        },
        {
            "name": "Clockwerk",
            "Keeper of the Light": -1.204
        },
        {
            "name": "Crystal Maiden",
            "Keeper of the Light": 0.899
        },
        {
            "name": "Dark Seer",
            "Keeper of the Light": 0.537
        },
        {
            "name": "Dark Willow",
            "Keeper of the Light": -0.077
        },
        {
            "name": "Dawnbreaker",
            "Keeper of the Light": -1.148
        },
        {
            "name": "Dazzle",
            "Keeper of the Light": -0.375
        },
        {
            "name": "Death Prophet",
            "Keeper of the Light": -1.426
        },
        {
            "name": "Disruptor",
            "Keeper of the Light": -0.961
        },
        {
            "name": "Doom",
            "Keeper of the Light": -1.208
        },
        {
            "name": "Dragon Knight",
            "Keeper of the Light": -1.378
        },
        {
            "name": "Drow Ranger",
            "Keeper of the Light": -0.425
        },
        {
            "name": "Earth Spirit",
            "Keeper of the Light": 0.169
        },
        {
            "name": "Earthshaker",
            "Keeper of the Light": 0.42
        },
        {
            "name": "Elder Titan",
            "Keeper of the Light": -0.982
        },
        {
            "name": "Ember Spirit",
            "Keeper of the Light": -0.358
        },
        {
            "name": "Enchantress",
            "Keeper of the Light": -1.348
        },
        {
            "name": "Enigma",
            "Keeper of the Light": 0.135
        },
        {
            "name": "Faceless Void",
            "Keeper of the Light": 2.394
        },
        {
            "name": "Grimstroke",
            "Keeper of the Light": -0.344
        },
        {
            "name": "Gyrocopter",
            "Keeper of the Light": -2.167
        },
        {
            "name": "Hoodwink",
            "Keeper of the Light": 0.051
        },
        {
            "name": "Huskar",
            "Keeper of the Light": 2.953
        },
        {
            "name": "Invoker",
            "Keeper of the Light": -1.136
        },
        {
            "name": "Io",
            "Keeper of the Light": 0.899
        },
        {
            "name": "Jakiro",
            "Keeper of the Light": -1.137
        },
        {
            "name": "Juggernaut",
            "Keeper of the Light": 0.799
        },
        {
            "name": "Kez",
            "Keeper of the Light": 0.74
        },
        {
            "name": "Kunkka",
            "Keeper of the Light": -0.732
        },
        {
            "name": "Legion Commander",
            "Keeper of the Light": 1.574
        },
        {
            "name": "Leshrac",
            "Keeper of the Light": -0.879
        },
        {
            "name": "Lich",
            "Keeper of the Light": -0.384
        },
        {
            "name": "Lifestealer",
            "Keeper of the Light": 0.742
        },
        {
            "name": "Lina",
            "Keeper of the Light": -0.884
        },
        {
            "name": "Lion",
            "Keeper of the Light": 0.287
        },
        {
            "name": "Lone Druid",
            "Keeper of the Light": -0.456
        },
        {
            "name": "Luna",
            "Keeper of the Light": 0.635
        },
        {
            "name": "Lycan",
            "Keeper of the Light": 1.01
        },
        {
            "name": "Magnus",
            "Keeper of the Light": 0.457
        },
        {
            "name": "Marci",
            "Keeper of the Light": 0.116
        },
        {
            "name": "Mars",
            "Keeper of the Light": -0.35
        },
        {
            "name": "Medusa",
            "Keeper of the Light": -0.071
        },
        {
            "name": "Meepo",
            "Keeper of the Light": 0.615
        },
        {
            "name": "Mirana",
            "Keeper of the Light": -0.179
        },
        {
            "name": "Monkey King",
            "Keeper of the Light": -2.322
        },
        {
            "name": "Morphling",
            "Keeper of the Light": -1.028
        },
        {
            "name": "Muerta",
            "Keeper of the Light": -1.577
        },
        {
            "name": "Naga Siren",
            "Keeper of the Light": 0.938
        },
        {
            "name": "Nature's Prophet",
            "Keeper of the Light": 1.162
        },
        {
            "name": "Necrophos",
            "Keeper of the Light": -1.412
        },
        {
            "name": "Night Stalker",
            "Keeper of the Light": -1.239
        },
        {
            "name": "Nyx Assassin",
            "Keeper of the Light": 1.346
        },
        {
            "name": "Ogre Magi",
            "Keeper of the Light": 0.842
        },
        {
            "name": "Omniknight",
            "Keeper of the Light": -0.289
        },
        {
            "name": "Oracle",
            "Keeper of the Light": -0.373
        },
        {
            "name": "Outworld Destroyer",
            "Keeper of the Light": -1.013
        },
        {
            "name": "Pangolier",
            "Keeper of the Light": -0.382
        },
        {
            "name": "Phantom Assassin",
            "Keeper of the Light": 2.048
        },
        {
            "name": "Phantom Lancer",
            "Keeper of the Light": -0.731
        },
        {
            "name": "Phoenix",
            "Keeper of the Light": -2.366
        },
        {
            "name": "Primal Beast",
            "Keeper of the Light": -0.184
        },
        {
            "name": "Puck",
            "Keeper of the Light": -0.521
        },
        {
            "name": "Pudge",
            "Keeper of the Light": 0.359
        },
        {
            "name": "Pugna",
            "Keeper of the Light": -0.918
        },
        {
            "name": "Queen of Pain",
            "Keeper of the Light": 1.24
        },
        {
            "name": "Razor",
            "Keeper of the Light": -0.244
        },
        {
            "name": "Riki",
            "Keeper of the Light": 0.275
        },
        {
            "name": "Ringmaster",
            "Keeper of the Light": -1.809
        },
        {
            "name": "Rubick",
            "Keeper of the Light": -1.209
        },
        {
            "name": "Sand King",
            "Keeper of the Light": 0.394
        },
        {
            "name": "Shadow Demon",
            "Keeper of the Light": -1.248
        },
        {
            "name": "Shadow Fiend",
            "Keeper of the Light": 0.014
        },
        {
            "name": "Shadow Shaman",
            "Keeper of the Light": 0.882
        },
        {
            "name": "Silencer",
            "Keeper of the Light": -0.332
        },
        {
            "name": "Skywrath Mage",
            "Keeper of the Light": 1.468
        },
        {
            "name": "Slardar",
            "Keeper of the Light": 1.409
        },
        {
            "name": "Slark",
            "Keeper of the Light": 1.245
        },
        {
            "name": "Snapfire",
            "Keeper of the Light": 0.401
        },
        {
            "name": "Sniper",
            "Keeper of the Light": 0.764
        },
        {
            "name": "Spectre",
            "Keeper of the Light": -0.957
        },
        {
            "name": "Spirit Breaker",
            "Keeper of the Light": 0.993
        },
        {
            "name": "Storm Spirit",
            "Keeper of the Light": 1.922
        },
        {
            "name": "Sven",
            "Keeper of the Light": 1.568
        },
        {
            "name": "Techies",
            "Keeper of the Light": -0.507
        },
        {
            "name": "Templar Assassin",
            "Keeper of the Light": 0.208
        },
        {
            "name": "Terrorblade",
            "Keeper of the Light": -0.946
        },
        {
            "name": "Tidehunter",
            "Keeper of the Light": -0.174
        },
        {
            "name": "Timbersaw",
            "Keeper of the Light": -0.322
        },
        {
            "name": "Tinker",
            "Keeper of the Light": -0.158
        },
        {
            "name": "Tiny",
            "Keeper of the Light": -1.03
        },
        {
            "name": "Treant Protector",
            "Keeper of the Light": -0.383
        },
        {
            "name": "Troll Warlord",
            "Keeper of the Light": -0.257
        },
        {
            "name": "Tusk",
            "Keeper of the Light": 0.083
        },
        {
            "name": "Underlord",
            "Keeper of the Light": -0.697
        },
        {
            "name": "Undying",
            "Keeper of the Light": -0.707
        },
        {
            "name": "Ursa",
            "Keeper of the Light": 0.499
        },
        {
            "name": "Vengeful Spirit",
            "Keeper of the Light": -0.294
        },
        {
            "name": "Venomancer",
            "Keeper of the Light": 0.026
        },
        {
            "name": "Viper",
            "Keeper of the Light": -0.161
        },
        {
            "name": "Visage",
            "Keeper of the Light": -0.528
        },
        {
            "name": "Void Spirit",
            "Keeper of the Light": 1.889
        },
        {
            "name": "Warlock",
            "Keeper of the Light": -0.15
        },
        {
            "name": "Weaver",
            "Keeper of the Light": 2.373
        },
        {
            "name": "Windranger",
            "Keeper of the Light": 0.952
        },
        {
            "name": "Winter Wyvern",
            "Keeper of the Light": -1.899
        },
        {
            "name": "Witch Doctor",
            "Keeper of the Light": 0.279
        },
        {
            "name": "Wraith King",
            "Keeper of the Light": -1.185
        },
        {
            "name": "Zeus",
            "Keeper of the Light": -0.419
        }
    ],
    "Leshrac": [
        {
            "name": "Abaddon",
            "Leshrac": -0.116
        },
        {
            "name": "Alchemist",
            "Leshrac": -1.162
        },
        {
            "name": "Ancient Apparition",
            "Leshrac": 2.578
        },
        {
            "name": "Anti-Mage",
            "Leshrac": 1.196
        },
        {
            "name": "Arc Warden",
            "Leshrac": -0.114
        },
        {
            "name": "Axe",
            "Leshrac": -2.541
        },
        {
            "name": "Bane",
            "Leshrac": -0.175
        },
        {
            "name": "Batrider",
            "Leshrac": -0.922
        },
        {
            "name": "Beastmaster",
            "Leshrac": -0.337
        },
        {
            "name": "Bloodseeker",
            "Leshrac": 0.925
        },
        {
            "name": "Bounty Hunter",
            "Leshrac": 0.573
        },
        {
            "name": "Brewmaster",
            "Leshrac": 0.384
        },
        {
            "name": "Bristleback",
            "Leshrac": 2.48
        },
        {
            "name": "Broodmother",
            "Leshrac": 0.745
        },
        {
            "name": "Centaur Warrunner",
            "Leshrac": -0.587
        },
        {
            "name": "Chaos Knight",
            "Leshrac": -1.267
        },
        {
            "name": "Chen",
            "Leshrac": -6.016
        },
        {
            "name": "Clinkz",
            "Leshrac": 2.162
        },
        {
            "name": "Clockwerk",
            "Leshrac": 0.45
        },
        {
            "name": "Crystal Maiden",
            "Leshrac": -0.343
        },
        {
            "name": "Dark Seer",
            "Leshrac": -4.546
        },
        {
            "name": "Dark Willow",
            "Leshrac": -0.759
        },
        {
            "name": "Dawnbreaker",
            "Leshrac": 0.928
        },
        {
            "name": "Dazzle",
            "Leshrac": -0.166
        },
        {
            "name": "Death Prophet",
            "Leshrac": 1.861
        },
        {
            "name": "Disruptor",
            "Leshrac": 0.781
        },
        {
            "name": "Doom",
            "Leshrac": 0.821
        },
        {
            "name": "Dragon Knight",
            "Leshrac": -0.102
        },
        {
            "name": "Drow Ranger",
            "Leshrac": 2.417
        },
        {
            "name": "Earth Spirit",
            "Leshrac": -1.268
        },
        {
            "name": "Earthshaker",
            "Leshrac": -0.61
        },
        {
            "name": "Elder Titan",
            "Leshrac": 0.483
        },
        {
            "name": "Ember Spirit",
            "Leshrac": 1.597
        },
        {
            "name": "Enchantress",
            "Leshrac": -0.48
        },
        {
            "name": "Enigma",
            "Leshrac": 0.07
        },
        {
            "name": "Faceless Void",
            "Leshrac": -0.172
        },
        {
            "name": "Grimstroke",
            "Leshrac": 1.278
        },
        {
            "name": "Gyrocopter",
            "Leshrac": 0.881
        },
        {
            "name": "Hoodwink",
            "Leshrac": 1.327
        },
        {
            "name": "Huskar",
            "Leshrac": 0.324
        },
        {
            "name": "Invoker",
            "Leshrac": 0.695
        },
        {
            "name": "Io",
            "Leshrac": 0.323
        },
        {
            "name": "Jakiro",
            "Leshrac": -0.139
        },
        {
            "name": "Juggernaut",
            "Leshrac": -0.167
        },
        {
            "name": "Keeper of the Light",
            "Leshrac": 0.91
        },
        {
            "name": "Kez",
            "Leshrac": 0.001
        },
        {
            "name": "Kunkka",
            "Leshrac": 0.148
        },
        {
            "name": "Legion Commander",
            "Leshrac": 0.647
        },
        {
            "name": "Lich",
            "Leshrac": -0.052
        },
        {
            "name": "Lifestealer",
            "Leshrac": 0.941
        },
        {
            "name": "Lina",
            "Leshrac": 0.852
        },
        {
            "name": "Lion",
            "Leshrac": 0.721
        },
        {
            "name": "Lone Druid",
            "Leshrac": -2.038
        },
        {
            "name": "Luna",
            "Leshrac": 1.507
        },
        {
            "name": "Lycan",
            "Leshrac": 0.174
        },
        {
            "name": "Magnus",
            "Leshrac": -0.424
        },
        {
            "name": "Marci",
            "Leshrac": -0.384
        },
        {
            "name": "Mars",
            "Leshrac": -0.168
        },
        {
            "name": "Medusa",
            "Leshrac": 3.156
        },
        {
            "name": "Meepo",
            "Leshrac": -6.916
        },
        {
            "name": "Mirana",
            "Leshrac": -0.024
        },
        {
            "name": "Monkey King",
            "Leshrac": -3.51
        },
        {
            "name": "Morphling",
            "Leshrac": -0.131
        },
        {
            "name": "Muerta",
            "Leshrac": -2.573
        },
        {
            "name": "Naga Siren",
            "Leshrac": -3.133
        },
        {
            "name": "Nature's Prophet",
            "Leshrac": -3.143
        },
        {
            "name": "Necrophos",
            "Leshrac": -3.091
        },
        {
            "name": "Night Stalker",
            "Leshrac": -0.385
        },
        {
            "name": "Nyx Assassin",
            "Leshrac": 0.258
        },
        {
            "name": "Ogre Magi",
            "Leshrac": 1.026
        },
        {
            "name": "Omniknight",
            "Leshrac": -1.781
        },
        {
            "name": "Oracle",
            "Leshrac": 0.014
        },
        {
            "name": "Outworld Destroyer",
            "Leshrac": 1.783
        },
        {
            "name": "Pangolier",
            "Leshrac": -1.281
        },
        {
            "name": "Phantom Assassin",
            "Leshrac": -1.093
        },
        {
            "name": "Phantom Lancer",
            "Leshrac": -3.697
        },
        {
            "name": "Phoenix",
            "Leshrac": -0.392
        },
        {
            "name": "Primal Beast",
            "Leshrac": -0.007
        },
        {
            "name": "Puck",
            "Leshrac": -0.164
        },
        {
            "name": "Pudge",
            "Leshrac": 0.119
        },
        {
            "name": "Pugna",
            "Leshrac": -0.154
        },
        {
            "name": "Queen of Pain",
            "Leshrac": 1.643
        },
        {
            "name": "Razor",
            "Leshrac": 0.057
        },
        {
            "name": "Riki",
            "Leshrac": -1.294
        },
        {
            "name": "Ringmaster",
            "Leshrac": -0.772
        },
        {
            "name": "Rubick",
            "Leshrac": -0.054
        },
        {
            "name": "Sand King",
            "Leshrac": -2.251
        },
        {
            "name": "Shadow Demon",
            "Leshrac": -0.594
        },
        {
            "name": "Shadow Fiend",
            "Leshrac": 0.851
        },
        {
            "name": "Shadow Shaman",
            "Leshrac": -0.551
        },
        {
            "name": "Silencer",
            "Leshrac": 2.402
        },
        {
            "name": "Skywrath Mage",
            "Leshrac": 1.701
        },
        {
            "name": "Slardar",
            "Leshrac": 0.425
        },
        {
            "name": "Slark",
            "Leshrac": -3.345
        },
        {
            "name": "Snapfire",
            "Leshrac": 0.417
        },
        {
            "name": "Sniper",
            "Leshrac": 1.493
        },
        {
            "name": "Spectre",
            "Leshrac": 0.061
        },
        {
            "name": "Spirit Breaker",
            "Leshrac": -0.366
        },
        {
            "name": "Storm Spirit",
            "Leshrac": -0.291
        },
        {
            "name": "Sven",
            "Leshrac": 1.361
        },
        {
            "name": "Techies",
            "Leshrac": -0.896
        },
        {
            "name": "Templar Assassin",
            "Leshrac": -4.033
        },
        {
            "name": "Terrorblade",
            "Leshrac": -0.73
        },
        {
            "name": "Tidehunter",
            "Leshrac": -2.929
        },
        {
            "name": "Timbersaw",
            "Leshrac": -1.683
        },
        {
            "name": "Tinker",
            "Leshrac": -2.246
        },
        {
            "name": "Tiny",
            "Leshrac": -1.446
        },
        {
            "name": "Treant Protector",
            "Leshrac": -2.471
        },
        {
            "name": "Troll Warlord",
            "Leshrac": -0.883
        },
        {
            "name": "Tusk",
            "Leshrac": -0.979
        },
        {
            "name": "Underlord",
            "Leshrac": -0.258
        },
        {
            "name": "Undying",
            "Leshrac": 3.328
        },
        {
            "name": "Ursa",
            "Leshrac": 1.902
        },
        {
            "name": "Vengeful Spirit",
            "Leshrac": 0.369
        },
        {
            "name": "Venomancer",
            "Leshrac": 1.177
        },
        {
            "name": "Viper",
            "Leshrac": 0.248
        },
        {
            "name": "Visage",
            "Leshrac": -0.185
        },
        {
            "name": "Void Spirit",
            "Leshrac": -0.021
        },
        {
            "name": "Warlock",
            "Leshrac": 0.25
        },
        {
            "name": "Weaver",
            "Leshrac": 0.307
        },
        {
            "name": "Windranger",
            "Leshrac": 0.331
        },
        {
            "name": "Winter Wyvern",
            "Leshrac": -2.618
        },
        {
            "name": "Witch Doctor",
            "Leshrac": 0.946
        },
        {
            "name": "Wraith King",
            "Leshrac": 0.455
        },
        {
            "name": "Zeus",
            "Leshrac": 1.288
        }
    ],
    "Lich": [
        {
            "name": "Abaddon",
            "Lich": -0.467
        },
        {
            "name": "Alchemist",
            "Lich": -0.047
        },
        {
            "name": "Ancient Apparition",
            "Lich": 0.544
        },
        {
            "name": "Anti-Mage",
            "Lich": 1.697
        },
        {
            "name": "Arc Warden",
            "Lich": -1.038
        },
        {
            "name": "Axe",
            "Lich": -0.002
        },
        {
            "name": "Bane",
            "Lich": 1.396
        },
        {
            "name": "Batrider",
            "Lich": 1.311
        },
        {
            "name": "Beastmaster",
            "Lich": 0.728
        },
        {
            "name": "Bloodseeker",
            "Lich": 0.955
        },
        {
            "name": "Bounty Hunter",
            "Lich": 1.356
        },
        {
            "name": "Brewmaster",
            "Lich": 2.047
        },
        {
            "name": "Bristleback",
            "Lich": 1.983
        },
        {
            "name": "Broodmother",
            "Lich": 0.27
        },
        {
            "name": "Centaur Warrunner",
            "Lich": 1.381
        },
        {
            "name": "Chaos Knight",
            "Lich": -1.556
        },
        {
            "name": "Chen",
            "Lich": 3.582
        },
        {
            "name": "Clinkz",
            "Lich": 1.323
        },
        {
            "name": "Clockwerk",
            "Lich": -0.237
        },
        {
            "name": "Crystal Maiden",
            "Lich": 0.257
        },
        {
            "name": "Dark Seer",
            "Lich": 0.886
        },
        {
            "name": "Dark Willow",
            "Lich": 1.208
        },
        {
            "name": "Dawnbreaker",
            "Lich": -0.673
        },
        {
            "name": "Dazzle",
            "Lich": 1.151
        },
        {
            "name": "Death Prophet",
            "Lich": 1.323
        },
        {
            "name": "Disruptor",
            "Lich": 0.701
        },
        {
            "name": "Doom",
            "Lich": 1.169
        },
        {
            "name": "Dragon Knight",
            "Lich": -0.473
        },
        {
            "name": "Drow Ranger",
            "Lich": 1.121
        },
        {
            "name": "Earth Spirit",
            "Lich": 1.475
        },
        {
            "name": "Earthshaker",
            "Lich": 0.461
        },
        {
            "name": "Elder Titan",
            "Lich": 1.119
        },
        {
            "name": "Ember Spirit",
            "Lich": 1.776
        },
        {
            "name": "Enchantress",
            "Lich": 0.511
        },
        {
            "name": "Enigma",
            "Lich": 1.489
        },
        {
            "name": "Faceless Void",
            "Lich": 1.438
        },
        {
            "name": "Grimstroke",
            "Lich": 0.42
        },
        {
            "name": "Gyrocopter",
            "Lich": 1.193
        },
        {
            "name": "Hoodwink",
            "Lich": 1.398
        },
        {
            "name": "Huskar",
            "Lich": 0.639
        },
        {
            "name": "Invoker",
            "Lich": 1.317
        },
        {
            "name": "Io",
            "Lich": -0.622
        },
        {
            "name": "Jakiro",
            "Lich": 0.899
        },
        {
            "name": "Juggernaut",
            "Lich": 0.523
        },
        {
            "name": "Keeper of the Light",
            "Lich": 1.51
        },
        {
            "name": "Kez",
            "Lich": 1.272
        },
        {
            "name": "Kunkka",
            "Lich": 0.575
        },
        {
            "name": "Legion Commander",
            "Lich": -0.836
        },
        {
            "name": "Leshrac",
            "Lich": 0.975
        },
        {
            "name": "Lifestealer",
            "Lich": -0.684
        },
        {
            "name": "Lina",
            "Lich": 1.123
        },
        {
            "name": "Lion",
            "Lich": 0.418
        },
        {
            "name": "Lone Druid",
            "Lich": -1.255
        },
        {
            "name": "Luna",
            "Lich": -0.713
        },
        {
            "name": "Lycan",
            "Lich": 1.334
        },
        {
            "name": "Magnus",
            "Lich": 0.506
        },
        {
            "name": "Marci",
            "Lich": -0.353
        },
        {
            "name": "Mars",
            "Lich": 0.188
        },
        {
            "name": "Medusa",
            "Lich": -0.56
        },
        {
            "name": "Meepo",
            "Lich": 0.433
        },
        {
            "name": "Mirana",
            "Lich": 1.549
        },
        {
            "name": "Monkey King",
            "Lich": 0.004
        },
        {
            "name": "Morphling",
            "Lich": 1.022
        },
        {
            "name": "Muerta",
            "Lich": 0.676
        },
        {
            "name": "Naga Siren",
            "Lich": 0.087
        },
        {
            "name": "Nature's Prophet",
            "Lich": 1.741
        },
        {
            "name": "Necrophos",
            "Lich": -1.275
        },
        {
            "name": "Night Stalker",
            "Lich": 0.296
        },
        {
            "name": "Nyx Assassin",
            "Lich": 0.851
        },
        {
            "name": "Ogre Magi",
            "Lich": 0.068
        },
        {
            "name": "Omniknight",
            "Lich": 0.788
        },
        {
            "name": "Oracle",
            "Lich": 1.826
        },
        {
            "name": "Outworld Destroyer",
            "Lich": 1.311
        },
        {
            "name": "Pangolier",
            "Lich": 1.888
        },
        {
            "name": "Phantom Assassin",
            "Lich": -0.688
        },
        {
            "name": "Phantom Lancer",
            "Lich": 1.902
        },
        {
            "name": "Phoenix",
            "Lich": 1.583
        },
        {
            "name": "Primal Beast",
            "Lich": 0.394
        },
        {
            "name": "Puck",
            "Lich": 1.422
        },
        {
            "name": "Pudge",
            "Lich": 0.597
        },
        {
            "name": "Pugna",
            "Lich": 2.534
        },
        {
            "name": "Queen of Pain",
            "Lich": 1.435
        },
        {
            "name": "Razor",
            "Lich": 0.457
        },
        {
            "name": "Riki",
            "Lich": 0.166
        },
        {
            "name": "Ringmaster",
            "Lich": 1.989
        },
        {
            "name": "Rubick",
            "Lich": 1.512
        },
        {
            "name": "Sand King",
            "Lich": 0.131
        },
        {
            "name": "Shadow Demon",
            "Lich": 2.919
        },
        {
            "name": "Shadow Fiend",
            "Lich": 0.372
        },
        {
            "name": "Shadow Shaman",
            "Lich": 0.631
        },
        {
            "name": "Silencer",
            "Lich": 0.848
        },
        {
            "name": "Skywrath Mage",
            "Lich": 0.661
        },
        {
            "name": "Slardar",
            "Lich": -0.143
        },
        {
            "name": "Slark",
            "Lich": 0.656
        },
        {
            "name": "Snapfire",
            "Lich": 1.343
        },
        {
            "name": "Sniper",
            "Lich": 0.747
        },
        {
            "name": "Spectre",
            "Lich": 0.693
        },
        {
            "name": "Spirit Breaker",
            "Lich": -1.114
        },
        {
            "name": "Storm Spirit",
            "Lich": 0.588
        },
        {
            "name": "Sven",
            "Lich": 0.308
        },
        {
            "name": "Techies",
            "Lich": 0.669
        },
        {
            "name": "Templar Assassin",
            "Lich": 1.661
        },
        {
            "name": "Terrorblade",
            "Lich": -0.135
        },
        {
            "name": "Tidehunter",
            "Lich": -0.388
        },
        {
            "name": "Timbersaw",
            "Lich": 1.801
        },
        {
            "name": "Tinker",
            "Lich": 1.825
        },
        {
            "name": "Tiny",
            "Lich": 0.367
        },
        {
            "name": "Treant Protector",
            "Lich": 0.537
        },
        {
            "name": "Troll Warlord",
            "Lich": -0.042
        },
        {
            "name": "Tusk",
            "Lich": 0.367
        },
        {
            "name": "Underlord",
            "Lich": 0.564
        },
        {
            "name": "Undying",
            "Lich": 1.315
        },
        {
            "name": "Ursa",
            "Lich": 0.346
        },
        {
            "name": "Vengeful Spirit",
            "Lich": -0.4
        },
        {
            "name": "Venomancer",
            "Lich": 0.769
        },
        {
            "name": "Viper",
            "Lich": 1.021
        },
        {
            "name": "Visage",
            "Lich": 1.044
        },
        {
            "name": "Void Spirit",
            "Lich": 0.434
        },
        {
            "name": "Warlock",
            "Lich": 0.343
        },
        {
            "name": "Weaver",
            "Lich": 2.232
        },
        {
            "name": "Windranger",
            "Lich": 1.811
        },
        {
            "name": "Winter Wyvern",
            "Lich": 1.311
        },
        {
            "name": "Witch Doctor",
            "Lich": 0.559
        },
        {
            "name": "Wraith King",
            "Lich": -1.026
        },
        {
            "name": "Zeus",
            "Lich": 1.014
        }
    ],
    "Lina": [
        {
            "name": "Abaddon",
            "Lina": -1.923
        },
        {
            "name": "Alchemist",
            "Lina": -0.952
        },
        {
            "name": "Ancient Apparition",
            "Lina": -1.259
        },
        {
            "name": "Anti-Mage",
            "Lina": 1.342
        },
        {
            "name": "Arc Warden",
            "Lina": -0.522
        },
        {
            "name": "Axe",
            "Lina": 0.676
        },
        {
            "name": "Bane",
            "Lina": 1.316
        },
        {
            "name": "Batrider",
            "Lina": 2.29
        },
        {
            "name": "Beastmaster",
            "Lina": 0.051
        },
        {
            "name": "Bloodseeker",
            "Lina": -0.091
        },
        {
            "name": "Bounty Hunter",
            "Lina": 0.951
        },
        {
            "name": "Brewmaster",
            "Lina": -1.152
        },
        {
            "name": "Bristleback",
            "Lina": 1.567
        },
        {
            "name": "Broodmother",
            "Lina": -1.305
        },
        {
            "name": "Centaur Warrunner",
            "Lina": 0.564
        },
        {
            "name": "Chaos Knight",
            "Lina": -1.271
        },
        {
            "name": "Chen",
            "Lina": 0.979
        },
        {
            "name": "Clinkz",
            "Lina": 2.193
        },
        {
            "name": "Clockwerk",
            "Lina": -0.313
        },
        {
            "name": "Crystal Maiden",
            "Lina": 0.034
        },
        {
            "name": "Dark Seer",
            "Lina": -1.108
        },
        {
            "name": "Dark Willow",
            "Lina": -0.23
        },
        {
            "name": "Dawnbreaker",
            "Lina": -2.221
        },
        {
            "name": "Dazzle",
            "Lina": 0.432
        },
        {
            "name": "Death Prophet",
            "Lina": -0.227
        },
        {
            "name": "Disruptor",
            "Lina": 0.107
        },
        {
            "name": "Doom",
            "Lina": -0.73
        },
        {
            "name": "Dragon Knight",
            "Lina": 0.03
        },
        {
            "name": "Drow Ranger",
            "Lina": 0.131
        },
        {
            "name": "Earth Spirit",
            "Lina": -0.082
        },
        {
            "name": "Earthshaker",
            "Lina": -0.118
        },
        {
            "name": "Elder Titan",
            "Lina": -1.442
        },
        {
            "name": "Ember Spirit",
            "Lina": 1.878
        },
        {
            "name": "Enchantress",
            "Lina": 0.585
        },
        {
            "name": "Enigma",
            "Lina": -0.392
        },
        {
            "name": "Faceless Void",
            "Lina": 1.714
        },
        {
            "name": "Grimstroke",
            "Lina": -0.601
        },
        {
            "name": "Gyrocopter",
            "Lina": 0.652
        },
        {
            "name": "Hoodwink",
            "Lina": 0.817
        },
        {
            "name": "Huskar",
            "Lina": -0.023
        },
        {
            "name": "Invoker",
            "Lina": 0.612
        },
        {
            "name": "Io",
            "Lina": -0.701
        },
        {
            "name": "Jakiro",
            "Lina": -0.507
        },
        {
            "name": "Juggernaut",
            "Lina": -0.662
        },
        {
            "name": "Keeper of the Light",
            "Lina": 1.238
        },
        {
            "name": "Kez",
            "Lina": 0.506
        },
        {
            "name": "Kunkka",
            "Lina": 0.93
        },
        {
            "name": "Legion Commander",
            "Lina": -0.171
        },
        {
            "name": "Leshrac",
            "Lina": -0.527
        },
        {
            "name": "Lich",
            "Lina": -1.253
        },
        {
            "name": "Lifestealer",
            "Lina": -0.381
        },
        {
            "name": "Lion",
            "Lina": -0.19
        },
        {
            "name": "Lone Druid",
            "Lina": -0.201
        },
        {
            "name": "Luna",
            "Lina": -0.225
        },
        {
            "name": "Lycan",
            "Lina": -0.344
        },
        {
            "name": "Magnus",
            "Lina": 0.365
        },
        {
            "name": "Marci",
            "Lina": 0.283
        },
        {
            "name": "Mars",
            "Lina": 0.909
        },
        {
            "name": "Medusa",
            "Lina": 1.012
        },
        {
            "name": "Meepo",
            "Lina": -3.941
        },
        {
            "name": "Mirana",
            "Lina": 1.279
        },
        {
            "name": "Monkey King",
            "Lina": 0.208
        },
        {
            "name": "Morphling",
            "Lina": -2.357
        },
        {
            "name": "Muerta",
            "Lina": -0.907
        },
        {
            "name": "Naga Siren",
            "Lina": -2.153
        },
        {
            "name": "Nature's Prophet",
            "Lina": 0.764
        },
        {
            "name": "Necrophos",
            "Lina": -3.029
        },
        {
            "name": "Night Stalker",
            "Lina": 1.01
        },
        {
            "name": "Nyx Assassin",
            "Lina": 0.953
        },
        {
            "name": "Ogre Magi",
            "Lina": 0.396
        },
        {
            "name": "Omniknight",
            "Lina": -0.512
        },
        {
            "name": "Oracle",
            "Lina": 0.066
        },
        {
            "name": "Outworld Destroyer",
            "Lina": 0.695
        },
        {
            "name": "Pangolier",
            "Lina": 1.118
        },
        {
            "name": "Phantom Assassin",
            "Lina": 0.797
        },
        {
            "name": "Phantom Lancer",
            "Lina": -3.205
        },
        {
            "name": "Phoenix",
            "Lina": -3.237
        },
        {
            "name": "Primal Beast",
            "Lina": -0.034
        },
        {
            "name": "Puck",
            "Lina": 1.322
        },
        {
            "name": "Pudge",
            "Lina": 0.245
        },
        {
            "name": "Pugna",
            "Lina": -0.328
        },
        {
            "name": "Queen of Pain",
            "Lina": -0.004
        },
        {
            "name": "Razor",
            "Lina": 1.155
        },
        {
            "name": "Riki",
            "Lina": 0.609
        },
        {
            "name": "Ringmaster",
            "Lina": 0.156
        },
        {
            "name": "Rubick",
            "Lina": 1.319
        },
        {
            "name": "Sand King",
            "Lina": -0.732
        },
        {
            "name": "Shadow Demon",
            "Lina": -0.1
        },
        {
            "name": "Shadow Fiend",
            "Lina": -0.415
        },
        {
            "name": "Shadow Shaman",
            "Lina": 0.218
        },
        {
            "name": "Silencer",
            "Lina": 0.518
        },
        {
            "name": "Skywrath Mage",
            "Lina": 0.327
        },
        {
            "name": "Slardar",
            "Lina": 1.33
        },
        {
            "name": "Slark",
            "Lina": -0.254
        },
        {
            "name": "Snapfire",
            "Lina": 0.422
        },
        {
            "name": "Sniper",
            "Lina": 0.757
        },
        {
            "name": "Spectre",
            "Lina": -0.48
        },
        {
            "name": "Spirit Breaker",
            "Lina": 0.876
        },
        {
            "name": "Storm Spirit",
            "Lina": 1.357
        },
        {
            "name": "Sven",
            "Lina": 1.801
        },
        {
            "name": "Techies",
            "Lina": -0.27
        },
        {
            "name": "Templar Assassin",
            "Lina": 3.498
        },
        {
            "name": "Terrorblade",
            "Lina": -1.549
        },
        {
            "name": "Tidehunter",
            "Lina": 0.972
        },
        {
            "name": "Timbersaw",
            "Lina": -0.347
        },
        {
            "name": "Tinker",
            "Lina": -0.319
        },
        {
            "name": "Tiny",
            "Lina": 0.821
        },
        {
            "name": "Treant Protector",
            "Lina": 0.293
        },
        {
            "name": "Troll Warlord",
            "Lina": -1.101
        },
        {
            "name": "Tusk",
            "Lina": 0.679
        },
        {
            "name": "Underlord",
            "Lina": -0.806
        },
        {
            "name": "Undying",
            "Lina": -0.786
        },
        {
            "name": "Ursa",
            "Lina": 1.216
        },
        {
            "name": "Vengeful Spirit",
            "Lina": 0.116
        },
        {
            "name": "Venomancer",
            "Lina": 0.088
        },
        {
            "name": "Viper",
            "Lina": 0.267
        },
        {
            "name": "Visage",
            "Lina": 0.416
        },
        {
            "name": "Void Spirit",
            "Lina": 0.394
        },
        {
            "name": "Warlock",
            "Lina": -1.637
        },
        {
            "name": "Weaver",
            "Lina": 1.066
        },
        {
            "name": "Windranger",
            "Lina": 2.382
        },
        {
            "name": "Winter Wyvern",
            "Lina": 0.012
        },
        {
            "name": "Witch Doctor",
            "Lina": -0.628
        },
        {
            "name": "Wraith King",
            "Lina": 0.256
        },
        {
            "name": "Zeus",
            "Lina": 0.06
        }
    ],
    "Lion": [
        {
            "name": "Abaddon",
            "Lion": -1.693
        },
        {
            "name": "Alchemist",
            "Lion": 0.012
        },
        {
            "name": "Ancient Apparition",
            "Lion": -1.004
        },
        {
            "name": "Anti-Mage",
            "Lion": -0.995
        },
        {
            "name": "Arc Warden",
            "Lion": 0.865
        },
        {
            "name": "Axe",
            "Lion": -0.152
        },
        {
            "name": "Bane",
            "Lion": 0.601
        },
        {
            "name": "Batrider",
            "Lion": 0.8
        },
        {
            "name": "Beastmaster",
            "Lion": 2.22
        },
        {
            "name": "Bloodseeker",
            "Lion": -0.735
        },
        {
            "name": "Bounty Hunter",
            "Lion": -0.997
        },
        {
            "name": "Brewmaster",
            "Lion": -0.085
        },
        {
            "name": "Bristleback",
            "Lion": -0.095
        },
        {
            "name": "Broodmother",
            "Lion": -0.9
        },
        {
            "name": "Centaur Warrunner",
            "Lion": 0.287
        },
        {
            "name": "Chaos Knight",
            "Lion": -0.06
        },
        {
            "name": "Chen",
            "Lion": 1.13
        },
        {
            "name": "Clinkz",
            "Lion": -0.287
        },
        {
            "name": "Clockwerk",
            "Lion": -0.677
        },
        {
            "name": "Crystal Maiden",
            "Lion": -0.135
        },
        {
            "name": "Dark Seer",
            "Lion": 0.016
        },
        {
            "name": "Dark Willow",
            "Lion": -0.075
        },
        {
            "name": "Dawnbreaker",
            "Lion": 0.048
        },
        {
            "name": "Dazzle",
            "Lion": 1.398
        },
        {
            "name": "Death Prophet",
            "Lion": 0.312
        },
        {
            "name": "Disruptor",
            "Lion": 0.21
        },
        {
            "name": "Doom",
            "Lion": 1.053
        },
        {
            "name": "Dragon Knight",
            "Lion": 1.222
        },
        {
            "name": "Drow Ranger",
            "Lion": 0.728
        },
        {
            "name": "Earth Spirit",
            "Lion": -0.268
        },
        {
            "name": "Earthshaker",
            "Lion": -0.462
        },
        {
            "name": "Elder Titan",
            "Lion": -0.852
        },
        {
            "name": "Ember Spirit",
            "Lion": 0.691
        },
        {
            "name": "Enchantress",
            "Lion": -0.145
        },
        {
            "name": "Enigma",
            "Lion": 0.005
        },
        {
            "name": "Faceless Void",
            "Lion": -0.204
        },
        {
            "name": "Grimstroke",
            "Lion": 0.383
        },
        {
            "name": "Gyrocopter",
            "Lion": 1.329
        },
        {
            "name": "Hoodwink",
            "Lion": 0.954
        },
        {
            "name": "Huskar",
            "Lion": -0.57
        },
        {
            "name": "Invoker",
            "Lion": 0.592
        },
        {
            "name": "Io",
            "Lion": -0.016
        },
        {
            "name": "Jakiro",
            "Lion": -0.16
        },
        {
            "name": "Juggernaut",
            "Lion": -1.84
        },
        {
            "name": "Keeper of the Light",
            "Lion": 0.302
        },
        {
            "name": "Kez",
            "Lion": -0.704
        },
        {
            "name": "Kunkka",
            "Lion": 0.727
        },
        {
            "name": "Legion Commander",
            "Lion": -1.648
        },
        {
            "name": "Leshrac",
            "Lion": -0.519
        },
        {
            "name": "Lich",
            "Lion": -1.026
        },
        {
            "name": "Lifestealer",
            "Lion": -0.467
        },
        {
            "name": "Lina",
            "Lion": 0.097
        },
        {
            "name": "Lone Druid",
            "Lion": 0.515
        },
        {
            "name": "Luna",
            "Lion": 1.129
        },
        {
            "name": "Lycan",
            "Lion": 0.081
        },
        {
            "name": "Magnus",
            "Lion": -0.151
        },
        {
            "name": "Marci",
            "Lion": -0.185
        },
        {
            "name": "Mars",
            "Lion": 1.112
        },
        {
            "name": "Medusa",
            "Lion": -1.267
        },
        {
            "name": "Meepo",
            "Lion": -0.739
        },
        {
            "name": "Mirana",
            "Lion": 0.785
        },
        {
            "name": "Monkey King",
            "Lion": 0.905
        },
        {
            "name": "Morphling",
            "Lion": -1.438
        },
        {
            "name": "Muerta",
            "Lion": 0.111
        },
        {
            "name": "Naga Siren",
            "Lion": -0.401
        },
        {
            "name": "Nature's Prophet",
            "Lion": 1.163
        },
        {
            "name": "Necrophos",
            "Lion": -0.637
        },
        {
            "name": "Night Stalker",
            "Lion": -0.148
        },
        {
            "name": "Nyx Assassin",
            "Lion": -1.559
        },
        {
            "name": "Ogre Magi",
            "Lion": -0.29
        },
        {
            "name": "Omniknight",
            "Lion": 0.713
        },
        {
            "name": "Oracle",
            "Lion": 0.042
        },
        {
            "name": "Outworld Destroyer",
            "Lion": 0.671
        },
        {
            "name": "Pangolier",
            "Lion": 0.733
        },
        {
            "name": "Phantom Assassin",
            "Lion": 0.041
        },
        {
            "name": "Phantom Lancer",
            "Lion": 0.766
        },
        {
            "name": "Phoenix",
            "Lion": -0.649
        },
        {
            "name": "Primal Beast",
            "Lion": -0.543
        },
        {
            "name": "Puck",
            "Lion": -0.831
        },
        {
            "name": "Pudge",
            "Lion": 0.034
        },
        {
            "name": "Pugna",
            "Lion": 0.234
        },
        {
            "name": "Queen of Pain",
            "Lion": -0.194
        },
        {
            "name": "Razor",
            "Lion": 1.463
        },
        {
            "name": "Riki",
            "Lion": -0.705
        },
        {
            "name": "Ringmaster",
            "Lion": 0.734
        },
        {
            "name": "Rubick",
            "Lion": 1.88
        },
        {
            "name": "Sand King",
            "Lion": -0.274
        },
        {
            "name": "Shadow Demon",
            "Lion": 0.503
        },
        {
            "name": "Shadow Fiend",
            "Lion": 0.349
        },
        {
            "name": "Shadow Shaman",
            "Lion": 0.017
        },
        {
            "name": "Silencer",
            "Lion": -0.009
        },
        {
            "name": "Skywrath Mage",
            "Lion": -0.171
        },
        {
            "name": "Slardar",
            "Lion": -0.064
        },
        {
            "name": "Slark",
            "Lion": -1.602
        },
        {
            "name": "Snapfire",
            "Lion": 0.809
        },
        {
            "name": "Sniper",
            "Lion": 0.562
        },
        {
            "name": "Spectre",
            "Lion": 0.232
        },
        {
            "name": "Spirit Breaker",
            "Lion": -0.43
        },
        {
            "name": "Storm Spirit",
            "Lion": -1.575
        },
        {
            "name": "Sven",
            "Lion": 0.633
        },
        {
            "name": "Techies",
            "Lion": -0.066
        },
        {
            "name": "Templar Assassin",
            "Lion": 1.881
        },
        {
            "name": "Terrorblade",
            "Lion": 0.177
        },
        {
            "name": "Tidehunter",
            "Lion": 0.771
        },
        {
            "name": "Timbersaw",
            "Lion": -0.794
        },
        {
            "name": "Tinker",
            "Lion": 0.14
        },
        {
            "name": "Tiny",
            "Lion": 1.09
        },
        {
            "name": "Treant Protector",
            "Lion": 0.741
        },
        {
            "name": "Troll Warlord",
            "Lion": -1.424
        },
        {
            "name": "Tusk",
            "Lion": 0.341
        },
        {
            "name": "Underlord",
            "Lion": 0.168
        },
        {
            "name": "Undying",
            "Lion": -0.739
        },
        {
            "name": "Ursa",
            "Lion": -0.307
        },
        {
            "name": "Vengeful Spirit",
            "Lion": 0.518
        },
        {
            "name": "Venomancer",
            "Lion": 0.645
        },
        {
            "name": "Viper",
            "Lion": 0.509
        },
        {
            "name": "Visage",
            "Lion": 0.505
        },
        {
            "name": "Void Spirit",
            "Lion": -1.068
        },
        {
            "name": "Warlock",
            "Lion": -0.199
        },
        {
            "name": "Weaver",
            "Lion": -0.82
        },
        {
            "name": "Windranger",
            "Lion": 1.457
        },
        {
            "name": "Winter Wyvern",
            "Lion": 0.457
        },
        {
            "name": "Witch Doctor",
            "Lion": -0.769
        },
        {
            "name": "Wraith King",
            "Lion": -0.15
        },
        {
            "name": "Zeus",
            "Lion": -0.6
        }
    ],
    "Muerta": [
        {
            "name": "Abaddon",
            "Muerta": -0.433
        },
        {
            "name": "Alchemist",
            "Muerta": -0.176
        },
        {
            "name": "Ancient Apparition",
            "Muerta": 0.289
        },
        {
            "name": "Anti-Mage",
            "Muerta": 0.925
        },
        {
            "name": "Arc Warden",
            "Muerta": 1.164
        },
        {
            "name": "Axe",
            "Muerta": 1.429
        },
        {
            "name": "Bane",
            "Muerta": 2.462
        },
        {
            "name": "Batrider",
            "Muerta": 1.217
        },
        {
            "name": "Beastmaster",
            "Muerta": -0.216
        },
        {
            "name": "Bloodseeker",
            "Muerta": 0.855
        },
        {
            "name": "Bounty Hunter",
            "Muerta": 0.762
        },
        {
            "name": "Brewmaster",
            "Muerta": 0.483
        },
        {
            "name": "Bristleback",
            "Muerta": 0.738
        },
        {
            "name": "Broodmother",
            "Muerta": 0.71
        },
        {
            "name": "Centaur Warrunner",
            "Muerta": 2.537
        },
        {
            "name": "Chaos Knight",
            "Muerta": 0.196
        },
        {
            "name": "Chen",
            "Muerta": 1.745
        },
        {
            "name": "Clinkz",
            "Muerta": 0.33
        },
        {
            "name": "Clockwerk",
            "Muerta": -0.47
        },
        {
            "name": "Crystal Maiden",
            "Muerta": 0.144
        },
        {
            "name": "Dark Seer",
            "Muerta": 1.575
        },
        {
            "name": "Dark Willow",
            "Muerta": -0.032
        },
        {
            "name": "Dawnbreaker",
            "Muerta": -1.599
        },
        {
            "name": "Dazzle",
            "Muerta": -0.396
        },
        {
            "name": "Death Prophet",
            "Muerta": -0.038
        },
        {
            "name": "Disruptor",
            "Muerta": -0.486
        },
        {
            "name": "Doom",
            "Muerta": -0.429
        },
        {
            "name": "Dragon Knight",
            "Muerta": -0.322
        },
        {
            "name": "Drow Ranger",
            "Muerta": -0.559
        },
        {
            "name": "Earth Spirit",
            "Muerta": -0.709
        },
        {
            "name": "Earthshaker",
            "Muerta": -0.454
        },
        {
            "name": "Elder Titan",
            "Muerta": 1.466
        },
        {
            "name": "Ember Spirit",
            "Muerta": -2.188
        },
        {
            "name": "Enchantress",
            "Muerta": 2.346
        },
        {
            "name": "Enigma",
            "Muerta": 0.46
        },
        {
            "name": "Faceless Void",
            "Muerta": -0.764
        },
        {
            "name": "Grimstroke",
            "Muerta": 0.264
        },
        {
            "name": "Gyrocopter",
            "Muerta": 0.188
        },
        {
            "name": "Hoodwink",
            "Muerta": 0.489
        },
        {
            "name": "Huskar",
            "Muerta": 0.396
        },
        {
            "name": "Invoker",
            "Muerta": 1.369
        },
        {
            "name": "Io",
            "Muerta": -0.787
        },
        {
            "name": "Jakiro",
            "Muerta": 0.878
        },
        {
            "name": "Juggernaut",
            "Muerta": -1.779
        },
        {
            "name": "Keeper of the Light",
            "Muerta": 1.478
        },
        {
            "name": "Kez",
            "Muerta": -1.223
        },
        {
            "name": "Kunkka",
            "Muerta": 1.872
        },
        {
            "name": "Legion Commander",
            "Muerta": 1.255
        },
        {
            "name": "Leshrac",
            "Muerta": 2.535
        },
        {
            "name": "Lich",
            "Muerta": 0.566
        },
        {
            "name": "Lifestealer",
            "Muerta": -0.684
        },
        {
            "name": "Lina",
            "Muerta": 1.724
        },
        {
            "name": "Lion",
            "Muerta": 0.431
        },
        {
            "name": "Lone Druid",
            "Muerta": -0.69
        },
        {
            "name": "Luna",
            "Muerta": -0.007
        },
        {
            "name": "Lycan",
            "Muerta": 1.22
        },
        {
            "name": "Magnus",
            "Muerta": -0.539
        },
        {
            "name": "Marci",
            "Muerta": -2.656
        },
        {
            "name": "Mars",
            "Muerta": -0.482
        },
        {
            "name": "Medusa",
            "Muerta": 1.783
        },
        {
            "name": "Meepo",
            "Muerta": -2.113
        },
        {
            "name": "Mirana",
            "Muerta": 0.015
        },
        {
            "name": "Monkey King",
            "Muerta": -3.577
        },
        {
            "name": "Morphling",
            "Muerta": -0.897
        },
        {
            "name": "Naga Siren",
            "Muerta": 1.342
        },
        {
            "name": "Nature's Prophet",
            "Muerta": 1.089
        },
        {
            "name": "Necrophos",
            "Muerta": -3.797
        },
        {
            "name": "Night Stalker",
            "Muerta": -0.287
        },
        {
            "name": "Nyx Assassin",
            "Muerta": 0.913
        },
        {
            "name": "Ogre Magi",
            "Muerta": 0.37
        },
        {
            "name": "Omniknight",
            "Muerta": -1.411
        },
        {
            "name": "Oracle",
            "Muerta": 0.389
        },
        {
            "name": "Outworld Destroyer",
            "Muerta": -0.562
        },
        {
            "name": "Pangolier",
            "Muerta": -0.368
        },
        {
            "name": "Phantom Assassin",
            "Muerta": -0.428
        },
        {
            "name": "Phantom Lancer",
            "Muerta": -0.016
        },
        {
            "name": "Phoenix",
            "Muerta": -2.036
        },
        {
            "name": "Primal Beast",
            "Muerta": 1.972
        },
        {
            "name": "Puck",
            "Muerta": -2.052
        },
        {
            "name": "Pudge",
            "Muerta": 1.217
        },
        {
            "name": "Pugna",
            "Muerta": 1.257
        },
        {
            "name": "Queen of Pain",
            "Muerta": -0.355
        },
        {
            "name": "Razor",
            "Muerta": -1.104
        },
        {
            "name": "Riki",
            "Muerta": -0.919
        },
        {
            "name": "Ringmaster",
            "Muerta": 1.197
        },
        {
            "name": "Rubick",
            "Muerta": 0.42
        },
        {
            "name": "Sand King",
            "Muerta": -0.693
        },
        {
            "name": "Shadow Demon",
            "Muerta": 1.255
        },
        {
            "name": "Shadow Fiend",
            "Muerta": -0.627
        },
        {
            "name": "Shadow Shaman",
            "Muerta": -0.706
        },
        {
            "name": "Silencer",
            "Muerta": -0.854
        },
        {
            "name": "Skywrath Mage",
            "Muerta": 1.945
        },
        {
            "name": "Slardar",
            "Muerta": -2.548
        },
        {
            "name": "Slark",
            "Muerta": -1.874
        },
        {
            "name": "Snapfire",
            "Muerta": 1.433
        },
        {
            "name": "Sniper",
            "Muerta": 0.458
        },
        {
            "name": "Spectre",
            "Muerta": 2.692
        },
        {
            "name": "Spirit Breaker",
            "Muerta": 0.242
        },
        {
            "name": "Storm Spirit",
            "Muerta": -0.732
        },
        {
            "name": "Sven",
            "Muerta": -0.518
        },
        {
            "name": "Techies",
            "Muerta": 0.588
        },
        {
            "name": "Templar Assassin",
            "Muerta": 1.158
        },
        {
            "name": "Terrorblade",
            "Muerta": -0.148
        },
        {
            "name": "Tidehunter",
            "Muerta": -1.793
        },
        {
            "name": "Timbersaw",
            "Muerta": -1.134
        },
        {
            "name": "Tinker",
            "Muerta": 0.914
        },
        {
            "name": "Tiny",
            "Muerta": 0.476
        },
        {
            "name": "Treant Protector",
            "Muerta": -1.811
        },
        {
            "name": "Troll Warlord",
            "Muerta": -2.583
        },
        {
            "name": "Tusk",
            "Muerta": -0.913
        },
        {
            "name": "Underlord",
            "Muerta": 0.959
        },
        {
            "name": "Undying",
            "Muerta": -0.871
        },
        {
            "name": "Ursa",
            "Muerta": -0.772
        },
        {
            "name": "Vengeful Spirit",
            "Muerta": -0.503
        },
        {
            "name": "Venomancer",
            "Muerta": -1.681
        },
        {
            "name": "Viper",
            "Muerta": -0.415
        },
        {
            "name": "Visage",
            "Muerta": 0.339
        },
        {
            "name": "Void Spirit",
            "Muerta": -0.796
        },
        {
            "name": "Warlock",
            "Muerta": 0.017
        },
        {
            "name": "Weaver",
            "Muerta": -1.329
        },
        {
            "name": "Windranger",
            "Muerta": 0.16
        },
        {
            "name": "Winter Wyvern",
            "Muerta": -0.002
        },
        {
            "name": "Witch Doctor",
            "Muerta": -1.261
        },
        {
            "name": "Wraith King",
            "Muerta": 1.553
        },
        {
            "name": "Zeus",
            "Muerta": 1.301
        }
    ],
    "Nature's Prophet": [
        {
            "name": "Abaddon",
            "Nature's Prophet": -1.168
        },
        {
            "name": "Alchemist",
            "Nature's Prophet": -0.965
        },
        {
            "name": "Ancient Apparition",
            "Nature's Prophet": -1.632
        },
        {
            "name": "Anti-Mage",
            "Nature's Prophet": 0.999
        },
        {
            "name": "Arc Warden",
            "Nature's Prophet": 1.354
        },
        {
            "name": "Axe",
            "Nature's Prophet": 0.343
        },
        {
            "name": "Bane",
            "Nature's Prophet": -1.183
        },
        {
            "name": "Batrider",
            "Nature's Prophet": 1.408
        },
        {
            "name": "Beastmaster",
            "Nature's Prophet": -0.185
        },
        {
            "name": "Bloodseeker",
            "Nature's Prophet": -3.481
        },
        {
            "name": "Bounty Hunter",
            "Nature's Prophet": -0.657
        },
        {
            "name": "Brewmaster",
            "Nature's Prophet": -0.719
        },
        {
            "name": "Bristleback",
            "Nature's Prophet": -0.705
        },
        {
            "name": "Broodmother",
            "Nature's Prophet": 2.018
        },
        {
            "name": "Centaur Warrunner",
            "Nature's Prophet": -0.791
        },
        {
            "name": "Chaos Knight",
            "Nature's Prophet": 0.642
        },
        {
            "name": "Chen",
            "Nature's Prophet": -1.432
        },
        {
            "name": "Clinkz",
            "Nature's Prophet": -1.055
        },
        {
            "name": "Clockwerk",
            "Nature's Prophet": 0.37
        },
        {
            "name": "Crystal Maiden",
            "Nature's Prophet": 1.059
        },
        {
            "name": "Dark Seer",
            "Nature's Prophet": -1.259
        },
        {
            "name": "Dark Willow",
            "Nature's Prophet": -0.789
        },
        {
            "name": "Dawnbreaker",
            "Nature's Prophet": 1.347
        },
        {
            "name": "Dazzle",
            "Nature's Prophet": 0.22
        },
        {
            "name": "Death Prophet",
            "Nature's Prophet": -1.545
        },
        {
            "name": "Disruptor",
            "Nature's Prophet": -1.561
        },
        {
            "name": "Doom",
            "Nature's Prophet": -1.28
        },
        {
            "name": "Dragon Knight",
            "Nature's Prophet": 0.76
        },
        {
            "name": "Drow Ranger",
            "Nature's Prophet": -1.458
        },
        {
            "name": "Earth Spirit",
            "Nature's Prophet": 0.187
        },
        {
            "name": "Earthshaker",
            "Nature's Prophet": -0.56
        },
        {
            "name": "Elder Titan",
            "Nature's Prophet": -2.843
        },
        {
            "name": "Ember Spirit",
            "Nature's Prophet": -1.482
        },
        {
            "name": "Enchantress",
            "Nature's Prophet": -0.191
        },
        {
            "name": "Enigma",
            "Nature's Prophet": -1.884
        },
        {
            "name": "Faceless Void",
            "Nature's Prophet": -0.938
        },
        {
            "name": "Grimstroke",
            "Nature's Prophet": -1.602
        },
        {
            "name": "Gyrocopter",
            "Nature's Prophet": -0.513
        },
        {
            "name": "Hoodwink",
            "Nature's Prophet": 0.219
        },
        {
            "name": "Huskar",
            "Nature's Prophet": -0.893
        },
        {
            "name": "Invoker",
            "Nature's Prophet": -0.178
        },
        {
            "name": "Io",
            "Nature's Prophet": 0.529
        },
        {
            "name": "Jakiro",
            "Nature's Prophet": -0.397
        },
        {
            "name": "Juggernaut",
            "Nature's Prophet": -2.577
        },
        {
            "name": "Keeper of the Light",
            "Nature's Prophet": -1.282
        },
        {
            "name": "Kez",
            "Nature's Prophet": 0.451
        },
        {
            "name": "Kunkka",
            "Nature's Prophet": -0.354
        },
        {
            "name": "Legion Commander",
            "Nature's Prophet": 0.363
        },
        {
            "name": "Leshrac",
            "Nature's Prophet": 2.726
        },
        {
            "name": "Lich",
            "Nature's Prophet": -0.767
        },
        {
            "name": "Lifestealer",
            "Nature's Prophet": -3.332
        },
        {
            "name": "Lina",
            "Nature's Prophet": -0.066
        },
        {
            "name": "Lion",
            "Nature's Prophet": -0.254
        },
        {
            "name": "Lone Druid",
            "Nature's Prophet": -0.428
        },
        {
            "name": "Luna",
            "Nature's Prophet": 1.405
        },
        {
            "name": "Lycan",
            "Nature's Prophet": -0.744
        },
        {
            "name": "Magnus",
            "Nature's Prophet": -0.192
        },
        {
            "name": "Marci",
            "Nature's Prophet": 0.104
        },
        {
            "name": "Mars",
            "Nature's Prophet": 0.522
        },
        {
            "name": "Medusa",
            "Nature's Prophet": -0.034
        },
        {
            "name": "Meepo",
            "Nature's Prophet": 0.666
        },
        {
            "name": "Mirana",
            "Nature's Prophet": 0.117
        },
        {
            "name": "Monkey King",
            "Nature's Prophet": 0.117
        },
        {
            "name": "Morphling",
            "Nature's Prophet": 1.525
        },
        {
            "name": "Muerta",
            "Nature's Prophet": -1.124
        },
        {
            "name": "Naga Siren",
            "Nature's Prophet": 0.457
        },
        {
            "name": "Necrophos",
            "Nature's Prophet": -0.796
        },
        {
            "name": "Night Stalker",
            "Nature's Prophet": 0.513
        },
        {
            "name": "Nyx Assassin",
            "Nature's Prophet": 0.864
        },
        {
            "name": "Ogre Magi",
            "Nature's Prophet": -0.476
        },
        {
            "name": "Omniknight",
            "Nature's Prophet": -1.316
        },
        {
            "name": "Oracle",
            "Nature's Prophet": -0.35
        },
        {
            "name": "Outworld Destroyer",
            "Nature's Prophet": -1.244
        },
        {
            "name": "Pangolier",
            "Nature's Prophet": -1.91
        },
        {
            "name": "Phantom Assassin",
            "Nature's Prophet": 1.863
        },
        {
            "name": "Phantom Lancer",
            "Nature's Prophet": -1.009
        },
        {
            "name": "Phoenix",
            "Nature's Prophet": -1.379
        },
        {
            "name": "Primal Beast",
            "Nature's Prophet": 2.569
        },
        {
            "name": "Puck",
            "Nature's Prophet": -1.78
        },
        {
            "name": "Pudge",
            "Nature's Prophet": 0.895
        },
        {
            "name": "Pugna",
            "Nature's Prophet": -0.457
        },
        {
            "name": "Queen of Pain",
            "Nature's Prophet": -0.301
        },
        {
            "name": "Razor",
            "Nature's Prophet": -1.578
        },
        {
            "name": "Riki",
            "Nature's Prophet": -0.923
        },
        {
            "name": "Ringmaster",
            "Nature's Prophet": -1.18
        },
        {
            "name": "Rubick",
            "Nature's Prophet": -0.682
        },
        {
            "name": "Sand King",
            "Nature's Prophet": -0.115
        },
        {
            "name": "Shadow Demon",
            "Nature's Prophet": -0.998
        },
        {
            "name": "Shadow Fiend",
            "Nature's Prophet": 0.467
        },
        {
            "name": "Shadow Shaman",
            "Nature's Prophet": 0.436
        },
        {
            "name": "Silencer",
            "Nature's Prophet": -1.506
        },
        {
            "name": "Skywrath Mage",
            "Nature's Prophet": -0.153
        },
        {
            "name": "Slardar",
            "Nature's Prophet": 0.549
        },
        {
            "name": "Slark",
            "Nature's Prophet": 0.016
        },
        {
            "name": "Snapfire",
            "Nature's Prophet": 0.422
        },
        {
            "name": "Sniper",
            "Nature's Prophet": -0.709
        },
        {
            "name": "Spectre",
            "Nature's Prophet": 3.337
        },
        {
            "name": "Spirit Breaker",
            "Nature's Prophet": 0.454
        },
        {
            "name": "Storm Spirit",
            "Nature's Prophet": 0.078
        },
        {
            "name": "Sven",
            "Nature's Prophet": -0.776
        },
        {
            "name": "Techies",
            "Nature's Prophet": 0.207
        },
        {
            "name": "Templar Assassin",
            "Nature's Prophet": 0.742
        },
        {
            "name": "Terrorblade",
            "Nature's Prophet": -0.111
        },
        {
            "name": "Tidehunter",
            "Nature's Prophet": -1.558
        },
        {
            "name": "Timbersaw",
            "Nature's Prophet": -0.285
        },
        {
            "name": "Tinker",
            "Nature's Prophet": 1.093
        },
        {
            "name": "Tiny",
            "Nature's Prophet": 0.045
        },
        {
            "name": "Treant Protector",
            "Nature's Prophet": 1.929
        },
        {
            "name": "Troll Warlord",
            "Nature's Prophet": 0.289
        },
        {
            "name": "Tusk",
            "Nature's Prophet": -0.372
        },
        {
            "name": "Underlord",
            "Nature's Prophet": 1.096
        },
        {
            "name": "Undying",
            "Nature's Prophet": -0.586
        },
        {
            "name": "Ursa",
            "Nature's Prophet": -0.519
        },
        {
            "name": "Vengeful Spirit",
            "Nature's Prophet": 0.304
        },
        {
            "name": "Venomancer",
            "Nature's Prophet": -1.365
        },
        {
            "name": "Viper",
            "Nature's Prophet": -0.334
        },
        {
            "name": "Visage",
            "Nature's Prophet": 0.041
        },
        {
            "name": "Void Spirit",
            "Nature's Prophet": 0.479
        },
        {
            "name": "Warlock",
            "Nature's Prophet": -0.979
        },
        {
            "name": "Weaver",
            "Nature's Prophet": -1.098
        },
        {
            "name": "Windranger",
            "Nature's Prophet": -0.276
        },
        {
            "name": "Winter Wyvern",
            "Nature's Prophet": -1.045
        },
        {
            "name": "Witch Doctor",
            "Nature's Prophet": -0.735
        },
        {
            "name": "Wraith King",
            "Nature's Prophet": 0.317
        },
        {
            "name": "Zeus",
            "Nature's Prophet": -0.033
        }
    ],
    "Necrophos": [
        {
            "name": "Abaddon",
            "Necrophos": -0.511
        },
        {
            "name": "Alchemist",
            "Necrophos": 0.0
        },
        {
            "name": "Ancient Apparition",
            "Necrophos": 4.088
        },
        {
            "name": "Anti-Mage",
            "Necrophos": 1.353
        },
        {
            "name": "Arc Warden",
            "Necrophos": 2.905
        },
        {
            "name": "Axe",
            "Necrophos": -2.934
        },
        {
            "name": "Bane",
            "Necrophos": -1.139
        },
        {
            "name": "Batrider",
            "Necrophos": 0.565
        },
        {
            "name": "Beastmaster",
            "Necrophos": 0.799
        },
        {
            "name": "Bloodseeker",
            "Necrophos": 0.478
        },
        {
            "name": "Bounty Hunter",
            "Necrophos": -0.436
        },
        {
            "name": "Brewmaster",
            "Necrophos": 1.253
        },
        {
            "name": "Bristleback",
            "Necrophos": -1.446
        },
        {
            "name": "Broodmother",
            "Necrophos": -1.702
        },
        {
            "name": "Centaur Warrunner",
            "Necrophos": -1.225
        },
        {
            "name": "Chaos Knight",
            "Necrophos": -1.188
        },
        {
            "name": "Chen",
            "Necrophos": 0.771
        },
        {
            "name": "Clinkz",
            "Necrophos": 1.248
        },
        {
            "name": "Clockwerk",
            "Necrophos": -0.411
        },
        {
            "name": "Crystal Maiden",
            "Necrophos": 1.323
        },
        {
            "name": "Dark Seer",
            "Necrophos": 1.945
        },
        {
            "name": "Dark Willow",
            "Necrophos": -0.073
        },
        {
            "name": "Dawnbreaker",
            "Necrophos": 1.239
        },
        {
            "name": "Dazzle",
            "Necrophos": 1.175
        },
        {
            "name": "Death Prophet",
            "Necrophos": 3.199
        },
        {
            "name": "Disruptor",
            "Necrophos": 1.267
        },
        {
            "name": "Doom",
            "Necrophos": 4.172
        },
        {
            "name": "Dragon Knight",
            "Necrophos": -2.625
        },
        {
            "name": "Drow Ranger",
            "Necrophos": 2.263
        },
        {
            "name": "Earth Spirit",
            "Necrophos": -0.385
        },
        {
            "name": "Earthshaker",
            "Necrophos": -0.809
        },
        {
            "name": "Elder Titan",
            "Necrophos": 0.017
        },
        {
            "name": "Ember Spirit",
            "Necrophos": -0.097
        },
        {
            "name": "Enchantress",
            "Necrophos": -0.271
        },
        {
            "name": "Enigma",
            "Necrophos": -0.546
        },
        {
            "name": "Faceless Void",
            "Necrophos": 0.686
        },
        {
            "name": "Grimstroke",
            "Necrophos": 2.476
        },
        {
            "name": "Gyrocopter",
            "Necrophos": 1.462
        },
        {
            "name": "Hoodwink",
            "Necrophos": 0.856
        },
        {
            "name": "Huskar",
            "Necrophos": -3.779
        },
        {
            "name": "Invoker",
            "Necrophos": -0.208
        },
        {
            "name": "Io",
            "Necrophos": 1.13
        },
        {
            "name": "Jakiro",
            "Necrophos": -0.047
        },
        {
            "name": "Juggernaut",
            "Necrophos": -0.076
        },
        {
            "name": "Keeper of the Light",
            "Necrophos": 1.715
        },
        {
            "name": "Kez",
            "Necrophos": -0.669
        },
        {
            "name": "Kunkka",
            "Necrophos": -1.043
        },
        {
            "name": "Legion Commander",
            "Necrophos": 1.348
        },
        {
            "name": "Leshrac",
            "Necrophos": 3.104
        },
        {
            "name": "Lich",
            "Necrophos": 1.52
        },
        {
            "name": "Lifestealer",
            "Necrophos": -0.284
        },
        {
            "name": "Lina",
            "Necrophos": 3.065
        },
        {
            "name": "Lion",
            "Necrophos": 0.14
        },
        {
            "name": "Lone Druid",
            "Necrophos": 0.481
        },
        {
            "name": "Luna",
            "Necrophos": 0.173
        },
        {
            "name": "Lycan",
            "Necrophos": -1.782
        },
        {
            "name": "Magnus",
            "Necrophos": -0.307
        },
        {
            "name": "Marci",
            "Necrophos": 1.328
        },
        {
            "name": "Mars",
            "Necrophos": 0.388
        },
        {
            "name": "Medusa",
            "Necrophos": 1.526
        },
        {
            "name": "Meepo",
            "Necrophos": -0.526
        },
        {
            "name": "Mirana",
            "Necrophos": 0.634
        },
        {
            "name": "Monkey King",
            "Necrophos": 0.342
        },
        {
            "name": "Morphling",
            "Necrophos": 0.014
        },
        {
            "name": "Muerta",
            "Necrophos": 3.95
        },
        {
            "name": "Naga Siren",
            "Necrophos": -3.357
        },
        {
            "name": "Nature's Prophet",
            "Necrophos": 1.085
        },
        {
            "name": "Night Stalker",
            "Necrophos": -0.084
        },
        {
            "name": "Nyx Assassin",
            "Necrophos": 0.537
        },
        {
            "name": "Ogre Magi",
            "Necrophos": -0.384
        },
        {
            "name": "Omniknight",
            "Necrophos": -0.365
        },
        {
            "name": "Oracle",
            "Necrophos": 2.996
        },
        {
            "name": "Outworld Destroyer",
            "Necrophos": 0.995
        },
        {
            "name": "Pangolier",
            "Necrophos": 0.898
        },
        {
            "name": "Phantom Assassin",
            "Necrophos": -0.08
        },
        {
            "name": "Phantom Lancer",
            "Necrophos": -3.945
        },
        {
            "name": "Phoenix",
            "Necrophos": -0.258
        },
        {
            "name": "Primal Beast",
            "Necrophos": 0.271
        },
        {
            "name": "Puck",
            "Necrophos": 0.936
        },
        {
            "name": "Pudge",
            "Necrophos": 2.057
        },
        {
            "name": "Pugna",
            "Necrophos": 3.187
        },
        {
            "name": "Queen of Pain",
            "Necrophos": 0.757
        },
        {
            "name": "Razor",
            "Necrophos": -0.85
        },
        {
            "name": "Riki",
            "Necrophos": -1.835
        },
        {
            "name": "Ringmaster",
            "Necrophos": 0.554
        },
        {
            "name": "Rubick",
            "Necrophos": -0.032
        },
        {
            "name": "Sand King",
            "Necrophos": 0.77
        },
        {
            "name": "Shadow Demon",
            "Necrophos": 2.356
        },
        {
            "name": "Shadow Fiend",
            "Necrophos": 1.727
        },
        {
            "name": "Shadow Shaman",
            "Necrophos": -1.914
        },
        {
            "name": "Silencer",
            "Necrophos": 2.503
        },
        {
            "name": "Skywrath Mage",
            "Necrophos": 4.183
        },
        {
            "name": "Slardar",
            "Necrophos": -0.059
        },
        {
            "name": "Slark",
            "Necrophos": -1.779
        },
        {
            "name": "Snapfire",
            "Necrophos": 1.37
        },
        {
            "name": "Sniper",
            "Necrophos": 2.194
        },
        {
            "name": "Spectre",
            "Necrophos": -1.97
        },
        {
            "name": "Spirit Breaker",
            "Necrophos": -2.849
        },
        {
            "name": "Storm Spirit",
            "Necrophos": -0.365
        },
        {
            "name": "Sven",
            "Necrophos": -0.015
        },
        {
            "name": "Techies",
            "Necrophos": -0.699
        },
        {
            "name": "Templar Assassin",
            "Necrophos": -0.742
        },
        {
            "name": "Terrorblade",
            "Necrophos": 0.423
        },
        {
            "name": "Tidehunter",
            "Necrophos": -1.66
        },
        {
            "name": "Timbersaw",
            "Necrophos": 0.427
        },
        {
            "name": "Tinker",
            "Necrophos": -0.118
        },
        {
            "name": "Tiny",
            "Necrophos": -3.587
        },
        {
            "name": "Treant Protector",
            "Necrophos": -1.058
        },
        {
            "name": "Troll Warlord",
            "Necrophos": -1.603
        },
        {
            "name": "Tusk",
            "Necrophos": -0.376
        },
        {
            "name": "Underlord",
            "Necrophos": 1.683
        },
        {
            "name": "Undying",
            "Necrophos": -0.209
        },
        {
            "name": "Ursa",
            "Necrophos": -1.097
        },
        {
            "name": "Vengeful Spirit",
            "Necrophos": -0.844
        },
        {
            "name": "Venomancer",
            "Necrophos": 0.943
        },
        {
            "name": "Viper",
            "Necrophos": 2.342
        },
        {
            "name": "Visage",
            "Necrophos": 2.567
        },
        {
            "name": "Void Spirit",
            "Necrophos": -0.689
        },
        {
            "name": "Warlock",
            "Necrophos": 0.87
        },
        {
            "name": "Weaver",
            "Necrophos": -0.446
        },
        {
            "name": "Windranger",
            "Necrophos": -0.239
        },
        {
            "name": "Winter Wyvern",
            "Necrophos": -2.246
        },
        {
            "name": "Witch Doctor",
            "Necrophos": 0.169
        },
        {
            "name": "Wraith King",
            "Necrophos": -3.642
        },
        {
            "name": "Zeus",
            "Necrophos": -0.881
        }
    ],
    "Oracle": [
        {
            "name": "Abaddon",
            "Oracle": -0.431
        },
        {
            "name": "Alchemist",
            "Oracle": 1.322
        },
        {
            "name": "Ancient Apparition",
            "Oracle": -0.739
        },
        {
            "name": "Anti-Mage",
            "Oracle": 2.967
        },
        {
            "name": "Arc Warden",
            "Oracle": 0.194
        },
        {
            "name": "Axe",
            "Oracle": 0.461
        },
        {
            "name": "Bane",
            "Oracle": -0.053
        },
        {
            "name": "Batrider",
            "Oracle": -0.414
        },
        {
            "name": "Beastmaster",
            "Oracle": 1.234
        },
        {
            "name": "Bloodseeker",
            "Oracle": -2.82
        },
        {
            "name": "Bounty Hunter",
            "Oracle": -0.916
        },
        {
            "name": "Brewmaster",
            "Oracle": -1.418
        },
        {
            "name": "Bristleback",
            "Oracle": 0.403
        },
        {
            "name": "Broodmother",
            "Oracle": 2.035
        },
        {
            "name": "Centaur Warrunner",
            "Oracle": -2.576
        },
        {
            "name": "Chaos Knight",
            "Oracle": 2.796
        },
        {
            "name": "Chen",
            "Oracle": -1.767
        },
        {
            "name": "Clinkz",
            "Oracle": 1.058
        },
        {
            "name": "Clockwerk",
            "Oracle": -0.698
        },
        {
            "name": "Crystal Maiden",
            "Oracle": 0.673
        },
        {
            "name": "Dark Seer",
            "Oracle": -1.875
        },
        {
            "name": "Dark Willow",
            "Oracle": -0.739
        },
        {
            "name": "Dawnbreaker",
            "Oracle": 1.051
        },
        {
            "name": "Dazzle",
            "Oracle": 1.464
        },
        {
            "name": "Death Prophet",
            "Oracle": 3.341
        },
        {
            "name": "Disruptor",
            "Oracle": -1.465
        },
        {
            "name": "Doom",
            "Oracle": 1.023
        },
        {
            "name": "Dragon Knight",
            "Oracle": 1.192
        },
        {
            "name": "Drow Ranger",
            "Oracle": 1.569
        },
        {
            "name": "Earth Spirit",
            "Oracle": -1.289
        },
        {
            "name": "Earthshaker",
            "Oracle": 1.162
        },
        {
            "name": "Elder Titan",
            "Oracle": 0.659
        },
        {
            "name": "Ember Spirit",
            "Oracle": -2.685
        },
        {
            "name": "Enchantress",
            "Oracle": 2.285
        },
        {
            "name": "Enigma",
            "Oracle": 0.62
        },
        {
            "name": "Faceless Void",
            "Oracle": -1.423
        },
        {
            "name": "Grimstroke",
            "Oracle": 0.106
        },
        {
            "name": "Gyrocopter",
            "Oracle": -0.456
        },
        {
            "name": "Hoodwink",
            "Oracle": 0.733
        },
        {
            "name": "Huskar",
            "Oracle": 1.409
        },
        {
            "name": "Invoker",
            "Oracle": 0.408
        },
        {
            "name": "Io",
            "Oracle": 2.736
        },
        {
            "name": "Jakiro",
            "Oracle": -1.198
        },
        {
            "name": "Juggernaut",
            "Oracle": -0.652
        },
        {
            "name": "Keeper of the Light",
            "Oracle": 0.446
        },
        {
            "name": "Kez",
            "Oracle": -0.383
        },
        {
            "name": "Kunkka",
            "Oracle": 0.508
        },
        {
            "name": "Legion Commander",
            "Oracle": -2.817
        },
        {
            "name": "Leshrac",
            "Oracle": 0.065
        },
        {
            "name": "Lich",
            "Oracle": -1.604
        },
        {
            "name": "Lifestealer",
            "Oracle": -0.552
        },
        {
            "name": "Lina",
            "Oracle": -0.483
        },
        {
            "name": "Lion",
            "Oracle": -0.935
        },
        {
            "name": "Lone Druid",
            "Oracle": 1.827
        },
        {
            "name": "Luna",
            "Oracle": -0.279
        },
        {
            "name": "Lycan",
            "Oracle": 2.446
        },
        {
            "name": "Magnus",
            "Oracle": -0.167
        },
        {
            "name": "Marci",
            "Oracle": 2.871
        },
        {
            "name": "Mars",
            "Oracle": 0.155
        },
        {
            "name": "Medusa",
            "Oracle": -0.016
        },
        {
            "name": "Meepo",
            "Oracle": 2.602
        },
        {
            "name": "Mirana",
            "Oracle": 0.057
        },
        {
            "name": "Monkey King",
            "Oracle": 0.959
        },
        {
            "name": "Morphling",
            "Oracle": -0.047
        },
        {
            "name": "Muerta",
            "Oracle": -0.312
        },
        {
            "name": "Naga Siren",
            "Oracle": 1.897
        },
        {
            "name": "Nature's Prophet",
            "Oracle": 0.111
        },
        {
            "name": "Necrophos",
            "Oracle": -3.211
        },
        {
            "name": "Night Stalker",
            "Oracle": 0.947
        },
        {
            "name": "Nyx Assassin",
            "Oracle": 0.276
        },
        {
            "name": "Ogre Magi",
            "Oracle": -1.068
        },
        {
            "name": "Omniknight",
            "Oracle": 1.919
        },
        {
            "name": "Outworld Destroyer",
            "Oracle": 2.886
        },
        {
            "name": "Pangolier",
            "Oracle": 2.26
        },
        {
            "name": "Phantom Assassin",
            "Oracle": 0.727
        },
        {
            "name": "Phantom Lancer",
            "Oracle": 2.438
        },
        {
            "name": "Phoenix",
            "Oracle": -1.553
        },
        {
            "name": "Primal Beast",
            "Oracle": -0.402
        },
        {
            "name": "Puck",
            "Oracle": -0.922
        },
        {
            "name": "Pudge",
            "Oracle": 0.654
        },
        {
            "name": "Pugna",
            "Oracle": 0.766
        },
        {
            "name": "Queen of Pain",
            "Oracle": -1.556
        },
        {
            "name": "Razor",
            "Oracle": 3.109
        },
        {
            "name": "Riki",
            "Oracle": 2.593
        },
        {
            "name": "Ringmaster",
            "Oracle": -0.753
        },
        {
            "name": "Rubick",
            "Oracle": -0.954
        },
        {
            "name": "Sand King",
            "Oracle": -1.359
        },
        {
            "name": "Shadow Demon",
            "Oracle": 3.616
        },
        {
            "name": "Shadow Fiend",
            "Oracle": 2.399
        },
        {
            "name": "Shadow Shaman",
            "Oracle": 0.989
        },
        {
            "name": "Silencer",
            "Oracle": 0.433
        },
        {
            "name": "Skywrath Mage",
            "Oracle": -1.217
        },
        {
            "name": "Slardar",
            "Oracle": 1.258
        },
        {
            "name": "Slark",
            "Oracle": -0.049
        },
        {
            "name": "Snapfire",
            "Oracle": -0.23
        },
        {
            "name": "Sniper",
            "Oracle": -0.009
        },
        {
            "name": "Spectre",
            "Oracle": -2.146
        },
        {
            "name": "Spirit Breaker",
            "Oracle": -1.366
        },
        {
            "name": "Storm Spirit",
            "Oracle": -2.504
        },
        {
            "name": "Sven",
            "Oracle": 0.879
        },
        {
            "name": "Techies",
            "Oracle": -1.458
        },
        {
            "name": "Templar Assassin",
            "Oracle": 2.816
        },
        {
            "name": "Terrorblade",
            "Oracle": 1.574
        },
        {
            "name": "Tidehunter",
            "Oracle": 0.271
        },
        {
            "name": "Timbersaw",
            "Oracle": -0.489
        },
        {
            "name": "Tinker",
            "Oracle": 0.443
        },
        {
            "name": "Tiny",
            "Oracle": 0.141
        },
        {
            "name": "Treant Protector",
            "Oracle": 0.396
        },
        {
            "name": "Troll Warlord",
            "Oracle": -1.488
        },
        {
            "name": "Tusk",
            "Oracle": 2.459
        },
        {
            "name": "Underlord",
            "Oracle": -1.174
        },
        {
            "name": "Undying",
            "Oracle": 2.197
        },
        {
            "name": "Ursa",
            "Oracle": 0.473
        },
        {
            "name": "Vengeful Spirit",
            "Oracle": 1.165
        },
        {
            "name": "Venomancer",
            "Oracle": -1.044
        },
        {
            "name": "Viper",
            "Oracle": 0.518
        },
        {
            "name": "Visage",
            "Oracle": 1.832
        },
        {
            "name": "Void Spirit",
            "Oracle": -1.619
        },
        {
            "name": "Warlock",
            "Oracle": -0.717
        },
        {
            "name": "Weaver",
            "Oracle": 0.963
        },
        {
            "name": "Windranger",
            "Oracle": 0.409
        },
        {
            "name": "Winter Wyvern",
            "Oracle": -0.026
        },
        {
            "name": "Witch Doctor",
            "Oracle": -0.17
        },
        {
            "name": "Wraith King",
            "Oracle": -0.225
        },
        {
            "name": "Zeus",
            "Oracle": -1.279
        }
    ],
    "Outworld Destroyer": [
        {
            "name": "Abaddon",
            "Outworld Destroyer": -3.235
        },
        {
            "name": "Alchemist",
            "Outworld Destroyer": -0.409
        },
        {
            "name": "Ancient Apparition",
            "Outworld Destroyer": -1.996
        },
        {
            "name": "Anti-Mage",
            "Outworld Destroyer": 1.24
        },
        {
            "name": "Arc Warden",
            "Outworld Destroyer": 3.018
        },
        {
            "name": "Axe",
            "Outworld Destroyer": -3.279
        },
        {
            "name": "Bane",
            "Outworld Destroyer": 0.198
        },
        {
            "name": "Batrider",
            "Outworld Destroyer": -0.508
        },
        {
            "name": "Beastmaster",
            "Outworld Destroyer": 1.377
        },
        {
            "name": "Bloodseeker",
            "Outworld Destroyer": 0.416
        },
        {
            "name": "Bounty Hunter",
            "Outworld Destroyer": 0.286
        },
        {
            "name": "Brewmaster",
            "Outworld Destroyer": 2.13
        },
        {
            "name": "Bristleback",
            "Outworld Destroyer": 1.12
        },
        {
            "name": "Broodmother",
            "Outworld Destroyer": 5.132
        },
        {
            "name": "Centaur Warrunner",
            "Outworld Destroyer": -1.132
        },
        {
            "name": "Chaos Knight",
            "Outworld Destroyer": 2.0
        },
        {
            "name": "Chen",
            "Outworld Destroyer": 0.769
        },
        {
            "name": "Clinkz",
            "Outworld Destroyer": 1.009
        },
        {
            "name": "Clockwerk",
            "Outworld Destroyer": -0.551
        },
        {
            "name": "Crystal Maiden",
            "Outworld Destroyer": 0.157
        },
        {
            "name": "Dark Seer",
            "Outworld Destroyer": -0.313
        },
        {
            "name": "Dark Willow",
            "Outworld Destroyer": 0.956
        },
        {
            "name": "Dawnbreaker",
            "Outworld Destroyer": -0.323
        },
        {
            "name": "Dazzle",
            "Outworld Destroyer": -0.823
        },
        {
            "name": "Death Prophet",
            "Outworld Destroyer": 1.881
        },
        {
            "name": "Disruptor",
            "Outworld Destroyer": 0.192
        },
        {
            "name": "Doom",
            "Outworld Destroyer": 0.811
        },
        {
            "name": "Dragon Knight",
            "Outworld Destroyer": -1.55
        },
        {
            "name": "Drow Ranger",
            "Outworld Destroyer": -0.812
        },
        {
            "name": "Earth Spirit",
            "Outworld Destroyer": -0.361
        },
        {
            "name": "Earthshaker",
            "Outworld Destroyer": -0.225
        },
        {
            "name": "Elder Titan",
            "Outworld Destroyer": 0.025
        },
        {
            "name": "Ember Spirit",
            "Outworld Destroyer": 0.631
        },
        {
            "name": "Enchantress",
            "Outworld Destroyer": 1.003
        },
        {
            "name": "Enigma",
            "Outworld Destroyer": 2.173
        },
        {
            "name": "Faceless Void",
            "Outworld Destroyer": -1.191
        },
        {
            "name": "Grimstroke",
            "Outworld Destroyer": 0.231
        },
        {
            "name": "Gyrocopter",
            "Outworld Destroyer": -0.034
        },
        {
            "name": "Hoodwink",
            "Outworld Destroyer": 0.003
        },
        {
            "name": "Huskar",
            "Outworld Destroyer": 2.316
        },
        {
            "name": "Invoker",
            "Outworld Destroyer": -0.781
        },
        {
            "name": "Io",
            "Outworld Destroyer": -0.35
        },
        {
            "name": "Jakiro",
            "Outworld Destroyer": -1.016
        },
        {
            "name": "Juggernaut",
            "Outworld Destroyer": -0.808
        },
        {
            "name": "Keeper of the Light",
            "Outworld Destroyer": 1.049
        },
        {
            "name": "Kez",
            "Outworld Destroyer": -0.498
        },
        {
            "name": "Kunkka",
            "Outworld Destroyer": 0.536
        },
        {
            "name": "Legion Commander",
            "Outworld Destroyer": -1.701
        },
        {
            "name": "Leshrac",
            "Outworld Destroyer": -1.567
        },
        {
            "name": "Lich",
            "Outworld Destroyer": -0.972
        },
        {
            "name": "Lifestealer",
            "Outworld Destroyer": 1.061
        },
        {
            "name": "Lina",
            "Outworld Destroyer": -1.071
        },
        {
            "name": "Lion",
            "Outworld Destroyer": -1.361
        },
        {
            "name": "Lone Druid",
            "Outworld Destroyer": 1.552
        },
        {
            "name": "Luna",
            "Outworld Destroyer": 0.028
        },
        {
            "name": "Lycan",
            "Outworld Destroyer": 1.431
        },
        {
            "name": "Magnus",
            "Outworld Destroyer": 0.4
        },
        {
            "name": "Marci",
            "Outworld Destroyer": 1.094
        },
        {
            "name": "Mars",
            "Outworld Destroyer": 0.293
        },
        {
            "name": "Medusa",
            "Outworld Destroyer": 2.907
        },
        {
            "name": "Meepo",
            "Outworld Destroyer": 0.344
        },
        {
            "name": "Mirana",
            "Outworld Destroyer": -1.142
        },
        {
            "name": "Monkey King",
            "Outworld Destroyer": -1.038
        },
        {
            "name": "Morphling",
            "Outworld Destroyer": -0.817
        },
        {
            "name": "Muerta",
            "Outworld Destroyer": 0.696
        },
        {
            "name": "Naga Siren",
            "Outworld Destroyer": 1.856
        },
        {
            "name": "Nature's Prophet",
            "Outworld Destroyer": 1.155
        },
        {
            "name": "Necrophos",
            "Outworld Destroyer": -0.996
        },
        {
            "name": "Night Stalker",
            "Outworld Destroyer": 1.336
        },
        {
            "name": "Nyx Assassin",
            "Outworld Destroyer": 2.744
        },
        {
            "name": "Ogre Magi",
            "Outworld Destroyer": 0.962
        },
        {
            "name": "Omniknight",
            "Outworld Destroyer": -2.076
        },
        {
            "name": "Oracle",
            "Outworld Destroyer": -2.845
        },
        {
            "name": "Pangolier",
            "Outworld Destroyer": 1.39
        },
        {
            "name": "Phantom Assassin",
            "Outworld Destroyer": 1.197
        },
        {
            "name": "Phantom Lancer",
            "Outworld Destroyer": 3.387
        },
        {
            "name": "Phoenix",
            "Outworld Destroyer": 0.291
        },
        {
            "name": "Primal Beast",
            "Outworld Destroyer": -1.747
        },
        {
            "name": "Puck",
            "Outworld Destroyer": 1.89
        },
        {
            "name": "Pudge",
            "Outworld Destroyer": -0.123
        },
        {
            "name": "Pugna",
            "Outworld Destroyer": 0.878
        },
        {
            "name": "Queen of Pain",
            "Outworld Destroyer": 1.038
        },
        {
            "name": "Razor",
            "Outworld Destroyer": 0.654
        },
        {
            "name": "Riki",
            "Outworld Destroyer": 0.187
        },
        {
            "name": "Ringmaster",
            "Outworld Destroyer": 0.421
        },
        {
            "name": "Rubick",
            "Outworld Destroyer": 2.015
        },
        {
            "name": "Sand King",
            "Outworld Destroyer": 0.914
        },
        {
            "name": "Shadow Demon",
            "Outworld Destroyer": 1.538
        },
        {
            "name": "Shadow Fiend",
            "Outworld Destroyer": -0.599
        },
        {
            "name": "Shadow Shaman",
            "Outworld Destroyer": 1.658
        },
        {
            "name": "Silencer",
            "Outworld Destroyer": 2.262
        },
        {
            "name": "Skywrath Mage",
            "Outworld Destroyer": 0.616
        },
        {
            "name": "Slardar",
            "Outworld Destroyer": -0.129
        },
        {
            "name": "Slark",
            "Outworld Destroyer": -1.786
        },
        {
            "name": "Snapfire",
            "Outworld Destroyer": -1.308
        },
        {
            "name": "Sniper",
            "Outworld Destroyer": 0.425
        },
        {
            "name": "Spectre",
            "Outworld Destroyer": 0.139
        },
        {
            "name": "Spirit Breaker",
            "Outworld Destroyer": 0.008
        },
        {
            "name": "Storm Spirit",
            "Outworld Destroyer": 1.854
        },
        {
            "name": "Sven",
            "Outworld Destroyer": -0.015
        },
        {
            "name": "Techies",
            "Outworld Destroyer": -0.418
        },
        {
            "name": "Templar Assassin",
            "Outworld Destroyer": 3.442
        },
        {
            "name": "Terrorblade",
            "Outworld Destroyer": 0.02
        },
        {
            "name": "Tidehunter",
            "Outworld Destroyer": -2.542
        },
        {
            "name": "Timbersaw",
            "Outworld Destroyer": -4.058
        },
        {
            "name": "Tinker",
            "Outworld Destroyer": 1.43
        },
        {
            "name": "Tiny",
            "Outworld Destroyer": 0.068
        },
        {
            "name": "Treant Protector",
            "Outworld Destroyer": -0.327
        },
        {
            "name": "Troll Warlord",
            "Outworld Destroyer": -3.014
        },
        {
            "name": "Tusk",
            "Outworld Destroyer": 0.967
        },
        {
            "name": "Underlord",
            "Outworld Destroyer": -0.932
        },
        {
            "name": "Undying",
            "Outworld Destroyer": 0.192
        },
        {
            "name": "Ursa",
            "Outworld Destroyer": -0.061
        },
        {
            "name": "Vengeful Spirit",
            "Outworld Destroyer": -0.281
        },
        {
            "name": "Venomancer",
            "Outworld Destroyer": 0.678
        },
        {
            "name": "Viper",
            "Outworld Destroyer": -0.772
        },
        {
            "name": "Visage",
            "Outworld Destroyer": 3.257
        },
        {
            "name": "Void Spirit",
            "Outworld Destroyer": 0.452
        },
        {
            "name": "Warlock",
            "Outworld Destroyer": 0.138
        },
        {
            "name": "Weaver",
            "Outworld Destroyer": 0.266
        },
        {
            "name": "Windranger",
            "Outworld Destroyer": -0.078
        },
        {
            "name": "Winter Wyvern",
            "Outworld Destroyer": -1.546
        },
        {
            "name": "Witch Doctor",
            "Outworld Destroyer": -0.054
        },
        {
            "name": "Wraith King",
            "Outworld Destroyer": 1.96
        },
        {
            "name": "Zeus",
            "Outworld Destroyer": 0.833
        }
    ],
    "Puck": [
        {
            "name": "Abaddon",
            "Puck": 1.586
        },
        {
            "name": "Alchemist",
            "Puck": 1.814
        },
        {
            "name": "Ancient Apparition",
            "Puck": -2.336
        },
        {
            "name": "Anti-Mage",
            "Puck": -1.697
        },
        {
            "name": "Arc Warden",
            "Puck": 1.215
        },
        {
            "name": "Axe",
            "Puck": 0.869
        },
        {
            "name": "Bane",
            "Puck": 1.838
        },
        {
            "name": "Batrider",
            "Puck": -1.677
        },
        {
            "name": "Beastmaster",
            "Puck": 1.04
        },
        {
            "name": "Bloodseeker",
            "Puck": -0.457
        },
        {
            "name": "Bounty Hunter",
            "Puck": 0.381
        },
        {
            "name": "Brewmaster",
            "Puck": -1.82
        },
        {
            "name": "Bristleback",
            "Puck": -0.571
        },
        {
            "name": "Broodmother",
            "Puck": 4.023
        },
        {
            "name": "Centaur Warrunner",
            "Puck": 0.336
        },
        {
            "name": "Chaos Knight",
            "Puck": 0.021
        },
        {
            "name": "Chen",
            "Puck": 3.532
        },
        {
            "name": "Clinkz",
            "Puck": 0.314
        },
        {
            "name": "Clockwerk",
            "Puck": -1.191
        },
        {
            "name": "Crystal Maiden",
            "Puck": -1.273
        },
        {
            "name": "Dark Seer",
            "Puck": -0.364
        },
        {
            "name": "Dark Willow",
            "Puck": -1.361
        },
        {
            "name": "Dawnbreaker",
            "Puck": -0.212
        },
        {
            "name": "Dazzle",
            "Puck": -0.078
        },
        {
            "name": "Death Prophet",
            "Puck": 0.801
        },
        {
            "name": "Disruptor",
            "Puck": 0.82
        },
        {
            "name": "Doom",
            "Puck": -0.3
        },
        {
            "name": "Dragon Knight",
            "Puck": 2.013
        },
        {
            "name": "Drow Ranger",
            "Puck": -0.544
        },
        {
            "name": "Earth Spirit",
            "Puck": 1.061
        },
        {
            "name": "Earthshaker",
            "Puck": 0.332
        },
        {
            "name": "Elder Titan",
            "Puck": -0.843
        },
        {
            "name": "Ember Spirit",
            "Puck": -0.188
        },
        {
            "name": "Enchantress",
            "Puck": 0.225
        },
        {
            "name": "Enigma",
            "Puck": 0.531
        },
        {
            "name": "Faceless Void",
            "Puck": 0.444
        },
        {
            "name": "Grimstroke",
            "Puck": 0.85
        },
        {
            "name": "Gyrocopter",
            "Puck": 0.492
        },
        {
            "name": "Hoodwink",
            "Puck": -1.01
        },
        {
            "name": "Huskar",
            "Puck": 2.537
        },
        {
            "name": "Invoker",
            "Puck": -2.179
        },
        {
            "name": "Io",
            "Puck": 1.315
        },
        {
            "name": "Jakiro",
            "Puck": -0.025
        },
        {
            "name": "Juggernaut",
            "Puck": -0.935
        },
        {
            "name": "Keeper of the Light",
            "Puck": 0.517
        },
        {
            "name": "Kez",
            "Puck": -0.019
        },
        {
            "name": "Kunkka",
            "Puck": 0.065
        },
        {
            "name": "Legion Commander",
            "Puck": 1.774
        },
        {
            "name": "Leshrac",
            "Puck": 0.154
        },
        {
            "name": "Lich",
            "Puck": -0.684
        },
        {
            "name": "Lifestealer",
            "Puck": -0.329
        },
        {
            "name": "Lina",
            "Puck": -1.206
        },
        {
            "name": "Lion",
            "Puck": 1.183
        },
        {
            "name": "Lone Druid",
            "Puck": 2.79
        },
        {
            "name": "Luna",
            "Puck": 0.874
        },
        {
            "name": "Lycan",
            "Puck": 0.768
        },
        {
            "name": "Magnus",
            "Puck": -2.057
        },
        {
            "name": "Marci",
            "Puck": -0.189
        },
        {
            "name": "Mars",
            "Puck": -2.117
        },
        {
            "name": "Medusa",
            "Puck": 0.825
        },
        {
            "name": "Meepo",
            "Puck": 1.215
        },
        {
            "name": "Mirana",
            "Puck": -1.342
        },
        {
            "name": "Monkey King",
            "Puck": 1.114
        },
        {
            "name": "Morphling",
            "Puck": -0.442
        },
        {
            "name": "Muerta",
            "Puck": 2.004
        },
        {
            "name": "Naga Siren",
            "Puck": -1.718
        },
        {
            "name": "Nature's Prophet",
            "Puck": 1.765
        },
        {
            "name": "Necrophos",
            "Puck": -0.769
        },
        {
            "name": "Night Stalker",
            "Puck": 4.72
        },
        {
            "name": "Nyx Assassin",
            "Puck": 1.841
        },
        {
            "name": "Ogre Magi",
            "Puck": -0.46
        },
        {
            "name": "Omniknight",
            "Puck": 1.562
        },
        {
            "name": "Oracle",
            "Puck": 0.764
        },
        {
            "name": "Outworld Destroyer",
            "Puck": -1.873
        },
        {
            "name": "Pangolier",
            "Puck": -2.078
        },
        {
            "name": "Phantom Assassin",
            "Puck": -1.127
        },
        {
            "name": "Phantom Lancer",
            "Puck": -3.792
        },
        {
            "name": "Phoenix",
            "Puck": -1.069
        },
        {
            "name": "Primal Beast",
            "Puck": -1.054
        },
        {
            "name": "Pudge",
            "Puck": -1.424
        },
        {
            "name": "Pugna",
            "Puck": 1.463
        },
        {
            "name": "Queen of Pain",
            "Puck": -2.56
        },
        {
            "name": "Razor",
            "Puck": -0.918
        },
        {
            "name": "Riki",
            "Puck": 4.889
        },
        {
            "name": "Ringmaster",
            "Puck": -1.034
        },
        {
            "name": "Rubick",
            "Puck": 0.818
        },
        {
            "name": "Sand King",
            "Puck": -0.505
        },
        {
            "name": "Shadow Demon",
            "Puck": -1.461
        },
        {
            "name": "Shadow Fiend",
            "Puck": 1.251
        },
        {
            "name": "Shadow Shaman",
            "Puck": 1.052
        },
        {
            "name": "Silencer",
            "Puck": 2.051
        },
        {
            "name": "Skywrath Mage",
            "Puck": 0.39
        },
        {
            "name": "Slardar",
            "Puck": 0.036
        },
        {
            "name": "Slark",
            "Puck": -2.081
        },
        {
            "name": "Snapfire",
            "Puck": 0.001
        },
        {
            "name": "Sniper",
            "Puck": 1.29
        },
        {
            "name": "Spectre",
            "Puck": 2.653
        },
        {
            "name": "Spirit Breaker",
            "Puck": -0.157
        },
        {
            "name": "Storm Spirit",
            "Puck": -3.011
        },
        {
            "name": "Sven",
            "Puck": 1.706
        },
        {
            "name": "Techies",
            "Puck": 0.192
        },
        {
            "name": "Templar Assassin",
            "Puck": 1.554
        },
        {
            "name": "Terrorblade",
            "Puck": -0.766
        },
        {
            "name": "Tidehunter",
            "Puck": 0.793
        },
        {
            "name": "Timbersaw",
            "Puck": -1.417
        },
        {
            "name": "Tinker",
            "Puck": 1.623
        },
        {
            "name": "Tiny",
            "Puck": 2.188
        },
        {
            "name": "Treant Protector",
            "Puck": 0.204
        },
        {
            "name": "Troll Warlord",
            "Puck": 0.06
        },
        {
            "name": "Tusk",
            "Puck": -0.976
        },
        {
            "name": "Underlord",
            "Puck": 0.121
        },
        {
            "name": "Undying",
            "Puck": 1.367
        },
        {
            "name": "Ursa",
            "Puck": 0.552
        },
        {
            "name": "Vengeful Spirit",
            "Puck": -0.918
        },
        {
            "name": "Venomancer",
            "Puck": -0.628
        },
        {
            "name": "Viper",
            "Puck": -0.807
        },
        {
            "name": "Visage",
            "Puck": -0.712
        },
        {
            "name": "Void Spirit",
            "Puck": 1.642
        },
        {
            "name": "Warlock",
            "Puck": -0.523
        },
        {
            "name": "Weaver",
            "Puck": -1.507
        },
        {
            "name": "Windranger",
            "Puck": -1.023
        },
        {
            "name": "Winter Wyvern",
            "Puck": 0.594
        },
        {
            "name": "Witch Doctor",
            "Puck": -1.455
        },
        {
            "name": "Wraith King",
            "Puck": 1.378
        },
        {
            "name": "Zeus",
            "Puck": 1.073
        }
    ],
    "Pugna": [
        {
            "name": "Abaddon",
            "Pugna": -1.058
        },
        {
            "name": "Alchemist",
            "Pugna": 0.912
        },
        {
            "name": "Ancient Apparition",
            "Pugna": 1.07
        },
        {
            "name": "Anti-Mage",
            "Pugna": 2.278
        },
        {
            "name": "Arc Warden",
            "Pugna": -1.112
        },
        {
            "name": "Axe",
            "Pugna": -1.103
        },
        {
            "name": "Bane",
            "Pugna": 1.079
        },
        {
            "name": "Batrider",
            "Pugna": -1.327
        },
        {
            "name": "Beastmaster",
            "Pugna": -0.808
        },
        {
            "name": "Bloodseeker",
            "Pugna": 0.718
        },
        {
            "name": "Bounty Hunter",
            "Pugna": 0.994
        },
        {
            "name": "Brewmaster",
            "Pugna": -0.841
        },
        {
            "name": "Bristleback",
            "Pugna": 0.761
        },
        {
            "name": "Broodmother",
            "Pugna": 0.487
        },
        {
            "name": "Centaur Warrunner",
            "Pugna": 0.759
        },
        {
            "name": "Chaos Knight",
            "Pugna": 2.295
        },
        {
            "name": "Chen",
            "Pugna": -0.959
        },
        {
            "name": "Clinkz",
            "Pugna": 0.601
        },
        {
            "name": "Clockwerk",
            "Pugna": 0.171
        },
        {
            "name": "Crystal Maiden",
            "Pugna": -0.937
        },
        {
            "name": "Dark Seer",
            "Pugna": -0.21
        },
        {
            "name": "Dark Willow",
            "Pugna": -0.884
        },
        {
            "name": "Dawnbreaker",
            "Pugna": -0.431
        },
        {
            "name": "Dazzle",
            "Pugna": -0.668
        },
        {
            "name": "Death Prophet",
            "Pugna": 0.635
        },
        {
            "name": "Disruptor",
            "Pugna": -1.294
        },
        {
            "name": "Doom",
            "Pugna": 1.712
        },
        {
            "name": "Dragon Knight",
            "Pugna": -0.115
        },
        {
            "name": "Drow Ranger",
            "Pugna": 1.391
        },
        {
            "name": "Earth Spirit",
            "Pugna": -0.446
        },
        {
            "name": "Earthshaker",
            "Pugna": 1.352
        },
        {
            "name": "Elder Titan",
            "Pugna": 0.983
        },
        {
            "name": "Ember Spirit",
            "Pugna": -1.332
        },
        {
            "name": "Enchantress",
            "Pugna": 1.196
        },
        {
            "name": "Enigma",
            "Pugna": 0.984
        },
        {
            "name": "Faceless Void",
            "Pugna": 1.371
        },
        {
            "name": "Grimstroke",
            "Pugna": -0.154
        },
        {
            "name": "Gyrocopter",
            "Pugna": -0.167
        },
        {
            "name": "Hoodwink",
            "Pugna": -0.179
        },
        {
            "name": "Huskar",
            "Pugna": 3.886
        },
        {
            "name": "Invoker",
            "Pugna": -0.391
        },
        {
            "name": "Io",
            "Pugna": 0.465
        },
        {
            "name": "Jakiro",
            "Pugna": -0.73
        },
        {
            "name": "Juggernaut",
            "Pugna": -0.27
        },
        {
            "name": "Keeper of the Light",
            "Pugna": 0.863
        },
        {
            "name": "Kez",
            "Pugna": -1.152
        },
        {
            "name": "Kunkka",
            "Pugna": -0.074
        },
        {
            "name": "Legion Commander",
            "Pugna": -0.747
        },
        {
            "name": "Leshrac",
            "Pugna": 0.131
        },
        {
            "name": "Lich",
            "Pugna": -1.898
        },
        {
            "name": "Lifestealer",
            "Pugna": 0.012
        },
        {
            "name": "Lina",
            "Pugna": 0.731
        },
        {
            "name": "Lion",
            "Pugna": -0.081
        },
        {
            "name": "Lone Druid",
            "Pugna": 1.732
        },
        {
            "name": "Luna",
            "Pugna": 0.614
        },
        {
            "name": "Lycan",
            "Pugna": -0.848
        },
        {
            "name": "Magnus",
            "Pugna": 1.407
        },
        {
            "name": "Marci",
            "Pugna": 0.783
        },
        {
            "name": "Mars",
            "Pugna": -1.063
        },
        {
            "name": "Medusa",
            "Pugna": 1.85
        },
        {
            "name": "Meepo",
            "Pugna": 0.166
        },
        {
            "name": "Mirana",
            "Pugna": -0.106
        },
        {
            "name": "Monkey King",
            "Pugna": -0.972
        },
        {
            "name": "Morphling",
            "Pugna": 0.024
        },
        {
            "name": "Muerta",
            "Pugna": -1.204
        },
        {
            "name": "Naga Siren",
            "Pugna": -2.553
        },
        {
            "name": "Nature's Prophet",
            "Pugna": 0.335
        },
        {
            "name": "Necrophos",
            "Pugna": -3.096
        },
        {
            "name": "Night Stalker",
            "Pugna": 1.473
        },
        {
            "name": "Nyx Assassin",
            "Pugna": 1.493
        },
        {
            "name": "Ogre Magi",
            "Pugna": 0.759
        },
        {
            "name": "Omniknight",
            "Pugna": -1.726
        },
        {
            "name": "Oracle",
            "Pugna": -0.823
        },
        {
            "name": "Outworld Destroyer",
            "Pugna": -0.867
        },
        {
            "name": "Pangolier",
            "Pugna": -0.679
        },
        {
            "name": "Phantom Assassin",
            "Pugna": 0.011
        },
        {
            "name": "Phantom Lancer",
            "Pugna": -1.477
        },
        {
            "name": "Phoenix",
            "Pugna": -0.074
        },
        {
            "name": "Primal Beast",
            "Pugna": 0.904
        },
        {
            "name": "Puck",
            "Pugna": -1.536
        },
        {
            "name": "Pudge",
            "Pugna": 2.854
        },
        {
            "name": "Queen of Pain",
            "Pugna": -1.466
        },
        {
            "name": "Razor",
            "Pugna": -0.786
        },
        {
            "name": "Riki",
            "Pugna": 0.908
        },
        {
            "name": "Ringmaster",
            "Pugna": -0.736
        },
        {
            "name": "Rubick",
            "Pugna": -0.471
        },
        {
            "name": "Sand King",
            "Pugna": -1.621
        },
        {
            "name": "Shadow Demon",
            "Pugna": -1.905
        },
        {
            "name": "Shadow Fiend",
            "Pugna": 1.116
        },
        {
            "name": "Shadow Shaman",
            "Pugna": 0.434
        },
        {
            "name": "Silencer",
            "Pugna": 1.639
        },
        {
            "name": "Skywrath Mage",
            "Pugna": -0.307
        },
        {
            "name": "Slardar",
            "Pugna": 1.55
        },
        {
            "name": "Slark",
            "Pugna": -2.41
        },
        {
            "name": "Snapfire",
            "Pugna": -0.011
        },
        {
            "name": "Sniper",
            "Pugna": 1.81
        },
        {
            "name": "Spectre",
            "Pugna": -0.42
        },
        {
            "name": "Spirit Breaker",
            "Pugna": 1.509
        },
        {
            "name": "Storm Spirit",
            "Pugna": -3.177
        },
        {
            "name": "Sven",
            "Pugna": 2.208
        },
        {
            "name": "Techies",
            "Pugna": -0.401
        },
        {
            "name": "Templar Assassin",
            "Pugna": -1.584
        },
        {
            "name": "Terrorblade",
            "Pugna": 0.883
        },
        {
            "name": "Tidehunter",
            "Pugna": -2.984
        },
        {
            "name": "Timbersaw",
            "Pugna": -4.076
        },
        {
            "name": "Tinker",
            "Pugna": -3.097
        },
        {
            "name": "Tiny",
            "Pugna": -0.059
        },
        {
            "name": "Treant Protector",
            "Pugna": -0.482
        },
        {
            "name": "Troll Warlord",
            "Pugna": -2.058
        },
        {
            "name": "Tusk",
            "Pugna": -1.027
        },
        {
            "name": "Underlord",
            "Pugna": -1.631
        },
        {
            "name": "Undying",
            "Pugna": -0.59
        },
        {
            "name": "Ursa",
            "Pugna": 0.469
        },
        {
            "name": "Vengeful Spirit",
            "Pugna": 0.111
        },
        {
            "name": "Venomancer",
            "Pugna": -0.698
        },
        {
            "name": "Viper",
            "Pugna": 0.351
        },
        {
            "name": "Visage",
            "Pugna": 0.253
        },
        {
            "name": "Void Spirit",
            "Pugna": -0.952
        },
        {
            "name": "Warlock",
            "Pugna": 0.225
        },
        {
            "name": "Weaver",
            "Pugna": 0.512
        },
        {
            "name": "Windranger",
            "Pugna": -0.559
        },
        {
            "name": "Winter Wyvern",
            "Pugna": -3.736
        },
        {
            "name": "Witch Doctor",
            "Pugna": -0.299
        },
        {
            "name": "Wraith King",
            "Pugna": 0.503
        },
        {
            "name": "Zeus",
            "Pugna": -0.565
        }
    ],
    "Queen of Pain": [
        {
            "name": "Abaddon",
            "Queen of Pain": 1.037
        },
        {
            "name": "Alchemist",
            "Queen of Pain": -1.359
        },
        {
            "name": "Ancient Apparition",
            "Queen of Pain": -0.848
        },
        {
            "name": "Anti-Mage",
            "Queen of Pain": 1.564
        },
        {
            "name": "Arc Warden",
            "Queen of Pain": 1.472
        },
        {
            "name": "Axe",
            "Queen of Pain": -1.484
        },
        {
            "name": "Bane",
            "Queen of Pain": 0.921
        },
        {
            "name": "Batrider",
            "Queen of Pain": -0.856
        },
        {
            "name": "Beastmaster",
            "Queen of Pain": -0.056
        },
        {
            "name": "Bloodseeker",
            "Queen of Pain": -0.565
        },
        {
            "name": "Bounty Hunter",
            "Queen of Pain": -0.137
        },
        {
            "name": "Brewmaster",
            "Queen of Pain": -0.499
        },
        {
            "name": "Bristleback",
            "Queen of Pain": 0.047
        },
        {
            "name": "Broodmother",
            "Queen of Pain": 0.297
        },
        {
            "name": "Centaur Warrunner",
            "Queen of Pain": -0.465
        },
        {
            "name": "Chaos Knight",
            "Queen of Pain": 0.907
        },
        {
            "name": "Chen",
            "Queen of Pain": 1.238
        },
        {
            "name": "Clinkz",
            "Queen of Pain": 0.566
        },
        {
            "name": "Clockwerk",
            "Queen of Pain": -1.903
        },
        {
            "name": "Crystal Maiden",
            "Queen of Pain": -0.263
        },
        {
            "name": "Dark Seer",
            "Queen of Pain": -1.182
        },
        {
            "name": "Dark Willow",
            "Queen of Pain": -0.728
        },
        {
            "name": "Dawnbreaker",
            "Queen of Pain": -0.49
        },
        {
            "name": "Dazzle",
            "Queen of Pain": -0.572
        },
        {
            "name": "Death Prophet",
            "Queen of Pain": -0.764
        },
        {
            "name": "Disruptor",
            "Queen of Pain": 0.284
        },
        {
            "name": "Doom",
            "Queen of Pain": 0.104
        },
        {
            "name": "Dragon Knight",
            "Queen of Pain": 0.237
        },
        {
            "name": "Drow Ranger",
            "Queen of Pain": -0.244
        },
        {
            "name": "Earth Spirit",
            "Queen of Pain": 0.334
        },
        {
            "name": "Earthshaker",
            "Queen of Pain": 0.121
        },
        {
            "name": "Elder Titan",
            "Queen of Pain": 0.903
        },
        {
            "name": "Ember Spirit",
            "Queen of Pain": 3.739
        },
        {
            "name": "Enchantress",
            "Queen of Pain": 0.055
        },
        {
            "name": "Enigma",
            "Queen of Pain": 0.81
        },
        {
            "name": "Faceless Void",
            "Queen of Pain": 1.609
        },
        {
            "name": "Grimstroke",
            "Queen of Pain": 0.802
        },
        {
            "name": "Gyrocopter",
            "Queen of Pain": -0.322
        },
        {
            "name": "Hoodwink",
            "Queen of Pain": 0.043
        },
        {
            "name": "Huskar",
            "Queen of Pain": 2.734
        },
        {
            "name": "Invoker",
            "Queen of Pain": 0.068
        },
        {
            "name": "Io",
            "Queen of Pain": 0.637
        },
        {
            "name": "Jakiro",
            "Queen of Pain": -0.435
        },
        {
            "name": "Juggernaut",
            "Queen of Pain": -0.835
        },
        {
            "name": "Keeper of the Light",
            "Queen of Pain": -0.98
        },
        {
            "name": "Kez",
            "Queen of Pain": 0.413
        },
        {
            "name": "Kunkka",
            "Queen of Pain": -0.188
        },
        {
            "name": "Legion Commander",
            "Queen of Pain": 1.56
        },
        {
            "name": "Leshrac",
            "Queen of Pain": -1.562
        },
        {
            "name": "Lich",
            "Queen of Pain": -1.069
        },
        {
            "name": "Lifestealer",
            "Queen of Pain": -1.411
        },
        {
            "name": "Lina",
            "Queen of Pain": 0.211
        },
        {
            "name": "Lion",
            "Queen of Pain": 0.36
        },
        {
            "name": "Lone Druid",
            "Queen of Pain": 2.198
        },
        {
            "name": "Luna",
            "Queen of Pain": -0.497
        },
        {
            "name": "Lycan",
            "Queen of Pain": -0.059
        },
        {
            "name": "Magnus",
            "Queen of Pain": -0.39
        },
        {
            "name": "Marci",
            "Queen of Pain": 0.009
        },
        {
            "name": "Mars",
            "Queen of Pain": -1.802
        },
        {
            "name": "Medusa",
            "Queen of Pain": 3.815
        },
        {
            "name": "Meepo",
            "Queen of Pain": 3.444
        },
        {
            "name": "Mirana",
            "Queen of Pain": -0.433
        },
        {
            "name": "Monkey King",
            "Queen of Pain": -1.114
        },
        {
            "name": "Morphling",
            "Queen of Pain": 1.43
        },
        {
            "name": "Muerta",
            "Queen of Pain": 0.46
        },
        {
            "name": "Naga Siren",
            "Queen of Pain": -0.524
        },
        {
            "name": "Nature's Prophet",
            "Queen of Pain": 0.474
        },
        {
            "name": "Necrophos",
            "Queen of Pain": -0.773
        },
        {
            "name": "Night Stalker",
            "Queen of Pain": 1.08
        },
        {
            "name": "Nyx Assassin",
            "Queen of Pain": 0.717
        },
        {
            "name": "Ogre Magi",
            "Queen of Pain": -0.176
        },
        {
            "name": "Omniknight",
            "Queen of Pain": -0.949
        },
        {
            "name": "Oracle",
            "Queen of Pain": 1.209
        },
        {
            "name": "Outworld Destroyer",
            "Queen of Pain": -1.18
        },
        {
            "name": "Pangolier",
            "Queen of Pain": -0.416
        },
        {
            "name": "Phantom Assassin",
            "Queen of Pain": -1.408
        },
        {
            "name": "Phantom Lancer",
            "Queen of Pain": -0.054
        },
        {
            "name": "Phoenix",
            "Queen of Pain": -0.795
        },
        {
            "name": "Primal Beast",
            "Queen of Pain": -1.084
        },
        {
            "name": "Puck",
            "Queen of Pain": 2.403
        },
        {
            "name": "Pudge",
            "Queen of Pain": -0.803
        },
        {
            "name": "Pugna",
            "Queen of Pain": 1.348
        },
        {
            "name": "Razor",
            "Queen of Pain": -3.548
        },
        {
            "name": "Riki",
            "Queen of Pain": 1.439
        },
        {
            "name": "Ringmaster",
            "Queen of Pain": -0.887
        },
        {
            "name": "Rubick",
            "Queen of Pain": -0.565
        },
        {
            "name": "Sand King",
            "Queen of Pain": -1.705
        },
        {
            "name": "Shadow Demon",
            "Queen of Pain": -0.802
        },
        {
            "name": "Shadow Fiend",
            "Queen of Pain": 0.411
        },
        {
            "name": "Shadow Shaman",
            "Queen of Pain": 0.757
        },
        {
            "name": "Silencer",
            "Queen of Pain": 0.481
        },
        {
            "name": "Skywrath Mage",
            "Queen of Pain": 0.447
        },
        {
            "name": "Slardar",
            "Queen of Pain": -0.058
        },
        {
            "name": "Slark",
            "Queen of Pain": -0.149
        },
        {
            "name": "Snapfire",
            "Queen of Pain": -0.289
        },
        {
            "name": "Sniper",
            "Queen of Pain": -0.568
        },
        {
            "name": "Spectre",
            "Queen of Pain": -0.49
        },
        {
            "name": "Spirit Breaker",
            "Queen of Pain": -0.397
        },
        {
            "name": "Storm Spirit",
            "Queen of Pain": 0.07
        },
        {
            "name": "Sven",
            "Queen of Pain": -0.336
        },
        {
            "name": "Techies",
            "Queen of Pain": -0.153
        },
        {
            "name": "Templar Assassin",
            "Queen of Pain": 1.355
        },
        {
            "name": "Terrorblade",
            "Queen of Pain": 0.111
        },
        {
            "name": "Tidehunter",
            "Queen of Pain": -0.16
        },
        {
            "name": "Timbersaw",
            "Queen of Pain": -1.896
        },
        {
            "name": "Tinker",
            "Queen of Pain": 0.527
        },
        {
            "name": "Tiny",
            "Queen of Pain": -0.518
        },
        {
            "name": "Treant Protector",
            "Queen of Pain": -0.348
        },
        {
            "name": "Troll Warlord",
            "Queen of Pain": -0.223
        },
        {
            "name": "Tusk",
            "Queen of Pain": -0.034
        },
        {
            "name": "Underlord",
            "Queen of Pain": 0.029
        },
        {
            "name": "Undying",
            "Queen of Pain": 0.06
        },
        {
            "name": "Ursa",
            "Queen of Pain": 0.3
        },
        {
            "name": "Vengeful Spirit",
            "Queen of Pain": 0.114
        },
        {
            "name": "Venomancer",
            "Queen of Pain": -0.672
        },
        {
            "name": "Viper",
            "Queen of Pain": 0.209
        },
        {
            "name": "Visage",
            "Queen of Pain": 0.191
        },
        {
            "name": "Void Spirit",
            "Queen of Pain": 1.415
        },
        {
            "name": "Warlock",
            "Queen of Pain": -0.248
        },
        {
            "name": "Weaver",
            "Queen of Pain": -0.174
        },
        {
            "name": "Windranger",
            "Queen of Pain": -1.096
        },
        {
            "name": "Winter Wyvern",
            "Queen of Pain": -1.025
        },
        {
            "name": "Witch Doctor",
            "Queen of Pain": 1.074
        },
        {
            "name": "Wraith King",
            "Queen of Pain": 0.038
        },
        {
            "name": "Zeus",
            "Queen of Pain": -1.283
        }
    ],
    "Ringmaster": [
        {
            "name": "Abaddon",
            "Ringmaster": 0.015
        },
        {
            "name": "Alchemist",
            "Ringmaster": 0.554
        },
        {
            "name": "Ancient Apparition",
            "Ringmaster": 0.355
        },
        {
            "name": "Anti-Mage",
            "Ringmaster": 0.698
        },
        {
            "name": "Arc Warden",
            "Ringmaster": -0.892
        },
        {
            "name": "Axe",
            "Ringmaster": -1.246
        },
        {
            "name": "Bane",
            "Ringmaster": 0.302
        },
        {
            "name": "Batrider",
            "Ringmaster": 1.298
        },
        {
            "name": "Beastmaster",
            "Ringmaster": -0.11
        },
        {
            "name": "Bloodseeker",
            "Ringmaster": 2.08
        },
        {
            "name": "Bounty Hunter",
            "Ringmaster": 0.644
        },
        {
            "name": "Brewmaster",
            "Ringmaster": 2.083
        },
        {
            "name": "Bristleback",
            "Ringmaster": 1.212
        },
        {
            "name": "Broodmother",
            "Ringmaster": 0.202
        },
        {
            "name": "Centaur Warrunner",
            "Ringmaster": 0.16
        },
        {
            "name": "Chaos Knight",
            "Ringmaster": -1.112
        },
        {
            "name": "Chen",
            "Ringmaster": 0.14
        },
        {
            "name": "Clinkz",
            "Ringmaster": -0.141
        },
        {
            "name": "Clockwerk",
            "Ringmaster": -0.734
        },
        {
            "name": "Crystal Maiden",
            "Ringmaster": 0.029
        },
        {
            "name": "Dark Seer",
            "Ringmaster": 0.276
        },
        {
            "name": "Dark Willow",
            "Ringmaster": -0.633
        },
        {
            "name": "Dawnbreaker",
            "Ringmaster": -0.751
        },
        {
            "name": "Dazzle",
            "Ringmaster": 0.241
        },
        {
            "name": "Death Prophet",
            "Ringmaster": 0.668
        },
        {
            "name": "Disruptor",
            "Ringmaster": -0.773
        },
        {
            "name": "Doom",
            "Ringmaster": 0.813
        },
        {
            "name": "Dragon Knight",
            "Ringmaster": -1.639
        },
        {
            "name": "Drow Ranger",
            "Ringmaster": -0.703
        },
        {
            "name": "Earth Spirit",
            "Ringmaster": 1.311
        },
        {
            "name": "Earthshaker",
            "Ringmaster": 0.636
        },
        {
            "name": "Elder Titan",
            "Ringmaster": 0.308
        },
        {
            "name": "Ember Spirit",
            "Ringmaster": 0.983
        },
        {
            "name": "Enchantress",
            "Ringmaster": 0.874
        },
        {
            "name": "Enigma",
            "Ringmaster": 0.291
        },
        {
            "name": "Faceless Void",
            "Ringmaster": 0.593
        },
        {
            "name": "Grimstroke",
            "Ringmaster": -0.644
        },
        {
            "name": "Gyrocopter",
            "Ringmaster": -0.685
        },
        {
            "name": "Hoodwink",
            "Ringmaster": 0.171
        },
        {
            "name": "Huskar",
            "Ringmaster": 0.244
        },
        {
            "name": "Invoker",
            "Ringmaster": -0.196
        },
        {
            "name": "Io",
            "Ringmaster": 0.143
        },
        {
            "name": "Jakiro",
            "Ringmaster": -0.953
        },
        {
            "name": "Juggernaut",
            "Ringmaster": 0.682
        },
        {
            "name": "Keeper of the Light",
            "Ringmaster": 1.91
        },
        {
            "name": "Kez",
            "Ringmaster": 1.378
        },
        {
            "name": "Kunkka",
            "Ringmaster": -0.064
        },
        {
            "name": "Legion Commander",
            "Ringmaster": -1.95
        },
        {
            "name": "Leshrac",
            "Ringmaster": 0.769
        },
        {
            "name": "Lich",
            "Ringmaster": -0.857
        },
        {
            "name": "Lifestealer",
            "Ringmaster": 0.015
        },
        {
            "name": "Lina",
            "Ringmaster": 0.367
        },
        {
            "name": "Lion",
            "Ringmaster": -0.37
        },
        {
            "name": "Lone Druid",
            "Ringmaster": -0.528
        },
        {
            "name": "Luna",
            "Ringmaster": -0.763
        },
        {
            "name": "Lycan",
            "Ringmaster": 1.254
        },
        {
            "name": "Magnus",
            "Ringmaster": 0.299
        },
        {
            "name": "Marci",
            "Ringmaster": -0.396
        },
        {
            "name": "Mars",
            "Ringmaster": -0.952
        },
        {
            "name": "Medusa",
            "Ringmaster": -0.297
        },
        {
            "name": "Meepo",
            "Ringmaster": -1.831
        },
        {
            "name": "Mirana",
            "Ringmaster": -0.972
        },
        {
            "name": "Monkey King",
            "Ringmaster": -1.049
        },
        {
            "name": "Morphling",
            "Ringmaster": 0.182
        },
        {
            "name": "Muerta",
            "Ringmaster": -1.182
        },
        {
            "name": "Naga Siren",
            "Ringmaster": 1.925
        },
        {
            "name": "Nature's Prophet",
            "Ringmaster": 1.075
        },
        {
            "name": "Necrophos",
            "Ringmaster": -0.146
        },
        {
            "name": "Night Stalker",
            "Ringmaster": 0.81
        },
        {
            "name": "Nyx Assassin",
            "Ringmaster": 0.588
        },
        {
            "name": "Ogre Magi",
            "Ringmaster": -0.165
        },
        {
            "name": "Omniknight",
            "Ringmaster": 0.011
        },
        {
            "name": "Oracle",
            "Ringmaster": 0.79
        },
        {
            "name": "Outworld Destroyer",
            "Ringmaster": -0.33
        },
        {
            "name": "Pangolier",
            "Ringmaster": 0.927
        },
        {
            "name": "Phantom Assassin",
            "Ringmaster": 1.536
        },
        {
            "name": "Phantom Lancer",
            "Ringmaster": 1.162
        },
        {
            "name": "Phoenix",
            "Ringmaster": 0.266
        },
        {
            "name": "Primal Beast",
            "Ringmaster": -0.043
        },
        {
            "name": "Puck",
            "Ringmaster": 1.096
        },
        {
            "name": "Pudge",
            "Ringmaster": -0.738
        },
        {
            "name": "Pugna",
            "Ringmaster": 0.758
        },
        {
            "name": "Queen of Pain",
            "Ringmaster": 1.092
        },
        {
            "name": "Razor",
            "Ringmaster": -0.317
        },
        {
            "name": "Riki",
            "Ringmaster": 0.65
        },
        {
            "name": "Rubick",
            "Ringmaster": -0.343
        },
        {
            "name": "Sand King",
            "Ringmaster": -0.113
        },
        {
            "name": "Shadow Demon",
            "Ringmaster": 1.444
        },
        {
            "name": "Shadow Fiend",
            "Ringmaster": -0.71
        },
        {
            "name": "Shadow Shaman",
            "Ringmaster": -0.161
        },
        {
            "name": "Silencer",
            "Ringmaster": -0.684
        },
        {
            "name": "Skywrath Mage",
            "Ringmaster": -0.704
        },
        {
            "name": "Slardar",
            "Ringmaster": -0.564
        },
        {
            "name": "Slark",
            "Ringmaster": -2.222
        },
        {
            "name": "Snapfire",
            "Ringmaster": 0.251
        },
        {
            "name": "Sniper",
            "Ringmaster": -0.01
        },
        {
            "name": "Spectre",
            "Ringmaster": 0.099
        },
        {
            "name": "Spirit Breaker",
            "Ringmaster": -0.276
        },
        {
            "name": "Storm Spirit",
            "Ringmaster": 0.396
        },
        {
            "name": "Sven",
            "Ringmaster": -0.157
        },
        {
            "name": "Techies",
            "Ringmaster": 0.495
        },
        {
            "name": "Templar Assassin",
            "Ringmaster": -0.116
        },
        {
            "name": "Terrorblade",
            "Ringmaster": -0.54
        },
        {
            "name": "Tidehunter",
            "Ringmaster": -0.22
        },
        {
            "name": "Timbersaw",
            "Ringmaster": 1.09
        },
        {
            "name": "Tinker",
            "Ringmaster": 1.054
        },
        {
            "name": "Tiny",
            "Ringmaster": -0.86
        },
        {
            "name": "Treant Protector",
            "Ringmaster": -1.012
        },
        {
            "name": "Troll Warlord",
            "Ringmaster": -0.819
        },
        {
            "name": "Tusk",
            "Ringmaster": -0.161
        },
        {
            "name": "Underlord",
            "Ringmaster": -0.834
        },
        {
            "name": "Undying",
            "Ringmaster": 0.681
        },
        {
            "name": "Ursa",
            "Ringmaster": 0.751
        },
        {
            "name": "Vengeful Spirit",
            "Ringmaster": 0.163
        },
        {
            "name": "Venomancer",
            "Ringmaster": 0.478
        },
        {
            "name": "Viper",
            "Ringmaster": 0.038
        },
        {
            "name": "Visage",
            "Ringmaster": -0.083
        },
        {
            "name": "Void Spirit",
            "Ringmaster": 0.952
        },
        {
            "name": "Warlock",
            "Ringmaster": 0.072
        },
        {
            "name": "Weaver",
            "Ringmaster": 0.291
        },
        {
            "name": "Windranger",
            "Ringmaster": -0.004
        },
        {
            "name": "Winter Wyvern",
            "Ringmaster": 0.646
        },
        {
            "name": "Witch Doctor",
            "Ringmaster": -0.637
        },
        {
            "name": "Wraith King",
            "Ringmaster": 0.912
        },
        {
            "name": "Zeus",
            "Ringmaster": 0.212
        }
    ],
    "Rubick": [
        {
            "name": "Abaddon",
            "Rubick": -2.143
        },
        {
            "name": "Alchemist",
            "Rubick": 0.057
        },
        {
            "name": "Ancient Apparition",
            "Rubick": -0.233
        },
        {
            "name": "Anti-Mage",
            "Rubick": 0.066
        },
        {
            "name": "Arc Warden",
            "Rubick": -1.172
        },
        {
            "name": "Axe",
            "Rubick": 0.59
        },
        {
            "name": "Bane",
            "Rubick": 0.051
        },
        {
            "name": "Batrider",
            "Rubick": 1.884
        },
        {
            "name": "Beastmaster",
            "Rubick": -1.254
        },
        {
            "name": "Bloodseeker",
            "Rubick": -0.767
        },
        {
            "name": "Bounty Hunter",
            "Rubick": -0.497
        },
        {
            "name": "Brewmaster",
            "Rubick": 0.066
        },
        {
            "name": "Bristleback",
            "Rubick": 0.164
        },
        {
            "name": "Broodmother",
            "Rubick": 0.166
        },
        {
            "name": "Centaur Warrunner",
            "Rubick": -0.274
        },
        {
            "name": "Chaos Knight",
            "Rubick": -0.362
        },
        {
            "name": "Chen",
            "Rubick": 1.067
        },
        {
            "name": "Clinkz",
            "Rubick": 0.954
        },
        {
            "name": "Clockwerk",
            "Rubick": -0.052
        },
        {
            "name": "Crystal Maiden",
            "Rubick": -2.405
        },
        {
            "name": "Dark Seer",
            "Rubick": -0.896
        },
        {
            "name": "Dark Willow",
            "Rubick": -0.027
        },
        {
            "name": "Dawnbreaker",
            "Rubick": -1.104
        },
        {
            "name": "Dazzle",
            "Rubick": 0.89
        },
        {
            "name": "Death Prophet",
            "Rubick": -0.198
        },
        {
            "name": "Disruptor",
            "Rubick": 0.342
        },
        {
            "name": "Doom",
            "Rubick": -0.922
        },
        {
            "name": "Dragon Knight",
            "Rubick": -0.576
        },
        {
            "name": "Drow Ranger",
            "Rubick": 0.35
        },
        {
            "name": "Earth Spirit",
            "Rubick": 1.231
        },
        {
            "name": "Earthshaker",
            "Rubick": 0.41
        },
        {
            "name": "Elder Titan",
            "Rubick": 1.128
        },
        {
            "name": "Ember Spirit",
            "Rubick": 1.384
        },
        {
            "name": "Enchantress",
            "Rubick": 0.221
        },
        {
            "name": "Enigma",
            "Rubick": -4.302
        },
        {
            "name": "Faceless Void",
            "Rubick": -0.454
        },
        {
            "name": "Grimstroke",
            "Rubick": -0.189
        },
        {
            "name": "Gyrocopter",
            "Rubick": 0.258
        },
        {
            "name": "Hoodwink",
            "Rubick": 1.236
        },
        {
            "name": "Huskar",
            "Rubick": 0.917
        },
        {
            "name": "Invoker",
            "Rubick": 0.229
        },
        {
            "name": "Io",
            "Rubick": 0.47
        },
        {
            "name": "Jakiro",
            "Rubick": -3.771
        },
        {
            "name": "Juggernaut",
            "Rubick": -0.058
        },
        {
            "name": "Keeper of the Light",
            "Rubick": 0.978
        },
        {
            "name": "Kez",
            "Rubick": 0.742
        },
        {
            "name": "Kunkka",
            "Rubick": -1.196
        },
        {
            "name": "Legion Commander",
            "Rubick": 0.693
        },
        {
            "name": "Leshrac",
            "Rubick": -0.342
        },
        {
            "name": "Lich",
            "Rubick": -1.485
        },
        {
            "name": "Lifestealer",
            "Rubick": 0.4
        },
        {
            "name": "Lina",
            "Rubick": -1.231
        },
        {
            "name": "Lion",
            "Rubick": -1.738
        },
        {
            "name": "Lone Druid",
            "Rubick": -0.923
        },
        {
            "name": "Luna",
            "Rubick": -1.634
        },
        {
            "name": "Lycan",
            "Rubick": -0.49
        },
        {
            "name": "Magnus",
            "Rubick": -1.061
        },
        {
            "name": "Marci",
            "Rubick": 0.273
        },
        {
            "name": "Mars",
            "Rubick": 0.693
        },
        {
            "name": "Medusa",
            "Rubick": -2.212
        },
        {
            "name": "Meepo",
            "Rubick": 0.034
        },
        {
            "name": "Mirana",
            "Rubick": -0.104
        },
        {
            "name": "Monkey King",
            "Rubick": 0.657
        },
        {
            "name": "Morphling",
            "Rubick": -2.198
        },
        {
            "name": "Muerta",
            "Rubick": -0.42
        },
        {
            "name": "Naga Siren",
            "Rubick": -0.253
        },
        {
            "name": "Nature's Prophet",
            "Rubick": 0.801
        },
        {
            "name": "Necrophos",
            "Rubick": -0.355
        },
        {
            "name": "Night Stalker",
            "Rubick": 0.048
        },
        {
            "name": "Nyx Assassin",
            "Rubick": -0.662
        },
        {
            "name": "Ogre Magi",
            "Rubick": -0.203
        },
        {
            "name": "Omniknight",
            "Rubick": -0.842
        },
        {
            "name": "Oracle",
            "Rubick": 0.066
        },
        {
            "name": "Outworld Destroyer",
            "Rubick": -2.315
        },
        {
            "name": "Pangolier",
            "Rubick": 0.977
        },
        {
            "name": "Phantom Assassin",
            "Rubick": 0.546
        },
        {
            "name": "Phantom Lancer",
            "Rubick": -1.449
        },
        {
            "name": "Phoenix",
            "Rubick": 0.599
        },
        {
            "name": "Primal Beast",
            "Rubick": 0.859
        },
        {
            "name": "Puck",
            "Rubick": -0.935
        },
        {
            "name": "Pudge",
            "Rubick": 0.679
        },
        {
            "name": "Pugna",
            "Rubick": 0.164
        },
        {
            "name": "Queen of Pain",
            "Rubick": 0.418
        },
        {
            "name": "Razor",
            "Rubick": 1.317
        },
        {
            "name": "Riki",
            "Rubick": -0.41
        },
        {
            "name": "Ringmaster",
            "Rubick": 0.196
        },
        {
            "name": "Sand King",
            "Rubick": 0.299
        },
        {
            "name": "Shadow Demon",
            "Rubick": 0.692
        },
        {
            "name": "Shadow Fiend",
            "Rubick": -0.037
        },
        {
            "name": "Shadow Shaman",
            "Rubick": -0.959
        },
        {
            "name": "Silencer",
            "Rubick": -0.993
        },
        {
            "name": "Skywrath Mage",
            "Rubick": 0.434
        },
        {
            "name": "Slardar",
            "Rubick": 0.338
        },
        {
            "name": "Slark",
            "Rubick": 0.447
        },
        {
            "name": "Snapfire",
            "Rubick": 0.494
        },
        {
            "name": "Sniper",
            "Rubick": -0.102
        },
        {
            "name": "Spectre",
            "Rubick": -0.347
        },
        {
            "name": "Spirit Breaker",
            "Rubick": -0.491
        },
        {
            "name": "Storm Spirit",
            "Rubick": 0.335
        },
        {
            "name": "Sven",
            "Rubick": -0.661
        },
        {
            "name": "Techies",
            "Rubick": -0.337
        },
        {
            "name": "Templar Assassin",
            "Rubick": 2.18
        },
        {
            "name": "Terrorblade",
            "Rubick": -0.279
        },
        {
            "name": "Tidehunter",
            "Rubick": -0.473
        },
        {
            "name": "Timbersaw",
            "Rubick": -0.342
        },
        {
            "name": "Tinker",
            "Rubick": 0.996
        },
        {
            "name": "Tiny",
            "Rubick": -0.205
        },
        {
            "name": "Treant Protector",
            "Rubick": -0.365
        },
        {
            "name": "Troll Warlord",
            "Rubick": -0.727
        },
        {
            "name": "Tusk",
            "Rubick": 1.415
        },
        {
            "name": "Underlord",
            "Rubick": -6.179
        },
        {
            "name": "Undying",
            "Rubick": -1.638
        },
        {
            "name": "Ursa",
            "Rubick": 1.632
        },
        {
            "name": "Vengeful Spirit",
            "Rubick": -0.957
        },
        {
            "name": "Venomancer",
            "Rubick": -0.473
        },
        {
            "name": "Viper",
            "Rubick": -0.68
        },
        {
            "name": "Visage",
            "Rubick": 0.225
        },
        {
            "name": "Void Spirit",
            "Rubick": -0.846
        },
        {
            "name": "Warlock",
            "Rubick": -0.635
        },
        {
            "name": "Weaver",
            "Rubick": -2.283
        },
        {
            "name": "Windranger",
            "Rubick": 0.429
        },
        {
            "name": "Winter Wyvern",
            "Rubick": 0.349
        },
        {
            "name": "Witch Doctor",
            "Rubick": -0.775
        },
        {
            "name": "Wraith King",
            "Rubick": -1.833
        },
        {
            "name": "Zeus",
            "Rubick": -0.42
        }
    ],
    "Shadow Demon": [
        {
            "name": "Abaddon",
            "Shadow Demon": -2.124
        },
        {
            "name": "Alchemist",
            "Shadow Demon": 0.096
        },
        {
            "name": "Ancient Apparition",
            "Shadow Demon": 0.099
        },
        {
            "name": "Anti-Mage",
            "Shadow Demon": 1.719
        },
        {
            "name": "Arc Warden",
            "Shadow Demon": 0.623
        },
        {
            "name": "Axe",
            "Shadow Demon": -0.736
        },
        {
            "name": "Bane",
            "Shadow Demon": -0.112
        },
        {
            "name": "Batrider",
            "Shadow Demon": 0.516
        },
        {
            "name": "Beastmaster",
            "Shadow Demon": -0.657
        },
        {
            "name": "Bloodseeker",
            "Shadow Demon": 0.054
        },
        {
            "name": "Bounty Hunter",
            "Shadow Demon": 1.781
        },
        {
            "name": "Brewmaster",
            "Shadow Demon": 0.37
        },
        {
            "name": "Bristleback",
            "Shadow Demon": -0.891
        },
        {
            "name": "Broodmother",
            "Shadow Demon": 0.755
        },
        {
            "name": "Centaur Warrunner",
            "Shadow Demon": 0.407
        },
        {
            "name": "Chaos Knight",
            "Shadow Demon": 2.428
        },
        {
            "name": "Chen",
            "Shadow Demon": 4.125
        },
        {
            "name": "Clinkz",
            "Shadow Demon": 0.203
        },
        {
            "name": "Clockwerk",
            "Shadow Demon": -1.269
        },
        {
            "name": "Crystal Maiden",
            "Shadow Demon": -0.147
        },
        {
            "name": "Dark Seer",
            "Shadow Demon": 0.306
        },
        {
            "name": "Dark Willow",
            "Shadow Demon": -1.61
        },
        {
            "name": "Dawnbreaker",
            "Shadow Demon": 1.568
        },
        {
            "name": "Dazzle",
            "Shadow Demon": 0.631
        },
        {
            "name": "Death Prophet",
            "Shadow Demon": 0.015
        },
        {
            "name": "Disruptor",
            "Shadow Demon": -1.744
        },
        {
            "name": "Doom",
            "Shadow Demon": -0.089
        },
        {
            "name": "Dragon Knight",
            "Shadow Demon": 0.338
        },
        {
            "name": "Drow Ranger",
            "Shadow Demon": -0.671
        },
        {
            "name": "Earth Spirit",
            "Shadow Demon": 1.122
        },
        {
            "name": "Earthshaker",
            "Shadow Demon": 1.452
        },
        {
            "name": "Elder Titan",
            "Shadow Demon": -2.538
        },
        {
            "name": "Ember Spirit",
            "Shadow Demon": 1.891
        },
        {
            "name": "Enchantress",
            "Shadow Demon": 1.71
        },
        {
            "name": "Enigma",
            "Shadow Demon": 1.951
        },
        {
            "name": "Faceless Void",
            "Shadow Demon": 1.316
        },
        {
            "name": "Grimstroke",
            "Shadow Demon": -1.518
        },
        {
            "name": "Gyrocopter",
            "Shadow Demon": -0.937
        },
        {
            "name": "Hoodwink",
            "Shadow Demon": -0.407
        },
        {
            "name": "Huskar",
            "Shadow Demon": -1.962
        },
        {
            "name": "Invoker",
            "Shadow Demon": -0.809
        },
        {
            "name": "Io",
            "Shadow Demon": 1.613
        },
        {
            "name": "Jakiro",
            "Shadow Demon": 0.748
        },
        {
            "name": "Juggernaut",
            "Shadow Demon": -0.116
        },
        {
            "name": "Keeper of the Light",
            "Shadow Demon": 1.253
        },
        {
            "name": "Kez",
            "Shadow Demon": -0.057
        },
        {
            "name": "Kunkka",
            "Shadow Demon": 0.138
        },
        {
            "name": "Legion Commander",
            "Shadow Demon": -2.183
        },
        {
            "name": "Leshrac",
            "Shadow Demon": 0.541
        },
        {
            "name": "Lich",
            "Shadow Demon": -2.068
        },
        {
            "name": "Lifestealer",
            "Shadow Demon": 0.842
        },
        {
            "name": "Lina",
            "Shadow Demon": 0.653
        },
        {
            "name": "Lion",
            "Shadow Demon": -0.212
        },
        {
            "name": "Lone Druid",
            "Shadow Demon": 2.024
        },
        {
            "name": "Luna",
            "Shadow Demon": -1.198
        },
        {
            "name": "Lycan",
            "Shadow Demon": 2.295
        },
        {
            "name": "Magnus",
            "Shadow Demon": 0.525
        },
        {
            "name": "Marci",
            "Shadow Demon": 2.23
        },
        {
            "name": "Mars",
            "Shadow Demon": -0.247
        },
        {
            "name": "Medusa",
            "Shadow Demon": -4.237
        },
        {
            "name": "Meepo",
            "Shadow Demon": 1.682
        },
        {
            "name": "Mirana",
            "Shadow Demon": 0.28
        },
        {
            "name": "Monkey King",
            "Shadow Demon": 0.266
        },
        {
            "name": "Morphling",
            "Shadow Demon": 0.858
        },
        {
            "name": "Muerta",
            "Shadow Demon": -1.246
        },
        {
            "name": "Naga Siren",
            "Shadow Demon": 1.848
        },
        {
            "name": "Nature's Prophet",
            "Shadow Demon": 0.946
        },
        {
            "name": "Necrophos",
            "Shadow Demon": -2.198
        },
        {
            "name": "Night Stalker",
            "Shadow Demon": -0.318
        },
        {
            "name": "Nyx Assassin",
            "Shadow Demon": 0.948
        },
        {
            "name": "Ogre Magi",
            "Shadow Demon": -1.192
        },
        {
            "name": "Omniknight",
            "Shadow Demon": -0.295
        },
        {
            "name": "Oracle",
            "Shadow Demon": -3.666
        },
        {
            "name": "Outworld Destroyer",
            "Shadow Demon": -1.518
        },
        {
            "name": "Pangolier",
            "Shadow Demon": 2.886
        },
        {
            "name": "Phantom Assassin",
            "Shadow Demon": 1.121
        },
        {
            "name": "Phantom Lancer",
            "Shadow Demon": 2.492
        },
        {
            "name": "Phoenix",
            "Shadow Demon": 1.335
        },
        {
            "name": "Primal Beast",
            "Shadow Demon": -0.008
        },
        {
            "name": "Puck",
            "Shadow Demon": 1.531
        },
        {
            "name": "Pudge",
            "Shadow Demon": 0.532
        },
        {
            "name": "Pugna",
            "Shadow Demon": 1.934
        },
        {
            "name": "Queen of Pain",
            "Shadow Demon": 0.951
        },
        {
            "name": "Razor",
            "Shadow Demon": 0.419
        },
        {
            "name": "Riki",
            "Shadow Demon": 2.024
        },
        {
            "name": "Ringmaster",
            "Shadow Demon": -1.384
        },
        {
            "name": "Rubick",
            "Shadow Demon": -1.007
        },
        {
            "name": "Sand King",
            "Shadow Demon": 1.941
        },
        {
            "name": "Shadow Fiend",
            "Shadow Demon": -1.473
        },
        {
            "name": "Shadow Shaman",
            "Shadow Demon": -0.133
        },
        {
            "name": "Silencer",
            "Shadow Demon": -1.175
        },
        {
            "name": "Skywrath Mage",
            "Shadow Demon": -2.151
        },
        {
            "name": "Slardar",
            "Shadow Demon": -0.306
        },
        {
            "name": "Slark",
            "Shadow Demon": -0.612
        },
        {
            "name": "Snapfire",
            "Shadow Demon": -0.272
        },
        {
            "name": "Sniper",
            "Shadow Demon": -0.027
        },
        {
            "name": "Spectre",
            "Shadow Demon": -0.697
        },
        {
            "name": "Spirit Breaker",
            "Shadow Demon": 0.389
        },
        {
            "name": "Storm Spirit",
            "Shadow Demon": 0.305
        },
        {
            "name": "Sven",
            "Shadow Demon": 2.017
        },
        {
            "name": "Techies",
            "Shadow Demon": -0.726
        },
        {
            "name": "Templar Assassin",
            "Shadow Demon": -0.962
        },
        {
            "name": "Terrorblade",
            "Shadow Demon": 0.257
        },
        {
            "name": "Tidehunter",
            "Shadow Demon": 0.963
        },
        {
            "name": "Timbersaw",
            "Shadow Demon": 2.109
        },
        {
            "name": "Tinker",
            "Shadow Demon": -0.007
        },
        {
            "name": "Tiny",
            "Shadow Demon": 0.666
        },
        {
            "name": "Treant Protector",
            "Shadow Demon": 0.397
        },
        {
            "name": "Troll Warlord",
            "Shadow Demon": 0.513
        },
        {
            "name": "Tusk",
            "Shadow Demon": 0.81
        },
        {
            "name": "Underlord",
            "Shadow Demon": 1.123
        },
        {
            "name": "Undying",
            "Shadow Demon": 1.846
        },
        {
            "name": "Ursa",
            "Shadow Demon": -0.421
        },
        {
            "name": "Vengeful Spirit",
            "Shadow Demon": -0.274
        },
        {
            "name": "Venomancer",
            "Shadow Demon": -0.92
        },
        {
            "name": "Viper",
            "Shadow Demon": -1.98
        },
        {
            "name": "Visage",
            "Shadow Demon": -1.293
        },
        {
            "name": "Void Spirit",
            "Shadow Demon": 1.01
        },
        {
            "name": "Warlock",
            "Shadow Demon": 0.749
        },
        {
            "name": "Weaver",
            "Shadow Demon": 1.934
        },
        {
            "name": "Windranger",
            "Shadow Demon": -0.634
        },
        {
            "name": "Winter Wyvern",
            "Shadow Demon": 0.249
        },
        {
            "name": "Witch Doctor",
            "Shadow Demon": -0.212
        },
        {
            "name": "Wraith King",
            "Shadow Demon": -0.059
        },
        {
            "name": "Zeus",
            "Shadow Demon": -0.021
        }
    ],
    "Shadow Shaman": [
        {
            "name": "Abaddon",
            "Shadow Shaman": 0.862
        },
        {
            "name": "Alchemist",
            "Shadow Shaman": 1.41
        },
        {
            "name": "Ancient Apparition",
            "Shadow Shaman": 0.175
        },
        {
            "name": "Anti-Mage",
            "Shadow Shaman": -0.739
        },
        {
            "name": "Arc Warden",
            "Shadow Shaman": 1.312
        },
        {
            "name": "Axe",
            "Shadow Shaman": 0.848
        },
        {
            "name": "Bane",
            "Shadow Shaman": -0.582
        },
        {
            "name": "Batrider",
            "Shadow Shaman": 0.815
        },
        {
            "name": "Beastmaster",
            "Shadow Shaman": 1.333
        },
        {
            "name": "Bloodseeker",
            "Shadow Shaman": 0.094
        },
        {
            "name": "Bounty Hunter",
            "Shadow Shaman": -2.063
        },
        {
            "name": "Brewmaster",
            "Shadow Shaman": 0.352
        },
        {
            "name": "Bristleback",
            "Shadow Shaman": -1.087
        },
        {
            "name": "Broodmother",
            "Shadow Shaman": -0.617
        },
        {
            "name": "Centaur Warrunner",
            "Shadow Shaman": -0.456
        },
        {
            "name": "Chaos Knight",
            "Shadow Shaman": 1.727
        },
        {
            "name": "Chen",
            "Shadow Shaman": -0.319
        },
        {
            "name": "Clinkz",
            "Shadow Shaman": -0.288
        },
        {
            "name": "Clockwerk",
            "Shadow Shaman": 0.57
        },
        {
            "name": "Crystal Maiden",
            "Shadow Shaman": -0.613
        },
        {
            "name": "Dark Seer",
            "Shadow Shaman": 2.038
        },
        {
            "name": "Dark Willow",
            "Shadow Shaman": 0.02
        },
        {
            "name": "Dawnbreaker",
            "Shadow Shaman": 1.642
        },
        {
            "name": "Dazzle",
            "Shadow Shaman": 0.341
        },
        {
            "name": "Death Prophet",
            "Shadow Shaman": 0.615
        },
        {
            "name": "Disruptor",
            "Shadow Shaman": -0.342
        },
        {
            "name": "Doom",
            "Shadow Shaman": 0.565
        },
        {
            "name": "Dragon Knight",
            "Shadow Shaman": 0.473
        },
        {
            "name": "Drow Ranger",
            "Shadow Shaman": 0.845
        },
        {
            "name": "Earth Spirit",
            "Shadow Shaman": -0.558
        },
        {
            "name": "Earthshaker",
            "Shadow Shaman": -0.052
        },
        {
            "name": "Elder Titan",
            "Shadow Shaman": 0.129
        },
        {
            "name": "Ember Spirit",
            "Shadow Shaman": 0.261
        },
        {
            "name": "Enchantress",
            "Shadow Shaman": 0.803
        },
        {
            "name": "Enigma",
            "Shadow Shaman": 0.79
        },
        {
            "name": "Faceless Void",
            "Shadow Shaman": -0.479
        },
        {
            "name": "Grimstroke",
            "Shadow Shaman": 0.065
        },
        {
            "name": "Gyrocopter",
            "Shadow Shaman": 1.88
        },
        {
            "name": "Hoodwink",
            "Shadow Shaman": 0.975
        },
        {
            "name": "Huskar",
            "Shadow Shaman": -0.52
        },
        {
            "name": "Invoker",
            "Shadow Shaman": 0.166
        },
        {
            "name": "Io",
            "Shadow Shaman": 0.326
        },
        {
            "name": "Jakiro",
            "Shadow Shaman": 0.583
        },
        {
            "name": "Juggernaut",
            "Shadow Shaman": -0.849
        },
        {
            "name": "Keeper of the Light",
            "Shadow Shaman": -0.215
        },
        {
            "name": "Kez",
            "Shadow Shaman": -1.049
        },
        {
            "name": "Kunkka",
            "Shadow Shaman": 0.614
        },
        {
            "name": "Legion Commander",
            "Shadow Shaman": -0.408
        },
        {
            "name": "Leshrac",
            "Shadow Shaman": 0.855
        },
        {
            "name": "Lich",
            "Shadow Shaman": -0.552
        },
        {
            "name": "Lifestealer",
            "Shadow Shaman": -0.483
        },
        {
            "name": "Lina",
            "Shadow Shaman": -0.427
        },
        {
            "name": "Lion",
            "Shadow Shaman": -0.607
        },
        {
            "name": "Lone Druid",
            "Shadow Shaman": 2.257
        },
        {
            "name": "Luna",
            "Shadow Shaman": 1.71
        },
        {
            "name": "Lycan",
            "Shadow Shaman": -0.384
        },
        {
            "name": "Magnus",
            "Shadow Shaman": 0.316
        },
        {
            "name": "Marci",
            "Shadow Shaman": 0.25
        },
        {
            "name": "Mars",
            "Shadow Shaman": 0.983
        },
        {
            "name": "Medusa",
            "Shadow Shaman": 2.217
        },
        {
            "name": "Meepo",
            "Shadow Shaman": 2.18
        },
        {
            "name": "Mirana",
            "Shadow Shaman": 0.819
        },
        {
            "name": "Monkey King",
            "Shadow Shaman": 0.727
        },
        {
            "name": "Morphling",
            "Shadow Shaman": -0.592
        },
        {
            "name": "Muerta",
            "Shadow Shaman": 1.172
        },
        {
            "name": "Naga Siren",
            "Shadow Shaman": 2.131
        },
        {
            "name": "Nature's Prophet",
            "Shadow Shaman": 0.048
        },
        {
            "name": "Necrophos",
            "Shadow Shaman": 1.832
        },
        {
            "name": "Night Stalker",
            "Shadow Shaman": -0.47
        },
        {
            "name": "Nyx Assassin",
            "Shadow Shaman": -1.001
        },
        {
            "name": "Ogre Magi",
            "Shadow Shaman": -0.417
        },
        {
            "name": "Omniknight",
            "Shadow Shaman": 1.451
        },
        {
            "name": "Oracle",
            "Shadow Shaman": -0.888
        },
        {
            "name": "Outworld Destroyer",
            "Shadow Shaman": -1.469
        },
        {
            "name": "Pangolier",
            "Shadow Shaman": 0.234
        },
        {
            "name": "Phantom Assassin",
            "Shadow Shaman": -0.186
        },
        {
            "name": "Phantom Lancer",
            "Shadow Shaman": 3.957
        },
        {
            "name": "Phoenix",
            "Shadow Shaman": 0.335
        },
        {
            "name": "Primal Beast",
            "Shadow Shaman": -0.274
        },
        {
            "name": "Puck",
            "Shadow Shaman": -0.761
        },
        {
            "name": "Pudge",
            "Shadow Shaman": 1.091
        },
        {
            "name": "Pugna",
            "Shadow Shaman": -0.097
        },
        {
            "name": "Queen of Pain",
            "Shadow Shaman": -0.685
        },
        {
            "name": "Razor",
            "Shadow Shaman": 1.674
        },
        {
            "name": "Riki",
            "Shadow Shaman": 0.165
        },
        {
            "name": "Ringmaster",
            "Shadow Shaman": 0.655
        },
        {
            "name": "Rubick",
            "Shadow Shaman": 0.687
        },
        {
            "name": "Sand King",
            "Shadow Shaman": 2.278
        },
        {
            "name": "Shadow Demon",
            "Shadow Shaman": 0.528
        },
        {
            "name": "Shadow Fiend",
            "Shadow Shaman": -0.108
        },
        {
            "name": "Silencer",
            "Shadow Shaman": 2.684
        },
        {
            "name": "Skywrath Mage",
            "Shadow Shaman": -0.588
        },
        {
            "name": "Slardar",
            "Shadow Shaman": -1.609
        },
        {
            "name": "Slark",
            "Shadow Shaman": -0.603
        },
        {
            "name": "Snapfire",
            "Shadow Shaman": 1.372
        },
        {
            "name": "Sniper",
            "Shadow Shaman": 1.12
        },
        {
            "name": "Spectre",
            "Shadow Shaman": 1.641
        },
        {
            "name": "Spirit Breaker",
            "Shadow Shaman": 0.192
        },
        {
            "name": "Storm Spirit",
            "Shadow Shaman": -1.408
        },
        {
            "name": "Sven",
            "Shadow Shaman": -0.151
        },
        {
            "name": "Techies",
            "Shadow Shaman": 0.806
        },
        {
            "name": "Templar Assassin",
            "Shadow Shaman": -0.603
        },
        {
            "name": "Terrorblade",
            "Shadow Shaman": 1.789
        },
        {
            "name": "Tidehunter",
            "Shadow Shaman": 0.741
        },
        {
            "name": "Timbersaw",
            "Shadow Shaman": 0.244
        },
        {
            "name": "Tinker",
            "Shadow Shaman": -0.441
        },
        {
            "name": "Tiny",
            "Shadow Shaman": 0.987
        },
        {
            "name": "Treant Protector",
            "Shadow Shaman": 1.287
        },
        {
            "name": "Troll Warlord",
            "Shadow Shaman": -1.208
        },
        {
            "name": "Tusk",
            "Shadow Shaman": 0.216
        },
        {
            "name": "Underlord",
            "Shadow Shaman": 0.912
        },
        {
            "name": "Undying",
            "Shadow Shaman": 0.392
        },
        {
            "name": "Ursa",
            "Shadow Shaman": -2.354
        },
        {
            "name": "Vengeful Spirit",
            "Shadow Shaman": 0.794
        },
        {
            "name": "Venomancer",
            "Shadow Shaman": 0.191
        },
        {
            "name": "Viper",
            "Shadow Shaman": -0.992
        },
        {
            "name": "Visage",
            "Shadow Shaman": 0.24
        },
        {
            "name": "Void Spirit",
            "Shadow Shaman": -0.4
        },
        {
            "name": "Warlock",
            "Shadow Shaman": 1.795
        },
        {
            "name": "Weaver",
            "Shadow Shaman": -0.928
        },
        {
            "name": "Windranger",
            "Shadow Shaman": 0.733
        },
        {
            "name": "Winter Wyvern",
            "Shadow Shaman": 0.067
        },
        {
            "name": "Witch Doctor",
            "Shadow Shaman": 0.81
        },
        {
            "name": "Wraith King",
            "Shadow Shaman": 0.977
        },
        {
            "name": "Zeus",
            "Shadow Shaman": 0.113
        }
    ],
    "Silencer": [
        {
            "name": "Abaddon",
            "Silencer": 1.745
        },
        {
            "name": "Alchemist",
            "Silencer": 1.533
        },
        {
            "name": "Ancient Apparition",
            "Silencer": -1.672
        },
        {
            "name": "Anti-Mage",
            "Silencer": 0.934
        },
        {
            "name": "Arc Warden",
            "Silencer": 1.996
        },
        {
            "name": "Axe",
            "Silencer": -1.338
        },
        {
            "name": "Bane",
            "Silencer": -1.967
        },
        {
            "name": "Batrider",
            "Silencer": 0.595
        },
        {
            "name": "Beastmaster",
            "Silencer": 1.285
        },
        {
            "name": "Bloodseeker",
            "Silencer": 0.317
        },
        {
            "name": "Bounty Hunter",
            "Silencer": 0.689
        },
        {
            "name": "Brewmaster",
            "Silencer": 1.585
        },
        {
            "name": "Bristleback",
            "Silencer": -1.991
        },
        {
            "name": "Broodmother",
            "Silencer": 3.033
        },
        {
            "name": "Centaur Warrunner",
            "Silencer": 0.521
        },
        {
            "name": "Chaos Knight",
            "Silencer": 0.843
        },
        {
            "name": "Chen",
            "Silencer": 2.847
        },
        {
            "name": "Clinkz",
            "Silencer": -0.556
        },
        {
            "name": "Clockwerk",
            "Silencer": 0.793
        },
        {
            "name": "Crystal Maiden",
            "Silencer": -0.117
        },
        {
            "name": "Dark Seer",
            "Silencer": 0.72
        },
        {
            "name": "Dark Willow",
            "Silencer": 0.734
        },
        {
            "name": "Dawnbreaker",
            "Silencer": -2.523
        },
        {
            "name": "Dazzle",
            "Silencer": -0.24
        },
        {
            "name": "Death Prophet",
            "Silencer": -1.017
        },
        {
            "name": "Disruptor",
            "Silencer": 0.596
        },
        {
            "name": "Doom",
            "Silencer": 0.829
        },
        {
            "name": "Dragon Knight",
            "Silencer": 0.375
        },
        {
            "name": "Drow Ranger",
            "Silencer": -0.799
        },
        {
            "name": "Earth Spirit",
            "Silencer": -0.079
        },
        {
            "name": "Earthshaker",
            "Silencer": -1.001
        },
        {
            "name": "Elder Titan",
            "Silencer": -0.48
        },
        {
            "name": "Ember Spirit",
            "Silencer": 0.344
        },
        {
            "name": "Enchantress",
            "Silencer": 1.726
        },
        {
            "name": "Enigma",
            "Silencer": -1.47
        },
        {
            "name": "Faceless Void",
            "Silencer": -1.616
        },
        {
            "name": "Grimstroke",
            "Silencer": -0.762
        },
        {
            "name": "Gyrocopter",
            "Silencer": 0.211
        },
        {
            "name": "Hoodwink",
            "Silencer": 0.719
        },
        {
            "name": "Huskar",
            "Silencer": 1.809
        },
        {
            "name": "Invoker",
            "Silencer": 1.092
        },
        {
            "name": "Io",
            "Silencer": 0.219
        },
        {
            "name": "Jakiro",
            "Silencer": -0.116
        },
        {
            "name": "Juggernaut",
            "Silencer": -0.872
        },
        {
            "name": "Keeper of the Light",
            "Silencer": 0.729
        },
        {
            "name": "Kez",
            "Silencer": 0.002
        },
        {
            "name": "Kunkka",
            "Silencer": 0.336
        },
        {
            "name": "Legion Commander",
            "Silencer": 1.787
        },
        {
            "name": "Leshrac",
            "Silencer": -1.937
        },
        {
            "name": "Lich",
            "Silencer": -0.734
        },
        {
            "name": "Lifestealer",
            "Silencer": -0.23
        },
        {
            "name": "Lina",
            "Silencer": -0.849
        },
        {
            "name": "Lion",
            "Silencer": -0.764
        },
        {
            "name": "Lone Druid",
            "Silencer": 3.548
        },
        {
            "name": "Luna",
            "Silencer": 1.785
        },
        {
            "name": "Lycan",
            "Silencer": 4.564
        },
        {
            "name": "Magnus",
            "Silencer": -0.088
        },
        {
            "name": "Marci",
            "Silencer": 0.315
        },
        {
            "name": "Mars",
            "Silencer": -0.173
        },
        {
            "name": "Medusa",
            "Silencer": 1.112
        },
        {
            "name": "Meepo",
            "Silencer": 0.316
        },
        {
            "name": "Mirana",
            "Silencer": 1.107
        },
        {
            "name": "Monkey King",
            "Silencer": 1.444
        },
        {
            "name": "Morphling",
            "Silencer": 2.256
        },
        {
            "name": "Muerta",
            "Silencer": 1.178
        },
        {
            "name": "Naga Siren",
            "Silencer": 2.579
        },
        {
            "name": "Nature's Prophet",
            "Silencer": 1.727
        },
        {
            "name": "Necrophos",
            "Silencer": -2.519
        },
        {
            "name": "Night Stalker",
            "Silencer": 2.215
        },
        {
            "name": "Nyx Assassin",
            "Silencer": 0.271
        },
        {
            "name": "Ogre Magi",
            "Silencer": 1.471
        },
        {
            "name": "Omniknight",
            "Silencer": -1.546
        },
        {
            "name": "Oracle",
            "Silencer": -0.381
        },
        {
            "name": "Outworld Destroyer",
            "Silencer": -2.062
        },
        {
            "name": "Pangolier",
            "Silencer": -0.065
        },
        {
            "name": "Phantom Assassin",
            "Silencer": 1.139
        },
        {
            "name": "Phantom Lancer",
            "Silencer": 2.064
        },
        {
            "name": "Phoenix",
            "Silencer": -0.565
        },
        {
            "name": "Primal Beast",
            "Silencer": -0.605
        },
        {
            "name": "Puck",
            "Silencer": -1.818
        },
        {
            "name": "Pudge",
            "Silencer": -0.735
        },
        {
            "name": "Pugna",
            "Silencer": -1.28
        },
        {
            "name": "Queen of Pain",
            "Silencer": -0.547
        },
        {
            "name": "Razor",
            "Silencer": 1.074
        },
        {
            "name": "Riki",
            "Silencer": -0.723
        },
        {
            "name": "Ringmaster",
            "Silencer": 1.039
        },
        {
            "name": "Rubick",
            "Silencer": 0.579
        },
        {
            "name": "Sand King",
            "Silencer": -1.489
        },
        {
            "name": "Shadow Demon",
            "Silencer": 1.427
        },
        {
            "name": "Shadow Fiend",
            "Silencer": 1.261
        },
        {
            "name": "Shadow Shaman",
            "Silencer": -2.776
        },
        {
            "name": "Skywrath Mage",
            "Silencer": -0.479
        },
        {
            "name": "Slardar",
            "Silencer": -0.759
        },
        {
            "name": "Slark",
            "Silencer": 1.492
        },
        {
            "name": "Snapfire",
            "Silencer": 0.653
        },
        {
            "name": "Sniper",
            "Silencer": 2.123
        },
        {
            "name": "Spectre",
            "Silencer": 1.96
        },
        {
            "name": "Spirit Breaker",
            "Silencer": 1.524
        },
        {
            "name": "Storm Spirit",
            "Silencer": -1.607
        },
        {
            "name": "Sven",
            "Silencer": 0.036
        },
        {
            "name": "Techies",
            "Silencer": 0.913
        },
        {
            "name": "Templar Assassin",
            "Silencer": 0.481
        },
        {
            "name": "Terrorblade",
            "Silencer": 1.963
        },
        {
            "name": "Tidehunter",
            "Silencer": 2.305
        },
        {
            "name": "Timbersaw",
            "Silencer": -4.436
        },
        {
            "name": "Tinker",
            "Silencer": 0.008
        },
        {
            "name": "Tiny",
            "Silencer": -0.136
        },
        {
            "name": "Treant Protector",
            "Silencer": 0.502
        },
        {
            "name": "Troll Warlord",
            "Silencer": -0.356
        },
        {
            "name": "Tusk",
            "Silencer": 1.048
        },
        {
            "name": "Underlord",
            "Silencer": 0.81
        },
        {
            "name": "Undying",
            "Silencer": -0.947
        },
        {
            "name": "Ursa",
            "Silencer": -0.638
        },
        {
            "name": "Vengeful Spirit",
            "Silencer": 0.315
        },
        {
            "name": "Venomancer",
            "Silencer": 0.756
        },
        {
            "name": "Viper",
            "Silencer": 0.746
        },
        {
            "name": "Visage",
            "Silencer": 0.573
        },
        {
            "name": "Void Spirit",
            "Silencer": 0.429
        },
        {
            "name": "Warlock",
            "Silencer": -0.332
        },
        {
            "name": "Weaver",
            "Silencer": -0.797
        },
        {
            "name": "Windranger",
            "Silencer": 1.319
        },
        {
            "name": "Winter Wyvern",
            "Silencer": -0.159
        },
        {
            "name": "Witch Doctor",
            "Silencer": -1.379
        },
        {
            "name": "Wraith King",
            "Silencer": 2.295
        },
        {
            "name": "Zeus",
            "Silencer": -0.057
        }
    ],
    "Skywrath Mage": [
        {
            "name": "Abaddon",
            "Skywrath Mage": 1.819
        },
        {
            "name": "Alchemist",
            "Skywrath Mage": -0.653
        },
        {
            "name": "Ancient Apparition",
            "Skywrath Mage": -0.712
        },
        {
            "name": "Anti-Mage",
            "Skywrath Mage": 0.98
        },
        {
            "name": "Arc Warden",
            "Skywrath Mage": 0.759
        },
        {
            "name": "Axe",
            "Skywrath Mage": -1.443
        },
        {
            "name": "Bane",
            "Skywrath Mage": 0.884
        },
        {
            "name": "Batrider",
            "Skywrath Mage": 0.208
        },
        {
            "name": "Beastmaster",
            "Skywrath Mage": 0.874
        },
        {
            "name": "Bloodseeker",
            "Skywrath Mage": -1.632
        },
        {
            "name": "Bounty Hunter",
            "Skywrath Mage": -0.434
        },
        {
            "name": "Brewmaster",
            "Skywrath Mage": 0.312
        },
        {
            "name": "Bristleback",
            "Skywrath Mage": 1.214
        },
        {
            "name": "Broodmother",
            "Skywrath Mage": 1.52
        },
        {
            "name": "Centaur Warrunner",
            "Skywrath Mage": 0.691
        },
        {
            "name": "Chaos Knight",
            "Skywrath Mage": 2.467
        },
        {
            "name": "Chen",
            "Skywrath Mage": -0.093
        },
        {
            "name": "Clinkz",
            "Skywrath Mage": -0.044
        },
        {
            "name": "Clockwerk",
            "Skywrath Mage": -0.565
        },
        {
            "name": "Crystal Maiden",
            "Skywrath Mage": 0.253
        },
        {
            "name": "Dark Seer",
            "Skywrath Mage": 0.308
        },
        {
            "name": "Dark Willow",
            "Skywrath Mage": 0.076
        },
        {
            "name": "Dawnbreaker",
            "Skywrath Mage": -0.164
        },
        {
            "name": "Dazzle",
            "Skywrath Mage": 1.117
        },
        {
            "name": "Death Prophet",
            "Skywrath Mage": 0.475
        },
        {
            "name": "Disruptor",
            "Skywrath Mage": 0.438
        },
        {
            "name": "Doom",
            "Skywrath Mage": -0.036
        },
        {
            "name": "Dragon Knight",
            "Skywrath Mage": 1.739
        },
        {
            "name": "Drow Ranger",
            "Skywrath Mage": -0.418
        },
        {
            "name": "Earth Spirit",
            "Skywrath Mage": -0.6
        },
        {
            "name": "Earthshaker",
            "Skywrath Mage": -0.526
        },
        {
            "name": "Elder Titan",
            "Skywrath Mage": -1.493
        },
        {
            "name": "Ember Spirit",
            "Skywrath Mage": -0.68
        },
        {
            "name": "Enchantress",
            "Skywrath Mage": 0.245
        },
        {
            "name": "Enigma",
            "Skywrath Mage": 0.258
        },
        {
            "name": "Faceless Void",
            "Skywrath Mage": -0.952
        },
        {
            "name": "Grimstroke",
            "Skywrath Mage": -0.142
        },
        {
            "name": "Gyrocopter",
            "Skywrath Mage": 0.189
        },
        {
            "name": "Hoodwink",
            "Skywrath Mage": -0.445
        },
        {
            "name": "Huskar",
            "Skywrath Mage": 0.438
        },
        {
            "name": "Invoker",
            "Skywrath Mage": -0.019
        },
        {
            "name": "Io",
            "Skywrath Mage": 1.424
        },
        {
            "name": "Jakiro",
            "Skywrath Mage": -0.151
        },
        {
            "name": "Juggernaut",
            "Skywrath Mage": -1.499
        },
        {
            "name": "Keeper of the Light",
            "Skywrath Mage": -1.269
        },
        {
            "name": "Kez",
            "Skywrath Mage": 0.133
        },
        {
            "name": "Kunkka",
            "Skywrath Mage": 0.278
        },
        {
            "name": "Legion Commander",
            "Skywrath Mage": 0.197
        },
        {
            "name": "Leshrac",
            "Skywrath Mage": -1.537
        },
        {
            "name": "Lich",
            "Skywrath Mage": -0.071
        },
        {
            "name": "Lifestealer",
            "Skywrath Mage": -1.508
        },
        {
            "name": "Lina",
            "Skywrath Mage": -0.291
        },
        {
            "name": "Lion",
            "Skywrath Mage": 0.085
        },
        {
            "name": "Lone Druid",
            "Skywrath Mage": 2.14
        },
        {
            "name": "Luna",
            "Skywrath Mage": 0.069
        },
        {
            "name": "Lycan",
            "Skywrath Mage": 3.25
        },
        {
            "name": "Magnus",
            "Skywrath Mage": 0.888
        },
        {
            "name": "Marci",
            "Skywrath Mage": 1.25
        },
        {
            "name": "Mars",
            "Skywrath Mage": 0.562
        },
        {
            "name": "Medusa",
            "Skywrath Mage": 2.681
        },
        {
            "name": "Meepo",
            "Skywrath Mage": 1.317
        },
        {
            "name": "Mirana",
            "Skywrath Mage": 0.96
        },
        {
            "name": "Monkey King",
            "Skywrath Mage": -0.372
        },
        {
            "name": "Morphling",
            "Skywrath Mage": -0.359
        },
        {
            "name": "Muerta",
            "Skywrath Mage": -1.777
        },
        {
            "name": "Naga Siren",
            "Skywrath Mage": 0.93
        },
        {
            "name": "Nature's Prophet",
            "Skywrath Mage": 0.144
        },
        {
            "name": "Necrophos",
            "Skywrath Mage": -4.237
        },
        {
            "name": "Night Stalker",
            "Skywrath Mage": 1.598
        },
        {
            "name": "Nyx Assassin",
            "Skywrath Mage": -0.858
        },
        {
            "name": "Ogre Magi",
            "Skywrath Mage": -0.112
        },
        {
            "name": "Omniknight",
            "Skywrath Mage": 1.466
        },
        {
            "name": "Oracle",
            "Skywrath Mage": 1.047
        },
        {
            "name": "Outworld Destroyer",
            "Skywrath Mage": -0.641
        },
        {
            "name": "Pangolier",
            "Skywrath Mage": 1.333
        },
        {
            "name": "Phantom Assassin",
            "Skywrath Mage": 0.512
        },
        {
            "name": "Phantom Lancer",
            "Skywrath Mage": 0.621
        },
        {
            "name": "Phoenix",
            "Skywrath Mage": -1.85
        },
        {
            "name": "Primal Beast",
            "Skywrath Mage": -0.621
        },
        {
            "name": "Puck",
            "Skywrath Mage": -0.398
        },
        {
            "name": "Pudge",
            "Skywrath Mage": -0.599
        },
        {
            "name": "Pugna",
            "Skywrath Mage": 0.286
        },
        {
            "name": "Queen of Pain",
            "Skywrath Mage": -0.522
        },
        {
            "name": "Razor",
            "Skywrath Mage": 0.964
        },
        {
            "name": "Riki",
            "Skywrath Mage": 0.527
        },
        {
            "name": "Ringmaster",
            "Skywrath Mage": 0.748
        },
        {
            "name": "Rubick",
            "Skywrath Mage": -0.849
        },
        {
            "name": "Sand King",
            "Skywrath Mage": -0.89
        },
        {
            "name": "Shadow Demon",
            "Skywrath Mage": 2.121
        },
        {
            "name": "Shadow Fiend",
            "Skywrath Mage": 0.18
        },
        {
            "name": "Shadow Shaman",
            "Skywrath Mage": 0.808
        },
        {
            "name": "Silencer",
            "Skywrath Mage": 0.627
        },
        {
            "name": "Slardar",
            "Skywrath Mage": 0.497
        },
        {
            "name": "Slark",
            "Skywrath Mage": -0.457
        },
        {
            "name": "Snapfire",
            "Skywrath Mage": 0.363
        },
        {
            "name": "Sniper",
            "Skywrath Mage": -0.316
        },
        {
            "name": "Spectre",
            "Skywrath Mage": 0.137
        },
        {
            "name": "Spirit Breaker",
            "Skywrath Mage": 0.864
        },
        {
            "name": "Storm Spirit",
            "Skywrath Mage": -1.22
        },
        {
            "name": "Sven",
            "Skywrath Mage": 1.243
        },
        {
            "name": "Techies",
            "Skywrath Mage": -0.6
        },
        {
            "name": "Templar Assassin",
            "Skywrath Mage": 2.128
        },
        {
            "name": "Terrorblade",
            "Skywrath Mage": -0.768
        },
        {
            "name": "Tidehunter",
            "Skywrath Mage": 1.414
        },
        {
            "name": "Timbersaw",
            "Skywrath Mage": -2.422
        },
        {
            "name": "Tinker",
            "Skywrath Mage": -0.683
        },
        {
            "name": "Tiny",
            "Skywrath Mage": 0.142
        },
        {
            "name": "Treant Protector",
            "Skywrath Mage": -0.461
        },
        {
            "name": "Troll Warlord",
            "Skywrath Mage": -0.947
        },
        {
            "name": "Tusk",
            "Skywrath Mage": 1.579
        },
        {
            "name": "Underlord",
            "Skywrath Mage": -0.367
        },
        {
            "name": "Undying",
            "Skywrath Mage": -0.33
        },
        {
            "name": "Ursa",
            "Skywrath Mage": -0.354
        },
        {
            "name": "Vengeful Spirit",
            "Skywrath Mage": 0.637
        },
        {
            "name": "Venomancer",
            "Skywrath Mage": -0.034
        },
        {
            "name": "Viper",
            "Skywrath Mage": 0.129
        },
        {
            "name": "Visage",
            "Skywrath Mage": 1.193
        },
        {
            "name": "Void Spirit",
            "Skywrath Mage": 0.826
        },
        {
            "name": "Warlock",
            "Skywrath Mage": 0.628
        },
        {
            "name": "Weaver",
            "Skywrath Mage": -0.757
        },
        {
            "name": "Windranger",
            "Skywrath Mage": 0.813
        },
        {
            "name": "Winter Wyvern",
            "Skywrath Mage": -0.508
        },
        {
            "name": "Witch Doctor",
            "Skywrath Mage": -0.656
        },
        {
            "name": "Wraith King",
            "Skywrath Mage": 1.81
        },
        {
            "name": "Zeus",
            "Skywrath Mage": -1.646
        }
    ],
    "Storm Spirit": [
        {
            "name": "Abaddon",
            "Storm Spirit": 1.28
        },
        {
            "name": "Alchemist",
            "Storm Spirit": 0.241
        },
        {
            "name": "Ancient Apparition",
            "Storm Spirit": -3.303
        },
        {
            "name": "Anti-Mage",
            "Storm Spirit": 4.51
        },
        {
            "name": "Arc Warden",
            "Storm Spirit": 1.604
        },
        {
            "name": "Axe",
            "Storm Spirit": 1.362
        },
        {
            "name": "Bane",
            "Storm Spirit": 0.683
        },
        {
            "name": "Batrider",
            "Storm Spirit": -0.763
        },
        {
            "name": "Beastmaster",
            "Storm Spirit": 1.203
        },
        {
            "name": "Bloodseeker",
            "Storm Spirit": -3.088
        },
        {
            "name": "Bounty Hunter",
            "Storm Spirit": -1.504
        },
        {
            "name": "Brewmaster",
            "Storm Spirit": -1.458
        },
        {
            "name": "Bristleback",
            "Storm Spirit": -2.281
        },
        {
            "name": "Broodmother",
            "Storm Spirit": -0.839
        },
        {
            "name": "Centaur Warrunner",
            "Storm Spirit": 0.131
        },
        {
            "name": "Chaos Knight",
            "Storm Spirit": -0.171
        },
        {
            "name": "Chen",
            "Storm Spirit": 0.36
        },
        {
            "name": "Clinkz",
            "Storm Spirit": 0.225
        },
        {
            "name": "Clockwerk",
            "Storm Spirit": -2.194
        },
        {
            "name": "Crystal Maiden",
            "Storm Spirit": 1.348
        },
        {
            "name": "Dark Seer",
            "Storm Spirit": 0.673
        },
        {
            "name": "Dark Willow",
            "Storm Spirit": 0.318
        },
        {
            "name": "Dawnbreaker",
            "Storm Spirit": 0.293
        },
        {
            "name": "Dazzle",
            "Storm Spirit": 1.807
        },
        {
            "name": "Death Prophet",
            "Storm Spirit": 0.791
        },
        {
            "name": "Disruptor",
            "Storm Spirit": 1.285
        },
        {
            "name": "Doom",
            "Storm Spirit": -0.098
        },
        {
            "name": "Dragon Knight",
            "Storm Spirit": 0.007
        },
        {
            "name": "Drow Ranger",
            "Storm Spirit": -2.236
        },
        {
            "name": "Earth Spirit",
            "Storm Spirit": 0.541
        },
        {
            "name": "Earthshaker",
            "Storm Spirit": 0.774
        },
        {
            "name": "Elder Titan",
            "Storm Spirit": -1.623
        },
        {
            "name": "Ember Spirit",
            "Storm Spirit": 2.398
        },
        {
            "name": "Enchantress",
            "Storm Spirit": -0.484
        },
        {
            "name": "Enigma",
            "Storm Spirit": 2.053
        },
        {
            "name": "Faceless Void",
            "Storm Spirit": -0.27
        },
        {
            "name": "Grimstroke",
            "Storm Spirit": 1.543
        },
        {
            "name": "Gyrocopter",
            "Storm Spirit": -0.45
        },
        {
            "name": "Hoodwink",
            "Storm Spirit": -0.052
        },
        {
            "name": "Huskar",
            "Storm Spirit": 1.706
        },
        {
            "name": "Invoker",
            "Storm Spirit": 0.364
        },
        {
            "name": "Io",
            "Storm Spirit": 1.205
        },
        {
            "name": "Jakiro",
            "Storm Spirit": -0.439
        },
        {
            "name": "Juggernaut",
            "Storm Spirit": -1.169
        },
        {
            "name": "Keeper of the Light",
            "Storm Spirit": -1.702
        },
        {
            "name": "Kez",
            "Storm Spirit": 1.204
        },
        {
            "name": "Kunkka",
            "Storm Spirit": -0.365
        },
        {
            "name": "Legion Commander",
            "Storm Spirit": 1.163
        },
        {
            "name": "Leshrac",
            "Storm Spirit": 0.356
        },
        {
            "name": "Lich",
            "Storm Spirit": 0.067
        },
        {
            "name": "Lifestealer",
            "Storm Spirit": -1.694
        },
        {
            "name": "Lina",
            "Storm Spirit": -1.488
        },
        {
            "name": "Lion",
            "Storm Spirit": 1.812
        },
        {
            "name": "Lone Druid",
            "Storm Spirit": 3.634
        },
        {
            "name": "Luna",
            "Storm Spirit": 1.119
        },
        {
            "name": "Lycan",
            "Storm Spirit": 0.776
        },
        {
            "name": "Magnus",
            "Storm Spirit": -0.501
        },
        {
            "name": "Marci",
            "Storm Spirit": 0.698
        },
        {
            "name": "Mars",
            "Storm Spirit": -2.241
        },
        {
            "name": "Medusa",
            "Storm Spirit": 1.034
        },
        {
            "name": "Meepo",
            "Storm Spirit": 4.501
        },
        {
            "name": "Mirana",
            "Storm Spirit": -0.269
        },
        {
            "name": "Monkey King",
            "Storm Spirit": -0.895
        },
        {
            "name": "Morphling",
            "Storm Spirit": 0.455
        },
        {
            "name": "Muerta",
            "Storm Spirit": 0.774
        },
        {
            "name": "Naga Siren",
            "Storm Spirit": 2.41
        },
        {
            "name": "Nature's Prophet",
            "Storm Spirit": -0.078
        },
        {
            "name": "Necrophos",
            "Storm Spirit": 0.535
        },
        {
            "name": "Night Stalker",
            "Storm Spirit": 1.575
        },
        {
            "name": "Nyx Assassin",
            "Storm Spirit": 0.934
        },
        {
            "name": "Ogre Magi",
            "Storm Spirit": -0.723
        },
        {
            "name": "Omniknight",
            "Storm Spirit": 1.586
        },
        {
            "name": "Oracle",
            "Storm Spirit": 2.331
        },
        {
            "name": "Outworld Destroyer",
            "Storm Spirit": -1.834
        },
        {
            "name": "Pangolier",
            "Storm Spirit": -1.599
        },
        {
            "name": "Phantom Assassin",
            "Storm Spirit": -0.82
        },
        {
            "name": "Phantom Lancer",
            "Storm Spirit": -1.237
        },
        {
            "name": "Phoenix",
            "Storm Spirit": 0.043
        },
        {
            "name": "Primal Beast",
            "Storm Spirit": -0.162
        },
        {
            "name": "Puck",
            "Storm Spirit": 3.017
        },
        {
            "name": "Pudge",
            "Storm Spirit": -1.115
        },
        {
            "name": "Pugna",
            "Storm Spirit": 3.123
        },
        {
            "name": "Queen of Pain",
            "Storm Spirit": -0.127
        },
        {
            "name": "Razor",
            "Storm Spirit": -2.384
        },
        {
            "name": "Riki",
            "Storm Spirit": 3.264
        },
        {
            "name": "Ringmaster",
            "Storm Spirit": -0.266
        },
        {
            "name": "Rubick",
            "Storm Spirit": -0.748
        },
        {
            "name": "Sand King",
            "Storm Spirit": -1.657
        },
        {
            "name": "Shadow Demon",
            "Storm Spirit": -0.256
        },
        {
            "name": "Shadow Fiend",
            "Storm Spirit": 1.848
        },
        {
            "name": "Shadow Shaman",
            "Storm Spirit": 1.663
        },
        {
            "name": "Silencer",
            "Storm Spirit": 1.773
        },
        {
            "name": "Skywrath Mage",
            "Storm Spirit": 1.208
        },
        {
            "name": "Slardar",
            "Storm Spirit": -0.487
        },
        {
            "name": "Slark",
            "Storm Spirit": 1.862
        },
        {
            "name": "Snapfire",
            "Storm Spirit": -0.44
        },
        {
            "name": "Sniper",
            "Storm Spirit": -3.435
        },
        {
            "name": "Spectre",
            "Storm Spirit": 0.603
        },
        {
            "name": "Spirit Breaker",
            "Storm Spirit": 0.357
        },
        {
            "name": "Sven",
            "Storm Spirit": -0.177
        },
        {
            "name": "Techies",
            "Storm Spirit": -0.291
        },
        {
            "name": "Templar Assassin",
            "Storm Spirit": -0.804
        },
        {
            "name": "Terrorblade",
            "Storm Spirit": 0.058
        },
        {
            "name": "Tidehunter",
            "Storm Spirit": 2.037
        },
        {
            "name": "Timbersaw",
            "Storm Spirit": -1.459
        },
        {
            "name": "Tinker",
            "Storm Spirit": -0.1
        },
        {
            "name": "Tiny",
            "Storm Spirit": 0.648
        },
        {
            "name": "Treant Protector",
            "Storm Spirit": 0.645
        },
        {
            "name": "Troll Warlord",
            "Storm Spirit": 2.828
        },
        {
            "name": "Tusk",
            "Storm Spirit": -0.188
        },
        {
            "name": "Underlord",
            "Storm Spirit": 0.834
        },
        {
            "name": "Undying",
            "Storm Spirit": -0.551
        },
        {
            "name": "Ursa",
            "Storm Spirit": -0.303
        },
        {
            "name": "Vengeful Spirit",
            "Storm Spirit": -0.598
        },
        {
            "name": "Venomancer",
            "Storm Spirit": -2.638
        },
        {
            "name": "Viper",
            "Storm Spirit": -0.733
        },
        {
            "name": "Visage",
            "Storm Spirit": 0.765
        },
        {
            "name": "Void Spirit",
            "Storm Spirit": -0.092
        },
        {
            "name": "Warlock",
            "Storm Spirit": -1.28
        },
        {
            "name": "Weaver",
            "Storm Spirit": -0.324
        },
        {
            "name": "Windranger",
            "Storm Spirit": 0.769
        },
        {
            "name": "Winter Wyvern",
            "Storm Spirit": -0.565
        },
        {
            "name": "Witch Doctor",
            "Storm Spirit": 0.744
        },
        {
            "name": "Wraith King",
            "Storm Spirit": 0.045
        },
        {
            "name": "Zeus",
            "Storm Spirit": -2.405
        }
    ],
    "Tinker": [
        {
            "name": "Abaddon",
            "Tinker": -0.938
        },
        {
            "name": "Alchemist",
            "Tinker": -0.535
        },
        {
            "name": "Ancient Apparition",
            "Tinker": 0.476
        },
        {
            "name": "Anti-Mage",
            "Tinker": 3.809
        },
        {
            "name": "Arc Warden",
            "Tinker": 0.065
        },
        {
            "name": "Axe",
            "Tinker": -1.446
        },
        {
            "name": "Bane",
            "Tinker": 0.555
        },
        {
            "name": "Batrider",
            "Tinker": -0.23
        },
        {
            "name": "Beastmaster",
            "Tinker": -0.291
        },
        {
            "name": "Bloodseeker",
            "Tinker": -0.905
        },
        {
            "name": "Bounty Hunter",
            "Tinker": -1.157
        },
        {
            "name": "Brewmaster",
            "Tinker": -1.767
        },
        {
            "name": "Bristleback",
            "Tinker": -0.095
        },
        {
            "name": "Broodmother",
            "Tinker": -0.213
        },
        {
            "name": "Centaur Warrunner",
            "Tinker": 0.375
        },
        {
            "name": "Chaos Knight",
            "Tinker": 2.053
        },
        {
            "name": "Chen",
            "Tinker": 0.844
        },
        {
            "name": "Clinkz",
            "Tinker": 0.909
        },
        {
            "name": "Clockwerk",
            "Tinker": -0.473
        },
        {
            "name": "Crystal Maiden",
            "Tinker": 0.307
        },
        {
            "name": "Dark Seer",
            "Tinker": 0.17
        },
        {
            "name": "Dark Willow",
            "Tinker": -0.891
        },
        {
            "name": "Dawnbreaker",
            "Tinker": 0.378
        },
        {
            "name": "Dazzle",
            "Tinker": -0.271
        },
        {
            "name": "Death Prophet",
            "Tinker": 2.869
        },
        {
            "name": "Disruptor",
            "Tinker": -0.972
        },
        {
            "name": "Doom",
            "Tinker": -0.039
        },
        {
            "name": "Dragon Knight",
            "Tinker": -1.19
        },
        {
            "name": "Drow Ranger",
            "Tinker": 0.178
        },
        {
            "name": "Earth Spirit",
            "Tinker": 0.63
        },
        {
            "name": "Earthshaker",
            "Tinker": 1.299
        },
        {
            "name": "Elder Titan",
            "Tinker": -0.063
        },
        {
            "name": "Ember Spirit",
            "Tinker": -3.74
        },
        {
            "name": "Enchantress",
            "Tinker": 1.604
        },
        {
            "name": "Enigma",
            "Tinker": 3.396
        },
        {
            "name": "Faceless Void",
            "Tinker": 1.343
        },
        {
            "name": "Grimstroke",
            "Tinker": 0.424
        },
        {
            "name": "Gyrocopter",
            "Tinker": 0.114
        },
        {
            "name": "Hoodwink",
            "Tinker": -0.734
        },
        {
            "name": "Huskar",
            "Tinker": -0.438
        },
        {
            "name": "Invoker",
            "Tinker": -0.173
        },
        {
            "name": "Io",
            "Tinker": 0.662
        },
        {
            "name": "Jakiro",
            "Tinker": -0.445
        },
        {
            "name": "Juggernaut",
            "Tinker": 1.809
        },
        {
            "name": "Keeper of the Light",
            "Tinker": 0.168
        },
        {
            "name": "Kez",
            "Tinker": -1.816
        },
        {
            "name": "Kunkka",
            "Tinker": -1.712
        },
        {
            "name": "Legion Commander",
            "Tinker": 0.222
        },
        {
            "name": "Leshrac",
            "Tinker": 2.179
        },
        {
            "name": "Lich",
            "Tinker": -0.737
        },
        {
            "name": "Lifestealer",
            "Tinker": 0.176
        },
        {
            "name": "Lina",
            "Tinker": 1.082
        },
        {
            "name": "Lion",
            "Tinker": 0.452
        },
        {
            "name": "Lone Druid",
            "Tinker": -1.122
        },
        {
            "name": "Luna",
            "Tinker": 0.963
        },
        {
            "name": "Lycan",
            "Tinker": -1.081
        },
        {
            "name": "Magnus",
            "Tinker": 0.313
        },
        {
            "name": "Marci",
            "Tinker": 1.514
        },
        {
            "name": "Mars",
            "Tinker": -1.428
        },
        {
            "name": "Medusa",
            "Tinker": 0.198
        },
        {
            "name": "Meepo",
            "Tinker": -1.248
        },
        {
            "name": "Mirana",
            "Tinker": 1.914
        },
        {
            "name": "Monkey King",
            "Tinker": -1.35
        },
        {
            "name": "Morphling",
            "Tinker": -1.413
        },
        {
            "name": "Muerta",
            "Tinker": -0.908
        },
        {
            "name": "Naga Siren",
            "Tinker": 2.466
        },
        {
            "name": "Nature's Prophet",
            "Tinker": -1.295
        },
        {
            "name": "Necrophos",
            "Tinker": 0.601
        },
        {
            "name": "Night Stalker",
            "Tinker": -0.394
        },
        {
            "name": "Nyx Assassin",
            "Tinker": 1.567
        },
        {
            "name": "Ogre Magi",
            "Tinker": 0.03
        },
        {
            "name": "Omniknight",
            "Tinker": 4.013
        },
        {
            "name": "Oracle",
            "Tinker": -0.419
        },
        {
            "name": "Outworld Destroyer",
            "Tinker": -1.429
        },
        {
            "name": "Pangolier",
            "Tinker": 0.691
        },
        {
            "name": "Phantom Assassin",
            "Tinker": -0.423
        },
        {
            "name": "Phantom Lancer",
            "Tinker": -0.16
        },
        {
            "name": "Phoenix",
            "Tinker": -1.8
        },
        {
            "name": "Primal Beast",
            "Tinker": 0.404
        },
        {
            "name": "Puck",
            "Tinker": -1.626
        },
        {
            "name": "Pudge",
            "Tinker": 2.125
        },
        {
            "name": "Pugna",
            "Tinker": 3.175
        },
        {
            "name": "Queen of Pain",
            "Tinker": -0.482
        },
        {
            "name": "Razor",
            "Tinker": -0.124
        },
        {
            "name": "Riki",
            "Tinker": -0.916
        },
        {
            "name": "Ringmaster",
            "Tinker": -1.034
        },
        {
            "name": "Rubick",
            "Tinker": -1.241
        },
        {
            "name": "Sand King",
            "Tinker": 0.322
        },
        {
            "name": "Shadow Demon",
            "Tinker": -0.015
        },
        {
            "name": "Shadow Fiend",
            "Tinker": 0.512
        },
        {
            "name": "Shadow Shaman",
            "Tinker": 1.065
        },
        {
            "name": "Silencer",
            "Tinker": 0.399
        },
        {
            "name": "Skywrath Mage",
            "Tinker": 0.87
        },
        {
            "name": "Slardar",
            "Tinker": 1.042
        },
        {
            "name": "Slark",
            "Tinker": -0.19
        },
        {
            "name": "Snapfire",
            "Tinker": 0.569
        },
        {
            "name": "Sniper",
            "Tinker": 0.211
        },
        {
            "name": "Spectre",
            "Tinker": -0.442
        },
        {
            "name": "Spirit Breaker",
            "Tinker": 0.325
        },
        {
            "name": "Storm Spirit",
            "Tinker": 0.213
        },
        {
            "name": "Sven",
            "Tinker": 1.615
        },
        {
            "name": "Techies",
            "Tinker": -0.244
        },
        {
            "name": "Templar Assassin",
            "Tinker": -2.278
        },
        {
            "name": "Terrorblade",
            "Tinker": -1.742
        },
        {
            "name": "Tidehunter",
            "Tinker": -2.267
        },
        {
            "name": "Timbersaw",
            "Tinker": -0.99
        },
        {
            "name": "Tiny",
            "Tinker": 0.836
        },
        {
            "name": "Treant Protector",
            "Tinker": -0.555
        },
        {
            "name": "Troll Warlord",
            "Tinker": -1.334
        },
        {
            "name": "Tusk",
            "Tinker": 0.277
        },
        {
            "name": "Underlord",
            "Tinker": -2.161
        },
        {
            "name": "Undying",
            "Tinker": -0.351
        },
        {
            "name": "Ursa",
            "Tinker": -1.344
        },
        {
            "name": "Vengeful Spirit",
            "Tinker": 0.401
        },
        {
            "name": "Venomancer",
            "Tinker": -2.243
        },
        {
            "name": "Viper",
            "Tinker": -2.142
        },
        {
            "name": "Visage",
            "Tinker": 0.88
        },
        {
            "name": "Void Spirit",
            "Tinker": -1.558
        },
        {
            "name": "Warlock",
            "Tinker": -0.216
        },
        {
            "name": "Weaver",
            "Tinker": -0.45
        },
        {
            "name": "Windranger",
            "Tinker": 0.701
        },
        {
            "name": "Winter Wyvern",
            "Tinker": -1.456
        },
        {
            "name": "Witch Doctor",
            "Tinker": 0.674
        },
        {
            "name": "Wraith King",
            "Tinker": -1.625
        },
        {
            "name": "Zeus",
            "Tinker": 0.154
        }
    ],
    "Warlock": [
        {
            "name": "Abaddon",
            "Warlock": 0.055
        },
        {
            "name": "Alchemist",
            "Warlock": 0.263
        },
        {
            "name": "Ancient Apparition",
            "Warlock": 0.461
        },
        {
            "name": "Anti-Mage",
            "Warlock": 0.856
        },
        {
            "name": "Arc Warden",
            "Warlock": -2.635
        },
        {
            "name": "Axe",
            "Warlock": -2.306
        },
        {
            "name": "Bane",
            "Warlock": -0.363
        },
        {
            "name": "Batrider",
            "Warlock": 0.171
        },
        {
            "name": "Beastmaster",
            "Warlock": -2.318
        },
        {
            "name": "Bloodseeker",
            "Warlock": -0.557
        },
        {
            "name": "Bounty Hunter",
            "Warlock": 1.631
        },
        {
            "name": "Brewmaster",
            "Warlock": -1.275
        },
        {
            "name": "Bristleback",
            "Warlock": 3.164
        },
        {
            "name": "Broodmother",
            "Warlock": -0.204
        },
        {
            "name": "Centaur Warrunner",
            "Warlock": -1.091
        },
        {
            "name": "Chaos Knight",
            "Warlock": -4.096
        },
        {
            "name": "Chen",
            "Warlock": -0.75
        },
        {
            "name": "Clinkz",
            "Warlock": 0.609
        },
        {
            "name": "Clockwerk",
            "Warlock": -0.074
        },
        {
            "name": "Crystal Maiden",
            "Warlock": 0.454
        },
        {
            "name": "Dark Seer",
            "Warlock": -1.227
        },
        {
            "name": "Dark Willow",
            "Warlock": -0.68
        },
        {
            "name": "Dawnbreaker",
            "Warlock": 1.039
        },
        {
            "name": "Dazzle",
            "Warlock": -0.016
        },
        {
            "name": "Death Prophet",
            "Warlock": -0.752
        },
        {
            "name": "Disruptor",
            "Warlock": 0.087
        },
        {
            "name": "Doom",
            "Warlock": -0.013
        },
        {
            "name": "Dragon Knight",
            "Warlock": 0.314
        },
        {
            "name": "Drow Ranger",
            "Warlock": 0.032
        },
        {
            "name": "Earth Spirit",
            "Warlock": 1.141
        },
        {
            "name": "Earthshaker",
            "Warlock": 1.432
        },
        {
            "name": "Elder Titan",
            "Warlock": -0.076
        },
        {
            "name": "Ember Spirit",
            "Warlock": 0.246
        },
        {
            "name": "Enchantress",
            "Warlock": 0.729
        },
        {
            "name": "Enigma",
            "Warlock": -2.606
        },
        {
            "name": "Faceless Void",
            "Warlock": 0.631
        },
        {
            "name": "Grimstroke",
            "Warlock": -0.531
        },
        {
            "name": "Gyrocopter",
            "Warlock": 0.767
        },
        {
            "name": "Hoodwink",
            "Warlock": 0.073
        },
        {
            "name": "Huskar",
            "Warlock": -0.178
        },
        {
            "name": "Invoker",
            "Warlock": 0.564
        },
        {
            "name": "Io",
            "Warlock": 0.351
        },
        {
            "name": "Jakiro",
            "Warlock": 1.194
        },
        {
            "name": "Juggernaut",
            "Warlock": -0.186
        },
        {
            "name": "Keeper of the Light",
            "Warlock": 0.453
        },
        {
            "name": "Kez",
            "Warlock": 0.792
        },
        {
            "name": "Kunkka",
            "Warlock": -0.802
        },
        {
            "name": "Legion Commander",
            "Warlock": 0.25
        },
        {
            "name": "Leshrac",
            "Warlock": -0.007
        },
        {
            "name": "Lich",
            "Warlock": -0.15
        },
        {
            "name": "Lifestealer",
            "Warlock": 0.566
        },
        {
            "name": "Lina",
            "Warlock": 1.445
        },
        {
            "name": "Lion",
            "Warlock": -0.726
        },
        {
            "name": "Lone Druid",
            "Warlock": -1.965
        },
        {
            "name": "Luna",
            "Warlock": -1.486
        },
        {
            "name": "Lycan",
            "Warlock": 1.758
        },
        {
            "name": "Magnus",
            "Warlock": 0.652
        },
        {
            "name": "Marci",
            "Warlock": 0.977
        },
        {
            "name": "Mars",
            "Warlock": 0.097
        },
        {
            "name": "Medusa",
            "Warlock": -0.442
        },
        {
            "name": "Meepo",
            "Warlock": -3.777
        },
        {
            "name": "Mirana",
            "Warlock": 0.73
        },
        {
            "name": "Monkey King",
            "Warlock": 1.042
        },
        {
            "name": "Morphling",
            "Warlock": 1.52
        },
        {
            "name": "Muerta",
            "Warlock": 0.224
        },
        {
            "name": "Naga Siren",
            "Warlock": -3.881
        },
        {
            "name": "Nature's Prophet",
            "Warlock": 1.005
        },
        {
            "name": "Necrophos",
            "Warlock": -0.95
        },
        {
            "name": "Night Stalker",
            "Warlock": 0.09
        },
        {
            "name": "Nyx Assassin",
            "Warlock": 1.71
        },
        {
            "name": "Ogre Magi",
            "Warlock": -1.868
        },
        {
            "name": "Omniknight",
            "Warlock": -0.946
        },
        {
            "name": "Oracle",
            "Warlock": 0.719
        },
        {
            "name": "Outworld Destroyer",
            "Warlock": -0.129
        },
        {
            "name": "Pangolier",
            "Warlock": 2.69
        },
        {
            "name": "Phantom Assassin",
            "Warlock": 1.942
        },
        {
            "name": "Phantom Lancer",
            "Warlock": -5.266
        },
        {
            "name": "Phoenix",
            "Warlock": 1.794
        },
        {
            "name": "Primal Beast",
            "Warlock": 1.616
        },
        {
            "name": "Puck",
            "Warlock": 0.574
        },
        {
            "name": "Pudge",
            "Warlock": -0.186
        },
        {
            "name": "Pugna",
            "Warlock": -0.1
        },
        {
            "name": "Queen of Pain",
            "Warlock": 0.081
        },
        {
            "name": "Razor",
            "Warlock": 0.648
        },
        {
            "name": "Riki",
            "Warlock": -0.819
        },
        {
            "name": "Ringmaster",
            "Warlock": 0.232
        },
        {
            "name": "Rubick",
            "Warlock": -0.061
        },
        {
            "name": "Sand King",
            "Warlock": 2.315
        },
        {
            "name": "Shadow Demon",
            "Warlock": -0.532
        },
        {
            "name": "Shadow Fiend",
            "Warlock": 1.699
        },
        {
            "name": "Shadow Shaman",
            "Warlock": -1.958
        },
        {
            "name": "Silencer",
            "Warlock": 0.343
        },
        {
            "name": "Skywrath Mage",
            "Warlock": -0.617
        },
        {
            "name": "Slardar",
            "Warlock": 1.992
        },
        {
            "name": "Slark",
            "Warlock": 1.406
        },
        {
            "name": "Snapfire",
            "Warlock": 1.792
        },
        {
            "name": "Sniper",
            "Warlock": 0.912
        },
        {
            "name": "Spectre",
            "Warlock": -1.786
        },
        {
            "name": "Spirit Breaker",
            "Warlock": -0.552
        },
        {
            "name": "Storm Spirit",
            "Warlock": 1.312
        },
        {
            "name": "Sven",
            "Warlock": 2.066
        },
        {
            "name": "Techies",
            "Warlock": -0.407
        },
        {
            "name": "Templar Assassin",
            "Warlock": 0.195
        },
        {
            "name": "Terrorblade",
            "Warlock": -4.097
        },
        {
            "name": "Tidehunter",
            "Warlock": 2.43
        },
        {
            "name": "Timbersaw",
            "Warlock": 0.172
        },
        {
            "name": "Tinker",
            "Warlock": 0.481
        },
        {
            "name": "Tiny",
            "Warlock": -0.398
        },
        {
            "name": "Treant Protector",
            "Warlock": -0.273
        },
        {
            "name": "Troll Warlord",
            "Warlock": 0.865
        },
        {
            "name": "Tusk",
            "Warlock": 1.433
        },
        {
            "name": "Underlord",
            "Warlock": 0.783
        },
        {
            "name": "Undying",
            "Warlock": 0.0
        },
        {
            "name": "Ursa",
            "Warlock": 1.082
        },
        {
            "name": "Vengeful Spirit",
            "Warlock": -0.897
        },
        {
            "name": "Venomancer",
            "Warlock": 0.721
        },
        {
            "name": "Viper",
            "Warlock": -0.864
        },
        {
            "name": "Visage",
            "Warlock": -1.993
        },
        {
            "name": "Void Spirit",
            "Warlock": 0.059
        },
        {
            "name": "Weaver",
            "Warlock": 2.355
        },
        {
            "name": "Windranger",
            "Warlock": 0.595
        },
        {
            "name": "Winter Wyvern",
            "Warlock": -1.31
        },
        {
            "name": "Witch Doctor",
            "Warlock": -0.076
        },
        {
            "name": "Wraith King",
            "Warlock": -2.976
        },
        {
            "name": "Zeus",
            "Warlock": -0.068
        }
    ],
    "Witch Doctor": [
        {
            "name": "Abaddon",
            "Witch Doctor": 0.182
        },
        {
            "name": "Alchemist",
            "Witch Doctor": -0.014
        },
        {
            "name": "Ancient Apparition",
            "Witch Doctor": 0.533
        },
        {
            "name": "Anti-Mage",
            "Witch Doctor": -0.66
        },
        {
            "name": "Arc Warden",
            "Witch Doctor": -0.609
        },
        {
            "name": "Axe",
            "Witch Doctor": -2.445
        },
        {
            "name": "Bane",
            "Witch Doctor": -0.003
        },
        {
            "name": "Batrider",
            "Witch Doctor": 0.925
        },
        {
            "name": "Beastmaster",
            "Witch Doctor": 0.127
        },
        {
            "name": "Bloodseeker",
            "Witch Doctor": -2.44
        },
        {
            "name": "Bounty Hunter",
            "Witch Doctor": 0.596
        },
        {
            "name": "Brewmaster",
            "Witch Doctor": 1.46
        },
        {
            "name": "Bristleback",
            "Witch Doctor": 2.673
        },
        {
            "name": "Broodmother",
            "Witch Doctor": 2.143
        },
        {
            "name": "Centaur Warrunner",
            "Witch Doctor": -1.835
        },
        {
            "name": "Chaos Knight",
            "Witch Doctor": -0.645
        },
        {
            "name": "Chen",
            "Witch Doctor": 2.204
        },
        {
            "name": "Clinkz",
            "Witch Doctor": 0.824
        },
        {
            "name": "Clockwerk",
            "Witch Doctor": -0.935
        },
        {
            "name": "Crystal Maiden",
            "Witch Doctor": -0.928
        },
        {
            "name": "Dark Seer",
            "Witch Doctor": -1.219
        },
        {
            "name": "Dark Willow",
            "Witch Doctor": 1.284
        },
        {
            "name": "Dawnbreaker",
            "Witch Doctor": -0.265
        },
        {
            "name": "Dazzle",
            "Witch Doctor": 0.331
        },
        {
            "name": "Death Prophet",
            "Witch Doctor": 0.841
        },
        {
            "name": "Disruptor",
            "Witch Doctor": 0.694
        },
        {
            "name": "Doom",
            "Witch Doctor": 0.178
        },
        {
            "name": "Dragon Knight",
            "Witch Doctor": -1.372
        },
        {
            "name": "Drow Ranger",
            "Witch Doctor": 1.142
        },
        {
            "name": "Earth Spirit",
            "Witch Doctor": -0.753
        },
        {
            "name": "Earthshaker",
            "Witch Doctor": 0.469
        },
        {
            "name": "Elder Titan",
            "Witch Doctor": 0.215
        },
        {
            "name": "Ember Spirit",
            "Witch Doctor": 0.35
        },
        {
            "name": "Enchantress",
            "Witch Doctor": -0.065
        },
        {
            "name": "Enigma",
            "Witch Doctor": 0.337
        },
        {
            "name": "Faceless Void",
            "Witch Doctor": 1.991
        },
        {
            "name": "Grimstroke",
            "Witch Doctor": 0.466
        },
        {
            "name": "Gyrocopter",
            "Witch Doctor": -0.221
        },
        {
            "name": "Hoodwink",
            "Witch Doctor": 1.318
        },
        {
            "name": "Huskar",
            "Witch Doctor": -0.791
        },
        {
            "name": "Invoker",
            "Witch Doctor": 0.57
        },
        {
            "name": "Io",
            "Witch Doctor": -0.792
        },
        {
            "name": "Jakiro",
            "Witch Doctor": 0.465
        },
        {
            "name": "Juggernaut",
            "Witch Doctor": -0.925
        },
        {
            "name": "Keeper of the Light",
            "Witch Doctor": 0.42
        },
        {
            "name": "Kez",
            "Witch Doctor": -0.676
        },
        {
            "name": "Kunkka",
            "Witch Doctor": 0.044
        },
        {
            "name": "Legion Commander",
            "Witch Doctor": -0.039
        },
        {
            "name": "Leshrac",
            "Witch Doctor": -0.474
        },
        {
            "name": "Lich",
            "Witch Doctor": -0.565
        },
        {
            "name": "Lifestealer",
            "Witch Doctor": -1.138
        },
        {
            "name": "Lina",
            "Witch Doctor": 0.589
        },
        {
            "name": "Lion",
            "Witch Doctor": 0.569
        },
        {
            "name": "Lone Druid",
            "Witch Doctor": -0.74
        },
        {
            "name": "Luna",
            "Witch Doctor": -0.649
        },
        {
            "name": "Lycan",
            "Witch Doctor": 0.678
        },
        {
            "name": "Magnus",
            "Witch Doctor": 0.307
        },
        {
            "name": "Marci",
            "Witch Doctor": -0.029
        },
        {
            "name": "Mars",
            "Witch Doctor": 0.881
        },
        {
            "name": "Medusa",
            "Witch Doctor": 3.158
        },
        {
            "name": "Meepo",
            "Witch Doctor": -1.63
        },
        {
            "name": "Mirana",
            "Witch Doctor": 1.477
        },
        {
            "name": "Monkey King",
            "Witch Doctor": 0.079
        },
        {
            "name": "Morphling",
            "Witch Doctor": 1.159
        },
        {
            "name": "Muerta",
            "Witch Doctor": 1.671
        },
        {
            "name": "Naga Siren",
            "Witch Doctor": 2.322
        },
        {
            "name": "Nature's Prophet",
            "Witch Doctor": 1.384
        },
        {
            "name": "Necrophos",
            "Witch Doctor": -0.232
        },
        {
            "name": "Night Stalker",
            "Witch Doctor": 1.006
        },
        {
            "name": "Nyx Assassin",
            "Witch Doctor": 0.452
        },
        {
            "name": "Ogre Magi",
            "Witch Doctor": -0.431
        },
        {
            "name": "Omniknight",
            "Witch Doctor": -1.045
        },
        {
            "name": "Oracle",
            "Witch Doctor": 0.063
        },
        {
            "name": "Outworld Destroyer",
            "Witch Doctor": 0.034
        },
        {
            "name": "Pangolier",
            "Witch Doctor": 1.767
        },
        {
            "name": "Phantom Assassin",
            "Witch Doctor": 1.271
        },
        {
            "name": "Phantom Lancer",
            "Witch Doctor": -0.103
        },
        {
            "name": "Phoenix",
            "Witch Doctor": 0.418
        },
        {
            "name": "Primal Beast",
            "Witch Doctor": -0.884
        },
        {
            "name": "Puck",
            "Witch Doctor": 1.634
        },
        {
            "name": "Pudge",
            "Witch Doctor": -0.317
        },
        {
            "name": "Pugna",
            "Witch Doctor": 0.602
        },
        {
            "name": "Queen of Pain",
            "Witch Doctor": -0.857
        },
        {
            "name": "Razor",
            "Witch Doctor": -1.643
        },
        {
            "name": "Riki",
            "Witch Doctor": 2.156
        },
        {
            "name": "Ringmaster",
            "Witch Doctor": 1.128
        },
        {
            "name": "Rubick",
            "Witch Doctor": 0.759
        },
        {
            "name": "Sand King",
            "Witch Doctor": 0.468
        },
        {
            "name": "Shadow Demon",
            "Witch Doctor": 0.652
        },
        {
            "name": "Shadow Fiend",
            "Witch Doctor": 0.684
        },
        {
            "name": "Shadow Shaman",
            "Witch Doctor": -0.87
        },
        {
            "name": "Silencer",
            "Witch Doctor": 1.137
        },
        {
            "name": "Skywrath Mage",
            "Witch Doctor": 0.767
        },
        {
            "name": "Slardar",
            "Witch Doctor": 0.186
        },
        {
            "name": "Slark",
            "Witch Doctor": 1.194
        },
        {
            "name": "Snapfire",
            "Witch Doctor": 1.085
        },
        {
            "name": "Sniper",
            "Witch Doctor": 1.163
        },
        {
            "name": "Spectre",
            "Witch Doctor": -0.717
        },
        {
            "name": "Spirit Breaker",
            "Witch Doctor": -0.625
        },
        {
            "name": "Storm Spirit",
            "Witch Doctor": -0.491
        },
        {
            "name": "Sven",
            "Witch Doctor": 0.386
        },
        {
            "name": "Techies",
            "Witch Doctor": 0.223
        },
        {
            "name": "Templar Assassin",
            "Witch Doctor": 1.663
        },
        {
            "name": "Terrorblade",
            "Witch Doctor": -0.155
        },
        {
            "name": "Tidehunter",
            "Witch Doctor": -1.808
        },
        {
            "name": "Timbersaw",
            "Witch Doctor": -1.263
        },
        {
            "name": "Tinker",
            "Witch Doctor": -0.016
        },
        {
            "name": "Tiny",
            "Witch Doctor": -0.329
        },
        {
            "name": "Treant Protector",
            "Witch Doctor": 0.276
        },
        {
            "name": "Troll Warlord",
            "Witch Doctor": -0.271
        },
        {
            "name": "Tusk",
            "Witch Doctor": 0.688
        },
        {
            "name": "Underlord",
            "Witch Doctor": -1.212
        },
        {
            "name": "Undying",
            "Witch Doctor": -1.574
        },
        {
            "name": "Ursa",
            "Witch Doctor": 1.129
        },
        {
            "name": "Vengeful Spirit",
            "Witch Doctor": 0.041
        },
        {
            "name": "Venomancer",
            "Witch Doctor": -0.367
        },
        {
            "name": "Viper",
            "Witch Doctor": -1.634
        },
        {
            "name": "Visage",
            "Witch Doctor": -0.112
        },
        {
            "name": "Void Spirit",
            "Witch Doctor": 0.551
        },
        {
            "name": "Warlock",
            "Witch Doctor": -0.117
        },
        {
            "name": "Weaver",
            "Witch Doctor": 0.555
        },
        {
            "name": "Windranger",
            "Witch Doctor": 1.687
        },
        {
            "name": "Winter Wyvern",
            "Witch Doctor": 0.017
        },
        {
            "name": "Wraith King",
            "Witch Doctor": 0.773
        },
        {
            "name": "Zeus",
            "Witch Doctor": 1.157
        }
    ],
    "Zeus": [
        {
            "name": "Abaddon",
            "Zeus": -0.067
        },
        {
            "name": "Alchemist",
            "Zeus": 0.362
        },
        {
            "name": "Ancient Apparition",
            "Zeus": -0.999
        },
        {
            "name": "Anti-Mage",
            "Zeus": 2.051
        },
        {
            "name": "Arc Warden",
            "Zeus": 0.737
        },
        {
            "name": "Axe",
            "Zeus": -1.668
        },
        {
            "name": "Bane",
            "Zeus": 0.629
        },
        {
            "name": "Batrider",
            "Zeus": 1.692
        },
        {
            "name": "Beastmaster",
            "Zeus": 1.692
        },
        {
            "name": "Bloodseeker",
            "Zeus": -0.369
        },
        {
            "name": "Bounty Hunter",
            "Zeus": -0.27
        },
        {
            "name": "Brewmaster",
            "Zeus": 1.294
        },
        {
            "name": "Bristleback",
            "Zeus": 1.365
        },
        {
            "name": "Broodmother",
            "Zeus": 2.326
        },
        {
            "name": "Centaur Warrunner",
            "Zeus": 0.683
        },
        {
            "name": "Chaos Knight",
            "Zeus": 1.742
        },
        {
            "name": "Chen",
            "Zeus": 3.723
        },
        {
            "name": "Clinkz",
            "Zeus": 0.539
        },
        {
            "name": "Clockwerk",
            "Zeus": 0.083
        },
        {
            "name": "Crystal Maiden",
            "Zeus": -0.88
        },
        {
            "name": "Dark Seer",
            "Zeus": 0.493
        },
        {
            "name": "Dark Willow",
            "Zeus": 0.843
        },
        {
            "name": "Dawnbreaker",
            "Zeus": 0.14
        },
        {
            "name": "Dazzle",
            "Zeus": 0.488
        },
        {
            "name": "Death Prophet",
            "Zeus": 0.913
        },
        {
            "name": "Disruptor",
            "Zeus": -0.284
        },
        {
            "name": "Doom",
            "Zeus": 0.566
        },
        {
            "name": "Dragon Knight",
            "Zeus": 0.333
        },
        {
            "name": "Drow Ranger",
            "Zeus": -1.771
        },
        {
            "name": "Earth Spirit",
            "Zeus": 0.259
        },
        {
            "name": "Earthshaker",
            "Zeus": -0.682
        },
        {
            "name": "Elder Titan",
            "Zeus": -1.503
        },
        {
            "name": "Ember Spirit",
            "Zeus": 2.117
        },
        {
            "name": "Enchantress",
            "Zeus": 0.878
        },
        {
            "name": "Enigma",
            "Zeus": 0.028
        },
        {
            "name": "Faceless Void",
            "Zeus": 1.409
        },
        {
            "name": "Grimstroke",
            "Zeus": -0.106
        },
        {
            "name": "Gyrocopter",
            "Zeus": -0.535
        },
        {
            "name": "Hoodwink",
            "Zeus": -0.311
        },
        {
            "name": "Huskar",
            "Zeus": 1.809
        },
        {
            "name": "Invoker",
            "Zeus": -0.418
        },
        {
            "name": "Io",
            "Zeus": 1.474
        },
        {
            "name": "Jakiro",
            "Zeus": -0.784
        },
        {
            "name": "Juggernaut",
            "Zeus": 0.529
        },
        {
            "name": "Keeper of the Light",
            "Zeus": 0.81
        },
        {
            "name": "Kez",
            "Zeus": 0.596
        },
        {
            "name": "Kunkka",
            "Zeus": 0.082
        },
        {
            "name": "Legion Commander",
            "Zeus": -0.906
        },
        {
            "name": "Leshrac",
            "Zeus": -0.999
        },
        {
            "name": "Lich",
            "Zeus": -0.877
        },
        {
            "name": "Lifestealer",
            "Zeus": 1.333
        },
        {
            "name": "Lina",
            "Zeus": -0.098
        },
        {
            "name": "Lion",
            "Zeus": 0.444
        },
        {
            "name": "Lone Druid",
            "Zeus": 1.038
        },
        {
            "name": "Luna",
            "Zeus": -0.229
        },
        {
            "name": "Lycan",
            "Zeus": 2.133
        },
        {
            "name": "Magnus",
            "Zeus": 0.584
        },
        {
            "name": "Marci",
            "Zeus": 0.366
        },
        {
            "name": "Mars",
            "Zeus": 0.959
        },
        {
            "name": "Medusa",
            "Zeus": 1.037
        },
        {
            "name": "Meepo",
            "Zeus": 3.781
        },
        {
            "name": "Mirana",
            "Zeus": -0.328
        },
        {
            "name": "Monkey King",
            "Zeus": -1.194
        },
        {
            "name": "Morphling",
            "Zeus": -0.954
        },
        {
            "name": "Muerta",
            "Zeus": -0.769
        },
        {
            "name": "Naga Siren",
            "Zeus": 1.784
        },
        {
            "name": "Nature's Prophet",
            "Zeus": 0.452
        },
        {
            "name": "Necrophos",
            "Zeus": 0.797
        },
        {
            "name": "Night Stalker",
            "Zeus": 0.134
        },
        {
            "name": "Nyx Assassin",
            "Zeus": -0.53
        },
        {
            "name": "Ogre Magi",
            "Zeus": 0.504
        },
        {
            "name": "Omniknight",
            "Zeus": 0.263
        },
        {
            "name": "Oracle",
            "Zeus": 1.008
        },
        {
            "name": "Outworld Destroyer",
            "Zeus": -0.84
        },
        {
            "name": "Pangolier",
            "Zeus": 0.637
        },
        {
            "name": "Phantom Assassin",
            "Zeus": -0.525
        },
        {
            "name": "Phantom Lancer",
            "Zeus": 0.121
        },
        {
            "name": "Phoenix",
            "Zeus": 0.152
        },
        {
            "name": "Primal Beast",
            "Zeus": 0.454
        },
        {
            "name": "Puck",
            "Zeus": -0.81
        },
        {
            "name": "Pudge",
            "Zeus": 0.605
        },
        {
            "name": "Pugna",
            "Zeus": 0.732
        },
        {
            "name": "Queen of Pain",
            "Zeus": 1.32
        },
        {
            "name": "Razor",
            "Zeus": 0.353
        },
        {
            "name": "Riki",
            "Zeus": -1.743
        },
        {
            "name": "Ringmaster",
            "Zeus": 0.171
        },
        {
            "name": "Rubick",
            "Zeus": 0.27
        },
        {
            "name": "Sand King",
            "Zeus": -2.686
        },
        {
            "name": "Shadow Demon",
            "Zeus": 0.298
        },
        {
            "name": "Shadow Fiend",
            "Zeus": -1.484
        },
        {
            "name": "Shadow Shaman",
            "Zeus": -0.159
        },
        {
            "name": "Silencer",
            "Zeus": -0.047
        },
        {
            "name": "Skywrath Mage",
            "Zeus": 1.599
        },
        {
            "name": "Slardar",
            "Zeus": 0.093
        },
        {
            "name": "Slark",
            "Zeus": -0.676
        },
        {
            "name": "Snapfire",
            "Zeus": 0.239
        },
        {
            "name": "Sniper",
            "Zeus": -1.13
        },
        {
            "name": "Spectre",
            "Zeus": 1.893
        },
        {
            "name": "Spirit Breaker",
            "Zeus": 0.119
        },
        {
            "name": "Storm Spirit",
            "Zeus": 2.313
        },
        {
            "name": "Sven",
            "Zeus": 1.021
        },
        {
            "name": "Techies",
            "Zeus": 0.231
        },
        {
            "name": "Templar Assassin",
            "Zeus": 1.003
        },
        {
            "name": "Terrorblade",
            "Zeus": 0.402
        },
        {
            "name": "Tidehunter",
            "Zeus": -0.35
        },
        {
            "name": "Timbersaw",
            "Zeus": -0.107
        },
        {
            "name": "Tinker",
            "Zeus": 0.237
        },
        {
            "name": "Tiny",
            "Zeus": 0.061
        },
        {
            "name": "Treant Protector",
            "Zeus": -0.013
        },
        {
            "name": "Troll Warlord",
            "Zeus": -0.258
        },
        {
            "name": "Tusk",
            "Zeus": 0.247
        },
        {
            "name": "Underlord",
            "Zeus": 1.366
        },
        {
            "name": "Undying",
            "Zeus": 0.863
        },
        {
            "name": "Ursa",
            "Zeus": -0.4
        },
        {
            "name": "Vengeful Spirit",
            "Zeus": 0.493
        },
        {
            "name": "Venomancer",
            "Zeus": 0.27
        },
        {
            "name": "Viper",
            "Zeus": 1.24
        },
        {
            "name": "Visage",
            "Zeus": 1.681
        },
        {
            "name": "Void Spirit",
            "Zeus": 1.35
        },
        {
            "name": "Warlock",
            "Zeus": -0.092
        },
        {
            "name": "Weaver",
            "Zeus": -0.407
        },
        {
            "name": "Windranger",
            "Zeus": 0.043
        },
        {
            "name": "Winter Wyvern",
            "Zeus": -1.17
        },
        {
            "name": "Witch Doctor",
            "Zeus": -1.181
        },
        {
            "name": "Wraith King",
            "Zeus": 0.256
        }
    ],
    "Abaddon": [
        {
            "name": "Alchemist",
            "Abaddon": 1.198
        },
        {
            "name": "Ancient Apparition",
            "Abaddon": 1.811
        },
        {
            "name": "Anti-Mage",
            "Abaddon": 3.099
        },
        {
            "name": "Arc Warden",
            "Abaddon": -1.586
        },
        {
            "name": "Axe",
            "Abaddon": -2.732
        },
        {
            "name": "Bane",
            "Abaddon": -2.825
        },
        {
            "name": "Batrider",
            "Abaddon": -0.122
        },
        {
            "name": "Beastmaster",
            "Abaddon": 0.756
        },
        {
            "name": "Bloodseeker",
            "Abaddon": -0.168
        },
        {
            "name": "Bounty Hunter",
            "Abaddon": -2.185
        },
        {
            "name": "Brewmaster",
            "Abaddon": -0.334
        },
        {
            "name": "Bristleback",
            "Abaddon": -0.656
        },
        {
            "name": "Broodmother",
            "Abaddon": 1.052
        },
        {
            "name": "Centaur Warrunner",
            "Abaddon": -0.491
        },
        {
            "name": "Chaos Knight",
            "Abaddon": 0.665
        },
        {
            "name": "Chen",
            "Abaddon": 1.791
        },
        {
            "name": "Clinkz",
            "Abaddon": -1.619
        },
        {
            "name": "Clockwerk",
            "Abaddon": 0.723
        },
        {
            "name": "Crystal Maiden",
            "Abaddon": 0.151
        },
        {
            "name": "Dark Seer",
            "Abaddon": 3.145
        },
        {
            "name": "Dark Willow",
            "Abaddon": 0.951
        },
        {
            "name": "Dawnbreaker",
            "Abaddon": 1.261
        },
        {
            "name": "Dazzle",
            "Abaddon": -1.018
        },
        {
            "name": "Death Prophet",
            "Abaddon": 1.222
        },
        {
            "name": "Disruptor",
            "Abaddon": -0.054
        },
        {
            "name": "Doom",
            "Abaddon": 3.478
        },
        {
            "name": "Dragon Knight",
            "Abaddon": 0.798
        },
        {
            "name": "Drow Ranger",
            "Abaddon": 0.973
        },
        {
            "name": "Earth Spirit",
            "Abaddon": -1.26
        },
        {
            "name": "Earthshaker",
            "Abaddon": 2.103
        },
        {
            "name": "Elder Titan",
            "Abaddon": 3.771
        },
        {
            "name": "Ember Spirit",
            "Abaddon": 0.05
        },
        {
            "name": "Enchantress",
            "Abaddon": 0.962
        },
        {
            "name": "Enigma",
            "Abaddon": -0.247
        },
        {
            "name": "Faceless Void",
            "Abaddon": -0.397
        },
        {
            "name": "Grimstroke",
            "Abaddon": 1.131
        },
        {
            "name": "Gyrocopter",
            "Abaddon": 0.103
        },
        {
            "name": "Hoodwink",
            "Abaddon": 0.517
        },
        {
            "name": "Huskar",
            "Abaddon": 0.068
        },
        {
            "name": "Invoker",
            "Abaddon": 0.465
        },
        {
            "name": "Io",
            "Abaddon": 2.337
        },
        {
            "name": "Jakiro",
            "Abaddon": -1.268
        },
        {
            "name": "Juggernaut",
            "Abaddon": -0.555
        },
        {
            "name": "Keeper of the Light",
            "Abaddon": 1.955
        },
        {
            "name": "Kez",
            "Abaddon": -1.111
        },
        {
            "name": "Kunkka",
            "Abaddon": 1.803
        },
        {
            "name": "Legion Commander",
            "Abaddon": -1.513
        },
        {
            "name": "Leshrac",
            "Abaddon": 0.491
        },
        {
            "name": "Lich",
            "Abaddon": 0.458
        },
        {
            "name": "Lifestealer",
            "Abaddon": -0.376
        },
        {
            "name": "Lina",
            "Abaddon": 1.355
        },
        {
            "name": "Lion",
            "Abaddon": 0.495
        },
        {
            "name": "Lone Druid",
            "Abaddon": 2.735
        },
        {
            "name": "Luna",
            "Abaddon": 0.23
        },
        {
            "name": "Lycan",
            "Abaddon": 1.071
        },
        {
            "name": "Magnus",
            "Abaddon": 1.31
        },
        {
            "name": "Marci",
            "Abaddon": 2.792
        },
        {
            "name": "Mars",
            "Abaddon": 0.856
        },
        {
            "name": "Medusa",
            "Abaddon": 1.086
        },
        {
            "name": "Meepo",
            "Abaddon": 3.453
        },
        {
            "name": "Mirana",
            "Abaddon": -0.563
        },
        {
            "name": "Monkey King",
            "Abaddon": 2.916
        },
        {
            "name": "Morphling",
            "Abaddon": 0.235
        },
        {
            "name": "Muerta",
            "Abaddon": 0.849
        },
        {
            "name": "Naga Siren",
            "Abaddon": -0.259
        },
        {
            "name": "Nature's Prophet",
            "Abaddon": 1.255
        },
        {
            "name": "Necrophos",
            "Abaddon": 0.416
        },
        {
            "name": "Night Stalker",
            "Abaddon": -0.117
        },
        {
            "name": "Nyx Assassin",
            "Abaddon": 0.777
        },
        {
            "name": "Ogre Magi",
            "Abaddon": -0.786
        },
        {
            "name": "Omniknight",
            "Abaddon": 1.412
        },
        {
            "name": "Oracle",
            "Abaddon": 0.584
        },
        {
            "name": "Outworld Destroyer",
            "Abaddon": 3.272
        },
        {
            "name": "Pangolier",
            "Abaddon": 1.248
        },
        {
            "name": "Phantom Assassin",
            "Abaddon": 0.118
        },
        {
            "name": "Phantom Lancer",
            "Abaddon": 1.883
        },
        {
            "name": "Phoenix",
            "Abaddon": -0.765
        },
        {
            "name": "Primal Beast",
            "Abaddon": 1.901
        },
        {
            "name": "Puck",
            "Abaddon": -1.429
        },
        {
            "name": "Pudge",
            "Abaddon": 0.346
        },
        {
            "name": "Pugna",
            "Abaddon": 1.218
        },
        {
            "name": "Queen of Pain",
            "Abaddon": -1.337
        },
        {
            "name": "Razor",
            "Abaddon": 1.148
        },
        {
            "name": "Riki",
            "Abaddon": 0.54
        },
        {
            "name": "Ringmaster",
            "Abaddon": 0.426
        },
        {
            "name": "Rubick",
            "Abaddon": 1.376
        },
        {
            "name": "Sand King",
            "Abaddon": -0.325
        },
        {
            "name": "Shadow Demon",
            "Abaddon": 2.418
        },
        {
            "name": "Shadow Fiend",
            "Abaddon": 1.591
        },
        {
            "name": "Shadow Shaman",
            "Abaddon": -1.118
        },
        {
            "name": "Silencer",
            "Abaddon": -1.874
        },
        {
            "name": "Skywrath Mage",
            "Abaddon": -1.737
        },
        {
            "name": "Slardar",
            "Abaddon": -0.029
        },
        {
            "name": "Slark",
            "Abaddon": 1.989
        },
        {
            "name": "Snapfire",
            "Abaddon": 0.759
        },
        {
            "name": "Sniper",
            "Abaddon": 0.202
        },
        {
            "name": "Spectre",
            "Abaddon": -4.63
        },
        {
            "name": "Spirit Breaker",
            "Abaddon": 0.192
        },
        {
            "name": "Storm Spirit",
            "Abaddon": -1.2
        },
        {
            "name": "Sven",
            "Abaddon": 2.456
        },
        {
            "name": "Techies",
            "Abaddon": 0.574
        },
        {
            "name": "Templar Assassin",
            "Abaddon": 1.176
        },
        {
            "name": "Terrorblade",
            "Abaddon": 1.958
        },
        {
            "name": "Tidehunter",
            "Abaddon": -0.311
        },
        {
            "name": "Timbersaw",
            "Abaddon": 0.057
        },
        {
            "name": "Tinker",
            "Abaddon": 1.345
        },
        {
            "name": "Tiny",
            "Abaddon": 0.892
        },
        {
            "name": "Treant Protector",
            "Abaddon": 0.503
        },
        {
            "name": "Troll Warlord",
            "Abaddon": -0.161
        },
        {
            "name": "Tusk",
            "Abaddon": 2.771
        },
        {
            "name": "Underlord",
            "Abaddon": 0.724
        },
        {
            "name": "Undying",
            "Abaddon": 3.144
        },
        {
            "name": "Ursa",
            "Abaddon": 0.891
        },
        {
            "name": "Vengeful Spirit",
            "Abaddon": 0.319
        },
        {
            "name": "Venomancer",
            "Abaddon": 0.121
        },
        {
            "name": "Viper",
            "Abaddon": 0.552
        },
        {
            "name": "Visage",
            "Abaddon": 0.51
        },
        {
            "name": "Void Spirit",
            "Abaddon": -0.709
        },
        {
            "name": "Warlock",
            "Abaddon": -0.045
        },
        {
            "name": "Weaver",
            "Abaddon": 1.367
        },
        {
            "name": "Windranger",
            "Abaddon": 0.666
        },
        {
            "name": "Winter Wyvern",
            "Abaddon": 0.248
        },
        {
            "name": "Witch Doctor",
            "Abaddon": -0.68
        },
        {
            "name": "Wraith King",
            "Abaddon": -2.056
        },
        {
            "name": "Zeus",
            "Abaddon": -0.253
        }
    ],
    "Bane": [
        {
            "name": "Abaddon",
            "Bane": 3.387
        },
        {
            "name": "Alchemist",
            "Bane": -1.235
        },
        {
            "name": "Ancient Apparition",
            "Bane": -1.224
        },
        {
            "name": "Anti-Mage",
            "Bane": -0.466
        },
        {
            "name": "Arc Warden",
            "Bane": 4.11
        },
        {
            "name": "Axe",
            "Bane": -0.107
        },
        {
            "name": "Batrider",
            "Bane": 0.112
        },
        {
            "name": "Beastmaster",
            "Bane": 1.019
        },
        {
            "name": "Bloodseeker",
            "Bane": 0.271
        },
        {
            "name": "Bounty Hunter",
            "Bane": 0.593
        },
        {
            "name": "Brewmaster",
            "Bane": -0.657
        },
        {
            "name": "Bristleback",
            "Bane": -0.057
        },
        {
            "name": "Broodmother",
            "Bane": -1.321
        },
        {
            "name": "Centaur Warrunner",
            "Bane": -1.325
        },
        {
            "name": "Chaos Knight",
            "Bane": 3.839
        },
        {
            "name": "Chen",
            "Bane": 1.516
        },
        {
            "name": "Clinkz",
            "Bane": 1.431
        },
        {
            "name": "Clockwerk",
            "Bane": 0.56
        },
        {
            "name": "Crystal Maiden",
            "Bane": 0.03
        },
        {
            "name": "Dark Seer",
            "Bane": -0.418
        },
        {
            "name": "Dark Willow",
            "Bane": 1.136
        },
        {
            "name": "Dawnbreaker",
            "Bane": 0.031
        },
        {
            "name": "Dazzle",
            "Bane": 0.212
        },
        {
            "name": "Death Prophet",
            "Bane": 0.973
        },
        {
            "name": "Disruptor",
            "Bane": -0.624
        },
        {
            "name": "Doom",
            "Bane": -0.72
        },
        {
            "name": "Dragon Knight",
            "Bane": 0.003
        },
        {
            "name": "Drow Ranger",
            "Bane": 0.886
        },
        {
            "name": "Earth Spirit",
            "Bane": -0.324
        },
        {
            "name": "Earthshaker",
            "Bane": 0.118
        },
        {
            "name": "Elder Titan",
            "Bane": -2.369
        },
        {
            "name": "Ember Spirit",
            "Bane": -2.622
        },
        {
            "name": "Enchantress",
            "Bane": -1.895
        },
        {
            "name": "Enigma",
            "Bane": -0.866
        },
        {
            "name": "Faceless Void",
            "Bane": -0.42
        },
        {
            "name": "Grimstroke",
            "Bane": 0.363
        },
        {
            "name": "Gyrocopter",
            "Bane": -0.656
        },
        {
            "name": "Hoodwink",
            "Bane": -0.857
        },
        {
            "name": "Huskar",
            "Bane": 1.08
        },
        {
            "name": "Invoker",
            "Bane": -0.799
        },
        {
            "name": "Io",
            "Bane": 0.231
        },
        {
            "name": "Jakiro",
            "Bane": 0.038
        },
        {
            "name": "Juggernaut",
            "Bane": -0.291
        },
        {
            "name": "Keeper of the Light",
            "Bane": -0.949
        },
        {
            "name": "Kez",
            "Bane": -2.239
        },
        {
            "name": "Kunkka",
            "Bane": -1.333
        },
        {
            "name": "Legion Commander",
            "Bane": -0.378
        },
        {
            "name": "Leshrac",
            "Bane": 0.201
        },
        {
            "name": "Lich",
            "Bane": -0.567
        },
        {
            "name": "Lifestealer",
            "Bane": -0.805
        },
        {
            "name": "Lina",
            "Bane": -1.381
        },
        {
            "name": "Lion",
            "Bane": -0.805
        },
        {
            "name": "Lone Druid",
            "Bane": 1.24
        },
        {
            "name": "Luna",
            "Bane": 0.348
        },
        {
            "name": "Lycan",
            "Bane": -0.907
        },
        {
            "name": "Magnus",
            "Bane": 0.027
        },
        {
            "name": "Marci",
            "Bane": 0.794
        },
        {
            "name": "Mars",
            "Bane": -1.176
        },
        {
            "name": "Medusa",
            "Bane": 0.306
        },
        {
            "name": "Meepo",
            "Bane": 3.054
        },
        {
            "name": "Mirana",
            "Bane": -0.636
        },
        {
            "name": "Monkey King",
            "Bane": -0.581
        },
        {
            "name": "Morphling",
            "Bane": -2.566
        },
        {
            "name": "Muerta",
            "Bane": -2.495
        },
        {
            "name": "Naga Siren",
            "Bane": 3.105
        },
        {
            "name": "Nature's Prophet",
            "Bane": 0.981
        },
        {
            "name": "Necrophos",
            "Bane": 1.492
        },
        {
            "name": "Night Stalker",
            "Bane": 0.108
        },
        {
            "name": "Nyx Assassin",
            "Bane": -0.115
        },
        {
            "name": "Ogre Magi",
            "Bane": -0.019
        },
        {
            "name": "Omniknight",
            "Bane": 0.032
        },
        {
            "name": "Oracle",
            "Bane": 0.061
        },
        {
            "name": "Outworld Destroyer",
            "Bane": -0.168
        },
        {
            "name": "Pangolier",
            "Bane": -0.864
        },
        {
            "name": "Phantom Assassin",
            "Bane": -1.987
        },
        {
            "name": "Phantom Lancer",
            "Bane": 1.94
        },
        {
            "name": "Phoenix",
            "Bane": -0.986
        },
        {
            "name": "Primal Beast",
            "Bane": -0.17
        },
        {
            "name": "Puck",
            "Bane": -1.981
        },
        {
            "name": "Pudge",
            "Bane": 1.456
        },
        {
            "name": "Pugna",
            "Bane": -1.096
        },
        {
            "name": "Queen of Pain",
            "Bane": -1.133
        },
        {
            "name": "Razor",
            "Bane": 0.632
        },
        {
            "name": "Riki",
            "Bane": 1.209
        },
        {
            "name": "Ringmaster",
            "Bane": -0.33
        },
        {
            "name": "Rubick",
            "Bane": -0.521
        },
        {
            "name": "Sand King",
            "Bane": 1.101
        },
        {
            "name": "Shadow Demon",
            "Bane": 0.115
        },
        {
            "name": "Shadow Fiend",
            "Bane": -0.86
        },
        {
            "name": "Shadow Shaman",
            "Bane": 1.032
        },
        {
            "name": "Silencer",
            "Bane": 2.458
        },
        {
            "name": "Skywrath Mage",
            "Bane": -0.956
        },
        {
            "name": "Slardar",
            "Bane": 1.445
        },
        {
            "name": "Slark",
            "Bane": 3.106
        },
        {
            "name": "Snapfire",
            "Bane": -0.484
        },
        {
            "name": "Sniper",
            "Bane": -0.217
        },
        {
            "name": "Spectre",
            "Bane": 1.749
        },
        {
            "name": "Spirit Breaker",
            "Bane": 0.821
        },
        {
            "name": "Storm Spirit",
            "Bane": -0.652
        },
        {
            "name": "Sven",
            "Bane": 0.207
        },
        {
            "name": "Techies",
            "Bane": -0.797
        },
        {
            "name": "Templar Assassin",
            "Bane": -0.203
        },
        {
            "name": "Terrorblade",
            "Bane": 2.022
        },
        {
            "name": "Tidehunter",
            "Bane": 1.826
        },
        {
            "name": "Timbersaw",
            "Bane": -0.843
        },
        {
            "name": "Tinker",
            "Bane": -0.517
        },
        {
            "name": "Tiny",
            "Bane": -0.296
        },
        {
            "name": "Treant Protector",
            "Bane": 1.708
        },
        {
            "name": "Troll Warlord",
            "Bane": -0.526
        },
        {
            "name": "Tusk",
            "Bane": -0.481
        },
        {
            "name": "Underlord",
            "Bane": 1.225
        },
        {
            "name": "Undying",
            "Bane": -0.059
        },
        {
            "name": "Ursa",
            "Bane": 1.131
        },
        {
            "name": "Vengeful Spirit",
            "Bane": 0.635
        },
        {
            "name": "Venomancer",
            "Bane": 0.861
        },
        {
            "name": "Viper",
            "Bane": -0.223
        },
        {
            "name": "Visage",
            "Bane": 1.301
        },
        {
            "name": "Void Spirit",
            "Bane": -0.288
        },
        {
            "name": "Warlock",
            "Bane": 0.621
        },
        {
            "name": "Weaver",
            "Bane": -0.797
        },
        {
            "name": "Windranger",
            "Bane": -0.515
        },
        {
            "name": "Winter Wyvern",
            "Bane": 2.094
        },
        {
            "name": "Witch Doctor",
            "Bane": 0.374
        },
        {
            "name": "Wraith King",
            "Bane": 0.334
        },
        {
            "name": "Zeus",
            "Bane": -0.518
        }
    ],
    "Batrider": [
        {
            "name": "Abaddon",
            "Batrider": 0.99
        },
        {
            "name": "Alchemist",
            "Batrider": -1.319
        },
        {
            "name": "Ancient Apparition",
            "Batrider": 0.756
        },
        {
            "name": "Anti-Mage",
            "Batrider": -0.591
        },
        {
            "name": "Arc Warden",
            "Batrider": 0.305
        },
        {
            "name": "Axe",
            "Batrider": -1.096
        },
        {
            "name": "Bane",
            "Batrider": -0.084
        },
        {
            "name": "Beastmaster",
            "Batrider": 0.424
        },
        {
            "name": "Bloodseeker",
            "Batrider": 0.955
        },
        {
            "name": "Bounty Hunter",
            "Batrider": -1.021
        },
        {
            "name": "Brewmaster",
            "Batrider": -1.382
        },
        {
            "name": "Bristleback",
            "Batrider": -0.076
        },
        {
            "name": "Broodmother",
            "Batrider": -2.232
        },
        {
            "name": "Centaur Warrunner",
            "Batrider": -0.337
        },
        {
            "name": "Chaos Knight",
            "Batrider": 0.046
        },
        {
            "name": "Chen",
            "Batrider": -2.934
        },
        {
            "name": "Clinkz",
            "Batrider": 0.6
        },
        {
            "name": "Clockwerk",
            "Batrider": -0.588
        },
        {
            "name": "Crystal Maiden",
            "Batrider": 1.456
        },
        {
            "name": "Dark Seer",
            "Batrider": -0.382
        },
        {
            "name": "Dark Willow",
            "Batrider": -0.531
        },
        {
            "name": "Dawnbreaker",
            "Batrider": 0.169
        },
        {
            "name": "Dazzle",
            "Batrider": 0.031
        },
        {
            "name": "Death Prophet",
            "Batrider": 0.318
        },
        {
            "name": "Disruptor",
            "Batrider": -0.492
        },
        {
            "name": "Doom",
            "Batrider": -2.427
        },
        {
            "name": "Dragon Knight",
            "Batrider": 0.078
        },
        {
            "name": "Drow Ranger",
            "Batrider": -1.47
        },
        {
            "name": "Earth Spirit",
            "Batrider": -0.236
        },
        {
            "name": "Earthshaker",
            "Batrider": -0.69
        },
        {
            "name": "Elder Titan",
            "Batrider": -1.014
        },
        {
            "name": "Ember Spirit",
            "Batrider": -0.048
        },
        {
            "name": "Enchantress",
            "Batrider": 2.414
        },
        {
            "name": "Enigma",
            "Batrider": 1.844
        },
        {
            "name": "Faceless Void",
            "Batrider": 1.808
        },
        {
            "name": "Grimstroke",
            "Batrider": 1.32
        },
        {
            "name": "Gyrocopter",
            "Batrider": 0.212
        },
        {
            "name": "Hoodwink",
            "Batrider": 0.061
        },
        {
            "name": "Huskar",
            "Batrider": 1.955
        },
        {
            "name": "Invoker",
            "Batrider": -0.92
        },
        {
            "name": "Io",
            "Batrider": -0.585
        },
        {
            "name": "Jakiro",
            "Batrider": 1.2
        },
        {
            "name": "Juggernaut",
            "Batrider": 0.602
        },
        {
            "name": "Keeper of the Light",
            "Batrider": -1.65
        },
        {
            "name": "Kez",
            "Batrider": -0.389
        },
        {
            "name": "Kunkka",
            "Batrider": 0.666
        },
        {
            "name": "Legion Commander",
            "Batrider": 1.099
        },
        {
            "name": "Leshrac",
            "Batrider": 0.928
        },
        {
            "name": "Lich",
            "Batrider": 0.77
        },
        {
            "name": "Lifestealer",
            "Batrider": 0.145
        },
        {
            "name": "Lina",
            "Batrider": -1.13
        },
        {
            "name": "Lion",
            "Batrider": 0.831
        },
        {
            "name": "Lone Druid",
            "Batrider": -0.528
        },
        {
            "name": "Luna",
            "Batrider": 0.591
        },
        {
            "name": "Lycan",
            "Batrider": -1.732
        },
        {
            "name": "Magnus",
            "Batrider": 0.307
        },
        {
            "name": "Marci",
            "Batrider": -2.043
        },
        {
            "name": "Mars",
            "Batrider": -0.765
        },
        {
            "name": "Medusa",
            "Batrider": 0.859
        },
        {
            "name": "Meepo",
            "Batrider": -2.251
        },
        {
            "name": "Mirana",
            "Batrider": 2.025
        },
        {
            "name": "Monkey King",
            "Batrider": -2.366
        },
        {
            "name": "Morphling",
            "Batrider": 0.422
        },
        {
            "name": "Muerta",
            "Batrider": -1.188
        },
        {
            "name": "Naga Siren",
            "Batrider": 1.768
        },
        {
            "name": "Nature's Prophet",
            "Batrider": -1.393
        },
        {
            "name": "Necrophos",
            "Batrider": 0.241
        },
        {
            "name": "Night Stalker",
            "Batrider": -1.228
        },
        {
            "name": "Nyx Assassin",
            "Batrider": -0.8
        },
        {
            "name": "Ogre Magi",
            "Batrider": 0.665
        },
        {
            "name": "Omniknight",
            "Batrider": 3.011
        },
        {
            "name": "Oracle",
            "Batrider": 0.587
        },
        {
            "name": "Outworld Destroyer",
            "Batrider": 0.827
        },
        {
            "name": "Pangolier",
            "Batrider": 0.4
        },
        {
            "name": "Phantom Assassin",
            "Batrider": -0.325
        },
        {
            "name": "Phantom Lancer",
            "Batrider": 0.688
        },
        {
            "name": "Phoenix",
            "Batrider": -0.129
        },
        {
            "name": "Primal Beast",
            "Batrider": -0.899
        },
        {
            "name": "Puck",
            "Batrider": 2.111
        },
        {
            "name": "Pudge",
            "Batrider": -1.565
        },
        {
            "name": "Pugna",
            "Batrider": 1.535
        },
        {
            "name": "Queen of Pain",
            "Batrider": 1.547
        },
        {
            "name": "Razor",
            "Batrider": -0.148
        },
        {
            "name": "Riki",
            "Batrider": 1.125
        },
        {
            "name": "Ringmaster",
            "Batrider": -1.258
        },
        {
            "name": "Rubick",
            "Batrider": -1.626
        },
        {
            "name": "Sand King",
            "Batrider": 0.54
        },
        {
            "name": "Shadow Demon",
            "Batrider": -0.392
        },
        {
            "name": "Shadow Fiend",
            "Batrider": -0.815
        },
        {
            "name": "Shadow Shaman",
            "Batrider": 0.125
        },
        {
            "name": "Silencer",
            "Batrider": 0.068
        },
        {
            "name": "Skywrath Mage",
            "Batrider": 0.109
        },
        {
            "name": "Slardar",
            "Batrider": 1.242
        },
        {
            "name": "Slark",
            "Batrider": 0.729
        },
        {
            "name": "Snapfire",
            "Batrider": -0.145
        },
        {
            "name": "Sniper",
            "Batrider": 0.522
        },
        {
            "name": "Spectre",
            "Batrider": -0.191
        },
        {
            "name": "Spirit Breaker",
            "Batrider": 1.077
        },
        {
            "name": "Storm Spirit",
            "Batrider": 1.18
        },
        {
            "name": "Sven",
            "Batrider": 1.882
        },
        {
            "name": "Techies",
            "Batrider": -1.032
        },
        {
            "name": "Templar Assassin",
            "Batrider": -3.341
        },
        {
            "name": "Terrorblade",
            "Batrider": -0.202
        },
        {
            "name": "Tidehunter",
            "Batrider": 1.146
        },
        {
            "name": "Timbersaw",
            "Batrider": -1.716
        },
        {
            "name": "Tinker",
            "Batrider": 0.31
        },
        {
            "name": "Tiny",
            "Batrider": 0.57
        },
        {
            "name": "Treant Protector",
            "Batrider": -0.585
        },
        {
            "name": "Troll Warlord",
            "Batrider": -0.192
        },
        {
            "name": "Tusk",
            "Batrider": -0.131
        },
        {
            "name": "Underlord",
            "Batrider": -0.182
        },
        {
            "name": "Undying",
            "Batrider": 0.314
        },
        {
            "name": "Ursa",
            "Batrider": -1.571
        },
        {
            "name": "Vengeful Spirit",
            "Batrider": 0.13
        },
        {
            "name": "Venomancer",
            "Batrider": 0.416
        },
        {
            "name": "Viper",
            "Batrider": 2.704
        },
        {
            "name": "Visage",
            "Batrider": -0.797
        },
        {
            "name": "Void Spirit",
            "Batrider": 0.099
        },
        {
            "name": "Warlock",
            "Batrider": 0.383
        },
        {
            "name": "Weaver",
            "Batrider": 2.627
        },
        {
            "name": "Windranger",
            "Batrider": -0.435
        },
        {
            "name": "Winter Wyvern",
            "Batrider": 0.558
        },
        {
            "name": "Witch Doctor",
            "Batrider": 0.257
        },
        {
            "name": "Wraith King",
            "Batrider": 1.204
        },
        {
            "name": "Zeus",
            "Batrider": -0.932
        }
    ],
    "Beastmaster": [
        {
            "name": "Abaddon",
            "Beastmaster": -0.278
        },
        {
            "name": "Alchemist",
            "Beastmaster": 1.871
        },
        {
            "name": "Ancient Apparition",
            "Beastmaster": -0.37
        },
        {
            "name": "Anti-Mage",
            "Beastmaster": -2.345
        },
        {
            "name": "Arc Warden",
            "Beastmaster": 0.008
        },
        {
            "name": "Axe",
            "Beastmaster": -0.423
        },
        {
            "name": "Bane",
            "Beastmaster": -1.073
        },
        {
            "name": "Batrider",
            "Beastmaster": -0.403
        },
        {
            "name": "Bloodseeker",
            "Beastmaster": -0.543
        },
        {
            "name": "Bounty Hunter",
            "Beastmaster": 0.405
        },
        {
            "name": "Brewmaster",
            "Beastmaster": 0.776
        },
        {
            "name": "Bristleback",
            "Beastmaster": 4.122
        },
        {
            "name": "Broodmother",
            "Beastmaster": -1.316
        },
        {
            "name": "Centaur Warrunner",
            "Beastmaster": -0.726
        },
        {
            "name": "Chaos Knight",
            "Beastmaster": -0.306
        },
        {
            "name": "Chen",
            "Beastmaster": 8.025
        },
        {
            "name": "Clinkz",
            "Beastmaster": 2.857
        },
        {
            "name": "Clockwerk",
            "Beastmaster": -3.931
        },
        {
            "name": "Crystal Maiden",
            "Beastmaster": 0.753
        },
        {
            "name": "Dark Seer",
            "Beastmaster": 3.235
        },
        {
            "name": "Dark Willow",
            "Beastmaster": -0.599
        },
        {
            "name": "Dawnbreaker",
            "Beastmaster": 1.734
        },
        {
            "name": "Dazzle",
            "Beastmaster": 1.73
        },
        {
            "name": "Death Prophet",
            "Beastmaster": 0.005
        },
        {
            "name": "Disruptor",
            "Beastmaster": -1.385
        },
        {
            "name": "Doom",
            "Beastmaster": -1.153
        },
        {
            "name": "Dragon Knight",
            "Beastmaster": 0.107
        },
        {
            "name": "Drow Ranger",
            "Beastmaster": 0.993
        },
        {
            "name": "Earth Spirit",
            "Beastmaster": 1.148
        },
        {
            "name": "Earthshaker",
            "Beastmaster": 2.248
        },
        {
            "name": "Elder Titan",
            "Beastmaster": 3.239
        },
        {
            "name": "Ember Spirit",
            "Beastmaster": 1.86
        },
        {
            "name": "Enchantress",
            "Beastmaster": 2.292
        },
        {
            "name": "Enigma",
            "Beastmaster": 1.039
        },
        {
            "name": "Faceless Void",
            "Beastmaster": -1.244
        },
        {
            "name": "Grimstroke",
            "Beastmaster": -1.126
        },
        {
            "name": "Gyrocopter",
            "Beastmaster": 3.515
        },
        {
            "name": "Hoodwink",
            "Beastmaster": 0.345
        },
        {
            "name": "Huskar",
            "Beastmaster": -0.671
        },
        {
            "name": "Invoker",
            "Beastmaster": -0.786
        },
        {
            "name": "Io",
            "Beastmaster": 0.109
        },
        {
            "name": "Jakiro",
            "Beastmaster": 1.984
        },
        {
            "name": "Juggernaut",
            "Beastmaster": -0.359
        },
        {
            "name": "Keeper of the Light",
            "Beastmaster": 3.515
        },
        {
            "name": "Kez",
            "Beastmaster": 0.211
        },
        {
            "name": "Kunkka",
            "Beastmaster": 1.084
        },
        {
            "name": "Legion Commander",
            "Beastmaster": 0.003
        },
        {
            "name": "Leshrac",
            "Beastmaster": 0.356
        },
        {
            "name": "Lich",
            "Beastmaster": 0.577
        },
        {
            "name": "Lifestealer",
            "Beastmaster": -1.224
        },
        {
            "name": "Lina",
            "Beastmaster": 0.647
        },
        {
            "name": "Lion",
            "Beastmaster": -2.186
        },
        {
            "name": "Lone Druid",
            "Beastmaster": -1.028
        },
        {
            "name": "Luna",
            "Beastmaster": -0.359
        },
        {
            "name": "Lycan",
            "Beastmaster": -0.382
        },
        {
            "name": "Magnus",
            "Beastmaster": 0.139
        },
        {
            "name": "Marci",
            "Beastmaster": 0.393
        },
        {
            "name": "Mars",
            "Beastmaster": -1.4
        },
        {
            "name": "Medusa",
            "Beastmaster": 5.004
        },
        {
            "name": "Meepo",
            "Beastmaster": 2.664
        },
        {
            "name": "Mirana",
            "Beastmaster": -0.073
        },
        {
            "name": "Monkey King",
            "Beastmaster": 0.048
        },
        {
            "name": "Morphling",
            "Beastmaster": -2.244
        },
        {
            "name": "Muerta",
            "Beastmaster": 0.248
        },
        {
            "name": "Naga Siren",
            "Beastmaster": 1.388
        },
        {
            "name": "Nature's Prophet",
            "Beastmaster": 0.092
        },
        {
            "name": "Necrophos",
            "Beastmaster": -0.419
        },
        {
            "name": "Night Stalker",
            "Beastmaster": -3.931
        },
        {
            "name": "Nyx Assassin",
            "Beastmaster": -1.864
        },
        {
            "name": "Ogre Magi",
            "Beastmaster": -1.905
        },
        {
            "name": "Omniknight",
            "Beastmaster": 0.512
        },
        {
            "name": "Oracle",
            "Beastmaster": -1.157
        },
        {
            "name": "Outworld Destroyer",
            "Beastmaster": -1.239
        },
        {
            "name": "Pangolier",
            "Beastmaster": 2.563
        },
        {
            "name": "Phantom Assassin",
            "Beastmaster": 0.231
        },
        {
            "name": "Phantom Lancer",
            "Beastmaster": -1.135
        },
        {
            "name": "Phoenix",
            "Beastmaster": 2.027
        },
        {
            "name": "Primal Beast",
            "Beastmaster": 0.278
        },
        {
            "name": "Puck",
            "Beastmaster": -1.06
        },
        {
            "name": "Pudge",
            "Beastmaster": -1.867
        },
        {
            "name": "Pugna",
            "Beastmaster": 0.846
        },
        {
            "name": "Queen of Pain",
            "Beastmaster": 0.191
        },
        {
            "name": "Razor",
            "Beastmaster": 0.631
        },
        {
            "name": "Riki",
            "Beastmaster": -0.766
        },
        {
            "name": "Ringmaster",
            "Beastmaster": 0.098
        },
        {
            "name": "Rubick",
            "Beastmaster": 1.406
        },
        {
            "name": "Sand King",
            "Beastmaster": 3.981
        },
        {
            "name": "Shadow Demon",
            "Beastmaster": 0.688
        },
        {
            "name": "Shadow Fiend",
            "Beastmaster": 0.99
        },
        {
            "name": "Shadow Shaman",
            "Beastmaster": -0.917
        },
        {
            "name": "Silencer",
            "Beastmaster": -0.982
        },
        {
            "name": "Skywrath Mage",
            "Beastmaster": -0.798
        },
        {
            "name": "Slardar",
            "Beastmaster": -0.944
        },
        {
            "name": "Slark",
            "Beastmaster": -0.285
        },
        {
            "name": "Snapfire",
            "Beastmaster": 1.878
        },
        {
            "name": "Sniper",
            "Beastmaster": 1.132
        },
        {
            "name": "Spectre",
            "Beastmaster": -3.968
        },
        {
            "name": "Spirit Breaker",
            "Beastmaster": -1.325
        },
        {
            "name": "Storm Spirit",
            "Beastmaster": -1.104
        },
        {
            "name": "Sven",
            "Beastmaster": 1.305
        },
        {
            "name": "Techies",
            "Beastmaster": 0.685
        },
        {
            "name": "Templar Assassin",
            "Beastmaster": -0.02
        },
        {
            "name": "Terrorblade",
            "Beastmaster": 1.072
        },
        {
            "name": "Tidehunter",
            "Beastmaster": 2.403
        },
        {
            "name": "Timbersaw",
            "Beastmaster": -0.415
        },
        {
            "name": "Tinker",
            "Beastmaster": 0.341
        },
        {
            "name": "Tiny",
            "Beastmaster": 0.054
        },
        {
            "name": "Treant Protector",
            "Beastmaster": 1.834
        },
        {
            "name": "Troll Warlord",
            "Beastmaster": 2.581
        },
        {
            "name": "Tusk",
            "Beastmaster": 0.248
        },
        {
            "name": "Underlord",
            "Beastmaster": 2.543
        },
        {
            "name": "Undying",
            "Beastmaster": 1.106
        },
        {
            "name": "Ursa",
            "Beastmaster": -0.689
        },
        {
            "name": "Vengeful Spirit",
            "Beastmaster": -1.134
        },
        {
            "name": "Venomancer",
            "Beastmaster": 0.699
        },
        {
            "name": "Viper",
            "Beastmaster": 1.46
        },
        {
            "name": "Visage",
            "Beastmaster": -1.681
        },
        {
            "name": "Void Spirit",
            "Beastmaster": -0.984
        },
        {
            "name": "Warlock",
            "Beastmaster": 2.631
        },
        {
            "name": "Weaver",
            "Beastmaster": 1.435
        },
        {
            "name": "Windranger",
            "Beastmaster": 1.128
        },
        {
            "name": "Winter Wyvern",
            "Beastmaster": 1.29
        },
        {
            "name": "Witch Doctor",
            "Beastmaster": 0.478
        },
        {
            "name": "Wraith King",
            "Beastmaster": -2.735
        },
        {
            "name": "Zeus",
            "Beastmaster": -1.486
        }
    ],
    "Brewmaster": [
        {
            "name": "Abaddon",
            "Brewmaster": 0.872
        },
        {
            "name": "Alchemist",
            "Brewmaster": 1.125
        },
        {
            "name": "Ancient Apparition",
            "Brewmaster": -1.57
        },
        {
            "name": "Anti-Mage",
            "Brewmaster": 1.549
        },
        {
            "name": "Arc Warden",
            "Brewmaster": -0.543
        },
        {
            "name": "Axe",
            "Brewmaster": 0.195
        },
        {
            "name": "Bane",
            "Brewmaster": 0.517
        },
        {
            "name": "Batrider",
            "Brewmaster": 1.344
        },
        {
            "name": "Beastmaster",
            "Brewmaster": -0.893
        },
        {
            "name": "Bloodseeker",
            "Brewmaster": -0.616
        },
        {
            "name": "Bounty Hunter",
            "Brewmaster": 0.895
        },
        {
            "name": "Bristleback",
            "Brewmaster": 1.672
        },
        {
            "name": "Broodmother",
            "Brewmaster": -1.406
        },
        {
            "name": "Centaur Warrunner",
            "Brewmaster": -2.412
        },
        {
            "name": "Chaos Knight",
            "Brewmaster": -0.089
        },
        {
            "name": "Chen",
            "Brewmaster": 2.025
        },
        {
            "name": "Clinkz",
            "Brewmaster": 1.878
        },
        {
            "name": "Clockwerk",
            "Brewmaster": 0.341
        },
        {
            "name": "Crystal Maiden",
            "Brewmaster": 0.391
        },
        {
            "name": "Dark Seer",
            "Brewmaster": 0.091
        },
        {
            "name": "Dark Willow",
            "Brewmaster": 0.093
        },
        {
            "name": "Dawnbreaker",
            "Brewmaster": 1.517
        },
        {
            "name": "Dazzle",
            "Brewmaster": 1.393
        },
        {
            "name": "Death Prophet",
            "Brewmaster": 1.213
        },
        {
            "name": "Disruptor",
            "Brewmaster": 0.511
        },
        {
            "name": "Doom",
            "Brewmaster": 0.34
        },
        {
            "name": "Dragon Knight",
            "Brewmaster": -0.942
        },
        {
            "name": "Drow Ranger",
            "Brewmaster": -0.54
        },
        {
            "name": "Earth Spirit",
            "Brewmaster": -0.632
        },
        {
            "name": "Earthshaker",
            "Brewmaster": 0.438
        },
        {
            "name": "Elder Titan",
            "Brewmaster": 0.661
        },
        {
            "name": "Ember Spirit",
            "Brewmaster": -0.19
        },
        {
            "name": "Enchantress",
            "Brewmaster": 0.922
        },
        {
            "name": "Enigma",
            "Brewmaster": 0.182
        },
        {
            "name": "Faceless Void",
            "Brewmaster": 1.96
        },
        {
            "name": "Grimstroke",
            "Brewmaster": 0.021
        },
        {
            "name": "Gyrocopter",
            "Brewmaster": 1.355
        },
        {
            "name": "Hoodwink",
            "Brewmaster": -0.416
        },
        {
            "name": "Huskar",
            "Brewmaster": 1.44
        },
        {
            "name": "Invoker",
            "Brewmaster": 0.101
        },
        {
            "name": "Io",
            "Brewmaster": 1.935
        },
        {
            "name": "Jakiro",
            "Brewmaster": 1.291
        },
        {
            "name": "Juggernaut",
            "Brewmaster": 0.371
        },
        {
            "name": "Keeper of the Light",
            "Brewmaster": 1.177
        },
        {
            "name": "Kez",
            "Brewmaster": 1.639
        },
        {
            "name": "Kunkka",
            "Brewmaster": -0.777
        },
        {
            "name": "Legion Commander",
            "Brewmaster": 1.432
        },
        {
            "name": "Leshrac",
            "Brewmaster": -0.36
        },
        {
            "name": "Lich",
            "Brewmaster": -1.256
        },
        {
            "name": "Lifestealer",
            "Brewmaster": 0.097
        },
        {
            "name": "Lina",
            "Brewmaster": 1.67
        },
        {
            "name": "Lion",
            "Brewmaster": 0.089
        },
        {
            "name": "Lone Druid",
            "Brewmaster": -3.064
        },
        {
            "name": "Luna",
            "Brewmaster": 0.751
        },
        {
            "name": "Lycan",
            "Brewmaster": 0.265
        },
        {
            "name": "Magnus",
            "Brewmaster": -0.171
        },
        {
            "name": "Marci",
            "Brewmaster": 0.363
        },
        {
            "name": "Mars",
            "Brewmaster": 0.461
        },
        {
            "name": "Medusa",
            "Brewmaster": -0.149
        },
        {
            "name": "Meepo",
            "Brewmaster": 2.398
        },
        {
            "name": "Mirana",
            "Brewmaster": -1.908
        },
        {
            "name": "Monkey King",
            "Brewmaster": 0.565
        },
        {
            "name": "Morphling",
            "Brewmaster": 1.321
        },
        {
            "name": "Muerta",
            "Brewmaster": -0.588
        },
        {
            "name": "Naga Siren",
            "Brewmaster": -1.768
        },
        {
            "name": "Nature's Prophet",
            "Brewmaster": 0.543
        },
        {
            "name": "Necrophos",
            "Brewmaster": -1.019
        },
        {
            "name": "Night Stalker",
            "Brewmaster": -1.749
        },
        {
            "name": "Nyx Assassin",
            "Brewmaster": -1.239
        },
        {
            "name": "Ogre Magi",
            "Brewmaster": -1.045
        },
        {
            "name": "Omniknight",
            "Brewmaster": 1.566
        },
        {
            "name": "Oracle",
            "Brewmaster": 1.615
        },
        {
            "name": "Outworld Destroyer",
            "Brewmaster": -2.112
        },
        {
            "name": "Pangolier",
            "Brewmaster": 2.154
        },
        {
            "name": "Phantom Assassin",
            "Brewmaster": -0.421
        },
        {
            "name": "Phantom Lancer",
            "Brewmaster": 0.005
        },
        {
            "name": "Phoenix",
            "Brewmaster": 0.098
        },
        {
            "name": "Primal Beast",
            "Brewmaster": 0.528
        },
        {
            "name": "Puck",
            "Brewmaster": 1.931
        },
        {
            "name": "Pudge",
            "Brewmaster": 0.162
        },
        {
            "name": "Pugna",
            "Brewmaster": 0.824
        },
        {
            "name": "Queen of Pain",
            "Brewmaster": 0.447
        },
        {
            "name": "Razor",
            "Brewmaster": -1.39
        },
        {
            "name": "Riki",
            "Brewmaster": 0.555
        },
        {
            "name": "Ringmaster",
            "Brewmaster": -2.067
        },
        {
            "name": "Rubick",
            "Brewmaster": -0.605
        },
        {
            "name": "Sand King",
            "Brewmaster": 1.251
        },
        {
            "name": "Shadow Demon",
            "Brewmaster": -0.495
        },
        {
            "name": "Shadow Fiend",
            "Brewmaster": -0.358
        },
        {
            "name": "Shadow Shaman",
            "Brewmaster": -0.016
        },
        {
            "name": "Silencer",
            "Brewmaster": -1.392
        },
        {
            "name": "Skywrath Mage",
            "Brewmaster": -0.369
        },
        {
            "name": "Slardar",
            "Brewmaster": 0.791
        },
        {
            "name": "Slark",
            "Brewmaster": 2.102
        },
        {
            "name": "Snapfire",
            "Brewmaster": 0.406
        },
        {
            "name": "Sniper",
            "Brewmaster": -0.131
        },
        {
            "name": "Spectre",
            "Brewmaster": -1.544
        },
        {
            "name": "Spirit Breaker",
            "Brewmaster": 1.45
        },
        {
            "name": "Storm Spirit",
            "Brewmaster": 1.536
        },
        {
            "name": "Sven",
            "Brewmaster": 0.587
        },
        {
            "name": "Techies",
            "Brewmaster": 0.54
        },
        {
            "name": "Templar Assassin",
            "Brewmaster": -1.347
        },
        {
            "name": "Terrorblade",
            "Brewmaster": -2.156
        },
        {
            "name": "Tidehunter",
            "Brewmaster": -0.484
        },
        {
            "name": "Timbersaw",
            "Brewmaster": 0.328
        },
        {
            "name": "Tinker",
            "Brewmaster": 1.727
        },
        {
            "name": "Tiny",
            "Brewmaster": -2.374
        },
        {
            "name": "Treant Protector",
            "Brewmaster": -1.244
        },
        {
            "name": "Troll Warlord",
            "Brewmaster": -2.039
        },
        {
            "name": "Tusk",
            "Brewmaster": 1.628
        },
        {
            "name": "Underlord",
            "Brewmaster": -1.169
        },
        {
            "name": "Undying",
            "Brewmaster": 0.233
        },
        {
            "name": "Ursa",
            "Brewmaster": -1.926
        },
        {
            "name": "Vengeful Spirit",
            "Brewmaster": -1.147
        },
        {
            "name": "Venomancer",
            "Brewmaster": -1.555
        },
        {
            "name": "Viper",
            "Brewmaster": -2.217
        },
        {
            "name": "Visage",
            "Brewmaster": -2.355
        },
        {
            "name": "Void Spirit",
            "Brewmaster": 1.156
        },
        {
            "name": "Warlock",
            "Brewmaster": 1.614
        },
        {
            "name": "Weaver",
            "Brewmaster": 2.53
        },
        {
            "name": "Windranger",
            "Brewmaster": 1.14
        },
        {
            "name": "Winter Wyvern",
            "Brewmaster": 0.141
        },
        {
            "name": "Witch Doctor",
            "Brewmaster": -1.272
        },
        {
            "name": "Wraith King",
            "Brewmaster": -2.106
        },
        {
            "name": "Zeus",
            "Brewmaster": -1.309
        }
    ],
    "Broodmother": [
        {
            "name": "Abaddon",
            "Broodmother": -0.795
        },
        {
            "name": "Alchemist",
            "Broodmother": 0.459
        },
        {
            "name": "Ancient Apparition",
            "Broodmother": -0.066
        },
        {
            "name": "Anti-Mage",
            "Broodmother": -2.255
        },
        {
            "name": "Arc Warden",
            "Broodmother": -5.122
        },
        {
            "name": "Axe",
            "Broodmother": 4.592
        },
        {
            "name": "Bane",
            "Broodmother": 1.403
        },
        {
            "name": "Batrider",
            "Broodmother": 2.265
        },
        {
            "name": "Beastmaster",
            "Broodmother": 1.357
        },
        {
            "name": "Bloodseeker",
            "Broodmother": -1.017
        },
        {
            "name": "Bounty Hunter",
            "Broodmother": 1.0
        },
        {
            "name": "Brewmaster",
            "Broodmother": 1.462
        },
        {
            "name": "Bristleback",
            "Broodmother": 2.811
        },
        {
            "name": "Centaur Warrunner",
            "Broodmother": -0.658
        },
        {
            "name": "Chaos Knight",
            "Broodmother": 1.436
        },
        {
            "name": "Chen",
            "Broodmother": -3.462
        },
        {
            "name": "Clinkz",
            "Broodmother": -2.989
        },
        {
            "name": "Clockwerk",
            "Broodmother": -2.176
        },
        {
            "name": "Crystal Maiden",
            "Broodmother": 3.867
        },
        {
            "name": "Dark Seer",
            "Broodmother": 3.8
        },
        {
            "name": "Dark Willow",
            "Broodmother": -0.669
        },
        {
            "name": "Dawnbreaker",
            "Broodmother": -1.014
        },
        {
            "name": "Dazzle",
            "Broodmother": 0.784
        },
        {
            "name": "Death Prophet",
            "Broodmother": -0.77
        },
        {
            "name": "Disruptor",
            "Broodmother": 0.15
        },
        {
            "name": "Doom",
            "Broodmother": -2.434
        },
        {
            "name": "Dragon Knight",
            "Broodmother": 1.981
        },
        {
            "name": "Drow Ranger",
            "Broodmother": -0.721
        },
        {
            "name": "Earth Spirit",
            "Broodmother": -0.442
        },
        {
            "name": "Earthshaker",
            "Broodmother": 9.088
        },
        {
            "name": "Elder Titan",
            "Broodmother": -1.767
        },
        {
            "name": "Ember Spirit",
            "Broodmother": -4.726
        },
        {
            "name": "Enchantress",
            "Broodmother": 2.066
        },
        {
            "name": "Enigma",
            "Broodmother": 1.105
        },
        {
            "name": "Faceless Void",
            "Broodmother": -0.583
        },
        {
            "name": "Grimstroke",
            "Broodmother": 1.536
        },
        {
            "name": "Gyrocopter",
            "Broodmother": -1.47
        },
        {
            "name": "Hoodwink",
            "Broodmother": 0.609
        },
        {
            "name": "Huskar",
            "Broodmother": -0.077
        },
        {
            "name": "Invoker",
            "Broodmother": -2.656
        },
        {
            "name": "Io",
            "Broodmother": -0.774
        },
        {
            "name": "Jakiro",
            "Broodmother": 1.895
        },
        {
            "name": "Juggernaut",
            "Broodmother": -2.765
        },
        {
            "name": "Keeper of the Light",
            "Broodmother": 0.055
        },
        {
            "name": "Kez",
            "Broodmother": 0.596
        },
        {
            "name": "Kunkka",
            "Broodmother": 0.731
        },
        {
            "name": "Legion Commander",
            "Broodmother": 1.509
        },
        {
            "name": "Leshrac",
            "Broodmother": -0.621
        },
        {
            "name": "Lich",
            "Broodmother": 0.48
        },
        {
            "name": "Lifestealer",
            "Broodmother": -4.078
        },
        {
            "name": "Lina",
            "Broodmother": 1.549
        },
        {
            "name": "Lion",
            "Broodmother": 0.773
        },
        {
            "name": "Lone Druid",
            "Broodmother": -2.776
        },
        {
            "name": "Luna",
            "Broodmother": -0.025
        },
        {
            "name": "Lycan",
            "Broodmother": -2.815
        },
        {
            "name": "Magnus",
            "Broodmother": 1.475
        },
        {
            "name": "Marci",
            "Broodmother": 1.84
        },
        {
            "name": "Mars",
            "Broodmother": 0.483
        },
        {
            "name": "Medusa",
            "Broodmother": -1.227
        },
        {
            "name": "Meepo",
            "Broodmother": 2.324
        },
        {
            "name": "Mirana",
            "Broodmother": -0.327
        },
        {
            "name": "Monkey King",
            "Broodmother": 2.316
        },
        {
            "name": "Morphling",
            "Broodmother": -0.965
        },
        {
            "name": "Muerta",
            "Broodmother": -0.754
        },
        {
            "name": "Naga Siren",
            "Broodmother": 6.696
        },
        {
            "name": "Nature's Prophet",
            "Broodmother": -2.462
        },
        {
            "name": "Necrophos",
            "Broodmother": 2.019
        },
        {
            "name": "Night Stalker",
            "Broodmother": -5.541
        },
        {
            "name": "Nyx Assassin",
            "Broodmother": -1.091
        },
        {
            "name": "Ogre Magi",
            "Broodmother": -2.46
        },
        {
            "name": "Omniknight",
            "Broodmother": 2.795
        },
        {
            "name": "Oracle",
            "Broodmother": -1.989
        },
        {
            "name": "Outworld Destroyer",
            "Broodmother": -5.249
        },
        {
            "name": "Pangolier",
            "Broodmother": 2.303
        },
        {
            "name": "Phantom Assassin",
            "Broodmother": 3.077
        },
        {
            "name": "Phantom Lancer",
            "Broodmother": 2.296
        },
        {
            "name": "Phoenix",
            "Broodmother": 0.552
        },
        {
            "name": "Primal Beast",
            "Broodmother": 1.6
        },
        {
            "name": "Puck",
            "Broodmother": -4.254
        },
        {
            "name": "Pudge",
            "Broodmother": -2.436
        },
        {
            "name": "Pugna",
            "Broodmother": -0.54
        },
        {
            "name": "Queen of Pain",
            "Broodmother": -0.501
        },
        {
            "name": "Razor",
            "Broodmother": -0.27
        },
        {
            "name": "Riki",
            "Broodmother": -1.418
        },
        {
            "name": "Ringmaster",
            "Broodmother": -0.214
        },
        {
            "name": "Rubick",
            "Broodmother": -0.846
        },
        {
            "name": "Sand King",
            "Broodmother": 1.763
        },
        {
            "name": "Shadow Demon",
            "Broodmother": -0.786
        },
        {
            "name": "Shadow Fiend",
            "Broodmother": 2.841
        },
        {
            "name": "Shadow Shaman",
            "Broodmother": 0.785
        },
        {
            "name": "Silencer",
            "Broodmother": -3.091
        },
        {
            "name": "Skywrath Mage",
            "Broodmother": -1.672
        },
        {
            "name": "Slardar",
            "Broodmother": 2.839
        },
        {
            "name": "Slark",
            "Broodmother": -0.255
        },
        {
            "name": "Snapfire",
            "Broodmother": 0.071
        },
        {
            "name": "Sniper",
            "Broodmother": 0.308
        },
        {
            "name": "Spectre",
            "Broodmother": -1.188
        },
        {
            "name": "Spirit Breaker",
            "Broodmother": 3.656
        },
        {
            "name": "Storm Spirit",
            "Broodmother": 0.868
        },
        {
            "name": "Sven",
            "Broodmother": 3.465
        },
        {
            "name": "Techies",
            "Broodmother": 2.157
        },
        {
            "name": "Templar Assassin",
            "Broodmother": 0.421
        },
        {
            "name": "Terrorblade",
            "Broodmother": 3.746
        },
        {
            "name": "Tidehunter",
            "Broodmother": 0.693
        },
        {
            "name": "Timbersaw",
            "Broodmother": -0.58
        },
        {
            "name": "Tinker",
            "Broodmother": 0.185
        },
        {
            "name": "Tiny",
            "Broodmother": 2.425
        },
        {
            "name": "Treant Protector",
            "Broodmother": -0.189
        },
        {
            "name": "Troll Warlord",
            "Broodmother": 2.014
        },
        {
            "name": "Tusk",
            "Broodmother": 0.895
        },
        {
            "name": "Underlord",
            "Broodmother": -0.805
        },
        {
            "name": "Undying",
            "Broodmother": -1.313
        },
        {
            "name": "Ursa",
            "Broodmother": -2.107
        },
        {
            "name": "Vengeful Spirit",
            "Broodmother": -0.538
        },
        {
            "name": "Venomancer",
            "Broodmother": -1.298
        },
        {
            "name": "Viper",
            "Broodmother": -0.963
        },
        {
            "name": "Visage",
            "Broodmother": -1.085
        },
        {
            "name": "Void Spirit",
            "Broodmother": -2.86
        },
        {
            "name": "Warlock",
            "Broodmother": 0.308
        },
        {
            "name": "Weaver",
            "Broodmother": -1.455
        },
        {
            "name": "Windranger",
            "Broodmother": 0.288
        },
        {
            "name": "Winter Wyvern",
            "Broodmother": 2.974
        },
        {
            "name": "Witch Doctor",
            "Broodmother": -2.386
        },
        {
            "name": "Wraith King",
            "Broodmother": -2.478
        },
        {
            "name": "Zeus",
            "Broodmother": -2.563
        }
    ],
    "Chen": [
        {
            "name": "Abaddon",
            "Chen": -0.961
        },
        {
            "name": "Alchemist",
            "Chen": 0.402
        },
        {
            "name": "Ancient Apparition",
            "Chen": -0.881
        },
        {
            "name": "Anti-Mage",
            "Chen": 1.761
        },
        {
            "name": "Arc Warden",
            "Chen": 1.396
        },
        {
            "name": "Axe",
            "Chen": 2.085
        },
        {
            "name": "Bane",
            "Chen": -1.818
        },
        {
            "name": "Batrider",
            "Chen": 2.895
        },
        {
            "name": "Beastmaster",
            "Chen": -8.244
        },
        {
            "name": "Bloodseeker",
            "Chen": -2.848
        },
        {
            "name": "Bounty Hunter",
            "Chen": -1.037
        },
        {
            "name": "Brewmaster",
            "Chen": -1.59
        },
        {
            "name": "Bristleback",
            "Chen": 0.686
        },
        {
            "name": "Broodmother",
            "Chen": 3.868
        },
        {
            "name": "Centaur Warrunner",
            "Chen": -1.858
        },
        {
            "name": "Chaos Knight",
            "Chen": 3.796
        },
        {
            "name": "Clinkz",
            "Chen": -0.524
        },
        {
            "name": "Clockwerk",
            "Chen": -2.881
        },
        {
            "name": "Crystal Maiden",
            "Chen": 0.217
        },
        {
            "name": "Dark Seer",
            "Chen": -2.62
        },
        {
            "name": "Dark Willow",
            "Chen": -0.814
        },
        {
            "name": "Dawnbreaker",
            "Chen": -0.807
        },
        {
            "name": "Dazzle",
            "Chen": 1.585
        },
        {
            "name": "Death Prophet",
            "Chen": 2.028
        },
        {
            "name": "Disruptor",
            "Chen": -1.915
        },
        {
            "name": "Doom",
            "Chen": 1.01
        },
        {
            "name": "Dragon Knight",
            "Chen": 0.244
        },
        {
            "name": "Drow Ranger",
            "Chen": -0.084
        },
        {
            "name": "Earth Spirit",
            "Chen": -2.319
        },
        {
            "name": "Earthshaker",
            "Chen": 1.427
        },
        {
            "name": "Elder Titan",
            "Chen": -3.326
        },
        {
            "name": "Ember Spirit",
            "Chen": -2.647
        },
        {
            "name": "Enchantress",
            "Chen": 0.58
        },
        {
            "name": "Enigma",
            "Chen": 3.9
        },
        {
            "name": "Faceless Void",
            "Chen": -0.894
        },
        {
            "name": "Grimstroke",
            "Chen": -0.809
        },
        {
            "name": "Gyrocopter",
            "Chen": -0.077
        },
        {
            "name": "Hoodwink",
            "Chen": -0.272
        },
        {
            "name": "Huskar",
            "Chen": 0.271
        },
        {
            "name": "Invoker",
            "Chen": 0.96
        },
        {
            "name": "Io",
            "Chen": 1.108
        },
        {
            "name": "Jakiro",
            "Chen": 3.3
        },
        {
            "name": "Juggernaut",
            "Chen": -1.597
        },
        {
            "name": "Keeper of the Light",
            "Chen": -0.178
        },
        {
            "name": "Kez",
            "Chen": 0.182
        },
        {
            "name": "Kunkka",
            "Chen": -0.348
        },
        {
            "name": "Legion Commander",
            "Chen": 0.23
        },
        {
            "name": "Leshrac",
            "Chen": 6.223
        },
        {
            "name": "Lich",
            "Chen": -1.959
        },
        {
            "name": "Lifestealer",
            "Chen": -2.833
        },
        {
            "name": "Lina",
            "Chen": 0.442
        },
        {
            "name": "Lion",
            "Chen": 0.737
        },
        {
            "name": "Lone Druid",
            "Chen": -1.283
        },
        {
            "name": "Luna",
            "Chen": 1.724
        },
        {
            "name": "Lycan",
            "Chen": 2.861
        },
        {
            "name": "Magnus",
            "Chen": 1.255
        },
        {
            "name": "Marci",
            "Chen": 3.783
        },
        {
            "name": "Mars",
            "Chen": -1.448
        },
        {
            "name": "Medusa",
            "Chen": -2.656
        },
        {
            "name": "Meepo",
            "Chen": 2.481
        },
        {
            "name": "Mirana",
            "Chen": -2.137
        },
        {
            "name": "Monkey King",
            "Chen": -0.213
        },
        {
            "name": "Morphling",
            "Chen": -0.248
        },
        {
            "name": "Muerta",
            "Chen": -1.708
        },
        {
            "name": "Naga Siren",
            "Chen": 4.789
        },
        {
            "name": "Nature's Prophet",
            "Chen": 1.699
        },
        {
            "name": "Necrophos",
            "Chen": 0.092
        },
        {
            "name": "Night Stalker",
            "Chen": -2.084
        },
        {
            "name": "Nyx Assassin",
            "Chen": -0.597
        },
        {
            "name": "Ogre Magi",
            "Chen": 0.481
        },
        {
            "name": "Omniknight",
            "Chen": 2.499
        },
        {
            "name": "Oracle",
            "Chen": 2.095
        },
        {
            "name": "Outworld Destroyer",
            "Chen": -0.431
        },
        {
            "name": "Pangolier",
            "Chen": -2.417
        },
        {
            "name": "Phantom Assassin",
            "Chen": 2.366
        },
        {
            "name": "Phantom Lancer",
            "Chen": 4.402
        },
        {
            "name": "Phoenix",
            "Chen": 0.512
        },
        {
            "name": "Primal Beast",
            "Chen": 0.614
        },
        {
            "name": "Puck",
            "Chen": -3.366
        },
        {
            "name": "Pudge",
            "Chen": -0.28
        },
        {
            "name": "Pugna",
            "Chen": 1.187
        },
        {
            "name": "Queen of Pain",
            "Chen": -0.674
        },
        {
            "name": "Razor",
            "Chen": -0.071
        },
        {
            "name": "Riki",
            "Chen": 1.726
        },
        {
            "name": "Ringmaster",
            "Chen": 0.006
        },
        {
            "name": "Rubick",
            "Chen": -0.565
        },
        {
            "name": "Sand King",
            "Chen": 0.406
        },
        {
            "name": "Shadow Demon",
            "Chen": -4.091
        },
        {
            "name": "Shadow Fiend",
            "Chen": 2.269
        },
        {
            "name": "Shadow Shaman",
            "Chen": 1.575
        },
        {
            "name": "Silencer",
            "Chen": -2.225
        },
        {
            "name": "Skywrath Mage",
            "Chen": 0.365
        },
        {
            "name": "Slardar",
            "Chen": 3.478
        },
        {
            "name": "Slark",
            "Chen": 2.463
        },
        {
            "name": "Snapfire",
            "Chen": 1.474
        },
        {
            "name": "Sniper",
            "Chen": -0.73
        },
        {
            "name": "Spectre",
            "Chen": -2.303
        },
        {
            "name": "Spirit Breaker",
            "Chen": 0.894
        },
        {
            "name": "Storm Spirit",
            "Chen": -0.021
        },
        {
            "name": "Sven",
            "Chen": 3.329
        },
        {
            "name": "Techies",
            "Chen": 2.973
        },
        {
            "name": "Templar Assassin",
            "Chen": -1.745
        },
        {
            "name": "Terrorblade",
            "Chen": 2.279
        },
        {
            "name": "Tidehunter",
            "Chen": -6.619
        },
        {
            "name": "Timbersaw",
            "Chen": 0.147
        },
        {
            "name": "Tinker",
            "Chen": -0.801
        },
        {
            "name": "Tiny",
            "Chen": 0.228
        },
        {
            "name": "Treant Protector",
            "Chen": 0.377
        },
        {
            "name": "Troll Warlord",
            "Chen": 2.816
        },
        {
            "name": "Tusk",
            "Chen": -0.315
        },
        {
            "name": "Underlord",
            "Chen": -1.256
        },
        {
            "name": "Undying",
            "Chen": -0.724
        },
        {
            "name": "Ursa",
            "Chen": 1.916
        },
        {
            "name": "Vengeful Spirit",
            "Chen": 0.64
        },
        {
            "name": "Venomancer",
            "Chen": 0.195
        },
        {
            "name": "Viper",
            "Chen": -1.202
        },
        {
            "name": "Visage",
            "Chen": -3.052
        },
        {
            "name": "Void Spirit",
            "Chen": -3.469
        },
        {
            "name": "Warlock",
            "Chen": 1.658
        },
        {
            "name": "Weaver",
            "Chen": 1.405
        },
        {
            "name": "Windranger",
            "Chen": 3.46
        },
        {
            "name": "Winter Wyvern",
            "Chen": 1.627
        },
        {
            "name": "Witch Doctor",
            "Chen": -1.115
        },
        {
            "name": "Wraith King",
            "Chen": -1.889
        },
        {
            "name": "Zeus",
            "Chen": -3.221
        }
    ],
    "Clockwerk": [
        {
            "name": "Abaddon",
            "Clockwerk": -0.712
        },
        {
            "name": "Alchemist",
            "Clockwerk": 0.296
        },
        {
            "name": "Ancient Apparition",
            "Clockwerk": 0.475
        },
        {
            "name": "Anti-Mage",
            "Clockwerk": 1.992
        },
        {
            "name": "Arc Warden",
            "Clockwerk": 0.642
        },
        {
            "name": "Axe",
            "Clockwerk": -0.276
        },
        {
            "name": "Bane",
            "Clockwerk": -0.359
        },
        {
            "name": "Batrider",
            "Clockwerk": 1.075
        },
        {
            "name": "Beastmaster",
            "Clockwerk": 3.986
        },
        {
            "name": "Bloodseeker",
            "Clockwerk": -0.026
        },
        {
            "name": "Bounty Hunter",
            "Clockwerk": -0.247
        },
        {
            "name": "Brewmaster",
            "Clockwerk": -0.094
        },
        {
            "name": "Bristleback",
            "Clockwerk": -0.931
        },
        {
            "name": "Broodmother",
            "Clockwerk": 2.16
        },
        {
            "name": "Centaur Warrunner",
            "Clockwerk": -0.53
        },
        {
            "name": "Chaos Knight",
            "Clockwerk": 0.366
        },
        {
            "name": "Chen",
            "Clockwerk": 3.335
        },
        {
            "name": "Clinkz",
            "Clockwerk": 0.455
        },
        {
            "name": "Crystal Maiden",
            "Clockwerk": -0.85
        },
        {
            "name": "Dark Seer",
            "Clockwerk": 0.297
        },
        {
            "name": "Dark Willow",
            "Clockwerk": 0.525
        },
        {
            "name": "Dawnbreaker",
            "Clockwerk": -0.07
        },
        {
            "name": "Dazzle",
            "Clockwerk": 0.933
        },
        {
            "name": "Death Prophet",
            "Clockwerk": 0.143
        },
        {
            "name": "Disruptor",
            "Clockwerk": 1.223
        },
        {
            "name": "Doom",
            "Clockwerk": -0.054
        },
        {
            "name": "Dragon Knight",
            "Clockwerk": 0.436
        },
        {
            "name": "Drow Ranger",
            "Clockwerk": -1.774
        },
        {
            "name": "Earth Spirit",
            "Clockwerk": 0.496
        },
        {
            "name": "Earthshaker",
            "Clockwerk": -1.704
        },
        {
            "name": "Elder Titan",
            "Clockwerk": 1.498
        },
        {
            "name": "Ember Spirit",
            "Clockwerk": 2.636
        },
        {
            "name": "Enchantress",
            "Clockwerk": -0.282
        },
        {
            "name": "Enigma",
            "Clockwerk": 1.122
        },
        {
            "name": "Faceless Void",
            "Clockwerk": 1.191
        },
        {
            "name": "Grimstroke",
            "Clockwerk": 0.781
        },
        {
            "name": "Gyrocopter",
            "Clockwerk": 0.332
        },
        {
            "name": "Hoodwink",
            "Clockwerk": -0.404
        },
        {
            "name": "Huskar",
            "Clockwerk": 4.171
        },
        {
            "name": "Invoker",
            "Clockwerk": 0.832
        },
        {
            "name": "Io",
            "Clockwerk": 1.509
        },
        {
            "name": "Jakiro",
            "Clockwerk": 0.823
        },
        {
            "name": "Juggernaut",
            "Clockwerk": 2.058
        },
        {
            "name": "Keeper of the Light",
            "Clockwerk": 1.407
        },
        {
            "name": "Kez",
            "Clockwerk": 0.182
        },
        {
            "name": "Kunkka",
            "Clockwerk": 1.355
        },
        {
            "name": "Legion Commander",
            "Clockwerk": -0.316
        },
        {
            "name": "Leshrac",
            "Clockwerk": -0.229
        },
        {
            "name": "Lich",
            "Clockwerk": 0.595
        },
        {
            "name": "Lifestealer",
            "Clockwerk": 1.344
        },
        {
            "name": "Lina",
            "Clockwerk": 0.002
        },
        {
            "name": "Lion",
            "Clockwerk": -0.026
        },
        {
            "name": "Lone Druid",
            "Clockwerk": 2.08
        },
        {
            "name": "Luna",
            "Clockwerk": -1.503
        },
        {
            "name": "Lycan",
            "Clockwerk": 0.971
        },
        {
            "name": "Magnus",
            "Clockwerk": 0.067
        },
        {
            "name": "Marci",
            "Clockwerk": 0.405
        },
        {
            "name": "Mars",
            "Clockwerk": -0.002
        },
        {
            "name": "Medusa",
            "Clockwerk": -1.478
        },
        {
            "name": "Meepo",
            "Clockwerk": 3.666
        },
        {
            "name": "Mirana",
            "Clockwerk": 0.507
        },
        {
            "name": "Monkey King",
            "Clockwerk": 0.841
        },
        {
            "name": "Morphling",
            "Clockwerk": 1.766
        },
        {
            "name": "Muerta",
            "Clockwerk": 0.695
        },
        {
            "name": "Naga Siren",
            "Clockwerk": 1.915
        },
        {
            "name": "Nature's Prophet",
            "Clockwerk": -0.392
        },
        {
            "name": "Necrophos",
            "Clockwerk": 0.408
        },
        {
            "name": "Night Stalker",
            "Clockwerk": -0.235
        },
        {
            "name": "Nyx Assassin",
            "Clockwerk": -0.636
        },
        {
            "name": "Ogre Magi",
            "Clockwerk": 0.398
        },
        {
            "name": "Omniknight",
            "Clockwerk": 1.882
        },
        {
            "name": "Oracle",
            "Clockwerk": 0.665
        },
        {
            "name": "Outworld Destroyer",
            "Clockwerk": 0.557
        },
        {
            "name": "Pangolier",
            "Clockwerk": -0.255
        },
        {
            "name": "Phantom Assassin",
            "Clockwerk": -0.854
        },
        {
            "name": "Phantom Lancer",
            "Clockwerk": 2.741
        },
        {
            "name": "Phoenix",
            "Clockwerk": 0.329
        },
        {
            "name": "Primal Beast",
            "Clockwerk": -1.439
        },
        {
            "name": "Puck",
            "Clockwerk": 1.224
        },
        {
            "name": "Pudge",
            "Clockwerk": -1.551
        },
        {
            "name": "Pugna",
            "Clockwerk": -0.072
        },
        {
            "name": "Queen of Pain",
            "Clockwerk": 1.782
        },
        {
            "name": "Razor",
            "Clockwerk": -1.533
        },
        {
            "name": "Riki",
            "Clockwerk": -0.674
        },
        {
            "name": "Ringmaster",
            "Clockwerk": 0.943
        },
        {
            "name": "Rubick",
            "Clockwerk": -0.65
        },
        {
            "name": "Sand King",
            "Clockwerk": 0.57
        },
        {
            "name": "Shadow Demon",
            "Clockwerk": 1.411
        },
        {
            "name": "Shadow Fiend",
            "Clockwerk": -0.529
        },
        {
            "name": "Shadow Shaman",
            "Clockwerk": -0.622
        },
        {
            "name": "Silencer",
            "Clockwerk": -0.81
        },
        {
            "name": "Skywrath Mage",
            "Clockwerk": 0.586
        },
        {
            "name": "Slardar",
            "Clockwerk": -0.05
        },
        {
            "name": "Slark",
            "Clockwerk": -2.489
        },
        {
            "name": "Snapfire",
            "Clockwerk": 1.129
        },
        {
            "name": "Sniper",
            "Clockwerk": -2.018
        },
        {
            "name": "Spectre",
            "Clockwerk": -0.734
        },
        {
            "name": "Spirit Breaker",
            "Clockwerk": -2.835
        },
        {
            "name": "Storm Spirit",
            "Clockwerk": 2.185
        },
        {
            "name": "Sven",
            "Clockwerk": -0.523
        },
        {
            "name": "Techies",
            "Clockwerk": -0.311
        },
        {
            "name": "Templar Assassin",
            "Clockwerk": 0.858
        },
        {
            "name": "Terrorblade",
            "Clockwerk": 2.094
        },
        {
            "name": "Tidehunter",
            "Clockwerk": -0.853
        },
        {
            "name": "Timbersaw",
            "Clockwerk": 0.281
        },
        {
            "name": "Tinker",
            "Clockwerk": 0.658
        },
        {
            "name": "Tiny",
            "Clockwerk": -0.491
        },
        {
            "name": "Treant Protector",
            "Clockwerk": -0.131
        },
        {
            "name": "Troll Warlord",
            "Clockwerk": 0.343
        },
        {
            "name": "Tusk",
            "Clockwerk": 0.839
        },
        {
            "name": "Underlord",
            "Clockwerk": 1.326
        },
        {
            "name": "Undying",
            "Clockwerk": 1.933
        },
        {
            "name": "Ursa",
            "Clockwerk": 0.609
        },
        {
            "name": "Vengeful Spirit",
            "Clockwerk": -0.093
        },
        {
            "name": "Venomancer",
            "Clockwerk": 1.811
        },
        {
            "name": "Viper",
            "Clockwerk": 1.647
        },
        {
            "name": "Visage",
            "Clockwerk": 2.064
        },
        {
            "name": "Void Spirit",
            "Clockwerk": 0.552
        },
        {
            "name": "Warlock",
            "Clockwerk": 0.061
        },
        {
            "name": "Weaver",
            "Clockwerk": -0.77
        },
        {
            "name": "Windranger",
            "Clockwerk": -1.819
        },
        {
            "name": "Winter Wyvern",
            "Clockwerk": 1.112
        },
        {
            "name": "Witch Doctor",
            "Clockwerk": 0.91
        },
        {
            "name": "Wraith King",
            "Clockwerk": -0.445
        },
        {
            "name": "Zeus",
            "Clockwerk": -0.214
        }
    ],
    "Dark Seer": [
        {
            "name": "Abaddon",
            "Dark Seer": -2.914
        },
        {
            "name": "Alchemist",
            "Dark Seer": 0.093
        },
        {
            "name": "Ancient Apparition",
            "Dark Seer": -1.275
        },
        {
            "name": "Anti-Mage",
            "Dark Seer": 0.453
        },
        {
            "name": "Arc Warden",
            "Dark Seer": -0.257
        },
        {
            "name": "Axe",
            "Dark Seer": 1.554
        },
        {
            "name": "Bane",
            "Dark Seer": 0.487
        },
        {
            "name": "Batrider",
            "Dark Seer": 0.312
        },
        {
            "name": "Beastmaster",
            "Dark Seer": -3.331
        },
        {
            "name": "Bloodseeker",
            "Dark Seer": 3.348
        },
        {
            "name": "Bounty Hunter",
            "Dark Seer": 0.011
        },
        {
            "name": "Brewmaster",
            "Dark Seer": -0.204
        },
        {
            "name": "Bristleback",
            "Dark Seer": 0.807
        },
        {
            "name": "Broodmother",
            "Dark Seer": -3.791
        },
        {
            "name": "Centaur Warrunner",
            "Dark Seer": 0.663
        },
        {
            "name": "Chaos Knight",
            "Dark Seer": -4.694
        },
        {
            "name": "Chen",
            "Dark Seer": 2.273
        },
        {
            "name": "Clinkz",
            "Dark Seer": 0.637
        },
        {
            "name": "Clockwerk",
            "Dark Seer": -0.124
        },
        {
            "name": "Crystal Maiden",
            "Dark Seer": 1.468
        },
        {
            "name": "Dark Willow",
            "Dark Seer": 0.959
        },
        {
            "name": "Dawnbreaker",
            "Dark Seer": 1.525
        },
        {
            "name": "Dazzle",
            "Dark Seer": 0.216
        },
        {
            "name": "Death Prophet",
            "Dark Seer": -0.27
        },
        {
            "name": "Disruptor",
            "Dark Seer": 0.463
        },
        {
            "name": "Doom",
            "Dark Seer": 1.491
        },
        {
            "name": "Dragon Knight",
            "Dark Seer": -0.108
        },
        {
            "name": "Drow Ranger",
            "Dark Seer": -2.223
        },
        {
            "name": "Earth Spirit",
            "Dark Seer": -0.937
        },
        {
            "name": "Earthshaker",
            "Dark Seer": 2.388
        },
        {
            "name": "Elder Titan",
            "Dark Seer": -2.039
        },
        {
            "name": "Ember Spirit",
            "Dark Seer": 1.479
        },
        {
            "name": "Enchantress",
            "Dark Seer": 2.963
        },
        {
            "name": "Enigma",
            "Dark Seer": 0.285
        },
        {
            "name": "Faceless Void",
            "Dark Seer": 1.723
        },
        {
            "name": "Grimstroke",
            "Dark Seer": 0.825
        },
        {
            "name": "Gyrocopter",
            "Dark Seer": 2.064
        },
        {
            "name": "Hoodwink",
            "Dark Seer": 2.53
        },
        {
            "name": "Huskar",
            "Dark Seer": 0.109
        },
        {
            "name": "Invoker",
            "Dark Seer": 0.007
        },
        {
            "name": "Io",
            "Dark Seer": -0.149
        },
        {
            "name": "Jakiro",
            "Dark Seer": 0.806
        },
        {
            "name": "Juggernaut",
            "Dark Seer": 1.604
        },
        {
            "name": "Keeper of the Light",
            "Dark Seer": -0.652
        },
        {
            "name": "Kez",
            "Dark Seer": 0.731
        },
        {
            "name": "Kunkka",
            "Dark Seer": -0.597
        },
        {
            "name": "Legion Commander",
            "Dark Seer": 1.585
        },
        {
            "name": "Leshrac",
            "Dark Seer": 4.441
        },
        {
            "name": "Lich",
            "Dark Seer": 0.017
        },
        {
            "name": "Lifestealer",
            "Dark Seer": -0.42
        },
        {
            "name": "Lina",
            "Dark Seer": 1.496
        },
        {
            "name": "Lion",
            "Dark Seer": -0.019
        },
        {
            "name": "Lone Druid",
            "Dark Seer": 0.007
        },
        {
            "name": "Luna",
            "Dark Seer": -1.655
        },
        {
            "name": "Lycan",
            "Dark Seer": -1.166
        },
        {
            "name": "Magnus",
            "Dark Seer": -0.213
        },
        {
            "name": "Marci",
            "Dark Seer": -1.484
        },
        {
            "name": "Mars",
            "Dark Seer": 0.117
        },
        {
            "name": "Medusa",
            "Dark Seer": -5.895
        },
        {
            "name": "Meepo",
            "Dark Seer": -0.183
        },
        {
            "name": "Mirana",
            "Dark Seer": -0.509
        },
        {
            "name": "Monkey King",
            "Dark Seer": 0.612
        },
        {
            "name": "Morphling",
            "Dark Seer": -0.162
        },
        {
            "name": "Muerta",
            "Dark Seer": -1.573
        },
        {
            "name": "Naga Siren",
            "Dark Seer": -0.22
        },
        {
            "name": "Nature's Prophet",
            "Dark Seer": 1.039
        },
        {
            "name": "Necrophos",
            "Dark Seer": -1.833
        },
        {
            "name": "Night Stalker",
            "Dark Seer": -1.472
        },
        {
            "name": "Nyx Assassin",
            "Dark Seer": 0.079
        },
        {
            "name": "Ogre Magi",
            "Dark Seer": -0.99
        },
        {
            "name": "Omniknight",
            "Dark Seer": -0.984
        },
        {
            "name": "Oracle",
            "Dark Seer": 2.017
        },
        {
            "name": "Outworld Destroyer",
            "Dark Seer": 0.435
        },
        {
            "name": "Pangolier",
            "Dark Seer": -0.795
        },
        {
            "name": "Phantom Assassin",
            "Dark Seer": 1.017
        },
        {
            "name": "Phantom Lancer",
            "Dark Seer": -4.167
        },
        {
            "name": "Phoenix",
            "Dark Seer": 0.487
        },
        {
            "name": "Primal Beast",
            "Dark Seer": -1.132
        },
        {
            "name": "Puck",
            "Dark Seer": 0.332
        },
        {
            "name": "Pudge",
            "Dark Seer": 0.077
        },
        {
            "name": "Pugna",
            "Dark Seer": 0.135
        },
        {
            "name": "Queen of Pain",
            "Dark Seer": 1.241
        },
        {
            "name": "Razor",
            "Dark Seer": -0.06
        },
        {
            "name": "Riki",
            "Dark Seer": -1.013
        },
        {
            "name": "Ringmaster",
            "Dark Seer": -0.35
        },
        {
            "name": "Rubick",
            "Dark Seer": 0.564
        },
        {
            "name": "Sand King",
            "Dark Seer": 1.237
        },
        {
            "name": "Shadow Demon",
            "Dark Seer": -0.385
        },
        {
            "name": "Shadow Fiend",
            "Dark Seer": -1.24
        },
        {
            "name": "Shadow Shaman",
            "Dark Seer": -1.922
        },
        {
            "name": "Silencer",
            "Dark Seer": -0.483
        },
        {
            "name": "Skywrath Mage",
            "Dark Seer": -0.307
        },
        {
            "name": "Slardar",
            "Dark Seer": -0.182
        },
        {
            "name": "Slark",
            "Dark Seer": -0.074
        },
        {
            "name": "Snapfire",
            "Dark Seer": 1.067
        },
        {
            "name": "Sniper",
            "Dark Seer": 0.194
        },
        {
            "name": "Spectre",
            "Dark Seer": -0.85
        },
        {
            "name": "Spirit Breaker",
            "Dark Seer": -0.533
        },
        {
            "name": "Storm Spirit",
            "Dark Seer": -0.64
        },
        {
            "name": "Sven",
            "Dark Seer": 0.504
        },
        {
            "name": "Techies",
            "Dark Seer": 0.645
        },
        {
            "name": "Templar Assassin",
            "Dark Seer": -2.536
        },
        {
            "name": "Terrorblade",
            "Dark Seer": -2.49
        },
        {
            "name": "Tidehunter",
            "Dark Seer": -1.792
        },
        {
            "name": "Timbersaw",
            "Dark Seer": 1.758
        },
        {
            "name": "Tinker",
            "Dark Seer": -0.18
        },
        {
            "name": "Tiny",
            "Dark Seer": 0.156
        },
        {
            "name": "Treant Protector",
            "Dark Seer": -1.636
        },
        {
            "name": "Troll Warlord",
            "Dark Seer": 1.263
        },
        {
            "name": "Tusk",
            "Dark Seer": -0.079
        },
        {
            "name": "Underlord",
            "Dark Seer": 0.923
        },
        {
            "name": "Undying",
            "Dark Seer": -0.528
        },
        {
            "name": "Ursa",
            "Dark Seer": 1.42
        },
        {
            "name": "Vengeful Spirit",
            "Dark Seer": -1.442
        },
        {
            "name": "Venomancer",
            "Dark Seer": -2.051
        },
        {
            "name": "Viper",
            "Dark Seer": -3.452
        },
        {
            "name": "Visage",
            "Dark Seer": -2.036
        },
        {
            "name": "Void Spirit",
            "Dark Seer": 0.386
        },
        {
            "name": "Warlock",
            "Dark Seer": 1.573
        },
        {
            "name": "Weaver",
            "Dark Seer": 2.182
        },
        {
            "name": "Windranger",
            "Dark Seer": 1.037
        },
        {
            "name": "Winter Wyvern",
            "Dark Seer": -0.375
        },
        {
            "name": "Witch Doctor",
            "Dark Seer": 1.786
        },
        {
            "name": "Wraith King",
            "Dark Seer": -1.881
        },
        {
            "name": "Zeus",
            "Dark Seer": -0.463
        }
    ],
    "Dark Willow": [
        {
            "name": "Abaddon",
            "Dark Willow": -0.836
        },
        {
            "name": "Alchemist",
            "Dark Willow": 0.25
        },
        {
            "name": "Ancient Apparition",
            "Dark Willow": 0.235
        },
        {
            "name": "Anti-Mage",
            "Dark Willow": -0.054
        },
        {
            "name": "Arc Warden",
            "Dark Willow": 0.996
        },
        {
            "name": "Axe",
            "Dark Willow": 2.345
        },
        {
            "name": "Bane",
            "Dark Willow": -1.132
        },
        {
            "name": "Batrider",
            "Dark Willow": 0.773
        },
        {
            "name": "Beastmaster",
            "Dark Willow": 0.631
        },
        {
            "name": "Bloodseeker",
            "Dark Willow": -0.676
        },
        {
            "name": "Bounty Hunter",
            "Dark Willow": -0.39
        },
        {
            "name": "Brewmaster",
            "Dark Willow": -0.182
        },
        {
            "name": "Bristleback",
            "Dark Willow": 0.971
        },
        {
            "name": "Broodmother",
            "Dark Willow": 0.491
        },
        {
            "name": "Centaur Warrunner",
            "Dark Willow": -0.376
        },
        {
            "name": "Chaos Knight",
            "Dark Willow": -0.395
        },
        {
            "name": "Chen",
            "Dark Willow": 0.96
        },
        {
            "name": "Clinkz",
            "Dark Willow": -0.341
        },
        {
            "name": "Clockwerk",
            "Dark Willow": -0.496
        },
        {
            "name": "Crystal Maiden",
            "Dark Willow": 0.893
        },
        {
            "name": "Dark Seer",
            "Dark Willow": -0.925
        },
        {
            "name": "Dawnbreaker",
            "Dark Willow": -1.06
        },
        {
            "name": "Dazzle",
            "Dark Willow": -0.496
        },
        {
            "name": "Death Prophet",
            "Dark Willow": -0.109
        },
        {
            "name": "Disruptor",
            "Dark Willow": -0.391
        },
        {
            "name": "Doom",
            "Dark Willow": -0.305
        },
        {
            "name": "Dragon Knight",
            "Dark Willow": -0.456
        },
        {
            "name": "Drow Ranger",
            "Dark Willow": -0.461
        },
        {
            "name": "Earth Spirit",
            "Dark Willow": 0.518
        },
        {
            "name": "Earthshaker",
            "Dark Willow": -0.213
        },
        {
            "name": "Elder Titan",
            "Dark Willow": 1.225
        },
        {
            "name": "Ember Spirit",
            "Dark Willow": -1.415
        },
        {
            "name": "Enchantress",
            "Dark Willow": -0.392
        },
        {
            "name": "Enigma",
            "Dark Willow": 0.882
        },
        {
            "name": "Faceless Void",
            "Dark Willow": 0.761
        },
        {
            "name": "Grimstroke",
            "Dark Willow": -1.317
        },
        {
            "name": "Gyrocopter",
            "Dark Willow": -0.945
        },
        {
            "name": "Hoodwink",
            "Dark Willow": 0.289
        },
        {
            "name": "Huskar",
            "Dark Willow": -0.786
        },
        {
            "name": "Invoker",
            "Dark Willow": 0.333
        },
        {
            "name": "Io",
            "Dark Willow": -2.358
        },
        {
            "name": "Jakiro",
            "Dark Willow": 0.46
        },
        {
            "name": "Juggernaut",
            "Dark Willow": 1.949
        },
        {
            "name": "Keeper of the Light",
            "Dark Willow": 0.188
        },
        {
            "name": "Kez",
            "Dark Willow": -0.891
        },
        {
            "name": "Kunkka",
            "Dark Willow": -0.453
        },
        {
            "name": "Legion Commander",
            "Dark Willow": 1.231
        },
        {
            "name": "Leshrac",
            "Dark Willow": 0.674
        },
        {
            "name": "Lich",
            "Dark Willow": -0.406
        },
        {
            "name": "Lifestealer",
            "Dark Willow": 0.109
        },
        {
            "name": "Lina",
            "Dark Willow": 0.564
        },
        {
            "name": "Lion",
            "Dark Willow": 0.297
        },
        {
            "name": "Lone Druid",
            "Dark Willow": -2.246
        },
        {
            "name": "Luna",
            "Dark Willow": 0.72
        },
        {
            "name": "Lycan",
            "Dark Willow": -0.629
        },
        {
            "name": "Magnus",
            "Dark Willow": -0.92
        },
        {
            "name": "Marci",
            "Dark Willow": -2.024
        },
        {
            "name": "Mars",
            "Dark Willow": -0.495
        },
        {
            "name": "Medusa",
            "Dark Willow": -0.851
        },
        {
            "name": "Meepo",
            "Dark Willow": -1.242
        },
        {
            "name": "Mirana",
            "Dark Willow": 0.054
        },
        {
            "name": "Monkey King",
            "Dark Willow": -0.689
        },
        {
            "name": "Morphling",
            "Dark Willow": 0.097
        },
        {
            "name": "Muerta",
            "Dark Willow": 0.116
        },
        {
            "name": "Naga Siren",
            "Dark Willow": 3.155
        },
        {
            "name": "Nature's Prophet",
            "Dark Willow": 0.72
        },
        {
            "name": "Necrophos",
            "Dark Willow": 0.298
        },
        {
            "name": "Night Stalker",
            "Dark Willow": -1.571
        },
        {
            "name": "Nyx Assassin",
            "Dark Willow": -0.031
        },
        {
            "name": "Ogre Magi",
            "Dark Willow": -0.728
        },
        {
            "name": "Omniknight",
            "Dark Willow": -0.171
        },
        {
            "name": "Oracle",
            "Dark Willow": 0.646
        },
        {
            "name": "Outworld Destroyer",
            "Dark Willow": -0.935
        },
        {
            "name": "Pangolier",
            "Dark Willow": -0.149
        },
        {
            "name": "Phantom Assassin",
            "Dark Willow": 0.471
        },
        {
            "name": "Phantom Lancer",
            "Dark Willow": 1.846
        },
        {
            "name": "Phoenix",
            "Dark Willow": -0.071
        },
        {
            "name": "Primal Beast",
            "Dark Willow": -0.884
        },
        {
            "name": "Puck",
            "Dark Willow": 1.369
        },
        {
            "name": "Pudge",
            "Dark Willow": -0.063
        },
        {
            "name": "Pugna",
            "Dark Willow": 0.828
        },
        {
            "name": "Queen of Pain",
            "Dark Willow": 0.793
        },
        {
            "name": "Razor",
            "Dark Willow": -2.025
        },
        {
            "name": "Riki",
            "Dark Willow": -1.306
        },
        {
            "name": "Ringmaster",
            "Dark Willow": 0.662
        },
        {
            "name": "Rubick",
            "Dark Willow": -0.211
        },
        {
            "name": "Sand King",
            "Dark Willow": 1.335
        },
        {
            "name": "Shadow Demon",
            "Dark Willow": 1.612
        },
        {
            "name": "Shadow Fiend",
            "Dark Willow": 0.445
        },
        {
            "name": "Shadow Shaman",
            "Dark Willow": 0.275
        },
        {
            "name": "Silencer",
            "Dark Willow": -0.564
        },
        {
            "name": "Skywrath Mage",
            "Dark Willow": -0.052
        },
        {
            "name": "Slardar",
            "Dark Willow": -1.51
        },
        {
            "name": "Slark",
            "Dark Willow": -0.802
        },
        {
            "name": "Snapfire",
            "Dark Willow": 0.655
        },
        {
            "name": "Sniper",
            "Dark Willow": -0.164
        },
        {
            "name": "Spectre",
            "Dark Willow": 0.702
        },
        {
            "name": "Spirit Breaker",
            "Dark Willow": -1.195
        },
        {
            "name": "Storm Spirit",
            "Dark Willow": -0.297
        },
        {
            "name": "Sven",
            "Dark Willow": -0.979
        },
        {
            "name": "Techies",
            "Dark Willow": 1.413
        },
        {
            "name": "Templar Assassin",
            "Dark Willow": -1.108
        },
        {
            "name": "Terrorblade",
            "Dark Willow": 0.442
        },
        {
            "name": "Tidehunter",
            "Dark Willow": -0.31
        },
        {
            "name": "Timbersaw",
            "Dark Willow": -0.12
        },
        {
            "name": "Tinker",
            "Dark Willow": 0.888
        },
        {
            "name": "Tiny",
            "Dark Willow": 0.037
        },
        {
            "name": "Treant Protector",
            "Dark Willow": -0.384
        },
        {
            "name": "Troll Warlord",
            "Dark Willow": -0.237
        },
        {
            "name": "Tusk",
            "Dark Willow": -1.573
        },
        {
            "name": "Underlord",
            "Dark Willow": 0.967
        },
        {
            "name": "Undying",
            "Dark Willow": 0.112
        },
        {
            "name": "Ursa",
            "Dark Willow": -0.86
        },
        {
            "name": "Vengeful Spirit",
            "Dark Willow": -1.052
        },
        {
            "name": "Venomancer",
            "Dark Willow": 0.467
        },
        {
            "name": "Viper",
            "Dark Willow": 0.001
        },
        {
            "name": "Visage",
            "Dark Willow": -2.37
        },
        {
            "name": "Void Spirit",
            "Dark Willow": 0.185
        },
        {
            "name": "Warlock",
            "Dark Willow": 0.766
        },
        {
            "name": "Weaver",
            "Dark Willow": -2.077
        },
        {
            "name": "Windranger",
            "Dark Willow": 0.009
        },
        {
            "name": "Winter Wyvern",
            "Dark Willow": -0.106
        },
        {
            "name": "Witch Doctor",
            "Dark Willow": -1.081
        },
        {
            "name": "Wraith King",
            "Dark Willow": 0.073
        },
        {
            "name": "Zeus",
            "Dark Willow": -0.749
        }
    ],
    "Dazzle": [
        {
            "name": "Abaddon",
            "Dazzle": 1.065
        },
        {
            "name": "Alchemist",
            "Dazzle": -0.63
        },
        {
            "name": "Ancient Apparition",
            "Dazzle": 2.225
        },
        {
            "name": "Anti-Mage",
            "Dazzle": -0.443
        },
        {
            "name": "Arc Warden",
            "Dazzle": -1.145
        },
        {
            "name": "Axe",
            "Dazzle": 1.643
        },
        {
            "name": "Bane",
            "Dazzle": -0.333
        },
        {
            "name": "Batrider",
            "Dazzle": 0.266
        },
        {
            "name": "Beastmaster",
            "Dazzle": -1.635
        },
        {
            "name": "Bloodseeker",
            "Dazzle": -0.415
        },
        {
            "name": "Bounty Hunter",
            "Dazzle": -0.517
        },
        {
            "name": "Brewmaster",
            "Dazzle": -1.43
        },
        {
            "name": "Bristleback",
            "Dazzle": -1.243
        },
        {
            "name": "Broodmother",
            "Dazzle": -0.858
        },
        {
            "name": "Centaur Warrunner",
            "Dazzle": -1.835
        },
        {
            "name": "Chaos Knight",
            "Dazzle": -1.171
        },
        {
            "name": "Chen",
            "Dazzle": -1.121
        },
        {
            "name": "Clinkz",
            "Dazzle": -1.189
        },
        {
            "name": "Clockwerk",
            "Dazzle": -0.97
        },
        {
            "name": "Crystal Maiden",
            "Dazzle": 0.167
        },
        {
            "name": "Dark Seer",
            "Dazzle": -0.402
        },
        {
            "name": "Dark Willow",
            "Dazzle": 0.449
        },
        {
            "name": "Dawnbreaker",
            "Dazzle": -0.278
        },
        {
            "name": "Death Prophet",
            "Dazzle": 0.174
        },
        {
            "name": "Disruptor",
            "Dazzle": -1.074
        },
        {
            "name": "Doom",
            "Dazzle": 0.355
        },
        {
            "name": "Dragon Knight",
            "Dazzle": 0.406
        },
        {
            "name": "Drow Ranger",
            "Dazzle": 0.737
        },
        {
            "name": "Earth Spirit",
            "Dazzle": 0.553
        },
        {
            "name": "Earthshaker",
            "Dazzle": -0.009
        },
        {
            "name": "Elder Titan",
            "Dazzle": -0.805
        },
        {
            "name": "Ember Spirit",
            "Dazzle": -1.536
        },
        {
            "name": "Enchantress",
            "Dazzle": -0.576
        },
        {
            "name": "Enigma",
            "Dazzle": -0.59
        },
        {
            "name": "Faceless Void",
            "Dazzle": 0.479
        },
        {
            "name": "Grimstroke",
            "Dazzle": 0.375
        },
        {
            "name": "Gyrocopter",
            "Dazzle": 1.418
        },
        {
            "name": "Hoodwink",
            "Dazzle": 0.432
        },
        {
            "name": "Huskar",
            "Dazzle": -0.808
        },
        {
            "name": "Invoker",
            "Dazzle": -0.738
        },
        {
            "name": "Io",
            "Dazzle": -0.983
        },
        {
            "name": "Jakiro",
            "Dazzle": 0.417
        },
        {
            "name": "Juggernaut",
            "Dazzle": -0.55
        },
        {
            "name": "Keeper of the Light",
            "Dazzle": 0.36
        },
        {
            "name": "Kez",
            "Dazzle": 0.238
        },
        {
            "name": "Kunkka",
            "Dazzle": -0.432
        },
        {
            "name": "Legion Commander",
            "Dazzle": -1.616
        },
        {
            "name": "Leshrac",
            "Dazzle": 0.035
        },
        {
            "name": "Lich",
            "Dazzle": -0.377
        },
        {
            "name": "Lifestealer",
            "Dazzle": -1.837
        },
        {
            "name": "Lina",
            "Dazzle": -0.126
        },
        {
            "name": "Lion",
            "Dazzle": -1.35
        },
        {
            "name": "Lone Druid",
            "Dazzle": -1.496
        },
        {
            "name": "Luna",
            "Dazzle": 0.643
        },
        {
            "name": "Lycan",
            "Dazzle": -1.076
        },
        {
            "name": "Magnus",
            "Dazzle": -0.087
        },
        {
            "name": "Marci",
            "Dazzle": 0.107
        },
        {
            "name": "Mars",
            "Dazzle": 0.372
        },
        {
            "name": "Medusa",
            "Dazzle": -1.273
        },
        {
            "name": "Meepo",
            "Dazzle": -2.017
        },
        {
            "name": "Mirana",
            "Dazzle": 0.477
        },
        {
            "name": "Monkey King",
            "Dazzle": 0.672
        },
        {
            "name": "Morphling",
            "Dazzle": 0.175
        },
        {
            "name": "Muerta",
            "Dazzle": 0.392
        },
        {
            "name": "Naga Siren",
            "Dazzle": -3.12
        },
        {
            "name": "Nature's Prophet",
            "Dazzle": -0.209
        },
        {
            "name": "Necrophos",
            "Dazzle": -1.074
        },
        {
            "name": "Night Stalker",
            "Dazzle": -0.381
        },
        {
            "name": "Nyx Assassin",
            "Dazzle": -0.156
        },
        {
            "name": "Ogre Magi",
            "Dazzle": 0.081
        },
        {
            "name": "Omniknight",
            "Dazzle": 0.474
        },
        {
            "name": "Oracle",
            "Dazzle": -1.628
        },
        {
            "name": "Outworld Destroyer",
            "Dazzle": 0.754
        },
        {
            "name": "Pangolier",
            "Dazzle": 1.868
        },
        {
            "name": "Phantom Assassin",
            "Dazzle": 0.215
        },
        {
            "name": "Phantom Lancer",
            "Dazzle": 0.793
        },
        {
            "name": "Phoenix",
            "Dazzle": 1.38
        },
        {
            "name": "Primal Beast",
            "Dazzle": 0.506
        },
        {
            "name": "Puck",
            "Dazzle": 0.055
        },
        {
            "name": "Pudge",
            "Dazzle": -0.147
        },
        {
            "name": "Pugna",
            "Dazzle": 0.579
        },
        {
            "name": "Queen of Pain",
            "Dazzle": 0.611
        },
        {
            "name": "Razor",
            "Dazzle": 1.28
        },
        {
            "name": "Riki",
            "Dazzle": 1.421
        },
        {
            "name": "Ringmaster",
            "Dazzle": -0.238
        },
        {
            "name": "Rubick",
            "Dazzle": -1.093
        },
        {
            "name": "Sand King",
            "Dazzle": 0.843
        },
        {
            "name": "Shadow Demon",
            "Dazzle": -0.646
        },
        {
            "name": "Shadow Fiend",
            "Dazzle": 0.404
        },
        {
            "name": "Shadow Shaman",
            "Dazzle": -0.091
        },
        {
            "name": "Silencer",
            "Dazzle": 0.371
        },
        {
            "name": "Skywrath Mage",
            "Dazzle": -1.122
        },
        {
            "name": "Slardar",
            "Dazzle": -0.196
        },
        {
            "name": "Slark",
            "Dazzle": 1.243
        },
        {
            "name": "Snapfire",
            "Dazzle": -0.03
        },
        {
            "name": "Sniper",
            "Dazzle": 0.058
        },
        {
            "name": "Spectre",
            "Dazzle": -1.613
        },
        {
            "name": "Spirit Breaker",
            "Dazzle": -0.046
        },
        {
            "name": "Storm Spirit",
            "Dazzle": -1.783
        },
        {
            "name": "Sven",
            "Dazzle": 0.924
        },
        {
            "name": "Techies",
            "Dazzle": -0.152
        },
        {
            "name": "Templar Assassin",
            "Dazzle": -0.042
        },
        {
            "name": "Terrorblade",
            "Dazzle": -0.919
        },
        {
            "name": "Tidehunter",
            "Dazzle": 2.138
        },
        {
            "name": "Timbersaw",
            "Dazzle": -0.034
        },
        {
            "name": "Tinker",
            "Dazzle": 0.262
        },
        {
            "name": "Tiny",
            "Dazzle": -0.103
        },
        {
            "name": "Treant Protector",
            "Dazzle": 0.805
        },
        {
            "name": "Troll Warlord",
            "Dazzle": 1.047
        },
        {
            "name": "Tusk",
            "Dazzle": 0.537
        },
        {
            "name": "Underlord",
            "Dazzle": 0.324
        },
        {
            "name": "Undying",
            "Dazzle": 0.535
        },
        {
            "name": "Ursa",
            "Dazzle": -0.818
        },
        {
            "name": "Vengeful Spirit",
            "Dazzle": 0.075
        },
        {
            "name": "Venomancer",
            "Dazzle": 1.265
        },
        {
            "name": "Viper",
            "Dazzle": 0.634
        },
        {
            "name": "Visage",
            "Dazzle": -1.507
        },
        {
            "name": "Void Spirit",
            "Dazzle": -1.184
        },
        {
            "name": "Warlock",
            "Dazzle": 0.012
        },
        {
            "name": "Weaver",
            "Dazzle": 1.719
        },
        {
            "name": "Windranger",
            "Dazzle": 0.609
        },
        {
            "name": "Winter Wyvern",
            "Dazzle": 0.993
        },
        {
            "name": "Witch Doctor",
            "Dazzle": 0.075
        },
        {
            "name": "Wraith King",
            "Dazzle": -2.169
        },
        {
            "name": "Zeus",
            "Dazzle": -0.343
        }
    ],
    "Enigma": [
        {
            "name": "Abaddon",
            "Enigma": 0.597
        },
        {
            "name": "Alchemist",
            "Enigma": 2.433
        },
        {
            "name": "Ancient Apparition",
            "Enigma": 0.199
        },
        {
            "name": "Anti-Mage",
            "Enigma": -2.076
        },
        {
            "name": "Arc Warden",
            "Enigma": -1.703
        },
        {
            "name": "Axe",
            "Enigma": 1.213
        },
        {
            "name": "Bane",
            "Enigma": 0.818
        },
        {
            "name": "Batrider",
            "Enigma": -1.652
        },
        {
            "name": "Beastmaster",
            "Enigma": -1.046
        },
        {
            "name": "Bloodseeker",
            "Enigma": -0.219
        },
        {
            "name": "Bounty Hunter",
            "Enigma": -0.757
        },
        {
            "name": "Brewmaster",
            "Enigma": -0.156
        },
        {
            "name": "Bristleback",
            "Enigma": 4.787
        },
        {
            "name": "Broodmother",
            "Enigma": -1.066
        },
        {
            "name": "Centaur Warrunner",
            "Enigma": -0.235
        },
        {
            "name": "Chaos Knight",
            "Enigma": -0.763
        },
        {
            "name": "Chen",
            "Enigma": -3.496
        },
        {
            "name": "Clinkz",
            "Enigma": 2.065
        },
        {
            "name": "Clockwerk",
            "Enigma": -1.023
        },
        {
            "name": "Crystal Maiden",
            "Enigma": -1.924
        },
        {
            "name": "Dark Seer",
            "Enigma": -0.299
        },
        {
            "name": "Dark Willow",
            "Enigma": -1.011
        },
        {
            "name": "Dawnbreaker",
            "Enigma": 0.487
        },
        {
            "name": "Dazzle",
            "Enigma": 0.471
        },
        {
            "name": "Death Prophet",
            "Enigma": -0.272
        },
        {
            "name": "Disruptor",
            "Enigma": 0.648
        },
        {
            "name": "Doom",
            "Enigma": -1.566
        },
        {
            "name": "Dragon Knight",
            "Enigma": -0.172
        },
        {
            "name": "Drow Ranger",
            "Enigma": -0.065
        },
        {
            "name": "Earth Spirit",
            "Enigma": -2.358
        },
        {
            "name": "Earthshaker",
            "Enigma": 1.044
        },
        {
            "name": "Elder Titan",
            "Enigma": 0.076
        },
        {
            "name": "Ember Spirit",
            "Enigma": -0.749
        },
        {
            "name": "Enchantress",
            "Enigma": 0.888
        },
        {
            "name": "Faceless Void",
            "Enigma": 0.552
        },
        {
            "name": "Grimstroke",
            "Enigma": 0.973
        },
        {
            "name": "Gyrocopter",
            "Enigma": 2.889
        },
        {
            "name": "Hoodwink",
            "Enigma": 0.165
        },
        {
            "name": "Huskar",
            "Enigma": -1.02
        },
        {
            "name": "Invoker",
            "Enigma": -0.322
        },
        {
            "name": "Io",
            "Enigma": -1.072
        },
        {
            "name": "Jakiro",
            "Enigma": 1.147
        },
        {
            "name": "Juggernaut",
            "Enigma": -0.695
        },
        {
            "name": "Keeper of the Light",
            "Enigma": -0.083
        },
        {
            "name": "Kez",
            "Enigma": -0.78
        },
        {
            "name": "Kunkka",
            "Enigma": 2.389
        },
        {
            "name": "Legion Commander",
            "Enigma": 0.872
        },
        {
            "name": "Leshrac",
            "Enigma": -0.127
        },
        {
            "name": "Lich",
            "Enigma": -0.818
        },
        {
            "name": "Lifestealer",
            "Enigma": -3.782
        },
        {
            "name": "Lina",
            "Enigma": 0.582
        },
        {
            "name": "Lion",
            "Enigma": -0.079
        },
        {
            "name": "Lone Druid",
            "Enigma": -1.871
        },
        {
            "name": "Luna",
            "Enigma": -0.064
        },
        {
            "name": "Lycan",
            "Enigma": -1.807
        },
        {
            "name": "Magnus",
            "Enigma": -2.619
        },
        {
            "name": "Marci",
            "Enigma": -1.877
        },
        {
            "name": "Mars",
            "Enigma": -1.416
        },
        {
            "name": "Medusa",
            "Enigma": 4.057
        },
        {
            "name": "Meepo",
            "Enigma": -2.016
        },
        {
            "name": "Mirana",
            "Enigma": -0.427
        },
        {
            "name": "Monkey King",
            "Enigma": -1.335
        },
        {
            "name": "Morphling",
            "Enigma": 0.149
        },
        {
            "name": "Muerta",
            "Enigma": -0.458
        },
        {
            "name": "Naga Siren",
            "Enigma": -1.556
        },
        {
            "name": "Nature's Prophet",
            "Enigma": 1.68
        },
        {
            "name": "Necrophos",
            "Enigma": 0.817
        },
        {
            "name": "Night Stalker",
            "Enigma": -1.734
        },
        {
            "name": "Nyx Assassin",
            "Enigma": -0.145
        },
        {
            "name": "Ogre Magi",
            "Enigma": -1.842
        },
        {
            "name": "Omniknight",
            "Enigma": -0.108
        },
        {
            "name": "Oracle",
            "Enigma": -0.633
        },
        {
            "name": "Outworld Destroyer",
            "Enigma": -2.167
        },
        {
            "name": "Pangolier",
            "Enigma": -0.237
        },
        {
            "name": "Phantom Assassin",
            "Enigma": 0.962
        },
        {
            "name": "Phantom Lancer",
            "Enigma": -0.86
        },
        {
            "name": "Phoenix",
            "Enigma": 0.231
        },
        {
            "name": "Primal Beast",
            "Enigma": -2.657
        },
        {
            "name": "Puck",
            "Enigma": -0.57
        },
        {
            "name": "Pudge",
            "Enigma": -0.763
        },
        {
            "name": "Pugna",
            "Enigma": -1.023
        },
        {
            "name": "Queen of Pain",
            "Enigma": -0.949
        },
        {
            "name": "Razor",
            "Enigma": -1.099
        },
        {
            "name": "Riki",
            "Enigma": 2.021
        },
        {
            "name": "Ringmaster",
            "Enigma": -0.252
        },
        {
            "name": "Rubick",
            "Enigma": 4.536
        },
        {
            "name": "Sand King",
            "Enigma": 1.071
        },
        {
            "name": "Shadow Demon",
            "Enigma": -1.986
        },
        {
            "name": "Shadow Fiend",
            "Enigma": 0.367
        },
        {
            "name": "Shadow Shaman",
            "Enigma": -0.583
        },
        {
            "name": "Silencer",
            "Enigma": 1.909
        },
        {
            "name": "Skywrath Mage",
            "Enigma": -0.312
        },
        {
            "name": "Slardar",
            "Enigma": -0.926
        },
        {
            "name": "Slark",
            "Enigma": -2.732
        },
        {
            "name": "Snapfire",
            "Enigma": 0.33
        },
        {
            "name": "Sniper",
            "Enigma": 2.885
        },
        {
            "name": "Spectre",
            "Enigma": 2.258
        },
        {
            "name": "Spirit Breaker",
            "Enigma": -0.84
        },
        {
            "name": "Storm Spirit",
            "Enigma": -2.148
        },
        {
            "name": "Sven",
            "Enigma": 0.279
        },
        {
            "name": "Techies",
            "Enigma": -0.611
        },
        {
            "name": "Templar Assassin",
            "Enigma": -0.073
        },
        {
            "name": "Terrorblade",
            "Enigma": 1.589
        },
        {
            "name": "Tidehunter",
            "Enigma": -0.496
        },
        {
            "name": "Timbersaw",
            "Enigma": -0.821
        },
        {
            "name": "Tinker",
            "Enigma": -3.348
        },
        {
            "name": "Tiny",
            "Enigma": -0.57
        },
        {
            "name": "Treant Protector",
            "Enigma": 0.678
        },
        {
            "name": "Troll Warlord",
            "Enigma": 0.198
        },
        {
            "name": "Tusk",
            "Enigma": -1.184
        },
        {
            "name": "Underlord",
            "Enigma": -1.791
        },
        {
            "name": "Undying",
            "Enigma": 0.859
        },
        {
            "name": "Ursa",
            "Enigma": -1.782
        },
        {
            "name": "Vengeful Spirit",
            "Enigma": 0.063
        },
        {
            "name": "Venomancer",
            "Enigma": -2.049
        },
        {
            "name": "Viper",
            "Enigma": 0.024
        },
        {
            "name": "Visage",
            "Enigma": -1.844
        },
        {
            "name": "Void Spirit",
            "Enigma": -0.964
        },
        {
            "name": "Warlock",
            "Enigma": 2.849
        },
        {
            "name": "Weaver",
            "Enigma": 0.47
        },
        {
            "name": "Windranger",
            "Enigma": -0.287
        },
        {
            "name": "Winter Wyvern",
            "Enigma": 1.805
        },
        {
            "name": "Witch Doctor",
            "Enigma": -0.15
        },
        {
            "name": "Wraith King",
            "Enigma": 2.684
        },
        {
            "name": "Zeus",
            "Enigma": 0.075
        }
    ],
    "Invoker": [
        {
            "name": "Abaddon",
            "Invoker": -1.019
        },
        {
            "name": "Alchemist",
            "Invoker": -0.579
        },
        {
            "name": "Ancient Apparition",
            "Invoker": -0.535
        },
        {
            "name": "Anti-Mage",
            "Invoker": 0.623
        },
        {
            "name": "Arc Warden",
            "Invoker": 0.416
        },
        {
            "name": "Axe",
            "Invoker": 0.172
        },
        {
            "name": "Bane",
            "Invoker": 0.444
        },
        {
            "name": "Batrider",
            "Invoker": 1.381
        },
        {
            "name": "Beastmaster",
            "Invoker": 0.767
        },
        {
            "name": "Bloodseeker",
            "Invoker": -0.274
        },
        {
            "name": "Bounty Hunter",
            "Invoker": 0.691
        },
        {
            "name": "Brewmaster",
            "Invoker": -0.377
        },
        {
            "name": "Bristleback",
            "Invoker": 0.982
        },
        {
            "name": "Broodmother",
            "Invoker": 1.843
        },
        {
            "name": "Centaur Warrunner",
            "Invoker": -1.154
        },
        {
            "name": "Chaos Knight",
            "Invoker": -1.089
        },
        {
            "name": "Chen",
            "Invoker": -0.212
        },
        {
            "name": "Clinkz",
            "Invoker": -0.052
        },
        {
            "name": "Clockwerk",
            "Invoker": -1.157
        },
        {
            "name": "Crystal Maiden",
            "Invoker": -0.321
        },
        {
            "name": "Dark Seer",
            "Invoker": -0.245
        },
        {
            "name": "Dark Willow",
            "Invoker": -0.377
        },
        {
            "name": "Dawnbreaker",
            "Invoker": -1.446
        },
        {
            "name": "Dazzle",
            "Invoker": 0.671
        },
        {
            "name": "Death Prophet",
            "Invoker": 0.428
        },
        {
            "name": "Disruptor",
            "Invoker": -1.107
        },
        {
            "name": "Doom",
            "Invoker": -0.824
        },
        {
            "name": "Dragon Knight",
            "Invoker": -0.591
        },
        {
            "name": "Drow Ranger",
            "Invoker": -0.605
        },
        {
            "name": "Earth Spirit",
            "Invoker": 0.059
        },
        {
            "name": "Earthshaker",
            "Invoker": -0.045
        },
        {
            "name": "Elder Titan",
            "Invoker": -0.994
        },
        {
            "name": "Ember Spirit",
            "Invoker": -0.242
        },
        {
            "name": "Enchantress",
            "Invoker": 0.443
        },
        {
            "name": "Enigma",
            "Invoker": 0.025
        },
        {
            "name": "Faceless Void",
            "Invoker": 1.266
        },
        {
            "name": "Grimstroke",
            "Invoker": -0.357
        },
        {
            "name": "Gyrocopter",
            "Invoker": 0.239
        },
        {
            "name": "Hoodwink",
            "Invoker": 1.077
        },
        {
            "name": "Huskar",
            "Invoker": 0.129
        },
        {
            "name": "Io",
            "Invoker": 0.257
        },
        {
            "name": "Jakiro",
            "Invoker": -0.428
        },
        {
            "name": "Juggernaut",
            "Invoker": 0.219
        },
        {
            "name": "Keeper of the Light",
            "Invoker": 1.056
        },
        {
            "name": "Kez",
            "Invoker": 0.551
        },
        {
            "name": "Kunkka",
            "Invoker": -0.329
        },
        {
            "name": "Legion Commander",
            "Invoker": -0.206
        },
        {
            "name": "Leshrac",
            "Invoker": -0.809
        },
        {
            "name": "Lich",
            "Invoker": -1.103
        },
        {
            "name": "Lifestealer",
            "Invoker": 0.346
        },
        {
            "name": "Lina",
            "Invoker": -0.46
        },
        {
            "name": "Lion",
            "Invoker": -0.42
        },
        {
            "name": "Lone Druid",
            "Invoker": -0.11
        },
        {
            "name": "Luna",
            "Invoker": -0.415
        },
        {
            "name": "Lycan",
            "Invoker": 1.036
        },
        {
            "name": "Magnus",
            "Invoker": -0.461
        },
        {
            "name": "Marci",
            "Invoker": 0.39
        },
        {
            "name": "Mars",
            "Invoker": 0.138
        },
        {
            "name": "Medusa",
            "Invoker": -4.66
        },
        {
            "name": "Meepo",
            "Invoker": 1.255
        },
        {
            "name": "Mirana",
            "Invoker": 1.239
        },
        {
            "name": "Monkey King",
            "Invoker": -0.89
        },
        {
            "name": "Morphling",
            "Invoker": 0.647
        },
        {
            "name": "Muerta",
            "Invoker": -1.152
        },
        {
            "name": "Naga Siren",
            "Invoker": 0.79
        },
        {
            "name": "Nature's Prophet",
            "Invoker": 0.413
        },
        {
            "name": "Necrophos",
            "Invoker": 0.013
        },
        {
            "name": "Night Stalker",
            "Invoker": -1.086
        },
        {
            "name": "Nyx Assassin",
            "Invoker": -0.098
        },
        {
            "name": "Ogre Magi",
            "Invoker": -1.053
        },
        {
            "name": "Omniknight",
            "Invoker": 0.661
        },
        {
            "name": "Oracle",
            "Invoker": -0.899
        },
        {
            "name": "Outworld Destroyer",
            "Invoker": 0.395
        },
        {
            "name": "Pangolier",
            "Invoker": 1.085
        },
        {
            "name": "Phantom Assassin",
            "Invoker": 1.517
        },
        {
            "name": "Phantom Lancer",
            "Invoker": 2.79
        },
        {
            "name": "Phoenix",
            "Invoker": -0.123
        },
        {
            "name": "Primal Beast",
            "Invoker": 0.02
        },
        {
            "name": "Puck",
            "Invoker": 1.911
        },
        {
            "name": "Pudge",
            "Invoker": -0.791
        },
        {
            "name": "Pugna",
            "Invoker": 0.244
        },
        {
            "name": "Queen of Pain",
            "Invoker": -0.086
        },
        {
            "name": "Razor",
            "Invoker": 0.021
        },
        {
            "name": "Riki",
            "Invoker": 1.106
        },
        {
            "name": "Ringmaster",
            "Invoker": 0.194
        },
        {
            "name": "Rubick",
            "Invoker": -0.27
        },
        {
            "name": "Sand King",
            "Invoker": 1.786
        },
        {
            "name": "Shadow Demon",
            "Invoker": 0.689
        },
        {
            "name": "Shadow Fiend",
            "Invoker": -0.433
        },
        {
            "name": "Shadow Shaman",
            "Invoker": -0.269
        },
        {
            "name": "Silencer",
            "Invoker": -1.27
        },
        {
            "name": "Skywrath Mage",
            "Invoker": -0.156
        },
        {
            "name": "Slardar",
            "Invoker": 0.479
        },
        {
            "name": "Slark",
            "Invoker": -1.359
        },
        {
            "name": "Snapfire",
            "Invoker": 1.051
        },
        {
            "name": "Sniper",
            "Invoker": 0.063
        },
        {
            "name": "Spectre",
            "Invoker": -1.05
        },
        {
            "name": "Spirit Breaker",
            "Invoker": -1.044
        },
        {
            "name": "Storm Spirit",
            "Invoker": -0.493
        },
        {
            "name": "Sven",
            "Invoker": 0.039
        },
        {
            "name": "Techies",
            "Invoker": 0.206
        },
        {
            "name": "Templar Assassin",
            "Invoker": 1.591
        },
        {
            "name": "Terrorblade",
            "Invoker": 0.277
        },
        {
            "name": "Tidehunter",
            "Invoker": -0.065
        },
        {
            "name": "Timbersaw",
            "Invoker": -0.387
        },
        {
            "name": "Tinker",
            "Invoker": 0.215
        },
        {
            "name": "Tiny",
            "Invoker": 0.042
        },
        {
            "name": "Treant Protector",
            "Invoker": -0.111
        },
        {
            "name": "Troll Warlord",
            "Invoker": -1.525
        },
        {
            "name": "Tusk",
            "Invoker": 1.335
        },
        {
            "name": "Underlord",
            "Invoker": -0.94
        },
        {
            "name": "Undying",
            "Invoker": -0.83
        },
        {
            "name": "Ursa",
            "Invoker": -0.089
        },
        {
            "name": "Vengeful Spirit",
            "Invoker": -0.318
        },
        {
            "name": "Venomancer",
            "Invoker": -0.071
        },
        {
            "name": "Viper",
            "Invoker": -1.603
        },
        {
            "name": "Visage",
            "Invoker": -0.036
        },
        {
            "name": "Void Spirit",
            "Invoker": 1.225
        },
        {
            "name": "Warlock",
            "Invoker": -0.917
        },
        {
            "name": "Weaver",
            "Invoker": 1.063
        },
        {
            "name": "Windranger",
            "Invoker": 1.313
        },
        {
            "name": "Winter Wyvern",
            "Invoker": 0.978
        },
        {
            "name": "Witch Doctor",
            "Invoker": -0.481
        },
        {
            "name": "Wraith King",
            "Invoker": -2.067
        },
        {
            "name": "Zeus",
            "Invoker": 0.386
        }
    ],
    "Io": [
        {
            "name": "Abaddon",
            "Io": -2.221
        },
        {
            "name": "Alchemist",
            "Io": -0.207
        },
        {
            "name": "Ancient Apparition",
            "Io": 3.349
        },
        {
            "name": "Anti-Mage",
            "Io": -2.061
        },
        {
            "name": "Arc Warden",
            "Io": -2.171
        },
        {
            "name": "Axe",
            "Io": -0.267
        },
        {
            "name": "Bane",
            "Io": -0.37
        },
        {
            "name": "Batrider",
            "Io": 0.729
        },
        {
            "name": "Beastmaster",
            "Io": -0.121
        },
        {
            "name": "Bloodseeker",
            "Io": -2.38
        },
        {
            "name": "Bounty Hunter",
            "Io": -0.423
        },
        {
            "name": "Brewmaster",
            "Io": -1.874
        },
        {
            "name": "Bristleback",
            "Io": 0.38
        },
        {
            "name": "Broodmother",
            "Io": 0.737
        },
        {
            "name": "Centaur Warrunner",
            "Io": -1.312
        },
        {
            "name": "Chaos Knight",
            "Io": -0.492
        },
        {
            "name": "Chen",
            "Io": -0.701
        },
        {
            "name": "Clinkz",
            "Io": -0.652
        },
        {
            "name": "Clockwerk",
            "Io": -1.485
        },
        {
            "name": "Crystal Maiden",
            "Io": 0.993
        },
        {
            "name": "Dark Seer",
            "Io": 0.187
        },
        {
            "name": "Dark Willow",
            "Io": 2.36
        },
        {
            "name": "Dawnbreaker",
            "Io": 0.032
        },
        {
            "name": "Dazzle",
            "Io": 0.898
        },
        {
            "name": "Death Prophet",
            "Io": 0.521
        },
        {
            "name": "Disruptor",
            "Io": 1.46
        },
        {
            "name": "Doom",
            "Io": 0.962
        },
        {
            "name": "Dragon Knight",
            "Io": 1.501
        },
        {
            "name": "Drow Ranger",
            "Io": 0.884
        },
        {
            "name": "Earth Spirit",
            "Io": -0.731
        },
        {
            "name": "Earthshaker",
            "Io": 2.246
        },
        {
            "name": "Elder Titan",
            "Io": 1.699
        },
        {
            "name": "Ember Spirit",
            "Io": 0.449
        },
        {
            "name": "Enchantress",
            "Io": -2.419
        },
        {
            "name": "Enigma",
            "Io": 1.133
        },
        {
            "name": "Faceless Void",
            "Io": 1.481
        },
        {
            "name": "Grimstroke",
            "Io": -0.121
        },
        {
            "name": "Gyrocopter",
            "Io": 0.769
        },
        {
            "name": "Hoodwink",
            "Io": 1.086
        },
        {
            "name": "Huskar",
            "Io": -0.804
        },
        {
            "name": "Invoker",
            "Io": -0.774
        },
        {
            "name": "Jakiro",
            "Io": 0.801
        },
        {
            "name": "Juggernaut",
            "Io": -1.567
        },
        {
            "name": "Keeper of the Light",
            "Io": -0.872
        },
        {
            "name": "Kez",
            "Io": 0.482
        },
        {
            "name": "Kunkka",
            "Io": 2.121
        },
        {
            "name": "Legion Commander",
            "Io": -0.105
        },
        {
            "name": "Leshrac",
            "Io": -0.238
        },
        {
            "name": "Lich",
            "Io": 1.589
        },
        {
            "name": "Lifestealer",
            "Io": -2.237
        },
        {
            "name": "Lina",
            "Io": 0.804
        },
        {
            "name": "Lion",
            "Io": -0.366
        },
        {
            "name": "Lone Druid",
            "Io": -0.247
        },
        {
            "name": "Luna",
            "Io": 2.104
        },
        {
            "name": "Lycan",
            "Io": 0.138
        },
        {
            "name": "Magnus",
            "Io": 1.514
        },
        {
            "name": "Marci",
            "Io": 1.817
        },
        {
            "name": "Mars",
            "Io": 1.364
        },
        {
            "name": "Medusa",
            "Io": 0.938
        },
        {
            "name": "Meepo",
            "Io": -0.655
        },
        {
            "name": "Mirana",
            "Io": 1.637
        },
        {
            "name": "Monkey King",
            "Io": 1.911
        },
        {
            "name": "Morphling",
            "Io": -0.721
        },
        {
            "name": "Muerta",
            "Io": 0.805
        },
        {
            "name": "Naga Siren",
            "Io": 2.29
        },
        {
            "name": "Nature's Prophet",
            "Io": -0.766
        },
        {
            "name": "Necrophos",
            "Io": -1.07
        },
        {
            "name": "Night Stalker",
            "Io": -2.653
        },
        {
            "name": "Nyx Assassin",
            "Io": -1.227
        },
        {
            "name": "Ogre Magi",
            "Io": -0.305
        },
        {
            "name": "Omniknight",
            "Io": 0.755
        },
        {
            "name": "Oracle",
            "Io": -2.725
        },
        {
            "name": "Outworld Destroyer",
            "Io": 0.347
        },
        {
            "name": "Pangolier",
            "Io": -0.054
        },
        {
            "name": "Phantom Assassin",
            "Io": 0.231
        },
        {
            "name": "Phantom Lancer",
            "Io": 1.64
        },
        {
            "name": "Phoenix",
            "Io": -1.532
        },
        {
            "name": "Primal Beast",
            "Io": 1.454
        },
        {
            "name": "Puck",
            "Io": -1.408
        },
        {
            "name": "Pudge",
            "Io": -1.162
        },
        {
            "name": "Pugna",
            "Io": -0.529
        },
        {
            "name": "Queen of Pain",
            "Io": -0.815
        },
        {
            "name": "Razor",
            "Io": 0.525
        },
        {
            "name": "Riki",
            "Io": -0.2
        },
        {
            "name": "Ringmaster",
            "Io": -0.111
        },
        {
            "name": "Rubick",
            "Io": -1.113
        },
        {
            "name": "Sand King",
            "Io": 0.804
        },
        {
            "name": "Shadow Demon",
            "Io": -1.576
        },
        {
            "name": "Shadow Fiend",
            "Io": 1.579
        },
        {
            "name": "Shadow Shaman",
            "Io": -0.174
        },
        {
            "name": "Silencer",
            "Io": -0.181
        },
        {
            "name": "Skywrath Mage",
            "Io": -1.498
        },
        {
            "name": "Slardar",
            "Io": 1.425
        },
        {
            "name": "Slark",
            "Io": 0.904
        },
        {
            "name": "Snapfire",
            "Io": 0.604
        },
        {
            "name": "Sniper",
            "Io": 0.056
        },
        {
            "name": "Spectre",
            "Io": -2.841
        },
        {
            "name": "Spirit Breaker",
            "Io": -0.076
        },
        {
            "name": "Storm Spirit",
            "Io": -1.295
        },
        {
            "name": "Sven",
            "Io": 1.907
        },
        {
            "name": "Techies",
            "Io": 1.534
        },
        {
            "name": "Templar Assassin",
            "Io": 0.237
        },
        {
            "name": "Terrorblade",
            "Io": 1.4
        },
        {
            "name": "Tidehunter",
            "Io": -0.616
        },
        {
            "name": "Timbersaw",
            "Io": -1.287
        },
        {
            "name": "Tinker",
            "Io": -0.594
        },
        {
            "name": "Tiny",
            "Io": 1.117
        },
        {
            "name": "Treant Protector",
            "Io": 1.009
        },
        {
            "name": "Troll Warlord",
            "Io": 3.164
        },
        {
            "name": "Tusk",
            "Io": 0.32
        },
        {
            "name": "Underlord",
            "Io": 2.526
        },
        {
            "name": "Undying",
            "Io": 1.082
        },
        {
            "name": "Ursa",
            "Io": 0.446
        },
        {
            "name": "Vengeful Spirit",
            "Io": 0.58
        },
        {
            "name": "Venomancer",
            "Io": 1.34
        },
        {
            "name": "Viper",
            "Io": 0.941
        },
        {
            "name": "Visage",
            "Io": -1.017
        },
        {
            "name": "Void Spirit",
            "Io": -1.825
        },
        {
            "name": "Warlock",
            "Io": -0.296
        },
        {
            "name": "Weaver",
            "Io": 1.22
        },
        {
            "name": "Windranger",
            "Io": 2.17
        },
        {
            "name": "Winter Wyvern",
            "Io": 2.293
        },
        {
            "name": "Witch Doctor",
            "Io": 1.026
        },
        {
            "name": "Wraith King",
            "Io": -1.508
        },
        {
            "name": "Zeus",
            "Io": -1.571
        }
    ],
    "Lone Druid": [
        {
            "name": "Abaddon",
            "Lone Druid": -2.648
        },
        {
            "name": "Alchemist",
            "Lone Druid": 1.927
        },
        {
            "name": "Ancient Apparition",
            "Lone Druid": -0.26
        },
        {
            "name": "Anti-Mage",
            "Lone Druid": -4.64
        },
        {
            "name": "Arc Warden",
            "Lone Druid": -2.074
        },
        {
            "name": "Axe",
            "Lone Druid": 0.38
        },
        {
            "name": "Bane",
            "Lone Druid": -1.244
        },
        {
            "name": "Batrider",
            "Lone Druid": 0.521
        },
        {
            "name": "Beastmaster",
            "Lone Druid": 1.07
        },
        {
            "name": "Bloodseeker",
            "Lone Druid": 1.421
        },
        {
            "name": "Bounty Hunter",
            "Lone Druid": 0.554
        },
        {
            "name": "Brewmaster",
            "Lone Druid": 3.086
        },
        {
            "name": "Bristleback",
            "Lone Druid": 2.5
        },
        {
            "name": "Broodmother",
            "Lone Druid": 2.803
        },
        {
            "name": "Centaur Warrunner",
            "Lone Druid": -2.984
        },
        {
            "name": "Chaos Knight",
            "Lone Druid": 0.087
        },
        {
            "name": "Chen",
            "Lone Druid": 1.313
        },
        {
            "name": "Clinkz",
            "Lone Druid": 1.746
        },
        {
            "name": "Clockwerk",
            "Lone Druid": -2.141
        },
        {
            "name": "Crystal Maiden",
            "Lone Druid": 2.184
        },
        {
            "name": "Dark Seer",
            "Lone Druid": 0.028
        },
        {
            "name": "Dark Willow",
            "Lone Druid": 2.155
        },
        {
            "name": "Dawnbreaker",
            "Lone Druid": -0.107
        },
        {
            "name": "Dazzle",
            "Lone Druid": 1.329
        },
        {
            "name": "Death Prophet",
            "Lone Druid": 0.656
        },
        {
            "name": "Disruptor",
            "Lone Druid": -0.083
        },
        {
            "name": "Doom",
            "Lone Druid": -1.333
        },
        {
            "name": "Dragon Knight",
            "Lone Druid": 0.277
        },
        {
            "name": "Drow Ranger",
            "Lone Druid": -0.803
        },
        {
            "name": "Earth Spirit",
            "Lone Druid": -2.233
        },
        {
            "name": "Earthshaker",
            "Lone Druid": 3.245
        },
        {
            "name": "Elder Titan",
            "Lone Druid": 4.077
        },
        {
            "name": "Ember Spirit",
            "Lone Druid": -4.548
        },
        {
            "name": "Enchantress",
            "Lone Druid": 0.452
        },
        {
            "name": "Enigma",
            "Lone Druid": 1.893
        },
        {
            "name": "Faceless Void",
            "Lone Druid": -1.803
        },
        {
            "name": "Grimstroke",
            "Lone Druid": -1.035
        },
        {
            "name": "Gyrocopter",
            "Lone Druid": 0.935
        },
        {
            "name": "Hoodwink",
            "Lone Druid": 0.797
        },
        {
            "name": "Huskar",
            "Lone Druid": 3.534
        },
        {
            "name": "Invoker",
            "Lone Druid": -0.471
        },
        {
            "name": "Io",
            "Lone Druid": 0.204
        },
        {
            "name": "Jakiro",
            "Lone Druid": 2.701
        },
        {
            "name": "Juggernaut",
            "Lone Druid": -2.259
        },
        {
            "name": "Keeper of the Light",
            "Lone Druid": 0.532
        },
        {
            "name": "Kez",
            "Lone Druid": 1.555
        },
        {
            "name": "Kunkka",
            "Lone Druid": 1.676
        },
        {
            "name": "Legion Commander",
            "Lone Druid": -1.691
        },
        {
            "name": "Leshrac",
            "Lone Druid": 2.136
        },
        {
            "name": "Lich",
            "Lone Druid": 2.056
        },
        {
            "name": "Lifestealer",
            "Lone Druid": -0.99
        },
        {
            "name": "Lina",
            "Lone Druid": 0.048
        },
        {
            "name": "Lion",
            "Lone Druid": -1.319
        },
        {
            "name": "Luna",
            "Lone Druid": 3.567
        },
        {
            "name": "Lycan",
            "Lone Druid": 0.689
        },
        {
            "name": "Magnus",
            "Lone Druid": -0.423
        },
        {
            "name": "Marci",
            "Lone Druid": 1.67
        },
        {
            "name": "Mars",
            "Lone Druid": -2.393
        },
        {
            "name": "Medusa",
            "Lone Druid": -2.511
        },
        {
            "name": "Meepo",
            "Lone Druid": -0.902
        },
        {
            "name": "Mirana",
            "Lone Druid": -0.778
        },
        {
            "name": "Monkey King",
            "Lone Druid": 5.07
        },
        {
            "name": "Morphling",
            "Lone Druid": -0.785
        },
        {
            "name": "Muerta",
            "Lone Druid": 0.582
        },
        {
            "name": "Naga Siren",
            "Lone Druid": 1.563
        },
        {
            "name": "Nature's Prophet",
            "Lone Druid": 0.049
        },
        {
            "name": "Necrophos",
            "Lone Druid": -0.424
        },
        {
            "name": "Night Stalker",
            "Lone Druid": -4.144
        },
        {
            "name": "Nyx Assassin",
            "Lone Druid": -0.594
        },
        {
            "name": "Ogre Magi",
            "Lone Druid": -1.225
        },
        {
            "name": "Omniknight",
            "Lone Druid": 2.025
        },
        {
            "name": "Oracle",
            "Lone Druid": -1.903
        },
        {
            "name": "Outworld Destroyer",
            "Lone Druid": -1.636
        },
        {
            "name": "Pangolier",
            "Lone Druid": 2.086
        },
        {
            "name": "Phantom Assassin",
            "Lone Druid": 3.87
        },
        {
            "name": "Phantom Lancer",
            "Lone Druid": 2.165
        },
        {
            "name": "Phoenix",
            "Lone Druid": 2.679
        },
        {
            "name": "Primal Beast",
            "Lone Druid": -0.601
        },
        {
            "name": "Puck",
            "Lone Druid": -3.001
        },
        {
            "name": "Pudge",
            "Lone Druid": -1.788
        },
        {
            "name": "Pugna",
            "Lone Druid": -1.889
        },
        {
            "name": "Queen of Pain",
            "Lone Druid": -2.719
        },
        {
            "name": "Razor",
            "Lone Druid": 2.561
        },
        {
            "name": "Riki",
            "Lone Druid": 0.44
        },
        {
            "name": "Ringmaster",
            "Lone Druid": 0.43
        },
        {
            "name": "Rubick",
            "Lone Druid": 0.25
        },
        {
            "name": "Sand King",
            "Lone Druid": 3.258
        },
        {
            "name": "Shadow Demon",
            "Lone Druid": -2.096
        },
        {
            "name": "Shadow Fiend",
            "Lone Druid": 2.45
        },
        {
            "name": "Shadow Shaman",
            "Lone Druid": -2.433
        },
        {
            "name": "Silencer",
            "Lone Druid": -3.703
        },
        {
            "name": "Skywrath Mage",
            "Lone Druid": -2.315
        },
        {
            "name": "Slardar",
            "Lone Druid": 0.172
        },
        {
            "name": "Slark",
            "Lone Druid": 0.963
        },
        {
            "name": "Snapfire",
            "Lone Druid": 1.009
        },
        {
            "name": "Sniper",
            "Lone Druid": 1.264
        },
        {
            "name": "Spectre",
            "Lone Druid": -3.72
        },
        {
            "name": "Spirit Breaker",
            "Lone Druid": -0.072
        },
        {
            "name": "Storm Spirit",
            "Lone Druid": -3.917
        },
        {
            "name": "Sven",
            "Lone Druid": 1.755
        },
        {
            "name": "Techies",
            "Lone Druid": 2.605
        },
        {
            "name": "Templar Assassin",
            "Lone Druid": 0.09
        },
        {
            "name": "Terrorblade",
            "Lone Druid": 2.723
        },
        {
            "name": "Tidehunter",
            "Lone Druid": 0.175
        },
        {
            "name": "Timbersaw",
            "Lone Druid": 1.172
        },
        {
            "name": "Tinker",
            "Lone Druid": 1.171
        },
        {
            "name": "Tiny",
            "Lone Druid": 0.681
        },
        {
            "name": "Treant Protector",
            "Lone Druid": 1.588
        },
        {
            "name": "Troll Warlord",
            "Lone Druid": 1.277
        },
        {
            "name": "Tusk",
            "Lone Druid": 1.776
        },
        {
            "name": "Underlord",
            "Lone Druid": 3.978
        },
        {
            "name": "Undying",
            "Lone Druid": 1.58
        },
        {
            "name": "Ursa",
            "Lone Druid": 0.707
        },
        {
            "name": "Vengeful Spirit",
            "Lone Druid": -0.782
        },
        {
            "name": "Venomancer",
            "Lone Druid": 3.024
        },
        {
            "name": "Viper",
            "Lone Druid": 2.26
        },
        {
            "name": "Visage",
            "Lone Druid": 0.04
        },
        {
            "name": "Void Spirit",
            "Lone Druid": -3.423
        },
        {
            "name": "Warlock",
            "Lone Druid": 2.213
        },
        {
            "name": "Weaver",
            "Lone Druid": 1.416
        },
        {
            "name": "Windranger",
            "Lone Druid": 3.154
        },
        {
            "name": "Winter Wyvern",
            "Lone Druid": 5.228
        },
        {
            "name": "Witch Doctor",
            "Lone Druid": 0.882
        },
        {
            "name": "Wraith King",
            "Lone Druid": -4.227
        },
        {
            "name": "Zeus",
            "Lone Druid": -1.309
        }
    ],
    "Lycan": [
        {
            "name": "Abaddon",
            "Lycan": -0.797
        },
        {
            "name": "Alchemist",
            "Lycan": 2.002
        },
        {
            "name": "Ancient Apparition",
            "Lycan": -2.042
        },
        {
            "name": "Anti-Mage",
            "Lycan": -0.421
        },
        {
            "name": "Arc Warden",
            "Lycan": -3.947
        },
        {
            "name": "Axe",
            "Lycan": 2.326
        },
        {
            "name": "Bane",
            "Lycan": 0.892
        },
        {
            "name": "Batrider",
            "Lycan": 1.764
        },
        {
            "name": "Beastmaster",
            "Lycan": 0.354
        },
        {
            "name": "Bloodseeker",
            "Lycan": 1.798
        },
        {
            "name": "Bounty Hunter",
            "Lycan": 2.062
        },
        {
            "name": "Brewmaster",
            "Lycan": -0.269
        },
        {
            "name": "Bristleback",
            "Lycan": 2.635
        },
        {
            "name": "Broodmother",
            "Lycan": 2.828
        },
        {
            "name": "Centaur Warrunner",
            "Lycan": 0.13
        },
        {
            "name": "Chaos Knight",
            "Lycan": 1.935
        },
        {
            "name": "Chen",
            "Lycan": -2.542
        },
        {
            "name": "Clinkz",
            "Lycan": 0.437
        },
        {
            "name": "Clockwerk",
            "Lycan": -0.941
        },
        {
            "name": "Crystal Maiden",
            "Lycan": 3.024
        },
        {
            "name": "Dark Seer",
            "Lycan": 1.082
        },
        {
            "name": "Dark Willow",
            "Lycan": 0.632
        },
        {
            "name": "Dawnbreaker",
            "Lycan": 0.254
        },
        {
            "name": "Dazzle",
            "Lycan": 0.993
        },
        {
            "name": "Death Prophet",
            "Lycan": -3.261
        },
        {
            "name": "Disruptor",
            "Lycan": -0.797
        },
        {
            "name": "Doom",
            "Lycan": -0.696
        },
        {
            "name": "Dragon Knight",
            "Lycan": 2.451
        },
        {
            "name": "Drow Ranger",
            "Lycan": -2.592
        },
        {
            "name": "Earth Spirit",
            "Lycan": -0.299
        },
        {
            "name": "Earthshaker",
            "Lycan": 3.202
        },
        {
            "name": "Elder Titan",
            "Lycan": -0.447
        },
        {
            "name": "Ember Spirit",
            "Lycan": -0.287
        },
        {
            "name": "Enchantress",
            "Lycan": 3.552
        },
        {
            "name": "Enigma",
            "Lycan": 1.795
        },
        {
            "name": "Faceless Void",
            "Lycan": -0.417
        },
        {
            "name": "Grimstroke",
            "Lycan": -1.529
        },
        {
            "name": "Gyrocopter",
            "Lycan": 1.988
        },
        {
            "name": "Hoodwink",
            "Lycan": 0.697
        },
        {
            "name": "Huskar",
            "Lycan": 2.814
        },
        {
            "name": "Invoker",
            "Lycan": -1.568
        },
        {
            "name": "Io",
            "Lycan": -0.191
        },
        {
            "name": "Jakiro",
            "Lycan": 0.43
        },
        {
            "name": "Juggernaut",
            "Lycan": -1.199
        },
        {
            "name": "Keeper of the Light",
            "Lycan": -1.057
        },
        {
            "name": "Kez",
            "Lycan": 1.367
        },
        {
            "name": "Kunkka",
            "Lycan": -0.318
        },
        {
            "name": "Legion Commander",
            "Lycan": -0.621
        },
        {
            "name": "Leshrac",
            "Lycan": -0.144
        },
        {
            "name": "Lich",
            "Lycan": -0.726
        },
        {
            "name": "Lifestealer",
            "Lycan": -0.109
        },
        {
            "name": "Lina",
            "Lycan": 0.37
        },
        {
            "name": "Lion",
            "Lycan": -0.399
        },
        {
            "name": "Lone Druid",
            "Lycan": -0.609
        },
        {
            "name": "Luna",
            "Lycan": 1.348
        },
        {
            "name": "Magnus",
            "Lycan": 1.015
        },
        {
            "name": "Marci",
            "Lycan": 1.173
        },
        {
            "name": "Mars",
            "Lycan": -1.111
        },
        {
            "name": "Medusa",
            "Lycan": -0.157
        },
        {
            "name": "Meepo",
            "Lycan": 2.294
        },
        {
            "name": "Mirana",
            "Lycan": 2.033
        },
        {
            "name": "Monkey King",
            "Lycan": 2.761
        },
        {
            "name": "Morphling",
            "Lycan": 0.257
        },
        {
            "name": "Muerta",
            "Lycan": -1.27
        },
        {
            "name": "Naga Siren",
            "Lycan": 4.826
        },
        {
            "name": "Nature's Prophet",
            "Lycan": 0.423
        },
        {
            "name": "Necrophos",
            "Lycan": 2.177
        },
        {
            "name": "Night Stalker",
            "Lycan": -3.725
        },
        {
            "name": "Nyx Assassin",
            "Lycan": 0.212
        },
        {
            "name": "Ogre Magi",
            "Lycan": -0.277
        },
        {
            "name": "Omniknight",
            "Lycan": 4.217
        },
        {
            "name": "Oracle",
            "Lycan": -2.446
        },
        {
            "name": "Outworld Destroyer",
            "Lycan": -1.425
        },
        {
            "name": "Pangolier",
            "Lycan": 2.489
        },
        {
            "name": "Phantom Assassin",
            "Lycan": 3.452
        },
        {
            "name": "Phantom Lancer",
            "Lycan": 1.839
        },
        {
            "name": "Phoenix",
            "Lycan": 1.025
        },
        {
            "name": "Primal Beast",
            "Lycan": -0.754
        },
        {
            "name": "Puck",
            "Lycan": -0.888
        },
        {
            "name": "Pudge",
            "Lycan": -2.215
        },
        {
            "name": "Pugna",
            "Lycan": 0.794
        },
        {
            "name": "Queen of Pain",
            "Lycan": -0.095
        },
        {
            "name": "Razor",
            "Lycan": -1.972
        },
        {
            "name": "Riki",
            "Lycan": -1.216
        },
        {
            "name": "Ringmaster",
            "Lycan": -1.289
        },
        {
            "name": "Rubick",
            "Lycan": -0.056
        },
        {
            "name": "Sand King",
            "Lycan": 0.947
        },
        {
            "name": "Shadow Demon",
            "Lycan": -2.41
        },
        {
            "name": "Shadow Fiend",
            "Lycan": 0.158
        },
        {
            "name": "Shadow Shaman",
            "Lycan": 0.652
        },
        {
            "name": "Silencer",
            "Lycan": -4.73
        },
        {
            "name": "Skywrath Mage",
            "Lycan": -3.451
        },
        {
            "name": "Slardar",
            "Lycan": 2.926
        },
        {
            "name": "Slark",
            "Lycan": 1.191
        },
        {
            "name": "Snapfire",
            "Lycan": -0.536
        },
        {
            "name": "Sniper",
            "Lycan": -2.387
        },
        {
            "name": "Spectre",
            "Lycan": -3.641
        },
        {
            "name": "Spirit Breaker",
            "Lycan": 2.316
        },
        {
            "name": "Storm Spirit",
            "Lycan": -0.749
        },
        {
            "name": "Sven",
            "Lycan": 3.995
        },
        {
            "name": "Techies",
            "Lycan": 1.096
        },
        {
            "name": "Templar Assassin",
            "Lycan": 0.553
        },
        {
            "name": "Terrorblade",
            "Lycan": 3.223
        },
        {
            "name": "Tidehunter",
            "Lycan": 0.293
        },
        {
            "name": "Timbersaw",
            "Lycan": 0.245
        },
        {
            "name": "Tinker",
            "Lycan": 1.044
        },
        {
            "name": "Tiny",
            "Lycan": 1.619
        },
        {
            "name": "Treant Protector",
            "Lycan": 0.373
        },
        {
            "name": "Troll Warlord",
            "Lycan": 4.837
        },
        {
            "name": "Tusk",
            "Lycan": 3.498
        },
        {
            "name": "Underlord",
            "Lycan": 0.396
        },
        {
            "name": "Undying",
            "Lycan": -1.49
        },
        {
            "name": "Ursa",
            "Lycan": -0.181
        },
        {
            "name": "Vengeful Spirit",
            "Lycan": -0.511
        },
        {
            "name": "Venomancer",
            "Lycan": -2.22
        },
        {
            "name": "Viper",
            "Lycan": -2.986
        },
        {
            "name": "Visage",
            "Lycan": -1.684
        },
        {
            "name": "Void Spirit",
            "Lycan": -2.356
        },
        {
            "name": "Warlock",
            "Lycan": -1.749
        },
        {
            "name": "Weaver",
            "Lycan": 0.854
        },
        {
            "name": "Windranger",
            "Lycan": 0.884
        },
        {
            "name": "Winter Wyvern",
            "Lycan": 1.489
        },
        {
            "name": "Witch Doctor",
            "Lycan": -0.628
        },
        {
            "name": "Wraith King",
            "Lycan": -1.382
        },
        {
            "name": "Zeus",
            "Lycan": -2.349
        }
    ],
    "Magnus": [
        {
            "name": "Abaddon",
            "Magnus": -1.489
        },
        {
            "name": "Alchemist",
            "Magnus": 0.933
        },
        {
            "name": "Ancient Apparition",
            "Magnus": -0.476
        },
        {
            "name": "Anti-Mage",
            "Magnus": -2.006
        },
        {
            "name": "Arc Warden",
            "Magnus": -1.175
        },
        {
            "name": "Axe",
            "Magnus": -0.228
        },
        {
            "name": "Bane",
            "Magnus": -0.121
        },
        {
            "name": "Batrider",
            "Magnus": 0.244
        },
        {
            "name": "Beastmaster",
            "Magnus": -0.014
        },
        {
            "name": "Bloodseeker",
            "Magnus": 0.719
        },
        {
            "name": "Bounty Hunter",
            "Magnus": -1.007
        },
        {
            "name": "Brewmaster",
            "Magnus": 0.042
        },
        {
            "name": "Bristleback",
            "Magnus": -0.095
        },
        {
            "name": "Broodmother",
            "Magnus": -1.548
        },
        {
            "name": "Centaur Warrunner",
            "Magnus": -0.524
        },
        {
            "name": "Chaos Knight",
            "Magnus": -1.143
        },
        {
            "name": "Chen",
            "Magnus": -0.613
        },
        {
            "name": "Clinkz",
            "Magnus": 0.363
        },
        {
            "name": "Clockwerk",
            "Magnus": -0.259
        },
        {
            "name": "Crystal Maiden",
            "Magnus": -0.797
        },
        {
            "name": "Dark Seer",
            "Magnus": 0.116
        },
        {
            "name": "Dark Willow",
            "Magnus": 0.876
        },
        {
            "name": "Dawnbreaker",
            "Magnus": 0.082
        },
        {
            "name": "Dazzle",
            "Magnus": 0.099
        },
        {
            "name": "Death Prophet",
            "Magnus": -0.457
        },
        {
            "name": "Disruptor",
            "Magnus": 0.855
        },
        {
            "name": "Doom",
            "Magnus": 0.371
        },
        {
            "name": "Dragon Knight",
            "Magnus": 0.294
        },
        {
            "name": "Drow Ranger",
            "Magnus": -1.12
        },
        {
            "name": "Earth Spirit",
            "Magnus": -0.233
        },
        {
            "name": "Earthshaker",
            "Magnus": 1.017
        },
        {
            "name": "Elder Titan",
            "Magnus": 0.531
        },
        {
            "name": "Ember Spirit",
            "Magnus": 1.526
        },
        {
            "name": "Enchantress",
            "Magnus": 0.076
        },
        {
            "name": "Enigma",
            "Magnus": 2.318
        },
        {
            "name": "Faceless Void",
            "Magnus": 1.916
        },
        {
            "name": "Grimstroke",
            "Magnus": 0.85
        },
        {
            "name": "Gyrocopter",
            "Magnus": 0.731
        },
        {
            "name": "Hoodwink",
            "Magnus": 0.406
        },
        {
            "name": "Huskar",
            "Magnus": 1.908
        },
        {
            "name": "Invoker",
            "Magnus": 0.459
        },
        {
            "name": "Io",
            "Magnus": -1.572
        },
        {
            "name": "Jakiro",
            "Magnus": -0.032
        },
        {
            "name": "Juggernaut",
            "Magnus": 0.956
        },
        {
            "name": "Keeper of the Light",
            "Magnus": -0.393
        },
        {
            "name": "Kez",
            "Magnus": 0.482
        },
        {
            "name": "Kunkka",
            "Magnus": 0.836
        },
        {
            "name": "Legion Commander",
            "Magnus": -0.635
        },
        {
            "name": "Leshrac",
            "Magnus": 0.343
        },
        {
            "name": "Lich",
            "Magnus": 0.107
        },
        {
            "name": "Lifestealer",
            "Magnus": 0.179
        },
        {
            "name": "Lina",
            "Magnus": -0.139
        },
        {
            "name": "Lion",
            "Magnus": 0.412
        },
        {
            "name": "Lone Druid",
            "Magnus": 0.124
        },
        {
            "name": "Luna",
            "Magnus": 0.022
        },
        {
            "name": "Lycan",
            "Magnus": -1.117
        },
        {
            "name": "Marci",
            "Magnus": -1.878
        },
        {
            "name": "Mars",
            "Magnus": 0.496
        },
        {
            "name": "Medusa",
            "Magnus": 0.075
        },
        {
            "name": "Meepo",
            "Magnus": -0.559
        },
        {
            "name": "Mirana",
            "Magnus": 0.227
        },
        {
            "name": "Monkey King",
            "Magnus": 0.264
        },
        {
            "name": "Morphling",
            "Magnus": 0.225
        },
        {
            "name": "Muerta",
            "Magnus": 0.605
        },
        {
            "name": "Naga Siren",
            "Magnus": -1.608
        },
        {
            "name": "Nature's Prophet",
            "Magnus": 0.291
        },
        {
            "name": "Necrophos",
            "Magnus": 0.354
        },
        {
            "name": "Night Stalker",
            "Magnus": -0.273
        },
        {
            "name": "Nyx Assassin",
            "Magnus": -0.65
        },
        {
            "name": "Ogre Magi",
            "Magnus": -1.004
        },
        {
            "name": "Omniknight",
            "Magnus": -0.279
        },
        {
            "name": "Oracle",
            "Magnus": -0.12
        },
        {
            "name": "Outworld Destroyer",
            "Magnus": -0.527
        },
        {
            "name": "Pangolier",
            "Magnus": -0.025
        },
        {
            "name": "Phantom Assassin",
            "Magnus": -0.911
        },
        {
            "name": "Phantom Lancer",
            "Magnus": -2.019
        },
        {
            "name": "Phoenix",
            "Magnus": 0.695
        },
        {
            "name": "Primal Beast",
            "Magnus": 0.218
        },
        {
            "name": "Puck",
            "Magnus": 1.956
        },
        {
            "name": "Pudge",
            "Magnus": -2.256
        },
        {
            "name": "Pugna",
            "Magnus": -1.369
        },
        {
            "name": "Queen of Pain",
            "Magnus": 0.414
        },
        {
            "name": "Razor",
            "Magnus": -1.031
        },
        {
            "name": "Riki",
            "Magnus": 0.425
        },
        {
            "name": "Ringmaster",
            "Magnus": -0.228
        },
        {
            "name": "Rubick",
            "Magnus": 1.015
        },
        {
            "name": "Sand King",
            "Magnus": -1.108
        },
        {
            "name": "Shadow Demon",
            "Magnus": -0.446
        },
        {
            "name": "Shadow Fiend",
            "Magnus": 0.433
        },
        {
            "name": "Shadow Shaman",
            "Magnus": -0.207
        },
        {
            "name": "Silencer",
            "Magnus": 0.1
        },
        {
            "name": "Skywrath Mage",
            "Magnus": -0.91
        },
        {
            "name": "Slardar",
            "Magnus": -0.067
        },
        {
            "name": "Slark",
            "Magnus": -1.247
        },
        {
            "name": "Snapfire",
            "Magnus": -0.129
        },
        {
            "name": "Sniper",
            "Magnus": -0.266
        },
        {
            "name": "Spectre",
            "Magnus": -1.022
        },
        {
            "name": "Spirit Breaker",
            "Magnus": 0.033
        },
        {
            "name": "Storm Spirit",
            "Magnus": 0.421
        },
        {
            "name": "Sven",
            "Magnus": 0.882
        },
        {
            "name": "Techies",
            "Magnus": -0.713
        },
        {
            "name": "Templar Assassin",
            "Magnus": 0.803
        },
        {
            "name": "Terrorblade",
            "Magnus": 0.02
        },
        {
            "name": "Tidehunter",
            "Magnus": 0.711
        },
        {
            "name": "Timbersaw",
            "Magnus": -0.948
        },
        {
            "name": "Tinker",
            "Magnus": -0.198
        },
        {
            "name": "Tiny",
            "Magnus": -0.03
        },
        {
            "name": "Treant Protector",
            "Magnus": -0.266
        },
        {
            "name": "Troll Warlord",
            "Magnus": 1.904
        },
        {
            "name": "Tusk",
            "Magnus": -0.223
        },
        {
            "name": "Underlord",
            "Magnus": -0.764
        },
        {
            "name": "Undying",
            "Magnus": -1.217
        },
        {
            "name": "Ursa",
            "Magnus": 1.202
        },
        {
            "name": "Vengeful Spirit",
            "Magnus": 0.143
        },
        {
            "name": "Venomancer",
            "Magnus": -1.156
        },
        {
            "name": "Viper",
            "Magnus": -0.041
        },
        {
            "name": "Visage",
            "Magnus": -0.705
        },
        {
            "name": "Void Spirit",
            "Magnus": 0.453
        },
        {
            "name": "Warlock",
            "Magnus": -0.762
        },
        {
            "name": "Weaver",
            "Magnus": 1.53
        },
        {
            "name": "Windranger",
            "Magnus": 0.856
        },
        {
            "name": "Winter Wyvern",
            "Magnus": 1.454
        },
        {
            "name": "Witch Doctor",
            "Magnus": -0.107
        },
        {
            "name": "Wraith King",
            "Magnus": 0.738
        },
        {
            "name": "Zeus",
            "Magnus": -0.51
        }
    ],
    "Marci": [
        {
            "name": "Abaddon",
            "Marci": -2.641
        },
        {
            "name": "Alchemist",
            "Marci": -1.149
        },
        {
            "name": "Ancient Apparition",
            "Marci": 2.12
        },
        {
            "name": "Anti-Mage",
            "Marci": -3.029
        },
        {
            "name": "Arc Warden",
            "Marci": -3.076
        },
        {
            "name": "Axe",
            "Marci": 1.749
        },
        {
            "name": "Bane",
            "Marci": -0.883
        },
        {
            "name": "Batrider",
            "Marci": 2.212
        },
        {
            "name": "Beastmaster",
            "Marci": -0.357
        },
        {
            "name": "Bloodseeker",
            "Marci": 0.592
        },
        {
            "name": "Bounty Hunter",
            "Marci": -0.809
        },
        {
            "name": "Brewmaster",
            "Marci": -0.374
        },
        {
            "name": "Bristleback",
            "Marci": 0.382
        },
        {
            "name": "Broodmother",
            "Marci": -1.764
        },
        {
            "name": "Centaur Warrunner",
            "Marci": 0.956
        },
        {
            "name": "Chaos Knight",
            "Marci": 0.834
        },
        {
            "name": "Chen",
            "Marci": -3.306
        },
        {
            "name": "Clinkz",
            "Marci": -0.248
        },
        {
            "name": "Clockwerk",
            "Marci": -0.337
        },
        {
            "name": "Crystal Maiden",
            "Marci": 0.734
        },
        {
            "name": "Dark Seer",
            "Marci": 1.44
        },
        {
            "name": "Dark Willow",
            "Marci": 2.031
        },
        {
            "name": "Dawnbreaker",
            "Marci": 0.953
        },
        {
            "name": "Dazzle",
            "Marci": -0.15
        },
        {
            "name": "Death Prophet",
            "Marci": 0.572
        },
        {
            "name": "Disruptor",
            "Marci": 0.684
        },
        {
            "name": "Doom",
            "Marci": -1.875
        },
        {
            "name": "Dragon Knight",
            "Marci": 1.306
        },
        {
            "name": "Drow Ranger",
            "Marci": -0.196
        },
        {
            "name": "Earth Spirit",
            "Marci": -1.54
        },
        {
            "name": "Earthshaker",
            "Marci": 1.842
        },
        {
            "name": "Elder Titan",
            "Marci": 1.341
        },
        {
            "name": "Ember Spirit",
            "Marci": 0.192
        },
        {
            "name": "Enchantress",
            "Marci": -1.198
        },
        {
            "name": "Enigma",
            "Marci": 1.855
        },
        {
            "name": "Faceless Void",
            "Marci": 0.811
        },
        {
            "name": "Grimstroke",
            "Marci": 0.427
        },
        {
            "name": "Gyrocopter",
            "Marci": 0.613
        },
        {
            "name": "Hoodwink",
            "Marci": 0.229
        },
        {
            "name": "Huskar",
            "Marci": -0.64
        },
        {
            "name": "Invoker",
            "Marci": -0.634
        },
        {
            "name": "Io",
            "Marci": -1.799
        },
        {
            "name": "Jakiro",
            "Marci": 1.127
        },
        {
            "name": "Juggernaut",
            "Marci": 0.469
        },
        {
            "name": "Keeper of the Light",
            "Marci": -0.021
        },
        {
            "name": "Kez",
            "Marci": 0.335
        },
        {
            "name": "Kunkka",
            "Marci": 1.88
        },
        {
            "name": "Legion Commander",
            "Marci": -0.715
        },
        {
            "name": "Leshrac",
            "Marci": 0.398
        },
        {
            "name": "Lich",
            "Marci": 1.438
        },
        {
            "name": "Lifestealer",
            "Marci": -1.531
        },
        {
            "name": "Lina",
            "Marci": -0.069
        },
        {
            "name": "Lion",
            "Marci": 0.297
        },
        {
            "name": "Lone Druid",
            "Marci": -1.733
        },
        {
            "name": "Luna",
            "Marci": 1.087
        },
        {
            "name": "Lycan",
            "Marci": -1.16
        },
        {
            "name": "Magnus",
            "Marci": 1.964
        },
        {
            "name": "Mars",
            "Marci": 1.175
        },
        {
            "name": "Medusa",
            "Marci": 1.117
        },
        {
            "name": "Meepo",
            "Marci": -2.612
        },
        {
            "name": "Mirana",
            "Marci": 1.544
        },
        {
            "name": "Monkey King",
            "Marci": 1.619
        },
        {
            "name": "Morphling",
            "Marci": -1.308
        },
        {
            "name": "Muerta",
            "Marci": 2.639
        },
        {
            "name": "Naga Siren",
            "Marci": 1.605
        },
        {
            "name": "Nature's Prophet",
            "Marci": -0.244
        },
        {
            "name": "Necrophos",
            "Marci": -1.2
        },
        {
            "name": "Night Stalker",
            "Marci": -3.104
        },
        {
            "name": "Nyx Assassin",
            "Marci": -0.58
        },
        {
            "name": "Ogre Magi",
            "Marci": -0.393
        },
        {
            "name": "Omniknight",
            "Marci": 2.189
        },
        {
            "name": "Oracle",
            "Marci": -2.9
        },
        {
            "name": "Outworld Destroyer",
            "Marci": -1.059
        },
        {
            "name": "Pangolier",
            "Marci": 2.451
        },
        {
            "name": "Phantom Assassin",
            "Marci": 0.544
        },
        {
            "name": "Phantom Lancer",
            "Marci": 0.618
        },
        {
            "name": "Phoenix",
            "Marci": -1.761
        },
        {
            "name": "Primal Beast",
            "Marci": 1.279
        },
        {
            "name": "Puck",
            "Marci": 0.205
        },
        {
            "name": "Pudge",
            "Marci": -3.188
        },
        {
            "name": "Pugna",
            "Marci": -0.81
        },
        {
            "name": "Queen of Pain",
            "Marci": -0.047
        },
        {
            "name": "Razor",
            "Marci": -0.27
        },
        {
            "name": "Riki",
            "Marci": 0.202
        },
        {
            "name": "Ringmaster",
            "Marci": 0.442
        },
        {
            "name": "Rubick",
            "Marci": -0.627
        },
        {
            "name": "Sand King",
            "Marci": 1.281
        },
        {
            "name": "Shadow Demon",
            "Marci": -2.169
        },
        {
            "name": "Shadow Fiend",
            "Marci": 0.965
        },
        {
            "name": "Shadow Shaman",
            "Marci": 0.005
        },
        {
            "name": "Silencer",
            "Marci": -0.174
        },
        {
            "name": "Skywrath Mage",
            "Marci": -1.297
        },
        {
            "name": "Slardar",
            "Marci": 1.224
        },
        {
            "name": "Slark",
            "Marci": 0.286
        },
        {
            "name": "Snapfire",
            "Marci": 1.09
        },
        {
            "name": "Sniper",
            "Marci": 0.768
        },
        {
            "name": "Spectre",
            "Marci": -1.947
        },
        {
            "name": "Spirit Breaker",
            "Marci": 0.463
        },
        {
            "name": "Storm Spirit",
            "Marci": -0.684
        },
        {
            "name": "Sven",
            "Marci": 2.66
        },
        {
            "name": "Techies",
            "Marci": 2.28
        },
        {
            "name": "Templar Assassin",
            "Marci": 0.941
        },
        {
            "name": "Terrorblade",
            "Marci": 1.275
        },
        {
            "name": "Tidehunter",
            "Marci": 0.733
        },
        {
            "name": "Timbersaw",
            "Marci": -1.377
        },
        {
            "name": "Tinker",
            "Marci": -1.446
        },
        {
            "name": "Tiny",
            "Marci": 0.769
        },
        {
            "name": "Treant Protector",
            "Marci": 0.696
        },
        {
            "name": "Troll Warlord",
            "Marci": 4.003
        },
        {
            "name": "Tusk",
            "Marci": 0.182
        },
        {
            "name": "Underlord",
            "Marci": 1.227
        },
        {
            "name": "Undying",
            "Marci": -0.675
        },
        {
            "name": "Ursa",
            "Marci": 0.678
        },
        {
            "name": "Vengeful Spirit",
            "Marci": -0.882
        },
        {
            "name": "Venomancer",
            "Marci": -0.069
        },
        {
            "name": "Viper",
            "Marci": -0.926
        },
        {
            "name": "Visage",
            "Marci": -2.451
        },
        {
            "name": "Void Spirit",
            "Marci": -0.447
        },
        {
            "name": "Warlock",
            "Marci": -0.901
        },
        {
            "name": "Weaver",
            "Marci": 1.806
        },
        {
            "name": "Windranger",
            "Marci": 2.354
        },
        {
            "name": "Winter Wyvern",
            "Marci": 2.321
        },
        {
            "name": "Witch Doctor",
            "Marci": 0.357
        },
        {
            "name": "Wraith King",
            "Marci": -0.542
        },
        {
            "name": "Zeus",
            "Marci": -0.274
        }
    ],
    "Mirana": [
        {
            "name": "Abaddon",
            "Mirana": 1.023
        },
        {
            "name": "Alchemist",
            "Mirana": -0.068
        },
        {
            "name": "Ancient Apparition",
            "Mirana": -1.077
        },
        {
            "name": "Anti-Mage",
            "Mirana": -0.772
        },
        {
            "name": "Arc Warden",
            "Mirana": -1.526
        },
        {
            "name": "Axe",
            "Mirana": 1.855
        },
        {
            "name": "Bane",
            "Mirana": 0.611
        },
        {
            "name": "Batrider",
            "Mirana": -1.933
        },
        {
            "name": "Beastmaster",
            "Mirana": 0.026
        },
        {
            "name": "Bloodseeker",
            "Mirana": -0.051
        },
        {
            "name": "Bounty Hunter",
            "Mirana": 1.403
        },
        {
            "name": "Brewmaster",
            "Mirana": 1.772
        },
        {
            "name": "Bristleback",
            "Mirana": -0.168
        },
        {
            "name": "Broodmother",
            "Mirana": 0.249
        },
        {
            "name": "Centaur Warrunner",
            "Mirana": 1.419
        },
        {
            "name": "Chaos Knight",
            "Mirana": 0.256
        },
        {
            "name": "Chen",
            "Mirana": 2.178
        },
        {
            "name": "Clinkz",
            "Mirana": -1.168
        },
        {
            "name": "Clockwerk",
            "Mirana": -0.265
        },
        {
            "name": "Crystal Maiden",
            "Mirana": 0.55
        },
        {
            "name": "Dark Seer",
            "Mirana": 0.452
        },
        {
            "name": "Dark Willow",
            "Mirana": -0.011
        },
        {
            "name": "Dawnbreaker",
            "Mirana": 0.113
        },
        {
            "name": "Dazzle",
            "Mirana": -0.434
        },
        {
            "name": "Death Prophet",
            "Mirana": -2.476
        },
        {
            "name": "Disruptor",
            "Mirana": -0.024
        },
        {
            "name": "Doom",
            "Mirana": -0.775
        },
        {
            "name": "Dragon Knight",
            "Mirana": 0.288
        },
        {
            "name": "Drow Ranger",
            "Mirana": -0.128
        },
        {
            "name": "Earth Spirit",
            "Mirana": 0.211
        },
        {
            "name": "Earthshaker",
            "Mirana": -0.362
        },
        {
            "name": "Elder Titan",
            "Mirana": -0.688
        },
        {
            "name": "Ember Spirit",
            "Mirana": 0.333
        },
        {
            "name": "Enchantress",
            "Mirana": 0.506
        },
        {
            "name": "Enigma",
            "Mirana": 0.36
        },
        {
            "name": "Faceless Void",
            "Mirana": 0.084
        },
        {
            "name": "Grimstroke",
            "Mirana": -0.284
        },
        {
            "name": "Gyrocopter",
            "Mirana": 0.414
        },
        {
            "name": "Hoodwink",
            "Mirana": -0.436
        },
        {
            "name": "Huskar",
            "Mirana": 0.468
        },
        {
            "name": "Invoker",
            "Mirana": -1.097
        },
        {
            "name": "Io",
            "Mirana": -1.662
        },
        {
            "name": "Jakiro",
            "Mirana": -0.02
        },
        {
            "name": "Juggernaut",
            "Mirana": -0.285
        },
        {
            "name": "Keeper of the Light",
            "Mirana": 0.063
        },
        {
            "name": "Kez",
            "Mirana": -0.695
        },
        {
            "name": "Kunkka",
            "Mirana": 0.715
        },
        {
            "name": "Legion Commander",
            "Mirana": 0.634
        },
        {
            "name": "Leshrac",
            "Mirana": -0.115
        },
        {
            "name": "Lich",
            "Mirana": -0.321
        },
        {
            "name": "Lifestealer",
            "Mirana": -0.55
        },
        {
            "name": "Lina",
            "Mirana": -0.594
        },
        {
            "name": "Lion",
            "Mirana": 0.242
        },
        {
            "name": "Lone Druid",
            "Mirana": 0.642
        },
        {
            "name": "Luna",
            "Mirana": 1.089
        },
        {
            "name": "Lycan",
            "Mirana": -2.108
        },
        {
            "name": "Magnus",
            "Mirana": 0.006
        },
        {
            "name": "Marci",
            "Mirana": -1.535
        },
        {
            "name": "Mars",
            "Mirana": 1.382
        },
        {
            "name": "Medusa",
            "Mirana": 0.027
        },
        {
            "name": "Meepo",
            "Mirana": 1.165
        },
        {
            "name": "Monkey King",
            "Mirana": -0.957
        },
        {
            "name": "Morphling",
            "Mirana": 0.036
        },
        {
            "name": "Muerta",
            "Mirana": -0.097
        },
        {
            "name": "Naga Siren",
            "Mirana": -1.578
        },
        {
            "name": "Nature's Prophet",
            "Mirana": -0.158
        },
        {
            "name": "Necrophos",
            "Mirana": -0.081
        },
        {
            "name": "Night Stalker",
            "Mirana": 0.058
        },
        {
            "name": "Nyx Assassin",
            "Mirana": 0.436
        },
        {
            "name": "Ogre Magi",
            "Mirana": 0.092
        },
        {
            "name": "Omniknight",
            "Mirana": 1.168
        },
        {
            "name": "Oracle",
            "Mirana": -0.069
        },
        {
            "name": "Outworld Destroyer",
            "Mirana": 1.253
        },
        {
            "name": "Pangolier",
            "Mirana": -1.041
        },
        {
            "name": "Phantom Assassin",
            "Mirana": -0.187
        },
        {
            "name": "Phantom Lancer",
            "Mirana": -1.157
        },
        {
            "name": "Phoenix",
            "Mirana": -1.151
        },
        {
            "name": "Primal Beast",
            "Mirana": -0.77
        },
        {
            "name": "Puck",
            "Mirana": 1.47
        },
        {
            "name": "Pudge",
            "Mirana": -0.014
        },
        {
            "name": "Pugna",
            "Mirana": 0.092
        },
        {
            "name": "Queen of Pain",
            "Mirana": 0.78
        },
        {
            "name": "Razor",
            "Mirana": -1.628
        },
        {
            "name": "Riki",
            "Mirana": 0.028
        },
        {
            "name": "Ringmaster",
            "Mirana": 0.928
        },
        {
            "name": "Rubick",
            "Mirana": 0.349
        },
        {
            "name": "Sand King",
            "Mirana": -0.396
        },
        {
            "name": "Shadow Demon",
            "Mirana": -0.311
        },
        {
            "name": "Shadow Fiend",
            "Mirana": -0.78
        },
        {
            "name": "Shadow Shaman",
            "Mirana": -0.139
        },
        {
            "name": "Silencer",
            "Mirana": -0.603
        },
        {
            "name": "Skywrath Mage",
            "Mirana": -0.844
        },
        {
            "name": "Slardar",
            "Mirana": -0.511
        },
        {
            "name": "Slark",
            "Mirana": -0.124
        },
        {
            "name": "Snapfire",
            "Mirana": -0.671
        },
        {
            "name": "Sniper",
            "Mirana": -0.933
        },
        {
            "name": "Spectre",
            "Mirana": 1.446
        },
        {
            "name": "Spirit Breaker",
            "Mirana": 0.649
        },
        {
            "name": "Storm Spirit",
            "Mirana": 0.383
        },
        {
            "name": "Sven",
            "Mirana": -0.948
        },
        {
            "name": "Techies",
            "Mirana": 0.254
        },
        {
            "name": "Templar Assassin",
            "Mirana": -0.871
        },
        {
            "name": "Terrorblade",
            "Mirana": 0.989
        },
        {
            "name": "Tidehunter",
            "Mirana": -0.441
        },
        {
            "name": "Timbersaw",
            "Mirana": -0.616
        },
        {
            "name": "Tinker",
            "Mirana": -1.949
        },
        {
            "name": "Tiny",
            "Mirana": 0.673
        },
        {
            "name": "Treant Protector",
            "Mirana": 0.432
        },
        {
            "name": "Troll Warlord",
            "Mirana": -1.443
        },
        {
            "name": "Tusk",
            "Mirana": -0.153
        },
        {
            "name": "Underlord",
            "Mirana": -0.116
        },
        {
            "name": "Undying",
            "Mirana": 0.075
        },
        {
            "name": "Ursa",
            "Mirana": -1.916
        },
        {
            "name": "Vengeful Spirit",
            "Mirana": 0.316
        },
        {
            "name": "Venomancer",
            "Mirana": -1.178
        },
        {
            "name": "Viper",
            "Mirana": -1.163
        },
        {
            "name": "Visage",
            "Mirana": -0.968
        },
        {
            "name": "Void Spirit",
            "Mirana": -0.191
        },
        {
            "name": "Warlock",
            "Mirana": -0.463
        },
        {
            "name": "Weaver",
            "Mirana": -0.96
        },
        {
            "name": "Windranger",
            "Mirana": -0.567
        },
        {
            "name": "Winter Wyvern",
            "Mirana": -1.359
        },
        {
            "name": "Witch Doctor",
            "Mirana": -0.746
        },
        {
            "name": "Wraith King",
            "Mirana": 1.451
        },
        {
            "name": "Zeus",
            "Mirana": 1.049
        }
    ],
    "Nyx Assassin": [
        {
            "name": "Abaddon",
            "Nyx Assassin": -0.844
        },
        {
            "name": "Alchemist",
            "Nyx Assassin": -0.416
        },
        {
            "name": "Ancient Apparition",
            "Nyx Assassin": -0.747
        },
        {
            "name": "Anti-Mage",
            "Nyx Assassin": -0.873
        },
        {
            "name": "Arc Warden",
            "Nyx Assassin": 1.168
        },
        {
            "name": "Axe",
            "Nyx Assassin": 0.646
        },
        {
            "name": "Bane",
            "Nyx Assassin": 0.178
        },
        {
            "name": "Batrider",
            "Nyx Assassin": 1.341
        },
        {
            "name": "Beastmaster",
            "Nyx Assassin": 2.04
        },
        {
            "name": "Bloodseeker",
            "Nyx Assassin": 0.122
        },
        {
            "name": "Bounty Hunter",
            "Nyx Assassin": -0.052
        },
        {
            "name": "Brewmaster",
            "Nyx Assassin": 1.343
        },
        {
            "name": "Bristleback",
            "Nyx Assassin": -0.591
        },
        {
            "name": "Broodmother",
            "Nyx Assassin": 1.061
        },
        {
            "name": "Centaur Warrunner",
            "Nyx Assassin": 0.223
        },
        {
            "name": "Chaos Knight",
            "Nyx Assassin": 2.153
        },
        {
            "name": "Chen",
            "Nyx Assassin": 1.183
        },
        {
            "name": "Clinkz",
            "Nyx Assassin": -0.063
        },
        {
            "name": "Clockwerk",
            "Nyx Assassin": 0.557
        },
        {
            "name": "Crystal Maiden",
            "Nyx Assassin": -0.394
        },
        {
            "name": "Dark Seer",
            "Nyx Assassin": 0.004
        },
        {
            "name": "Dark Willow",
            "Nyx Assassin": 0.091
        },
        {
            "name": "Dawnbreaker",
            "Nyx Assassin": -1.969
        },
        {
            "name": "Dazzle",
            "Nyx Assassin": 0.172
        },
        {
            "name": "Death Prophet",
            "Nyx Assassin": 1.203
        },
        {
            "name": "Disruptor",
            "Nyx Assassin": 1.09
        },
        {
            "name": "Doom",
            "Nyx Assassin": 0.664
        },
        {
            "name": "Dragon Knight",
            "Nyx Assassin": 1.225
        },
        {
            "name": "Drow Ranger",
            "Nyx Assassin": -0.529
        },
        {
            "name": "Earth Spirit",
            "Nyx Assassin": 0.988
        },
        {
            "name": "Earthshaker",
            "Nyx Assassin": 0.67
        },
        {
            "name": "Elder Titan",
            "Nyx Assassin": 0.621
        },
        {
            "name": "Ember Spirit",
            "Nyx Assassin": 2.011
        },
        {
            "name": "Enchantress",
            "Nyx Assassin": -0.677
        },
        {
            "name": "Enigma",
            "Nyx Assassin": 0.155
        },
        {
            "name": "Faceless Void",
            "Nyx Assassin": 0.46
        },
        {
            "name": "Grimstroke",
            "Nyx Assassin": 1.352
        },
        {
            "name": "Gyrocopter",
            "Nyx Assassin": -0.357
        },
        {
            "name": "Hoodwink",
            "Nyx Assassin": -0.142
        },
        {
            "name": "Huskar",
            "Nyx Assassin": 2.231
        },
        {
            "name": "Invoker",
            "Nyx Assassin": -0.043
        },
        {
            "name": "Io",
            "Nyx Assassin": 1.164
        },
        {
            "name": "Jakiro",
            "Nyx Assassin": 0.093
        },
        {
            "name": "Juggernaut",
            "Nyx Assassin": 1.251
        },
        {
            "name": "Keeper of the Light",
            "Nyx Assassin": -1.009
        },
        {
            "name": "Kez",
            "Nyx Assassin": 1.025
        },
        {
            "name": "Kunkka",
            "Nyx Assassin": 1.143
        },
        {
            "name": "Legion Commander",
            "Nyx Assassin": 0.196
        },
        {
            "name": "Leshrac",
            "Nyx Assassin": -0.129
        },
        {
            "name": "Lich",
            "Nyx Assassin": -0.468
        },
        {
            "name": "Lifestealer",
            "Nyx Assassin": 2.314
        },
        {
            "name": "Lina",
            "Nyx Assassin": -1.087
        },
        {
            "name": "Lion",
            "Nyx Assassin": 1.582
        },
        {
            "name": "Lone Druid",
            "Nyx Assassin": 0.491
        },
        {
            "name": "Luna",
            "Nyx Assassin": -1.298
        },
        {
            "name": "Lycan",
            "Nyx Assassin": -0.152
        },
        {
            "name": "Magnus",
            "Nyx Assassin": 0.643
        },
        {
            "name": "Marci",
            "Nyx Assassin": 0.599
        },
        {
            "name": "Mars",
            "Nyx Assassin": -0.397
        },
        {
            "name": "Medusa",
            "Nyx Assassin": -1.096
        },
        {
            "name": "Meepo",
            "Nyx Assassin": 1.746
        },
        {
            "name": "Mirana",
            "Nyx Assassin": -0.101
        },
        {
            "name": "Monkey King",
            "Nyx Assassin": 0.538
        },
        {
            "name": "Morphling",
            "Nyx Assassin": -2.961
        },
        {
            "name": "Muerta",
            "Nyx Assassin": -0.613
        },
        {
            "name": "Naga Siren",
            "Nyx Assassin": 0.934
        },
        {
            "name": "Nature's Prophet",
            "Nyx Assassin": -0.717
        },
        {
            "name": "Necrophos",
            "Nyx Assassin": -0.521
        },
        {
            "name": "Night Stalker",
            "Nyx Assassin": 1.439
        },
        {
            "name": "Ogre Magi",
            "Nyx Assassin": 0.582
        },
        {
            "name": "Omniknight",
            "Nyx Assassin": 0.243
        },
        {
            "name": "Oracle",
            "Nyx Assassin": -0.36
        },
        {
            "name": "Outworld Destroyer",
            "Nyx Assassin": -2.665
        },
        {
            "name": "Pangolier",
            "Nyx Assassin": 0.298
        },
        {
            "name": "Phantom Assassin",
            "Nyx Assassin": -2.156
        },
        {
            "name": "Phantom Lancer",
            "Nyx Assassin": -1.223
        },
        {
            "name": "Phoenix",
            "Nyx Assassin": -0.69
        },
        {
            "name": "Primal Beast",
            "Nyx Assassin": -0.159
        },
        {
            "name": "Puck",
            "Nyx Assassin": -1.733
        },
        {
            "name": "Pudge",
            "Nyx Assassin": 0.022
        },
        {
            "name": "Pugna",
            "Nyx Assassin": -1.319
        },
        {
            "name": "Queen of Pain",
            "Nyx Assassin": -0.782
        },
        {
            "name": "Razor",
            "Nyx Assassin": -0.455
        },
        {
            "name": "Riki",
            "Nyx Assassin": -0.683
        },
        {
            "name": "Ringmaster",
            "Nyx Assassin": -0.363
        },
        {
            "name": "Rubick",
            "Nyx Assassin": 0.345
        },
        {
            "name": "Sand King",
            "Nyx Assassin": -1.807
        },
        {
            "name": "Shadow Demon",
            "Nyx Assassin": -0.712
        },
        {
            "name": "Shadow Fiend",
            "Nyx Assassin": -1.262
        },
        {
            "name": "Shadow Shaman",
            "Nyx Assassin": 1.112
        },
        {
            "name": "Silencer",
            "Nyx Assassin": -0.233
        },
        {
            "name": "Skywrath Mage",
            "Nyx Assassin": 0.855
        },
        {
            "name": "Slardar",
            "Nyx Assassin": 2.01
        },
        {
            "name": "Slark",
            "Nyx Assassin": 0.16
        },
        {
            "name": "Snapfire",
            "Nyx Assassin": -0.05
        },
        {
            "name": "Sniper",
            "Nyx Assassin": -2.55
        },
        {
            "name": "Spectre",
            "Nyx Assassin": 0.849
        },
        {
            "name": "Spirit Breaker",
            "Nyx Assassin": -0.023
        },
        {
            "name": "Storm Spirit",
            "Nyx Assassin": -0.889
        },
        {
            "name": "Sven",
            "Nyx Assassin": 1.219
        },
        {
            "name": "Techies",
            "Nyx Assassin": 0.59
        },
        {
            "name": "Templar Assassin",
            "Nyx Assassin": 2.233
        },
        {
            "name": "Terrorblade",
            "Nyx Assassin": 1.476
        },
        {
            "name": "Tidehunter",
            "Nyx Assassin": 0.044
        },
        {
            "name": "Timbersaw",
            "Nyx Assassin": -0.71
        },
        {
            "name": "Tinker",
            "Nyx Assassin": -1.277
        },
        {
            "name": "Tiny",
            "Nyx Assassin": 1.069
        },
        {
            "name": "Treant Protector",
            "Nyx Assassin": 0.622
        },
        {
            "name": "Troll Warlord",
            "Nyx Assassin": 0.836
        },
        {
            "name": "Tusk",
            "Nyx Assassin": 1.13
        },
        {
            "name": "Underlord",
            "Nyx Assassin": -0.156
        },
        {
            "name": "Undying",
            "Nyx Assassin": 0.001
        },
        {
            "name": "Ursa",
            "Nyx Assassin": 1.888
        },
        {
            "name": "Vengeful Spirit",
            "Nyx Assassin": 0.579
        },
        {
            "name": "Venomancer",
            "Nyx Assassin": 0.077
        },
        {
            "name": "Viper",
            "Nyx Assassin": 0.659
        },
        {
            "name": "Visage",
            "Nyx Assassin": 1.352
        },
        {
            "name": "Void Spirit",
            "Nyx Assassin": 0.122
        },
        {
            "name": "Warlock",
            "Nyx Assassin": -1.711
        },
        {
            "name": "Weaver",
            "Nyx Assassin": -1.97
        },
        {
            "name": "Windranger",
            "Nyx Assassin": -0.508
        },
        {
            "name": "Winter Wyvern",
            "Nyx Assassin": -0.136
        },
        {
            "name": "Witch Doctor",
            "Nyx Assassin": -0.395
        },
        {
            "name": "Wraith King",
            "Nyx Assassin": 0.882
        },
        {
            "name": "Zeus",
            "Nyx Assassin": 0.55
        }
    ],
    "Pangolier": [
        {
            "name": "Abaddon",
            "Pangolier": -0.662
        },
        {
            "name": "Alchemist",
            "Pangolier": 0.218
        },
        {
            "name": "Ancient Apparition",
            "Pangolier": -0.884
        },
        {
            "name": "Anti-Mage",
            "Pangolier": -1.02
        },
        {
            "name": "Arc Warden",
            "Pangolier": -3.628
        },
        {
            "name": "Axe",
            "Pangolier": 1.132
        },
        {
            "name": "Bane",
            "Pangolier": 0.818
        },
        {
            "name": "Batrider",
            "Pangolier": -0.418
        },
        {
            "name": "Beastmaster",
            "Pangolier": -2.588
        },
        {
            "name": "Bloodseeker",
            "Pangolier": 4.566
        },
        {
            "name": "Bounty Hunter",
            "Pangolier": 1.43
        },
        {
            "name": "Brewmaster",
            "Pangolier": -2.186
        },
        {
            "name": "Bristleback",
            "Pangolier": 0.457
        },
        {
            "name": "Broodmother",
            "Pangolier": -2.273
        },
        {
            "name": "Centaur Warrunner",
            "Pangolier": -1.295
        },
        {
            "name": "Chaos Knight",
            "Pangolier": -1.56
        },
        {
            "name": "Chen",
            "Pangolier": 2.324
        },
        {
            "name": "Clinkz",
            "Pangolier": 1.406
        },
        {
            "name": "Clockwerk",
            "Pangolier": 0.673
        },
        {
            "name": "Crystal Maiden",
            "Pangolier": -0.526
        },
        {
            "name": "Dark Seer",
            "Pangolier": 0.766
        },
        {
            "name": "Dark Willow",
            "Pangolier": 0.273
        },
        {
            "name": "Dawnbreaker",
            "Pangolier": -0.307
        },
        {
            "name": "Dazzle",
            "Pangolier": -1.852
        },
        {
            "name": "Death Prophet",
            "Pangolier": -0.603
        },
        {
            "name": "Disruptor",
            "Pangolier": -1.268
        },
        {
            "name": "Doom",
            "Pangolier": 1.281
        },
        {
            "name": "Dragon Knight",
            "Pangolier": 1.567
        },
        {
            "name": "Drow Ranger",
            "Pangolier": 2.138
        },
        {
            "name": "Earth Spirit",
            "Pangolier": -0.334
        },
        {
            "name": "Earthshaker",
            "Pangolier": 1.108
        },
        {
            "name": "Elder Titan",
            "Pangolier": 0.898
        },
        {
            "name": "Ember Spirit",
            "Pangolier": 1.696
        },
        {
            "name": "Enchantress",
            "Pangolier": 0.074
        },
        {
            "name": "Enigma",
            "Pangolier": 0.337
        },
        {
            "name": "Faceless Void",
            "Pangolier": 0.318
        },
        {
            "name": "Grimstroke",
            "Pangolier": 1.851
        },
        {
            "name": "Gyrocopter",
            "Pangolier": 1.77
        },
        {
            "name": "Hoodwink",
            "Pangolier": -0.116
        },
        {
            "name": "Huskar",
            "Pangolier": 4.441
        },
        {
            "name": "Invoker",
            "Pangolier": -0.981
        },
        {
            "name": "Io",
            "Pangolier": 0.097
        },
        {
            "name": "Jakiro",
            "Pangolier": -1.151
        },
        {
            "name": "Juggernaut",
            "Pangolier": 0.004
        },
        {
            "name": "Keeper of the Light",
            "Pangolier": 0.441
        },
        {
            "name": "Kez",
            "Pangolier": 0.723
        },
        {
            "name": "Kunkka",
            "Pangolier": -0.631
        },
        {
            "name": "Legion Commander",
            "Pangolier": -0.185
        },
        {
            "name": "Leshrac",
            "Pangolier": 1.24
        },
        {
            "name": "Lich",
            "Pangolier": -0.498
        },
        {
            "name": "Lifestealer",
            "Pangolier": -1.294
        },
        {
            "name": "Lina",
            "Pangolier": -0.343
        },
        {
            "name": "Lion",
            "Pangolier": 0.102
        },
        {
            "name": "Lone Druid",
            "Pangolier": -2.114
        },
        {
            "name": "Luna",
            "Pangolier": 1.403
        },
        {
            "name": "Lycan",
            "Pangolier": -2.528
        },
        {
            "name": "Magnus",
            "Pangolier": 0.341
        },
        {
            "name": "Marci",
            "Pangolier": -2.383
        },
        {
            "name": "Mars",
            "Pangolier": -1.0
        },
        {
            "name": "Medusa",
            "Pangolier": -3.082
        },
        {
            "name": "Meepo",
            "Pangolier": -0.647
        },
        {
            "name": "Mirana",
            "Pangolier": 0.975
        },
        {
            "name": "Monkey King",
            "Pangolier": 4.273
        },
        {
            "name": "Morphling",
            "Pangolier": 3.035
        },
        {
            "name": "Muerta",
            "Pangolier": 0.453
        },
        {
            "name": "Naga Siren",
            "Pangolier": -5.945
        },
        {
            "name": "Nature's Prophet",
            "Pangolier": 1.955
        },
        {
            "name": "Necrophos",
            "Pangolier": -0.369
        },
        {
            "name": "Night Stalker",
            "Pangolier": 1.244
        },
        {
            "name": "Nyx Assassin",
            "Pangolier": 0.095
        },
        {
            "name": "Ogre Magi",
            "Pangolier": 1.5
        },
        {
            "name": "Omniknight",
            "Pangolier": 0.783
        },
        {
            "name": "Oracle",
            "Pangolier": -2.196
        },
        {
            "name": "Outworld Destroyer",
            "Pangolier": -1.234
        },
        {
            "name": "Phantom Assassin",
            "Pangolier": -1.214
        },
        {
            "name": "Phantom Lancer",
            "Pangolier": -1.235
        },
        {
            "name": "Phoenix",
            "Pangolier": -0.158
        },
        {
            "name": "Primal Beast",
            "Pangolier": 0.738
        },
        {
            "name": "Puck",
            "Pangolier": 2.33
        },
        {
            "name": "Pudge",
            "Pangolier": -1.958
        },
        {
            "name": "Pugna",
            "Pangolier": 0.763
        },
        {
            "name": "Queen of Pain",
            "Pangolier": 0.741
        },
        {
            "name": "Razor",
            "Pangolier": 0.612
        },
        {
            "name": "Riki",
            "Pangolier": 1.504
        },
        {
            "name": "Ringmaster",
            "Pangolier": -0.897
        },
        {
            "name": "Rubick",
            "Pangolier": -1.006
        },
        {
            "name": "Sand King",
            "Pangolier": -1.59
        },
        {
            "name": "Shadow Demon",
            "Pangolier": -2.908
        },
        {
            "name": "Shadow Fiend",
            "Pangolier": 1.585
        },
        {
            "name": "Shadow Shaman",
            "Pangolier": 0.512
        },
        {
            "name": "Silencer",
            "Pangolier": 0.684
        },
        {
            "name": "Skywrath Mage",
            "Pangolier": -1.272
        },
        {
            "name": "Slardar",
            "Pangolier": 0.017
        },
        {
            "name": "Slark",
            "Pangolier": 0.429
        },
        {
            "name": "Snapfire",
            "Pangolier": -0.582
        },
        {
            "name": "Sniper",
            "Pangolier": 0.258
        },
        {
            "name": "Spectre",
            "Pangolier": -1.101
        },
        {
            "name": "Spirit Breaker",
            "Pangolier": 0.804
        },
        {
            "name": "Storm Spirit",
            "Pangolier": 1.877
        },
        {
            "name": "Sven",
            "Pangolier": 1.492
        },
        {
            "name": "Techies",
            "Pangolier": 0.733
        },
        {
            "name": "Templar Assassin",
            "Pangolier": -0.729
        },
        {
            "name": "Terrorblade",
            "Pangolier": 1.796
        },
        {
            "name": "Tidehunter",
            "Pangolier": -2.518
        },
        {
            "name": "Timbersaw",
            "Pangolier": 0.154
        },
        {
            "name": "Tinker",
            "Pangolier": -0.667
        },
        {
            "name": "Tiny",
            "Pangolier": -0.295
        },
        {
            "name": "Treant Protector",
            "Pangolier": 1.227
        },
        {
            "name": "Troll Warlord",
            "Pangolier": -1.983
        },
        {
            "name": "Tusk",
            "Pangolier": 0.366
        },
        {
            "name": "Underlord",
            "Pangolier": -1.363
        },
        {
            "name": "Undying",
            "Pangolier": -0.131
        },
        {
            "name": "Ursa",
            "Pangolier": 0.235
        },
        {
            "name": "Vengeful Spirit",
            "Pangolier": -0.357
        },
        {
            "name": "Venomancer",
            "Pangolier": 0.232
        },
        {
            "name": "Viper",
            "Pangolier": -1.508
        },
        {
            "name": "Visage",
            "Pangolier": -2.058
        },
        {
            "name": "Void Spirit",
            "Pangolier": -2.022
        },
        {
            "name": "Warlock",
            "Pangolier": -2.474
        },
        {
            "name": "Weaver",
            "Pangolier": 2.115
        },
        {
            "name": "Windranger",
            "Pangolier": -0.843
        },
        {
            "name": "Winter Wyvern",
            "Pangolier": 2.525
        },
        {
            "name": "Witch Doctor",
            "Pangolier": -1.177
        },
        {
            "name": "Wraith King",
            "Pangolier": -2.03
        },
        {
            "name": "Zeus",
            "Pangolier": -0.118
        }
    ],
    "Phoenix": [
        {
            "name": "Abaddon",
            "Phoenix": 0.979
        },
        {
            "name": "Alchemist",
            "Phoenix": 0.469
        },
        {
            "name": "Ancient Apparition",
            "Phoenix": 0.151
        },
        {
            "name": "Anti-Mage",
            "Phoenix": -1.42
        },
        {
            "name": "Arc Warden",
            "Phoenix": 0.743
        },
        {
            "name": "Axe",
            "Phoenix": -1.995
        },
        {
            "name": "Bane",
            "Phoenix": 0.995
        },
        {
            "name": "Batrider",
            "Phoenix": 0.371
        },
        {
            "name": "Beastmaster",
            "Phoenix": -1.871
        },
        {
            "name": "Bloodseeker",
            "Phoenix": 2.576
        },
        {
            "name": "Bounty Hunter",
            "Phoenix": -0.272
        },
        {
            "name": "Brewmaster",
            "Phoenix": -0.072
        },
        {
            "name": "Bristleback",
            "Phoenix": 0.478
        },
        {
            "name": "Broodmother",
            "Phoenix": -0.534
        },
        {
            "name": "Centaur Warrunner",
            "Phoenix": -0.127
        },
        {
            "name": "Chaos Knight",
            "Phoenix": -4.455
        },
        {
            "name": "Chen",
            "Phoenix": -0.25
        },
        {
            "name": "Clinkz",
            "Phoenix": 2.755
        },
        {
            "name": "Clockwerk",
            "Phoenix": -0.29
        },
        {
            "name": "Crystal Maiden",
            "Phoenix": -0.958
        },
        {
            "name": "Dark Seer",
            "Phoenix": -0.464
        },
        {
            "name": "Dark Willow",
            "Phoenix": 0.026
        },
        {
            "name": "Dawnbreaker",
            "Phoenix": -1.934
        },
        {
            "name": "Dazzle",
            "Phoenix": -1.465
        },
        {
            "name": "Death Prophet",
            "Phoenix": 1.411
        },
        {
            "name": "Disruptor",
            "Phoenix": 0.813
        },
        {
            "name": "Doom",
            "Phoenix": 1.241
        },
        {
            "name": "Dragon Knight",
            "Phoenix": -2.163
        },
        {
            "name": "Drow Ranger",
            "Phoenix": 0.828
        },
        {
            "name": "Earth Spirit",
            "Phoenix": -0.572
        },
        {
            "name": "Earthshaker",
            "Phoenix": -0.246
        },
        {
            "name": "Elder Titan",
            "Phoenix": -0.306
        },
        {
            "name": "Ember Spirit",
            "Phoenix": -0.038
        },
        {
            "name": "Enchantress",
            "Phoenix": 0.644
        },
        {
            "name": "Enigma",
            "Phoenix": -0.205
        },
        {
            "name": "Faceless Void",
            "Phoenix": 0.215
        },
        {
            "name": "Grimstroke",
            "Phoenix": 0.131
        },
        {
            "name": "Gyrocopter",
            "Phoenix": -0.825
        },
        {
            "name": "Hoodwink",
            "Phoenix": 0.403
        },
        {
            "name": "Huskar",
            "Phoenix": 1.743
        },
        {
            "name": "Invoker",
            "Phoenix": -0.116
        },
        {
            "name": "Io",
            "Phoenix": 1.523
        },
        {
            "name": "Jakiro",
            "Phoenix": 1.063
        },
        {
            "name": "Juggernaut",
            "Phoenix": 3.485
        },
        {
            "name": "Keeper of the Light",
            "Phoenix": 2.34
        },
        {
            "name": "Kez",
            "Phoenix": 0.785
        },
        {
            "name": "Kunkka",
            "Phoenix": -0.492
        },
        {
            "name": "Legion Commander",
            "Phoenix": 1.782
        },
        {
            "name": "Leshrac",
            "Phoenix": 0.436
        },
        {
            "name": "Lich",
            "Phoenix": -0.967
        },
        {
            "name": "Lifestealer",
            "Phoenix": 2.898
        },
        {
            "name": "Lina",
            "Phoenix": 3.889
        },
        {
            "name": "Lion",
            "Phoenix": 0.615
        },
        {
            "name": "Lone Druid",
            "Phoenix": -2.639
        },
        {
            "name": "Luna",
            "Phoenix": -0.283
        },
        {
            "name": "Lycan",
            "Phoenix": -1.009
        },
        {
            "name": "Magnus",
            "Phoenix": -0.838
        },
        {
            "name": "Marci",
            "Phoenix": 1.767
        },
        {
            "name": "Mars",
            "Phoenix": -0.51
        },
        {
            "name": "Medusa",
            "Phoenix": 0.721
        },
        {
            "name": "Meepo",
            "Phoenix": 0.499
        },
        {
            "name": "Mirana",
            "Phoenix": 1.211
        },
        {
            "name": "Monkey King",
            "Phoenix": -1.986
        },
        {
            "name": "Morphling",
            "Phoenix": 1.458
        },
        {
            "name": "Muerta",
            "Phoenix": 2.043
        },
        {
            "name": "Naga Siren",
            "Phoenix": -1.643
        },
        {
            "name": "Nature's Prophet",
            "Phoenix": 1.317
        },
        {
            "name": "Necrophos",
            "Phoenix": 0.41
        },
        {
            "name": "Night Stalker",
            "Phoenix": -1.925
        },
        {
            "name": "Nyx Assassin",
            "Phoenix": 0.744
        },
        {
            "name": "Ogre Magi",
            "Phoenix": 0.6
        },
        {
            "name": "Omniknight",
            "Phoenix": -0.008
        },
        {
            "name": "Oracle",
            "Phoenix": 1.469
        },
        {
            "name": "Outworld Destroyer",
            "Phoenix": -0.263
        },
        {
            "name": "Pangolier",
            "Phoenix": 0.336
        },
        {
            "name": "Phantom Assassin",
            "Phoenix": -2.582
        },
        {
            "name": "Phantom Lancer",
            "Phoenix": -1.319
        },
        {
            "name": "Primal Beast",
            "Phoenix": 0.518
        },
        {
            "name": "Puck",
            "Phoenix": 1.062
        },
        {
            "name": "Pudge",
            "Phoenix": -0.529
        },
        {
            "name": "Pugna",
            "Phoenix": 0.071
        },
        {
            "name": "Queen of Pain",
            "Phoenix": 0.752
        },
        {
            "name": "Razor",
            "Phoenix": -1.061
        },
        {
            "name": "Riki",
            "Phoenix": -0.401
        },
        {
            "name": "Ringmaster",
            "Phoenix": -0.166
        },
        {
            "name": "Rubick",
            "Phoenix": -1.174
        },
        {
            "name": "Sand King",
            "Phoenix": -1.669
        },
        {
            "name": "Shadow Demon",
            "Phoenix": -1.267
        },
        {
            "name": "Shadow Fiend",
            "Phoenix": -0.46
        },
        {
            "name": "Shadow Shaman",
            "Phoenix": -0.157
        },
        {
            "name": "Silencer",
            "Phoenix": 0.735
        },
        {
            "name": "Skywrath Mage",
            "Phoenix": 1.885
        },
        {
            "name": "Slardar",
            "Phoenix": -1.645
        },
        {
            "name": "Slark",
            "Phoenix": 1.086
        },
        {
            "name": "Snapfire",
            "Phoenix": 0.606
        },
        {
            "name": "Sniper",
            "Phoenix": 1.006
        },
        {
            "name": "Spectre",
            "Phoenix": -0.765
        },
        {
            "name": "Spirit Breaker",
            "Phoenix": -0.324
        },
        {
            "name": "Storm Spirit",
            "Phoenix": -0.019
        },
        {
            "name": "Sven",
            "Phoenix": -1.182
        },
        {
            "name": "Techies",
            "Phoenix": -0.323
        },
        {
            "name": "Templar Assassin",
            "Phoenix": -3.039
        },
        {
            "name": "Terrorblade",
            "Phoenix": -1.126
        },
        {
            "name": "Tidehunter",
            "Phoenix": 1.689
        },
        {
            "name": "Timbersaw",
            "Phoenix": -0.525
        },
        {
            "name": "Tinker",
            "Phoenix": 1.859
        },
        {
            "name": "Tiny",
            "Phoenix": -1.73
        },
        {
            "name": "Treant Protector",
            "Phoenix": -2.305
        },
        {
            "name": "Troll Warlord",
            "Phoenix": -1.245
        },
        {
            "name": "Tusk",
            "Phoenix": -1.372
        },
        {
            "name": "Underlord",
            "Phoenix": 1.35
        },
        {
            "name": "Undying",
            "Phoenix": -1.131
        },
        {
            "name": "Ursa",
            "Phoenix": 0.079
        },
        {
            "name": "Vengeful Spirit",
            "Phoenix": -0.398
        },
        {
            "name": "Venomancer",
            "Phoenix": -0.944
        },
        {
            "name": "Viper",
            "Phoenix": 0.611
        },
        {
            "name": "Visage",
            "Phoenix": 0.294
        },
        {
            "name": "Void Spirit",
            "Phoenix": 0.423
        },
        {
            "name": "Warlock",
            "Phoenix": -1.764
        },
        {
            "name": "Weaver",
            "Phoenix": 1.119
        },
        {
            "name": "Windranger",
            "Phoenix": 0.144
        },
        {
            "name": "Winter Wyvern",
            "Phoenix": -1.117
        },
        {
            "name": "Witch Doctor",
            "Phoenix": -0.278
        },
        {
            "name": "Wraith King",
            "Phoenix": -2.637
        },
        {
            "name": "Zeus",
            "Phoenix": -0.105
        }
    ],
    "Sand King": [
        {
            "name": "Abaddon",
            "Sand King": 0.491
        },
        {
            "name": "Alchemist",
            "Sand King": -0.395
        },
        {
            "name": "Ancient Apparition",
            "Sand King": 1.277
        },
        {
            "name": "Anti-Mage",
            "Sand King": 1.251
        },
        {
            "name": "Arc Warden",
            "Sand King": -2.231
        },
        {
            "name": "Axe",
            "Sand King": -1.647
        },
        {
            "name": "Bane",
            "Sand King": -1.087
        },
        {
            "name": "Batrider",
            "Sand King": -0.329
        },
        {
            "name": "Beastmaster",
            "Sand King": -3.793
        },
        {
            "name": "Bloodseeker",
            "Sand King": 1.909
        },
        {
            "name": "Bounty Hunter",
            "Sand King": 1.464
        },
        {
            "name": "Brewmaster",
            "Sand King": -1.173
        },
        {
            "name": "Bristleback",
            "Sand King": 2.447
        },
        {
            "name": "Broodmother",
            "Sand King": -1.713
        },
        {
            "name": "Centaur Warrunner",
            "Sand King": 1.867
        },
        {
            "name": "Chaos Knight",
            "Sand King": -4.578
        },
        {
            "name": "Chen",
            "Sand King": -0.065
        },
        {
            "name": "Clinkz",
            "Sand King": 0.899
        },
        {
            "name": "Clockwerk",
            "Sand King": -0.508
        },
        {
            "name": "Crystal Maiden",
            "Sand King": 0.207
        },
        {
            "name": "Dark Seer",
            "Sand King": -1.213
        },
        {
            "name": "Dark Willow",
            "Sand King": -1.433
        },
        {
            "name": "Dawnbreaker",
            "Sand King": 0.516
        },
        {
            "name": "Dazzle",
            "Sand King": -0.93
        },
        {
            "name": "Death Prophet",
            "Sand King": 0.252
        },
        {
            "name": "Disruptor",
            "Sand King": 1.375
        },
        {
            "name": "Doom",
            "Sand King": 0.933
        },
        {
            "name": "Dragon Knight",
            "Sand King": -1.198
        },
        {
            "name": "Drow Ranger",
            "Sand King": -1.082
        },
        {
            "name": "Earth Spirit",
            "Sand King": 0.901
        },
        {
            "name": "Earthshaker",
            "Sand King": -1.077
        },
        {
            "name": "Elder Titan",
            "Sand King": -0.464
        },
        {
            "name": "Ember Spirit",
            "Sand King": 2.075
        },
        {
            "name": "Enchantress",
            "Sand King": 0.46
        },
        {
            "name": "Enigma",
            "Sand King": -1.054
        },
        {
            "name": "Faceless Void",
            "Sand King": 2.063
        },
        {
            "name": "Grimstroke",
            "Sand King": -0.106
        },
        {
            "name": "Gyrocopter",
            "Sand King": 0.118
        },
        {
            "name": "Hoodwink",
            "Sand King": -0.293
        },
        {
            "name": "Huskar",
            "Sand King": 0.749
        },
        {
            "name": "Invoker",
            "Sand King": -2.212
        },
        {
            "name": "Io",
            "Sand King": -0.848
        },
        {
            "name": "Jakiro",
            "Sand King": 0.645
        },
        {
            "name": "Juggernaut",
            "Sand King": 1.946
        },
        {
            "name": "Keeper of the Light",
            "Sand King": -0.29
        },
        {
            "name": "Kez",
            "Sand King": 1.592
        },
        {
            "name": "Kunkka",
            "Sand King": 0.886
        },
        {
            "name": "Legion Commander",
            "Sand King": 0.247
        },
        {
            "name": "Leshrac",
            "Sand King": 2.208
        },
        {
            "name": "Lich",
            "Sand King": 0.588
        },
        {
            "name": "Lifestealer",
            "Sand King": 2.034
        },
        {
            "name": "Lina",
            "Sand King": 0.882
        },
        {
            "name": "Lion",
            "Sand King": 0.16
        },
        {
            "name": "Lone Druid",
            "Sand King": -3.226
        },
        {
            "name": "Luna",
            "Sand King": -1.491
        },
        {
            "name": "Lycan",
            "Sand King": -1.002
        },
        {
            "name": "Magnus",
            "Sand King": 1.072
        },
        {
            "name": "Marci",
            "Sand King": -1.308
        },
        {
            "name": "Mars",
            "Sand King": -0.66
        },
        {
            "name": "Medusa",
            "Sand King": -2.786
        },
        {
            "name": "Meepo",
            "Sand King": -1.753
        },
        {
            "name": "Mirana",
            "Sand King": 0.449
        },
        {
            "name": "Monkey King",
            "Sand King": -0.513
        },
        {
            "name": "Morphling",
            "Sand King": 0.814
        },
        {
            "name": "Muerta",
            "Sand King": 0.707
        },
        {
            "name": "Naga Siren",
            "Sand King": -4.578
        },
        {
            "name": "Nature's Prophet",
            "Sand King": -0.05
        },
        {
            "name": "Necrophos",
            "Sand King": -0.663
        },
        {
            "name": "Night Stalker",
            "Sand King": 1.147
        },
        {
            "name": "Nyx Assassin",
            "Sand King": 1.909
        },
        {
            "name": "Ogre Magi",
            "Sand King": -0.747
        },
        {
            "name": "Omniknight",
            "Sand King": 0.473
        },
        {
            "name": "Oracle",
            "Sand King": 1.344
        },
        {
            "name": "Outworld Destroyer",
            "Sand King": -0.888
        },
        {
            "name": "Pangolier",
            "Sand King": 1.632
        },
        {
            "name": "Phantom Assassin",
            "Sand King": -0.955
        },
        {
            "name": "Phantom Lancer",
            "Sand King": -7.248
        },
        {
            "name": "Phoenix",
            "Sand King": 1.696
        },
        {
            "name": "Primal Beast",
            "Sand King": -0.852
        },
        {
            "name": "Puck",
            "Sand King": 0.467
        },
        {
            "name": "Pudge",
            "Sand King": 1.217
        },
        {
            "name": "Pugna",
            "Sand King": 1.601
        },
        {
            "name": "Queen of Pain",
            "Sand King": 1.722
        },
        {
            "name": "Razor",
            "Sand King": -0.793
        },
        {
            "name": "Riki",
            "Sand King": -1.299
        },
        {
            "name": "Ringmaster",
            "Sand King": 0.168
        },
        {
            "name": "Rubick",
            "Sand King": -0.791
        },
        {
            "name": "Shadow Demon",
            "Sand King": -1.907
        },
        {
            "name": "Shadow Fiend",
            "Sand King": 0.071
        },
        {
            "name": "Shadow Shaman",
            "Sand King": -2.27
        },
        {
            "name": "Silencer",
            "Sand King": 1.724
        },
        {
            "name": "Skywrath Mage",
            "Sand King": 0.901
        },
        {
            "name": "Slardar",
            "Sand King": 1.045
        },
        {
            "name": "Slark",
            "Sand King": -1.246
        },
        {
            "name": "Snapfire",
            "Sand King": 0.663
        },
        {
            "name": "Sniper",
            "Sand King": 0.837
        },
        {
            "name": "Spectre",
            "Sand King": 0.977
        },
        {
            "name": "Spirit Breaker",
            "Sand King": -0.613
        },
        {
            "name": "Storm Spirit",
            "Sand King": 1.693
        },
        {
            "name": "Sven",
            "Sand King": 1.181
        },
        {
            "name": "Techies",
            "Sand King": 0.451
        },
        {
            "name": "Templar Assassin",
            "Sand King": -1.366
        },
        {
            "name": "Terrorblade",
            "Sand King": -2.158
        },
        {
            "name": "Tidehunter",
            "Sand King": 0.007
        },
        {
            "name": "Timbersaw",
            "Sand King": 1.108
        },
        {
            "name": "Tinker",
            "Sand King": -0.227
        },
        {
            "name": "Tiny",
            "Sand King": -1.46
        },
        {
            "name": "Treant Protector",
            "Sand King": -0.599
        },
        {
            "name": "Troll Warlord",
            "Sand King": 0.818
        },
        {
            "name": "Tusk",
            "Sand King": -1.735
        },
        {
            "name": "Underlord",
            "Sand King": 2.025
        },
        {
            "name": "Undying",
            "Sand King": -0.425
        },
        {
            "name": "Ursa",
            "Sand King": 0.412
        },
        {
            "name": "Vengeful Spirit",
            "Sand King": -1.221
        },
        {
            "name": "Venomancer",
            "Sand King": 0.294
        },
        {
            "name": "Viper",
            "Sand King": -0.674
        },
        {
            "name": "Visage",
            "Sand King": -1.217
        },
        {
            "name": "Void Spirit",
            "Sand King": 0.513
        },
        {
            "name": "Warlock",
            "Sand King": -2.294
        },
        {
            "name": "Weaver",
            "Sand King": 0.302
        },
        {
            "name": "Windranger",
            "Sand King": -0.997
        },
        {
            "name": "Winter Wyvern",
            "Sand King": -3.837
        },
        {
            "name": "Witch Doctor",
            "Sand King": -0.345
        },
        {
            "name": "Wraith King",
            "Sand King": -1.537
        },
        {
            "name": "Zeus",
            "Sand King": 3.011
        }
    ],
    "Snapfire": [
        {
            "name": "Abaddon",
            "Snapfire": -0.361
        },
        {
            "name": "Alchemist",
            "Snapfire": 0.144
        },
        {
            "name": "Ancient Apparition",
            "Snapfire": 0.034
        },
        {
            "name": "Anti-Mage",
            "Snapfire": -0.368
        },
        {
            "name": "Arc Warden",
            "Snapfire": -1.312
        },
        {
            "name": "Axe",
            "Snapfire": 0.158
        },
        {
            "name": "Bane",
            "Snapfire": 0.441
        },
        {
            "name": "Batrider",
            "Snapfire": 0.187
        },
        {
            "name": "Beastmaster",
            "Snapfire": -1.869
        },
        {
            "name": "Bloodseeker",
            "Snapfire": 0.352
        },
        {
            "name": "Bounty Hunter",
            "Snapfire": -0.332
        },
        {
            "name": "Brewmaster",
            "Snapfire": -0.528
        },
        {
            "name": "Bristleback",
            "Snapfire": 1.426
        },
        {
            "name": "Broodmother",
            "Snapfire": -0.122
        },
        {
            "name": "Centaur Warrunner",
            "Snapfire": 0.421
        },
        {
            "name": "Chaos Knight",
            "Snapfire": 0.287
        },
        {
            "name": "Chen",
            "Snapfire": -1.307
        },
        {
            "name": "Clinkz",
            "Snapfire": 0.783
        },
        {
            "name": "Clockwerk",
            "Snapfire": -0.908
        },
        {
            "name": "Crystal Maiden",
            "Snapfire": 0.164
        },
        {
            "name": "Dark Seer",
            "Snapfire": -1.133
        },
        {
            "name": "Dark Willow",
            "Snapfire": -0.62
        },
        {
            "name": "Dawnbreaker",
            "Snapfire": -0.654
        },
        {
            "name": "Dazzle",
            "Snapfire": 0.13
        },
        {
            "name": "Death Prophet",
            "Snapfire": 0.316
        },
        {
            "name": "Disruptor",
            "Snapfire": -0.091
        },
        {
            "name": "Doom",
            "Snapfire": -0.317
        },
        {
            "name": "Dragon Knight",
            "Snapfire": 0.572
        },
        {
            "name": "Drow Ranger",
            "Snapfire": -0.125
        },
        {
            "name": "Earth Spirit",
            "Snapfire": -0.16
        },
        {
            "name": "Earthshaker",
            "Snapfire": -0.144
        },
        {
            "name": "Elder Titan",
            "Snapfire": -0.467
        },
        {
            "name": "Ember Spirit",
            "Snapfire": -0.642
        },
        {
            "name": "Enchantress",
            "Snapfire": 1.176
        },
        {
            "name": "Enigma",
            "Snapfire": -0.358
        },
        {
            "name": "Faceless Void",
            "Snapfire": 1.675
        },
        {
            "name": "Grimstroke",
            "Snapfire": -0.77
        },
        {
            "name": "Gyrocopter",
            "Snapfire": -0.105
        },
        {
            "name": "Hoodwink",
            "Snapfire": -0.549
        },
        {
            "name": "Huskar",
            "Snapfire": 0.915
        },
        {
            "name": "Invoker",
            "Snapfire": -0.892
        },
        {
            "name": "Io",
            "Snapfire": -0.577
        },
        {
            "name": "Jakiro",
            "Snapfire": -0.533
        },
        {
            "name": "Juggernaut",
            "Snapfire": 0.282
        },
        {
            "name": "Keeper of the Light",
            "Snapfire": -0.426
        },
        {
            "name": "Kez",
            "Snapfire": 0.818
        },
        {
            "name": "Kunkka",
            "Snapfire": 0.355
        },
        {
            "name": "Legion Commander",
            "Snapfire": 0.472
        },
        {
            "name": "Leshrac",
            "Snapfire": -0.412
        },
        {
            "name": "Lich",
            "Snapfire": 0.011
        },
        {
            "name": "Lifestealer",
            "Snapfire": 0.945
        },
        {
            "name": "Lina",
            "Snapfire": 0.423
        },
        {
            "name": "Lion",
            "Snapfire": 0.071
        },
        {
            "name": "Lone Druid",
            "Snapfire": -1.081
        },
        {
            "name": "Luna",
            "Snapfire": -0.335
        },
        {
            "name": "Lycan",
            "Snapfire": 0.399
        },
        {
            "name": "Magnus",
            "Snapfire": 0.365
        },
        {
            "name": "Marci",
            "Snapfire": -1.067
        },
        {
            "name": "Mars",
            "Snapfire": 0.381
        },
        {
            "name": "Medusa",
            "Snapfire": -1.949
        },
        {
            "name": "Meepo",
            "Snapfire": -2.031
        },
        {
            "name": "Mirana",
            "Snapfire": 0.656
        },
        {
            "name": "Monkey King",
            "Snapfire": -1.457
        },
        {
            "name": "Morphling",
            "Snapfire": 0.258
        },
        {
            "name": "Muerta",
            "Snapfire": -1.446
        },
        {
            "name": "Naga Siren",
            "Snapfire": -2.393
        },
        {
            "name": "Nature's Prophet",
            "Snapfire": -0.451
        },
        {
            "name": "Necrophos",
            "Snapfire": -0.934
        },
        {
            "name": "Night Stalker",
            "Snapfire": 0.016
        },
        {
            "name": "Nyx Assassin",
            "Snapfire": 0.333
        },
        {
            "name": "Ogre Magi",
            "Snapfire": 0.215
        },
        {
            "name": "Omniknight",
            "Snapfire": 2.023
        },
        {
            "name": "Oracle",
            "Snapfire": 0.244
        },
        {
            "name": "Outworld Destroyer",
            "Snapfire": 1.46
        },
        {
            "name": "Pangolier",
            "Snapfire": 0.582
        },
        {
            "name": "Phantom Assassin",
            "Snapfire": 0.617
        },
        {
            "name": "Phantom Lancer",
            "Snapfire": -0.335
        },
        {
            "name": "Phoenix",
            "Snapfire": -0.434
        },
        {
            "name": "Primal Beast",
            "Snapfire": -0.41
        },
        {
            "name": "Puck",
            "Snapfire": 0.077
        },
        {
            "name": "Pudge",
            "Snapfire": -0.044
        },
        {
            "name": "Pugna",
            "Snapfire": 0.046
        },
        {
            "name": "Queen of Pain",
            "Snapfire": 0.579
        },
        {
            "name": "Razor",
            "Snapfire": -0.519
        },
        {
            "name": "Riki",
            "Snapfire": -1.078
        },
        {
            "name": "Ringmaster",
            "Snapfire": -0.284
        },
        {
            "name": "Rubick",
            "Snapfire": -0.357
        },
        {
            "name": "Sand King",
            "Snapfire": -0.642
        },
        {
            "name": "Shadow Demon",
            "Snapfire": 0.251
        },
        {
            "name": "Shadow Fiend",
            "Snapfire": -0.651
        },
        {
            "name": "Shadow Shaman",
            "Snapfire": -0.782
        },
        {
            "name": "Silencer",
            "Snapfire": -0.254
        },
        {
            "name": "Skywrath Mage",
            "Snapfire": -0.261
        },
        {
            "name": "Slardar",
            "Snapfire": 0.392
        },
        {
            "name": "Slark",
            "Snapfire": -0.804
        },
        {
            "name": "Sniper",
            "Snapfire": -0.508
        },
        {
            "name": "Spectre",
            "Snapfire": -0.369
        },
        {
            "name": "Spirit Breaker",
            "Snapfire": 0.23
        },
        {
            "name": "Storm Spirit",
            "Snapfire": 0.574
        },
        {
            "name": "Sven",
            "Snapfire": 1.61
        },
        {
            "name": "Techies",
            "Snapfire": -0.311
        },
        {
            "name": "Templar Assassin",
            "Snapfire": 0.207
        },
        {
            "name": "Terrorblade",
            "Snapfire": -0.788
        },
        {
            "name": "Tidehunter",
            "Snapfire": -1.872
        },
        {
            "name": "Timbersaw",
            "Snapfire": 0.319
        },
        {
            "name": "Tinker",
            "Snapfire": -0.572
        },
        {
            "name": "Tiny",
            "Snapfire": -0.083
        },
        {
            "name": "Treant Protector",
            "Snapfire": -0.222
        },
        {
            "name": "Troll Warlord",
            "Snapfire": 0.065
        },
        {
            "name": "Tusk",
            "Snapfire": 0.094
        },
        {
            "name": "Underlord",
            "Snapfire": -2.382
        },
        {
            "name": "Undying",
            "Snapfire": 0.241
        },
        {
            "name": "Ursa",
            "Snapfire": 1.362
        },
        {
            "name": "Vengeful Spirit",
            "Snapfire": -0.178
        },
        {
            "name": "Venomancer",
            "Snapfire": -0.535
        },
        {
            "name": "Viper",
            "Snapfire": -0.481
        },
        {
            "name": "Visage",
            "Snapfire": -0.007
        },
        {
            "name": "Void Spirit",
            "Snapfire": -0.658
        },
        {
            "name": "Warlock",
            "Snapfire": -1.561
        },
        {
            "name": "Weaver",
            "Snapfire": 1.201
        },
        {
            "name": "Windranger",
            "Snapfire": 0.702
        },
        {
            "name": "Winter Wyvern",
            "Snapfire": -0.725
        },
        {
            "name": "Witch Doctor",
            "Snapfire": -0.361
        },
        {
            "name": "Wraith King",
            "Snapfire": 0.234
        },
        {
            "name": "Zeus",
            "Snapfire": 0.284
        }
    ],
    "Techies": [
        {
            "name": "Abaddon",
            "Techies": -0.567
        },
        {
            "name": "Alchemist",
            "Techies": -0.812
        },
        {
            "name": "Ancient Apparition",
            "Techies": -0.393
        },
        {
            "name": "Anti-Mage",
            "Techies": -0.262
        },
        {
            "name": "Arc Warden",
            "Techies": -0.979
        },
        {
            "name": "Axe",
            "Techies": -0.196
        },
        {
            "name": "Bane",
            "Techies": 0.542
        },
        {
            "name": "Batrider",
            "Techies": 1.149
        },
        {
            "name": "Beastmaster",
            "Techies": -0.704
        },
        {
            "name": "Bloodseeker",
            "Techies": -1.528
        },
        {
            "name": "Bounty Hunter",
            "Techies": -0.997
        },
        {
            "name": "Brewmaster",
            "Techies": -0.73
        },
        {
            "name": "Bristleback",
            "Techies": 1.38
        },
        {
            "name": "Broodmother",
            "Techies": -2.253
        },
        {
            "name": "Centaur Warrunner",
            "Techies": 0.466
        },
        {
            "name": "Chaos Knight",
            "Techies": -0.808
        },
        {
            "name": "Chen",
            "Techies": -2.505
        },
        {
            "name": "Clinkz",
            "Techies": -0.967
        },
        {
            "name": "Clockwerk",
            "Techies": 0.273
        },
        {
            "name": "Crystal Maiden",
            "Techies": -0.309
        },
        {
            "name": "Dark Seer",
            "Techies": -0.764
        },
        {
            "name": "Dark Willow",
            "Techies": -1.439
        },
        {
            "name": "Dawnbreaker",
            "Techies": 0.253
        },
        {
            "name": "Dazzle",
            "Techies": 0.13
        },
        {
            "name": "Death Prophet",
            "Techies": -0.554
        },
        {
            "name": "Disruptor",
            "Techies": 0.122
        },
        {
            "name": "Doom",
            "Techies": 0.088
        },
        {
            "name": "Dragon Knight",
            "Techies": -0.107
        },
        {
            "name": "Drow Ranger",
            "Techies": 0.032
        },
        {
            "name": "Earth Spirit",
            "Techies": -0.017
        },
        {
            "name": "Earthshaker",
            "Techies": -0.678
        },
        {
            "name": "Elder Titan",
            "Techies": -1.104
        },
        {
            "name": "Ember Spirit",
            "Techies": -1.32
        },
        {
            "name": "Enchantress",
            "Techies": -0.934
        },
        {
            "name": "Enigma",
            "Techies": 0.365
        },
        {
            "name": "Faceless Void",
            "Techies": 0.681
        },
        {
            "name": "Grimstroke",
            "Techies": 0.64
        },
        {
            "name": "Gyrocopter",
            "Techies": -0.462
        },
        {
            "name": "Hoodwink",
            "Techies": -0.218
        },
        {
            "name": "Huskar",
            "Techies": -0.399
        },
        {
            "name": "Invoker",
            "Techies": -0.098
        },
        {
            "name": "Io",
            "Techies": -1.666
        },
        {
            "name": "Jakiro",
            "Techies": 0.844
        },
        {
            "name": "Juggernaut",
            "Techies": 1.569
        },
        {
            "name": "Keeper of the Light",
            "Techies": 0.426
        },
        {
            "name": "Kez",
            "Techies": -0.839
        },
        {
            "name": "Kunkka",
            "Techies": 0.285
        },
        {
            "name": "Legion Commander",
            "Techies": -1.295
        },
        {
            "name": "Leshrac",
            "Techies": 0.695
        },
        {
            "name": "Lich",
            "Techies": 0.279
        },
        {
            "name": "Lifestealer",
            "Techies": 2.128
        },
        {
            "name": "Lina",
            "Techies": 0.876
        },
        {
            "name": "Lion",
            "Techies": 0.856
        },
        {
            "name": "Lone Druid",
            "Techies": -2.745
        },
        {
            "name": "Luna",
            "Techies": -0.862
        },
        {
            "name": "Lycan",
            "Techies": -1.221
        },
        {
            "name": "Magnus",
            "Techies": 0.793
        },
        {
            "name": "Marci",
            "Techies": -2.335
        },
        {
            "name": "Mars",
            "Techies": 1.551
        },
        {
            "name": "Medusa",
            "Techies": 2.385
        },
        {
            "name": "Meepo",
            "Techies": -3.676
        },
        {
            "name": "Mirana",
            "Techies": -0.2
        },
        {
            "name": "Monkey King",
            "Techies": -2.865
        },
        {
            "name": "Morphling",
            "Techies": -1.464
        },
        {
            "name": "Muerta",
            "Techies": -0.57
        },
        {
            "name": "Naga Siren",
            "Techies": -1.689
        },
        {
            "name": "Nature's Prophet",
            "Techies": -0.207
        },
        {
            "name": "Necrophos",
            "Techies": 0.943
        },
        {
            "name": "Night Stalker",
            "Techies": -1.296
        },
        {
            "name": "Nyx Assassin",
            "Techies": -0.496
        },
        {
            "name": "Ogre Magi",
            "Techies": -0.565
        },
        {
            "name": "Omniknight",
            "Techies": -0.293
        },
        {
            "name": "Oracle",
            "Techies": 1.154
        },
        {
            "name": "Outworld Destroyer",
            "Techies": 0.306
        },
        {
            "name": "Pangolier",
            "Techies": -0.704
        },
        {
            "name": "Phantom Assassin",
            "Techies": -0.903
        },
        {
            "name": "Phantom Lancer",
            "Techies": -1.801
        },
        {
            "name": "Phoenix",
            "Techies": 0.27
        },
        {
            "name": "Primal Beast",
            "Techies": 0.314
        },
        {
            "name": "Puck",
            "Techies": -0.187
        },
        {
            "name": "Pudge",
            "Techies": 0.66
        },
        {
            "name": "Pugna",
            "Techies": 0.258
        },
        {
            "name": "Queen of Pain",
            "Techies": 0.266
        },
        {
            "name": "Razor",
            "Techies": -1.961
        },
        {
            "name": "Riki",
            "Techies": -2.046
        },
        {
            "name": "Ringmaster",
            "Techies": -0.535
        },
        {
            "name": "Rubick",
            "Techies": 0.403
        },
        {
            "name": "Sand King",
            "Techies": -0.582
        },
        {
            "name": "Shadow Demon",
            "Techies": 0.638
        },
        {
            "name": "Shadow Fiend",
            "Techies": -0.847
        },
        {
            "name": "Shadow Shaman",
            "Techies": -0.454
        },
        {
            "name": "Silencer",
            "Techies": -0.772
        },
        {
            "name": "Skywrath Mage",
            "Techies": 0.552
        },
        {
            "name": "Slardar",
            "Techies": -0.794
        },
        {
            "name": "Slark",
            "Techies": -2.879
        },
        {
            "name": "Snapfire",
            "Techies": 0.334
        },
        {
            "name": "Sniper",
            "Techies": -0.17
        },
        {
            "name": "Spectre",
            "Techies": 1.141
        },
        {
            "name": "Spirit Breaker",
            "Techies": -0.438
        },
        {
            "name": "Storm Spirit",
            "Techies": 0.272
        },
        {
            "name": "Sven",
            "Techies": -0.963
        },
        {
            "name": "Templar Assassin",
            "Techies": 1.804
        },
        {
            "name": "Terrorblade",
            "Techies": -1.67
        },
        {
            "name": "Tidehunter",
            "Techies": 0.587
        },
        {
            "name": "Timbersaw",
            "Techies": 0.981
        },
        {
            "name": "Tinker",
            "Techies": 0.169
        },
        {
            "name": "Tiny",
            "Techies": -0.134
        },
        {
            "name": "Treant Protector",
            "Techies": -0.513
        },
        {
            "name": "Troll Warlord",
            "Techies": -1.897
        },
        {
            "name": "Tusk",
            "Techies": -0.91
        },
        {
            "name": "Underlord",
            "Techies": 0.53
        },
        {
            "name": "Undying",
            "Techies": 0.14
        },
        {
            "name": "Ursa",
            "Techies": -0.532
        },
        {
            "name": "Vengeful Spirit",
            "Techies": 0.284
        },
        {
            "name": "Venomancer",
            "Techies": -0.816
        },
        {
            "name": "Viper",
            "Techies": -1.184
        },
        {
            "name": "Visage",
            "Techies": 0.419
        },
        {
            "name": "Void Spirit",
            "Techies": 0.115
        },
        {
            "name": "Warlock",
            "Techies": 0.389
        },
        {
            "name": "Weaver",
            "Techies": -1.406
        },
        {
            "name": "Windranger",
            "Techies": -0.598
        },
        {
            "name": "Winter Wyvern",
            "Techies": -0.785
        },
        {
            "name": "Witch Doctor",
            "Techies": 0.316
        },
        {
            "name": "Wraith King",
            "Techies": 0.986
        },
        {
            "name": "Zeus",
            "Techies": 0.056
        }
    ],
    "Timbersaw": [
        {
            "name": "Abaddon",
            "Timbersaw": 0.006
        },
        {
            "name": "Alchemist",
            "Timbersaw": -1.139
        },
        {
            "name": "Ancient Apparition",
            "Timbersaw": 0.546
        },
        {
            "name": "Anti-Mage",
            "Timbersaw": -1.048
        },
        {
            "name": "Arc Warden",
            "Timbersaw": 0.308
        },
        {
            "name": "Axe",
            "Timbersaw": -2.932
        },
        {
            "name": "Bane",
            "Timbersaw": 0.771
        },
        {
            "name": "Batrider",
            "Timbersaw": 1.907
        },
        {
            "name": "Beastmaster",
            "Timbersaw": 0.471
        },
        {
            "name": "Bloodseeker",
            "Timbersaw": 2.032
        },
        {
            "name": "Bounty Hunter",
            "Timbersaw": 0.88
        },
        {
            "name": "Brewmaster",
            "Timbersaw": -0.402
        },
        {
            "name": "Bristleback",
            "Timbersaw": 0.661
        },
        {
            "name": "Broodmother",
            "Timbersaw": 0.448
        },
        {
            "name": "Centaur Warrunner",
            "Timbersaw": -1.603
        },
        {
            "name": "Chaos Knight",
            "Timbersaw": -2.531
        },
        {
            "name": "Chen",
            "Timbersaw": 0.217
        },
        {
            "name": "Clinkz",
            "Timbersaw": 1.097
        },
        {
            "name": "Clockwerk",
            "Timbersaw": -0.271
        },
        {
            "name": "Crystal Maiden",
            "Timbersaw": 0.097
        },
        {
            "name": "Dark Seer",
            "Timbersaw": -1.728
        },
        {
            "name": "Dark Willow",
            "Timbersaw": 0.101
        },
        {
            "name": "Dawnbreaker",
            "Timbersaw": -0.795
        },
        {
            "name": "Dazzle",
            "Timbersaw": 0.032
        },
        {
            "name": "Death Prophet",
            "Timbersaw": 2.339
        },
        {
            "name": "Disruptor",
            "Timbersaw": 0.928
        },
        {
            "name": "Doom",
            "Timbersaw": 1.751
        },
        {
            "name": "Dragon Knight",
            "Timbersaw": -1.172
        },
        {
            "name": "Drow Ranger",
            "Timbersaw": -0.43
        },
        {
            "name": "Earth Spirit",
            "Timbersaw": -0.417
        },
        {
            "name": "Earthshaker",
            "Timbersaw": 0.446
        },
        {
            "name": "Elder Titan",
            "Timbersaw": 0.917
        },
        {
            "name": "Ember Spirit",
            "Timbersaw": 0.394
        },
        {
            "name": "Enchantress",
            "Timbersaw": 0.462
        },
        {
            "name": "Enigma",
            "Timbersaw": 0.724
        },
        {
            "name": "Faceless Void",
            "Timbersaw": 2.556
        },
        {
            "name": "Grimstroke",
            "Timbersaw": 3.113
        },
        {
            "name": "Gyrocopter",
            "Timbersaw": 0.573
        },
        {
            "name": "Hoodwink",
            "Timbersaw": -1.837
        },
        {
            "name": "Huskar",
            "Timbersaw": -2.412
        },
        {
            "name": "Invoker",
            "Timbersaw": 0.335
        },
        {
            "name": "Io",
            "Timbersaw": 1.116
        },
        {
            "name": "Jakiro",
            "Timbersaw": -0.006
        },
        {
            "name": "Juggernaut",
            "Timbersaw": 0.314
        },
        {
            "name": "Keeper of the Light",
            "Timbersaw": 0.344
        },
        {
            "name": "Kez",
            "Timbersaw": 1.754
        },
        {
            "name": "Kunkka",
            "Timbersaw": -2.156
        },
        {
            "name": "Legion Commander",
            "Timbersaw": -0.396
        },
        {
            "name": "Leshrac",
            "Timbersaw": 1.509
        },
        {
            "name": "Lich",
            "Timbersaw": -1.271
        },
        {
            "name": "Lifestealer",
            "Timbersaw": 0.825
        },
        {
            "name": "Lina",
            "Timbersaw": 0.659
        },
        {
            "name": "Lion",
            "Timbersaw": 1.116
        },
        {
            "name": "Lone Druid",
            "Timbersaw": -1.218
        },
        {
            "name": "Luna",
            "Timbersaw": 0.069
        },
        {
            "name": "Lycan",
            "Timbersaw": -0.297
        },
        {
            "name": "Magnus",
            "Timbersaw": 0.961
        },
        {
            "name": "Marci",
            "Timbersaw": 1.324
        },
        {
            "name": "Mars",
            "Timbersaw": 0.721
        },
        {
            "name": "Medusa",
            "Timbersaw": 3.933
        },
        {
            "name": "Meepo",
            "Timbersaw": -3.144
        },
        {
            "name": "Mirana",
            "Timbersaw": 0.723
        },
        {
            "name": "Monkey King",
            "Timbersaw": -0.918
        },
        {
            "name": "Morphling",
            "Timbersaw": -1.266
        },
        {
            "name": "Muerta",
            "Timbersaw": 1.159
        },
        {
            "name": "Naga Siren",
            "Timbersaw": -5.862
        },
        {
            "name": "Nature's Prophet",
            "Timbersaw": 0.262
        },
        {
            "name": "Necrophos",
            "Timbersaw": -0.31
        },
        {
            "name": "Night Stalker",
            "Timbersaw": 2.098
        },
        {
            "name": "Nyx Assassin",
            "Timbersaw": 0.774
        },
        {
            "name": "Ogre Magi",
            "Timbersaw": 0.767
        },
        {
            "name": "Omniknight",
            "Timbersaw": -1.711
        },
        {
            "name": "Oracle",
            "Timbersaw": 0.342
        },
        {
            "name": "Outworld Destroyer",
            "Timbersaw": 3.958
        },
        {
            "name": "Pangolier",
            "Timbersaw": -0.078
        },
        {
            "name": "Phantom Assassin",
            "Timbersaw": -1.94
        },
        {
            "name": "Phantom Lancer",
            "Timbersaw": -4.581
        },
        {
            "name": "Phoenix",
            "Timbersaw": 0.443
        },
        {
            "name": "Primal Beast",
            "Timbersaw": 0.59
        },
        {
            "name": "Puck",
            "Timbersaw": 1.409
        },
        {
            "name": "Pudge",
            "Timbersaw": -2.142
        },
        {
            "name": "Pugna",
            "Timbersaw": 3.986
        },
        {
            "name": "Queen of Pain",
            "Timbersaw": 1.98
        },
        {
            "name": "Razor",
            "Timbersaw": -1.438
        },
        {
            "name": "Riki",
            "Timbersaw": -0.337
        },
        {
            "name": "Ringmaster",
            "Timbersaw": -1.003
        },
        {
            "name": "Rubick",
            "Timbersaw": 0.143
        },
        {
            "name": "Sand King",
            "Timbersaw": -1.14
        },
        {
            "name": "Shadow Demon",
            "Timbersaw": -2.072
        },
        {
            "name": "Shadow Fiend",
            "Timbersaw": 0.749
        },
        {
            "name": "Shadow Shaman",
            "Timbersaw": -0.012
        },
        {
            "name": "Silencer",
            "Timbersaw": 4.657
        },
        {
            "name": "Skywrath Mage",
            "Timbersaw": 2.425
        },
        {
            "name": "Slardar",
            "Timbersaw": 0.892
        },
        {
            "name": "Slark",
            "Timbersaw": -2.087
        },
        {
            "name": "Snapfire",
            "Timbersaw": -0.204
        },
        {
            "name": "Sniper",
            "Timbersaw": -1.639
        },
        {
            "name": "Spectre",
            "Timbersaw": 1.384
        },
        {
            "name": "Spirit Breaker",
            "Timbersaw": -1.098
        },
        {
            "name": "Storm Spirit",
            "Timbersaw": 1.456
        },
        {
            "name": "Sven",
            "Timbersaw": -0.086
        },
        {
            "name": "Techies",
            "Timbersaw": -1.001
        },
        {
            "name": "Templar Assassin",
            "Timbersaw": 2.368
        },
        {
            "name": "Terrorblade",
            "Timbersaw": -1.626
        },
        {
            "name": "Tidehunter",
            "Timbersaw": -1.914
        },
        {
            "name": "Tinker",
            "Timbersaw": 0.961
        },
        {
            "name": "Tiny",
            "Timbersaw": -2.324
        },
        {
            "name": "Treant Protector",
            "Timbersaw": -2.003
        },
        {
            "name": "Troll Warlord",
            "Timbersaw": 0.437
        },
        {
            "name": "Tusk",
            "Timbersaw": -1.581
        },
        {
            "name": "Underlord",
            "Timbersaw": -1.956
        },
        {
            "name": "Undying",
            "Timbersaw": -1.633
        },
        {
            "name": "Ursa",
            "Timbersaw": 2.211
        },
        {
            "name": "Vengeful Spirit",
            "Timbersaw": -0.277
        },
        {
            "name": "Venomancer",
            "Timbersaw": -0.883
        },
        {
            "name": "Viper",
            "Timbersaw": -0.039
        },
        {
            "name": "Visage",
            "Timbersaw": 0.562
        },
        {
            "name": "Void Spirit",
            "Timbersaw": -0.587
        },
        {
            "name": "Warlock",
            "Timbersaw": -0.151
        },
        {
            "name": "Weaver",
            "Timbersaw": 2.433
        },
        {
            "name": "Windranger",
            "Timbersaw": 0.726
        },
        {
            "name": "Winter Wyvern",
            "Timbersaw": -3.776
        },
        {
            "name": "Witch Doctor",
            "Timbersaw": 1.679
        },
        {
            "name": "Wraith King",
            "Timbersaw": -1.989
        },
        {
            "name": "Zeus",
            "Timbersaw": 0.25
        }
    ],
    "Vengeful Spirit": [
        {
            "name": "Abaddon",
            "Vengeful Spirit": -0.33
        },
        {
            "name": "Alchemist",
            "Vengeful Spirit": -0.192
        },
        {
            "name": "Ancient Apparition",
            "Vengeful Spirit": -0.755
        },
        {
            "name": "Anti-Mage",
            "Vengeful Spirit": 0.924
        },
        {
            "name": "Arc Warden",
            "Vengeful Spirit": 0.486
        },
        {
            "name": "Axe",
            "Vengeful Spirit": -0.249
        },
        {
            "name": "Bane",
            "Vengeful Spirit": -0.544
        },
        {
            "name": "Batrider",
            "Vengeful Spirit": 0.373
        },
        {
            "name": "Beastmaster",
            "Vengeful Spirit": 1.227
        },
        {
            "name": "Bloodseeker",
            "Vengeful Spirit": 0.081
        },
        {
            "name": "Bounty Hunter",
            "Vengeful Spirit": 0.382
        },
        {
            "name": "Brewmaster",
            "Vengeful Spirit": 1.178
        },
        {
            "name": "Bristleback",
            "Vengeful Spirit": -0.385
        },
        {
            "name": "Broodmother",
            "Vengeful Spirit": 0.521
        },
        {
            "name": "Centaur Warrunner",
            "Vengeful Spirit": -0.344
        },
        {
            "name": "Chaos Knight",
            "Vengeful Spirit": 1.988
        },
        {
            "name": "Chen",
            "Vengeful Spirit": -0.013
        },
        {
            "name": "Clinkz",
            "Vengeful Spirit": -0.665
        },
        {
            "name": "Clockwerk",
            "Vengeful Spirit": 0.079
        },
        {
            "name": "Crystal Maiden",
            "Vengeful Spirit": 0.774
        },
        {
            "name": "Dark Seer",
            "Vengeful Spirit": 1.445
        },
        {
            "name": "Dark Willow",
            "Vengeful Spirit": 1.028
        },
        {
            "name": "Dawnbreaker",
            "Vengeful Spirit": 0.373
        },
        {
            "name": "Dazzle",
            "Vengeful Spirit": -0.069
        },
        {
            "name": "Death Prophet",
            "Vengeful Spirit": -0.468
        },
        {
            "name": "Disruptor",
            "Vengeful Spirit": -0.439
        },
        {
            "name": "Doom",
            "Vengeful Spirit": -0.226
        },
        {
            "name": "Dragon Knight",
            "Vengeful Spirit": 0.111
        },
        {
            "name": "Drow Ranger",
            "Vengeful Spirit": -0.082
        },
        {
            "name": "Earth Spirit",
            "Vengeful Spirit": -0.444
        },
        {
            "name": "Earthshaker",
            "Vengeful Spirit": -0.081
        },
        {
            "name": "Elder Titan",
            "Vengeful Spirit": -0.268
        },
        {
            "name": "Ember Spirit",
            "Vengeful Spirit": 0.68
        },
        {
            "name": "Enchantress",
            "Vengeful Spirit": 1.336
        },
        {
            "name": "Enigma",
            "Vengeful Spirit": -0.018
        },
        {
            "name": "Faceless Void",
            "Vengeful Spirit": -0.288
        },
        {
            "name": "Grimstroke",
            "Vengeful Spirit": -0.229
        },
        {
            "name": "Gyrocopter",
            "Vengeful Spirit": 0.645
        },
        {
            "name": "Hoodwink",
            "Vengeful Spirit": -0.264
        },
        {
            "name": "Huskar",
            "Vengeful Spirit": -0.59
        },
        {
            "name": "Invoker",
            "Vengeful Spirit": 0.088
        },
        {
            "name": "Io",
            "Vengeful Spirit": -0.579
        },
        {
            "name": "Jakiro",
            "Vengeful Spirit": 0.899
        },
        {
            "name": "Juggernaut",
            "Vengeful Spirit": 0.137
        },
        {
            "name": "Keeper of the Light",
            "Vengeful Spirit": 0.498
        },
        {
            "name": "Kez",
            "Vengeful Spirit": -0.288
        },
        {
            "name": "Kunkka",
            "Vengeful Spirit": 0.332
        },
        {
            "name": "Legion Commander",
            "Vengeful Spirit": -1.475
        },
        {
            "name": "Leshrac",
            "Vengeful Spirit": -0.162
        },
        {
            "name": "Lich",
            "Vengeful Spirit": 1.007
        },
        {
            "name": "Lifestealer",
            "Vengeful Spirit": 0.159
        },
        {
            "name": "Lina",
            "Vengeful Spirit": -0.206
        },
        {
            "name": "Lion",
            "Vengeful Spirit": -0.982
        },
        {
            "name": "Lone Druid",
            "Vengeful Spirit": 0.78
        },
        {
            "name": "Luna",
            "Vengeful Spirit": 0.457
        },
        {
            "name": "Lycan",
            "Vengeful Spirit": 0.492
        },
        {
            "name": "Magnus",
            "Vengeful Spirit": -0.212
        },
        {
            "name": "Marci",
            "Vengeful Spirit": 0.913
        },
        {
            "name": "Mars",
            "Vengeful Spirit": 0.037
        },
        {
            "name": "Medusa",
            "Vengeful Spirit": 0.675
        },
        {
            "name": "Meepo",
            "Vengeful Spirit": 1.711
        },
        {
            "name": "Mirana",
            "Vengeful Spirit": -0.076
        },
        {
            "name": "Monkey King",
            "Vengeful Spirit": -0.21
        },
        {
            "name": "Morphling",
            "Vengeful Spirit": 0.247
        },
        {
            "name": "Muerta",
            "Vengeful Spirit": 0.681
        },
        {
            "name": "Naga Siren",
            "Vengeful Spirit": 1.728
        },
        {
            "name": "Nature's Prophet",
            "Vengeful Spirit": -0.285
        },
        {
            "name": "Necrophos",
            "Vengeful Spirit": 0.894
        },
        {
            "name": "Night Stalker",
            "Vengeful Spirit": -1.077
        },
        {
            "name": "Nyx Assassin",
            "Vengeful Spirit": -0.586
        },
        {
            "name": "Ogre Magi",
            "Vengeful Spirit": -0.197
        },
        {
            "name": "Omniknight",
            "Vengeful Spirit": 1.602
        },
        {
            "name": "Oracle",
            "Vengeful Spirit": -1.214
        },
        {
            "name": "Outworld Destroyer",
            "Vengeful Spirit": 0.262
        },
        {
            "name": "Pangolier",
            "Vengeful Spirit": 0.607
        },
        {
            "name": "Phantom Assassin",
            "Vengeful Spirit": -0.502
        },
        {
            "name": "Phantom Lancer",
            "Vengeful Spirit": 4.163
        },
        {
            "name": "Phoenix",
            "Vengeful Spirit": 0.417
        },
        {
            "name": "Primal Beast",
            "Vengeful Spirit": 0.266
        },
        {
            "name": "Puck",
            "Vengeful Spirit": 0.954
        },
        {
            "name": "Pudge",
            "Vengeful Spirit": -0.755
        },
        {
            "name": "Pugna",
            "Vengeful Spirit": -0.072
        },
        {
            "name": "Queen of Pain",
            "Vengeful Spirit": -0.188
        },
        {
            "name": "Razor",
            "Vengeful Spirit": -0.116
        },
        {
            "name": "Riki",
            "Vengeful Spirit": -0.014
        },
        {
            "name": "Ringmaster",
            "Vengeful Spirit": -0.008
        },
        {
            "name": "Rubick",
            "Vengeful Spirit": 0.586
        },
        {
            "name": "Sand King",
            "Vengeful Spirit": 1.162
        },
        {
            "name": "Shadow Demon",
            "Vengeful Spirit": 0.418
        },
        {
            "name": "Shadow Fiend",
            "Vengeful Spirit": -0.804
        },
        {
            "name": "Shadow Shaman",
            "Vengeful Spirit": -0.735
        },
        {
            "name": "Silencer",
            "Vengeful Spirit": -0.277
        },
        {
            "name": "Skywrath Mage",
            "Vengeful Spirit": -0.639
        },
        {
            "name": "Slardar",
            "Vengeful Spirit": -0.369
        },
        {
            "name": "Slark",
            "Vengeful Spirit": -0.088
        },
        {
            "name": "Snapfire",
            "Vengeful Spirit": 0.357
        },
        {
            "name": "Sniper",
            "Vengeful Spirit": 0.121
        },
        {
            "name": "Spectre",
            "Vengeful Spirit": 0.408
        },
        {
            "name": "Spirit Breaker",
            "Vengeful Spirit": -0.072
        },
        {
            "name": "Storm Spirit",
            "Vengeful Spirit": 0.613
        },
        {
            "name": "Sven",
            "Vengeful Spirit": 0.089
        },
        {
            "name": "Techies",
            "Vengeful Spirit": -0.278
        },
        {
            "name": "Templar Assassin",
            "Vengeful Spirit": -0.19
        },
        {
            "name": "Terrorblade",
            "Vengeful Spirit": 2.684
        },
        {
            "name": "Tidehunter",
            "Vengeful Spirit": 1.28
        },
        {
            "name": "Timbersaw",
            "Vengeful Spirit": 0.249
        },
        {
            "name": "Tinker",
            "Vengeful Spirit": -0.19
        },
        {
            "name": "Tiny",
            "Vengeful Spirit": 0.604
        },
        {
            "name": "Treant Protector",
            "Vengeful Spirit": -0.039
        },
        {
            "name": "Troll Warlord",
            "Vengeful Spirit": 0.424
        },
        {
            "name": "Tusk",
            "Vengeful Spirit": -0.205
        },
        {
            "name": "Underlord",
            "Vengeful Spirit": 0.895
        },
        {
            "name": "Undying",
            "Vengeful Spirit": 0.358
        },
        {
            "name": "Ursa",
            "Vengeful Spirit": -0.185
        },
        {
            "name": "Venomancer",
            "Vengeful Spirit": 0.413
        },
        {
            "name": "Viper",
            "Vengeful Spirit": 0.262
        },
        {
            "name": "Visage",
            "Vengeful Spirit": -0.303
        },
        {
            "name": "Void Spirit",
            "Vengeful Spirit": -0.228
        },
        {
            "name": "Warlock",
            "Vengeful Spirit": 0.91
        },
        {
            "name": "Weaver",
            "Vengeful Spirit": -0.589
        },
        {
            "name": "Windranger",
            "Vengeful Spirit": 0.333
        },
        {
            "name": "Winter Wyvern",
            "Vengeful Spirit": 1.713
        },
        {
            "name": "Witch Doctor",
            "Vengeful Spirit": 0.028
        },
        {
            "name": "Wraith King",
            "Vengeful Spirit": -0.2
        },
        {
            "name": "Zeus",
            "Vengeful Spirit": -0.538
        }
    ],
    "Venomancer": [
        {
            "name": "Abaddon",
            "Venomancer": -0.126
        },
        {
            "name": "Alchemist",
            "Venomancer": -1.372
        },
        {
            "name": "Ancient Apparition",
            "Venomancer": 1.005
        },
        {
            "name": "Anti-Mage",
            "Venomancer": 0.774
        },
        {
            "name": "Arc Warden",
            "Venomancer": 0.84
        },
        {
            "name": "Axe",
            "Venomancer": -2.73
        },
        {
            "name": "Bane",
            "Venomancer": -0.831
        },
        {
            "name": "Batrider",
            "Venomancer": -0.042
        },
        {
            "name": "Beastmaster",
            "Venomancer": -0.59
        },
        {
            "name": "Bloodseeker",
            "Venomancer": -0.605
        },
        {
            "name": "Bounty Hunter",
            "Venomancer": -0.792
        },
        {
            "name": "Brewmaster",
            "Venomancer": 1.467
        },
        {
            "name": "Bristleback",
            "Venomancer": -1.783
        },
        {
            "name": "Broodmother",
            "Venomancer": 1.155
        },
        {
            "name": "Centaur Warrunner",
            "Venomancer": -0.987
        },
        {
            "name": "Chaos Knight",
            "Venomancer": -0.576
        },
        {
            "name": "Chen",
            "Venomancer": 0.264
        },
        {
            "name": "Clinkz",
            "Venomancer": 0.499
        },
        {
            "name": "Clockwerk",
            "Venomancer": -1.838
        },
        {
            "name": "Crystal Maiden",
            "Venomancer": 0.467
        },
        {
            "name": "Dark Seer",
            "Venomancer": 1.873
        },
        {
            "name": "Dark Willow",
            "Venomancer": -0.453
        },
        {
            "name": "Dawnbreaker",
            "Venomancer": -0.202
        },
        {
            "name": "Dazzle",
            "Venomancer": -1.266
        },
        {
            "name": "Death Prophet",
            "Venomancer": -0.862
        },
        {
            "name": "Disruptor",
            "Venomancer": -0.773
        },
        {
            "name": "Doom",
            "Venomancer": -0.964
        },
        {
            "name": "Dragon Knight",
            "Venomancer": -2.156
        },
        {
            "name": "Drow Ranger",
            "Venomancer": -0.842
        },
        {
            "name": "Earth Spirit",
            "Venomancer": 0.754
        },
        {
            "name": "Earthshaker",
            "Venomancer": -0.814
        },
        {
            "name": "Elder Titan",
            "Venomancer": -1.419
        },
        {
            "name": "Ember Spirit",
            "Venomancer": 0.149
        },
        {
            "name": "Enchantress",
            "Venomancer": 1.301
        },
        {
            "name": "Enigma",
            "Venomancer": 1.934
        },
        {
            "name": "Faceless Void",
            "Venomancer": 0.78
        },
        {
            "name": "Grimstroke",
            "Venomancer": -0.236
        },
        {
            "name": "Gyrocopter",
            "Venomancer": -0.513
        },
        {
            "name": "Hoodwink",
            "Venomancer": 0.086
        },
        {
            "name": "Huskar",
            "Venomancer": 0.52
        },
        {
            "name": "Invoker",
            "Venomancer": -0.016
        },
        {
            "name": "Io",
            "Venomancer": -1.369
        },
        {
            "name": "Jakiro",
            "Venomancer": -0.606
        },
        {
            "name": "Juggernaut",
            "Venomancer": 1.358
        },
        {
            "name": "Keeper of the Light",
            "Venomancer": 0.139
        },
        {
            "name": "Kez",
            "Venomancer": 0.543
        },
        {
            "name": "Kunkka",
            "Venomancer": -1.797
        },
        {
            "name": "Legion Commander",
            "Venomancer": 2.038
        },
        {
            "name": "Leshrac",
            "Venomancer": -1.104
        },
        {
            "name": "Lich",
            "Venomancer": -0.209
        },
        {
            "name": "Lifestealer",
            "Venomancer": -0.39
        },
        {
            "name": "Lina",
            "Venomancer": 0.048
        },
        {
            "name": "Lion",
            "Venomancer": -0.738
        },
        {
            "name": "Lone Druid",
            "Venomancer": -2.979
        },
        {
            "name": "Luna",
            "Venomancer": -0.529
        },
        {
            "name": "Lycan",
            "Venomancer": 2.039
        },
        {
            "name": "Magnus",
            "Venomancer": 1.158
        },
        {
            "name": "Marci",
            "Venomancer": 0.092
        },
        {
            "name": "Mars",
            "Venomancer": -1.226
        },
        {
            "name": "Medusa",
            "Venomancer": -0.163
        },
        {
            "name": "Meepo",
            "Venomancer": -1.399
        },
        {
            "name": "Mirana",
            "Venomancer": 1.339
        },
        {
            "name": "Monkey King",
            "Venomancer": -0.824
        },
        {
            "name": "Morphling",
            "Venomancer": 1.865
        },
        {
            "name": "Muerta",
            "Venomancer": 1.683
        },
        {
            "name": "Naga Siren",
            "Venomancer": 1.067
        },
        {
            "name": "Nature's Prophet",
            "Venomancer": 1.397
        },
        {
            "name": "Necrophos",
            "Venomancer": -0.871
        },
        {
            "name": "Night Stalker",
            "Venomancer": -2.165
        },
        {
            "name": "Nyx Assassin",
            "Venomancer": -0.055
        },
        {
            "name": "Ogre Magi",
            "Venomancer": -0.641
        },
        {
            "name": "Omniknight",
            "Venomancer": -0.598
        },
        {
            "name": "Oracle",
            "Venomancer": 0.843
        },
        {
            "name": "Outworld Destroyer",
            "Venomancer": -0.732
        },
        {
            "name": "Pangolier",
            "Venomancer": 0.013
        },
        {
            "name": "Phantom Assassin",
            "Venomancer": 1.529
        },
        {
            "name": "Phantom Lancer",
            "Venomancer": 2.898
        },
        {
            "name": "Phoenix",
            "Venomancer": 0.884
        },
        {
            "name": "Primal Beast",
            "Venomancer": -0.173
        },
        {
            "name": "Puck",
            "Venomancer": 0.647
        },
        {
            "name": "Pudge",
            "Venomancer": 0.418
        },
        {
            "name": "Pugna",
            "Venomancer": 0.692
        },
        {
            "name": "Queen of Pain",
            "Venomancer": 0.69
        },
        {
            "name": "Razor",
            "Venomancer": 0.128
        },
        {
            "name": "Riki",
            "Venomancer": -0.333
        },
        {
            "name": "Ringmaster",
            "Venomancer": -0.389
        },
        {
            "name": "Rubick",
            "Venomancer": 0.244
        },
        {
            "name": "Sand King",
            "Venomancer": -0.293
        },
        {
            "name": "Shadow Demon",
            "Venomancer": 0.952
        },
        {
            "name": "Shadow Fiend",
            "Venomancer": -0.49
        },
        {
            "name": "Shadow Shaman",
            "Venomancer": -0.067
        },
        {
            "name": "Silencer",
            "Venomancer": -0.682
        },
        {
            "name": "Skywrath Mage",
            "Venomancer": 0.006
        },
        {
            "name": "Slardar",
            "Venomancer": -1.371
        },
        {
            "name": "Slark",
            "Venomancer": 1.707
        },
        {
            "name": "Snapfire",
            "Venomancer": 0.651
        },
        {
            "name": "Sniper",
            "Venomancer": 1.717
        },
        {
            "name": "Spectre",
            "Venomancer": -1.388
        },
        {
            "name": "Spirit Breaker",
            "Venomancer": -1.43
        },
        {
            "name": "Storm Spirit",
            "Venomancer": 2.59
        },
        {
            "name": "Sven",
            "Venomancer": -0.283
        },
        {
            "name": "Techies",
            "Venomancer": 0.816
        },
        {
            "name": "Templar Assassin",
            "Venomancer": -1.349
        },
        {
            "name": "Terrorblade",
            "Venomancer": 1.235
        },
        {
            "name": "Tidehunter",
            "Venomancer": -1.183
        },
        {
            "name": "Timbersaw",
            "Venomancer": 0.87
        },
        {
            "name": "Tinker",
            "Venomancer": 2.186
        },
        {
            "name": "Tiny",
            "Venomancer": -1.753
        },
        {
            "name": "Treant Protector",
            "Venomancer": -0.007
        },
        {
            "name": "Troll Warlord",
            "Venomancer": -0.077
        },
        {
            "name": "Tusk",
            "Venomancer": -0.227
        },
        {
            "name": "Underlord",
            "Venomancer": -0.104
        },
        {
            "name": "Undying",
            "Venomancer": -1.566
        },
        {
            "name": "Ursa",
            "Venomancer": -1.973
        },
        {
            "name": "Vengeful Spirit",
            "Venomancer": -0.422
        },
        {
            "name": "Viper",
            "Venomancer": 0.818
        },
        {
            "name": "Visage",
            "Venomancer": -0.023
        },
        {
            "name": "Void Spirit",
            "Venomancer": 0.328
        },
        {
            "name": "Warlock",
            "Venomancer": -0.723
        },
        {
            "name": "Weaver",
            "Venomancer": 2.236
        },
        {
            "name": "Windranger",
            "Venomancer": 1.581
        },
        {
            "name": "Winter Wyvern",
            "Venomancer": -0.645
        },
        {
            "name": "Witch Doctor",
            "Venomancer": 0.601
        },
        {
            "name": "Wraith King",
            "Venomancer": -1.831
        },
        {
            "name": "Zeus",
            "Venomancer": -0.2
        }
    ],
    "Visage": [
        {
            "name": "Abaddon",
            "Visage": -0.27
        },
        {
            "name": "Alchemist",
            "Visage": 1.461
        },
        {
            "name": "Ancient Apparition",
            "Visage": -1.038
        },
        {
            "name": "Anti-Mage",
            "Visage": 0.51
        },
        {
            "name": "Arc Warden",
            "Visage": 0.613
        },
        {
            "name": "Axe",
            "Visage": 2.685
        },
        {
            "name": "Bane",
            "Visage": -1.309
        },
        {
            "name": "Batrider",
            "Visage": 0.864
        },
        {
            "name": "Beastmaster",
            "Visage": 1.713
        },
        {
            "name": "Bloodseeker",
            "Visage": 0.047
        },
        {
            "name": "Bounty Hunter",
            "Visage": 0.036
        },
        {
            "name": "Brewmaster",
            "Visage": 2.383
        },
        {
            "name": "Bristleback",
            "Visage": 3.257
        },
        {
            "name": "Broodmother",
            "Visage": 1.118
        },
        {
            "name": "Centaur Warrunner",
            "Visage": -0.7
        },
        {
            "name": "Chaos Knight",
            "Visage": 0.763
        },
        {
            "name": "Chen",
            "Visage": 3.358
        },
        {
            "name": "Clinkz",
            "Visage": 1.043
        },
        {
            "name": "Clockwerk",
            "Visage": -2.156
        },
        {
            "name": "Crystal Maiden",
            "Visage": 0.13
        },
        {
            "name": "Dark Seer",
            "Visage": 1.997
        },
        {
            "name": "Dark Willow",
            "Visage": 2.298
        },
        {
            "name": "Dawnbreaker",
            "Visage": -1.333
        },
        {
            "name": "Dazzle",
            "Visage": 1.351
        },
        {
            "name": "Death Prophet",
            "Visage": -0.061
        },
        {
            "name": "Disruptor",
            "Visage": 1.189
        },
        {
            "name": "Doom",
            "Visage": -1.469
        },
        {
            "name": "Dragon Knight",
            "Visage": 0.345
        },
        {
            "name": "Drow Ranger",
            "Visage": -0.078
        },
        {
            "name": "Earth Spirit",
            "Visage": 0.716
        },
        {
            "name": "Earthshaker",
            "Visage": 2.784
        },
        {
            "name": "Elder Titan",
            "Visage": -2.653
        },
        {
            "name": "Ember Spirit",
            "Visage": -0.431
        },
        {
            "name": "Enchantress",
            "Visage": 0.88
        },
        {
            "name": "Enigma",
            "Visage": 1.845
        },
        {
            "name": "Faceless Void",
            "Visage": -0.209
        },
        {
            "name": "Grimstroke",
            "Visage": -1.334
        },
        {
            "name": "Gyrocopter",
            "Visage": 1.304
        },
        {
            "name": "Hoodwink",
            "Visage": 2.028
        },
        {
            "name": "Huskar",
            "Visage": -1.38
        },
        {
            "name": "Invoker",
            "Visage": -0.69
        },
        {
            "name": "Io",
            "Visage": 0.97
        },
        {
            "name": "Jakiro",
            "Visage": 0.693
        },
        {
            "name": "Juggernaut",
            "Visage": -2.992
        },
        {
            "name": "Keeper of the Light",
            "Visage": 0.448
        },
        {
            "name": "Kez",
            "Visage": 1.06
        },
        {
            "name": "Kunkka",
            "Visage": 0.696
        },
        {
            "name": "Legion Commander",
            "Visage": -0.053
        },
        {
            "name": "Leshrac",
            "Visage": 0.235
        },
        {
            "name": "Lich",
            "Visage": -0.665
        },
        {
            "name": "Lifestealer",
            "Visage": -2.619
        },
        {
            "name": "Lina",
            "Visage": -1.003
        },
        {
            "name": "Lion",
            "Visage": -1.464
        },
        {
            "name": "Lone Druid",
            "Visage": 0.049
        },
        {
            "name": "Luna",
            "Visage": 1.864
        },
        {
            "name": "Lycan",
            "Visage": 1.695
        },
        {
            "name": "Magnus",
            "Visage": 0.413
        },
        {
            "name": "Marci",
            "Visage": 2.477
        },
        {
            "name": "Mars",
            "Visage": -0.012
        },
        {
            "name": "Medusa",
            "Visage": 3.167
        },
        {
            "name": "Meepo",
            "Visage": 0.737
        },
        {
            "name": "Mirana",
            "Visage": 0.833
        },
        {
            "name": "Monkey King",
            "Visage": -0.465
        },
        {
            "name": "Morphling",
            "Visage": -0.539
        },
        {
            "name": "Muerta",
            "Visage": -0.388
        },
        {
            "name": "Naga Siren",
            "Visage": 5.873
        },
        {
            "name": "Nature's Prophet",
            "Visage": -0.499
        },
        {
            "name": "Necrophos",
            "Visage": -2.746
        },
        {
            "name": "Night Stalker",
            "Visage": -1.207
        },
        {
            "name": "Nyx Assassin",
            "Visage": -1.602
        },
        {
            "name": "Ogre Magi",
            "Visage": -0.441
        },
        {
            "name": "Omniknight",
            "Visage": -0.72
        },
        {
            "name": "Oracle",
            "Visage": -1.847
        },
        {
            "name": "Outworld Destroyer",
            "Visage": -3.38
        },
        {
            "name": "Pangolier",
            "Visage": 1.959
        },
        {
            "name": "Phantom Assassin",
            "Visage": 2.071
        },
        {
            "name": "Phantom Lancer",
            "Visage": 4.565
        },
        {
            "name": "Phoenix",
            "Visage": -0.355
        },
        {
            "name": "Primal Beast",
            "Visage": -0.643
        },
        {
            "name": "Puck",
            "Visage": 0.537
        },
        {
            "name": "Pudge",
            "Visage": -1.033
        },
        {
            "name": "Pugna",
            "Visage": -0.368
        },
        {
            "name": "Queen of Pain",
            "Visage": -0.55
        },
        {
            "name": "Razor",
            "Visage": 1.794
        },
        {
            "name": "Riki",
            "Visage": 2.53
        },
        {
            "name": "Ringmaster",
            "Visage": 0.086
        },
        {
            "name": "Rubick",
            "Visage": -1.186
        },
        {
            "name": "Sand King",
            "Visage": 1.238
        },
        {
            "name": "Shadow Demon",
            "Visage": 1.173
        },
        {
            "name": "Shadow Fiend",
            "Visage": -0.629
        },
        {
            "name": "Shadow Shaman",
            "Visage": -0.228
        },
        {
            "name": "Silencer",
            "Visage": -0.552
        },
        {
            "name": "Skywrath Mage",
            "Visage": -1.34
        },
        {
            "name": "Slardar",
            "Visage": -0.561
        },
        {
            "name": "Slark",
            "Visage": -0.144
        },
        {
            "name": "Snapfire",
            "Visage": -0.135
        },
        {
            "name": "Sniper",
            "Visage": 1.298
        },
        {
            "name": "Spectre",
            "Visage": -1.337
        },
        {
            "name": "Spirit Breaker",
            "Visage": -0.157
        },
        {
            "name": "Storm Spirit",
            "Visage": -0.985
        },
        {
            "name": "Sven",
            "Visage": 2.425
        },
        {
            "name": "Techies",
            "Visage": -0.807
        },
        {
            "name": "Templar Assassin",
            "Visage": 0.304
        },
        {
            "name": "Terrorblade",
            "Visage": 1.633
        },
        {
            "name": "Tidehunter",
            "Visage": 0.47
        },
        {
            "name": "Timbersaw",
            "Visage": -0.762
        },
        {
            "name": "Tinker",
            "Visage": -0.908
        },
        {
            "name": "Tiny",
            "Visage": 0.388
        },
        {
            "name": "Treant Protector",
            "Visage": 1.596
        },
        {
            "name": "Troll Warlord",
            "Visage": 1.315
        },
        {
            "name": "Tusk",
            "Visage": 0.336
        },
        {
            "name": "Underlord",
            "Visage": 2.424
        },
        {
            "name": "Undying",
            "Visage": 1.707
        },
        {
            "name": "Ursa",
            "Visage": -0.971
        },
        {
            "name": "Vengeful Spirit",
            "Visage": 0.262
        },
        {
            "name": "Venomancer",
            "Visage": -0.172
        },
        {
            "name": "Viper",
            "Visage": 0.393
        },
        {
            "name": "Void Spirit",
            "Visage": 0.054
        },
        {
            "name": "Warlock",
            "Visage": 2.092
        },
        {
            "name": "Weaver",
            "Visage": 1.483
        },
        {
            "name": "Windranger",
            "Visage": 1.266
        },
        {
            "name": "Winter Wyvern",
            "Visage": 3.183
        },
        {
            "name": "Witch Doctor",
            "Visage": 0.024
        },
        {
            "name": "Wraith King",
            "Visage": -1.203
        },
        {
            "name": "Zeus",
            "Visage": -2.104
        }
    ],
    "Void Spirit": [
        {
            "name": "Abaddon",
            "Void Spirit": 0.941
        },
        {
            "name": "Alchemist",
            "Void Spirit": 1.51
        },
        {
            "name": "Ancient Apparition",
            "Void Spirit": -1.6
        },
        {
            "name": "Anti-Mage",
            "Void Spirit": 1.503
        },
        {
            "name": "Arc Warden",
            "Void Spirit": -0.106
        },
        {
            "name": "Axe",
            "Void Spirit": 1.38
        },
        {
            "name": "Bane",
            "Void Spirit": 0.307
        },
        {
            "name": "Batrider",
            "Void Spirit": 0.174
        },
        {
            "name": "Beastmaster",
            "Void Spirit": 0.97
        },
        {
            "name": "Bloodseeker",
            "Void Spirit": 1.044
        },
        {
            "name": "Bounty Hunter",
            "Void Spirit": -0.407
        },
        {
            "name": "Brewmaster",
            "Void Spirit": -1.013
        },
        {
            "name": "Bristleback",
            "Void Spirit": -1.082
        },
        {
            "name": "Broodmother",
            "Void Spirit": 2.785
        },
        {
            "name": "Centaur Warrunner",
            "Void Spirit": 0.296
        },
        {
            "name": "Chaos Knight",
            "Void Spirit": 0.408
        },
        {
            "name": "Chen",
            "Void Spirit": 3.546
        },
        {
            "name": "Clinkz",
            "Void Spirit": -1.802
        },
        {
            "name": "Clockwerk",
            "Void Spirit": -0.559
        },
        {
            "name": "Crystal Maiden",
            "Void Spirit": 0.075
        },
        {
            "name": "Dark Seer",
            "Void Spirit": -0.26
        },
        {
            "name": "Dark Willow",
            "Void Spirit": -0.266
        },
        {
            "name": "Dawnbreaker",
            "Void Spirit": 0.812
        },
        {
            "name": "Dazzle",
            "Void Spirit": 1.046
        },
        {
            "name": "Death Prophet",
            "Void Spirit": -0.08
        },
        {
            "name": "Disruptor",
            "Void Spirit": 1.109
        },
        {
            "name": "Doom",
            "Void Spirit": 0.342
        },
        {
            "name": "Dragon Knight",
            "Void Spirit": 2.243
        },
        {
            "name": "Drow Ranger",
            "Void Spirit": -0.853
        },
        {
            "name": "Earth Spirit",
            "Void Spirit": -0.973
        },
        {
            "name": "Earthshaker",
            "Void Spirit": 1.069
        },
        {
            "name": "Elder Titan",
            "Void Spirit": 1.927
        },
        {
            "name": "Ember Spirit",
            "Void Spirit": 1.916
        },
        {
            "name": "Enchantress",
            "Void Spirit": 2.516
        },
        {
            "name": "Enigma",
            "Void Spirit": 0.987
        },
        {
            "name": "Faceless Void",
            "Void Spirit": 0.331
        },
        {
            "name": "Grimstroke",
            "Void Spirit": -0.063
        },
        {
            "name": "Gyrocopter",
            "Void Spirit": 1.671
        },
        {
            "name": "Hoodwink",
            "Void Spirit": -0.707
        },
        {
            "name": "Huskar",
            "Void Spirit": 4.182
        },
        {
            "name": "Invoker",
            "Void Spirit": -1.976
        },
        {
            "name": "Io",
            "Void Spirit": 1.808
        },
        {
            "name": "Jakiro",
            "Void Spirit": 0.151
        },
        {
            "name": "Juggernaut",
            "Void Spirit": -2.454
        },
        {
            "name": "Keeper of the Light",
            "Void Spirit": -1.791
        },
        {
            "name": "Kez",
            "Void Spirit": 1.037
        },
        {
            "name": "Kunkka",
            "Void Spirit": -0.029
        },
        {
            "name": "Legion Commander",
            "Void Spirit": 0.485
        },
        {
            "name": "Leshrac",
            "Void Spirit": 0.111
        },
        {
            "name": "Lich",
            "Void Spirit": 0.146
        },
        {
            "name": "Lifestealer",
            "Void Spirit": -0.394
        },
        {
            "name": "Lina",
            "Void Spirit": -0.712
        },
        {
            "name": "Lion",
            "Void Spirit": 0.717
        },
        {
            "name": "Lone Druid",
            "Void Spirit": 3.355
        },
        {
            "name": "Luna",
            "Void Spirit": 0.93
        },
        {
            "name": "Lycan",
            "Void Spirit": 2.324
        },
        {
            "name": "Magnus",
            "Void Spirit": -0.764
        },
        {
            "name": "Marci",
            "Void Spirit": 0.419
        },
        {
            "name": "Mars",
            "Void Spirit": -2.591
        },
        {
            "name": "Medusa",
            "Void Spirit": 0.571
        },
        {
            "name": "Meepo",
            "Void Spirit": 5.233
        },
        {
            "name": "Mirana",
            "Void Spirit": 0.138
        },
        {
            "name": "Monkey King",
            "Void Spirit": 2.517
        },
        {
            "name": "Morphling",
            "Void Spirit": 0.149
        },
        {
            "name": "Muerta",
            "Void Spirit": 0.763
        },
        {
            "name": "Naga Siren",
            "Void Spirit": 0.832
        },
        {
            "name": "Nature's Prophet",
            "Void Spirit": -0.764
        },
        {
            "name": "Necrophos",
            "Void Spirit": 0.848
        },
        {
            "name": "Night Stalker",
            "Void Spirit": 3.01
        },
        {
            "name": "Nyx Assassin",
            "Void Spirit": -0.202
        },
        {
            "name": "Ogre Magi",
            "Void Spirit": 0.519
        },
        {
            "name": "Omniknight",
            "Void Spirit": 3.107
        },
        {
            "name": "Oracle",
            "Void Spirit": 1.611
        },
        {
            "name": "Outworld Destroyer",
            "Void Spirit": -0.486
        },
        {
            "name": "Pangolier",
            "Void Spirit": 2.055
        },
        {
            "name": "Phantom Assassin",
            "Void Spirit": -0.514
        },
        {
            "name": "Phantom Lancer",
            "Void Spirit": 0.804
        },
        {
            "name": "Phoenix",
            "Void Spirit": -0.479
        },
        {
            "name": "Primal Beast",
            "Void Spirit": -0.616
        },
        {
            "name": "Puck",
            "Void Spirit": -1.818
        },
        {
            "name": "Pudge",
            "Void Spirit": -1.829
        },
        {
            "name": "Pugna",
            "Void Spirit": 0.919
        },
        {
            "name": "Queen of Pain",
            "Void Spirit": -1.8
        },
        {
            "name": "Razor",
            "Void Spirit": -1.238
        },
        {
            "name": "Riki",
            "Void Spirit": 2.51
        },
        {
            "name": "Ringmaster",
            "Void Spirit": -0.939
        },
        {
            "name": "Rubick",
            "Void Spirit": 0.187
        },
        {
            "name": "Sand King",
            "Void Spirit": -0.559
        },
        {
            "name": "Shadow Demon",
            "Void Spirit": -1.031
        },
        {
            "name": "Shadow Fiend",
            "Void Spirit": 1.165
        },
        {
            "name": "Shadow Shaman",
            "Void Spirit": 0.545
        },
        {
            "name": "Silencer",
            "Void Spirit": -0.354
        },
        {
            "name": "Skywrath Mage",
            "Void Spirit": -0.959
        },
        {
            "name": "Slardar",
            "Void Spirit": 1.193
        },
        {
            "name": "Slark",
            "Void Spirit": 0.377
        },
        {
            "name": "Snapfire",
            "Void Spirit": 0.629
        },
        {
            "name": "Sniper",
            "Void Spirit": -1.718
        },
        {
            "name": "Spectre",
            "Void Spirit": -0.538
        },
        {
            "name": "Spirit Breaker",
            "Void Spirit": 0.134
        },
        {
            "name": "Storm Spirit",
            "Void Spirit": 0.049
        },
        {
            "name": "Sven",
            "Void Spirit": 2.578
        },
        {
            "name": "Techies",
            "Void Spirit": -0.358
        },
        {
            "name": "Templar Assassin",
            "Void Spirit": 0.401
        },
        {
            "name": "Terrorblade",
            "Void Spirit": 1.374
        },
        {
            "name": "Tidehunter",
            "Void Spirit": 1.242
        },
        {
            "name": "Timbersaw",
            "Void Spirit": 0.504
        },
        {
            "name": "Tinker",
            "Void Spirit": 1.631
        },
        {
            "name": "Tiny",
            "Void Spirit": 1.152
        },
        {
            "name": "Treant Protector",
            "Void Spirit": 0.308
        },
        {
            "name": "Troll Warlord",
            "Void Spirit": 2.212
        },
        {
            "name": "Tusk",
            "Void Spirit": 0.528
        },
        {
            "name": "Underlord",
            "Void Spirit": 1.611
        },
        {
            "name": "Undying",
            "Void Spirit": -0.262
        },
        {
            "name": "Ursa",
            "Void Spirit": 0.991
        },
        {
            "name": "Vengeful Spirit",
            "Void Spirit": 0.196
        },
        {
            "name": "Venomancer",
            "Void Spirit": -0.49
        },
        {
            "name": "Viper",
            "Void Spirit": -1.23
        },
        {
            "name": "Visage",
            "Void Spirit": -0.091
        },
        {
            "name": "Warlock",
            "Void Spirit": 0.039
        },
        {
            "name": "Weaver",
            "Void Spirit": -1.243
        },
        {
            "name": "Windranger",
            "Void Spirit": -0.911
        },
        {
            "name": "Winter Wyvern",
            "Void Spirit": 1.002
        },
        {
            "name": "Witch Doctor",
            "Void Spirit": -0.608
        },
        {
            "name": "Wraith King",
            "Void Spirit": 0.72
        },
        {
            "name": "Zeus",
            "Void Spirit": -1.611
        }
    ],
    "Windranger": [
        {
            "name": "Abaddon",
            "Windranger": -0.49
        },
        {
            "name": "Alchemist",
            "Windranger": 0.112
        },
        {
            "name": "Ancient Apparition",
            "Windranger": -1.417
        },
        {
            "name": "Anti-Mage",
            "Windranger": -0.757
        },
        {
            "name": "Arc Warden",
            "Windranger": -0.833
        },
        {
            "name": "Axe",
            "Windranger": 2.391
        },
        {
            "name": "Bane",
            "Windranger": 0.238
        },
        {
            "name": "Batrider",
            "Windranger": 0.408
        },
        {
            "name": "Beastmaster",
            "Windranger": -1.231
        },
        {
            "name": "Bloodseeker",
            "Windranger": 0.273
        },
        {
            "name": "Bounty Hunter",
            "Windranger": -0.292
        },
        {
            "name": "Brewmaster",
            "Windranger": -1.405
        },
        {
            "name": "Bristleback",
            "Windranger": 1.179
        },
        {
            "name": "Broodmother",
            "Windranger": -0.558
        },
        {
            "name": "Centaur Warrunner",
            "Windranger": 3.042
        },
        {
            "name": "Chaos Knight",
            "Windranger": -0.485
        },
        {
            "name": "Chen",
            "Windranger": -3.201
        },
        {
            "name": "Clinkz",
            "Windranger": -1.243
        },
        {
            "name": "Clockwerk",
            "Windranger": 1.877
        },
        {
            "name": "Crystal Maiden",
            "Windranger": 0.506
        },
        {
            "name": "Dark Seer",
            "Windranger": -1.357
        },
        {
            "name": "Dark Willow",
            "Windranger": -0.051
        },
        {
            "name": "Dawnbreaker",
            "Windranger": -0.397
        },
        {
            "name": "Dazzle",
            "Windranger": -0.575
        },
        {
            "name": "Death Prophet",
            "Windranger": -1.354
        },
        {
            "name": "Disruptor",
            "Windranger": -0.214
        },
        {
            "name": "Doom",
            "Windranger": -1.182
        },
        {
            "name": "Dragon Knight",
            "Windranger": 0.284
        },
        {
            "name": "Drow Ranger",
            "Windranger": 0.356
        },
        {
            "name": "Earth Spirit",
            "Windranger": 0.405
        },
        {
            "name": "Earthshaker",
            "Windranger": -0.787
        },
        {
            "name": "Elder Titan",
            "Windranger": -2.696
        },
        {
            "name": "Ember Spirit",
            "Windranger": -0.71
        },
        {
            "name": "Enchantress",
            "Windranger": -0.499
        },
        {
            "name": "Enigma",
            "Windranger": 0.043
        },
        {
            "name": "Faceless Void",
            "Windranger": 1.907
        },
        {
            "name": "Grimstroke",
            "Windranger": -0.946
        },
        {
            "name": "Gyrocopter",
            "Windranger": 0.514
        },
        {
            "name": "Hoodwink",
            "Windranger": -0.609
        },
        {
            "name": "Huskar",
            "Windranger": -3.817
        },
        {
            "name": "Invoker",
            "Windranger": -0.953
        },
        {
            "name": "Io",
            "Windranger": -2.399
        },
        {
            "name": "Jakiro",
            "Windranger": -0.562
        },
        {
            "name": "Juggernaut",
            "Windranger": -1.556
        },
        {
            "name": "Keeper of the Light",
            "Windranger": -1.147
        },
        {
            "name": "Kez",
            "Windranger": -1.636
        },
        {
            "name": "Kunkka",
            "Windranger": -0.079
        },
        {
            "name": "Legion Commander",
            "Windranger": 0.118
        },
        {
            "name": "Leshrac",
            "Windranger": -0.596
        },
        {
            "name": "Lich",
            "Windranger": -0.505
        },
        {
            "name": "Lifestealer",
            "Windranger": -1.779
        },
        {
            "name": "Lina",
            "Windranger": -1.474
        },
        {
            "name": "Lion",
            "Windranger": -0.088
        },
        {
            "name": "Lone Druid",
            "Windranger": -3.413
        },
        {
            "name": "Luna",
            "Windranger": 0.521
        },
        {
            "name": "Lycan",
            "Windranger": -1.183
        },
        {
            "name": "Magnus",
            "Windranger": -0.682
        },
        {
            "name": "Marci",
            "Windranger": -2.46
        },
        {
            "name": "Mars",
            "Windranger": 1.837
        },
        {
            "name": "Medusa",
            "Windranger": -1.883
        },
        {
            "name": "Meepo",
            "Windranger": -1.511
        },
        {
            "name": "Mirana",
            "Windranger": 0.44
        },
        {
            "name": "Monkey King",
            "Windranger": -1.998
        },
        {
            "name": "Morphling",
            "Windranger": -0.566
        },
        {
            "name": "Muerta",
            "Windranger": -0.309
        },
        {
            "name": "Naga Siren",
            "Windranger": 0.458
        },
        {
            "name": "Nature's Prophet",
            "Windranger": 0.295
        },
        {
            "name": "Necrophos",
            "Windranger": 0.753
        },
        {
            "name": "Night Stalker",
            "Windranger": -1.455
        },
        {
            "name": "Nyx Assassin",
            "Windranger": 0.75
        },
        {
            "name": "Ogre Magi",
            "Windranger": 0.798
        },
        {
            "name": "Omniknight",
            "Windranger": 0.021
        },
        {
            "name": "Oracle",
            "Windranger": -0.698
        },
        {
            "name": "Outworld Destroyer",
            "Windranger": -0.008
        },
        {
            "name": "Pangolier",
            "Windranger": 0.634
        },
        {
            "name": "Phantom Assassin",
            "Windranger": -0.689
        },
        {
            "name": "Phantom Lancer",
            "Windranger": -0.578
        },
        {
            "name": "Phoenix",
            "Windranger": -0.243
        },
        {
            "name": "Primal Beast",
            "Windranger": 0.388
        },
        {
            "name": "Puck",
            "Windranger": 1.024
        },
        {
            "name": "Pudge",
            "Windranger": 0.355
        },
        {
            "name": "Pugna",
            "Windranger": 0.39
        },
        {
            "name": "Queen of Pain",
            "Windranger": 1.478
        },
        {
            "name": "Razor",
            "Windranger": 0.845
        },
        {
            "name": "Riki",
            "Windranger": -0.842
        },
        {
            "name": "Ringmaster",
            "Windranger": -0.19
        },
        {
            "name": "Rubick",
            "Windranger": -0.015
        },
        {
            "name": "Sand King",
            "Windranger": 0.828
        },
        {
            "name": "Shadow Demon",
            "Windranger": 0.433
        },
        {
            "name": "Shadow Fiend",
            "Windranger": -1.227
        },
        {
            "name": "Shadow Shaman",
            "Windranger": -0.066
        },
        {
            "name": "Silencer",
            "Windranger": -0.939
        },
        {
            "name": "Skywrath Mage",
            "Windranger": -0.83
        },
        {
            "name": "Slardar",
            "Windranger": -1.063
        },
        {
            "name": "Slark",
            "Windranger": -0.59
        },
        {
            "name": "Snapfire",
            "Windranger": -0.74
        },
        {
            "name": "Sniper",
            "Windranger": -0.813
        },
        {
            "name": "Spectre",
            "Windranger": 1.75
        },
        {
            "name": "Spirit Breaker",
            "Windranger": 0.417
        },
        {
            "name": "Storm Spirit",
            "Windranger": -0.758
        },
        {
            "name": "Sven",
            "Windranger": -1.13
        },
        {
            "name": "Techies",
            "Windranger": 0.644
        },
        {
            "name": "Templar Assassin",
            "Windranger": -0.65
        },
        {
            "name": "Terrorblade",
            "Windranger": -0.942
        },
        {
            "name": "Tidehunter",
            "Windranger": -0.317
        },
        {
            "name": "Timbersaw",
            "Windranger": -0.719
        },
        {
            "name": "Tinker",
            "Windranger": -0.861
        },
        {
            "name": "Tiny",
            "Windranger": 1.201
        },
        {
            "name": "Treant Protector",
            "Windranger": -0.039
        },
        {
            "name": "Troll Warlord",
            "Windranger": -1.87
        },
        {
            "name": "Tusk",
            "Windranger": 0.183
        },
        {
            "name": "Underlord",
            "Windranger": -0.34
        },
        {
            "name": "Undying",
            "Windranger": -0.312
        },
        {
            "name": "Ursa",
            "Windranger": -2.452
        },
        {
            "name": "Vengeful Spirit",
            "Windranger": -0.244
        },
        {
            "name": "Venomancer",
            "Windranger": -1.505
        },
        {
            "name": "Viper",
            "Windranger": -2.946
        },
        {
            "name": "Visage",
            "Windranger": -1.651
        },
        {
            "name": "Void Spirit",
            "Windranger": 0.559
        },
        {
            "name": "Warlock",
            "Windranger": -0.501
        },
        {
            "name": "Weaver",
            "Windranger": 0.301
        },
        {
            "name": "Winter Wyvern",
            "Windranger": -1.259
        },
        {
            "name": "Witch Doctor",
            "Windranger": -0.827
        },
        {
            "name": "Wraith King",
            "Windranger": 0.442
        },
        {
            "name": "Zeus",
            "Windranger": 0.623
        }
    ],
    "Winter Wyvern": [
        {
            "name": "Abaddon",
            "Winter Wyvern": 0.066
        },
        {
            "name": "Alchemist",
            "Winter Wyvern": -0.331
        },
        {
            "name": "Ancient Apparition",
            "Winter Wyvern": 2.539
        },
        {
            "name": "Anti-Mage",
            "Winter Wyvern": 2.665
        },
        {
            "name": "Arc Warden",
            "Winter Wyvern": -0.02
        },
        {
            "name": "Axe",
            "Winter Wyvern": 0.469
        },
        {
            "name": "Bane",
            "Winter Wyvern": -2.092
        },
        {
            "name": "Batrider",
            "Winter Wyvern": -0.444
        },
        {
            "name": "Beastmaster",
            "Winter Wyvern": -1.356
        },
        {
            "name": "Bloodseeker",
            "Winter Wyvern": 0.338
        },
        {
            "name": "Bounty Hunter",
            "Winter Wyvern": 1.292
        },
        {
            "name": "Brewmaster",
            "Winter Wyvern": -0.107
        },
        {
            "name": "Bristleback",
            "Winter Wyvern": 0.042
        },
        {
            "name": "Broodmother",
            "Winter Wyvern": -2.937
        },
        {
            "name": "Centaur Warrunner",
            "Winter Wyvern": 0.39
        },
        {
            "name": "Chaos Knight",
            "Winter Wyvern": -1.125
        },
        {
            "name": "Chen",
            "Winter Wyvern": -1.327
        },
        {
            "name": "Clinkz",
            "Winter Wyvern": -1.496
        },
        {
            "name": "Clockwerk",
            "Winter Wyvern": -1.061
        },
        {
            "name": "Crystal Maiden",
            "Winter Wyvern": 0.974
        },
        {
            "name": "Dark Seer",
            "Winter Wyvern": 0.375
        },
        {
            "name": "Dark Willow",
            "Winter Wyvern": 0.06
        },
        {
            "name": "Dawnbreaker",
            "Winter Wyvern": -2.782
        },
        {
            "name": "Dazzle",
            "Winter Wyvern": -1.162
        },
        {
            "name": "Death Prophet",
            "Winter Wyvern": 1.654
        },
        {
            "name": "Disruptor",
            "Winter Wyvern": -0.84
        },
        {
            "name": "Doom",
            "Winter Wyvern": 1.775
        },
        {
            "name": "Dragon Knight",
            "Winter Wyvern": -2.001
        },
        {
            "name": "Drow Ranger",
            "Winter Wyvern": -1.16
        },
        {
            "name": "Earth Spirit",
            "Winter Wyvern": 0.249
        },
        {
            "name": "Earthshaker",
            "Winter Wyvern": -1.058
        },
        {
            "name": "Elder Titan",
            "Winter Wyvern": -1.256
        },
        {
            "name": "Ember Spirit",
            "Winter Wyvern": -0.568
        },
        {
            "name": "Enchantress",
            "Winter Wyvern": 1.231
        },
        {
            "name": "Enigma",
            "Winter Wyvern": -1.851
        },
        {
            "name": "Faceless Void",
            "Winter Wyvern": 0.383
        },
        {
            "name": "Grimstroke",
            "Winter Wyvern": 0.083
        },
        {
            "name": "Gyrocopter",
            "Winter Wyvern": 1.75
        },
        {
            "name": "Hoodwink",
            "Winter Wyvern": 0.109
        },
        {
            "name": "Huskar",
            "Winter Wyvern": 1.765
        },
        {
            "name": "Invoker",
            "Winter Wyvern": -1.385
        },
        {
            "name": "Io",
            "Winter Wyvern": -2.288
        },
        {
            "name": "Jakiro",
            "Winter Wyvern": -0.02
        },
        {
            "name": "Juggernaut",
            "Winter Wyvern": 0.486
        },
        {
            "name": "Keeper of the Light",
            "Winter Wyvern": 1.894
        },
        {
            "name": "Kez",
            "Winter Wyvern": -1.248
        },
        {
            "name": "Kunkka",
            "Winter Wyvern": -0.842
        },
        {
            "name": "Legion Commander",
            "Winter Wyvern": -3.442
        },
        {
            "name": "Leshrac",
            "Winter Wyvern": 2.652
        },
        {
            "name": "Lich",
            "Winter Wyvern": -0.521
        },
        {
            "name": "Lifestealer",
            "Winter Wyvern": -1.87
        },
        {
            "name": "Lina",
            "Winter Wyvern": 0.126
        },
        {
            "name": "Lion",
            "Winter Wyvern": -0.712
        },
        {
            "name": "Lone Druid",
            "Winter Wyvern": -5.214
        },
        {
            "name": "Luna",
            "Winter Wyvern": 0.696
        },
        {
            "name": "Lycan",
            "Winter Wyvern": -1.444
        },
        {
            "name": "Magnus",
            "Winter Wyvern": -1.709
        },
        {
            "name": "Marci",
            "Winter Wyvern": -2.422
        },
        {
            "name": "Mars",
            "Winter Wyvern": 0.638
        },
        {
            "name": "Medusa",
            "Winter Wyvern": -2.82
        },
        {
            "name": "Meepo",
            "Winter Wyvern": -6.246
        },
        {
            "name": "Mirana",
            "Winter Wyvern": 1.343
        },
        {
            "name": "Monkey King",
            "Winter Wyvern": -0.776
        },
        {
            "name": "Morphling",
            "Winter Wyvern": 0.571
        },
        {
            "name": "Muerta",
            "Winter Wyvern": -0.064
        },
        {
            "name": "Naga Siren",
            "Winter Wyvern": 1.746
        },
        {
            "name": "Nature's Prophet",
            "Winter Wyvern": 0.843
        },
        {
            "name": "Necrophos",
            "Winter Wyvern": 2.732
        },
        {
            "name": "Night Stalker",
            "Winter Wyvern": -1.014
        },
        {
            "name": "Nyx Assassin",
            "Winter Wyvern": 0.17
        },
        {
            "name": "Ogre Magi",
            "Winter Wyvern": -0.836
        },
        {
            "name": "Omniknight",
            "Winter Wyvern": -0.601
        },
        {
            "name": "Oracle",
            "Winter Wyvern": -0.008
        },
        {
            "name": "Outworld Destroyer",
            "Winter Wyvern": 1.639
        },
        {
            "name": "Pangolier",
            "Winter Wyvern": -2.54
        },
        {
            "name": "Phantom Assassin",
            "Winter Wyvern": 1.028
        },
        {
            "name": "Phantom Lancer",
            "Winter Wyvern": 0.749
        },
        {
            "name": "Phoenix",
            "Winter Wyvern": 1.097
        },
        {
            "name": "Primal Beast",
            "Winter Wyvern": 2.011
        },
        {
            "name": "Puck",
            "Winter Wyvern": -0.676
        },
        {
            "name": "Pudge",
            "Winter Wyvern": 0.956
        },
        {
            "name": "Pugna",
            "Winter Wyvern": 3.743
        },
        {
            "name": "Queen of Pain",
            "Winter Wyvern": 1.01
        },
        {
            "name": "Razor",
            "Winter Wyvern": 0.264
        },
        {
            "name": "Riki",
            "Winter Wyvern": -0.531
        },
        {
            "name": "Ringmaster",
            "Winter Wyvern": -0.615
        },
        {
            "name": "Rubick",
            "Winter Wyvern": -0.956
        },
        {
            "name": "Sand King",
            "Winter Wyvern": 3.946
        },
        {
            "name": "Shadow Demon",
            "Winter Wyvern": -0.27
        },
        {
            "name": "Shadow Fiend",
            "Winter Wyvern": -0.296
        },
        {
            "name": "Shadow Shaman",
            "Winter Wyvern": 0.195
        },
        {
            "name": "Silencer",
            "Winter Wyvern": 0.41
        },
        {
            "name": "Skywrath Mage",
            "Winter Wyvern": 0.52
        },
        {
            "name": "Slardar",
            "Winter Wyvern": -0.182
        },
        {
            "name": "Slark",
            "Winter Wyvern": 1.712
        },
        {
            "name": "Snapfire",
            "Winter Wyvern": 0.707
        },
        {
            "name": "Sniper",
            "Winter Wyvern": 1.639
        },
        {
            "name": "Spectre",
            "Winter Wyvern": 1.005
        },
        {
            "name": "Spirit Breaker",
            "Winter Wyvern": -1.029
        },
        {
            "name": "Storm Spirit",
            "Winter Wyvern": 0.552
        },
        {
            "name": "Sven",
            "Winter Wyvern": -0.927
        },
        {
            "name": "Techies",
            "Winter Wyvern": 0.658
        },
        {
            "name": "Templar Assassin",
            "Winter Wyvern": -1.573
        },
        {
            "name": "Terrorblade",
            "Winter Wyvern": -3.02
        },
        {
            "name": "Tidehunter",
            "Winter Wyvern": 1.152
        },
        {
            "name": "Timbersaw",
            "Winter Wyvern": 3.9
        },
        {
            "name": "Tinker",
            "Winter Wyvern": 1.42
        },
        {
            "name": "Tiny",
            "Winter Wyvern": -0.484
        },
        {
            "name": "Treant Protector",
            "Winter Wyvern": 0.759
        },
        {
            "name": "Troll Warlord",
            "Winter Wyvern": -2.281
        },
        {
            "name": "Tusk",
            "Winter Wyvern": -2.985
        },
        {
            "name": "Underlord",
            "Winter Wyvern": 1.14
        },
        {
            "name": "Undying",
            "Winter Wyvern": 2.184
        },
        {
            "name": "Ursa",
            "Winter Wyvern": -1.435
        },
        {
            "name": "Vengeful Spirit",
            "Winter Wyvern": -1.729
        },
        {
            "name": "Venomancer",
            "Winter Wyvern": 0.631
        },
        {
            "name": "Viper",
            "Winter Wyvern": 1.378
        },
        {
            "name": "Visage",
            "Winter Wyvern": -3.236
        },
        {
            "name": "Void Spirit",
            "Winter Wyvern": -1.046
        },
        {
            "name": "Warlock",
            "Winter Wyvern": 1.514
        },
        {
            "name": "Weaver",
            "Winter Wyvern": 1.354
        },
        {
            "name": "Windranger",
            "Winter Wyvern": 1.027
        },
        {
            "name": "Witch Doctor",
            "Winter Wyvern": 0.303
        },
        {
            "name": "Wraith King",
            "Winter Wyvern": -1.073
        },
        {
            "name": "Zeus",
            "Winter Wyvern": 1.415
        }
    ]
}


function updateTableData(selectedItems, ownComfortHeroes, oppositeComfortHeroes, isEnemyTable, setColumns, setData) {
    const nameColumn = {
        name: 'Counter',
        selector: row => row.name,
        sortable: true,
    };

    const comfortColumn = {
        name: 'Comfort',
        selector: row => row.comfort || '', // Add comfort emoji if available
        sortable: true,
    };

    const grandTotalColumn = {
        name: 'Grand Total',
        selector: row => row.grandTotal?.toFixed(3) || '0.000', // Round to 3 decimal places, fallback to 0
        sortable: true,
        sortFunction: (a, b) => b.grandTotal - a.grandTotal // Custom sorting function
    };
    
    // Ensure that these base columns are always present
    let selectedColumns = [nameColumn, comfortColumn, grandTotalColumn];

    if (selectedItems.length > 0) {
        // Filter out any undefined columns before adding them to selectedColumns
        const heroColumns = selectedItems
            .map(item => allColumns[item.value])
            .filter(column => column !== undefined);

        selectedColumns = [nameColumn, comfortColumn, ...heroColumns, grandTotalColumn];
    }

    setColumns(selectedColumns);

    if (selectedItems.length > 0) {
        const heroMap = {};
        selectedItems.forEach(item => {
            if (allData[item.value]) {
                allData[item.value].forEach(row => {
                    if (!heroMap[row.name]) {
                        heroMap[row.name] = { name: row.name, grandTotal: 0, comfort: '' };
                    }
                });
            }
        });

        selectedItems.forEach(item => {
            if (allData[item.value]) {
                allData[item.value].forEach((row) => {
                    if (heroMap[row.name]) {
                        const value = row.name === item.value ? 0 : row[item.value];
                        heroMap[row.name][item.value] = value;
                        heroMap[row.name].grandTotal = (heroMap[row.name].grandTotal || 0) + value;
                    }
                });
            }
        });

        Object.keys(heroMap).forEach(key => {
            let comfortEmojis = '';
            ownComfortHeroes.forEach(hero => {
                if (key === hero.value) {
                    comfortEmojis += isEnemyTable ? '😈' : '😊'; // Own comfort emoji
                }
            });
            oppositeComfortHeroes.forEach(hero => {
                if (key === hero.value) {
                    comfortEmojis += isEnemyTable ? '😊' : '😈'; // Opposite comfort emoji
                }
            });
            heroMap[key].comfort = comfortEmojis; // Assign the concatenated emojis to the comfort column
        });

        const selectedData = Object.values(heroMap);
        selectedData.sort((a, b) => b.grandTotal - a.grandTotal);

        setData(selectedData);
    } else {
        setData([]);
    }
}

function SelectTable({ onComfortSelect, ownComfortHeroes, oppositeComfortHeroes, isEnemyTable, urlParam }) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        // Initialize selected options from URL parameters
        const initialComfort = searchParams.get(`${urlParam}Comfort`)?.split(',') || [];
        const initialPicks = searchParams.get(`${urlParam}Picks`)?.split(',') || [];

        // Map the values to the format expected by the Select component
        const initialComfortOptions = initialComfort.map(value => ({ value, label: value }));
        const initialPickOptions = initialPicks.map(value => ({ value, label: value }));

        // Set the initial values for the selects
        onComfortSelect(initialComfortOptions);
        setSelectedOptions(initialPickOptions);
    }, [searchParams, onComfortSelect, urlParam]);

    useEffect(() => {
        updateTableData(selectedOptions, ownComfortHeroes, oppositeComfortHeroes, isEnemyTable, setColumns, setData);
    }, [ownComfortHeroes, oppositeComfortHeroes, selectedOptions, isEnemyTable]);

    function onSelect(selectedItems) {
        setSelectedOptions(selectedItems);
        const pickValues = selectedItems.map(item => item.value);
        searchParams.set(`${urlParam}Picks`, pickValues.join(','));
        setSearchParams(searchParams);
    }


    return (
        <div className="">
            <div>
                <Select
                    defaultValue={searchParams.get(`${urlParam}Comfort`)?.split(',').map(value => ({ value, label: value })) || []}
                    isMulti
                    onChange={selectedItems => {
                        onComfortSelect(selectedItems);
                        const comfortValues = selectedItems.map(item => item.value);
                        searchParams.set(`${urlParam}Comfort`, comfortValues.join(','));
                        setSearchParams(searchParams);
                    }}
                    name="comfortSelect"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Comfort Heroes..."
                />
            </div>
            <div>
                <Select
                    defaultValue={searchParams.get(`${urlParam}Picks`)?.split(',').map(value => ({ value, label: value })) || []}
                    isMulti
                    onChange = {onSelect}
                    name="pickSelect"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select" 
                    placeholder="Select Picked Heroes..."
                />
            </div>
            <div>
                <DataTable
                columns={columns}
                data={data}
                defaultSortField="Grand Total" // Sort by Grand Total
                defaultSortAsc={false} // Descending order
                />
            </div>
        </div>
    );
}


function DualSelectTable() {
    const [enemyComfortHeroes, setEnemyComfortHeroes] = useState([]);
    const [allyComfortHeroes, setAllyComfortHeroes] = useState([]);
    const [enemyNotes, setEnemyNotes] = useState('');  // State for enemy notes
    const [allyNotes, setAllyNotes] = useState('');    // State for ally notes

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1, marginRight: '10px' }}>
                <h2>Enemy Team Picks</h2>
                <textarea 
                    value={enemyNotes} 
                    onChange={(e) => setEnemyNotes(e.target.value)} 
                    placeholder="Write notes for Enemy Team..." 
                    style={{ width: '100%', marginBottom: '10px' }} 
                />
                <SelectTable 
                    onComfortSelect={setEnemyComfortHeroes} // Affects Enemy table
                    ownComfortHeroes={enemyComfortHeroes} // Enemy comfort affects Enemy table
                    oppositeComfortHeroes={allyComfortHeroes} // Ally comfort affects Enemy table
                    isEnemyTable={true} // Flag to indicate it's the enemy table
                    urlParam="enemy"
                />
            </div>
            <div style={{ flex: 1, marginLeft: '10px' }}>
                <h2>Ally Team Picks</h2>
                <textarea 
                    value={allyNotes} 
                    onChange={(e) => setAllyNotes(e.target.value)} 
                    placeholder="Write notes for Ally Team..." 
                    style={{ width: '100%', marginBottom: '10px' }} 
                />
                <SelectTable 
                    onComfortSelect={setAllyComfortHeroes} // Affects Ally table
                    ownComfortHeroes={allyComfortHeroes} // Ally comfort affects Ally table
                    oppositeComfortHeroes={enemyComfortHeroes} // Enemy comfort affects Ally table
                    isEnemyTable={false} // Flag to indicate it's the ally table
                    urlParam="ally"
                />
            </div>
        </div>
    );
}

export default DualSelectTable;